import React, { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { SearchRequestVacation } from "../../components/human-resources/SearchRequestVacation";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";

import { CrearVacacionPermiso } from "../../components/vacaciones-permisos/CrearVacacionPermiso";


import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { useTranslation } from "react-i18next";

// este componente es para las solicitudes de vacaciones y permisos
export const SolicitudesVacaPerm = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  const location = useLocation();


  // GET the useSearchParams
  const [params] = useSearchParams();

  const PAGE_SIZE = 20;


  // state definition
  const [listVacation, setListVacation] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la solicitud al servidor
  const [state, setState] = useState({ isLoading: false, msg: "", moreData: false, modalSearch: false, total: 0 });
  const [currentPag, setCurrentPag] = useState(1);


  // funcion para abrir el modal de busqueda
  const onOpenCloseFilter = () => {
    setState((val) => ({ ...val, modalSearch: !state.modalSearch }));
  };


  // funcion para actualizar la lista de las vacaciones
  const onSetNewListVacaFromChild = (newList = [], error = 0, msg = "", moreData = false, total = 0) => {
    setListVacation(newList);

    const ERROR_MSG = {
      404: msg
    };

    setState((val) => ({ ...val, msg: ERROR_MSG[error] ?? "", moreData, total }));
  };



  // funcion para solicitar la lista de vacaciones
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.vacations}?language=${language}&cod_vaca=${params.get("num_contr") ?? ""}&empresa=${params.get("nom_empr") ?? ""}&division=${params.get("nom_divi") ?? ""}&gerencia=${params.get("nom_geren") ?? ""}
    &nom_empl=${params.get("nom_empl") ?? ""}&nom_super=${params.get("nom_super") ?? ""}&tipo=${params.get("id_tipo") ?? ""}&etapa=${params.get("nom_etapa") ?? ""}&page=${currentPag}&pageSize=${PAGE_SIZE}&action=1`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });;

      const data = await response.json();

      if (response.status === 200) {
        setCurrentPag(currentPag + 1);

        if (data?.listVaca.length < PAGE_SIZE) {
          setCurrentPag(1);
        }

        setListVacation([...listVacation].concat(data?.listVaca));

        setState((val) => ({ ...val, moreData: data?.listVaca.length < PAGE_SIZE, msg: "", total: data?.Total }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);






  return (
    <div className="container display-data">
      {
        state.isLoading && (<LoadingComp />)
      }
      {
        // modal search
        state.modalSearch && (<SearchRequestVacation lang={language} token={getTheToken().token} location={location} onClose={onOpenCloseFilter} onSetNewList={onSetNewListVacaFromChild} />)
      }

      {
        state.msg && (<h4 style={{ color: "var(--danger-color)" }}>{state.msg}</h4>)
      }

      <h5 className="m-y-small">{listVacation.length} de {state.total} vacaciones</h5>

      <div className="table-container-mobile">
        <table className="table">
          <thead className="table-head">
            <tr>
              <th>Control</th>
              <th>Empleado</th>
              <th>Supervisor</th>
              <th>Tipo</th>
              <th>Inicio</th>
              <th>Fin</th>
              <th>Reintegro</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {
              listVacation && listVacation.map((lstV) => (
                <tr key={lstV.CodVacacion}>
                  <td className="txt-center">
                    <Link className="link-tag" to={`/human-capital/vacations/request/detail/${lstV.CodVacacion}`}>{lstV.CodVacacion}</Link>
                  </td>
                  <td>{lstV.NomEmpleado}</td>
                  <td>{lstV.NomLider}</td>
                  <td>{lstV.NomTipo}</td>
                  <td>{renderDate(lstV.FecInicio, language)}</td>
                  <td>{renderDate(lstV.FecFin, language)}</td>
                  <td>{renderDate(lstV.FecReintegro, language)}</td>
                  <td>{lstV.Comentario}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>


      <ButtonSearch onOpenFilter={onOpenCloseFilter} />

      {
        listVacation && listVacation.length > 0 && (
          <div className="row-flex align-c justify-center m-y-small">
            {
              state.moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button className="btn-primary" onClick={fetchDataServ}>{t("serviceCard.ver_mas")}</button>
            }
          </div>
        )
      }

    </div>
  );
};