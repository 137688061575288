// componente para buscar un empleado en el dynamic

import { useEffect, useState } from "react";
import { urls } from "../../api-urls/api-urls";
import { useNavigate } from "react-router-dom";








export const SearchEmplDyn = ({ onClose, nomEmpl, token, lang, codEmpl, setNotification, setCodeDyn }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServ, setDataServ] = useState([]); // estado para cargar la data
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [searchName, setSearchName] = useState(nomEmpl);

  // destructuring data server
  const { employee } = dataServ && dataServ;


  // buscar el empleado
  const FoundEmpl = employee && employee.filter((filtEmpl) => filtEmpl.NomEmpleado.toLowerCase().includes(searchName.toLowerCase()));



  // funcion para buscar en el servidor
  const onHandleSearchForm = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.employees}?language=${lang}&action=4&nom_empl=${searchName}`;

    try {
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para agregar el codigo del dyn al gis
  const onAddCodeLite = async (cod = 0) => {

    const END_POINT = `${urls.employees}/${codEmpl}/search?language=${lang}&action=2&code_lite=${cod}`;

    try {
      const response = await fetch(END_POINT, {
        method: "PUT",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        // enviar notificacion al hijo
        setNotification(data?.msg, false);
        setCodeDyn(cod);
        onClose();
      } else if (response.status === 404) {
        setNotification(data?.msg, true);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para solicitar la peticion al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.employees}?language=${lang}&nom_empl=${searchName}&action=4`;

    setController(abortController);
    try {
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);




  return (
    <div className="background-modal">
      <form onSubmit={onHandleSearchForm} className="container-select-customer container-filt-empl-dyn-h">
        <div className="modal-header">
          <h4>Buscar empleado</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          <div className="row-flex align-c">
            <div className="col-7">
              <strong>Empleado</strong>
            </div>
            <div className="col-8 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="employee"
                value={searchName}
                onChange={({ target }) => setSearchName(target.value)}
                autoComplete="off" />
            </div>
          </div>

          {
            FoundEmpl && FoundEmpl.map((empl) => (
              <div className="row-flex border-bottom hover-class" key={empl.CodEmpleado}>
                <div className="col-8 flex-grow">
                  <strong>{empl.NomEmpleado}</strong>
                </div>
                <div className="col-8 row-flex flex-end">
                  <button type="button" style={{ cursor: "pointer" }} onClick={() => onAddCodeLite(empl.CodEmpleado)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                      <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                    </svg>
                  </button>
                </div>
              </div>
            ))
          }

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-primary">Buscar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};