// Este componente es para ver el detalle de la orden de comercial
import React, { useEffect, useState } from "react";


// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";


// React router dom importation
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Urls importation
import { urls } from "../../api-urls/api-urls";

// Global functions
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";



export const OrderDetailCommercialView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation 
  const location = useLocation();

  // GET the useParams
  const { codSol } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]); // Estado para cargar la data del servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, serverError: false, unauthorized: false, notFound: false, showModal: false, showModalAddP: false });
  const [stateForm, setStateForm] = useState({ codCliente: "", nomCliente: "", codProveedor: "", nomProveedor: "", incoterm: "", facturarA: "", lugarEntrega: "", observa: "", moneda: "", codEmpResponsable: "", contacto: "", fecCompromiso: "", fecSalida: "" });
  const [addPart, setAddPart] = useState([]);


  // Destructuring data server
  const { orderDetail, segOrder, employees, items } = dataServer && dataServer;



  // useEffect para traer los datos del servidor
  useEffect(() => {
    // funcion para hacer el fetch de los datos
    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.commercialOrderProj}/${codSol}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        } else if (response.status === 404) {
          setState((val) => ({ ...val, dataVisible: false, notFound: true }));
        }

        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();
  }, [language]);


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if (orderDetail && orderDetail.length > 0) {
      setStateForm({
        codCliente: orderDetail[0]?.CodCliente, nomCliente: "", codProveedor: orderDetail[0]?.CodProveedor, nomProveedor: "",
        incoterm: orderDetail[0]?.Incoterm, facturarA: orderDetail[0]?.FacturarA, lugarEntrega: orderDetail[0]?.LugarEntrega,
        observa: orderDetail[0]?.Observacion, moneda: orderDetail[0]?.Moneda, codEmpResponsable: orderDetail[0]?.CodEmpResponsable,
        contacto: "", fecCompromiso: orderDetail[0]?.FecCompromiso, fecSalida: orderDetail[0]?.FecSalida
      });
    }
  }, [orderDetail]);

  return (
    <>
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {/* Error403 component */}
      {state.unauthorized && (<Error403View />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <div className="card-shadow">
                <div className="row-flex">
                  {/* Etapa */}
                  <div className="col-7"><strong>Etapa</strong></div>
                  <div className="col-3"><h5 className="active-class">Orden de compra</h5></div>
                </div>
                <form>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Solicitud */}
                    <div className="col-7"><strong>Solicitud</strong></div>
                    <div className="col-7"><h4>{orderDetail && orderDetail[0]?.CodSolicitud}</h4></div>
                    {/* Incoterm */}
                    <div className="col-7"><strong>Incoterm</strong></div>
                    <div className="col-7">
                      <select name="incoterm" value={stateForm.incoterm} onChange={({ target }) => setStateForm((val) => ({ ...val, incoterm: target.value }))}>
                        <option value=""></option>
                        <option value="CIF">CIF</option>
                        <option value="FOB">FBO</option>
                      </select>
                    </div>
                    {/* Orden dynamic */}
                    <div className="col-7"><strong>Orden Dynamics</strong></div>
                    <div className="col-4"><span></span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Fecha solicitud */}
                    <div className="col-7"><strong>Fecha solicitud</strong></div>
                    <div className="col-7"><span>{orderDetail && renderDate(orderDetail[0]?.FecIngreso, language)}</span></div>
                    {/* Moneda */}
                    <div className="col-7"><strong>Moneda</strong></div>
                    <div className="col-7">
                      <select name="currency" value={stateForm.moneda} onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                        <option value=""></option>
                        <option value="1">USD</option>
                        <option value="2">RD</option>
                      </select>
                    </div>
                    {/* Lugar de entrega */}
                    <div className="col-7"><strong>Lugar de entrega</strong></div>
                    <div className="col-4">
                      <input
                        className="input-text-control"
                        type="text"
                        name="deliveryPlac"
                        value={stateForm.lugarEntrega}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, lugarEntrega: target.value }))}
                        autoComplete="off" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Terminos */}
                    <div className="col-7"><strong>Términos GE</strong></div>
                    <div className="col-7">
                      <select name="terms">
                        <option value=""></option>
                        <option value="">5% Prior to production; 95% Prior to shipment</option>
                        <option value="">otros</option>
                      </select>
                    </div>
                    {/* Terminos Cliente */}
                    <div className="col-7"><strong>Terminos cliente</strong></div>
                    <div className="col-7">
                      <input
                        className="input-text-control"
                        type="text"
                        name="termCusto" />
                    </div>
                    {/* Empleado responsable */}
                    <div className="col-7"><strong>Responsable</strong></div>
                    <div className="col-4">
                      <select name="responsible" value={stateForm.codEmpResponsable} onChange={({ target }) => setStateForm((val) => ({ ...val, codEmpResponsable: target.value }))}>
                        <option value=""></option>
                        {
                          employees && employees.map((emp) => (
                            <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Cliente */}
                    <div className="col-7"><strong>Cliente</strong></div>
                    <div className="col-28-58">
                      <input
                        className="input-text-control"
                        type="text"
                        name="custo"
                        value={stateForm.nomCliente}
                        readOnly />
                    </div>
                    {/* Contacto */}
                    <div className="col-7"><strong>Contacto</strong></div>
                    <div className="col-7">
                      <input
                        className="input-text-control"
                        type="text"
                        name="contact" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Proveedor */}
                    <div className="col-7"><strong>Proveedor</strong></div>
                    <div className="col-28-58">
                      <input
                        className="input-text-control"
                        type="text"
                        name="suppli"
                        value={stateForm.nomProveedor}
                        readOnly />
                    </div>
                    {/* Fecha compromiso */}
                    <div className="col-7"><strong>Fecha compromiso</strong></div>
                    <div className="col-7">
                      <input
                        className="input-date-styles"
                        type="date"
                        name="fecComp"
                        value={stateForm.fecCompromiso}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, fecCompromiso: target.value }))} />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Facturar a */}
                    <div className="col-7"><strong>Facturar a</strong></div>
                    <div className="col-28-58">
                      <input
                        className="input-text-control"
                        type="text"
                        name="billTo"
                        value={stateForm.facturarA}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, facturarA: target.value }))}
                        autoComplete="off" />
                    </div>
                    {/* Fecha salida */}
                    <div className="col-7"><strong>Fecha salida</strong></div>
                    <div className="col-7">
                      <input
                        className="input-date-styles"
                        type="date"
                        name="fecSal"
                        value={stateForm.fecSalida}
                        onChange={({ target }) => ((val) => ({ ...val, fecSalida: target.value }))} />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Observaciones */}
                    <div className="col-7"><strong>Observaciones</strong></div>
                    <div className="col-28-58">
                      <textarea
                        className="textarea-description"
                        name="obser"
                        value={stateForm.observa}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, observa: target.value }))} />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Cargar documentos */}
                    <div className="col-7"><strong>Cargar Documentos</strong></div>
                    <div className="col-28-58 row-flex gap-medium">
                      {/* Boton para cargar la cotizacion del cliente */}
                      <button type="button" className="btn-docs" title="Cargar cotización cliente">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                          <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                          <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </button>

                      {/* Boton para descargar la cotizacion del cliente */}
                      {
                        orderDetail && orderDetail[0]?.CotiCliente && (
                          <button type="button" className="btn-pdf" title="Descargar cotización cliente">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                              <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                              <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                            </svg>
                          </button>
                        )
                      }
                    </div>
                  </div>

                  <hr className="m-y-small" />

                  {/* Botones de acción */}
                  <div className="row-flex m-y-medium j-content-space-around">
                    <button type="button" className="btn-primary">Guardar</button>
                    <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Cancelar</button>
                  </div>

                </form>
              </div>

              {/* Agregar item */}
              <div className="row-flex m-y-small">
                <div className="col-7">
                  <button className="btn-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }} onClick={() => setState((val) => ({ ...val, showModalAddP: true }))}>
                    Agregar item
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-plus" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Tabla para mostrar los items */}
              {
                items && items.length > 0 ? (
                  <div className="m-y-medium">
                    <div className="table-container-mobile m-y-small">
                      <table className="table">
                        <thead className="table-head">
                          <tr>
                            <th>Artículo</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>Unidad medida</th>
                            <th>Costo unitario</th>
                            <th>Descuento</th>
                            <th>Itbis</th>
                            <th>Precio total</th>
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {
                            items && items.map((art) => (
                              <tr key={art.CodDetSolicitud}>
                                <td><Link className="link-tag">{art.Articulo}</Link></td>
                                <td>{art.Descripcion}</td>
                                <td className="txt-center">{art.Cantidad}</td>
                                <td>{ }</td>
                                <td style={{ textAlign: 'right' }}>{formatCurrency(art.CostoUnitario, 'USD')}</td>
                                <td style={{ textAlign: 'right' }}>{art.Descuento}</td>
                                <td style={{ textAlign: 'right' }}>{art.Itbis}</td>
                                <td style={{ textAlign: 'right' }}>{formatCurrency(art.PrecioTotal, 'USD')}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (<div className="m-y-medium"><strong className="active-class" style={{ textDecoration: 'underline' }}>Aún no se han agregado items.</strong></div>)
              }

              {/* Tabla de seguimiento */}
              {
                segOrder && segOrder.length > 0 && (
                  <div className="m-y-medium">
                    <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>Seguimiento</h4>
                    <div className="table-container-mobile m-y-small">
                      <table className="table">
                        <thead className="table-head">
                          <tr>
                            <th>Fecha</th>
                            <th>Etapa</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {
                            segOrder && segOrder.map((seg) => (
                              <tr key={seg.CodSegOrd}>
                                <td className="txt-center">{renderDate(seg.FecIngreso, language)}</td>
                                <td>{seg.IdEtapa}</td>
                                <td className="col-6">
                                  <strong>{seg.NomEmpleado}: </strong>
                                  <span> {seg.Accion}</span>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              }

              {/* Modal para agregar los articulos */}
              {
                state.showModalAddP && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModalAddP: false }))}>
                    <div className="container-modal-add-part" onClick={(event) => event.stopPropagation()}>
                      <h5 className="txt-center active-class m-y-medium">Agregar artículos</h5>

                      <form>
                        {/* FILA */}
                        <div className="row-flex">
                          {/* Articulo */}
                          <div className="col-4"><strong>Artículo</strong></div>
                          <div className="col-43">
                            <input
                              className="input-text-control"
                              type="text"
                              name="art" />
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Descripción */}
                          <div className="col-4"><strong>Descripción</strong></div>
                          <div className="col-43">
                            <input
                              className="input-text-control"
                              type="text"
                              name="descrip" />
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Cantidad */}
                          <div className="col-4"><strong>Cantidad</strong></div>
                          <div className="col-4">
                            <input
                              className="input-text-control"
                              type="text"
                              name="quantity" />
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Unidad de medida */}
                          <div className="col-4"><strong>Unidad de medida</strong></div>
                          <div className="col-4">
                            <select name="unity">
                              <option value=""></option>
                            </select>
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Costo unitario */}
                          <div className="col-4"><strong>Costo unitario</strong></div>
                          <div className="col-4">
                            <input
                              className="input-text-control"
                              type="text"
                              name="costUnit" />
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Descuento */}
                          <div className="col-4"><strong>Descuento</strong></div>
                          <div className="col-4">
                            <input
                              className="input-text-control"
                              type="text"
                              name="discount" />
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Itbis */}
                          <div className="col-4"><strong>Itbis</strong></div>
                          <div className="col-4">
                            <input
                              className="input-text-control"
                              type="text"
                              name="itbis" />
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Precio total (calculado) */}
                          <div className="col-4"><strong>Precio total</strong></div>
                          <div className="col-4">
                            <input
                              className="input-text-control"
                              type="text"
                              name="preTotal"
                              readOnly />
                          </div>
                        </div>

                        {/* Botones de accion */}
                        <div className="row-flex j-content-space-around m-y-medium">
                          <button className="btn-primary">Agregar</button>
                          <button type="reset" className="btn-secondary" onClick={() => setState((val) => ({ ...val, showModalAddP: false }))}>Cerrar</button>
                        </div>
                      </form>
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </>
  );
};