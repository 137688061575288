import React, { useEffect, useState } from "react";

// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";


// React router dom importation
import { Link, useLocation, useNavigate } from "react-router-dom";

// Global function
import { urls } from "../../api-urls/api-urls";

// Global function
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";



export const PendingCommercialView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();


  // State definition
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, serverError: false, unauthorized: false });


  // Destructuring dataServer
  const { orders } = dataServer && dataServer;


  // useEffect para traer la data del servidor
  useEffect(() => {
    // Funcion para realizar el fetch de los datos
    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.commercialOrderProj}?language=${language}&initialData=no`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        }

        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();
  }, [language]);


  return (
    <>
      {/* Error 403 component */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Solicitud</th>
                      <th>Fecha</th>
                      <th>Cliente</th>
                      <th>Producto</th>
                      <th>Etapa</th>
                      <th>Ultima acción</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      orders && orders.map((ord) => (
                        <tr key={ord.codeIndex}>
                          <td className="txt-center"><Link className="link-tag" to={`/commercial/order-detail/${ord.CodSolicitud}`}>{ord.CodSolicitud}</Link></td>
                          <td className="txt-center">{renderDate(ord.FecIngreso, language)}</td>
                          <td>{ord.nomCliente}</td>
                          <td></td>
                          <td></td>
                          <td>{ord.lastComment}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="btn-new-pro-container">
                <button className="btn-primary" title="Hola" onClick={() => navigate("/commercial/create-order")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-plus" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                  </svg>
                </button>
              </div>
            </>
          )
        }
      </div>
    </>
  );
};