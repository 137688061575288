import React, { useEffect, useState } from "react";


// components importation
import { Error404Comp } from "../../components/errors/Error404Comp";
import { Error403View } from "../error-403-view/Error403View";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";



// React router dom importation
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Url importation
import { urls } from "../../api-urls/api-urls";
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { useTranslation } from "react-i18next";




export const AdministrativeApprovalsDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the translation of react
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the params
  const { codSolRep } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, error404: false, serverError: false, showModal: false });
  const [partes, setPartes] = useState([]);
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, success: false });
  const [stateForm, setStateForm] = useState({ tipApro: "", action: "0", comment: "", workflow: "", primaryAction: "" });
  const [questionAction, setQuestionAction] = useState("");


  // DESTRUCTURING DATA SERVER
  const { solRep, detSolRep } = dataServer && dataServer;


  // Menejador del submit para enviar al backend
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    if (!stateForm.tipApro && stateForm.action !== "0") {
      return setNotification((val) => ({ ...val, show: true, msg: t("RequestPartsView.selec_tip_apro"), errorNoti: true }));
    }

    const finalObj = { stateForm, partes };

    console.log("Se sometio el")

    try {
      // Enviar la solicitud al backend
      const response = await fetch(`${urls.administrativeAppro}/${codSolRep}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, showModal: false }));
        setStateForm({ tipApro: "", action: "0", comment: "", workflow: "" });
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      }

      console.log("Se sometió el formulario");

    } catch (error) {
      console.log(error);
    }
  };


  // Funcion para cambiar el aprobar partes
  const onHandleApprove = (index, newVal) => {
    const updateApprove = [...partes];
    updateApprove[index].Aprobar = newVal;

    setPartes(updateApprove);
  };

  // Funcion para cambiar el devolver
  const onHandleReturn = (index, newVal) => {
    const updateReturn = [...partes];
    updateReturn[index].Devolver = newVal;

    setPartes(updateReturn);
  };



  // Funcion para manejar comentar
  const onHandleComment = () => {
    setState((val) => ({ ...val, showModal: true }));
    setStateForm({ ...stateForm, primaryAction: "1" });
    setQuestionAction(t("RequestPartsView.no_selec_accion"));
  };

  // Funcion para manejar el guardar
  const onHandleSave = () => {
    if (!stateForm.tipApro && stateForm.action === "0") {
      return setNotification((val) => ({ ...val, show: true, msg: t("RequestPartsView.selec_tip_apro_accion"), errorNoti: true, success: false }));
    } else if (stateForm.action === "0") {
      return setNotification((val) => ({ ...val, show: true, msg: t("RequestPartsView.sele_accion"), errorNoti: true, success: false }));
    } else if (stateForm.action !== "0" && !stateForm.tipApro) {
      return setNotification({ show: true, msg: t("RequestPartsView.selec_tip_apro"), errorNoti: true });
    }
    setStateForm({ ...stateForm, primaryAction: "2" });
    setState((val) => ({ ...val, showModal: true }));
    setQuestionAction(t("RequestPartsView.seguro_enviar_etapa_sele"));
  };

  // Funcion para manejar el rechazar la solicitud
  const onHandleReturnRequest = () => {
    setStateForm((val) => ({ ...val, primaryAction: "3" }));
    setState((val) => ({ ...val, showModal: true }));
    setQuestionAction(t("RequestPartsView.seguro_enviar_apro_tecnica"));
  };


  // fech Data to the server
  const fetchDataServer = async () => {
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.administrativeAppro}/${codSolRep}/search?language=${language}`, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 400) {
        // Si hay un bad request
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, error404: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServer();

  }, [codSolRep, language]);

  // useEffect para recargar la data
  useEffect(() => {
    if (notification.success) {
      fetchDataServer();
    }
  }, [notification.success]);

  // useEffect para montar el detSolRepuesto en el estado de partes
  useEffect(() => {
    if (detSolRep && detSolRep.length > 0) {
      setPartes(detSolRep);
    }
  }, [detSolRep]);

  // console.log(partes);

  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, success: false });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);




  return (
    <>
      {/* Unauthorized comp */}
      {state.unauthorized && (<Error403View />)}
      {/* Component notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)}
      {state.error404 && (<Error404Comp msgPrin={t("RequestPartsView.error_404")} navigateTo={"/warehouse/administrative-approval"} />)}
      {
        state.dataVisible && (
          <div className="container display-data">

            <form onSubmit={onHandleSubmit}>
              {/* FILA */}
              <div className="row-flex">
                {/* Solicitud */}
                <div className="col-7"><strong>{t("RequestPartsView.soli")}</strong></div>
                <div className="col-7"><h4>{codSolRep}</h4></div>
                {/* Id Servicio */}
                <div className="col-8"><strong>{t("RequestPartsView.id_serv")}</strong></div>
                <div className="col-8"><Link className="link-tag" to={`/service/service-call/${solRep && solRep[0]?.CodServicio}`}>{solRep && solRep[0]?.CodServicio}</Link></div>
                {/* Modelo */}
                <div className="col-10"><strong>{t("RequestPartsView.modelo")}</strong></div>
                <div className="col-7"><span>{solRep && solRep[0]?.equipment[0]?.Modelo}</span></div>
                {/* Serial */}
                <div className="col-10"><strong>Serial</strong></div>
                <div className="col-7"><span>{solRep && solRep[0]?.equipment[0]?.Serial}</span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Fecha */}
                <div className="col-7"><strong>{t("RequestPartsView.fecha")}</strong></div>
                <div className="col-39-29"><span>{solRep && renderDate(solRep[0]?.FecIngreso, language)}</span></div>
                {/* Suplidor */}
                <div className="col-10"><strong>{t("RequestPartsView.supli")}</strong></div>
                <div className="col-7"><span>{solRep && solRep[0]?.equipment[0]?.Marca}</span></div>
                {/* Garantia */}
                <div className="col-10"><strong>Garantía</strong></div>
                <div className="col-7"><span></span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Cliente */}
                <div className="col-7"><strong>{t("RequestPartsView.cliente")}</strong></div>
                <div className="col-39-29 text-hidden-over"><Link className="link-tag" to={`/customers/customer-detail/${solRep && solRep[0]?.CodCliente}`}>{solRep && solRep[0]?.NomCliente}</Link></div>
                {/* FDO */}
                <div className="col-10"><strong>FDO</strong></div>
                <div className="col-7"><span>{solRep && solRep[0]?.equipment[0]?.FDO}</span></div>
                {/* O/C */}
                <div className="col-10"><strong>O/C</strong></div>
                <div className="col-7"><span>{solRep && solRep[0]?.equipment[0]?.NumOrden}</span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Equipo */}
                <div className="col-7"><strong>{t("RequestPartsView.equip")}</strong></div>
                <div className="col-61-58"><Link className="link-tag" to={`/equipments/equipment-detail/${solRep && solRep[0]?.equipment[0]?.CodEquipo}`}>{solRep && solRep[0]?.equipment[0]?.Linea} - {solRep && solRep[0]?.equipment[0]?.Modalidad} - {solRep && solRep[0]?.equipment[0]?.TipEquipo} - {solRep && solRep[0]?.equipment[0]?.Modelo} - {solRep && solRep[0]?.equipment[0]?.Serial} - {solRep && solRep[0]?.equipment[0]?.Marca} - {solRep && solRep[0]?.equipment[0]?.NumSistema}</Link></div>
                {/* Contrato */}
                <div className="col-10"><strong>{t("RequestPartsView.contr")}</strong></div>
                <div className="col-7"><span>{solRep && solRep[0]?.contract}</span> {solRep && solRep[0]?.fecEOL !== null && (<strong className="required"> {language === "en" ? "Equipment EOL" : "Equipo EOL"}</strong>)}</div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Ingeniero tecnico */}
                <div className="col-7"><strong>{t("RequestPartsView.ing_tec")}</strong></div>
                <div className="col-39-29"><span>{solRep && solRep[0]?.NomEmpleado}</span></div>
                {/* Falla */}
                <div className="col-10"><strong>{t("RequestPartsView.falla")}</strong></div>
                <div className="col-36-58"><span style={{ textDecoration: 'underline', textTransform: 'capitalize' }} className="required">{solRep && solRep[0]?.Falla}</span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Ultima acción */}
                <div className="col-7"><strong>{t("RequestPartsView.ult_accion")}</strong></div>
                <div className="col-83-87"><span style={{ textTransform: 'capitalize', textDecoration: 'underline' }} className="active-class">{solRep && solRep[0]?.lastAction}</span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Tipo de aprobación */}
                <div className="col-7"><strong>Tipo de aprobación</strong></div>
                <div className="col-4">
                  <select name="tipAproba" value={stateForm.tipApro} onChange={({ target }) => setStateForm({ ...stateForm, tipApro: target.value })}>
                    <option value=""></option>
                    <option value="0">Stock</option>
                    <option value="3">Designado</option>
                    <option value="6">Garantía ventas</option>
                    <option value="9">Exportaciones</option>
                    <option value="1">Activo fijo</option>
                    <option value="4">FMI/STB</option>
                    <option value="7">Garantía soporte</option>
                    <option value="10">Compra local</option>
                    <option value="2">Contrato</option>
                    <option value="5">Missing</option>
                    <option value="8">Gasto</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex m-y-small">
                {/* Seleccionar acción */}
                <div className="col-7"><strong>Seleccionar acción</strong></div>
                <div className="col-4">
                  <select name="action" value={stateForm.action} onChange={({ target }) => setStateForm({ ...stateForm, action: target.value })}>
                    <option value="0"></option>
                    <option value="1">Cotización</option>
                    <option value="2">Nota de entrega</option>
                    <option value="3">Orden de compra</option>
                    <option value="4">Solicitud de precios</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                <div className="col-7"><strong>Comentario</strong></div>
                <div className="col-83-87">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={stateForm.comment}
                    onChange={({ target }) => setStateForm({ ...stateForm, comment: target.value })}
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Workflow */}
                <div className="col-7"><strong>Workflow</strong></div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    type="text"
                    name="workflow"
                    value={stateForm.workflow}
                    onChange={({ target }) => setStateForm({ ...stateForm, workflow: target.value })} />
                </div>
              </div>

              {/* TABLA PARA MOSTRAR LAS PARTES */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.precio")}</th>
                      <th>{t("TableParts.devolver")}</th>
                      <th>{t("TableParts.coment")}</th>
                      <th>{t("TableParts.aprobar")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes && partes.map((part, index) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td className="txt-center">{index + 1}</td>
                          <td className="txt-center">{part.Cantidad}</td>
                          <td><Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link></td>
                          <td>{part.Descripcion}</td>
                          <td style={{ color: 'var(--money-color)', fontWeight: 'bold' }} className="col-10 txt-center">{formatCurrency(part.Lista, 'USD')}</td>
                          <td>
                            <select name={`devolver-${part.CodDetSolRepuesto}`} value={part.Devolver} onChange={({ target }) => onHandleReturn(index, target.value)}>
                              <option value="1">{t("RequestPartsView.si")}</option>
                              <option value="0">No</option>
                            </select>
                          </td>
                          <td className="col-4">{part.comment}</td>
                          <td className="col-10 txt-center">
                            <input
                              className="input-control-checkbox"
                              type="checkbox"
                              name={`aprovv-${part.CodDetSolRepuesto}`}
                              checked={part.Aprobar}
                              onChange={({ target }) => onHandleApprove(index, target.checked)} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones de acción */}
              <div className="row-flex m-y-medium j-content-space-around">
                <button type="button" className="btn-success" onClick={onHandleComment}>Comentar</button>
                <button type="button" className="btn-primary" onClick={onHandleSave}>Guardar</button>
                <button type="button" className="btn-warning" onClick={onHandleReturnRequest}>Rechazar</button>
                <button type="button" className="btn-danger">Cerrar</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModal: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          </div>
        )
      }
    </>
  );
};