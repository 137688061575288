// Componente para ver el detalle de la aprobacion
import React, { useEffect, useState } from "react";


// Components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// React router importation
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Global function
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";

// React translation import
import { useTranslation } from "react-i18next";

// Urls importation
import { urls } from "../../api-urls/api-urls";




export const AprobacionDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // React translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the params
  const { codSolG } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]); // Estado para cargar la data que viene del servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false });
  const [stateForm, setStateForm] = useState({ empresa: "", division: "", gerencia: "", areaFun: "", fecRequerida: "", formaPago: "", tranfeNombre: "", numCuenta: "", moneda: "", fecSolic: "", solicitante: "", beneficiario: "", codEmpSoli: "", comment: "", primaryAction: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, succes: false });
  const [questionAction, setQuestionAction] = useState("");
  const [controller, setController] = useState(null); // Estado para cargar el controlador para abortar la peticion al servidor


  // Destructuring data server
  const { solGastoDetail, segSolGasto, employeesPermission } = dataServer && dataServer;



  // Id Monedas
  const ID_MONEDAS = {
    0: 'DOP',
    1: 'USD',
    2: 'EUR',
    3: 'TTD'
  };

  const MONEDA_ISO = ID_MONEDAS[stateForm.moneda] ?? 'DOP';


  // Funcion para abrir el modal al dar click en aprobar
  const approveShowModal = () => {
    setStateForm((val) => ({ ...val, primaryAction: "1" }));

    setQuestionAction("Está seguro que desea aprobar esta solicitud?");

    setState((val) => ({ ...val, showModal: true }));
  };


  // Funcion para abrir el modal al dar click en devolver
  const returnShowModal = () => {
    setStateForm((val) => ({ ...val, primaryAction: "2" }));

    setQuestionAction("Está seguro que desea devolver a la etapa anterior?");

    setState((val) => ({ ...val, showModal: true }));
  };


  // Funcion para realizar la peticion al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.anticipo}/${codSolG}/search?language=${language}`, { // Se está utilizando la misma ruta que se utiliza para traer el detalle del anticipo
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para realizar el fetch
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        console.log("Se canceló la peticion al servidor");
        controller.abort();
      }
    };
  }, [language]);


  // useEffect para cargar los datos en el formulario
  useEffect(() => {
    if (solGastoDetail && solGastoDetail.length > 0) {
      const FecReque = solGastoDetail[0]?.FecRequerida ? solGastoDetail[0]?.FecRequerida.slice(0, 10) : "";
      const FecSol = solGastoDetail[0]?.FecIngreso ? solGastoDetail[0]?.FecIngreso.slice(0, 10) : "";
      setStateForm({
        empresa: solGastoDetail[0]?.CodEmpresa.toString() ?? "", division: solGastoDetail[0]?.CodGrupo.toString() ?? "", gerencia: solGastoDetail[0]?.CodGerencia.toString() ?? "",
        areaFun: "", fecRequerida: FecReque, formaPago: solGastoDetail[0]?.ForPago.toString() ?? "", tranfeNombre: solGastoDetail[0]?.EntFondo ?? "", numCuenta: solGastoDetail[0]?.NumCuenta ?? "",
        moneda: solGastoDetail[0]?.IdMoneda.toString() ?? "", fecSolic: FecSol, solicitante: solGastoDetail[0]?.Solicitante ?? "", beneficiario: solGastoDetail[0]?.Beneficiario ?? "", codEmpSoli: solGastoDetail[0]?.CodEmpSolicitante, comment: "", primaryAction: ""
      });
    }
  }, [solGastoDetail]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, succes: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.show]);


  // useEffect para redireccionar a la principal de aprobación
  useEffect(() => {
    if (notification.succes) {
      const timer = setTimeout(() => {
        navigate("/travel-expenses/approvals");
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notification.succes]);



  // Funcion para enviar el formulario al backend
  const onHandleApprove = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.anticipoApprove}/${codSolG}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, showModal: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, succes: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {/* Loading component */}
      {state.isLoading && (
        <div className="load-cont-transp">
          <div className="simple-spinner"><span></span></div>
          <p><strong>{language === "en" ? "Loading..." : "Cargando..."}</strong></p>
        </div>
      )}
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <form onSubmit={onHandleApprove}>
                {/* FILA */}
                <div className="row-flex">
                  {/* Nro. Control */}
                  <div className="col-7"><strong>Nro. Control</strong></div>
                  <div className="col-3"><h4>{codSolG}</h4></div>
                  {/* Etapa */}
                  <div className="col-7"><strong>Etapa</strong></div>
                  <div className="col-3"><strong className="active-class" style={{ textDecoration: 'underline' }}>{solGastoDetail && solGastoDetail[0]?.Etapa}</strong></div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Empresa */}
                  <div className="col-7"><strong>Empresa</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="company"
                      disabled
                      className="wrapper"
                      value={stateForm.empresa}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                      <option value=""></option>
                      <option value="1">Global Medica</option>
                      <option value="2">GMD HealthCare Ltd</option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Division empresa */}
                  <div className="col-7"><strong>División empresa</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="divisionComp"
                      className="wrapper"
                      disabled
                      value={stateForm.division}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                      <option value=""></option>
                      {
                        solGastoDetail && solGastoDetail[0]?.divisionEmp.map((div) => (
                          <option key={div.CodGrupo} value={div.CodGrupo}>{div.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* Gerencia */}
                  <div className="col-7"><strong>Gerencia</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="gerencia"
                      className="wrapper"
                      disabled
                      value={stateForm.gerencia}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
                      <option value=""></option>
                      {
                        solGastoDetail && solGastoDetail[0]?.gerencia.map((gere) => (
                          <option key={gere.CodGerencia} value={gere.CodGerencia}>{gere.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Area funcional */}
                  <div className="col-7"><strong>Area Funcional</strong></div>
                  <div className="col-3">
                    <select
                      name="areaFun"
                      className="wrapper"
                      disabled
                      value={stateForm.areaFun}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, areaFun: target.value }))}>
                      <option value=""></option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Solicitante */}
                  <div className="col-7"><strong>Solicitante</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control wrapper"
                      disabled
                      type="text"
                      name="requestor"
                      value={stateForm.solicitante}
                      readOnly />
                  </div>
                  {/* Beneficiario */}
                  <div className="col-7"><strong>Beneficiario</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control wrapper"
                      disabled
                      type="text"
                      name="benefi"
                      value={stateForm.beneficiario}
                      readOnly />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Fecha solicitud */}
                  <div className="col-7"><strong>Fecha de solicitud</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control wrapper"
                      disabled
                      type="text"
                      name="fecRequest"
                      value={renderDate(stateForm.fecSolic, language)}
                      readOnly />
                  </div>
                  {/* Fecha */}
                  <div className="col-7"><strong>Fecha requerida</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <input
                      className="input-date-styles wrapper"
                      disabled
                      type="date"
                      name="fecRequir"
                      value={stateForm.fecRequerida}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fecRequerida: target.value }))} />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Forma de pago */}
                  <div className="col-7"><strong>Forma de pago</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="formPag"
                      className="wrapper"
                      disabled
                      value={stateForm.formaPago}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, formaPago: target.value }))}>
                      <option value=""></option>
                      <option value="1">Depósito</option>
                      <option value="2">Efectivo</option>
                      <option value="3">Tarjeta de crédito</option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Tranferir a nombre de */}
                  <div className="col-7"><strong>Tranferir a nombre</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control wrapper"
                      disabled
                      type="text"
                      name="tranfName"
                      value={stateForm.tranfeNombre}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, tranfeNombre: target.value }))} />
                  </div>
                  {/* Numero de cuenta */}
                  <div className="col-7"><strong>Número de cuenta</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control wrapper"
                      disabled
                      type="text"
                      name="accountNum"
                      value={stateForm.numCuenta}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, numCuenta: target.value }))} />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Moneda */}
                  <div className="col-7"><strong>Moneda</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="moneda"
                      className="wrapper"
                      disabled
                      value={stateForm.moneda}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                      <option value=""></option>
                      <option value="0">RD$ Pesos</option>
                      <option value="1">US$ Dollar</option>
                      <option value="2">€ Euro</option>
                      <option value="3">TT$ Dollar</option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Comentario */}
                  <div className="col-7"><strong>Comentario</strong></div>
                  <div className="col-82-16">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={stateForm.comment}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                {/* Tabla para mostrar el detalle del anticipo */}
                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Cliente</th>
                        <th>Equipo</th>
                        <th>Origen</th>
                        <th>Destino</th>
                        <th>Total</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        solGastoDetail && solGastoDetail[0]?.detSolGasto.map((det) => (
                          <tr key={det.CodDetSolGasto}>
                            <td>{det.NomCliente}</td>
                            <td>{det.Descripcion === null ? "N/A" : det.Descripcion}</td>
                            <td>{det.CiuOrigen}</td>
                            <td>{det.CiuDestino}</td>
                            <td className="txt-right">{det.Total === null ? formatCurrency(0, MONEDA_ISO) : formatCurrency(det.Total, MONEDA_ISO)}</td>
                            <td className="row-flex justify-center">
                              <button type="button" className="btn-delivery" onClick={() => navigate(`/travel-expenses/approve-detail/${codSolG}/${det.CodDetSolGasto}`)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-journal-plus" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                      <tr>
                        <td colSpan={4} style={{ fontWeight: 'bold' }}>Total</td>
                        <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                          {solGastoDetail && formatCurrency(solGastoDetail[0]?.TotalDetSolG, MONEDA_ISO)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Tabla de seguimiento */}
                <div className="table-container-mobile m-y-medium seg-service-table">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Fecha</th>
                        <th>Etapa Actual</th>
                        <th>Próxima Etapa</th>
                        <th>Responsable</th>
                        <th>Comentario</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        segSolGasto && segSolGasto.map((seg) => (
                          <tr key={seg.CodSegSolGasto}>
                            <td>{renderDate(seg.FecIngreso, language)}</td>
                            <td>{seg.EtapaActual}</td>
                            <td>{seg.ProximaEtapa}</td>
                            <td>{seg.NomEmpleado}</td>
                            <td>{seg.Comentario}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Botones de accion */}
                <div className="row-flex j-content-space-around m-y-medium">
                  {
                    (employeesPermission && employeesPermission[0]?.NivViatico > 1) && (solGastoDetail && !solGastoDetail[0]?.isApproved) && (
                      <>
                        <button type="button" className="btn-primary" onClick={approveShowModal}>Aprobar</button>
                        <button type="button" className="btn-info" onClick={returnShowModal}>Devolver</button>
                        <button type="button" className="btn-danger">Eliminar</button>
                      </>
                    )
                  }
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
                </div>

                {/* Modal para confirmar que desea guardar */}
                {
                  state.showModal && (
                    <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                      <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                        {/* Question mark */}
                        <div className="row-flex m-y-small align-c justify-center">
                          <div className="question-mark-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                            </svg>
                          </div>
                        </div>
                        {
                          !state.isLoading && (
                            <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                              {questionAction}
                            </h5>
                          )
                        }
                        {/* Botones de accion */}
                        <div className="row-flex m-y-medium j-content-space-around">
                          {
                            !state.isLoading && (
                              <>
                                <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                                <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
              </form>
            </>
          )
        }
      </div>
    </>
  );
};