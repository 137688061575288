// este componente es un footer para ir atras

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";




export const FooterComp = () => {

  // GET the translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  return (
    <div className="footer-app">
      <button className="back-btn-footer" type="button" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
    </div>
  );
};