import React, { useContext, useEffect, useState } from "react";

// Components importation
import { SuccessfulNotification } from "../../components/successful-notification/SuccessfulNotificacion";
import { ReloadComp } from "../../components/login-component/ReloadComp";

// React translation importation
import { useTranslation } from "react-i18next";

//Style importation
import "../../auth/views/LoginView.css";

// ISOTIPO
import isotipo from "../../assets/isotipo.png";

// REACT ROUTER DOM IMPORTATION
import { useNavigate } from "react-router-dom";

// URLS API IMPORTATION
import { urls } from "../../api-urls/api-urls";

// USE CONTEXT IMPORTATION
import { UserContext } from "../../context/UserContext";

// SLOGAN IMPORTATION
import SloganEn from "../../assets/slogan-en.png";
import SloganEs from "../../assets/slogan-es.png";



export const LoginView = () => {

  // LANGUAGE
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  const labels = {
    userEmpty: language === "es" ? "Usuario y contraseña no pueden estar vacios." : "Username and Password cannot be empty.",
    invalidEmail: language === "es" ? "Este no es un correo válido." : "This is an invalid email.",
    invalidEmailPasswordBackend: language === "es" ? "Usuario o contraseña invalido." : "Username or Password is invalid.",
    connectionRefuse: language === "es" ? "Ocurrió un error al comunicarse con el servidor." : "There was an error trying to communicate with the server.",
    passwordAlert: language === "es" ? "Debe indicar su contraseña." : "You must enter your password.",
    userNameAlert: language === "es" ? "Debe indicar su usuario." : "You must enter your username."
  };


  //---> Navigate to
  const navigate = useNavigate();

  // ----> STATE DECLARATION
  const [loginState, setLoginState] = useState({ userName: "", userPassw: "", nomDispo: window.navigator.userAgent, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, tokenEquip: localStorage.getItem("UUID_Equip") });
  const [showMsg, setShowMsg] = useState(true);
  const [state, setState] = useState({ notification: false, userNameError: false, userPasswError: false, message: "", modalReload: false });
  // IS LOADING
  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState(true); // Esto muestra el formulario para iniciar sesion
  const [formVerify, setFormVerify] = useState(false);
  const [validateCode, setValidateCode] = useState("");
  const [dataServer, setDataServer] = useState({}); // cargar la informacion del usuario que viene del backend
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false });
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  // PATRON PARA VALIDAR EL CORREO
  const patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // USERCONTEXT
  const { setUserContext } = useContext(UserContext);


  const onLoginHandleSubmit = async (event) => {
    event.preventDefault();

    // VERIFICAR QUE EL USUARIO ENVIE LAS INFORMACIONES CORRECTAS
    if (loginState.userName.trim() === "" && loginState.userPassw.trim() === "") {
      return setState({ notification: !state.notification, userNameError: true, userPasswError: true, message: labels.userEmpty });
    } else if (!patron.test(loginState.userName)) {
      return setState({ notification: !state.notification, userNameError: true, userPasswError: false, message: labels.invalidEmail });
    } else if (loginState.userName.trim() === "") {
      return setState({ notification: !state.notification, userNameError: true, userPasswError: false, message: labels.userNameAlert });
    } else if (loginState.userPassw.trim() === "") {
      return setState({ notification: !state.notification, userNameError: false, userPasswError: true, message: labels.passwordAlert });
    }


    // CONSULTA A LA BD
    try {

      setIsLoading(true);

      setLoadingSpinner(true);

      const result = await fetch(`${urls.authRoute}?language=${language}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(loginState)
      });

      const data = await result.json();
      // console.log(result)
      console.clear();

      if (result.status === 404) {
        setState({ ...state, notification: !state.notification, message: data?.msg });
      } else if (result.status === 401) {
        setState({ ...state, notification: true, message: labels.invalidEmailPasswordBackend });
      } else if (result.status === 200) {
        // SETEAR LA DATA DEL USUARIO EN EL USE CONTEXT
        // console.log(data)

        if (data?.flag === 1) {

          setLoginForm(false); // ocultar el formulario del login
          setFormVerify(true); // Mostrar el formulario de verificar el codigo

          // Cargar la data del backend en el estado
          setDataServer(data?.empInfo);

          setNotification({ show: true, msg: data?.msg, errorNoti: false });

        } else { // login correcto

          setUserContext(data);

          const userInfo = {
            name: data?.nombreCompleto,
            email: data?.correo,
            token: data?.token
          };

          localStorage.setItem("userInfo", JSON.stringify(userInfo));

          return navigate("/home");

        }

      }

      setIsLoading(false);
      setLoadingSpinner(false);

    } catch (error) {
      // setIsLoading(true);
      setState({ ...state, notification: !state.notification, message: labels.connectionRefuse });

      setIsLoading(false);
      setLoadingSpinner(false);
    }
  };



  // Manejador del validador para el codigo de acceso
  const onHandleValidateCode = async (event) => {
    event.preventDefault();

    if (!validateCode.trim()) {
      return alert("Debe proporcionar un codigo");
    }

    if (validateCode.length < 8) {
      return alert("Debe ingresar el codigo correcto");
    }

    const finalObj = { dataServer, loginState };

    try {
      const response = await fetch(`${urls.authRoute}/${validateCode.trim()}/search?language=${language}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      console.clear(); // limpiar la consola

      if (response.status === 200) {
        setUserContext(data);

        const userInfo = {
          name: data?.nombreCompleto,
          email: data?.correo,
          token: data?.token
        };

        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("UUID_Equip", data?.UUID_Equip);

        const lastNavigation = localStorage.getItem("lastNav"); // Obtener la ultima ubicacion donde se navegó
        // NAVEGAR A LAS RUTAS PRIVADAS
        if (lastNavigation) {
          return navigate(lastNavigation);
        } else {
          return navigate("/home");
        }

      } else if (response.status === 404) {

        return setNotification({ show: true, msg: data?.msg, errorNoti: true });

      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para obtener la version
  const onHandleGetVersion = async () => {
    const END_POINT = `${urls.getVersion}`;
    try {
      const response = await fetch(END_POINT, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {

        const VER_LOCAL_S = localStorage.getItem("x-app-g-v");

        if (VER_LOCAL_S) {
          if (VER_LOCAL_S !== data?.ver) {
            setState((val) => ({ ...val, modalReload: true }));
            localStorage.setItem("x-app-g-v", data?.ver);
          }
        } else {
          localStorage.setItem("x-app-g-v", data?.ver);
          // abrir el componente con el mensaje del reload
          setState((val) => ({ ...val, modalReload: true }));
        }

      }

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setState((val) => ({ ...val, notification: false, userNameError: false, userPasswError: false, message: "" }));
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [state.notification]);


  // useEffect para limpiar las demas notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useEffect para verificar si hay token que en vez de que se quede en el login lo redireccione al home
  useEffect(() => {

    if (localStorage.getItem("userInfo")) {
      return navigate("/home");
    }

  }, []);


  // useEffect para recargar el componente en dado caso que exista una nueva version
  useEffect(() => {

    onHandleGetVersion();

  }, []);






  return (
    <>
      {
        // reload componente
        state.modalReload && (
          <ReloadComp />
        )
      }
      {/* Successful component */}
      {notification.show && (<SuccessfulNotification msg={notification.msg} errorNotification={notification.errorNoti} />)}

      {
        showMsg && (
          <div className="msg-notifi-secur">
            <div>
              <strong style={{ color: "rgba(224,28,28,0.788)" }}>{t("LoginView.moti_segur")}</strong>
            </div>
            <div>
              <button className="btn-close-log" onClick={() => setShowMsg(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg>
              </button>
            </div>
          </div>
        )
      }
      <div className="my-container">
        {
          window.screen.width >= 900 && (
            <div className="left-hero">
              {/* MISION, VISION, VALORES */}
              <div className="container-mision">
                <h4 style={{ textAlign: 'center', color: 'var(--third-color)', textDecoration: 'underline' }}>{t("LoginView.mision")}</h4>
                <p style={{ textTransform: 'capitalize', textAlign: 'center', color: 'var(--fourth-color)', padding: '0.2rem 1rem' }}>{t("LoginView.mision_msg")}</p>
              </div>
              {/* VISION */}
              <div className="container-vision m-y-small">
                <h4 style={{ textAlign: 'center', color: 'var(--third-color)', textDecoration: 'underline' }}>{t("LoginView.vision")}</h4>
                <p style={{ textTransform: 'capitalize', textAlign: 'center', color: 'var(--fourth-color)' }}>{t("LoginView.vision_msg")}</p>
              </div>
              {/* VALORES */}
              <div className="container-values m-y-small">
                <h4 style={{ textAlign: 'center', color: 'var(--third-color)', textDecoration: 'underline' }}>{t("LoginView.valores")}</h4>
                <p style={{ textTransform: 'capitalize', textAlign: 'center', color: 'var(--fourth-color)' }}>
                  {t("LoginView.conocimiento")}
                </p>
                <p style={{ textTransform: 'capitalize', textAlign: 'center', color: 'var(--fourth-color)' }}>
                  {t("LoginView.dina_equi")}
                </p>
                <p style={{ textTransform: 'capitalize', textAlign: 'center', color: 'var(--fourth-color)' }}>
                  {t("LoginView.integr")}
                </p>
                <p style={{ textTransform: 'capitalize', textAlign: 'center', color: 'var(--fourth-color)' }}>
                  {t("LoginView.pasion_clien")}
                </p>
                <p style={{ textTransform: 'capitalize', textAlign: 'center', color: 'var(--fourth-color)' }}>
                  {t("LoginView.tot_respon")}
                </p>
              </div>
            </div>
          )
        }
        <div className="right-login">
          {
            state.notification && (
              <div className="error-notification">
                <strong style={{ color: 'var(--first-color)' }}>{state.message}</strong>
              </div>
            )
          }
          <div className="container-login">
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.8rem' }}>
              <img className="logo-global" src={isotipo} alt="Logo Global" />
            </div>

            {/* Spinner loading */}
            {
              loadingSpinner && (
                <div className="load-cont-transp-login">
                  <div className="simple-spinner"><span></span></div>
                  <p><strong>{t("LoginView.cargando")}</strong></p>
                </div>
              )
            }


            {/* Formulario para iniciar sesión */}
            {
              loginForm && (
                <>
                  <h4 style={{ textAlign: 'center', marginBottom: '1.5rem', color: 'var(--first-color)' }}>{t("LoginView.iniciar_sesio")}</h4>
                  <form
                    style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                    onSubmit={onLoginHandleSubmit}>
                    <strong style={{ color: 'var(--first-color)', fontSize: '0.75rem' }}>{t("LoginView.user")}</strong>
                    <div>
                      <input
                        className={state.userNameError ? "invalid-input" : "input-login"}
                        style={{ width: '100%' }}
                        type="text"
                        name="user"
                        value={loginState.userName}
                        onChange={({ target }) => setLoginState((val) => ({ ...val, userName: target.value.trim() }))}
                        placeholder={t("LoginView.place_hold_email")} />
                    </div>
                    <strong style={{ color: 'var(--first-color)', fontSize: '0.75rem' }}>{t("LoginView.contra")}</strong>
                    <div>
                      <input
                        className={state.userPasswError ? "invalid-input" : "input-login"}
                        style={{ width: '100%' }}
                        type="password"
                        name="pass"
                        value={loginState.userPassw}
                        onChange={({ target }) => setLoginState((val) => ({ ...val, userPassw: target.value }))}
                        placeholder={t("LoginView.ing_password")}
                        autoComplete="off" />
                    </div>
                    <div>
                      <button
                        className={`btn-login`}
                        style={{ marginTop: '1rem' }}
                        type="submit" disabled={isLoading}>
                        {t("LoginView.btn_login")}
                      </button>
                    </div>
                  </form>
                </>
              )
            }

            {/* Formulario para verificar el codigo de acceso */}
            {formVerify && (
              <div className="container-val-acc-co">
                <h4 style={{ textAlign: 'center', marginBottom: '1.5rem', color: 'rgba(7, 79, 107, 0.705)' }}>{t("LoginView.codigo_acces")}</h4>

                <form onSubmit={onHandleValidateCode}>
                  <div className="row-flex">
                    <div className="col-100">
                      <input
                        className="verification-code-input"
                        type="text"
                        name="verifyCode"
                        value={validateCode}
                        onChange={({ target }) => setValidateCode(target.value)}
                        placeholder={t("LoginView.codigo")}
                        autoComplete="off" />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn-login"
                      style={{ marginTop: '1rem' }}>
                      {t("LoginView.btn_vali")}
                    </button>
                  </div>
                </form>
              </div>
            )}
            {/* SLOGAN */}
            <div className="container-slogan">
              {language === "es" ? <img style={{ opacity: '0.7' }} className="slogan" src={SloganEs} alt="Slogan Es" /> : <img style={{ opacity: '0.7' }} className="slogan" src={SloganEn} alt="Slogan En" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};