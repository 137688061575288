import React from "react";



export const LineaDataView = () => {

  return (
    <>
      <div className="container display-data">
        <h1>Linea data view</h1>
      </div>
    </>
  );
};