// Componente para listar las relaciones de gastos

import { useEffect, useState } from "react";


// Components import
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// React router importation
import { Link, useLocation, useNavigate } from "react-router-dom";


// url import
import { urls } from "../../api-urls/api-urls";

// global functions import
import { formatCurrency, getTheToken, StepTravelExpenses } from "../../global-functions/globalFunctions";

// react translation
import { useTranslation } from "react-i18next";






export const RelacionGastoPrincipal = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();


  // State definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar
  const [stateZona, setStateZona] = useState([]); // estado para cargar las zonas cuando se filtre por la empresa
  const [stateGeren, setStateGeren] = useState([]); // estado para cargar las gerencias cuando se filtre por la division de empresa
  const [stateUnidad, setStateUnidad] = useState([]); // estado para cargar la unidad cuando se filtre por la gerencia
  const [controller, setController] = useState(null); // estado para cargar el controlador para cuando se aborte la peticion
  const [stateForm, setStateForm] = useState({ empresa: "", zona: "", responsable: "", etapa: "", division: "", gerencia: "", areaFun: "", nroControl: "" }); // formulario para el filtrado de informacion
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [currentPage, setCurrentPage] = useState(1); // para setear el numero de pagina


  // Destructuring data server 
  const { listRelacion, divisionEmpresa, gerencia, unidad, zona } = dataServer && dataServer;


  // Filtrar las etapas de viaticos de la 6 a la 9
  const newStepViaticos = StepTravelExpenses && StepTravelExpenses.filter((via) => via.id_etapa > 4 && via.id_etapa < 10);


  const pageSize = 20; // pageSize


  // Funcion para abrir el modal del filtro
  const onShowFilter = () => {
    if (stateForm.nroControl !== "") {
      setStateForm((val) => ({ ...val, nroControl: "" }));
    }

    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // funcion para realizar el fetch al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.relacionGasto}?language=${language}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&responsable=${stateForm.responsable}&etapa=${stateForm.etapa}&division=${stateForm.division}&gerencia=${stateForm.gerencia}&areaFun=${stateForm.areaFun}&nroControl=${stateForm.nroControl}&page=${currentPage}&pageSize=${pageSize}`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);

  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: "", great: "" });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.show]);


  // useEffect para filtrar la zona por la empresa
  useEffect(() => {
    if (zona && zona.length > 0) {
      const filterZona = zona && zona.filter((zon) => zon.CodEmpresa.toString() === stateForm.empresa);

      setStateZona(filterZona);
    }
  }, [stateForm.empresa]);

  // useEffect para filtrar la gerencia por la division de la empresa
  useEffect(() => {
    if (gerencia && gerencia.length > 0) {
      const filterGerenc = gerencia && gerencia.filter((gere) => gere.CodGrupo.toString() === stateForm.division);

      setStateGeren(filterGerenc);
    }
  }, [stateForm.division]);


  // useEffect para filtrar la unidad por la gerencia
  useEffect(() => {
    if (unidad && unidad.length > 0) {
      const filterUnidad = unidad && unidad.filter((uni) => uni.CodGerencia.toString() === stateForm.gerencia);

      setStateUnidad(filterUnidad);
    }
  }, [stateForm.gerencia]);


  // funcion del manejo del formulario para buscar por el nro de control
  const onSearchByNroControl = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.relacionGasto}?language=${language}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&responsable=${stateForm.responsable}&etapa=${stateForm.etapa}&division=${stateForm.division}&gerencia=${stateForm.gerencia}&areaFun=${stateForm.areaFun}&nroControl=${stateForm.nroControl}&page=${currentPage}&pageSize=${pageSize}`, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, showModal: false }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para manejar el envio del formulario para utilizar los demas filtros
  const onSearchGeneral = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.relacionGasto}?language=${language}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&responsable=${stateForm.responsable}&etapa=${stateForm.etapa}&division=${stateForm.division}&gerencia=${stateForm.gerencia}&areaFun=${stateForm.areaFun}&nroControl=${stateForm.nroControl}&page=${currentPage}&pageSize=${pageSize}`, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, showModal: false }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <div className="row-flex j-content-space-b">
                <div className="col-3">
                  <form onSubmit={onSearchByNroControl} className="row-flex">
                    <div className="col-3">
                      <strong>Nro. Control</strong>
                    </div>
                    <div className="col-39-2">
                      <input
                        className="input-text-control"
                        type="text"
                        name="nroControl"
                        value={stateForm.nroControl}
                        onChange={({ target }) => {
                          if (isNaN(target.value)) {
                            return;
                          }
                          setStateForm((val) => ({ ...val, nroControl: target.value }))
                        }} />
                    </div>
                    <button type="submit" className="btn-success">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </form>
                </div>
                <div>
                  <button type="button" className="btn-success row-flex align-c gap-small" onClick={() => navigate("/travel-expenses/create-expense-report")}>
                    {t("Viaticos.nuevo")}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Button de search */}
              <ButtonSearch onOpenFilter={onShowFilter} />

              {/* tabla para mostrar todas las relaciones */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>{t("Viaticos.nro_control")}</th>
                      <th>{t("Viaticos.empresa")}</th>
                      <th>{t("Viaticos.responsable")}</th>
                      <th>{t("Viaticos.etapa")}</th>
                      <th>{t("Viaticos.moneda")}</th>
                      <th>{t("Viaticos.anti_soli")}</th>
                      <th>{t("Viaticos.anti_aprob")}</th>
                      <th>{t("Viaticos.rela_soli")}</th>
                      <th>{t("Viaticos.rela_aprob")}</th>
                      <th>{t("Viaticos.comentario")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      listRelacion && listRelacion.map((rela) => (
                        <tr key={rela.CodSolGasto}>
                          <td>
                            <Link className="link-tag" to={`/travel-expenses/expense-report-detail/${rela.CodSolGasto}`}>{rela.CodSolGasto}</Link>
                          </td>
                          <td>{rela.NomEmpresa}</td>
                          <td>{rela.NomEmpleado}</td>
                          <td>{rela.Etapa}</td>
                          <td className="txt-center">{rela.Moneda}</td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(rela.Anticipo, 'USD')}
                          </td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(rela.AntAprobado, 'USD')}
                          </td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(rela.Relacionado, 'USD')}
                          </td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(rela.RelAprobada, 'USD')}
                          </td>
                          <td>{rela.Comment}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {
                state.showModal && (
                  <div className="background-modal">
                    <div className="container-modal-filter-travel-expenses">
                      <form onSubmit={onSearchGeneral} className="m-y-medium">
                        {/* FILA */}
                        <div className="row-flex">
                          {/* Empresa */}
                          <div className="col-5"><strong>{t("Viaticos.empresa")}</strong></div>
                          <div className="col-30">
                            <select
                              name="company"
                              value={stateForm.empresa}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                              <option value=""></option>
                              <option value="1">Global Medica</option>
                              <option value="2">GMD Healthcare Ltd</option>
                            </select>
                          </div>
                          {/* Zona */}
                          <div className="col-5"><strong>{t("Viaticos.zona")}</strong></div>
                          <div className="col-30">
                            <select
                              name="zona"
                              disabled={stateForm.empresa === ""}
                              className={stateForm.empresa === "" ? "wrapper" : ""}
                              value={stateForm.zona}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, zona: target.value }))}>
                              <option value=""></option>
                              {
                                stateZona && stateZona.map((zon) => (
                                  <option key={zon.CodZona} value={zon.CodZona}>{zon.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Responsable */}
                          <div className="col-5"><strong>{t("Viaticos.responsable")}</strong></div>
                          <div className="col-30">
                            <input
                              className="input-text-control"
                              type="text"
                              name="responsable"
                              value={stateForm.responsable}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, responsable: target.value }))} />
                          </div>
                          {/* Etapa */}
                          <div className="col-5"><strong>{t("Viaticos.etapa")}</strong></div>
                          <div className="col-30">
                            <select
                              name="step"
                              value={stateForm.etapa}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, etapa: target.value }))}>
                              <option value=""></option>
                              {
                                newStepViaticos && newStepViaticos.map((step) => (
                                  <option key={step.id_etapa} value={step.id_etapa}>
                                    {language === "en" ? step.nombreEn : step.nombre}
                                  </option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Division empresa */}
                          <div className="col-5"><strong>{t("Viaticos.divi_empr")}</strong></div>
                          <div className="col-30">
                            <select
                              name="diviCompan"
                              value={stateForm.division}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                              <option value=""></option>
                              {
                                divisionEmpresa && divisionEmpresa.map((divi) => (
                                  <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                          {/* Gerencia */}
                          <div className="col-5"><strong>{t("Viaticos.gerencia")}</strong></div>
                          <div className="col-30">
                            <select
                              name="geren"
                              className={stateForm.division === "" ? "wrapper" : ""}
                              disabled={stateForm.division === ""}
                              value={stateForm.gerencia}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
                              <option value=""></option>
                              {
                                stateGeren && stateGeren.map((geren) => (
                                  <option key={geren.CodGerencia} value={geren.CodGerencia}>{geren.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Area funcional */}
                          <div className="col-5"><strong>{t("Viaticos.area_fun")}</strong></div>
                          <div className="col-30">
                            <select
                              name="areaFun"
                              className={stateForm.gerencia === "" ? "wrapper" : ""}
                              disabled={stateForm.gerencia === ""}
                              value={stateForm.areaFun}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, areaFun: target.value }))}>
                              <option value=""></option>
                              {
                                stateUnidad && stateUnidad.map((uni) => (
                                  <option key={uni.CodUnidad} value={uni.CodUnidad}>{uni.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        {/* Botones de accion */}
                        <div className="row-flex j-content-space-around m-y-medium">
                          <button type="submit" className="btn-success">{t("Viaticos.buscar")}</button>
                          <button type="reset" className="btn-secondary" onClick={onShowFilter}>{t("Viaticos.cerrar")}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </>
  );
};