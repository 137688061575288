// este componente es para crear un equipo para un cliente

import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";



// react router dom importation
import { useLocation, useNavigate, useParams } from "react-router-dom";

// urls import
import { urls } from "../../api-urls/api-urls";

// global functions
import { getTheToken } from "../../global-functions/globalFunctions";

// react translation
import { useTranslation } from "react-i18next";


export const CreateEquipment = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codCliente } = useParams();


  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [filtCities, setFiltCities] = useState([]); // estado para filtrar las ciudades
  const [filtState, setFiltState] = useState([]); // estado para filtrar las provincias
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({
    modelo: "", cliente: "", alias: "", ubicacion: "", pais: "", provincia: "", ciudad: "", verSoftware: "", tecInsta: "", tecInsta1: "", tecInsta2: "",
    tecInsta3: "", tecF1: "", tecF2: "", gerenServ: "", numFact: "", serial: "", numCarpe: "", numSist: "", psiCode: "", GON: "", po: "", fecGaranProve: "",
    fecGaranGlob: "", fecDespa: "", fecAduan: "", fecDesti: "", fecInsta: "", contacInsta: "", cargo: "", fecEOL: "", garanExte: "", observa: "", ventResp: false,
    ventManObra: false, proyec: false, activo: true, pendiente: false
  });
  const [stateRend, setStateRend] = useState({ modelo: "", cliente: "" }); // estado para renderizar los nombre de los tecnicos, modelo y cliente
  const [state, setState] = useState({ dataVisible: false, isLoading: false, unauthorized: false, modalModel: false, modalFilCusto: false, showModalSave: false, loadingData: false, questionAction: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // destructuring data server
  const { customers, provincias, ciudades, models, employees, customer } = dataServer && dataServer;


  // buscar los modelos
  const modelsFound = models && models.filter((mo) => mo.Descripcion.toLowerCase().includes(stateRend.modelo.toLowerCase()));

  // buscar los clientes
  const customersFound = codCliente === "equipment" ? customers && customers.filter((cus) => cus.Nombre.toLowerCase().includes(stateRend.cliente.toLowerCase())) : "";


  // console.log(customers)

  // funcion para agregar el modelo seleccionado al estado
  const onHandleAddModel = (model) => {
    setStateForm((val) => ({ ...val, modelo: model }));
    setState((val) => ({ ...val, modalModel: false }));
  };

  // funcion para agregar el cliente seleccionado al estado
  const onHandleAddCustomer = (codCli) => {
    setStateForm((val) => ({ ...val, cliente: codCli }));
    setState((val) => ({ ...val, modalFilCusto: false }));
  };


  // funcion para abrir el modal de buscar los clientes
  const onHandleOpenFiltCustomer = () => {
    if (codCliente === "equipment") {
      setState((val) => ({ ...val, modalFilCusto: true }));
    } else {
      return;
    }
  };


  // funcion para abrir el modal al guardar
  const onHandleSaveModal = () => {
    if (stateForm.modelo === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar el modelo.", error: true }));
    }

    if (stateForm.cliente === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar el cliente.", error: true }));
    }

    if (stateForm.pais === "" || stateForm.provincia === "" || stateForm.tecF1 === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar los campos obligatorios (*)", error: true }));
    }

    setState((val) => ({ ...val, showModalSave: true, questionAction: "Está seguro que desea crear este equipo?" }));
  };


  // funcion para enviar el formulario al servidor
  const onHandleForm = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loadingData: true }));

      const response = await fetch(`${urls.createEquipment}?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, loadingData: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para realizar la peticion al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.createEquipment}/${codCliente}/search?language=${language}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para realizar la peticion al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codCliente, language]);


  // useEffect para cargar la informacion en el stateForm dependiendo del parametro
  useEffect(() => {
    if (codCliente !== "equipment") {
      if (customer && customer.length > 0) {
        setStateForm((val) => ({
          ...val, cliente: customer[0]?.Codcliente, pais: customer[0]?.CodPais?.toString() ?? "", provincia: customer[0]?.CodEstado?.toString() ?? "",
          ciudad: customer[0]?.CodCiudad?.toString() ?? ""
        }));

        setStateRend((val) => ({ ...val, cliente: customer[0]?.Nombre }));
      }
    }
  }, [codCliente, customer]);


  // useEffect para renderizar el modelo
  useEffect(() => {
    if (models && models.length > 0) {
      const findModel = models && models.find((mod) => mod.CodModelo?.toString() === stateForm.modelo.toString());

      setStateRend((val) => ({ ...val, modelo: findModel?.Descripcion }));
    }
  }, [stateForm.modelo]);


  // useEffect para renderizar el cliente
  useEffect(() => {
    if (codCliente === "equipment") {

      if (customers && customers.length > 0) {
        const findCustomer = customers && customers.find((cus) => cus.CodCliente === stateForm.cliente);

        setStateRend((val) => ({ ...val, cliente: findCustomer?.Nombre }));
      }

    }
  }, [stateForm.cliente]);


  // useEffect para filtrar las provincias
  useEffect(() => {
    if (provincias && provincias.length > 0) {
      const foundProvincias = provincias && provincias.filter((pro) => pro.CodPais?.toString() === stateForm.pais);

      setFiltState(foundProvincias);
    }
  }, [stateForm.pais]);


  // useEffect para filtrar las ciudades
  useEffect(() => {
    if (ciudades && ciudades.length > 0) {
      const foundCities = ciudades && ciudades.filter((city) => city.CodEstado?.toString() === stateForm.provincia);

      setFiltCities(foundCities);
    }
  }, [stateForm.provincia]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para redirigir
  useEffect(() => {
    if (notification.success) {
      if (codCliente === "equipment") {
        const timer = setTimeout(() => {
          navigate("/equipments");
        }, 4000);

        return () => {
          clearTimeout(timer);
        };
      } else {
        const timer = setTimeout(() => {
          navigate(`/customers/customer-modify/${codCliente}`);
        }, 4000);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [notification.success]);




  return (
    <div className="container display-data">
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // unauthorized component
        state.unauthorized && (<Error403View />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        state.dataVisible && (
          <form onSubmit={onHandleForm}>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Modelo</strong></div>
              <div className="col-80-94">
                <input
                  className="input-text-control"
                  type="text"
                  name="model"
                  onFocus={() => setState((val) => ({ ...val, modalModel: true }))}
                  value={stateRend.modelo}
                  readOnly />
              </div>
              <div className="col-7"><strong>Cliente</strong></div>
              <div className="col-80-94">
                <input
                  className="input-text-control"
                  type="text"
                  name="customer"
                  onFocus={onHandleOpenFiltCustomer}
                  value={stateRend.cliente}
                  readOnly />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Alias</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="alias"
                  value={stateForm.alias}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, alias: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>Ubicación</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="location"
                  value={stateForm.ubicacion}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, ubicacion: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>País</strong> <strong className="required">(*)</strong></div>
              <div className="col-3">
                <select
                  name="country"
                  value={stateForm.pais}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, pais: target.value }))}>
                  <option value=""></option>
                  <option value="3">Islas del Caribe</option>
                  <option value="1">República Dominicana</option>
                </select>
              </div>
              <div className="col-7"><strong>Provincia</strong> <strong className="required">(*)</strong></div>
              <div className="col-3">
                <select
                  name="state"
                  disabled={stateForm.pais === ""}
                  value={stateForm.provincia}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, provincia: target.value }))}>
                  <option value=""></option>
                  {
                    filtState && filtState.map((pro) => (
                      <option key={pro.CodEstado} value={pro.CodEstado}>{pro.Nombre}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Ciudad</strong></div>
              <div className="col-3">
                <select
                  name="city"
                  disabled={stateForm.provincia === ""}
                  value={stateForm.ciudad}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, ciudad: target.value }))}>
                  <option value=""></option>
                  {
                    filtCities && filtCities.map((cit) => (
                      <option key={cit.CodCiudad} value={cit.CodCiudad}>{cit.Nombre}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-7"><strong>Versión Software</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="verSoft"
                  value={stateForm.verSoftware}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, verSoftware: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Técnico Instalador</strong></div>
              <div className="col-3">
                <select
                  name="tecInst"
                  value={stateForm.tecInsta}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, tecInsta: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-7"><strong>Técnico Instalador 1</strong></div>
              <div className="col-3">
                <select
                  name="tecInsta1"
                  value={stateForm.tecInsta1}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, tecInsta1: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Técnico Instalador 2</strong></div>
              <div className="col-3">
                <select
                  name="tecInsta2"
                  value={stateForm.tecInsta2}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, tecInsta2: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-7"><strong>Técnico Instalador 3</strong></div>
              <div className="col-3">
                <select
                  name="tecInsta3"
                  value={stateForm.tecInsta3}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, tecInsta3: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Técnico F1</strong> <strong className="required">(*)</strong></div>
              <div className="col-3">
                <select
                  name="tecF1"
                  value={stateForm.tecF1}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, tecF1: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-7"><strong>Técnico F2</strong></div>
              <div className="col-3">
                <select
                  name="tecF2"
                  value={stateForm.tecF2}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, tecF2: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Gerente de Servicio</strong></div>
              <div className="col-3">
                <select
                  name="gerenServ"
                  value={stateForm.gerenServ}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, gerenServ: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-7"><strong>Número de Factura</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="numFact"
                  value={stateForm.numFact}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, numFact: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Serial</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="serial"
                  value={stateForm.serial}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, serial: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>Número de carpeta</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="numCarp"
                  value={stateForm.numCarpe}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, numCarpe: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Número de Sistema</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="numSist"
                  value={stateForm.numSist}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, numSist: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>PSI Scode</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="psiScode"
                  value={stateForm.psiCode}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, psiCode: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>GON</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="GON"
                  value={stateForm.GON}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, GON: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>PO #</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="PO"
                  value={stateForm.po}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, po: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Fecha de Garantía Proveedor a Global</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecGaraProGlo"
                  value={stateForm.fecGaranProve}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecGaranProve: target.value }))} />
              </div>
              <div className="col-7"><strong>Fecha de Garantía Global al cliente</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecGaraGlob"
                  value={stateForm.fecGaranGlob}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecGaranGlob: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Fecha Despacho</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecDespa"
                  value={stateForm.fecDespa}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecDespa: target.value }))} />
              </div>
              <div className="col-7"><strong>Fecha Aduana</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecAdua"
                  value={stateForm.fecAduan}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecAduan: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Fecha Destino (Cliente)</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecDestin"
                  value={stateForm.fecDesti}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecDesti: target.value }))} />
              </div>
              <div className="col-7"><strong>Fecha Instalación</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecInsta"
                  value={stateForm.fecInsta}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecInsta: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Contacto Instalación</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="contactInsta"
                  value={stateForm.contacInsta}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, contacInsta: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>Cargo</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="cargo"
                  value={stateForm.cargo}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, cargo: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Fecha EOL</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecEOL"
                  value={stateForm.fecEOL}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecEOL: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Garantía Extendida</strong></div>
              <div className="col-80-94">
                <textarea
                  className="textarea-description"
                  name="garanExt"
                  value={stateForm.garanExte}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, garanExte: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Observación</strong></div>
              <div className="col-80-94">
                <textarea
                  className="textarea-description"
                  name="observa"
                  value={stateForm.observa}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, observa: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex align-c">
              <div className="col-7"><strong>Venta de Repuesto</strong></div>
              <div className="col-9">
                <input
                  className="input-control-checkbox"
                  type="checkbox"
                  name="venRepu"
                  checked={stateForm.ventResp}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, ventResp: target.checked }))} />
              </div>
              <div className="col-7"><strong>Venta de Mano de Obra</strong></div>
              <div className="col-9">
                <input
                  className="input-control-checkbox"
                  type="checkbox"
                  name="venManObra"
                  checked={stateForm.ventManObra}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, ventManObra: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Activo</strong></div>
              <div className="col-9">
                <input
                  className="input-control-checkbox"
                  type="checkbox"
                  name="active"
                  checked={stateForm.activo}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, activo: target.checked }))} />
              </div>
            </div>

            {/* botones de accion */}
            <div className="row-flex j-content-space-around m-y-small">
              <button type="button" className="btn-primary" onClick={onHandleSaveModal}>Guardar</button>
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
            </div>

            {
              state.showModalSave && (
                <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>
                  <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                    {/* Question mark */}
                    <div className="row-flex m-y-small align-c justify-center">
                      <div className="question-mark-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                        </svg>
                      </div>
                    </div>
                    <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                      {state.questionAction}
                    </h5>
                    {/* Botones de accion */}
                    <div className="row-flex m-y-medium j-content-space-around">
                      <button type="submit" className="btn-success" disabled={state.loadingData}>
                        {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                      </button>
                      <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>No</button>
                    </div>
                  </div>
                </div>
              )
            }
          </form>
        )
      }

      {
        // modal para abrir los modelos
        state.modalModel && (
          <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalModel: false }))}>
            <div className="container-modal-filter-customer p-1" onClick={(event) => event.stopPropagation()}>
              <div>
                <input
                  className="input-text-control"
                  type="text"
                  name="searchModel0"
                  value={stateRend.modelo}
                  onChange={({ target }) => setStateRend((val) => ({ ...val, modelo: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="m-y-small container-filter-cust">
                {
                  modelsFound && modelsFound.map((mo) => (
                    <div className="row-flex border-bottom hover-class" key={mo.CodModelo}>
                      <div className="col-80"><strong>{mo.Descripcion}</strong></div>
                      <div className="col-5 row-flex flex-end">
                        <button type="button" style={{ cursor: "pointer" }} onClick={() => onHandleAddModel(mo.CodModelo)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                            <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }

      {
        // modal para filtrar los clientes
        state.modalFilCusto && (
          <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalFilCusto: false }))}>
            <div className="container-modal-filter-customer p-1" onClick={(event) => event.stopPropagation()}>
              <div>
                <input
                  className="input-text-control"
                  type="text"
                  name="filtCustom"
                  value={stateRend.cliente}
                  onChange={({ target }) => setStateRend((val) => ({ ...val, cliente: target.value }))} />
              </div>
              <div className="m-y-small container-fiter-cust">
                {
                  customersFound && customersFound.map((cus) => (
                    <div className="row-flex border-bottom hover-class" key={cus.CodCliente}>
                      <div className="col-80"><strong>{cus.Nombre}</strong></div>
                      <div className="col-5 row-flex flex-end">
                        <button type="button" style={{ cursor: "pointer" }} onClick={() => onHandleAddCustomer(cus.CodCliente)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                            <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};