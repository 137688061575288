import React, { useEffect, useState } from "react";


// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";


// React router dom importation
import { useLocation, useNavigate, useParams } from "react-router-dom";


// urls importation
import { urls } from "../../api-urls/api-urls";

// Global function importation
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// React translation
import { useTranslation } from "react-i18next";
import { SuccessfulNotification } from "../../components/successful-notification/SuccessfulNotificacion";



export const ShipToLogisticViewDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codShipTo } = useParams();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [questionAction, setQuestionAction] = useState("");
  const [stateForm, setStateForm] = useState({ nombre: "", avenida: "", correo: "", codTel: "", telefono: "", activo: false });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, success: false });


  // Destructuring data server
  const { empEnvia } = dataServer && dataServer;

  // PATRON PARA VALIDAR EL CORREO
  const patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  // Funcion para abrir el modal al dar click en guardar
  const onHandleSaveModal = () => {
    if (!patron.test(stateForm.correo)) {
      return setNotification({ show: true, msg: "Este no es un correo valido", errorNoti: true, success: false });
    }
    setState((val) => ({ ...val, showModal: true }));
    setQuestionAction("Está seguro que desea actualizar esta información?");
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.dataShipTo}/${codShipTo}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 400) {
          // 
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          // no está autorizado a ver esta info
          setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

  }, [language, codShipTo]);


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if (empEnvia && empEnvia.length > 0) {
      setStateForm({ nombre: empEnvia[0]?.Nombre, avenida: empEnvia[0]?.Direccion, correo: empEnvia[0]?.Correo, codTel: empEnvia[0]?.CodTelefono, telefono: empEnvia[0]?.Telefono, activo: empEnvia[0]?.Activo });
    }
  }, [empEnvia]);


  // useEffect para limpiar las notificacion
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, success: false });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // Funcion para manejar el submit
  const onSaveInfo = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.dataShipTo}/${codShipTo}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification({ show: true, msg: data?.msg, errorNoti: false, success: true });
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 400) {
        setNotification({ show: true, msg: data?.msg, errorNoti: true, success: false });
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification({ show: true, msg: data?.msg, errorNoti: true, success: false });
      }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* Notification component */}
      {notification.show && (<SuccessfulNotification msg={notification.msg} errorNotification={notification.errorNoti} />)}
      {/* Unauthorized component */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <div className="row-flex justify-center p-1">
              {/* Tarjeta */}
              <div className="card-shadow col-88-89">
                <form onSubmit={onSaveInfo}>
                  {/* FILA */}
                  <div className="row-flex">
                    {/* Nombre */}
                    <div className="col-7"><strong>Nombre</strong></div>
                    <div className="col-3">
                      <input
                        className="input-text-control"
                        type="text"
                        name="nameShipTo"
                        value={stateForm.nombre}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, nombre: target.value }))}
                        autoComplete="off" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Avenida/Calle */}
                    <div className="col-7"><strong>Avenida/Calle</strong></div>
                    <div className="col-83-87">
                      <textarea
                        className="textarea-description"
                        name="avenue"
                        value={stateForm.avenida}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, avenida: target.value }))}
                        autoComplete="off" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Correo */}
                    <div className="col-7"><strong>Correo</strong></div>
                    <div className="col-3">
                      <input
                        className="input-text-control"
                        type="text"
                        name="email"
                        value={stateForm.correo}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, correo: target.value }))}
                        autoComplete="off" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Telefono */}
                    <div className="col-7"><strong>Telefono</strong></div>
                    <div className="row-flex col-3">
                      {/* CodTelefono */}
                      <div className="col-5">
                        <input
                          className="input-text-control"
                          type="text"
                          name="codTel"
                          value={stateForm.codTel}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, codTel: target.value }))}
                          autoComplete="off" />
                      </div>
                      {/* Telefono */}
                      <div className="col-4">
                        <input
                          className="input-text-control"
                          type="text"
                          name="tele"
                          value={stateForm.telefono}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, telefono: target.value }))} />
                      </div>
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    <div className="col-7"><strong>Activo</strong></div>
                    <div className="col-7">
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="active"
                        checked={stateForm.activo}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, activo: target.checked }))} />
                    </div>
                  </div>

                  {/* Ingresado por */}
                  <div className="m-y-small">
                    {/* FILA */}
                    <div className="row-flex">
                      {/* Ingresado por */}
                      <div className="col-7"><strong>Ingresado por</strong></div>
                      <div className="col-3"><span>{empEnvia && empEnvia[0]?.nomEmpIngreso}</span></div>
                      {/* Modificado por */}
                      <div className="col-7"><strong>Modificado por</strong></div>
                      <div className="col-3"><span>{empEnvia && empEnvia[0]?.nomEmpModifi}</span></div>
                    </div>
                    {/* FILA */}
                    <div className="row-flex">
                      {/* Fecha ingreso */}
                      <div className="col-7"><strong>Fecha ingreso</strong></div>
                      <div className="col-3"><span>{empEnvia && renderDate(empEnvia[0]?.FecIngreso, language)}</span></div>
                      {/* Fecha modificacion */}
                      <div className="col-7"><strong>Fecha modificacion</strong></div>
                      <div className="col-3"><span>{empEnvia && renderDate(empEnvia[0]?.FecModificacion, language)}</span></div>
                    </div>
                  </div>

                  {/* Botones de acción */}
                  <div className="m-y-medium">
                    <div className="row-flex gap-medium j-content-space-around">
                      <button type="button" className="btn-primary" onClick={onHandleSaveModal}>Guardar</button>
                      <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
                    </div>
                  </div>

                  {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
                  {
                    state.showModal && (
                      <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModal: false }))}>
                        <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                          {/* Question mark */}
                          <div className="row-flex m-y-small align-c justify-center">
                            <div className="question-mark-container">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                              </svg>
                            </div>
                          </div>
                          <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                            {questionAction}
                          </h5>
                          {/* Botones de accion */}
                          <div className="row-flex m-y-medium j-content-space-around">
                            <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                            <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </form>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};