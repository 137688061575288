// componente para generar el OKTS de las ordenes de comercial


export const CreateOKTSComponent = ({ onClose, arrOKTS, arrFirm }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  return (
    <div className="background-modal" onClick={onClose}>
      <div className="container-modal-OKTS" onClick={(event) => event.stopPropagation()}>
        <form>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Freight Forwarder</strong></div>
            <div className="col-65">
              <select name="" id="">
                <option value=""></option>
              </select>
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Nombre</strong></div>
            <div className="col-65">
              <input
                className="input-text-control"
                type="text"
                name="name" />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Dirección</strong></div>
            <div className="col-65">
              <textarea
                className="textarea-description"
                name="address" />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Teléfono</strong></div>
            <div className="col-65">
              <input
                className="input-text-control"
                type="text"
                name="telephone" />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Correo</strong></div>
            <div className="col-65">
              <input
                className="input-text-control"
                type="text"
                name="email" />
            </div>
          </div>

          <hr className="m-y-small" />

          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Transporte Local</strong></div>
            <div className="col-65">
              <textarea
                className="textarea-description"
                name="localTrans" />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Días en aduana</strong></div>
            <div className="col-65">
              <input
                className="input-text-control"
                type="text"
                name="customsDay" />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Firmante</strong></div>
            <div className="col-65">
              <select name="sign">
                <option value=""></option>
                {
                  arrFirm && arrFirm.map((firm) => (
                    <option key={firm.CodEmpleado} value={firm.CodEmpleado}>{firm.NomEmpleado}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Generar para</strong></div>
            <div className="col-65">
              <select name="" id="">
                <option value=""></option>
                <option value="1">Biotech</option>
                <option value="2">Global</option>
                <option value="3">Cliente</option>
              </select>
            </div>
          </div>

          {/* botones de accion */}
          <div className="row-flex j-content-space-around m-y-medium">
            <button type="submit" className="btn-primary">Guardar</button>
            <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
          </div>
        </form>
      </div>
    </div>
  );
};