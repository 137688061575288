
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate, renderFormatTime } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";


export const ServiceReportsDetailView = ({ grupo }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET useNavigate
  const navigate = useNavigate();

  // GET useLocation
  const location = useLocation();

  // GET params
  const { codRepServ } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false);
  const [stateForm, setStateForm] = useState({
    prioridad: "", ingInvolucrados: "", fecDesde: "", fecHasta: "", descripcion: "",
    serialTuboInst: "", serialTransInst: "", serialTuboDes: "", serialTransDes: "",
    horas: 0, horExtras: 0, horExtNocturna: 0, horExtFeriado: 0, horViaje: 0, totHoras: 0, totHorExtras: 0
  });

  // DESTRUCTURING DATA SERVER
  const { serviceReport, equitAndCust, employees, employeeRepSer, serFechaReport, HorasTotales } = dataServer && dataServer;


  const onHandleCalHor = (newVal) => {
    if (isNaN(newVal)) {
      return;
    }
    const totalHoras = Number(stateForm.horas) + Number(stateForm.horExtras) + Number(stateForm.horExtNocturna) + Number(stateForm.horExtFeriado) + Number(newVal);

    setStateForm((val) => ({ ...val, horViaje: newVal, totHoras: totalHoras }));
  };


  // descargar el reporte de servicio
  const onHandleDownloadServiceReport = async (print = "") => {
    try {
      const response = await fetch(`${urls.serviceReport}?codRepServ=${codRepServ}&action=2&language=${language}&print=${print}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear enlace oculto
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = blobURL;
        a.download = `service-report-${codRepServ}`;

        document.body.appendChild(a);

        // simular click en el enlace para iniciar la descarga
        a.click();


        // limpiar la url y el enlace de la descargar
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);
      }


    } catch (error) {
      console.log(error);
    }
  };


  // funcion para traer la data del servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setIsLoading(true);
      const response = await fetch(`${urls.serviceReport}/${codRepServ}/search?language=${language}`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 400) {
        // bad request
      } else if (response.status === 500) {
        // ocurrió un error en el servidor
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codRepServ, language]);



  // useEffect para cargar las horas en el stateForm
  // useEffect(() => {
  //   if (HorasTotales && HorasTotales.length > 0) {

  //     const totalHoras = HorasTotales[0]?.THorNormal + HorasTotales[0]?.THorExtras + HorasTotales[0]?.THorNocturna + HorasTotales[0]?.THorFeriado + Number(stateForm.horViaje);

  //     setStateForm((val) => ({
  //       ...val,

  //        totHoras: totalHoras
  //     }));
  //   }
  // }, [HorasTotales])


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if ((serviceReport && serviceReport.length > 0) && (HorasTotales && HorasTotales.length > 0)) {

      const totalHoras = HorasTotales[0]?.THorNormal + HorasTotales[0]?.THorExtras + HorasTotales[0]?.THorNocturna + HorasTotales[0]?.THorFeriado + serviceReport[0]?.HorViaje;

      setStateForm((val) => ({
        ...val, prioridad: serviceReport[0]?.Prioridad.toString(), ingInvolucrados: "", descripcion: serviceReport[0]?.Descripcion,
        serialTuboInst: serviceReport[0]?.SerTubo, serialTransInst: serviceReport[0]?.SerTransductor, serialTuboDes: serviceReport[0]?.SerTuboR,
        serialTransDes: serviceReport[0]?.SerTransductorR, horViaje: serviceReport[0]?.HorViaje, totHoras: totalHoras, horas: HorasTotales[0]?.THorNormal,
        horExtFeriado: HorasTotales[0]?.THorFeriado, horExtNocturna: HorasTotales[0]?.THorNocturna, horExtras: HorasTotales[0]?.THorExtras
      }));

    }
  }, [serviceReport, HorasTotales]);


  return (
    <>
      {
        isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        <form>
          {/* FILA 1 */}
          <div className="row-flex">
            {/* REPORTE DE SERVICIO TECNICO */}
            <div className="col-5 col-sm-50">
              <strong style={{ color: 'var(--danger-color)' }}>{t("serviceCard.report_serv_tec")}</strong>
            </div>
            <div className="col-4 col-sm-50 fake-input">
              <span>{serviceReport && serviceReport[0]?.NumReporte}</span>
            </div>
            {/* FECHA */}
            <div className="col-5 col-sm-50">
              <strong>{t("serviceCard.date")}</strong>
            </div>
            <div className="flex-auto col-sm-50 fake-input">
              <span>{serviceReport && renderDate(serviceReport[0]?.FecReporte, language)}</span>
            </div>
          </div>

          {/* FILA 2 */}
          <div className="row-flex">
            {/* CLIENTE */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.customer")}</strong>
            </div>
            <div className="col-sm-70-mod text-hidden-over">
              <span>{serviceReport && serviceReport[0]?.NomCliente}</span>
            </div>
          </div>

          {/* FLA 3 */}
          <div className="row-flex">
            {/* EQUIPO */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.equipment")}</strong>
            </div>
            <div className="col-4 col-sm-70-mod">
              <span>{serviceReport && serviceReport[0]?.Equipo}</span>
            </div>
            {/* MODELO */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.modelo")}</strong>
            </div>
            <div className="flex-auto col-sm-70-mod">
              <span>{serviceReport && serviceReport[0]?.Modelo}</span>
            </div>
          </div>
          <hr className="m-y-small" />

          {/* FILA 4 */}
          <div className="row-flex">
            {/* ESTADO DEL EQUIPO DESPUES DEL SERVICIO */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.estado_equip")}</strong>
            </div>
            <div className="col-75 col-sm-70-mod row-flex">
              {/* Operativo */}
              <div className="col-4 col-sm-80 row-flex gap-medium">
                <div className="input-control bg-green circle-temp">
                  <input
                    type="radio"
                    value="0"
                    name="temperature"
                    checked={stateForm.prioridad === "0"}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, prioridad: target.value }))} />
                </div>
                <div><span>{t("serviceCard.operativo")}</span></div>
              </div>

              {/* Operativo con fallas */}
              <div className="col-3 col-sm-80 row-flex gap-medium">
                <div className="input-control bg-yellow circle-temp">
                  <input
                    type="radio"
                    value="1"
                    name="temperature"
                    checked={stateForm.prioridad === "1"}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, prioridad: target.value }))} />
                </div>
                <div><span>{t("serviceCard.operativo_fallas")}</span></div>
              </div>

              {/* Parado */}
              <div className="col-4 col-sm-80 row-flex gap-medium">
                <div className="input-control bg-red circle-temp">
                  <input
                    type="radio"
                    value="4"
                    name="temperature"
                    checked={stateForm.prioridad === "4"}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, prioridad: target.value }))} />
                </div>
                <div>
                  <span>{t("serviceCard.equip_detenido")}</span>
                </div>
              </div>
            </div>
          </div>

          {/* FILA 5 */}
          {
            (serviceReport && !serviceReport[0]?.CodEmpGerente) && (
              <div className="row-flex">
                {/* INGENIEROS INVOLUCRADOS */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.ing_involucrados")}</strong>
                </div>
                <div className="col-4 col-sm-50">
                  <select
                    name="ingenInvolucrados"
                    value={stateForm.ingInvolucrados}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ingInvolucrados: target.value }))}>
                    <option value=""></option>
                    {
                      employees && employees.map((emp) => (
                        <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-10">
                  <button type="button" className="btn-add-date gap-small row-flex align-c">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>

                  </button>
                </div>
              </div>
            )
          }

          {/* FILA para mostrar la lista de los empleados que estan en el reporte */}
          {
            employeeRepSer && employeeRepSer.length > 0 && (
              <div className="row-flex m-y-small d-column">
                {
                  employeeRepSer && employeeRepSer.map((emp) => (
                    <div key={emp.CodEmpleado} className="col-6 col-100-sm">
                      <div className="row-flex gap-small align-c m-l-small">
                        <div>
                          <button type="button" className="btn-delete">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                            </svg>
                          </button>
                        </div>
                        <div>
                          <span>{emp.NomEmpleado}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }

          {/* FILA 6 */}
          {
            (serviceReport && !serviceReport[0]?.CodEmpGerente) && (
              <div className="row-flex">
                {/* FECHA DEL SERVICIO (Desde) */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.fec_servicio")}</strong>
                </div>
                <div className="col-4 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecDesde"
                    value={stateForm.fecDesde}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecDesde: target.value }))} />
                </div>
                {/* Fecha del servicio (Hasta) */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.fec_hasta")}</strong>
                </div>
                <div className="col-5 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecHasta"
                    value={stateForm.fecHasta}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecHasta: target.value }))} />
                </div>
                <div className="col-7 col-100-sm flex-mob-btn-add-date">
                  <button type="button" className="btn-add-date gap-small row-flex align-c">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>
                    {t("serviceCard.add_fec")}
                  </button>
                </div>
              </div>
            )
          }

          <hr className="m-y-small" />

          {/* Tabla para mostrar las fechas */}
          {
            serFechaReport && serFechaReport.length > 0 && (
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th></th>
                      <th>{t("serviceCard.fec_ini")}</th>
                      <th>{t("serviceCard.fec_fin")}</th>
                      <th>{t("serviceCard.hor_normal")}</th>
                      <th>{t("serviceCard.hor_ext_diurnas")}</th>
                      <th>{t("serviceCard.hor_ext_nocturnas")}</th>
                      <th>{t("serviceCard.hor_ext_feriados")}</th>
                      <th>{t("serviceCard.total_hor")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      serFechaReport && serFechaReport.map((fec) => (
                        <tr key={fec.CodRepSerFecha}>
                          <td></td>
                          <td>{renderDate(fec.FecInicio, language)} / {renderFormatTime(fec.FecInicio)}</td>
                          <td>{renderDate(fec.FecFin, language)} / {renderFormatTime(fec.FecFin)}</td>
                          <td className="txt-center">{fec.HorNormal}</td>
                          <td className="txt-center">{fec.HorExtras}</td>
                          <td className="txt-center">{fec.HorExtNocturna}</td>
                          <td className="txt-center">{fec.HorExtFeriado}</td>
                          <td className="txt-center">{fec.TotalHoras}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }


          {/* FILA 7 */}
          <div className="row-flex m-y-small">
            {/* DESCRIPCION DEL TRABAJO */}
            <div className="col-5"><strong>Descripción del Trabajo</strong></div>
            <div className="col-80">
              <textarea
                className="textarea-description"
                name="descripcionDelTrabajo"
                value={stateForm.descripcion}
                onChange={({ target }) => setStateForm((val) => ({ ...val, descripcion: target.value }))}
                placeholder={language === "en" ? "Describe the work done..." : "Describa el trabajo que realizó..."} />
            </div>
          </div>

          {/* FILA 8 */}
          <div className="row-flex">
            {/* NUMERO SERIAL DEL TUBO */}
            <div className="col-5 col-sm-50">
              <strong>{t("serviceCard.num_serial")}</strong>
            </div>
            <div className="col-4 col-sm-50">
              <input
                className="input-text-control"
                name="serialTubo"
                type="text"
                value={stateForm.serialTuboInst}
                onChange={({ target }) => setStateForm((val) => ({ ...val, serialTuboInst: target.value }))}
                placeholder={language === "en" ? "Serial number tube if apply..." : "Número de Serial del tubo si aplica..."}
                autoComplete="off" />
            </div>
            {/* Numero serial tubo desinstalado */}
            <div className="col-5 col-4 col-sm-50">
              <strong>{t("serviceCard.num_serial_tub_desins")}</strong>
            </div>
            <div className="col-4 col-4 col-sm-50">
              <input
                className="input-text-control"
                type="text"
                name="serTubDes"
                value={stateForm.serialTuboDes}
                onChange={({ target }) => setStateForm((val) => ({ ...val, serialTuboDes: target.value }))}
                autoComplete="off" />
            </div>
          </div>

          <div className="row-flex">
            {/* NUMERO SERIAL TRANSDUCTOR instalado */}
            <div className="col-5 col-sm-50"><strong>{t("serviceCard.num_serial_trans")}</strong></div>
            <div className="col-4 col-sm-50">
              <input
                className="input-text-control"
                name="serialTransductor"
                type="text"
                value={stateForm.serialTransInst}
                onChange={({ target }) => setStateForm((val) => ({ ...val, serialTransInst: target.value }))}
                placeholder={language === "en" ? "Serial number transducer if apply..." : "Número de Serial del transductor si aplica..."}
                autoComplete="off" />
            </div>
            {/* Numero serial transductor desinstalado */}
            <div className="col-5 col-sm-50">
              <strong>{t("serviceCard.num_serial_trans_desins")}</strong>
            </div>
            <div className="col-4 col-sm-50">
              <input
                className="input-text-control"
                type="text"
                name="serTransDes"
                value={stateForm.serialTransDes}
                onChange={({ target }) => setStateForm((val) => ({ ...val, serialTransDes: target.value }))}
                autoComplete="off" />
            </div>
          </div>

          {/* Tabla para mostrar las partes que se utilizaron en el reporte */}
          <div className="table-container-mobile m-y-medium">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>Cantidad</th>
                  <th>Parte</th>
                  <th>Descripción</th>
                  <th>Cantidades</th>
                  <th>Cantidades pendientes por justificar</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* FILA */}
          <div className="row-flex flex-end">
            <div className="col-42-87 col-100-sm row-flex flex-end">
              {/* Servicio normal */}
              <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>Servicio normal</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="hours"
                  value={stateForm.horas}
                  readOnly />
              </div>
              {/* Servicio H extra diurnas */}
              <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>Servicio H extra diurnas (de 07:00 pm a 9:00 pm)</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="hExtras"
                  value={stateForm.horExtras}
                  readOnly />
              </div>
              {/* Servicio H extra nocturnas */}
              <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>Servicio H extra nocturnas (de 9:00 pm a 7:00 am)</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="hExtNoct"
                  value={stateForm.horExtNocturna}
                  readOnly />
              </div>
              {/* Servicio H/Extra sabados, domingos y feriados */}
              <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>Servicio H/Extra sabados, domingos y feriados</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="hExtFeri"
                  value={stateForm.horExtFeriado}
                  readOnly />
              </div>
              {/* Horas en Viajes */}
              <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>En viajes</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="horViaj"
                  value={stateForm.horViaje}
                  onChange={({ target }) => onHandleCalHor(target.value)} />
              </div>
              {/* Total Horas */}
              <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>Total horas</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control wrapper"
                  type="text"
                  name="totalHor"
                  value={stateForm.totHoras}
                  readOnly />
              </div>
            </div>
          </div>


          {/* BOTONES DE ACCION */}
          <div className="row-flex j-content-space-around m-y-medium">
            <button className="btn-primary" type="submit">{t("serviceCard.guardar")}</button>
            <button className="btn-success" type="button" onClick={() => onHandleDownloadServiceReport("1")}>Imprimir</button>
            <button className="btn-info" type="button" onClick={() => onHandleDownloadServiceReport("0")}>Solicitar firma</button>
            <button className="btn-secondary" type="reset" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
          </div>
        </form>
      </div>
    </>
  );
};