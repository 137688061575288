import React from "react";


export const TipEquipDataView = () => {


  return (
    <>
      <div className="container display-data">
        <h1>Tipo de Equipo</h1>
      </div>
    </>
  );
};