// Este componente se utiliza para imprimir las notas de entrega
import React, { useEffect, useState } from "react";

// react router dom importation
import { useParams } from "react-router-dom";


// Importacion logo de global y GMD
import logoGlobal from "../../assets/logo-global.png";

// Url importation
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";


export const NotaEntregaDetailPrint = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useParams
  const { notEntr } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]);
  const [notEntrega, setNotEntrega] = useState([]); // Estado para cargar la informacion de las notas de entrega en el estado


  // Destructuring data server
  const { notaEntrega, detSolRep } = dataServer && dataServer;


  // useEffect para traer los datos del servidor
  useEffect(() => {
    const fetchDataServer = async () => {
      try {
        const response = await fetch(`${urls.notaEntrega}/${notEntr}/search?language=${language}&print=1`, {
          method: "POST",
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        }

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

  }, [notEntr, language]);


  // useEffect para cargar la informacion de las notas de entrega en el estado
  useEffect(() => {
    if (notaEntrega && notaEntrega.length > 0) {
      setNotEntrega(notaEntrega);
    }
  }, [notaEntrega]);

  console.log(notEntrega)

  return (
    <>
      <div className="container-print-area border">
        {/* FILA */}
        <div className="row-flex j-content-space-b">
          {/* Imagen */}
          <div className="col-3 logo-not-entr border">
            <img src={logoGlobal} alt="" />
          </div>
          {/* Detalle del conduce y la solicitud */}
          <div className="col-45 border">
            <div className="row-flex flex-end gap-small">
              <h4># Conduce: </h4>
              {notEntr}
              <h4># Sol.: </h4>
              {notEntrega[0]?.direccion[0]?.CodSolRepuesto}
            </div>
          </div>
        </div>

        {/* Titulo conduce */}
        <h3 className="m-y-medium txt-center" style={{ textDecoration: 'underline' }}>Conduce</h3>

        {/* FILA */}
        <div className="row-flex">
          {/* Cliente */}
          <div className="col-7 border">
            <strong>Cliente</strong>
          </div>
          <div className="col-83-87 border">
            <span>{notEntrega[0]?.direccion[0]?.Nombre}</span>
          </div>
        </div>

        {/* FILA */}
        <div className="row-flex">
          {/* Dirección */}
          <div className="col-7 border">
            <strong>Dirección</strong>
          </div>
          <div className="col-83-87 border">
            <span>{notEntrega[0]?.direccion[0]?.AveCalle} {notEntrega[0]?.direccion[0]?.EdiQta} {notEntrega[0]?.direccion[0]?.UrbSector} {notEntrega[0]?.direccion[0]?.Ciudad} {notEntrega[0]?.direccion[0]?.Estado}</span>
          </div>
        </div>

        {/* FILA */}
        <div className="row-flex">
          {/* Ubicación */}
          <div className="col-7 border">
            <strong>Ubicación</strong>
          </div>
          <div className="col-83-87 border">
            <span>{notEntrega[0]?.equipo[0]?.Ubicacion}</span>
          </div>
        </div>

        {/* FILA */}
        <div className="row-flex">
          {/* Equipo */}
          <div className="col-7">
            <strong>Equipo</strong>
          </div>
          <div className="col-39-29 border">
            <span></span>
          </div>
          {/* SID */}
          <div className="col-8 border">
            <strong>SID</strong>
          </div>
          <div className="col-3 border">
            <span>{notEntrega[0]?.equipo[0]?.NumSistema}</span>
          </div>
        </div>

        {/* FILA */}
        <div className="row-flex">
          {/* Tecnico */}
          <div className="col-7">
            <strong>Técnico</strong>
          </div>
          <div className="col-39-29">
            <span>{notEntrega[0]?.direccion[0]?.NomEmpleado}</span>
          </div>
          {/* Tipo */}
          <div className="col-8 border">
            <strong>Tipo</strong>
          </div>
          <div className="col-3 border">
            <span></span>
          </div>
        </div>

        {/* FILA */}
        <div className="row-flex">
          {/* Id Servicio */}
          <div className="col-7">
            <strong>Id. Servicio</strong>
          </div>
          <div className="col-39-29 border">
            <span>{notEntrega[0]?.direccion[0]?.CodServicio}</span>
          </div>
          {/* Nro. Factura */}
          <div className="col-8 border">
            <strong>Nro. Factura</strong>
          </div>
          <div className="col-3 border">
            <span></span>
          </div>
        </div>


        {/* Tabla para mostrar la informacion de las partes */}

      </div>
    </>
  );
};