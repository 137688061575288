import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { PartDetailModify } from "../../components/logistic-components/PartDetailModify";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// REACT ROUTER IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";

// react translation
import { useTranslation } from "react-i18next";



export const QuotationDetail = () => {

  // GET THE LANGUAGE
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // pathname location
  const location = useLocation();

  // navigate
  const navigate = useNavigate();

  // get params
  const { codSolRepuesto, cotNum } = useParams();


  // ETAPAS
  const APRO_COT = 15; // Aprobacion de Cotizacion
  const COBR_COT = 16; // Cobrar cotizacion
  const COT_COBRADA = 17; // Cotizacion Cobrada

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // controlador para cancelar la data del servidor
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, success: false });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, serverError: false, unauthorized: false });
  const [statePartModify, setStatePartModify] = useState({ showModal: false, solRep: 0, codDetSol: 0 });
  const [stateForm, setStateForm] = useState({
    tipCot: "", moneda: "", cambioMoneda: 1, responsable: "", contacto: "", atencion: "", correo: "", referencia: "", ubicacion: "", ordCompra: "", instFactu: "", instCobranza: "", instDespacho: "", accion: "0", comment: "", primaryAction: "1",
    hora: "", cosHora: "", manObra: 0, multipli: 1, viatico: 0, viaje: 0, descuento: 0, descuentoTxt: "", observ: "", gasto: 0, itbis: 0, cambio: 0, tiempoEntr: "", tiempoEntrTxt: "", valid: "", sitioEntr: "", garantia: "", condiCred: "", formaPago: "",
    otroPago: "", nota0: false, nota1: false, nota2: false, nota3: false, nota4: false, nota5: false, totMultRD: 0, totMultUS: 0, fleteRD: 0, fleteUS: 0, subTot2RD: 0, subTot2US: 0, totDescuentoRD: 0, totDescuentoUS: 0, totGastoRD: 0, totGastoUS: 0,
    subTotalRD: 0, subTotalUS: 0, totItbisRD: 0, totItbisUS: 0, totGeneralRD: 0, totGeneralUS: 0, codZona: 0, totalPartDolar: 0, totalPartRD: 0, etapa: 0
  });

  // DESTRUCTURING DATA SERVER
  const { solRepuesto, operationEmployee, detSolRep, contacto, empPermi, quotationTable, segCotizacion } = dataServer && dataServer;


  let TotalPartDolar = 0;
  let TotalPartRD = 0;

  let hor = 0;
  let costHor = 0;
  let manObraRD = parseFloat(stateForm.cambio) * parseFloat(stateForm.manObra);
  let subTotalUS = (parseFloat(stateForm.totalPartDolar).toFixed(2) + parseFloat(stateForm.manObra)) - parseFloat(stateForm.totDescuentoUS);
  let subTotalRD = (parseFloat(stateForm.totalPartRD) + parseFloat(manObraRD)) - parseFloat(stateForm.totDescuentoRD);
  let itbisUSD = stateForm.itbis === "" ? 0 : (subTotalUS * parseFloat(stateForm.itbis)) / 100;
  let itbisRD = stateForm.itbis === "" ? 0 : (subTotalRD * parseFloat(stateForm.itbis)) / 100;
  let descuento = 0;
  let TotalUS = subTotalUS + itbisUSD;
  let TotalRD = subTotalRD + itbisRD;


  // Validar correo
  const patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // current date
  // const currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  const onHandleChangeCambio = (newVal) => {
    if (isNaN(newVal)) {
      return;
    }
    setStateForm((val) => ({ ...val, cambio: newVal }));
  };



  // funcion para realizar los diferentes calculos
  const onHandleCalc = (newVal = 0, type = 0) => {
    // calcular el precio por hora

    if (isNaN(newVal)) {
      return;
    }


    switch (type) {
      case 1: // calcular el monto de horas
        // hor = newVal;
        costHor = hor * stateForm.cosHora;
        // manObra = costHor;
        manObraRD = costHor * stateForm.cambio;
        setStateForm((val) => ({ ...val, hora: newVal, manObra: newVal * stateForm.cosHora }));
        break;
      case 2: // calcular el costo de las horas
        setStateForm((val) => ({ ...val, cosHora: newVal, manObra: newVal, subTot2US: costHor, subTot2RD: manObraRD }));
        break;
      case 3: // descuento
        descuento = newVal * (TotalPartDolar + stateForm.manObra) / 100;
        setStateForm((val) => ({ ...val, descuento: newVal, totDescuentoUS: descuento }));
        break;
      case 4: // calcular el itbis
        setStateForm((val) => ({ ...val, itbis: newVal }));
        break;
    }

  };


  // Funcion para manejar el submit del formulario
  const onSaveQuotation = async (event) => {
    event.preventDefault();

    // Deben seleccionar el tipo de cotizacion
    if (stateForm.tipCot === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar el tipo de cotización", errorNoti: true }));
    }

    // Deben seleccionar la moneda
    if (stateForm.moneda === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar la moneda", errorNoti: true }));
    }

    // Debe indicar la referencia
    if (stateForm.referencia.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la referencia", errorNoti: true }));
    }

    // El responsable deben seleccionarlo
    if (stateForm.responsable === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar el responsable", errorNoti: true }));
    }

    console.log("Se envio el formulario")

    try {
      const response = await fetch(`${urls.getListQuotations}/${codSolRepuesto}/${cotNum}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });


      const data = await response.json();

      if (response.status === 201) { // se crea la cotizacion
        navigate(`/post-sales/quotation-detail/${codSolRepuesto}/${data?.numCot}`);
        setNotification({ show: true, msg: data?.msg, errorNoti: false, great: true });
      } else if (response.status === 200) { // se actualiza la cotizacion
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };



  // Funcion para abrir el modal para modificar las partes
  const openModalModifyPart = (codSolRep, codDetSolRep) => {
    setStatePartModify((val) => ({ ...val, solRep: codSolRep, codDetSol: codDetSolRep, showModal: true }));
  };


  // Funcion para cerrar el modal de modificacion de las partes
  const onCloseModal = () => {
    setStatePartModify((val) => ({ ...val, showModal: !statePartModify.showModal }));
  };

  // Funcion para renderizar las etapas de cotizacion
  const renderQuotationStep = (idEstatus, language) => {
    let NOM_ETAPA = "";

    switch (idEstatus) {
      case 0: // Oportunidad de venta
        NOM_ETAPA = language === "en" ? " / Sales oportunity" : " / Oportunidad de ventas";
        break;
      case -1: // Cotizando
        NOM_ETAPA = language === "en" ? " / Quotation" : " / Cotizando";
        break;
      case 1: // Aprobada
        NOM_ETAPA = language === "en" ? " / Approved" : " / Aprobada";
        break;
      case 2: // Rechazada
        NOM_ETAPA = language === "en" ? " / Rejected" : " / Rechazada";
        break;
    }

    return NOM_ETAPA;
  };



  // Textos automaticos Tiempos de entrega
  const TIEMP_ESTANDAR = stateForm.codZona === 1 ?
    `Los bienes objeto de una orden de compra serán entregados en un máximo de quince (15) días hábiles luego de recibida su orden de compra y confirmación de su forma de pago.`
    : `7-10 business days.`

  const TIEMP_INMEDIATO = stateForm.codZona === 1 ?
    `Los bienes objeto de una orden de compra serán entregados Inmediatamente Previa Confirmación de Disponibilidad de Especialista Técnico y confirmación de su forma de pago.`
    : `Immediately after the confirmation of availability of the Engineer and compliance with the payment method.`;

  const TIEMP_EJECUTADO = stateForm.codZona === 1 ? `Trabajo ejecutado.` : `Job completed.`;

  // Textos automaticos Formas de pago
  const ORDEN_SERVICIO = stateForm.codZona === 1 ?
    `Orden de servicio\nFavor hacer depósito en Cta. a nombre de Global Medica Dominicana SA, en el Banco BHD Leon\nCuenta en pesos: Cta. Cte. Nro. 109 9005-001-5\nCuenta en dólares\nCta. de ahorros Nro. 109 9005-002-3\nLuego enviar el comprobante de depósito al e-mail epena@global-medica.com`
    : `Service Order\nPlease send the proof of payment to this e-mail epena@global-medica.com`;

  const CONTADO = stateForm.codZona === 1 ?
    `100% de anticipo\nFavor hacer depósito en Cta. a nombre de Global Medica Dominicana SA\nEn el Banco BHD Leon\nCuenta en pesos\nCta. Cte. Nro.: 109 9005-001-5\nCuenta en dólares\nCta. de ahorros Nro.: 109 9005-002-3\nLuego enviar el comprobante de depósito al e-mail epena@global-medica.com`
    : `100% Cash in advance\nPlease send the proof of payment to this e-mail epena@global-medica.com`;

  const ANTICIPO = stateForm.codZona === 1 ?
    `70% de anticipo\n30% contra presentación de factura, antes del despacho del almacén de Global Medica Dominicana SA\nFavor hacer depósito en Cta. a nombre de Global Medica Dominicana SA\nEn el Banco BHD Leon\nCuenta en pesos\nCta. Cte. Nro.: 109 9005-001-5\nCuenta en dólares\nCta. de ahorros Nro.: 109 9005-002-3\nLuego enviar el comprobante de depósito al e-mail epena@global-medica.com`
    : `70% Cash in advance\n30% Delivery order\nPlease send the proof of payment to this e-mail epena@global-medica.com`;

  const MOROSO = stateForm.codZona === 1 ?
    `Pago de deuda con más de 90 días vencido y 100% de anticipo de esta cotización\nFavor hacer depósito en Cta. a nombre de Global Medica Dominicana SA\nEn el Banco BHD Leon\nCuenta en pesos\nCta. Cte. Nro.: 109 9005-001-5\nCuenta en dólares\nCta. de ahorros Nro.: 109 9005-002-3\nLuego enviar el comprobante de depósito al e-mail epena@global-medica.com`
    : `100% Cash in advance of this quote, and payment of invoice pending of more than 90 days\nPlease send the proof of payment to this e-mail epena@global-medica.com`;


  // funcion para solicitar info al serv
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getListQuotations}/${codSolRepuesto}/${cotNum}/search?language=${language}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      } else if (response.status === 500) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codSolRepuesto, language, cotNum]);

  // useEffect para cargar los datos de la tabla de cotizacion
  useEffect(() => {
    if (quotationTable && quotationTable.length > 0) {
      setStateForm((val) => ({
        ...val, tipCot: quotationTable[0]?.IdTipCotizacion, moneda: quotationTable[0]?.IdMoneda, cambioMoneda: quotationTable[0]?.CamMoneda, responsable: quotationTable[0]?.CodEmpResponsable, contacto: "", atencion: quotationTable[0]?.Atencion, correo: quotationTable[0]?.Fax, referencia: quotationTable[0]?.Referencia, ubicacion: quotationTable[0]?.Ubicacion ?? "", ordCompra: quotationTable[0]?.OrdenCompra ?? "", instFactu: quotationTable[0]?.Facturacion ?? "", instCobranza: quotationTable[0]?.Cobranza ?? "", instDespacho: quotationTable[0]?.Despacho ?? "",
        hora: quotationTable[0]?.Hora, cosHora: quotationTable[0]?.CosHora, manObra: quotationTable[0]?.ManObra, multipli: quotationTable[0]?.Multiplicador, viatico: 0, viaje: 0, descuento: quotationTable[0]?.Descuento, descuentoTxt: "", observ: "", gasto: 0, itbis: quotationTable[0]?.Iva, cambio: quotationTable[0]?.CamRep, tiempoEntr: "", tiempoEntrTxt: quotationTable[0]?.Tiempo, valid: quotationTable[0]?.Validez, sitioEntr: quotationTable[0]?.Entrega, garantia: quotationTable[0]?.Garantia, condiCred: quotationTable[0]?.CondCredito ?? "", formaPago: "",
        otroPago: quotationTable[0]?.OtroPago, nota0: quotationTable[0]?.Nota0, nota1: quotationTable[0]?.Nota1, nota2: quotationTable[0]?.Nota2, nota3: quotationTable[0]?.Nota3, nota4: quotationTable[0]?.Nota4, nota5: quotationTable[0]?.Nota5, totMultRD: 0, totMultUS: 0, fleteRD: 0, fleteUS: 0, subTot2RD: 0, subTot2US: 0, totDescuentoRD: 0, totDescuentoUS: 0, totGastoRD: 0, totGastoUS: 0,
        subTotalRD: 0, subTotalUS: 0, totItbisRD: 0, totItbisUS: 0, totGeneralRD: 0, totGeneralUS: 0, codZona: solRepuesto[0]?.CodZona, etapa: quotationTable[0]?.IdEtapa
      }));
    }
  }, [quotationTable]);


  // useEffect para traer algunos datos si el codCotizacion es null
  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0) {
      if (cotNum === "new") {
        // la cotizacion es nueva
        const IMPUESTO = {
          1: "18", // RD
          6: "", // caribe
          7: "12.5" // trinidad
        };

        setStateForm((val) => ({
          ...val, moneda: solRepuesto[0]?.IdMoneda, cambioMoneda: solRepuesto[0]?.CamMoneda, cosHora: solRepuesto[0]?.Hora ?? 0, referencia: solRepuesto[0]?.Falla, codZona: solRepuesto[0]?.CodZona, itbis: IMPUESTO[solRepuesto[0]?.CodZona] ?? ""
        }));
      }
    }
  }, [solRepuesto]);

  // useEffect para cargar el correo del contacto
  useEffect(() => {
    if (contacto && contacto.length > 0 && stateForm.contacto !== "") {
      const contactFound = contacto && contacto.find((cont) => cont.CodContacto.toString() === stateForm.contacto);

      setStateForm((val) => ({ ...val, correo: contactFound?.Correo ?? "", atencion: contactFound?.Nombre ?? "" }));
    }
  }, [stateForm.contacto]);


  // useEffect para mostrar los mensajes de tiempo de entrega
  useEffect(() => {
    let MENSAJE = "";
    switch (stateForm.tiempoEntr) {
      case "0": // Estandar
        MENSAJE = TIEMP_ESTANDAR;
        break;
      case "1": // Inmediato
        MENSAJE = TIEMP_INMEDIATO;
        break;
      case "2": // Ejecutado
        MENSAJE = TIEMP_EJECUTADO;
        break;
      default:
        MENSAJE = "";
    }

    setStateForm((val) => ({ ...val, tiempoEntrTxt: MENSAJE }));
  }, [stateForm.tiempoEntr]);


  // useEffect para mostrar los mensajes de forma de pago
  useEffect(() => {
    let MENSAJE = "";
    switch (stateForm.formaPago) {
      case "0": // Orden de servicio
        MENSAJE = ORDEN_SERVICIO;
        break;
      case "1": // Contado
        MENSAJE = CONTADO;
        break;
      case "2": // Anticipo
        MENSAJE = ANTICIPO;
        break;
      case "3": // Moroso
        MENSAJE = MOROSO;
        break;
      default:
        MENSAJE = "";
    }

    setStateForm((val) => ({ ...val, otroPago: MENSAJE }));
  }, [stateForm.formaPago]);


  // useEffect para limpiar las notificationes
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useEffect para recargar la data 
  useEffect(() => {
    if (notification.success) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [notification.success]);


  // descuento
  useEffect(() => {
    if (!isNaN(stateForm.descuento) && Number(stateForm.descuento) > 0) {
      setStateForm((val) => ({ ...val, descuentoTxt: "Descuento" }));
    } else {
      setStateForm((val) => ({ ...val, descuentoTxt: "", descuento: "" }));
    }
  }, [stateForm.descuento]);


  // useEffect para iterar las partes y sumar
  useEffect(() => {
    if (detSolRep && detSolRep.length > 0) {
      let totPartDolar = 0;
      let totPartRD = 0;

      detSolRep.forEach((part) => {
        totPartDolar += (parseFloat(part.PreCotizacion).toFixed(2) / parseFloat(stateForm.cambioMoneda)) * part.Cantidad * part.Multiplicador;
        totPartRD += (parseFloat(part.PreCotizacion).toFixed(2) / stateForm.cambioMoneda) * part.Cantidad * part.Multiplicador * parseFloat(stateForm.cambio);

        // setSt
      });

      setStateForm((val) => ({ ...val, totalPartDolar: totPartDolar, totalPartRD: totPartRD }));
    }
  }, [detSolRep, stateForm.cambio]);


  // console.log();
  // (parseFloat(TotalPartDolar) + parseFloat(stateForm.manObra)) - parseFloat(stateForm.totDescuentoUS)



  return (
    <>
      {/* Notification component */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)}
      {/* Loading component */}
      {
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onSaveQuotation}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* NRO. COTIZACION */}
                <div className="col-8"><strong>Cotización</strong></div>
                <div className="col-9"><span style={{ fontSize: '1rem', fontWeight: 'bold', color: 'var(--first-color)' }}>{solRepuesto && solRepuesto[0]?.CodCotizacion}</span></div>
                {/* SOLICITUD */}
                <div className="col-9"><strong>Solicitud</strong></div>
                <div className="col-9"><Link className="link-tag">{solRepuesto && solRepuesto[0]?.CodSolRepuesto}</Link></div>
                {/* CLIENTE */}
                <div className="col-9"><strong>Cliente</strong></div>
                <div className="col-43"><Link className="link-tag">{solRepuesto && solRepuesto[0]?.NomCliente}</Link></div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* FECHA */}
                <div className="col-8"><strong>Fecha</strong></div>
                <div className="col-9"><span>{quotationTable && renderDate(quotationTable[0]?.FecCotizacion, language)}</span></div>
                {/* ID. SERVICIO */}
                <div className="col-9"><strong>Id. Servicio</strong></div>
                <div className="col-9"><Link className="link-tag" to={`/service/service-call/${solRepuesto && solRepuesto[0]?.CodServicio}`}>{solRepuesto && solRepuesto[0]?.CodServicio}</Link></div>
                {/* EQUIPO */}
                <div className="col-9"><strong>Equipo</strong></div>
                <div className="col-43"><Link className="link-tag">{solRepuesto && solRepuesto[0]?.Equipo}</Link></div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* ESPECIALISTA TECNICO */}
                <div className="col-8"><strong>Especialista Técnico</strong></div>
                <div className="col-3"><span>{solRepuesto && solRepuesto[0]?.NomIngeniero}</span></div>
                {/* ETAPA */}
                <div className="col-9"><strong>Etapa</strong></div>
                <div className="col-39-2"><span style={{ fontWeight: 'bold', color: 'var(--green-color)', textDecoration: 'underline' }}>
                  {quotationTable && quotationTable.length > 0 ? `${quotationTable[0]?.NomEtapa} ${renderQuotationStep(quotationTable[0]?.IdEstatus, language)}` : `${language === "en" ? "Quotation" : "Cotización"} ${renderQuotationStep(-1, language)}`}
                </span>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-8"><strong>Observación</strong></div>
                <div className="col-87">
                  <span>{solRepuesto && solRepuesto[0]?.ObsSolicitud}</span>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* TIPO COTIZACION */}
                <div className="col-8"><strong>Tipo Cotización</strong></div>
                <div className="col-9">
                  <select
                    name="tipoCotizacion"
                    value={stateForm.tipCot}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipCot: target.value }))}>
                    <option value=""></option>
                    <option value="1">Mano de Obra</option>
                    <option value="2">Repuestos</option>
                    <option value="3">Local/Inventario</option>
                    <option value="4">CIF</option>
                    <option value="5">FOB</option>
                  </select>
                </div>
                {/* CUENTAS POR COBRAR */}
                <div className="col-9"><strong>Cuentas Por Cobrar</strong></div>
                <div className="col-9"><Link className="link-tag">Estado de Cuenta</Link></div>
                {/* RESPONSABLE */}
                <div className="col-9"><strong>Responsable</strong></div>
                <div className="col-4">
                  <select
                    name="responsable"
                    value={stateForm.responsable}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, responsable: target.value }))}>
                    <option value=""></option>
                    {
                      operationEmployee && operationEmployee.map((empl) => (
                        <option key={empl.CodEmpleado} value={empl.CodEmpleado}>{empl.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* MONEDA */}
                <div className="col-8"><strong>Moneda</strong></div>
                <div className="col-9">
                  <select
                    name="moneda"
                    value={stateForm.moneda}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                    <option value=""></option>
                    <option value="0">Dólar</option>
                    <option value="1">Euro</option>
                    <option value="2">RD</option>
                  </select>
                </div>
                {/* CAMBIO */}
                <div className="col-9"><strong>Cambio</strong></div>
                <div className="col-9">
                  <input
                    className="input-text-control"
                    type="text"
                    name="cambMon"
                    value={stateForm.cambioMoneda}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, cambioMoneda: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* CONTACTO */}
                <div className="col-8"><strong>Contacto</strong></div>
                <div className="col-3">
                  <select
                    name="contacto"
                    value={stateForm.contacto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, contacto: target.value }))}>
                    <option value=""></option>
                    {
                      contacto && contacto.map((cont) => (
                        <option key={cont.CodContacto} value={cont.CodContacto}>{cont.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* ATENCION */}
                <div className="col-8"><strong>Atención</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="atten"
                    value={stateForm.atencion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, atencion: target.value }))}
                    autoComplete="off" />
                </div>
                {/* CORREO */}
                <div className="col-9"><strong>Correo</strong></div>
                <div className="col-34-8">
                  <input
                    className="input-text-control"
                    type="text"
                    name="email"
                    value={stateForm.correo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, correo: target.value }))}
                    placeholder="example@example.com"
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* REFERENCIA */}
                <div className="col-8"><strong>Referencia</strong></div>
                <div className="col-3">
                  <textarea
                    className="textarea-description"
                    name="referencia"
                    value={stateForm.referencia}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, referencia: target.value }))}
                    placeholder={language === "en" ? "Place the quotation description..." : "Indique la descripcion de la cotización..."}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* NRO. FACTURA */}
                <div className="col-8"><strong>Número Factura</strong></div>
                <div className="col-87">
                  <Link className="link-tag">{quotationTable && quotationTable[0]?.CodFactura}</Link>
                </div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* UBICACION */}
                <div className="col-8"><strong>Ubicación</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="location"
                    value={stateForm.ubicacion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ubicacion: target.value }))}
                    autoComplete="off" />
                </div>
                {/* ORDEN DE COMPRA */}
                <div className="col-9"><strong>Orden de Compra</strong></div>
                <div className="col-34-8">
                  <input
                    className="input-text-control"
                    type="text"
                    name="purchaseOrder"
                    value={stateForm.ordCompra}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ordCompra: target.value }))}
                    placeholder={language === "en" ? "Customer's Purchase Order..." : "Orden de Compra del Cliente..."}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* INSTRUCCION DE FACTURACION */}
                <div className="col-8"><strong>Instrucción de Facturación</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="instrFacturacion"
                    value={stateForm.instFactu}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, instFactu: target.value }))}
                    placeholder={language === "en" ? "Place the billing instruction for the customer..." : "Indique las instrucciones de facturación para el cliente..."}
                    autoComplete="off" />
                </div>
                {/* INSTRUCCION DE COBRANZA */}
                <div className="col-9"><strong>Instrucción de Cobranza</strong></div>
                <div className="col-34-8">
                  <input
                    className="input-text-control"
                    type="text"
                    name="instruCobranza"
                    value={stateForm.instCobranza}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, instCobranza: target.value }))}
                    placeholder={language === "en" ? "Place the collection instruction for the customer..." : "Coloque las instrucciones de cobranza para este cliente..."}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 13 */}
              <div className="row-flex">
                <div className="col-8"><strong>Instrucción de Despacho</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    name="instrucDespacho"
                    value={stateForm.instDespacho}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, instDespacho: target.value }))}
                    placeholder={language === "en" ? "Place the dispatch instructions for the customer..." : "Coloque las instrucciones de despacho para el cliente..."}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 14 */}
              {
                !isNaN(cotNum) && (
                  <div className="row-flex">
                    {/* SELECCIONAR ACCION */}
                    <div className="col-8"><strong>Seleccionar Acción</strong></div>
                    <div className="col-7">
                      <select
                        name="action"
                        value={stateForm.accion}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, accion: target.value }))}>
                        <option value=""></option>
                        {
                          empPermi && ([3, 5, 6].includes(empPermi[0]?.CodPerfil)) && (
                            <>
                              {
                                // Si la etapa es aprobacion de cotizacion
                                stateForm.etapa === 15 && (
                                  <>
                                    <option value="1">Aprobada</option>
                                    <option value="2">Rechazada</option>
                                    <option value="4">Conduce</option>
                                  </>
                                )
                              }
                            </>
                          )
                        }
                        {
                          empPermi && ((stateForm.etapa <= 15) && [3, 6].includes(empPermi[0]?.CodPerfil) && (solRepuesto && solRepuesto[0]?.IdEtapa === 15)) && (
                            <>
                              {
                                // si la cotizacion tiene repuesto
                                (detSolRep && detSolRep.length > 0) && (
                                  stateForm.etapa !== 15 && (
                                    <option value="3">{t("RequestPartsView.orden_compra")}</option>
                                  )
                                )
                              }
                              {
                                // si la cotizacion no tiene repuesto
                                detSolRep && !detSolRep.length && (
                                  <option value="3">{t("ServiceReports.progra_visita")}</option>
                                )
                              }
                              {
                                //  si tiene repuesto y la etapa es distinto de cotizacion
                                ((detSolRep && detSolRep.length) && stateForm.etapa !== 15) && (
                                  <option value="4">Conduce</option>
                                )
                              }
                            </>
                          )
                        }
                        {
                          empPermi && ([APRO_COT, COBR_COT, COT_COBRADA].includes(stateForm.etapa) && [3, 6].includes(empPermi[0]?.CodPerfil)) && (
                            <option value="">Oportunidad de venta</option>
                          )
                        }
                        <option value="102">En espera de reporte</option>
                        <option value="0">Comentario</option>
                      </select>
                    </div>
                  </div>
                )
              }

              {/* FILA 15 */}
              <div className="row-flex">
                {/* COMENTARIO */}
                <div className="col-8"><strong>Comentario</strong></div>
                <div className="flex-auto">
                  <textarea
                    className="textarea-description"
                    name="comentario"
                    value={stateForm.comment}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                    placeholder={language === "em" ? "Comments..." : "Comentario..."}
                    autoComplete="off" />
                </div>
              </div>

              {/* Tabla para mostrar las partes */}
              {
                detSolRep && detSolRep.length > 0 && (
                  <div className="table-container-mobile m-y-medium">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th>#</th>
                          <th>{t("TableParts.canti")}</th>
                          <th>Cot. Previa</th>
                          <th>{t("TableParts.parte")}</th>
                          <th>{t("TableParts.descrip")}</th>
                          <th>WorkFlow</th>
                          <th>{t("TableParts.dispo")}</th>
                          <th>Precio Neto US$</th>
                          <th>Total neto US$</th>
                          <th>Mult</th>
                          <th>Total RD$</th>
                          <th>Total US$</th>
                          <th>D</th>
                          <th>S</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          detSolRep && detSolRep.map((part) => {

                            let preNet = parseFloat(part.PreCotizacion).toFixed(2) / parseFloat(stateForm.cambioMoneda);
                            let totNet = (preNet).toFixed(2) * part.Cantidad;
                            let totRD = totNet * part.Multiplicador * parseFloat(stateForm.cambio);
                            let totUS = ((part.PreCotizacion).toFixed(2) / parseFloat(stateForm.cambioMoneda)) * part.Cantidad * part.Multiplicador;

                            TotalPartDolar += (parseFloat(part.PreCotizacion).toFixed(2) / parseFloat(stateForm.cambioMoneda)) * part.Cantidad * part.Multiplicador;
                            TotalPartRD += (parseFloat(part.PreCotizacion).toFixed(2) / parseFloat(stateForm.cambioMoneda)) * part.Cantidad * part.Multiplicador * parseFloat(stateForm.cambio);

                            // setStateForm((val) => ({ ...val, totalPartDolar: TotalPartDolar }));
                            return (
                              <tr key={part.CodDetSolRepuesto}>
                                <td className="txt-center">
                                  <button type="button" className="btn-link-tag" onClick={() => openModalModifyPart(part?.CodSolRepuesto, part?.CodDetSolRepuesto)}>{part.CodDetSolRepuesto}</button>
                                </td>
                                <td className="txt-center">{part.Cantidad}</td>
                                <td></td>
                                <td>
                                  <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link>
                                </td>
                                <td>{part.DesCotizacion}</td>
                                <td className="txt-center">{part.WorkFlow}</td>
                                <td>{part.BackOrder}</td>
                                <td style={{ textAlign: 'right' }}>
                                  {formatCurrency(preNet, 'USD')}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {formatCurrency(totNet, 'USD')}
                                </td>
                                <td style={{ textAlign: 'right' }}>{part.Multiplicador}</td>
                                <td style={{ textAlign: 'right' }}>
                                  {formatCurrency(totRD, 'USD')}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {formatCurrency(totUS, 'USD')}
                                </td>
                                <td className="txt-center">
                                  <button type="button" className="btn-delivery" title="Enviar a despacho esta pieza.">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--green-color)" className="bi bi-truck" viewBox="0 0 16 16">
                                      <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                  </button>
                                </td>
                                <td className="txt-center">
                                  <button type="button" className="btn-delivery" title="Separar item de la cotización.">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-send-arrow-up-fill" viewBox="0 0 16 16">
                                      <path fillRule="evenodd" d="M15.854.146a.5.5 0 0 1 .11.54L13.026 8.03A4.5 4.5 0 0 0 8 12.5c0 .5 0 1.5-.773.36l-1.59-2.498L.644 7.184l-.002-.001-.41-.261a.5.5 0 0 1 .083-.886l.452-.18.001-.001L15.314.035a.5.5 0 0 1 .54.111ZM6.637 10.07l7.494-7.494.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338Z" />
                                      <path fillRule="evenodd" d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.354a.5.5 0 0 0-.722.016l-1.149 1.25a.5.5 0 1 0 .737.676l.28-.305V14a.5.5 0 0 0 1 0v-1.793l.396.397a.5.5 0 0 0 .708-.708l-1.25-1.25Z" />
                                    </svg>
                                  </button>
                                </td>
                              </tr>
                            )
                          })
                        }
                        <tr>
                          <td colSpan={8} style={{ fontWeight: 'bold' }}>Total</td>
                          <td></td>
                          <td className="txt-right" colSpan={2}>
                            <strong>{formatCurrency(TotalPartRD, "USD")}</strong>
                          </td>
                          <td className="txt-right">
                            <strong>{formatCurrency(TotalPartDolar, "USD")}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              }

              <hr className="m-y-small" />

              <div className="row-flex">
                <div className="col-65">
                  <div className="row-flex flex-end">
                    <div className="col-7"></div>
                    <div className="col-8">
                      <strong>Horas</strong>
                    </div>
                    <div className="col-8">
                      <strong>C/Horas</strong>
                    </div>
                    <div className="col-8">
                      <strong>M/Obra</strong>
                    </div>
                    <div className="col-8"></div>
                    <div className="col-8">
                      <strong>Multiplicador</strong>
                    </div>
                  </div>
                </div>
                <div className="col-35" style={{ backgroundColor: "var(--first-color)" }}>
                  <div className="row-flex flex-end align-c">
                    <div className="col-6">
                      <strong style={{ display: "block", textAlign: "right", color: "white" }}>RD $</strong>
                    </div>
                    <div className="col-6">
                      <strong style={{ display: "block", textAlign: "right", color: "white" }}>USD $</strong>
                    </div>
                  </div>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Primera columna */}
                <div className="col-65">
                  {/* FILA */}
                  <div className="row-flex flex-end">
                    {/* Mano de obra */}
                    <div className="col-7"><strong>Mano de obra</strong></div>
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="Hour txt-right"
                            value={stateForm.hora}
                            onChange={({ target }) => onHandleCalc(target.value, 1)}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.hora}</span>)
                      }
                    </div>
                    {/* Costo por hora */}
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control txt-right"
                            type="text"
                            name="HourCost"
                            value={stateForm.cosHora}
                            onChange={({ target }) => onHandleCalc(target.value, 2)}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.cosHora}</span>)
                      }
                    </div>
                    {/* Mano de obra */}
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="ManObra"
                            value={stateForm.manObra}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, manObra: target.value }))}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.manObra}</span>)
                      }
                    </div>
                    {/* Multiplicador */}
                    <div className="col-8"><strong>Multiplicador</strong></div>
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            style={{ textAlign: 'right' }}
                            className="input-text-control"
                            type="text"
                            name="Multipl"
                            value={stateForm.multipli}
                            onChange={({ target }) => {
                              if (isNaN(target.value)) {
                                return;
                              }
                              setStateForm((val) => ({ ...val, multipli: target.value }))
                            }} />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.multipli}</span>)
                      }
                    </div>
                  </div>
                  {/* FILA */}
                  <div className="row-flex flex-end">
                    {/* Viaticos */}
                    <div className="col-26-79"><strong>Viáticos</strong></div>
                    {/* Input de viatico */}
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="Viatico"
                            value={stateForm.viatico}
                            onChange={({ target }) => {
                              if (isNaN(target.value)) {
                                return;
                              }
                              setStateForm((val) => ({ ...val, viatico: target.value }))
                            }}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.viatico}</span>)
                      }
                    </div>
                    {/* Viaje */}
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="Viaje"
                            value={stateForm.viaje}
                            onChange={({ target }) => {
                              if (isNaN(target.value)) {
                                return;
                              }
                              setStateForm((val) => ({ ...val, viaje: target.value }))
                            }}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.viaje}</span>)
                      }
                    </div>
                    {/* Flete */}
                    <div className="col-8">
                      <strong>Flete</strong>
                    </div>
                  </div>
                  {/* FILA */}
                  <div className="row-flex flex-end">
                    <div className="col-3">
                      <strong>SubTotal Mano de obra + Viático</strong>
                    </div>
                  </div>
                  {/* FILA */}
                  <div className="row-flex flex-end">
                    {/* Descuento */}
                    <div className="col-8">
                      <strong>Descuento</strong>
                    </div>
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="Descuento"
                            value={stateForm.descuento}
                            onChange={({ target }) => onHandleCalc(target.value, 3)}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.descuento}</span>)
                      }
                    </div>
                    {/* Descripcion descuento */}
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="DescDescuento"
                            value={stateForm.descuentoTxt}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, descuentoTxt: target.value }))}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.descuentoTxt}</span>)
                      }
                    </div>
                  </div>
                  {/* FILA */}
                  <div className="row-flex flex-end">
                    {/* Gastos */}
                    <div className="col-8"><strong>Gastos</strong></div>
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="Gastos"
                            value={stateForm.gasto}
                            onChange={({ target }) => {
                              if (isNaN(target.value)) {
                                return;
                              }
                              setStateForm((val) => ({ ...val, gasto: target.value }))
                            }} />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.gasto}</span>)
                      }
                    </div>
                  </div>
                  {/* FILA */}
                  <div className="row-flex flex-end">
                    {/* Subtotal */}
                    <div className="col-8">
                      <strong>SubTotal</strong>
                    </div>
                  </div>
                  {/* FILA */}
                  <div className="row-flex flex-end">
                    {/* ITBIS */}
                    <div className="col-8">
                      <strong>Itbis</strong>
                    </div>
                    <div className="col-8">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            className="input-text-control"
                            type="text"
                            name="Itbis"
                            value={stateForm.itbis}
                            onChange={({ target }) => onHandleCalc(target.value, 4)}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.itbis}</span>)
                      }
                    </div>
                  </div>
                  {/* FILA */}
                  <div className="row-flex j-content-space-b">
                    {/* Cambio US */}
                    <div className="col-8">
                      <strong>Cambio US$</strong>
                    </div>
                    <div className="col-3">
                      {
                        stateForm.etapa === 14 && (
                          <input
                            style={{ textAlign: 'right' }}
                            className="input-text-control"
                            type="text"
                            name="cambio"
                            value={stateForm.cambio}
                            onChange={({ target }) => onHandleChangeCambio(target.value)}
                            autoComplete="off" />
                        )
                      }
                      {
                        stateForm.etapa > 14 && (<span>{stateForm.cambio}</span>)
                      }
                    </div>
                    {/* Total */}
                    <div className="col-8">
                      <strong>Total</strong>
                    </div>
                  </div>
                </div>
                {/* Segunda columna */}
                <div className="col-35">
                  {/* FILA */}
                  <div className="row-flex">
                    {/* FILA 1 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="valTotPartRD"
                        value={formatCurrency(TotalPartRD, "USD")}
                        readOnly />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="valTotPartUSD"
                        value={formatCurrency(TotalPartDolar, "USD")}
                        readOnly />
                    </div>
                    {/* FILA 2 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="" />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="" />
                    </div>
                    {/* FILA 3 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="subTot2RD"
                        value={formatCurrency(manObraRD, "USD")}
                        readOnly />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="subTotUSD"
                        value={formatCurrency(stateForm.manObra, "USD")}
                        readOnly />
                    </div>
                    {/* FILA 4 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="totDescuentRD"
                        value={formatCurrency(stateForm.totDescuentoRD, "USD")}
                        readOnly />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="totDescuentUS"
                        value={formatCurrency(stateForm.totDescuentoUS, "USD")}
                        readOnly />
                    </div>
                    {/* FILA 5 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="" />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="" />
                    </div>
                    {/* FILA 6 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="subTotalRD"
                        value={formatCurrency(subTotalRD, "USD")}
                        readOnly />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="subTotalUS"
                        value={formatCurrency(subTotalUS, "USD")}
                        readOnly />
                    </div>
                    {/* FILA 7 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="itbisRD"
                        value={formatCurrency(itbisRD, "USD")}
                        readOnly />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        name="itbisUS"
                        value={formatCurrency(itbisUSD, "USD")}
                        readOnly />
                    </div>
                    {/* FILA 8 */}
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold", color: "var(--green-color)" }}
                        type="text"
                        name="totRd"
                        value={formatCurrency(TotalRD, "USD")}
                        readOnly />
                    </div>
                    <div className="col-6 border-bottom">
                      <input
                        className="fake-input txt-right"
                        style={{ fontWeight: "bold", color: "var(--green-color)" }}
                        type="text"
                        name=""
                        value={formatCurrency(TotalUS, "USD")}
                        readOnly />
                    </div>
                  </div>
                </div>
              </div>

              <hr className="m-y-small" />

              {/* FILA */}
              <div className="row-flex">
                {/* Tiempo de entrega */}
                <div className="col-7">
                  <strong>Tiempo de entrega</strong>
                </div>
                <div className="col-7">
                  <select name="tiempoEntrega" value={stateForm.tiempoEntr} onChange={({ target }) => setStateForm((val) => ({ ...val, tiempoEntr: target.value }))}>
                    <option value=""></option>
                    <option value="0">Estandar</option>
                    <option value="1">Inmediato</option>
                    <option value="2">Ejecutado</option>
                  </select>
                </div>
                {/* Input para mostrar texto */}
                <div className="col-71-42">
                  {
                    stateForm.etapa === 14 && (
                      <input
                        className="input-text-control"
                        type="text"
                        name="TimeTxt"
                        value={stateForm.tiempoEntrTxt}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, tiempoEntrTxt: target.value }))}
                        autoComplete="off" />
                    )
                  }
                  {
                    stateForm.etapa > 14 && (<span>{stateForm.tiempoEntrTxt}</span>)
                  }
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Validez de la oferta */}
                <div className="col-7">
                  <strong>Validez de la oferta</strong>
                </div>
                <div className="col-7">
                  {
                    stateForm.etapa === 14 && (
                      <input
                        className="input-text-control"
                        type="text"
                        name="valid"
                        value={stateForm.valid}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, valid: target.value }))}
                        autoComplete="off" />
                    )
                  }
                  {
                    stateForm.etapa > 14 && (<span>{stateForm.valid}</span>)
                  }
                </div>
                {/* Sitio de entrega */}
                <div className="col-8">
                  <strong>Sitio de entrega</strong>
                </div>
                <div className="col-9">
                  {
                    stateForm.etapa === 14 && (
                      <input
                        className="input-text-control"
                        type="text"
                        name="deliveryPlac"
                        value={stateForm.sitioEntr}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, sitioEntr: target.value }))}
                        autoComplete="off" />
                    )
                  }
                  {
                    stateForm.etapa > 14 && (<span>{stateForm.sitioEntr}</span>)
                  }
                </div>
                {/* Garantía */}
                <div className="col-10"><strong>Garantía</strong></div>
                <div className="col-9">
                  {
                    stateForm.etapa === 14 && (
                      <input
                        className="input-text-control"
                        type="text"
                        name="warranty"
                        value={stateForm.garantia}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, garantia: target.value }))}
                        autoComplete="off" />
                    )
                  }
                  {
                    stateForm.etapa > 14 && (<span>{stateForm.garantia}</span>)
                  }
                </div>
                {/* Condiciones de credito */}
                <div className="col-7"><strong>Condiciones de crédito</strong></div>
                <div className="col-7">
                  {
                    stateForm.etapa === 14 && (
                      <input
                        className="input-text-control"
                        type="text"
                        name="condCre"
                        value={stateForm.condiCred}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, condiCred: target.value }))}
                        autoComplete="off" />
                    )
                  }
                  {
                    stateForm.etapa > 14 && (<span>{stateForm.condiCred}</span>)
                  }
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Forma de pago */}
                <div className="col-7"><strong>Forma de pago</strong></div>
                <div className="col-7">
                  <select
                    name="formPa"
                    value={stateForm.formaPago}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, formaPago: target.value }))}>
                    <option value=""></option>
                    <option value="0">Orden de servicio</option>
                    <option value="1">Contado</option>
                    <option value="2">Anticipo</option>
                    <option value="3">Moroso</option>
                  </select>
                </div>
                <div className="col-71-42">
                  {
                    stateForm.etapa === 14 && (
                      <textarea
                        className="textarea-description"
                        name="Obser"
                        value={stateForm.otroPago}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, otroPago: target.value }))}
                        autoComplete="off" />
                    )
                  }
                  {
                    stateForm.etapa > 14 && (<span>{stateForm.otroPago}</span>)
                  }
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Notas */}
                <div className="col-7"><strong>Notas</strong></div>
                <div className="col-9 row-flex align-c gap-small">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="Nota1"
                    checked={stateForm.nota1}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nota1: target.checked }))} />
                  <strong>Partes</strong>
                </div>
                {/* Nota 2 */}
                <div className="col-8 row-flex align-c gap-small">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="Nota2"
                    checked={stateForm.nota2}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nota2: target.checked }))} />
                  <strong>Evaluación</strong>
                </div>
                {/* Nota3 */}
                <div className="col-16 row-flex align-c gap-small">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="Nota3"
                    checked={stateForm.nota3}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nota3: target.checked }))} />
                  <strong>Mantenimiento preventivo</strong>
                </div>
                {/* Nota 4 */}
                <div className="col-9 row-flex align-c gap-small">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="Nota4"
                    checked={stateForm.nota4}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nota4: target.checked }))} />
                  <strong>Taller</strong>
                </div>
                {/* Nota5 */}
                <div className="col-16 row-flex align-c gap-small">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="Nota5"
                    checked={stateForm.nota5}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nota5: target.checked }))} />
                  <strong>Suministro por intercambio</strong>
                </div>
                {/* Nota0 */}
                <div className="col-18-5 row-flex align-c gap-small">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="Nota0"
                    checked={stateForm.nota0}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nota0: target.checked }))} />
                  <strong>Condición especial de ventas</strong>
                </div>
              </div>

              <hr className="m-y-small" />


              {/* Tabla para agregar el seguimiento de la cotización */}
              {
                segCotizacion && segCotizacion.length > 0 && (
                  <div className="table-container-mobile m-y-medium seg-service-table">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th>Fecha</th>
                          <th>Etapa actual</th>
                          <th>Proxima etapa</th>
                          <th>Responsable</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          segCotizacion && segCotizacion.map((seg) => (
                            <tr key={`${seg.CodCotizacion}-${seg.CodSegCotizacion}`}>
                              <td className="col-8 txt-center">{renderDate(seg.FecIngreso, language)}</td>
                              <td className="col-8">{seg.Etapa}</td>
                              <td className="col-8">{seg.NomEtapaAct}</td>
                              <td className="col-7">{seg.NomEmpleado}</td>
                              <td className="col-3" style={{ textTransform: 'capitalize' }}>{seg.Accion}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                )
              }

              {/* Botones de accion */}
              <div className="row-flex j-content-space-around m-y-medium">
                <button type="submit" className="btn-primary">Guardar</button>
                {
                  solRepuesto && solRepuesto[0]?.CodCotizacion !== null && (
                    <>

                    </>
                  )
                }
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

            </form>
          )
        }

      </div>

      {
        statePartModify.showModal && (<PartDetailModify solRepuesto={statePartModify?.solRep} codDetSolRepuesto={statePartModify?.codDetSol} onClose={onCloseModal} employeePerm={empPermi} />)
      }
    </>
  );
};