
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";

// REACR ROUTER IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";




export const TechnicalApprovalView = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // location navigation
  const location = useLocation();

  // navigate
  const navigate = useNavigate();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false });

  // DESTRUCTURING DATA SERVER
  const { autTecnica, employeePermission } = dataServer && dataServer;


  // useEffect para traer la data del servidiro
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.technicalApproval}?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

        setState((val) => ({ ...val, isLoading: false }));

      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [language]);


  return (
    <>
      {/* Unauthorized */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {
        state.dataVisible && (
          <>
            <div className="container display-data">
              {
                autTecnica && autTecnica.length > 0 ? (
                  <div className="table-container-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>Solicitud</th>
                          <th>ID</th>
                          <th>Fecha</th>
                          <th>Solicitante</th>
                          <th>Cliente</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>Equipo</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          autTecnica && autTecnica.map((auth) => (
                            <tr key={auth.CodSolRepuesto}>
                              <td style={{ textAlign: 'center' }}><Link className="link-tag" to={`/warehouse/technical-approval/technical-detail/${auth.CodSolRepuesto}`}>{auth.CodSolRepuesto}</Link></td>
                              <td style={{ textAlign: 'center' }}><Link className="link-tag" to={`/service/service-call/${auth.CodServicio}`}>{auth.CodServicio}</Link></td>
                              <td className="col-10" style={{ textAlign: 'center' }}>{renderDate(auth.FecIngreso, language)}</td>
                              <td className="col-7">{auth.NomEmpleado}</td>
                              <td className="col-4"><Link className="link-tag" to={`/customers/customer-detail/${auth.CodCliente}`}>{auth.NomCliente}</Link></td>
                              <td><Link className="link-tag" to={`/equipments/equipment-detail/${auth.CodEquipo}`}>{auth.equipment}</Link></td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-small"><strong>{language === "en" ? "No technical approvals pending" : "No hay autorizaciones técnicas pendientes por aprobación"} &#128064;</strong></div>)
              }
            </div>
          </>
        )
      }
    </>
  );
};