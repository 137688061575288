// componente para crear el suplidor

import { useEffect, useState } from "react";


// components import
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { SearchDYNSupplier } from "../../components/logistic-components/SearchDYNSupplier";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// urls importation
import { urls } from "../../api-urls/api-urls";

// react router dom import
import { useNavigate } from "react-router-dom";
import { getTheToken } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";




export const CreateSupplierView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServ, setDataSer] = useState([]); // estado para colocar la data del servidor
  const [controller, setController] = useState(null);
  const [stateForm, setStateForm] = useState({ codGlob: "", codGMDH: "", nombre: "", direccion: "", activo: true });
  const [errors, setErrors] = useState({ nombre: "", direccion: "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, modalA: false, compParams: "", modalB: false, questionAction: "", loading: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // cerrar el modal
  const onCloseModal = () => {
    setState((val) => ({ ...val, modalA: false }));
  };



  // funcion para abrir el modal de guardar
  const onHandleSaveModal = () => {
    if (stateForm.nombre.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("LogisticViewComp.completar_camp_vacios"), error: true }));
    }

    if (stateForm.direccion.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("LogisticViewComp.completar_camp_vacios"), error: true }));
    }

    setState((val) => ({ ...val, modalB: true, questionAction: t("LogisticViewComp.seguro_crear_supp") }));
  };


  // funcion para mostrar los error al hacer blur
  const onHandleBlur = (event) => {
    const { name, value } = event.target;

    if (value.trim() === "") {
      setErrors((val) => ({ ...val, [name]: t("LogisticViewComp.campo_vacio") }));
    } else {
      setErrors((val) => ({ ...val, [name]: "" }));
    }
  };




  // funcion para crear el suplidor
  const onHandleCreateSupp = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loading: true }));

      const response = await fetch(`${urls.dataSupplier}/new?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setState((val) => ({ ...val, modalB: false }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, loading: false }));

    } catch (error) {
      console.log(error);
    }
  };




  // agregar el suplidor seleccionado al estado
  const onAddSupplier = (codPro = "", compa = "") => {
    if (compa === "GLOBL") {
      setStateForm((val) => ({ ...val, codGlob: codPro }));
    } else {
      setStateForm((val) => ({ ...val, codGMDH: codPro }));
    }
    setState((val) => ({ ...val, modalA: false }));
  };



  // funcion para obtener los permisos del usuario
  const fetchPermissions = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(urls.permissions, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataSer(data?.EmpPerm);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // console.log(dataServ);


  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchPermissions();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  // useEffect para limpiar el estado de la notification
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);



  // useEffect para navegar a la ruta de la lista
  useEffect(() => {
    if (notification.success) {
      const timer = setTimeout(() => {
        navigate("/supplier-data");
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notification.success]);





  return (
    <div className="container display-data">
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // modal para seleccionar los suplidores
        state.modalA && (
          <SearchDYNSupplier
            company={state.compParams}
            onClose={onCloseModal}
            language={language}
            onAddSupplier={onAddSupplier} />)
      }
      {
        state.dataVisible && (
          <form onSubmit={onHandleCreateSupp}>
            {
              dataServ[0]?.CodEmpresa === "1" && (
                <div className="row-flex">
                  <div className="col-7">
                    <strong>{t("LogisticViewComp.codigo_global")}</strong>
                  </div>
                  <div className="col-7">
                    <input
                      className="input-text-control"
                      type="text"
                      name="codGlob"
                      value={stateForm.codGlob}
                      onFocus={() => setState((val) => ({ ...val, modalA: true, compParams: "GLOBL" }))}
                      readOnly />
                  </div>
                </div>
              )
            }
            <div className="row-flex">
              <div className="col-7">
                <strong>{t("LogisticViewComp.codigo_gmdh")}</strong>
              </div>
              <div className="col-7">
                <input
                  className="input-text-control"
                  type="text"
                  name="codGMD"
                  value={stateForm.codGMDH}
                  onFocus={() => setState((val) => ({ ...val, modalA: true, compParams: "GMDH" }))}
                  readOnly />
              </div>
            </div>
            <div className="row-flex">
              <div className="col-7">
                <strong>{t("LogisticViewComp.nombre")}</strong>
              </div>
              <div className="col-6">
                <input
                  className="input-text-control"
                  type="text"
                  name="nombre"
                  value={stateForm.nombre}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, nombre: target.value }))}
                  onBlur={onHandleBlur}
                  autoComplete="off" />
                {errors.nombre && (<p className="fade-animation" style={{ margin: 0, color: "var(--danger-color)" }}><span>{errors.nombre}</span></p>)}
              </div>
            </div>
            <div className="row-flex">
              <div className="col-7">
                <strong>{t("LogisticViewComp.direccion")}</strong>
              </div>
              <div className="col-85-71">
                <textarea
                  className="textarea-description"
                  name="direccion"
                  value={stateForm.direccion}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, direccion: target.value }))}
                  onBlur={onHandleBlur}
                  autoComplete="off" />
                {errors.direccion && (<p className="fade-animation" style={{ margin: 0, color: "var(--danger-color)" }}><span>{errors.direccion}</span></p>)}
              </div>
            </div>
            <div className="row-flex">
              <div className="col-7">
                <strong>{t("LogisticViewComp.activo")}</strong>
              </div>
              <div className="col-7">
                <input
                  className="input-control-checkbox"
                  type="checkbox"
                  name="active"
                  checked={stateForm.activo}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, activo: target.checked }))} />
              </div>
            </div>

            <div className="row-flex j-content-space-around m-y-small">
              <button type="button" className="btn-primary" onClick={onHandleSaveModal}>{t("DataComp.save")}</button>
              <button type="button" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
            </div>

            {
              state.modalB && (
                <div className="background-modal">
                  <div className="container-modal-confirm">
                    {/* Question mark */}
                    <div className="row-flex m-y-small align-c justify-center">
                      <div className="question-mark-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                        </svg>
                      </div>
                    </div>
                    <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                      {state.questionAction}
                    </h5>
                    {/* Botones de accion */}
                    <div className="row-flex m-y-medium j-content-space-around">
                      <button type="submit" className="btn-success" disabled={state.loading}>
                        {state.loading ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                      </button>
                      <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, modalB: false }))}>No</button>
                    </div>
                  </div>
                </div>
              )
            }
          </form>
        )
      }
    </div>
  );
};