import React, { useEffect, useState } from "react";

// REACT ROUTER DOM IMPORTATION 
import { Link, useLocation, useNavigate } from "react-router-dom";

// COMPONENTS IMPORTATION
import { Error500Comp } from "../../components/errors/Error500Comp";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { SuccessfulNotification } from "../../components/successful-notification/SuccessfulNotificacion";


// REACT TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTION IMPORTATIONS
import { getTheToken } from "../../global-functions/globalFunctions";
import { Error403View } from "../error-403-view/Error403View";





export const DivisionDataView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the react translation
  const [t] = useTranslation("global");

  // STATE DEFINITION
  const [dataDivision, setDataDivision] = useState([]); // para cargar la data de las divisiones cuando se modifique
  const [isLoading, setIsLoading] = useState(false); // para mostrar el loading
  const [unauthorized, setUnAuthorized] = useState(false); // para mostrar el componente de no autorizado
  const [errorServer, setErrorServer] = useState(false); // para mostrar el componente de error en el servidor
  const [dataVisible, setDataVisible] = useState(false); // para mostrar la data dependiendo de la respuesta del servidor
  const [showModal, setShowModal] = useState(false); // estado para abrir el modal
  const [stateForm, setStateForm] = useState({ name: "", fullName: "", codDivi: "", horas: 0, activo: false });
  const [filterDivision, setFilterDivision] = useState({ filter: false, codDivi: "" }); // para verificar que se quiere filtrar una division
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false });

  // FUNCION PARA ABRIR EL MODAL Y VERIFICAR CUAL FUE LA DIVISION QUE SE CLIQUEO
  const onOpenModal = (codDivi) => {
    setShowModal(true);

    // verificar si hay un codigo de division
    if (codDivi) {
      setFilterDivision({ filter: true, codDivi: codDivi });
    } else {
      setFilterDivision({ filter: false, codDivi: "" });
      console.log("No hay division")
    }
  };

  // FUNCION PARA CERRAR EL MODAL 
  const onCloseModal = () => {
    setShowModal(false);
    setFilterDivision({ filter: false, codDivi: "" });
  };


  // useEffect para filtrar la division
  useEffect(() => {
    if (filterDivision.filter && dataDivision && dataDivision.length > 0) {
      const filterDiv = dataDivision?.filter((divi) => divi.CodDivision === filterDivision.codDivi);

      setStateForm({ name: filterDiv[0].Nombre, fullName: filterDiv[0].Proveedor, codDivi: filterDiv[0].CodDivision, horas: filterDiv[0].Hora, activo: filterDiv[0].Activo });

    } else {
      setStateForm({ name: "", fullName: "", codDivi: "", horas: 0, activo: false });
    }

  }, [filterDivision.filter]);

  console.log(filterDivision.filter)


  // FUNCION PARA ENVIAR LOS DATOS AL SERVIDOR
  const onSubmitForm = (event) => {
    event.preventDefault();

    // Realizar las validaciones
    if (!stateForm.name || !stateForm.fullName || !stateForm.codDivi || !stateForm.horas) {
      return alert("Campos obli")
    }

    if (isNaN(stateForm.horas)) {
      return alert("Las horas solo deben ser numeros")
    }

    console.log("Se sometió el formulario")

  };


  // Funcion para traer los datos del servidor
  const fetchDataServer = async () => {
    setIsLoading(true);

    const response = await fetch(`${urls.divisions}/?language=${language}`, {
      headers: { "Authorization": getTheToken().token }
    });

    const data = await response.json();

    // DESTRUCTURING DATA FROM THE SERVER
    const { divisions } = data && data;

    if (response.status === 200) {
      setDataVisible(true);
      setDataDivision(divisions); // colocar la data del servidor en el estado para mostrarlo en la tabla
    } else if (response.status === 401) {
      localStorage.setItem("lastNav", location.pathname);
      localStorage.removeItem("userInfo");
      return navigate("/");
    } else if (response.status === 403) {
      setDataVisible(false);
      setUnAuthorized(true);
    } else if (response.status === 500) {
      setErrorServer(true);
    }

    setIsLoading(false);
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }

  }, [language]);



  return (
    <>
      {/* ERROR DEL SERVIDOR (500) */}
      {errorServer && (<Error500Comp />)}
      {/* LOADING COMP */}
      {isLoading && (<LoadingComp />)}
      {/* NO AUTORIZADO */}
      {unauthorized && (<Error403View />)}
      {/* NOTIFICACION */}
      {notification.show && (<SuccessfulNotification msg={notification.msg} errorNotification={notification.errorNoti} />)}
      {
        dataVisible && (
          <>
            <div className="container display-data">
              <div className="row-flex align-c">
                <div className="col-4">
                  <h1 style={{ color: 'var(--first-color)' }}>{t("navigationMenu.listDivi")}</h1>
                </div>
                <div className="col-10">
                  <button className="btn-add" onClick={() => onOpenModal("")}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* TABLA DE LA LISTA DE LAS DIVISIONES */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th style={{ borderRadius: '5px 0 0 0' }}>{t("DataComp.name")}</th>
                      <th>{t("DataComp.codDivi")}</th>
                      <th>{t("DataComp.active")}</th>
                      <th>{t("DataComp.enterBy")}</th>
                      <th style={{ borderRadius: '0 5px 0 0' }}>{t("DataComp.modBy")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      dataDivision && dataDivision.map((divi) => (
                        <tr key={divi.CodDivision}>
                          <td><Link className="link-tag" onClick={() => onOpenModal(divi.CodDivision)}>{divi.Nombre}</Link></td>
                          <td style={{ textAlign: 'center' }}>{divi.CodDivision}</td>
                          <td style={{ textAlign: 'center' }}><span className={divi.Activo ? "completed" : "inactive"}>{divi.activoLab}</span></td>
                          <td>{divi.nomEmpIng}</td>
                          <td>{divi.nomEmpMod}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>

            {/* MODAL PARA MODIFICAR LAS DIVISIONES */}
            {
              showModal && (
                <>
                  <div className="background-modal" onClick={onCloseModal}>
                    <div className="container-modal-data" onClick={(e) => e.stopPropagation()}>
                      <form className="m-t-medium" onSubmit={onSubmitForm}>
                        {/* FILA 1 */}
                        <div className="row-flex">
                          {/* NOMBRE */}
                          <div className="col-5"><strong>{t("DataComp.name")}</strong></div>
                          <div className="col-3">
                            <input
                              className="input-text-control"
                              style={{ width: '100%' }}
                              type="text"
                              name="nombre"
                              value={stateForm.name}
                              onChange={({ target }) => setStateForm({ ...stateForm, name: target.value })} />
                          </div>
                          {/* CODIGO DIVISION */}
                          <div className="col-5"><strong>{t("DataComp.codDivi")}</strong></div>
                          <div className="col-4">
                            <input
                              className="input-text-control"
                              style={{ width: '100%' }}
                              type="text"
                              name="codDiv"
                              value={stateForm.codDivi}
                              onChange={({ target }) => setStateForm({ ...stateForm, codDivi: target.value })} />
                          </div>
                        </div>

                        {/* FILA 2 */}
                        <div className="row-flex">
                          {/* NOMBRE COMPLETO */}
                          <div className="col-5"><strong>{t("DataComp.fullName")}</strong></div>
                          <div className="col-3">
                            <input
                              className="input-text-control"
                              style={{ width: '100%' }}
                              type="text"
                              name="nombreComp"
                              value={stateForm.fullName}
                              onChange={({ target }) => setStateForm({ ...stateForm, fullName: target.value })} />
                          </div>
                          {/* COSTO POR HORAS */}
                          <div className="col-5"><strong>Costo por hora</strong></div>
                          <div className="col-4">
                            <input
                              className="input-text-control"
                              style={{ width: '100%' }}
                              type="text"
                              name="costHoras"
                              value={stateForm.horas}
                              onChange={({ target }) => setStateForm({ ...stateForm, horas: target.value })} />
                          </div>
                        </div>

                        {/* FILA 3 */}
                        <div className="row-flex m-y-small">
                          {/* ACTIVO */}
                          <div className="col-5"><strong>{t("DataComp.active")}</strong></div>
                          <div className="col-3">
                            <input
                              className="input-control-checkbox"
                              type="checkbox"
                              name="activo"
                              checked={stateForm.activo}
                              onChange={({ target }) => setStateForm({ ...stateForm, activo: target.checked })} />
                          </div>
                        </div>

                        {/* BOTONES DE ACCION */}
                        <div className="row-flex m-y-small j-content-space-around">
                          <button type="submit" className="btn-primary">{t("DataComp.save")}</button>
                          <button type="reset" className="btn-secondary" onClick={onCloseModal}>{t("DataComp.close")}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )
            }
          </>
        )
      }
    </>
  );
};