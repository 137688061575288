
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { TopNavbarMobile } from "../../components/top-searchbar-mobile/TopNavbarMobile";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";
import { Error500Comp } from "../../components/errors/Error500Comp";



export const HistoryExpensesView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // STATE DECLARATION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataVisible, setDataVisible] = useState(false);
  const [unAuthorized, setUnAuthorized] = useState(false);
  const [serverError, setServerError] = useState(false);


  // DESTRUCTURING DATA SERVER
  const { allHistory } = dataServer && dataServer;


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(`${urls.historyExpenses}?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setDataVisible(true);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setDataVisible(false);
          setUnAuthorized(true);
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
          setDataVisible(false);
          setServerError(true);
        }

        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [language, navigate]);

  return (
    <>
      {
        dataVisible && (
          <>
            <TopNavbarMobile />
            <div className="container">
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th style={{ borderRadius: '5px 0 0 0' }}>Control</th>
                      <th>Responsable</th>
                      <th>Etapa</th>
                      <th>Moneda</th>
                      <th>Anticipo solicitado</th>
                      <th>Anticipo aprobado</th>
                      <th>Relación solicitada</th>
                      <th>Relación aprobada</th>
                      <th style={{ borderRadius: '0 5px 0 0' }}>Ultimo Comentario</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      allHistory && allHistory.map((history) => (
                        <tr key={history.CodSolGasto}>
                          <td><Link className="link-tag">{history.CodSolGasto}</Link></td>
                          <td>{history.NomEmpleado}</td>
                          <td>{history.etapa}</td>
                          <td style={{ textAlign: 'center' }}>{history.moneda}</td>
                          <td style={{ textAlign: 'right' }}>{history.antFormatted}</td>
                          <td style={{ textAlign: 'right' }}>{history.antAprobado}</td>
                          <td style={{ textAlign: 'right' }}>{ }</td>
                          <td style={{ textAlign: 'right' }}>{ }</td>
                          <td style={{ textTransform: 'capitalize' }}>{history.comment}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )
      }

      {
        isLoading && (<LoadingComp />)
      }
      {
        unAuthorized && (<Error403View />)
      }
      {
        serverError && (<Error500Comp />)
      }
    </>
  );
};