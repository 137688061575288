

// ----> inline Styles for NewEmployee component
export const cardNewEmployee = {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 0.3rem 1rem 0'
};

export const linesForms = {
  display: 'flex',
  gap: '0.5rem',
  padding: '0.3rem'
};



export const inputRadioStyles = {
  height: '0.8rem',
  padding: '1rem',
  marginRight: '0.5rem',
  width: '0.8rem'
};

// ----> inline Styles for btnLogout
export const btnLogout = {
  backgroundColor: 'var(--danger-color)',
  border: '1px solid var(--danger-color)',
  color: 'var(--white-color)',
  cursor: 'pointer',
  fontSize: '0.8rem',
  margin: '0.5rem 0 0.2rem 0',
  textAlign: 'left',
  padding: '0.2rem 0 0.2rem 0',
  width: '100%',
};

// ----> inline Styles for customer label on TopNavbarMobile Comp
export const customerContainerLabel = {
  bottom: '0',
  display: 'flex',
  gap: '0.8rem',
  alignItems: 'center',
  position: 'absolute',
  padding: '0.2rem 0 0.2rem 0.3rem',
  left: '10rem',
  top: '0',
  right: '5rem',
};


// ----> inline Styles for filters-container
export const filtersContainerCustomers = {
  alignItems: 'center',
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  bottom: '0',
  height: '100vh',
  justifyContent: 'center',
  position: 'fixed',
  left: '0',
  top: '0',
  right: '0',
  width: '100vw',
  zIndex: '4'
};

export const filterContainerCustomers = {
  animation: 'zoomIn 0.5s ease',
  backgroundColor: 'var(--white-color)',
  display: 'flex',
  borderRadius: '0.3rem',
  flexDirection: 'column',
  gap: '0.5rem',
  height: '70%',
  width: '40%'
};

export const btnCloseFilter = {
  borderRadius: '0.3rem',
  border: '1px solid var(--danger-color)',
  cursor: 'pointer',
  fontSize: '0.8rem',
  fontWeight: '600',
  padding: '0.3rem',
  width: '30%'
};



// ----> inline styles for ServiceView component

export const serviceContainer = {
  margin: 'var(--margin-container)'
};


// ----> inline styles for sidebar



// ----> inline styles for EquipmentView comp

export const equipmentView = {
  backgroundColor: 'var(--first-color)',
  color: 'var(--white-color)',
  borderBottom: '2px solid',
  fontWeight: 'bold',
  fontSize: '0.8rem',
  padding: '0.5rem 0 0.5rem 0'
};


// ----> inline styles for ErrorNotification 

export const errorContainer = {
  alignItems: 'center',
  backgroundColor: 'var(--background-black-alfa)',
  bottom: '0',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  width: '100vw',
  zIndex: '8'
};

export const errorDisplay = {
  backgroundColor: 'var(--white-color)',
  borderRadius: '0.7rem',
  height: '60%',
  width: '40%'
};

// ----> inline styles for NewCall
export const newCallStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.07)',
  borderRadius: '0.4rem',
  boxShadow: 'var(--box-shadow-bottom)',
  display: 'flex', 
  flexDirection: 'column', 
  margin: 'var(--margin-container)', 
  padding: '0.3rem'
};

// ----> inline styles for open customers on new call component
export const searchACustomerForOpenCall = {
  animation: 'zoomIn 0.5s ease',
  backgroundColor: 'var(--white-color)',
  display: 'flex',
  borderRadius: '0.3rem',
  flexDirection: 'column',
  gap: '0.5rem',
  height: '60%',
  width: '30rem'
};