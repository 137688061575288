// este componente es para modificar la data de los suplidores


// react imports
import { useEffect, useState } from "react";


// components imports
import { ToastNotification } from "../toast-notification/ToastNotification";


// react router dom import
import { useLocation, useNavigate } from "react-router-dom";

// global functions import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { urls } from "../../api-urls/api-urls";

// react translation
import { useTranslation } from "react-i18next";


export const ModifyDataSupplier = ({ detail, codPro, onClose }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useLocation()
  const location = useLocation();

  // GET the useNavigate
  const navigate = useNavigate();


  // state definition
  const [dataServer, setDataServer] = useState([]); // para colocar la data del servidor
  const [state, setState] = useState({ isLoading: false, openModalSupl: false, empresa: "" });
  const [stateForm, setStateForm] = useState({ codeGlobl: "", searchSuppl: "", codeGMDH: "", nombre: "", direccion: "", activo: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });


  // destructuring data from the server
  const { suppliersDYN } = dataServer && dataServer;

  
  // filtrar los suplidores
  const filterSupplier = suppliersDYN && suppliersDYN.filter((sup) => sup.NomProveedor?.toLowerCase().includes(stateForm.searchSuppl.toLowerCase()));
  

  // funcion para agregar el suplidor al estado y que luego se pueda guardar
  const onAddSupplier = (codPro = "") => {
    setState((val) => ({ ...val, openModalSupl: false }));
    if (state.empresa === "GLOBL") {
      setStateForm((val) => ({ ...val, codeGlobl: codPro }));
    } else {
      setStateForm((val) => ({ ...val, codeGMDH: codPro }));
    }
    setState((val) => ({ ...val, empresa: "" }));
  };



  // funcion para actualizar la informacion de un suplidor
  const onHandleSubmitUpdateSupplier = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.dataSupplier}/${codPro}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para buscar los suplidores dependiendo la empresa
  const openModalSearchSupp = async (empresa = "") => {
    setState((val) => ({ ...val, openModalSupl: true, empresa }));

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.dataSupplier}?language=${language}&empresa=${empresa}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cargar los valores en el estado
  useEffect(() => {
    if (detail && detail.length > 0) {
      setStateForm((val) => ({
        ...val, codeGlobl: detail[0]?.CodGLOBL, codeGMDH: detail[0]?.CodGMDH ?? "", nombre: detail[0]?.Nombre, direccion: detail[0]?.Direccion, activo: detail[0]?.Activo
      }));
    }
  }, [detail]);


  // useEffect para limpiar las notificationes del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.show]);



  return (
    <>
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="background-modal" onClick={onClose}>
        <div className="container-modal" onClick={(event) => event.stopPropagation()}>
          <form onSubmit={onHandleSubmitUpdateSupplier}>
            {/* agregar los suplidores del dynamic de la empresa Global */}
            <div className="row-flex align-c">
              <div className="col-7"><strong>{t("LogisticViewComp.codigo_global")}</strong></div>
              <div className="col-4">
                <input
                  className="input-text-control"
                  type="text"
                  name="codGlobl"
                  readOnly
                  value={stateForm.codeGlobl}
                  onFocus={() => openModalSearchSupp("GLOBL")} />
              </div>
            </div>

            {/* agregar los suplidores del dynamic de la empresa GMDH */}
            <div className="row-flex align-c">
              <div className="col-7"><strong>{t("LogisticViewComp.codigo_gmdh")}</strong></div>
              <div className="col-4">
                <input
                  className="input-text-control"
                  type="text"
                  name="codGMDH"
                  readOnly
                  value={stateForm.codeGMDH}
                  onFocus={() => openModalSearchSupp("GMDH")} />
              </div>
            </div>

            {/* FILA */}
            <div className="row-flex">
              <div className="col-7"><strong>{t("LogisticViewComp.nombre")}</strong></div>
              <div className="col-80">
                <input
                  className="input-text-control"
                  type="text"
                  name="nomPro"
                  value={stateForm.nombre}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, nombre: target.value }))} />
              </div>
            </div>

            {/* FILA */}
            <div className="row-flex">
              <div className="col-7"><strong>{t("LogisticViewComp.direccion")}</strong></div>
              <div className="col-80">
                <textarea
                  className="textarea-description"
                  name="address"
                  value={stateForm.direccion}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, direccion: target.value }))}
                  autoComplete="off" />
              </div>
            </div>

            {/* FILA */}
            <div className="row-flex">
              <div className="col-7"><strong>{t("LogisticViewComp.activo")}</strong></div>
              <div className="col-7">
                <input
                  className="input-control-checkbox"
                  type="checkbox"
                  name="active"
                  checked={stateForm.activo}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, activo: target.checked }))} />
              </div>
            </div>

            {/* FILA */}
            <div className="row-flex">
              <div className="col-7"><strong>{t("LogisticViewComp.ingr_por")}</strong></div>
              <div className="col-3">
                <span className="span">{detail && detail[0]?.Ingreso}</span>
              </div>
              {/* modificado por */}
              <div className="col-7"><strong>{t("LogisticViewComp.modif_por")}</strong></div>
              <div className="col-3">
                <span className="span">{detail && detail[0]?.Modificacion}</span>
              </div>
            </div>

            {/* FILA */}
            <div className="row-flex">
              <div className="col-7"><strong>{t("LogisticViewComp.fec_ing")}</strong></div>
              <div className="col-3">
                <span className="span">{detail && renderDate(detail[0]?.FecIngreso, language)}</span>
              </div>
              <div className="col-7"><strong>{t("LogisticViewComp.fec_mod")}</strong></div>
              <div className="col-3">
                <span className="span">{detail && renderDate(detail[0]?.FecModificacion, language)}</span>
              </div>
            </div>

            {/* Botones de accion */}
            <div className="row-flex j-content-space-around m-y-small">
              <button type="submit" className="btn-success">{t("LogisticViewComp.actualizar")}</button>
              <button type="reset" className="btn-secondary" onClick={onClose}>{t("LogisticViewComp.cerrar")}</button>
            </div>
          </form>


          {/* modal para buscar el suplidor dependiendo de la empresa */}
          {
            state.openModalSupl && (
              <div className="background-modal" onClick={() => setState((val) => ({ ...val, openModalSupl: false, empresa: "" }))}>
                <div className="container-modal-filter-customer" onClick={(event) => event.stopPropagation()}>
                  {/* formulario para buscar */}
                  <div className="row-flex align-c">
                    <div className="col-80">
                      <input
                        className="input-text-control"
                        type="text"
                        name="supplier"
                        value={stateForm.searchSuppl}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, searchSuppl: target.value }))} />
                    </div>
                    <div className="col-5">
                      <button type="submit" className="btn-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {
                    state.isLoading && (<strong>{t("LoginView.cargando")}</strong>)
                  }
                  {/* Mostrar la lista de los suplidores */}
                  {
                    suppliersDYN && suppliersDYN.length > 0 && (
                      <div className="list-supplier-container">
                        {
                          filterSupplier && filterSupplier.map((sup) => (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className="col-80 border-bottom hover-class" key={sup.CodProveedor}>
                              <strong>{sup.NomProveedor}</strong>
                              <button type="button" style={{ cursor: "pointer" }} onClick={() => onAddSupplier(sup.CodProveedor)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                                  <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                                </svg>
                              </button>
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};