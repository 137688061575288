// componente para mostrar el detalle de la relacion de gastos (el hijo del header)

import { useEffect, useState } from "react";

// components importation
import { Error403View } from "../error-403-view/Error403View";


// react router import
import { useLocation, useNavigate, useParams } from "react-router-dom";


// global funcion import
import { HorasViaticos, formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";

// urls importation
import { urls } from "../../api-urls/api-urls";





export const RelacionGastoDetailDetalle = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the params
  const { codSolG, codDet } = useParams();


  // state definition
  const [dataServer, setDataServer] = useState([]); // para colocar la data del servidor
  const [stateDetSolG, setStateDetSolG] = useState([]); // para colocar en el estado el detalle de la solicitud
  const [stateDetGastos, setStateDetGastos] = useState([]); // para colocar el detalle de los gastos
  const [controller, setController] = useState(null); // para abortar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, showModal: false });
  const [stateForm, setStateForm] = useState({ fecSalida: "", fecRegr: "", horaSali: "", horaRegr: "", origen: "", destino: "", cliente: "", concepto: "", subConcep: "", transpor: "", justifi: "", nroBoleIda: "", nroBoleRegr: "" });


  // destructuring data form the server
  const { detSolG, detGastos } = dataServer && dataServer;


  // funcion para realizar el fetch de datos
  const fetchDataServer = async () => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.relacionGasto}/${codSolG}/${codDet}/search?language=${language}`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error)
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // console.log(stateDetGastos)

  // useEffect para colocar en el estado la solicitud y toda la informacion 
  useEffect(() => {
    if (detSolG && detSolG.length > 0) {
      setStateDetSolG(detSolG);

      // colocar los datos en el formulario
      const fecSal = detSolG[0]?.FecSalida ? detSolG[0]?.FecSalida.slice(0, 10) : "";
      const fecRe = detSolG[0]?.FecLlegada ? detSolG[0]?.FecLlegada.slice(0, 10) : "";

      setStateForm((val) => ({
        fecSalida: fecSal, fecRegr: fecRe, horaSali: detSolG[0]?.HorSalida, horaRegr: detSolG[0]?.HorLlegada, origen: detSolG[0]?.CodCiuOrigen.toString(),
        destino: detSolG[0]?.CodCiuDestino, cliente: detSolG[0]?.Cliente, concepto: detSolG[0]?.CodConcepto.toString(), subConcep: detSolG[0]?.CodSubConcepto.toString(),
        transpor: detSolG[0]?.IdTransporte.toString(), justifi: detSolG[0]?.Justificacion, nroBoleIda: "", nroBoleRegr: ""
      }));
    }
  }, [detSolG]);


  // useEffect para colocar el detalle de los gastos
  useEffect(() => {
    if (detGastos && detGastos.length > 0) {
      setStateDetGastos(detGastos);
    }
  }, [detGastos]);


  return (
    <>
      {/* Unauthorized component */}
      {state.unauthorized && (<Error403View />)}
      {
        state.dataVisible && (
          <div className="container display-data">
            <form>
              {/* FILA */}
              <div className="row-flex">
                {/* nro control */}
                <div className="col-7"><strong>{t("Viaticos.nro_control")}</strong></div>
                <div className="col-3"><h4>{codSolG}</h4></div>
                {/* etapa */}
                <div className="col-7"><strong>{t("Viaticos.etapa")}</strong></div>
                <div className="col-3"><strong className="active-class" style={{ textDecoration: 'underline' }}>{stateDetSolG[0]?.Etapa}</strong></div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* fecha de salida */}
                <div className="col-7"><strong>Fecha de salida</strong></div>
                <div className="col-3">
                  <input
                    type="date"
                    className="input-date-styles"
                    name="fecSal"
                    value={stateForm.fecSalida}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecSalida: target.value }))} />
                </div>
                {/* fecha de regreso */}
                <div className="col-7"><strong>Fecha de regreso</strong></div>
                <div className="col-3">
                  <input
                    type="date"
                    className="input-date-styles"
                    name="fecRegr"
                    value={stateForm.fecRegr}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecRegr: target.value }))} />
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* hora de salida */}
                <div className="col-7"><strong>Hora de salida</strong></div>
                <div className="col-3">
                  <select
                    name="horSa"
                    value={stateForm.horaSali}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, horaSali: target.value }))}>
                    <option value=""></option>
                    {
                      HorasViaticos && HorasViaticos.map((hor) => (
                        <option key={hor} value={hor}>{hor}</option>
                      ))
                    }
                  </select>
                </div>
                {/* hora de regreso */}
                <div className="col-7"><strong>Hora de regreso</strong></div>
                <div className="col-3">
                  <select
                    name="horReg"
                    value={stateForm.horaRegr}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, horaRegr: target.value }))}>
                    <option value=""></option>
                    {
                      HorasViaticos && HorasViaticos.map((hor) => (
                        <option key={hor} value={hor}>{hor}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* origen */}
                <div className="col-7"><strong>Origen</strong></div>
                <div className="col-3">
                  <select
                    name="origin"
                    value={stateForm.origen}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, origen: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG[0]?.ciudad.map((ciu) => (
                        <option key={ciu.Codigo} value={ciu.Codigo}>{ciu.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
                {/* destino */}
                <div className="col-7"><strong>Destino</strong></div>
                <div className="col-3">
                  <select
                    name="desti"
                    value={stateForm.destino}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, destino: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG[0]?.ciudad.map((ciu) => (
                        <option key={ciu.Codigo} value={ciu.Codigo}>{ciu.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* cliente */}
                <div className="col-7"><strong>Cliente</strong></div>
                <div className="col-80-94">
                  <input
                    type="text"
                    className="input-text-control"
                    name="customer"
                    value={stateForm.cliente}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, cliente: target.value }))} />
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* concepto */}
                <div className="col-7"><strong>Concepto</strong></div>
                <div className="col-3">
                  <select
                    name="concept"
                    value={stateForm.concepto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, concepto: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG[0]?.concepto.map((con) => (
                        <option key={con.Codigo} value={con.Codigo}>{con.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
                {/* subconcepto */}
                <div className="col-7"><strong>Sub-concepto</strong></div>
                <div className="col-3">
                  <select
                    name="sub-concept"
                    value={stateForm.subConcep}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, subConcep: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG[0]?.subConcept.map((sub) => (
                        <option key={sub.Codigo} value={sub.Codigo}>{sub.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* transporte */}
                <div className="col-7"><strong>Transporte</strong></div>
                <div className="col-3">
                  <select
                    name="transpo"
                    value={stateForm.transpor}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, transpor: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG[0]?.transport.map((trans) => (
                        <option key={trans.Codigo} value={trans.Codigo}>{trans.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* justificacion */}
                <div className="col-7"><strong>Justificación</strong></div>
                <div className="col-80-94">
                  <input
                    type="text"
                    className="input-text-control"
                    name="jusitif"
                    value={stateForm.justifi}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, justifi: target.value }))} />
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* nro boleto ida */}
                <div className="col-7"><strong>Nro. boleto ida</strong></div>
                <div className="col-3">
                  <input
                    type="text"
                    className="input-text-control"
                    name="nroBolId"
                    value={stateForm.nroBoleIda}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nroBoleIda: target.value }))}
                    autoComplete="off" />
                </div>
                {/* nro boleto regreso */}
                <div className="col-7"><strong>Nro. boleto regreso</strong></div>
                <div className="col-3">
                  <input
                    type="text"
                    className="input-text-control"
                    name="nroBolReg"
                    value={stateForm.nroBoleRegr}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nroBoleRegr: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              {/* tabla para renderizar lo relacionado */}
              <div className="table-container-mobile m-y-medium table-expense-report" style={{ overflowY: "auto" }}>
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Tipo de gasto</th>
                      <th>Anticipo RD$</th>
                      {
                        stateDetGastos[0]?.fechas.map((fec) => (
                          <th key={fec.date}>
                            {renderDate(fec.date, language)}
                          </th>
                        ))
                      }
                      <th>Relacionado</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      stateDetGastos.map((gas) => {

                        return (
                          <tr key={gas.CodTipGasto}>
                            <td style={{ width: "250px" }}>{gas.Descripcion}</td>
                            <td className="txt-right col-5" style={{ width: "175px" }}>{formatCurrency(gas.Monto, "USD")}</td>
                            {
                              stateDetGastos[0]?.fechas.map((fec) => {

                                const montoCorrespon = gas.fechas.find((f) => f.date === fec.date)?.amount;

                                return (

                                  <td className="" style={{ width: "75px" }} key={fec.date}>
                                    <input
                                      className="input-text-control"
                                      type="text"
                                      // name={`amount${monto?.CodTipGasto ? monto?.CodTipGasto : gas.CodTipGasto}-${monto?.Dia ? monto?.Dia : gas.CodTipGasto + 1}`}
                                      value={montoCorrespon}
                                      readOnly />
                                  </td>

                                )
                              })
                            }
                            <td className="txt-right" style={{ fontWeight: "bold", width: "175px" }}>{formatCurrency(gas.Relacionado, "USD")}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        )
      }
    </>
  );
};