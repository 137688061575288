import React from "react";

// ----> ASSETS IMPORTATION
import slogan_es from "../../assets/slogan-es.png";
import slogan_en from "../../assets/slogan-en.png";

export const LoadingComp = () => {

  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);


  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'fixed',
        backgroundColor: '#f4f4f47a',
        bottom: '0',
        height: '100%',
        left: '0',
        top: '3.5rem',
        right: '0',
        width: '100vw',
        zIndex: '1'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <svg style={{
            animation: 'spin 1s linear infinite',
            height: '20vw',
            width: '20vw',

          }} viewBox="0 0 50 50">
            <circle
              style={{
                fill: 'none',
                stroke: '#074f6b',
                strokeWidth: '0.1rem',
                strokeLinecap: 'round',
                animation: 'dash 2.5s ease-in-out infinite'
              }}
              cx="25"
              cy="25"
              r="20" />
          </svg>
          <h4 style={{ color: 'var(--first-color)' }}>
            {language === "es" ? "Cargando..." : "Loading..."}
          </h4>
          <div className="slogan-container">
            {language === 'es' ?
              <img src={slogan_es} alt="Global Slogan" /> :
              <img src={slogan_en} alt="Global Slogan" />}
          </div>
        </div>
      </div>
    </>
  );
};