// componente para listar las tarjetas

import { useEffect, useState } from "react";


// components import
import { ToastNotification } from "../toast-notification/ToastNotification";


// url importation
import { urls } from "../../api-urls/api-urls";
import { useNavigate } from "react-router-dom";
import { renderDate } from "../../global-functions/globalFunctions";


export const CompListTC = ({ onClose, lang, token }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [stateCards, setStateCards] = useState([]); // estado para colocar la data
  const [controller, setController] = useState(null);
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });


  // destructuring data server
  const { Cards } = dataServer && dataServer;


  // funcion para actualizar los valores
  const onHandleChangeValue = (newVal, codId = 0, comp = 0) => {
    const PropertyName = {
      1: "NomTitular",
      2: "FecExp",
      3: "NumTarjeta",
      4: "NomBanc",
      5: "Activo",
      6: "Update"
    };

    setStateCards((val) => {
      return val.map((car) => {
        if (car.CodId === codId) {
          const updateProperty = {};
          updateProperty[PropertyName[comp]] = newVal;
          return { ...car, ...updateProperty };
        }
        return car;
      });
    });
  };


  // funcion para enviar la data al servidor
  const onHandleSaveForm = async (event) => {
    event.preventDefault();

    let CountErr = 0;
    for (const tar of stateCards) {
      if (tar.Update === false) {
        CountErr++;
      }
    }

    if (CountErr === stateCards.length) {
      return setNotification((val) => ({ ...val, show: true, msg: "No hay datos para actualizar.", error: true }));
    }

    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}&action=4`, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateCards)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar los datos al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}&action=11`, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {

      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para realizar la peticion
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);

  // useEffecta para cargar las tarjetas en el estado
  useEffect(() => {
    if (Cards && Cards.length > 0) {
      setStateCards(Cards);
    }
  }, [Cards]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);

  // console.log(Cards);



  return (
    <div className="background-modal">
      {
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <form onSubmit={onHandleSaveForm} className="container-modal-users">
        <div className="modal-header">
          <h4>Tarjetas</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          {
            stateCards && stateCards.map((car) => (
              <div key={car.CodId} className="card-shadow m-y-small">
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Titular</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name={`Titu-${car.CodId}`}
                      value={car.NomTitular}
                      onChange={({ target }) => onHandleChangeValue(target.value, car.CodId, 1)} />
                  </div>
                  <div className="col-7">
                    <strong>Fecha de vencimiento</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name={`fec${car.CodId}`}
                      value={car.FecExp}
                      onChange={({ target }) => onHandleChangeValue(target.value, car.CodId, 2)} />
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Tarjeta</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name={`card-${car.CodId}`}
                      value={car.NumTarjeta}
                      onChange={({ target }) => onHandleChangeValue(target.value, car.CodId, 3)} />
                  </div>
                  <div className="col-7">
                    <strong>Banco</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name={`banc-${car.CodId}`}
                      value={car.NomBanc ?? ""}
                      onChange={({ target }) => onHandleChangeValue(target.value, car.CodId, 4)} />
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Activo</strong>
                  </div>
                  <div className="col-10">
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name={`active-${car.CodId}`}
                      checked={car.Activo}
                      onChange={({ target }) => onHandleChangeValue(target.checked, car.CodId, 5)} />
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Ingresado por</strong>
                  </div>
                  <div className="col-3">
                    <span className="txt-muted">{car.NomEmpIngreso}</span>
                  </div>
                  <div className="col-7">
                    <strong>Ingresado en</strong>
                  </div>
                  <div className="col-3">
                    <span className="txt-muted">{renderDate(car.FecIngreso, lang)}</span>
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Modificado por</strong>
                  </div>
                  <div className="col-3">
                    <span className="txt-muted">{car.NomEmpModificacion}</span>
                  </div>
                  <div className="col-7">
                    <strong>Modificado en</strong>
                  </div>
                  <div className="col-3">
                    <span className="txt-muted">{car.FecModificacion ? renderDate(car.FecModificacion, lang) : "N/A"}</span>
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Actualizar?</strong>
                  </div>
                  <div className="col-9">
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name={`update-${car.CodId}`}
                      checked={car.Update}
                      onChange={({ target }) => onHandleChangeValue(target.checked, car.CodId, 6)} />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-primary">Guardar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};