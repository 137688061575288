// componente para renderizar los pendientes de comercial

import { useEffect, useState } from "react";


// components imports
import { AplicacionesPendConfi } from "./AplicacionesPendConfi";

// urls imports
import { urls } from "../../api-urls/api-urls";

// react router dom importation
import { useNavigate } from "react-router-dom";
import { getTheToken } from "../../global-functions/globalFunctions";


export const CommercialProfile = () => {

  // GET the language
  const lang = window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [filtZona, setFiltZona] = useState([]); // estado para filtrar la zona
  const [controller, setController] = useState(null); // estado para cancelar la data al servidor
  const [state, setState] = useState({ modalA: false, modalB: false, modalC: false });
  const [stateForm, setStateForm] = useState({ empresa: "", zona: "" });


  // destructuring data server
  const { confirmAplicaPend, confirmAplica, empresa, zona, TotalApplications, empPerm } = dataServer && dataServer;


  // funcion para cerrar el modalA
  const onHandleCloseModalA = () => {
    setState((val) => ({ ...val, modalA: false }));
  };


  // funcion para realizar el fetch de las peticiones
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      const response = await fetch(`${urls.myTasks}?action=1&language=${lang}&filtro=&zona=`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para filtrar la zona
  useEffect(() => {
    if (zona && zona.length > 0) {
      const filterZona = zona && zona.filter((zo) => zo.CodEmpresa.toString() === stateForm.empresa);

      setFiltZona(filterZona);
    }
  }, [stateForm.empresa]);


  return (
    <div className="container display-data">
      {
        empPerm && empPerm[0]?.CodPerfil === 12 && (
          <>
            {empPerm && (<h3>Hola {empPerm[0]?.NomEmpleado.split(" ")[0]}, estos son tus pendientes.</h3>)}
            <form className="m-y-small">
              <div className="row-flex">
                <div className="col-7">
                  <strong>Empresa</strong>
                </div>
                <div className="col-7">
                  <select
                    name="company"
                    value={stateForm.empresa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                    <option value=""></option>
                    {
                      empresa && empresa.map((emp) => (
                        <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-10">
                  <strong>Zona</strong>
                </div>
                <div className="col-7">
                  <select
                    name="area"
                    value={stateForm.zona}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, zona: target.value }))}>
                    <option value=""></option>
                    {
                      filtZona && filtZona.map((z) => (
                        <option key={z.CodZona} value={z.CodZona}>{z.NomZona}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </form>

            {/* botones de los contadores */}

            <div className="row-flex" style={{ flexDirection: "column" }}>
              <div className="col-3 row-flex">
                <button type="button" style={{ flexGrow: "1" }} className="btn-primary" onClick={() => setState((val) => ({ ...val, modalA: true }))}>Aplicaciones pendientes por confirmar ({confirmAplicaPend && confirmAplicaPend[0]?.Total})</button>
              </div>
              <div className="col-3 row-flex">
                <button type="button" style={{ flexGrow: "1" }} className="btn-primary">Aplicaciones confirmadas ({confirmAplica && confirmAplica[0]?.Total})</button>
              </div>
              <div className="col-3 row-flex">
                <button type="button" style={{ flexGrow: "1" }} className="btn-primary">Instalaciones (7)</button>
              </div>
            </div>


            {/* tarjeta */}
            <div className="row-flex m-y-medium">
              <div className="col-5">
                <div className="card-shadow">
                  <p>
                    <strong>Servicios de aplicaciones</strong>
                  </p>
                  <p>
                    <strong>Total </strong>
                    <span className="completed"> {TotalApplications && TotalApplications[0]?.Total}</span>
                  </p>
                  <div className="row-flex justify-center">
                    <button type="button" className="btn-review" onClick={() => navigate("/commercial/service/app-list")}>Ver todo</button>
                  </div>
                </div>
              </div> <div className="col-5">
                <div className="card-shadow">
                  <p>
                    <strong>Servicios de instalación</strong>
                  </p>
                  <p>
                    <strong>Total </strong>
                    <span className="completed">  50</span>
                  </p>
                  <div className="row-flex justify-center">
                    <button type="button" className="btn-review">Ver todo</button>
                  </div>
                </div>
              </div>
            </div>


            {/* componentes */}
            {
              // componente de las aplicaciones pendientes por confirmar
              state.modalA && (
                <AplicacionesPendConfi lang={lang} token={getTheToken().token} onClose={onHandleCloseModalA} />
              )
            }
          </>
        )
      }
    </div>
  );
};