// componente para la estadistica de las etapas

import { useEffect, useState } from "react";
import { urls } from "../../api-urls/api-urls";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";




export const ServiceStatistics = ({ area, lang, token, grup }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // params
  const [params] = useSearchParams();


  // State definition
  const [etapas, setEtapas] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, dataVis: false, msg: "" });

  // destructuring data etapas
  const {
    maintCorrect,
    maintPrevent,
    installation,
    preInstallation,
    application,
    fmi,
    repoStock,
    projects,
    empPerm
  } = etapas && etapas;


  // funcion para solicitar la informacion al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.myTasksStatistics}?zona=${area}&language=${lang}&filtro=${params.get("filt") ?? "0"}&grupo=${grup}`;

    try {
      setState((val) => ({ ...val, isLoading: true, dataVis: false }));
      const response = await fetch(END_POINT, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setEtapas(data);
        setState((val) => ({ ...val, dataVis: true }));
      } else if (response.status === 400) {
        setState((val) => ({ ...val, dataVis: false, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la informacion al servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [area, lang, grup]);


  return (
    <div className="card-shadow card-info-count">
      {
        empPerm && empPerm[0]?.CodPerfil !== 10 && (
          <h5 className="title-card-info-count">{t("serviceCard.recuent_mant_correc")}</h5>
        )
      }
      {
        state.isLoading && (<h5>{t("PartModify.cargando_info")}</h5>)
      }
      {
        state.dataVis && (
          <>
            <div className="card-info-container fade-animation">
              <table className="table-card">
                <thead>
                  <tr>
                    <th>{t("filterService.steps")}</th>
                    <th>{t("serviceCard.cant_llama")}</th>
                    <th>Target</th>
                    <th>{t("serviceCard.dias_etapas")}</th>
                    <th>{t("serviceCard.dias_abiertas")}</th>
                  </tr>
                </thead>
                <tbody className="table-body-card">
                  {
                    empPerm && empPerm[0]?.CodPerfil !== 10 && (
                      <>
                        {
                          maintCorrect && maintCorrect.map((et) => (
                            <tr key={et.IdEtapa}>
                              <td>{et.Nombre}</td>
                              <td>
                                <Link className="link-tag" to={`/service?filt=1&tip_serv=1&area=${area}&nom_step=${et.IdEtapa}&grp=${grup}`}>{et.CantLlamada}</Link>
                              </td>
                              <td>{et.Target}</td>
                              <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                              <td>{et.DiasAbier}</td>
                            </tr>
                          ))
                        }
                        <tr style={{ backgroundColor: "transparent", textAlign: "left" }}>
                          <td colSpan={5} style={{ textAlign: "left" }}>
                            <h4 className="title-card-info-count m-y-small">{t("newCallComp.mant_prevent")}</h4>
                          </td>
                        </tr>
                        {
                          // mantenimiento preventivo
                          maintPrevent && maintPrevent.map((et) => (
                            <tr key={et.IdEtapa}>
                              <td>{et.Nombre}</td>
                              <td>
                                <Link className="link-tag" to={`/service?filt=1&tip_serv=2&area=${area}&nom_step=${et.IdEtapa}&grp=${grup}`}>{et.CantLlamada}</Link>
                              </td>
                              <td>{et.Target}</td>
                              <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                              <td>{et.DiasAbier}</td>
                            </tr>
                          ))
                        }
                      </>
                    )
                  }
                  <tr style={{ backgroundColor: "transparent", textAlign: "left" }}>
                    <td colSpan={5} style={{ textAlign: "left" }}>
                      <h4 className="title-card-info-count m-y-small">{t("newCallComp.installs")}</h4>
                    </td>
                  </tr>
                  {
                    // instalacion
                    installation && installation.map((et) => (
                      <tr key={et.IdEtapa}>
                        <td>{et.Nombre}</td>
                        <td>
                          <Link className="link-tag" to={`/service?filt=1&tip_serv=3&area=${area}&nom_step=${et.IdEtapa}&grp=${empPerm && empPerm[0]?.CodPerfil !== 10 ? grup : ""}`}>{et.CantLlamada}</Link>
                        </td>
                        <td>{et.Target}</td>
                        <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                        <td>{et.DiasAbier}</td>
                      </tr>
                    ))
                  }
                  <tr style={{ backgroundColor: "transparent", textAlign: "left" }}>
                    <td colSpan={5} style={{ textAlign: "left" }}>
                      <h4 className="title-card-info-count m-y-small">{t("serviceCard.preInstallation")}</h4>
                    </td>
                  </tr>
                  {
                    preInstallation && preInstallation.map((et) => (
                      <tr key={et.IdEtapa}>
                        <td>{et.Nombre}</td>
                        <td>
                          <Link className="link-tag" to={`/service?filt=1&tip_serv=10&area=${area}&nom_step=${et.IdEtapa}&grp=${empPerm && empPerm[0]?.CodPerfil !== 10 ? grup : ""}`}>{et.CantLlamada}</Link>
                        </td>
                        <td>{et.Target}</td>
                        <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                        <td>{et.DiasAbier}</td>
                      </tr>
                    ))
                  }
                  <tr style={{ backgroundColor: "transparent", textAlign: "left" }}>
                    <td colSpan={5} style={{ textAlign: "left" }}>
                      <h4 className="title-card-info-count m-y-small">{t("serviceCard.applications")}</h4>
                    </td>
                  </tr>
                  {
                    application && application.map((et) => (
                      <tr key={et.IdEtapa}>
                        <td>{et.Nombre}</td>
                        <td>
                          <Link className="link-tag" to={`/service?filt=1&tip_serv=5&area=${area}&nom_step=${et.IdEtapa}&grp=${empPerm && empPerm[0]?.CodPerfil !== 10 ? grup : ""}`}>{et.CantLlamada}</Link>
                        </td>
                        <td>{et.Target}</td>
                        <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                        <td>{et.DiasAbier}</td>
                      </tr>
                    ))
                  }
                  {
                    empPerm && empPerm[0]?.CodPerfil !== 10 && (
                      <>
                        <tr style={{ backgroundColor: "transparent", textAlign: "left" }}>
                          <td colSpan={5} style={{ textAlign: "left" }}>
                            <h4 className="title-card-info-count m-y-small">FMI/STB</h4>
                          </td>
                        </tr>
                        {
                          fmi && fmi.map((et) => (
                            <tr key={et.IdEtapa}>
                              <td>{et.Nombre}</td>
                              <td>
                                <Link className="link-tag" to={`/service?filt=1&tip_serv=4&area=${area}&nom_step=${et.IdEtapa}&grp=${grup}`}>{et.CantLlamada}</Link>
                              </td>
                              <td>{et.Target}</td>
                              <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                              <td>{et.DiasAbier}</td>
                            </tr>
                          ))
                        }
                        <tr style={{ backgroundColor: "transparent", textAlign: "left" }}>
                          <td colSpan={5} style={{ textAlign: "left" }}>
                            <h4 className="title-card-info-count m-y-small">{t("serviceCallSteps.stockRepo")}</h4>
                          </td>
                        </tr>
                        {
                          repoStock && repoStock.map((et) => (
                            <tr key={et.IdEtapa}>
                              <td>{et.Nombre}</td>
                              <td>
                                <Link className="link-tag" to={`/service?filt=1&tip_serv=7&area=${area}&nom_step=${et.IdEtapa}&grp=${grup}`}>{et.CantLlamada}</Link>
                              </td>
                              <td>{et.Target}</td>
                              <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                              <td>{et.DiasAbier}</td>
                            </tr>
                          ))
                        }
                      </>
                    )
                  }
                  <tr style={{ backgroundColor: "transparent", textAlign: "left" }}>
                    <td colSpan={5} style={{ textAlign: "left" }}>
                      <h4 className="title-card-info-count m-y-small">{t("navigationMenu.projects")}</h4>
                    </td>
                  </tr>
                  {
                    projects && projects.map((et) => (
                      <tr key={et.IdEtapa}>
                        <td>{et.Nombre}</td>
                        <td>
                          <Link className="link-tag" to={`/service?filt=1&tip_serv=9&area=${area}&nom_step=${et.IdEtapa}&grp=${empPerm && empPerm[0]?.CodPerfil !== 10 ? grup : ""}`}>{et.CantLlamada}</Link>
                        </td>
                        <td>{et.Target}</td>
                        <td style={{ color: et.Color }}>{et.DiasEtapas}</td>
                        <td>{et.DiasAbier}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

          </>
        )
      }
    </div>
  );
};