import React, { useState, useEffect } from "react";

/*
---- Este componente es para ver el detalle de la solicitud y poder agregar las partes
*/

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// COMPONENTS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { AddPartComp } from "../../components/logistic-components/AddPartComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// USE TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";


export const SolRepuestoDetailView = () => {

  const [t] = useTranslation("global");

  // GET THE TOKEN
  const token = getTheToken().token;
  // LANGUAGE
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // NAVIGATE
  const navigate = useNavigate();

  // GET the useLocation()
  const location = useLocation();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dispatch, setDispatch] = useState(false);
  const [stateForm, setStateForm] = useState({ priority: "", tipSolicitud: "", action: "", codSol: 0, codDet: 0 });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, newUpdate: false });
  const [state, setState] = useState({ showModal: false, questionAction: "", dataVisible: false, showAddPartComp: false });

  // Etapas
  const SOL_REP = 10; // Solicitud de repuesto
  const ORDEN_COMPRA = 20; // Orden de compra

  // DESTRUCTURING DATA SERVER
  const { solRepuesto, detSolRepuesto, employeesPermission } = dataServer && dataServer;

  const { solRepuestoId } = useParams();



  // Funcion para abrir el modal cuando se de click en enviar
  const handleSendOpenMod = () => {
    setState((val) => ({ ...val, showModal: true, questionAction: t("LogisticViewComp.enviar_apro_tec") }));
    setStateForm((val) => ({ ...val, action: "1" }));
  };

  // Funcion para abrir el modal cuando se de click en eliminar
  const handleDeleteOpenMod = () => {
    setState((val) => ({ ...val, showModal: true, questionAction: t("LogisticViewComp.eliminar_solicitud") }));
    setStateForm((val) => ({ ...val, action: "2" }));
  };

  // Funcion para cerrar el componente de agregar partes
  const onCloseAddPartsComp = () => {
    setState((val) => ({ ...val, showAddPartComp: false }));
    // setDispatch(true);
  };


  // funcion para actualizar la data desde el hijo
  const onUpdateFromChild = (newVal) => {
    setDispatch(newVal)
  };


  // funcion para eliminar un repuesto
  const onHandleDeletePart = async (codSol = 0, codDet = 0) => {

    setStateForm((val) => ({ ...val, codSol, codDet, action: "3" }));

    setState((val) => ({ ...val, showModal: true, questionAction: t("LogisticViewComp.eliminar_pn") }));

  };



  // SOLICITUD DE DATOS AL SERVIDOR
  const fetchDataServer = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${urls.requestDetail}/${solRepuestoId}/search?language=${language}`, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      // VALIDACIONES
      if (response.status === 200) {
        setDataServer(data);
        setDispatch(false);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) { // verificar si el token aún es valido
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 400) {
        // bad request, enviar mensaje al usuario
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect
  useEffect(() => {

    fetchDataServer();


  }, [solRepuestoId, language]);


  // useEffect para solicitar la nueva informacion cuando se agregue un nuevo part number
  useEffect(() => {
    if (dispatch) {
      fetchDataServer();
    }

    return () => {
      setDispatch(false);
    }
  }, [dispatch]);



  // useEffect para actualizar la solicitud por el cambio de etapa
  useEffect(() => {
    if (notification.newUpdate) {
      fetchDataServer();
    }

  }, [notification.newUpdate]);

  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, newUpdate: false });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);

  // useEffect para actualizar el estado del tipo de solicitud y prioridad
  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0) {
      setStateForm((val) => ({ ...val, priority: solRepuesto[0]?.Prioridad?.toString() ?? "", tipSolicitud: solRepuesto[0]?.IdTipSolicitud.toString() ?? "" }));
    }
  }, [solRepuesto]);


  // OnSubmit request para enviar a aprobacion tecnica
  const onSubmitRequest = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.partDetail}/${solRepuestoId}/search?language=${language}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, newUpdate: true }));
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {
        // loading component
        isLoading && (<LoadingComp />)
      }
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)
      }

      <div className="container display-data">

        {
          state.dataVisible && (
            <form onSubmit={onSubmitRequest}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* SOLICITUD */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.request")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <h5>{solRepuestoId}</h5>
                </div>
                {/* ID SERVICIO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.serviceID")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <h5>
                    <Link className="link-tag" to={`/service/service-call/${solRepuesto && solRepuesto[0]?.CodServicio}`}>{solRepuesto && solRepuesto[0]?.CodServicio}</Link>
                  </h5>
                </div>
                {/* MODELO */}
                <div className="col-10 col-sm-20">
                  <strong>{t("LogisticViewComp.model")}</strong>
                </div>
                <div className="col-7 col-sm-30">
                  <span>{solRepuesto && solRepuesto[0]?.Modelo}</span>
                </div>
                {/* SERIAL */}
                <div className="col-10 col-sm-20">
                  <strong>Serial</strong>
                </div>
                <div className="col-sm-30">
                  <span>{solRepuesto && solRepuesto[0]?.Serial}</span>
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* FECHA/HORA */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.dateTime")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <span>{solRepuesto && renderDate(solRepuesto[0]?.FecIngreso, language)}</span>
                </div>
                {/* SUPLIDOR */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.supplier")}</strong>
                </div>
                <div className="col-3 col-sm-30">
                  <span>{solRepuesto && solRepuesto[0]?.NomProveedor}</span>
                </div>
                {/* INSTALACION */}
                <div className="col-10 col-sm-20">
                  <strong>{t("LogisticViewComp.instalation")}</strong>
                </div>
                <div className="col-sm-30">
                  <span></span>
                </div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* TIPO DE SOLICITUD */}
                <div className="col-9 col-sm-20">
                  <strong>{t("TipSol.tip_sol")}</strong>
                </div>
                <div className="col-3 col-sm-80">
                  <select
                    name="tipSol"
                    value={stateForm.tipSolicitud}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipSolicitud: target.value }))}>
                    <option value="0">{t("TipSol.coti")}</option>
                    <option value="3">Missing in Shipment</option>
                    <option value="1">{t("TipSol.garan")}</option>
                    <option value="4">Dead on Arrival</option>
                    <option value="2">{t("TipSol.contra_perfo")}</option>
                    <option value="5">{t("TipSol.ord_compr")}</option>
                  </select>
                </div>
                {/* GON */}
                <div className="col-9 col-sm-20">
                  <strong>GON</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <span></span>
                </div>
                {/* O/C */}
                <div className="col-9 col-sm-20">
                  <strong>O/C</strong>
                </div>
                <div className="col-sm-30">
                  <span></span>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.customer")}</strong>
                </div>
                <div className="col-3 col-sm-80 text-hidden-over">
                  <Link className="link-tag" to={`/customers/customer-detail/${solRepuesto && solRepuesto[0]?.CodCliente}`}>{solRepuesto && solRepuesto[0]?.NomCliente}</Link>
                </div>
                {/* CONTRATO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.contract")}</strong>
                </div>
                <div className="col-8 col-sm-80">
                  <span>{solRepuesto && solRepuesto[0]?.NomContrato}</span>
                </div>
                {/* PRIORIDAD */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.priority")}</strong>
                </div>
                <div className="col-7 col-sm-80">
                  <div className="row-flex j-content-space-around box-temperature col-70-sm">
                    <div className="input-control bg-green circle-temp">
                      <input
                        type="radio"
                        value="0"
                        name="temperature"
                        checked={stateForm.priority === "0"}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, priority: target.value }))} />
                    </div>
                    <div className="input-control bg-yellow circle-temp">
                      <input
                        type="radio"
                        value="1"
                        name="temperature"
                        checked={stateForm.priority === "1"}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, priority: target.value }))} />
                    </div>
                    <div className="input-control bg-red circle-temp">
                      <input
                        type="radio"
                        value="4"
                        name="temperature"
                        checked={stateForm.priority === "4"}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, priority: target.value }))} />
                    </div>
                  </div>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* FALLA */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.failure")}</strong>
                </div>
                <div className="col-3 col-sm-80">
                  <span style={{ textTransform: 'capitalize', color: 'var(--danger-color)', textDecoration: 'underline' }}>{solRepuesto && solRepuesto[0]?.Falla.toLowerCase()}</span>
                </div>
                {/* EQUIPO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.equipment")}</strong>
                </div>
                <div className="col-43 col-sm-80">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${solRepuesto && solRepuesto[0]?.CodEquipo}`}>{solRepuesto && solRepuesto[0]?.Equipo}</Link>
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* INGENIERO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.tecnicalEng")}</strong>
                </div>
                <div className="col-3 col-sm-80">
                  <span>{solRepuesto && solRepuesto[0]?.NomEmpIngreso}</span>
                </div>
                {/* ETAPA */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.etapa")}</strong>
                </div>
                <div className="col-sm-80">
                  <span style={{ color: 'var(--green-color)', fontWeight: 'bold', textDecoration: 'underline' }}>{solRepuesto && solRepuesto[0]?.NomEtapa}</span>
                </div>
              </div>

              {/* Boton para agregar las partes */}
              {
                solRepuesto && solRepuesto[0]?.IdEtapa <= ORDEN_COMPRA && (
                  <div className="row-flex">
                    <div className="col-9 col-sm-20">
                      <strong>{t("LogisticViewComp.agregar_part")}</strong>
                    </div>
                    <div className="col-9 col-sm-80">
                      <button type="button" className="btn-add" onClick={() => setState((val) => ({ ...val, showAddPartComp: true }))}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                )
              }

              {/* TABLA PARA MOSTRAR LAS PARTES QUE TIENE ESTA SOLICITUD */}
              {
                detSolRepuesto && detSolRepuesto.length > 0 ? (
                  <div className="table-container-mobile m-y-medium-table">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th></th>
                          <th>#</th>
                          <th>{t("TableParts.canti")}</th>
                          <th>{t("TableParts.parte")}</th>
                          <th>{t("TableParts.descrip")}</th>
                          <th>{t("TableParts.eta")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          detSolRepuesto && detSolRepuesto.map((det) => (
                            <tr key={det.CodDetSolRepuesto}>
                              <td className="txt-center">
                                {solRepuesto && solRepuesto[0]?.IdEtapa <= ORDEN_COMPRA && (<button type="button" className="btn-close-delete" onClick={() => onHandleDeletePart(det.CodSolRepuesto, det.CodDetSolRepuesto)}>X</button>)}
                              </td>
                              <td className="txt-center">
                                <Link className="link-tag">{det.CodDetSolRepuesto}</Link>
                              </td>
                              <td className="txt-center">{det.Cantidad}</td>
                              <td>
                                <Link className="link-tag" to={`/part-detail/${det.CodDetSolRepuesto}/${det.CodSolRepuesto}`}>{det.NumParte}</Link>
                              </td>
                              <td>{det.Descripcion}</td>
                              <td>{det.NomEtapa}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-medium-table"><strong className="required" style={{ textDecoration: 'underline' }}>{t("PartModify.no_partes")}</strong></div>)
              }

              {/* BOTONES */}
              <div className="row-flex m-y-medium j-content-space-around">
                {
                  solRepuesto && solRepuesto[0]?.IdEtapa === SOL_REP && (<button type="button" className="btn-primary" onClick={handleSendOpenMod}>{t("RequestPartsView.enviar")}</button>)
                }
                {/* Boton de eliminar */}
                {
                  solRepuesto && (solRepuesto[0]?.IdEtapa <= ORDEN_COMPRA || (employeesPermission[0]?.Coordinador && employeesPermission[0]?.CodPerfil === 3) || employeesPermission[0]?.CodPerfil === 6) && (
                    <button type="button" className="btn-danger" onClick={handleDeleteOpenMod}>{t("RequestPartsView.eliminar")}</button>
                  )
                }
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                        <button type="reset" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }


        {/* Componente para agregar partes */}
        {state.showAddPartComp && (<AddPartComp solRepuesto={solRepuestoId} onClose={onCloseAddPartsComp} employeePerm={employeesPermission} onUpdate={onUpdateFromChild} />)}

      </div >
    </>
  );
};