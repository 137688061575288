import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";

// urls importation
import { urls } from "../../api-urls/api-urls";


// REACT ROUTER DOM IMPORTATION
import { Link, useNavigate } from "react-router-dom";


// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";


export const AdministrationPending = () => {

  // get the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  const labelFacturar = language === "es" ? "Facturar" : "Bill";

  // NAVIGATE
  const navigate = useNavigate();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // DESTRUCTURING DATA SERVER
  const { pendientesFacturar, allSteps, employeePermisions } = dataServer && dataServer;

  const employeesPermission = [4, 7, 12, 5, 3, 6];


  // useEffect
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(urls.getPedingAdministraction, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }
        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, []);



  return (
    <>
      {
        isLoading && (<LoadingComp />)
      }
      {
        employeePermisions && employeesPermission.includes(employeePermisions[0].CodPerfil) ? (
          <>
            <div className="container display-data">
              <h4 className="m-y-small" style={{ color: 'var(--first-color)' }}>Servicios Pendientes por Facturar</h4>
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Cotización</th>
                      <th>Fecha</th>
                      <th>Equipo</th>
                      <th>Cliente</th>
                      <th>Problema</th>
                      <th>Responsable</th>
                      <th>Etapa</th>
                      <th>Tipo Servicio</th>
                      <th>Acción</th>
                      <th>Días</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      pendientesFacturar && pendientesFacturar.map((fact, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td><Link className="link-tag">{fact.CodServicio}</Link></td>
                          <td>{fact.cotizaciones.map((coti, indexCoti) => (
                            <Link key={indexCoti} className="link-tag">{coti.Cotizacion}</Link>
                          ))}</td>
                          <td>{renderDate(fact.FecIngreso, language)}</td>
                          <td>{fact.CodEquipo}</td>
                          <td><Link className="link-tag">{fact.NomCliente}</Link></td>
                          <td style={{ textTransform: 'capitalize' }}>{fact.Problema.toLowerCase() || ""}</td>
                          <td>{fact.NomEmpleado}</td>
                          <td>{fact.IdEtapa}</td>
                          <td>{fact.IdTipServicio}</td>
                          <td style={{ textAlign: 'center' }}>{fact.facturas.map((factu, indexFact) => (
                            <Link key={indexFact} className="link-tag">
                              {factu.NumFactura === null ? (<>{labelFacturar} <br /> {factu.CodCotizacion} <br /> {formatCurrency(factu.Monto, "USD")}</>) : (<>Cot. {factu.CodCotizacion} <br /> {formatCurrency(factu.Monto, "USD")} <br /> {factu.NumFactura}</>)}
                            </Link>
                          ))}</td>
                          <td>{fact.Dias}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

            </div>
          </>
        ) : (<Error403View />)
      }
    </>
  );
};