
import React from "react";


export const Error500Comp = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  return (
    <>
      <div className="container">
        <div className="container-error-500">
          <div className="container-circle-error">
            <div className="circle-error-noti">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
          <h1 style={{ textAlign: 'center', color: 'var(--first-color)' }}>Internal Server Error - 500</h1>
          <p style={{ textAlign: 'center' }}>
            {
              language === "en" ?
                "A server error occurred while processing the request, but don’t worry the administrators have already been notified and will be communicating with you as soon as possible."
                :
                "Ocurrió un error en el servidor al procesar la solicitud, pero descuide ya los administradores han sido notificados y estarán comunicandose con usted lo más pronto posible."
            }
            &#128521;
          </p>
        </div>
      </div>
    </>
  );
};