// Este componente es para mostrar la lista de los anticipos

import React, { useEffect, useState } from "react";


// Components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { SearchCompAnticipo } from "../../components/travel-expenses/SearchCompAnticipo";


// React router importation
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// Url importation
import { urls } from "../../api-urls/api-urls";

// Global functions import
import { formatCurrency, getTheToken } from "../../global-functions/globalFunctions";

// Translation
import { useTranslation } from "react-i18next";



export const AnticipoPrincipal = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();


  // GET the useSearchParams
  const [params, setParams] = useSearchParams();


  // State definition
  const [controller, setController] = useState(null); // controlador para abortar la peticion al servidor
  const [solGastoList, setSolGastoList] = useState([]); // estado para cargar la lista de las solicitudes
  const [currentPage, setCurrentPage] = useState(1); // Estado para cambiar el numero de las paginas
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, notFound: false, serverError: false, showModal: false, msg: "" });
  const [stateForm, setStateForm] = useState({ empresa: params.get("id_empr") ?? "", zona: params.get("id_area") ?? "", responsable: params.get("nom_respon") ?? "", etapa: params.get("id_eta") ?? "", division: params.get("id_divi") ?? "", gerencia: params.get("id_geren") ?? "", areaFun: params.get("id_unid") ?? "", nroControl: params.get("nro_control") ?? "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [moreData, setMoreData] = useState(false);


  // console.log(divisionEmpresa)

  const pageSize = 20; // Definicion del size de la pagina

  // const isLastPage = solGastoList && solGastoList.length < pageSize;


  // funcion para setear el estado del componente padre desde el hijo
  const onSetStateFromChild = (newVal = [], val = false) => {
    setSolGastoList(newVal);
    setMoreData(val)
  };


  // funcion para cambiar el valor del numero de control
  const onHandleChangeVal = (newVal = "") => {
    if (isNaN(newVal)) {
      return;
    }
    setStateForm((val) => ({ ...val, nroControl: newVal }));
    params.set("nro_control", newVal);
    setParams(params);
  };



  // Funcion para abrir el modal del filtro
  const onShowFilter = () => {
    if (stateForm.nroControl !== "") {
      setStateForm((val) => ({ ...val, nroControl: "" }));
    }

    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // funcion para traer la data del servidor
  const fetchDataServer = async () => {
    const END_POINT = `${urls.anticipo}?language=${language}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&responsable=${stateForm.responsable}&etapa=${stateForm.etapa}&division=${stateForm.division}&gerencia=${stateForm.gerencia}&areaFun=${stateForm.areaFun}&page=${currentPage}&pageSize=${pageSize}&nroControl=${stateForm.nroControl}&action=2&filt=${params.get("filt") ?? "0"}`;

    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      // console.log(data)

      if (response.status === 200) {
        // setDataServer(data);
        setCurrentPage(currentPage + 1);
        if (data?.solGastoList.length < pageSize) {
          setCurrentPage(1);
        }

        setSolGastoList([...solGastoList].concat(data?.solGastoList));
        setMoreData(data?.solGastoList.length < pageSize);

        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: true, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);



  // Funcion para buscar por el nro de control (solamente)
  const onSearchByNroControl = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.anticipo}?language=${language}&empresa=&zona=&responsable=&etapa=&division=&gerencia=&areaFun=&page=${currentPage}&pageSize=${pageSize}&nroControl=${stateForm.nroControl}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        // setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 404) {
        setNotification({ show: true, msg: data?.msg, error: true, great: false });
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              {/* FILA */}
              <div className="row-flex j-content-space-b">
                <div className="col-3">
                  <form onSubmit={onSearchByNroControl} className="row-flex">
                    <div className="col-3"><strong>{t("Viaticos.nro_control")}</strong></div>
                    <div className="col-39-2">
                      <input
                        className="input-text-control"
                        type="text"
                        name="nroControl"
                        value={stateForm.nroControl}
                        onChange={({ target }) => onHandleChangeVal(target.value)} />
                    </div>
                    <button type="submit" className="btn-success">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </form>
                </div>
                <div>
                  <button type="button" className="btn-success row-flex align-c gap-small" onClick={() => navigate("/travel-expenses/create-advance")}>
                    {t("Viaticos.nuevo")}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                </div>
              </div>

              {
                state.msg !== "" && (
                  <h4 className="m-y-small" style={{ color: "var(--danger-color)" }}>{state.msg}</h4>
                )
              }

              {/* Tabla para mostrar la info */}
              {
                solGastoList && solGastoList.length > 0 && (
                  <div className="table-container-mobile m-y-small">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th>{t("Viaticos.nro_control")}</th>
                          <th>{t("Viaticos.empresa")}</th>
                          <th>{t("Viaticos.responsable")}</th>
                          <th>{t("Viaticos.etapa")}</th>
                          <th>{t("Viaticos.moneda")}</th>
                          <th>{t("Viaticos.anti_soli")}</th>
                          <th>{t("Viaticos.anti_aprob")}</th>
                          <th>{t("Viaticos.rela_soli")}</th>
                          <th>{t("Viaticos.rela_aprob")}</th>
                          <th>{t("Viaticos.comentario")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          solGastoList && solGastoList.map((solGas) => (
                            <tr key={solGas.CodSolGasto}>
                              <td className="txt-center">
                                <Link className="link-tag" to={`/travel-expenses/expense-detail/${solGas.CodSolGasto}`}>{solGas.CodSolGasto}</Link>
                              </td>
                              <td>{solGas.NomEmpresa}</td>
                              <td>{solGas.NomEmpleado}</td>
                              <td>{solGas.Etapa}</td>
                              <td className="txt-center">{solGas.Moneda}</td>
                              <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                                {formatCurrency(solGas.Anticipo, "USD")}
                              </td>
                              <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                                {formatCurrency(solGas.AntAprobado, "USD")}
                              </td>
                              <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                                {formatCurrency(solGas.Relacionado, "USD")}
                              </td>
                              <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                                {formatCurrency(solGas.RelAprobada, "USD")}
                              </td>
                              <td>{solGas.Comentario}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                )
              }

              <div className="row-flex align-c justify-center m-y-small">
                {
                  solGastoList && solGastoList.length > 0 && (
                    <>
                      {
                        moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button type="button" className="btn-primary" onClick={fetchDataServer}>{t("serviceCard.ver_mas")}</button>
                      }
                    </>
                  )

                }
              </div>

              <ButtonSearch onOpenFilter={onShowFilter} />

              {
                state.showModal && (
                  <SearchCompAnticipo lang={language} onShowFilter={onShowFilter} token={getTheToken().token} newState={onSetStateFromChild} />
                )
              }
            </>
          )
        }
      </div>
    </>
  );
};