import React, { useEffect, useState } from "react";

// en el orden, este es el reporte nro. 11


// components import
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// REACT ROUTER DOM IMPORTATION
import { useNavigate } from "react-router-dom";

// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";


export const LogisticProjectionOrdersView = () => {

  // GET THE LANGUAGE
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // NAVIGATE
  const navigate = useNavigate();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // state para obtener los datos del servidor
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });

  // DESTRUCTURING DATA SERVER
  const { resultReport } = dataServer && dataServer;

  // en el orden de los reportes este es el nro. 11
  const body = {
    report: 11
  }

  // console.l

  const fetchDataServer = async () => {
    try {
      // OBTENER LOS PARAMETROS DE LA URL PARA CREAR EL REPORTE
      const urlParams = new URLSearchParams(document.location.search);

      const params = {
        division: urlParams.get('division'),
        supplier: urlParams.get('supplier'),
        customer: urlParams.get('customer'),
        line: urlParams.get('line'),
        modality: urlParams.get('modality'),
        tipEquipment: urlParams.get('tipEquipment'),
        model: urlParams.get('model'),
        zone: urlParams.get('zone'),
        fromDate: urlParams.get('fromDate'),
        untilDate: urlParams.get('untilDate'),
        step: urlParams.get('step')
      };

      const response = await fetch(`${urls.logisticReports}?division=${params.division}&supplier=${params.supplier}&customer=${params.customer}&line=${params.line}&modality=${params.modality}&tipEquipment=${params.tipEquipment}&model=${params.model}&company=${params.company}&zone=${params.zone}&fromDate=${params.fromDate}&untilDate=${params.untilDate}&step=${params.step}&language=${language}&nroParte=&workflow=`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": getTheToken().token
        },
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        // mensaje de que no se encontró nada
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 500) {
        // mensaje de que hubo un error en el servidor
      }

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect declaration
  useEffect(() => {

    fetchDataServer();
  }, []);


  // useEffect para limpiar las notification
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  return (
    <>
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      <div className="container">
        <h1>Reportes</h1>
        {
          !!resultReport && !!resultReport.length > 0 && (
            <div className="">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>Item #</th>
                    <th>Orden</th>
                    <th>Tipo</th>
                    <th>Llamada</th>
                    <th>Zona</th>
                    <th>Cliente</th>
                    <th>Línea</th>
                    <th>Equipo</th>
                    <th>Nro. Parte</th>
                    <th>Descripción</th>
                    <th>Cant.</th>
                    <th>Precio</th>
                    <th>Total</th>
                    <th>Etapa</th>
                    <th>Carrier</th>
                    <th>Guía</th>
                    <th>Fecha Despacho (EDD)</th>
                    <th>Fecha Llegada (LLA)</th>
                    <th>Comentario</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    resultReport && resultReport.map((report, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{report.NumOrden}</td>
                        <td>{report.nomAprobacion}</td>
                        <td className="txt-center">{report.CodServicio}</td>
                        <td>{report.NomZona}</td>
                        <td>{report.Cliente}</td>
                        <td>{report.Modalidad}</td>
                        <td>{report.Modalidad} {report.Modelo}</td>
                        <td>{report.NumParte}</td>
                        <td>{report.Descripcion}</td>
                        <td>{report.Cantidad}</td>
                        <td className="txt-center">{formatCurrency(report.Precio, "USD")}</td>
                        <td className="txt-center">{formatCurrency(report.Cantidad * report.Precio, "USD")}</td>
                        <td>{report.NomEtapa}</td>
                        <td>{report.Carrier}</td>
                        <td>{report.GuiDespacho}</td>
                        <td>{renderDate(report.FecDespacho, language)}</td>
                        <td>{renderDate(report.FecLlegada, language)}</td>
                        <td style={{ textTransform: "capitalize" }}>{report.Accion}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </div>
    </>
  );
};