// REACT IMPORTATION
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { ContactsComp } from "../../components/customer-components/ContactsComp";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken } from "../../global-functions/globalFunctions";

// REACT TRANSLATION
import { useTranslation } from "react-i18next";
import { EquipmentsList } from "../../components/customer-components/EquipmentsList";



export const CustomerModifyView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useParams
  const { codCliente } = useParams();

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the react translation
  const [t] = useTranslation("global");


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para abortar la peticion de datos al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false, unauthorized: false, modalAddCont: false, update: false });
  const [stateForm, setStateForm] = useState({
    nombreCliente: "", ciudad: "", rncCliente: "", vendedor: "", pais: "", edificio: "", estado: "", codigoPostal: "",
    tecnicoResponsable: "", fax: "", aveCalle: "", paginaWeb: "", urbSector: "", codTelefono: "", telefono: "", correo: "",
    tipoPersona: "", institucion: "", cargo: "", condicionesCredito: "", observaciones: "", activo: true, sector: ""
  });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // DESTRUCTURING DATA SERVER
  const { customer, ServiceEmplo, SalesEmplo, Estado, Ciudad } = dataServer && dataServer;



  // HANDLE SUBMIT TO UPDATE THE CUSTOMER
  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, update: true }));
      const response = await fetch(`${urls.getCustomer}/${codCliente}/search?language=${language}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      }

      setState((val) => ({ ...val, update: false }));

    } catch (error) {
      console.log(error);
    }
  };


  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.getCustomer}/${codCliente}/search?language=${language}`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }))
        // si el usuario no se encuentra autorizado
      } else if (response.status === 500) {
        // si ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    // para modificar el titulo del documento
    document.title = language === "en" ? "Modify Customer" : "Modificar Cliente";

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codCliente, language]);


  // useEffect para agregar los datos del servidor al estado y poder modificarlos
  useEffect(() => {
    if (customer && customer.length > 0) {
      setStateForm((val) => ({
        ...val, nombreCliente: customer[0]?.Nombre, ciudad: customer[0]?.CodCiudad?.toString() ?? "", rncCliente: customer[0]?.CodCliente, vendedor: customer[0]?.CodEmpVenta?.toString() ?? "", pais: customer[0]?.CodPais?.toString() ?? "", edificio: customer[0]?.EdiQta ?? "",
        estado: customer[0]?.CodEstado?.toString() ?? "", codigoPostal: customer[0]?.CodPostal ?? "", tecnicoResponsable: customer[0]?.CodEmpServicio ?? "", fax: customer[0]?.Fax ?? "", aveCalle: customer[0]?.AveCalle ?? "",
        paginaWeb: customer[0]?.PagWeb ?? "", urbSector: customer[0]?.UrbSector ?? "", codTelefono: customer[0]?.CodTelefono ?? "", telefono: customer[0]?.Telefono ?? "", correo: customer[0]?.Correo ?? "",
        tipoPersona: customer[0]?.TipPersona?.toString() ?? "", institucion: customer[0]?.Institucion ?? "", cargo: customer[0]?.Cargo ?? "", condicionesCredito: customer[0]?.ConPago ?? "", observaciones: customer[0]?.Observaciones ?? "",
        activo: customer[0]?.Activo, sector: customer[0]?.Sector?.toString() ?? ""
      }));
    }
  }, [customer]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  return (
    <>
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {/* unauthorized component */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {
        state.dataVisible && (
          <div className="display-data container">
            <div className="card-new-customer row-flex"
              style={{
                flexDirection: 'column',
                gap: '0.7rem',
                margin: '0 0.3rem 0.8rem 0'
              }}>

              <form style={{ flexDirection: 'column', gap: '0.7rem', padding: '0 0.5rem 0 0.5rem' }} onSubmit={handleUpdate}>
                {/* FILA 1*/}
                <div className="row-flex" >
                  {/* NOMBRE DEL CLIENTE */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.name")}
                    </strong>
                    <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      type="text"
                      name="customerName"
                      value={stateForm.nombreCliente}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, nombreCliente: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerNamePlaceHold")} />
                  </div>
                  {/* CIUDAD DEL CLIENTE */}
                  <div className="col-7">
                    <strong>
                      {t("createCustomerComp.city")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="ciudad"
                      value={stateForm.ciudad}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, ciudad: target.value }))}>
                      <option value=""></option>
                      {
                        Ciudad && Ciudad.filter((filt) => filt.CodPais.toString() === stateForm.estado).map((city) => (
                          <option key={city.CodEstado} value={city.CodEstado}>{city.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {/* FILA 2 */}
                <div className="row-flex">
                  {/* RNC O TAX ID */}
                  <div className="col-5">
                    <strong>
                      RNC / Tax ID
                    </strong>
                    <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      type="text"
                      name="codeCustomer"
                      value={stateForm.rncCliente}
                      readOnly
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerTaxPlaceHold")} />
                  </div>
                  {/* VENDEDOR */}
                  <div className="col-7">
                    <strong>
                      {t("createCustomerComp.salesPerson")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="vendedor"
                      value={stateForm.vendedor}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, vendedor: target.value }))}>
                      <option value=""></option>
                      {
                        SalesEmplo && SalesEmplo.map((emplo) => (
                          <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {/* FILA 3 */}
                <div className="row-flex">
                  <div className="col-5">
                    {/* PAIS */}
                    <strong>
                      {t("createCustomerComp.country")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="pais"
                      value={stateForm.pais}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, pais: target.value }))}>
                      <option value=""></option>
                      <option value="1">República Dominicana</option>
                      <option value="3">Islas del caribe</option>
                    </select>
                  </div>
                  {/* EDIFICIO */}
                  <div className="col-7">
                    <strong>
                      {t("createCustomerComp.building")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="customerBuilding"
                      value={stateForm.edificio}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, edificio: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerBuildingPlaceHold")} />
                  </div>
                </div>
                {/* FILA 4 */}
                <div className="row-flex">
                  {/* PROVINCIA */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.state")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="estado"
                      value={stateForm.estado}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, estado: target.value }))}>
                      <option value=""></option>
                      {
                        Estado && Estado.map((state) => (
                          <option key={state.CodEstado} value={state.CodEstado}>{state.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* CODIGO POSTAL */}
                  <div className="col-7">
                    <strong>
                      {t("createCustomerComp.postalCode")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="postalCode"
                      value={stateForm.codigoPostal}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, codigoPostal: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerPostalCodePlaceHold")} />
                  </div>
                </div>
                {/* FFILA 5 */}
                <div className="row-flex">
                  {/* TECNICO RESPONSABLE */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.responsibleTechnician")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="tecnicoReponsable"
                      value={stateForm.tecnicoResponsable}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, tecnicoResponsable: target.value }))}>
                      <option value=""></option>
                      {
                        ServiceEmplo && ServiceEmplo.map((emplo) => (
                          <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* FAX DEL CLIENTE */}
                  <div className="col-7">
                    <strong>
                      Fax
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="faxCustomer"
                      value={stateForm.fax}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fax: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerFaxPlaceHold")} />
                  </div>
                </div>
                {/* FILA 6 */}
                <div className="row-flex">
                  <div className="col-5">
                    {/* AVENIDA CALLE */}
                    <strong>
                      {t("createCustomerComp.avenue")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="streetCustomer"
                      value={stateForm.aveCalle}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, aveCalle: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerAvenuePlaceHold")} />
                  </div>
                  {/* PAGINA WEB */}
                  <div className="col-7">
                    <strong>
                      {t("createCustomerComp.webPage")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="customerWebPage"
                      value={stateForm.paginaWeb}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, paginaWeb: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerWEBPagePlaceHold")} />
                  </div>
                </div>
                {/* FILA 7 */}
                <div className="row-flex">
                  {/* URBANIZACION SECTOR */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.urbSector")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="urbSectorCustomer"
                      value={stateForm.urbSector}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, urbSector: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerUrbPlaceHold")} />
                  </div>
                </div>
                {/* FILA 8 */}
                <div className="row-flex">
                  {/* TELEFONO */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.telephone")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      value={`${stateForm.codTelefono} ${stateForm.telefono}`}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, telefono: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerTelephonePlaceHold")} />
                  </div>
                </div>
                {/* FILA 9 */}
                <div className="row-flex">
                  {/* CORREO */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.email")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="customerEmail"
                      value={stateForm.correo}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, correo: target.value }))}
                      autoComplete="off"
                      placeholder={t("createCustomerComp.customerEmailPlaceHold")} />
                  </div>
                </div>
                {/* FILA 10 */}
                <div className="row-flex">
                  {/* seleccionar el tipo de persona (natural - juridica) */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.personType")}
                    </strong>
                    <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span>
                  </div>
                  <div className="row-flex col-4 gap-small align-c">
                    {/* Colocar etiqueta para cada input radio */}
                    <span>Natural</span>
                    <input
                      style={{ width: '0.8rem', height: '0.8rem' }}
                      type="radio"
                      name="tipoPersona"
                      value="0"
                      checked={stateForm.tipoPersona === '0'}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, tipoPersona: target.value }))}
                    />
                    <span>Juridica</span>
                    <input
                      style={{ width: '0.8rem', height: '0.8rem' }}
                      type="radio"
                      name="tipoPersona"
                      value="1"
                      checked={stateForm.tipoPersona === '1'}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, tipoPersona: target.value }))}
                    />
                  </div>
                  {/* seleccionar el tipo de sector */}
                  <div className="col-7"><strong>Sector</strong></div>
                  <div className="row-flex col-4 gap-small align-c">
                    {/* Colocar etiqueta para cada input radio */}
                    <span>Público</span>
                    <input
                      style={{ width: '0.8rem', height: '0.8rem' }}
                      type="radio"
                      name="sector"
                      value="0"
                      checked={stateForm.sector === '0'}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, sector: target.value }))}
                    />
                    <span>Privado</span>
                    <input
                      style={{ width: '0.8rem', height: '0.8rem' }}
                      type="radio"
                      name="sector"
                      value="1"
                      checked={stateForm.sector === '1'}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, sector: target.value }))}
                    />
                  </div>
                </div>
                {/* FILA 11 */}
                <div className="row-flex">
                  {/* INSTITUCION */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.institution")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="instituCustomer"
                      value={stateForm.institucion}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, institucion: target.value }))}
                      autoComplete="off" />
                  </div>
                  {/* INGRESAR EL CARGO */}
                  <div className="col-7">
                    <strong>
                      {t("createCustomerComp.position")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      style={{ width: '100%' }}
                      type="text"
                      name="cargoCustomer"
                      value={stateForm.cargo}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, cargo: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>
                {/* FILA 12 */}
                <div className="row-flex">
                  {/* CONDICIONES DE CREDITO */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.creditTerms")}
                    </strong>
                  </div>
                  <div className="col-100">
                    <textarea
                      className="textarea-description"
                      value={stateForm.condicionesCredito}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, condicionesCredito: target.value }))}
                      placeholder={t("createCustomerComp.customerCreditTermsPlaceHold")} />
                  </div>
                </div>
                {/* FILA 13 */}
                <div className="row-flex">
                  {/* OBSERVACIONES */}
                  <div className="col-5">
                    <strong>
                      {t("createCustomerComp.observations")}
                    </strong>
                  </div>
                  <div className="col-100">
                    <textarea
                      className="textarea-description"
                      value={stateForm.observaciones}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, observaciones: target.value }))}
                      placeholder={t("createCustomerComp.customerObserPlaceHold")} />
                  </div>
                </div>

                {/* FILA 14 */}
                <div className="row-flex">
                  {/* ACTIVO */}
                  <div className="col-5"><strong>Activo</strong></div>
                  <div className="col-4">
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="activo"
                      checked={stateForm.activo}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, activo: target.checked }))} />
                  </div>
                </div>
                <hr />
                {/* Botones para guardar y cancelar */}
                <div className="row-flex m-y-small j-content-space-around">
                  <button type={state.update ? "button" : "submit"} disabled={state.update} className="btn-primary">{t("createCustomerComp.btnSave")}</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                </div>
              </form>
            </div>

            <ContactsComp idCus={codCliente} lang={language} />

            {/* TABLA PARA MOSTRAR LOS EQUIPOS */}
            {/* <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Equipos</h3> */}

            <div className="row-flex m-y-small">
              <div className="col-9"><strong>Agregar equipo</strong></div>
              <div className="col-10">
                <button type="button" style={{ cursor: "pointer" }} onClick={() => navigate(`/equipments/create-equipment/${codCliente}`)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                    <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905" />
                  </svg>
                </button>
              </div>
            </div>

            <EquipmentsList idCus={codCliente} lang={language} token={getTheToken().token} action={"2"} />

            {/* MOSTRAR LA INFORMACION DE LOS EMPLEADOS QUE HAN INGRESADO Y MODIFICADO */}
            <div className="row-flex m-y-small">
              {/* INGRESADO POR */}
              <div className="col-5">
                <strong>Ingresado por</strong>
              </div>
              <div className="col-4">
                <span>{customer && customer[0]?.NomEmpIngreso}</span>
              </div>
              {/* MODIFICADO POR */}
              <div className="col-5">
                <strong>Modificado por</strong>
              </div>
              <div className="col-4">
                <span>{customer && customer[0]?.NomEmpModi}</span>
              </div>
            </div>

          </div>
        )
      }

      {/* Modal para agregar un nuevo contacto */}

    </>
  );
};