import React from "react";


export const TargetDataView = () => {

  return (
    <>
      <div className="container display-data">
        <h1>Target Data View</h1>
      </div>
    </>
  );
};