import React, { useEffect, useState } from "react";

// ----> Imports from react-router-dom
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// ----> Import for translation
import { useTranslation } from "react-i18next";

// ----> Components importation
import { ErrorNotification } from "../error-notification/ErrorNotification";
import { Error403View } from "../../views/error-403-view/Error403View";
import { ToastNotification } from "../toast-notification/ToastNotification";
import { UploadServReportComp } from "./UploadServReportComp";
import { GeneratePOD } from "./GeneratePOD";
import { SegPartsTable } from "./SegPartsTable";


// ----> URL IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// ----> GLOBAL FUNCTIONS
import { renderDate, getTheToken } from "../../global-functions/globalFunctions";

// IMPORT CSS STYLES
import "../../components/serviceComponents/ServiceCallsDetails.css";

// custom hooks import
import { useDownloadDocs } from "../customs/useDownloadDocs";
import { SegToolsTable } from "./SegToolsTable";




export const ServiceCallsDetails = ({ grupo }) => {

  // language definition
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // useLocation
  const location = useLocation();

  // STATE DEFINITION
  const [stateForm, setStateForm] = useState({
    responsibleEmployee: "", step: "", equipment: "", problem: "", idTipServicio: "", action: "", fecDesde: "", fecHasta: "", comment: "", fecVisit: "", priority: "", tecRespon: "", employee: "", employeeProgra: "", idServic: ""
  });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, success: false });
  const [state, setState] = useState({ dataVisible: false, unAuthorized: false, modalUploadCA: false, modalUploadServRep: false, modalSearchCall: false, codRepServ: 0, modalPOD: false });
  const [dataServer, setDataServer] = useState([]); // para traer la data del servidor
  const [stateServCall, setStateServCall] = useState([]); // estado para cargar la llamada
  const [controller, setController] = useState(null); // controlador para abortar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false);
  const [badRequestError, setBadRequestError] = useState({ show: false, msg: "", urlMsg: "", url: false, otherMsg: "" }); // este estado es para que se muestre cuando ocurra un bad request 400
  const [isLoadingData, setIsLoadingData] = useState(false); // para inabilitar el boton de guardar la llamada
  const [caFile, setCAFile] = useState(null); // estado para cargar el documento de la ca en el estado y luego enviar al servidor
  const [documentType, setDocumentType] = useState(""); // para setear el tipo de documento que se subira

  // const [loadDataServer, setLoadDataServer] = useState(false); // este estado es para disparar la funcion del useEffect para traer la nueva data del servidor

  // console.log(stateForm.fecDesde)

  // DESTRUCTURING DATA SERVER
  const {
    idCallDetail,
    segIdCall,
    allSteps,
    empPerm,
    serviceReports,
    cotPend,
    PoderCerrar,
    tienePOD,
    reporAplica,
    docuAplicaciones,
    empProgramacion,
    CADoc,
    PODER_PROGRAMAR,
    PENDIENTE_FACTURAR,
    SE_PUEDE_CERRAR,
    tienePacking,
    // tieneGarantia
  } = dataServer && dataServer;


  // custom hook to download docs
  const { downLoadFile, stateS } = useDownloadDocs();


  // DEFINICION DE CONSTANTE DE ETAPAS DE SERVICIO 
  const ASIG_TECNICO = 2; // Asignacion de tecnico
  const SOL_REPUESTO = 10; // Solicitud de repuesto
  const APRO_TECNICA = 11; // aprobacion tecnica
  const APRO_ADMIN = 12; // aprobacion administrativa
  const SOL_PRECIO = 13; // Solicitud de precio
  const COTIZACION = 14; // Cotizacion
  const APRO_SOPORTE = 18; // Aprobacion del soporte
  const ORDEN_COMPRA = 20; // Orden de compra
  const ESPERA_REPUESTO = 21; // Espera de repuesto
  const CERRAR_SERVICIO = 25; // Cerrar Servicio
  const PIEZA_PERDIDA = 28; // Pieza perdida
  const ACUSE_RECIBO = 30; // Acuse de recibo
  const GUIA_DESPA = 31; // Guia de despacho
  const RECIBO_ALMACEN = 32; // Recibo de almacen
  const FEC_DESPACHO = 36; // Fecha de despacho
  const EN_TRANSITO = 37; // En transito
  const NACIONALIZACION = 38; // Nacionalizacion
  const CONDUCE = 40; // Conduce
  const NOTA_RETIRO = 43; // Nota de retiro
  const SE_ENTREG_REP_CLIENTE = 46; // Se entregó repuesto al cliente
  const REC_POD = 78; // Recepcion del POD
  const EQUIP_BAJO_OBSER = 22; // Equipo bajo observacion
  const CULMINADO = 99; // Culminado
  const CERRAR_APLI = 61; // Cerrar aplicacion


  // etapas genericas
  const GENERIC_STEPS = [
    SOL_REPUESTO,
    APRO_TECNICA,
    APRO_ADMIN,
    SOL_PRECIO,
    ORDEN_COMPRA,
    COTIZACION,
    APRO_SOPORTE,
    ESPERA_REPUESTO,
    SE_ENTREG_REP_CLIENTE,
    ACUSE_RECIBO,
    GUIA_DESPA,
    RECIBO_ALMACEN,
    FEC_DESPACHO,
    EN_TRANSITO,
    NACIONALIZACION,
    PIEZA_PERDIDA,
    NOTA_RETIRO,
    CONDUCE
  ]; // en la renderizacion buscar por (ACCIONES PARA ETAPAS GENERICAS)


  // TIPOS DE SERVICIO
  const INSTALL = 3; // INSTALACION
  const APP = 5; // APLICACIONES
  const PRE_INSTALL = 10; // Pre instalacion
  const serviceTypes = [INSTALL, APP, PRE_INSTALL];

  // console.log(stateServCall)

  const { id } = useParams();

  // console.log(stateForm)

  // CLOSE MODAL BAD REQUEST
  const onCloseModalBadRequest = () => {
    setBadRequestError({ show: false, msg: "", urlMsg: "", url: false, otherMsg: "" });
  };


  // funcion para abrir el modal para cargar el reporte de servicio
  const onOpenModalServRepor = (codRepServ) => {
    setState((val) => ({ ...val, modalUploadServRep: true, codRepServ }));
  };


  // funcion para cerrar el modal de cargar los reportes de servicio desde el hijo
  const onCloseModal = (value) => {
    setState((val) => ({ ...val, modalUploadServRep: value }));
  };

  // funcion para solicitar nuevamente la data cuando se actualice en el componente hijo
  const onNotify = (msgS = "", error = false, great = false, success = false) => {
    setNotification((val) => ({ ...val, show: true, msg: msgS, errorNoti: error, great, success }));
  };


  // funcion para cerrar el modal de generar los pod
  const onHandleCloseGePOD = () => {
    setState((val) => ({ ...val, modalPOD: false }));
  };



  // funcion para manejar la carga del documento de la ca y demas documentos
  const handleFileChange = (event) => {

    const { target } = event;

    const file = target.files[0];
    setCAFile(file);
  };


  // Destructuring useTranslation() Hook
  const [t] = useTranslation("global");


  // ----> useNavigate
  const navigate = useNavigate();


  // funcion para generar la CA
  const handleCreateCADoc = (fileName = "") => {

    const END_POINT = `${urls.geneEquipDocs}/${stateServCall[0]?.CodEquipo}/search?language=${language}&doc=ca`;

    downLoadFile(END_POINT, fileName, "application/pdf");
  };

  // Funcion para agregar un empleado en la tabla SerEmpleado
  const onAddEmployee = async () => {
    if (stateForm.employeeProgra === "") {
      return setNotification({ show: true, msg: "Debe seleccionar el empleado", errorNoti: true, great: false });
    }

    // Verificar que al menos la fecha desde no esté vacia
    if (stateForm.fecDesde === "") {
      return setNotification({ show: true, msg: "Debe seleccionar al menos la fecha desde.", errorNoti: true, great: false });
    }

    try {
      const response = await fetch(`${urls.calls}/${id}/${stateForm.employeeProgra}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  // ON SUBMIT SERVICE CALL 
  const onSaveIDCall = async (event) => {
    event.preventDefault();

    if (!stateForm.action) {
      return setNotification((val) => ({
        ...val, show: true, msg: language === "en" ? "You must select the action you want to perform" : "Debe seleccionar la acción que desea realizar", errorNoti: true
      }));

    } else {

      if (Number(stateForm.action) === 3 && (stateServCall[0]?.IdEtapa === 3 || stateServCall[0]?.IdEtapa === 4)) { // se programo la visita la fecha no debe estar vacia
        if (!stateForm.fecVisit) {
          return setNotification((val) => ({ ...val, show: true, msg: language === "en" ? "You must select the visit date." : "Debe seleccionar la fecha de visita.", errorNoti: true }));
        }
      }

      if (stateServCall[0]?.IdEtapa === 5 && stateForm.action === "3" && !stateForm.fecVisit) {
        return setNotification((val) => ({ ...val, show: true, msg: t("newCallComp.select_fec_visi"), errorNoti: true }));
      }

      // si es un comentario que solamente se hará en la llamada
      // if (Number(stateForm.action) === 0 && stateForm.comment.trim() === "") {
      //   return setNotification({
      //     show: true, msg: language === "en" ? "If you are making a comment, you should write something descriptive." : "Si realizará un comentario, debe escribir algo descriptivo.", errorNoti: true, great: false
      //   });
      // }

      // Verificar si estan intentando cerrar el servicio manualmente si la llamada está en la etapa de Recepcion del POD, porque hasta que no se cargue el POD no se puede cerrar la llamada
      if ((stateServCall[0]?.IdEtapa === REC_POD && Number(stateForm.step) === CERRAR_SERVICIO && PoderCerrar && !tienePOD) || (stateServCall[0]?.IdEtapa === REC_POD && Number(stateForm.step) === CULMINADO && PoderCerrar && !tienePOD)) {
        return setNotification((val) => ({
          ...val, show: true, msg: language === "en" ? "Service cannot be closed, POD must be uploaded." : "No se puede cerrar el servicio, se debe cargar el POD.", errorNoti: true
        }));
      }

      // Verificar que exista al menos un reporte de aplicacion para poder cerrar la llamada
      if ((stateServCall[0]?.IdEtapa === CERRAR_APLI && Number(stateForm.step) === CERRAR_SERVICIO && !reporAplica) || (stateServCall[0]?.IdEtapa === CERRAR_APLI && Number(stateForm.step) === CULMINADO && !reporAplica)) {
        return setNotification((val) => ({
          ...val, show: true, msg: language === "en" ? "You must upload at least one application report in order to close the call." : "Debe cargar al menos un reporte de aplicación para poder cerrar la llamada.", errorNoti: true
        }));
      }

      // cotPend
      if ((stateServCall[0]?.IdEtapa === EQUIP_BAJO_OBSER && Number(stateForm.step) === CERRAR_SERVICIO && cotPend) || (stateServCall[0]?.IdEtapa === EQUIP_BAJO_OBSER && Number(stateForm.step) === CULMINADO && cotPend)) {
        return setNotification((val) => ({ ...val, show: true, msg: t("newCallComp.coti_pend_facturar"), errorNoti: true }));
      }

      // Verificar que si la llamada está en asignacion de tecnico, envien el tecnico encargado
      if (stateServCall[0]?.IdEtapa === ASIG_TECNICO && stateForm.tecRespon === "") {
        return setNotification((val) => ({ ...val, show: true, msg: t("newCallComp.seleccionar.tec_respon"), errorNoti: true }));
      }

      // si la etapa es Programacion de preventivo
      if (stateServCall[0]?.IdEtapa === 29 && stateForm.fecVisit === "" && stateForm.action === "45") {
        return setNotification((val) => ({ ...val, show: true, msg: t("newCallComp.select_fec_visi"), errorNoti: true }));
      }
    }


    // SI LLEGA HASTA ACA ES PORQUE SE PUEDE ACTUALIZAR LA DATA
    // setLoadDataServer(true);

    try {
      setIsLoadingData(true); // para inhabilitar el boton de guardar

      const response = await fetch(`${urls.calls}/${id}/search?language=${language}&grupoParams=${grupo}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      // console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 201) {

      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      } else {
        // ocurrió un error al actualizar los datos
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

      setIsLoadingData(false); // para volver al estado normal

    } catch (error) {
      setNotification((val) => ({ ...val, show: true, msg: language === "en" ? "An error ocurred while trying to communicate with the server. Try again and if the error persists contact the admin." : "Ocurrió un error al intentar comunicarse con el servidor, verifique si tiene conexión a internet. Intentelo nuevamente y si el error persiste contacte al administrador.", errorNoti: true }));
      // console.log(error);
    }

  };


  // Funcion que realiza el fetch al servidor (para traer la data del servidor)
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setIsLoading(true);
      const response = await fetch(`${urls.calls}/${id}/search?language=${language}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      // console.clear()

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) { // no autorizado a ingresar a esta ruta
        setState((val) => ({ ...val, dataVisible: false, unAuthorized: true }));
      } else if (response.status === 400) {
        setBadRequestError({ show: true, msg: t("newCallComp.estructura_url"), url: true, urlMsg: t("newCallComp.url_msg"), otherMsg: t("newCallComp.other_msg_url") });
      } else if (response.status === 500) {
        // ocurrió un error en el servidor
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }
      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para parecer como si se recargó la pagina
  useEffect(() => {
    if (notification.success) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [notification.success]);


  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServer();

    document.title = language === "en" ? "ID Detail - GIS APP" : "Detalle del ID - GIS APP";

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [id, language]);


  // useEffect para cambiar el estado 
  useEffect(() => {
    if (idCallDetail && idCallDetail.length > 0) {
      const etapaCerrada = idCallDetail[0]?.IdEtapa === 99 ? "" : idCallDetail[0]?.IdEtapa;
      setStateForm((val) => ({
        ...val, responsibleEmployee: idCallDetail[0]?.CodEmpResponsable, step: etapaCerrada, equipment: idCallDetail[0]?.CodEquipo,
        problem: idCallDetail[0]?.Problema, idTipServicio: idCallDetail[0]?.IdTipServicio, action: "", fecDesde: "", fecHasta: "", comment: "", fecVisit: "",
        priority: idCallDetail[0]?.Prioridad.toString(), tecRespon: idCallDetail[0]?.IdEtapa === ASIG_TECNICO ? idCallDetail[0]?.CodEmpResponsable : ""
      }));

      setStateServCall(idCallDetail);
    }
  }, [idCallDetail]);

  // LIMPIAR LAS NOTIFICACIONES
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, success: false });
    }, 5500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // usEffect para cuando la etapa sea 99 pues no muestre nada en el select
  useEffect(() => {
    if (Number(stateForm.step) === 99) {
      setStateForm((val) => ({ ...val, step: "" }));
    }
  }, [stateForm.step]);


  // funcion para buscar una nueva llamada por su id
  const onSearchCall = (event) => {
    event.preventDefault();

    navigate(`/service/service-call/${stateForm.idServic}`);

    setState((val) => ({ ...val, modalSearchCall: false }));

  };


  // FUNCIONES PARA SOLICITAR O REALIZAR ACCIONES EXTRAS
  const downloadSerDoc = (docRequest, fileName = "") => {

    const END_POINT = `${urls.calls}/docs/${stateServCall[0]?.CodEquipo}/search?docRequest=${docRequest}`;

    downLoadFile(END_POINT, fileName, "application/pdf");
  };


  // funcion para descargar el reporte de servicio
  const downloadServiceReportDoc = (codRepServ = 0, fileName = "") => {

    const END_POINT = `${urls.serviceReport}?codRepServ=${codRepServ}&language=${language}&action=1`;

    downLoadFile(END_POINT, fileName, "application/pdf");
  };


  // funcion para cargar el documento de la ca al servidor y los demas documentos, pasando el tipo de documento
  const onHandleSubmitUploadCA = async (event) => {
    event.preventDefault();


    if (caFile) {
      // hay archivo cargado

      if (documentType === "") {
        return setNotification((val) => ({ ...val, show: true, msg: t("serviceCard.selecc_tip_documen"), errorNoti: true }));
      }
      const formData = new FormData();

      formData.append("file", caFile);

      try {
        const response = await fetch(`${urls.equipments}/${stateForm.equipment}/search?language=${language}&document=${documentType}`, {
          method: "POST",
          headers: { "Authorization": getTheToken().token },
          body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
          setState((val) => ({ ...val, modalUploadCA: false }));
          setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
          setCAFile(null);
        } else if (response.status === 400) {
          setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
        } else if (response.status === 404) {
          setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
        } else if (response.status === 413) {
          // el archivo es muy pesado
          setNotification((val) => ({ ...val, show: true, msg: "El archivo es pesado", errorNoti: true }));
        }

      } catch (error) {
        console.log(error);
      }

    } else {
      // no se ha seleccionado ningun archivo
      setNotification((val) => ({ ...val, show: true, msg: "Debe cargar un archivo para subirlo al servidor.", errorNoti: true }));
    }

  };


  // funcion para eliminar el empleado de la lista de programacion
  const onDeleteEmployee = async (emp) => {

    try {
      const response = await fetch(`${urls.calls}/${emp}/${id}/search?language=${language}`, {
        method: "DELETE",
        headers: {
          "Authorization": getTheToken().token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {/* SE ACTUALIZO LA LLAMADA CORRECTAMENTE */}
      {
        notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)
      }
      {isLoading && (
        <div className="load-cont-transp">
          <div className="simple-spinner"><span></span></div>
          <p><strong>{language === "en" ? "Loading..." : "Cargando..."}</strong></p>
        </div>
      )}
      {/* ----> SI HAY UN ERROR <---- */}
      {
        badRequestError.show && (
          <div className="background-modal">
            <ErrorNotification
              errorDisplayMsg={badRequestError.msg}
              url={badRequestError.url}
              urlMsg={badRequestError.urlMsg}
              otherMsg={badRequestError.otherMsg}
              onClose={onCloseModalBadRequest} />
          </div>
        )
      }
      {state.unAuthorized && (<Error403View />)}

      {/* ----> VIEW FOR DESKTOP <---- */}
      {
        state.dataVisible && (
          <>
            <div className="display-data">
              <div className="container">
                <form onSubmit={onSaveIDCall}>
                  <div style={{
                    borderRadius: '0.3rem',
                    backgroundColor: 'rgba(0, 0, 0, 0.01)',
                    padding: '0.5rem 0.6rem 0.5rem 0.6rem',
                    boxShadow: 'var(--box-shadow-bottom)'
                  }}>



                    {/* ESTO ES UNA FILA PARA COLOCAR EL NOMBRE DEL CLIENTE, FECHA INGRESO DE LA LLAMADA Y LA ETAPA */}
                    <div className="row-flex m-y-small j-content-space-b">
                      {/* id de la llamada */}
                      <div className="row-flex col-5 align-c">
                        <h4>ID: {stateServCall[0]?.CodServicio}</h4>
                      </div>
                      {/* fecha que se ingresó */}
                      <div className="row-flex align-c col-4 col-70-sm">
                        <div className="col-4">
                          <strong>Date</strong>
                        </div>
                        <div className="flex-auto">
                          <span>{renderDate(stateServCall[0]?.FecIngreso, language)}</span>
                        </div>
                      </div>
                      {/* ----> ETAPAS <---- */}
                      <div className="row-flex col-6 flex-end">
                        <div className="col-4">
                          <strong>{t("serviceCard.step")}</strong>
                        </div>
                        <div className="col-6">
                          {
                            (stateServCall[0]?.IdEtapa !== 99 && ![1, 8, 10].includes(empPerm[0]?.CodPerfil)) && (
                              <select
                                name="step"
                                value={stateForm.step}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, step: target.value }))}>
                                <option value=""></option>
                                {
                                  allSteps && allSteps.map((step) => (
                                    <option key={step.IdEtapa} value={step.IdEtapa}>{language === "en" ? step.NombreEn : step.Nombre}</option>
                                  ))
                                }
                              </select>
                            )
                          }
                          {
                            (stateServCall[0]?.IdEtapa === 99 || [1, 8, 10].includes(empPerm[0]?.CodPerfil)) && (<span>{stateServCall[0]?.NomEtapa}</span>)
                          }
                        </div>
                      </div>
                    </div>

                    {/* ESTO ES UNA FILA PARA COLOCAR EL NOMBRE DEL CLIENTE, TEMPERATURA Y EL ESTATUS DE LA LLAMADA */}
                    <div className="row-flex">
                      {/* NOMBRE DEL CLIENTE */}
                      <div className="col-8 col-sm-8"><strong>Cliente</strong></div>
                      <div className="col-3 col-70-sm">
                        <Link
                          className="link-tag customer-name"
                          to={`/customers/customer-detail/${stateServCall[0]?.CodCliente}`}>
                          {stateServCall[0]?.Nombre}
                        </Link>
                      </div>
                      {/* TEMPERATURA DE LA LLAMADA */}
                      <div className="col-8 col-sm-8">
                        <strong>Temperatura</strong>
                      </div>
                      <div className="col-70-sm">
                        <div className="row-flex j-content-space-around box-temperature col-70-sm">
                          <div className="input-control bg-green circle-temp">
                            <input
                              type="radio"
                              value="0"
                              name="temperature"
                              checked={stateForm.priority === "0"}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, priority: target.value }))} />
                          </div>
                          <div className="input-control bg-yellow circle-temp">
                            <input
                              type="radio"
                              value="1"
                              name="temperature"
                              checked={stateForm.priority === "1"}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, priority: target.value }))} />
                          </div>
                          <div className="input-control bg-red circle-temp">
                            <input
                              type="radio"
                              value="4"
                              name="temperature"
                              checked={stateForm.priority === "4"}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, priority: target.value }))} />
                          </div>
                        </div>
                      </div>
                      {/* STATUS DE LA LLAMADA */}
                      <div className="col-8 status-label col-sm-8">
                        <strong style={{ marginRight: '0.3rem' }}>{t("serviceCard.status")}</strong>
                      </div>
                      <div className="col-10 col-70-sm">
                        <span style={{
                          backgroundColor: 'var(--primary-color)',
                          borderRadius: '0.2rem',
                          color: 'var(--white-color)',
                          padding: '0.2rem'
                        }}>{stateServCall[0]?.IdEstatus === 1 ? t("newCallComp.ejecutandose") : t("newCallComp.completado")}</span>
                      </div>
                    </div>

                    {/* FILA PARA LA COBRANZA */}
                    <div className="row-flex">
                      <div className="col-8 col-sm-8"><strong>Cobranza</strong></div>
                      <div className="col-3 col-70-sm">
                        <Link className="link-tag">Estado de cuenta</Link>
                      </div>
                    </div>

                    {/* ESTO ES UNA FILA PARA EL EQUIPO, Y SELECCIONAR EL INGENIERO */}
                    <div className="row-flex">
                      <div className="col-8 col-sm-8">
                        <strong>{t("serviceCard.equipment")}</strong>
                      </div>
                      <div className="col-3 col-70-sm">
                        <select
                          name="equipment"
                          value={stateForm.equipment}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, equipment: target.value }))}>
                          <option value=""></option>
                          {
                            stateServCall && stateServCall[0]?.equipments.map((equip) => (
                              <option key={equip.CodEquipo} value={equip.CodEquipo}>{equip.TipEquipo || ''} - {equip.Modelo || ''} - {equip.Serial || ''}</option>
                            ))
                          }
                        </select>
                        <div className="text-hidden-over">
                          <Link className="link-equipments" to={`/equipments/equipment-detail/${stateServCall[0]?.CodEquipo}`}>{stateServCall[0]?.nomEquipo}</Link>
                        </div>
                      </div>
                      <div className="col-8 col-sm-8">
                        <strong>{t("serviceCard.responsible")}</strong>
                      </div>
                      <div className="col-39-2 col-70-sm flex-grow">
                        <select
                          name="reponsablProgr"
                          value={stateForm.responsibleEmployee}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, responsibleEmployee: target.value }))}>
                          <option value=""></option>
                          {
                            stateServCall && stateServCall[0]?.employees.map((emplo) => (
                              <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>

                    <div className="row-flex">
                      <div className="col-8 col-sm-8">
                        <strong>{t("serviceCard.problem")}</strong>
                      </div>
                      <div className="col-45-83 col-70-sm">
                        <textarea
                          style={{ userSelect: 'none', textTransform: 'capitalize' }}
                          className="textarea-description"
                          placeholder={t("serviceCard.problemPlaceHolder")}
                          value={stateForm.problem}
                          readOnly />
                      </div>
                      <div className="col-39-2 col-sm-100-out flex-grow">
                        {/* aca va el location */}
                        <p style={{ padding: 0, margin: "0 0 4px 0" }} className="border-bottom">
                          <strong>{language === "en" ? "Location" : "Ubicación"}: </strong>
                          <span>{stateServCall[0]?.Ubicacion}</span>
                        </p>
                        {
                          // Nro. Contrato
                          stateServCall[0]?.NumContrato && (
                            <>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.nro_cont")}:</strong>
                                <span> {stateServCall[0]?.NroContrato} {stateServCall[0]?.NumContrato}</span>
                              </p>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.duracion")}: </strong>
                                <span>{renderDate(stateServCall[0]?.FecInicio, language)} - {renderDate(stateServCall[0]?.FecFin, language)}</span>
                              </p>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.frecuencia")}: </strong>
                                <span>{stateServCall[0]?.Frecuencia}</span>
                              </p>
                            </>
                          )
                        }
                        {
                          stateServCall[0]?.FecEOL && (
                            <h4 style={{ color: "#ff0000" }}>{t("serviceCard.equipo_EOGS")}</h4>
                          )
                        }
                      </div>
                    </div>


                    {/* DATOS DEL CLIENTE LABEL */}
                    <h4 style={{ color: 'var(--first-color)' }}>{t("serviceCallSteps.customerData")}</h4>
                    <hr />

                    {/* ----> MOSTRAR LOS DATOS DEL CLIENTE <---- */}
                    <div className="row-flex align-c"> {/* ROW */}
                      {/* TIPO DE SERVICIO */}
                      <div className="col-8 col-sm-8"><strong style={{ marginRight: '0.3rem' }}>{t("serviceCard.serviceTypeSelect")}</strong></div>
                      <div className="col-7 col-70-sm">
                        <select
                          name="tipServ"
                          value={stateForm.idTipServicio}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, idTipServicio: target.value }))}>
                          <option value="1">{t("serviceCallSteps.correctiveMaint")}</option>
                          <option value="2">{t("serviceCallSteps.preventMaint")}</option>
                          <option value="4">FMI</option>
                          <option value="9">{t("serviceCallSteps.project")}</option>
                          <option value="10">{t("serviceCallSteps.preInstall")}</option>
                          <option value="3">{t("serviceCallSteps.install")}</option>
                          <option value="5">{t("serviceCallSteps.applications")}</option>
                          <option value="6">{t("serviceCallSteps.workshop")}</option>
                          <option value="7">{t("serviceCallSteps.stockRepo")}</option>
                          <option value="8">{t("serviceCallSteps.internalSupport")}</option>
                        </select>
                      </div>

                      {/* CORREO, TELEFONO DEL CLIENTE */}
                      <div className="col-5 col-sm-100-out">
                        <strong>{t("newCallComp.name")}:</strong>
                        <span style={{ textTransform: 'capitalize', marginLeft: '0.3rem' }}>{stateServCall[0]?.Encargado}</span>
                      </div>
                      {/* SEGUNDA COLUMNA */}
                      <div className="flex-auto col-45-sm col-sm-100-out">
                        <strong>{t("newCallComp.email")}:</strong>
                        <span style={{ marginLeft: '0.3rem', textDecoration: 'underline', color: 'blue' }}>
                          {stateServCall[0]?.Correo}
                        </span> |
                        <strong style={{ marginLeft: '0.3rem' }}>{t("newCallComp.cell")}</strong>
                        <span style={{ marginLeft: '0.3rem' }}>{stateServCall[0]?.Telefono}</span>
                      </div>
                    </div>
                  </div> {/* ESTE ES EL CIERRE DEL DIV DE LA TARJETA */}

                  {/* SI EL TIPO DE SERVICIO ES DE APLICACIONES (5) */}
                  {
                    serviceTypes.includes(stateServCall[0]?.IdTipServicio) && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            <div className="col-8"><strong style={{ textDecoration: 'underline' }}>{t("newCallComp.docs")}</strong></div>
                          </div>
                          <div className="row-flex align-c">
                            <div className="col-9"><strong>{t("serviceCard.documentos_cargar")}</strong></div>
                            <div className="col-10">
                              <button type="button" className="btn-docs" onClick={() => setState((val) => ({ ...val, modalUploadCA: true }))}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                                  <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                                  <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                                </svg>
                              </button>
                            </div>
                            {/* documentos de CA */}
                            {
                              stateServCall[0]?.IdTipServicio === INSTALL && (
                                <>
                                  <div className="card-shadow row-flex align-c gap-small">
                                    <h4>CA</h4>
                                    {
                                      (CADoc && CADoc[0]?.Acta) && (
                                        <>
                                          <div className="">
                                            <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("caService", "ca.pdf")}>
                                              {
                                                stateS.isLoading ?
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                  </svg>
                                                  : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                    <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                                  </svg>
                                              }
                                            </button>
                                          </div>
                                          <div>
                                            <button type="button" className="btn-del">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                              </svg>
                                            </button>
                                          </div>
                                        </>
                                      )
                                    }
                                    {
                                      CADoc && !CADoc[0]?.Acta && (<strong>No se ha cargado</strong>)
                                    }
                                  </div>
                                  <div className="col-4 flex-end row-flex gap-small m-left-auto-comp-serv">
                                    <div><strong>{t("newCallComp.uploadComp")}</strong></div>
                                    <div>
                                      <Link className="link-tag" to={`/service/service-equipment-components/${stateForm.equipment}`}>{t("newCallComp.comp")}</Link>
                                    </div>
                                    <div>
                                      <button type="button" disabled={stateS.isLoading} style={{ cursor: "pointer" }} title="CA" onClick={() => handleCreateCADoc("ca.pdf")}>
                                        {
                                          stateS.isLoading ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                              <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                            </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--first-color)" className="bi bi-journal-bookmark-fill" viewBox="0 0 16 16">
                                              <path fillRule="evenodd" d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z" />
                                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                                            </svg>
                                        }
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                            {
                              // si es pre instalacion mostrar el packing list y el pod para generar y descargar
                              stateServCall[0]?.IdTipServicio === PRE_INSTALL && (
                                <>
                                  <div className="row-flex col-80 j-content-space-b">
                                    <div className="col-4 row-flex">
                                      <div className="col-6"><strong>Packing List</strong></div>
                                      <div className="col-6">
                                        {
                                          tienePacking && (
                                            <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("packingList", "packing list.pdf")}>
                                              {
                                                stateS.isLoading ?
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                  </svg> :
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                    <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                                  </svg>
                                              }
                                            </button>
                                          )
                                        }
                                      </div>
                                    </div>
                                    {/* POD */}
                                    <div className="row-flex col-3 align-c">
                                      <div className="col-3">
                                        <button type="button" className="btn-success" style={{ display: "flex", gap: "0.3rem", alignItems: "center" }} onClick={() => setState((val) => ({ ...val, modalPOD: true }))}>
                                          POD
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-bookmark-fill" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z" />
                                            <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                                            <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                                          </svg>
                                        </button>
                                      </div>
                                      {
                                        tienePOD && (
                                          <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("pod", "pod.pdf")}>
                                            {
                                              stateS.isLoading ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                  <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                </svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                  <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                  <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                                </svg>
                                            }

                                          </button>
                                        )
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            }

                          </div>
                          {/* Esto se renderiza unicamente si el tipo de llamada es instalacion */}

                          {/* Esto se renderiza unicamente si el tipo de llamada es aplicacion */}
                          {
                            stateServCall[0]?.IdTipServicio === APP && (
                              <div className="flex-auto row-flex">
                                {/* APLICACION 1 */}
                                <div className="col-5 col-sm-80 row-flex align-c gap-small">
                                  <div>
                                    <strong className="p-small">Aplicación Fase 1</strong>
                                  </div>

                                  {
                                    docuAplicaciones && docuAplicaciones[0]?.Aplicacion1 && (
                                      <div>
                                        <button type="button" className="btn-pdf" onClick={() => downloadSerDoc("Aplicacion1", "Aplication 1.pdf")}>
                                          {
                                            stateS.isLoading ?
                                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                              </svg> :
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                              </svg>
                                          }

                                        </button>
                                      </div>
                                    )
                                  }
                                </div>

                                {/* APLICACION 2 */}
                                <div className="col-5 col-sm-80 row-flex align-c gap-small">
                                  <div>
                                    <strong className="p-small">Aplicación Fase 2</strong>
                                  </div>

                                  {
                                    docuAplicaciones && docuAplicaciones[0]?.Aplicacion2 && (
                                      <div>
                                        <button type="button" className="btn-pdf" onClick={() => downloadSerDoc("Aplicacion2", "Aplication 2.pdf")}>
                                          {
                                            stateS.isLoading ?
                                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                              </svg> :
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                              </svg>
                                          }
                                        </button>
                                      </div>
                                    )
                                  }
                                </div>

                                {/* APLICACION 3 */}
                                <div className="col-5 col-sm-80 row-flex align-c gap-small">
                                  <div>
                                    <strong className="p-small">Aplicación Fase 3</strong>
                                  </div>

                                  {
                                    docuAplicaciones && docuAplicaciones[0]?.Aplicacion3 && (
                                      <div>
                                        <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("Aplicacion3", "Aplication 3.pdf")}>
                                          {
                                            stateS.isLoading ?
                                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                              </svg> :
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                              </svg>
                                          }
                                        </button>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </>
                    )
                  }

                  {/* TARJETA QUE SIEMPRE APARECE PARA AGREGAR INGENIEROS */}
                  <div style={{ margin: '0.8rem 0', padding: '0.8rem 0.5rem 0.8rem 0.5rem', border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: '0.3rem', boxShadow: 'var(--box-shadow-bottom)' }}>
                    <div style={{ marginBottom: '0.5rem' }} className="row-flex d-column">
                      <div style={{ width: '8rem' }}>
                        <strong style={{ textDecoration: 'underline' }}>{t("newCallComp.scheduling")}</strong>
                      </div>
                    </div>
                    {/* ACA SE SELECCIONA EL INGENIER */}
                    <div className="row-flex">
                      <div className="col-8 col-sm-8">
                        <strong>{t("newCallComp.ingeniero")}</strong>
                      </div>
                      <div className="row-flex col-4 col-70-sm gap-small">
                        <select
                          className="select-tec-serv"
                          name="selectTecnico"
                          value={stateForm.employeeProgra}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, employeeProgra: target.value }))}>
                          <option value=""></option>
                          {
                            stateServCall[0]?.employees.map((emp) => (
                              <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                            ))
                          }
                        </select>
                        <button type="button" className="btn-primary" onClick={onAddEmployee}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {/* Segunda columna */}
                    <div className="row-flex">
                      <div className="col-8 col-sm-8">
                        {/* Esto es para seleccionar la fecha desde */}
                        <strong>{t("newCallComp.visitDate")}</strong>
                      </div>
                      <div className="col-4 col-70-sm">
                        <input
                          className="input-date-styles"
                          type="datetime-local"
                          name="fecDesde"
                          value={stateForm.fecDesde}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, fecDesde: target.value }))} />
                      </div>
                      <div className="col-8 col-sm-8">
                        <strong>{t("newCallComp.visitDateEnd")}</strong>
                      </div>
                      <div className="col-4 col-70-sm">
                        <input
                          className="input-date-styles"
                          type="datetime-local"
                          name="fecHasta"
                          value={stateForm.fecHasta}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, fecHasta: target.value }))} />
                      </div>
                    </div>
                    {/* FILA */}
                    {
                      empProgramacion && empProgramacion.length > 0 && (
                        <div className="row-flex d-column">
                          {
                            empProgramacion && empProgramacion.map((emp) => (
                              <div className="col-6 col-100-sm row-flex gap-small card-shadow" key={emp.CodEmpleado} style={{ margin: '0.2rem 0', border: "1px solid gray", borderRadius: "10px", padding: "0.5rem 0.3rem" }}>
                                <div className="col-6 text-hidden-over"><span>{emp.nomEmpleado}</span></div>
                                <div className="col-3">
                                  <span>{renderDate(emp.FecProgramacion, language)}</span>
                                  {
                                    emp.FecProHasta && (<span> - {renderDate(emp.FecProHasta, language)}</span>)
                                  }
                                </div>
                                <div className="col-10">
                                  <button type="button" className="btn-del" onClick={() => onDeleteEmployee(emp.CodEmpleado)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>

                  {/* SI LA ETAPA ES RECEPCION DEL POD (78) */}
                  {
                    stateServCall[0]?.IdEtapa === 78 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR ACCION */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                {
                                  tienePOD && (<option value="2">{t("AsignacionTecnicoComp.closeService")}</option>)
                                }
                                <option value="0">{t("newCallComp.comment")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </select>
                            </div>
                            {
                              !tienePOD && (
                                <div className="col-46-7"><span className="required">{t("newCallComp.no_pod")}</span></div>
                              )
                            }
                          </div>
                        </div>
                      </>
                    )
                  }

                  {/* SI LA ETAPA ES CULMINADO (99) */}
                  {
                    stateServCall[0]?.IdEtapa === 99 && (
                      <>
                        <div className="row-flex m-y-small">
                          <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                          <div className="col-4 col-sm-70-mod">
                            <select
                              name="actionCall"
                              value={stateForm.action}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                              <option value=""></option>
                              <option value="0">{t("newCallComp.comment")}</option>
                            </select>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // visita de aplicacion
                    stateServCall[0]?.IdEtapa === 60 && (
                      <div className="row-flex m-y-small">
                        <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                        <div className="col-4 col-sm-70-mod">
                          <select
                            name="actionCall"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            <option value="2">Aplicación terminada</option>
                            <option value="3">Programar aplicación</option>
                            <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            <option value="0">{t("newCallComp.comment")}</option>
                          </select>
                        </div>
                      </div>
                    )
                  }

                  {
                    // si la etapa es recepcion del acta de instalacion
                    stateServCall[0]?.IdEtapa === 62 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR LA ACCION */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                {
                                  CADoc && CADoc[0]?.Acta && (
                                    <>
                                      <option value="2">Programar aplicaciones</option>
                                      <option value="3">Generar cronograma</option>
                                    </>
                                  )
                                }
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                              </select>
                            </div>

                            {
                              CADoc && !CADoc[0]?.Acta && (
                                <div className="col-42-29"><strong className="required">{t("newCallComp.no_ca")}</strong></div>
                              )
                            }
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    stateServCall[0]?.IdEtapa === 83 && (
                      <div className="row-flex m-y-small">
                        <div className="col-8 col-sm-30">
                          <strong>{t("newCallComp.selectAction")}</strong>
                        </div>
                        <div className="col-4 col-sm-70-mod">
                          <select
                            name="actionCall"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            {
                              empPerm && ([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 10 && [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio)) || (empPerm[0]?.Coordinador && empPerm[0]?.CodPerfil === 1) || (empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                <>
                                  <option value="2">{t("newCallComp.tec_asistio")}</option>
                                  <option value="3">{t("newCallComp.tec_no_asistio")}</option>
                                </>
                              )
                            }
                            <option value="0">{t("newCallComp.comment")}</option>
                          </select>
                        </div>
                      </div>
                    )
                  }

                  {
                    // si la etapa es cerrar aplicaciones
                    stateServCall[0]?.IdEtapa === 61 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR ACCION */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                {reporAplica && (<option value="2">Se confirmó aplicación</option>)}
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </select>
                            </div>
                            {/* SINO SE HA CARGADO REPORTE DE APLICACIONES */}
                            {
                              !reporAplica && (
                                <div className="col-46-7"><span className="required">{t("newCallComp.no_report_apli")}</span></div>
                              )
                            }
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // acciones para etapas genericas
                    GENERIC_STEPS.includes(stateServCall[0]?.IdEtapa) && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR ACCION */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es Asignar especialista tecnico pre instalacion
                    stateServCall[0]?.IdEtapa === 71 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA */}
                          <div className="row-flex">
                            {/* Seleccionar accion */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                <option value="2">{t("AsignacionTecnicoComp.technicianContacted")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                              </select>
                            </div>
                            {/* Seleccionar empleado */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.techRespon")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="employee"
                                value={stateForm.tecRespon}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, tecRespon: target.value }))}>
                                <option value=""></option>
                                {
                                  stateServCall[0]?.employees.map((emp) => (
                                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es confirmar visita de aplicacion || espera dia de la aplicacion
                    (stateServCall[0]?.IdEtapa === 66 || stateServCall[0]?.IdEtapa === 59) && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR LA ACCION */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                <option value="5">{t("newCallComp.tec_asistio")}</option>
                                <option value="6">{t("newCallComp.tec_no_asistio")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es espera dia de pre instalacion || confirmar visita de la pre instalacion
                    (stateServCall[0]?.IdEtapa === 73 || stateServCall[0]?.IdEtapa === 74) && (
                      <div className="row-flex m-y-small">
                        <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                        <div className="col-4 col-sm-70-mod">
                          <select
                            name="actionCall"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            <option value="5">{t("newCallComp.tec_asistio")}</option>
                            <option value="6">{t("newCallComp.tec_no_asistio")}</option>
                            <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            <option value="0">{t("newCallComp.comment")}</option>
                            <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                          </select>
                        </div>
                      </div>
                    )
                  }

                  {
                    // Si la etapa es Programacion de aplicaciones
                    stateServCall[0]?.IdEtapa === 58 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR ACCION */}
                            <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                <option value="2">{t("newCallComp.program_aplicacion")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </select>
                            </div>
                            {/* FECHA DE APLICACION */}
                            <div className="col-8"><strong>{t("newCallComp.fec_aplicacion")}</strong></div>
                            <div className="col-4">
                              <input
                                className="input-date-styles"
                                type="datetime-local"
                                name="fecVisit"
                                value={stateForm.fecVisit}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, fecVisit: target.value }))} />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es visita pre instalacion
                    stateServCall[0]?.IdEtapa === 75 && (
                      <div className="row-flex m-y-small">
                        {/* SELECCIONAR ACCION */}
                        <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                        <div className="col-4 col-sm-70-mod">
                          <select
                            name="actionCall"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            <option value="5">{t("newCallComp.ambien_listo")}</option>
                            <option value="6">{t("newCallComp.progr_revis_ambi")}</option>
                            <option value="0">{t("newCallComp.comment")}</option>
                            <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                          </select>
                        </div>
                      </div>
                    )
                  }

                  {
                    // si la etapa es visita de instalacion
                    stateServCall[0]?.IdEtapa === 56 && (
                      <div className="row-flex">
                        <div className="col-8 col-sm-30">
                          <strong>{t("newCallComp.selectAction")}</strong>
                        </div>
                        <div className="col-4 col-sm-70-mod">
                          <select
                            name="actionCall"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            <option value="0">{t("serviceCard.comment")}</option>
                            <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                            {
                              empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil)) || (empPerm[0]?.CodPerfil === 10 && [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio)) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || (empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                <>
                                  <option value="2">{t("newCallComp.instalacion_term")}</option>
                                  <option value="3">{t("newCallComp.scheduleVisit")}</option>
                                </>
                              )
                            }
                          </select>
                        </div>
                      </div>
                    )
                  }

                  {
                    // si la etapa es espera por dia de visita || confirmar visita
                    (stateServCall[0]?.IdEtapa === 6 || stateServCall[0]?.IdEtapa === 7) && (
                      <>
                        {/* FILA 1 */}
                        <div className="row-flex m-y-small">
                          <div className="col-8 col-sm-30"><strong>{t("newCallComp.selectAction")}</strong></div>
                          <div className="col-4 col-sm-70-mod">
                            <select
                              name="actionCall"
                              value={stateForm.action}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                              <option value=""></option>
                              {
                                empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador))) && (
                                  <>
                                    <option value="8">{t("newCallComp.tec_sitio")}</option>
                                    <option value="18">{t("newCallComp.tec_no_asistio")}</option>
                                    <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                    <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                  </>
                                )
                              }
                              <option value="0">{t("newCallComp.comment")}</option>
                            </select>
                          </div>
                        </div>
                      </>
                    )
                  }


                  {
                    // Si la etapa es la 3 Programacion de Visita || Espera por cliente
                    (stateServCall[0]?.IdEtapa === 3 || stateServCall[0]?.IdEtapa === 4) && (
                      <div style={{ padding: '0.8rem 0.5rem' }} className="m-y-small">
                        {/* SEGUNDA FILA */}
                        <div className="row-flex">
                          <div style={{ alignItems: 'center' }} className="col-8 col-sm-30">
                            <strong style={{ textDecoration: 'underline' }}>{t("newCallComp.selectAction")}</strong>
                          </div>
                          <div className="col-4 col-sm-70-mod">
                            <select
                              name="actionCall"
                              value={stateForm.action}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                              <option></option>
                              {
                                empPerm &&
                                (
                                  (
                                    [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio) &&
                                    (
                                      [3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) ||
                                      (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                    )
                                  ) ||
                                  (
                                    [1, 2].includes(stateServCall[0]?.IdTipServicio) &&
                                    PODER_PROGRAMAR &&
                                    (
                                      [3, 6, 12].includes(empPerm[0]?.CodPerfil) ||
                                      (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                    )
                                  )
                                ) && (
                                  <>
                                    <option value="3">{t("ProgramacionVisita.visitScheduled")}</option>
                                    <option value="2">{t("ProgramacionVisita.problemSolved")}</option>
                                  </>
                                )
                              }
                              <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              <option value="0">{t("newCallComp.comment")}</option>
                            </select>
                          </div>
                          {
                            ([1, 2].includes(stateServCall[0]?.IdTipServicio) && !PODER_PROGRAMAR) && (
                              <div className="col-3">
                                <strong className="required">{t("newCallComp.no_se_puede_programar")}</strong>
                              </div>
                            )
                          }
                          {
                            empPerm && (
                              (
                                [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio) &&
                                (
                                  [3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) ||
                                  (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                )
                              ) ||
                              (
                                [1, 2].includes(stateServCall[0]?.IdTipServicio) &&
                                PODER_PROGRAMAR &&
                                (
                                  [3, 6, 12].includes(empPerm[0]?.CodPerfil) ||
                                  (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                )
                              )
                            ) && (
                              <>
                                {/* INPUT PARA PROGRAMAR LA VISITA */}
                                <div className="col-8 col-sm-30">
                                  <strong>{t("newCallComp.fec_visita")}</strong>
                                </div>
                                {/* INPUT PARA COLOCAR LA FECHA Y HORA */}
                                <div className="col-4 col-sm-70-mod">
                                  <input
                                    className="input-date-styles"
                                    type="datetime-local"
                                    name="fecVisit"
                                    value={stateForm.fecVisit}
                                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecVisit: target.value }))} />
                                </div>
                              </>
                            )
                          }
                        </div>
                      </div>
                    )
                  }

                  {
                    // Si la etapa es visita tecnica o diagnostico con escalacion
                    [8, 9].includes(stateServCall[0]?.IdEtapa) && (
                      <>
                        <div style={{ padding: '0.8rem 0.5rem' }} className="m-y-small">
                          <div className="row-flex">
                            <div style={{ alignItems: 'center' }} className="col-8 col-sm-30">
                              <strong style={{ textDecoration: 'underline' }}>{t("newCallComp.selectAction")}</strong>
                            </div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option></option>
                                {
                                  empPerm && ([3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                    <>
                                      <option value="2">{t("newCallComp.scheduleVisit")}</option>
                                      <option value="9">{t("newCallComp.diagnostScalation")}</option>
                                      <option value="23">{t("newCallComp.serviceCompleted")}</option>
                                    </>
                                  )
                                }
                                {
                                  stateServCall[0]?.CodGrupo === 3 && (
                                    <option value="4">{t("newCallComp.esp_clie")}</option>
                                  )
                                }
                                <option value="0">{t("newCallComp.comment")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // si la etapa es Recepcion de reporte || Cerrar servicio || devolucion de parte
                    [23, 25, 42].includes(stateServCall[0]?.IdEtapa) && (
                      <div className="row-flex m-y-small">
                        <div className="col-8 col-sm-30"><strong style={{ textDecoration: "underline" }}>{t("newCallComp.selectAction")}</strong></div>
                        <div className="col-4 col-sm-70-mod">
                          <select
                            name="actionCall"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            {
                              SE_PUEDE_CERRAR && ([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 10 && [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio))) && (
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              )
                            }
                            <option value="24">{t("newCallComp.pendToBeInv")}</option>
                            <option value="2">{t("newCallComp.scheduleVisit")}</option>
                            <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            <option value="0">{t("newCallComp.comment")}</option>
                          </select>
                        </div>
                        {
                          !SE_PUEDE_CERRAR && (
                            <div className="col-3">
                              <strong className="required">{t("newCallComp.cargar_report")}</strong>
                            </div>
                          )
                        }
                      </div>
                    )
                  }

                  {
                    // Si la etapa es ambiente listo
                    stateServCall[0]?.IdEtapa === 77 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR LA ACCION */}
                            <div className="col-8 col-sm-30">
                              <strong>{t("newCallComp.selectAction")}</strong>
                            </div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                <option value="30">{t("newCallComp.sol_inst")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // si la etapa es la 5 (Confirmar preventivo)
                    stateServCall[0]?.IdEtapa === 5 && (
                      <div className="m-y-small">
                        <div className="row-flex">
                          {/* seleccionar la accion */}
                          <div className="col-8 col-sm-30">
                            <strong>{t("newCallComp.selectAction")}</strong>
                          </div>
                          <div className="col-4 col-sm-70-mod">
                            <select
                              name="actionCall"
                              value={stateForm.action}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                              <option value=""></option>
                              {
                                empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador))) && (
                                  <>
                                    <option value="3">{t("newCallComp.confirmo_visita")}</option>
                                    <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                    <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                  </>
                                )
                              }
                              <option value="0">{t("newCallComp.comment")}</option>
                            </select>
                          </div>
                          <div className="col-8 col-sm-30">
                            <strong>{t("newCallComp.fec_visita")}</strong>
                          </div>
                          {
                            empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador))) && (
                              <div className="col-4 col-sm-70-mod">
                                <input
                                  className="input-date-styles"
                                  type="datetime-local"
                                  name="fecVisiPreven"
                                  value={stateForm.fecVisit}
                                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecVisit: target.value }))} />
                              </div>
                            )
                          }
                        </div>
                      </div>
                    )
                  }

                  {
                    // Si la etapa es programacion pre instalacion
                    stateServCall[0]?.IdEtapa === 72 && (
                      <>
                        <div className="m-y-small">
                          <div className="row-flex">
                            {/* SELECCIONAR ACCION */}
                            <div className="col-8 col-sm-30">
                              <strong>{t("newCallComp.selectAction")}</strong>
                            </div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                <option value="73">{t("newCallComp.progra_revi_ambient")}</option>
                                <option value="77">{t("newCallComp.ambien_culmin")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                              </select>
                            </div>
                            {/* FECHA REVISION AMBIENTE */}
                            <div className="col-8">
                              <strong>{t("newCallComp.fec_rebi")}</strong>
                            </div>
                            <div className="col-4">
                              <input
                                className="input-date-styles"
                                type="datetime-local"
                                name="fecAmbiente"
                                value={stateForm.fecVisit}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, fecVisit: target.value }))} />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es pendiente por facturar
                    stateServCall[0]?.IdEtapa === 24 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            <div className="col-8 col-sm-30">
                              <strong>{t("newCallComp.selectAction")}</strong>
                            </div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                {
                                  PENDIENTE_FACTURAR ? (<option value="25">{t("newCallComp.fact_realizada")}</option>) : null
                                }
                                <option value="23">{t("newCallComp.recepcion_rep_serv")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                              </select>
                            </div>
                            {/* MOSTRAR MENSAJE DE QUE SE DEBE FACTURAR ANTES DE CERRAR */}
                            {
                              !PENDIENTE_FACTURAR ? <div className="flex-auto"><span className="required">{t("newCallComp.coti_pend_facturar")}</span></div> : null
                            }
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es Programar la instalacion
                    stateServCall[0]?.IdEtapa === 54 && (
                      <div className="m-y-small">
                        {/* FILA */}
                        <div className="row-flex">
                          {/* Seleccionar acción */}
                          <div className="col-8 col-sm-30">
                            <strong>{t("newCallComp.selectAction")}</strong>
                          </div>
                          <div className="col-4 col-sm-70-mod">
                            <select
                              name="actionCall"
                              value={stateForm.action}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                              <option value=""></option>
                              <option value="2">{t("newCallComp.instalacion_prog")}</option>
                              <option value="3">{t("newCallComp.confir_instalacion")}</option>
                              <option value="0">{t("newCallComp.comment")}</option>
                              <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            </select>
                          </div>
                          {/* Seleccionar la fecha */}
                          <div className="col-8">
                            <strong>{t("newCallComp.fec_instalacion")}</strong>
                          </div>
                          <div className="col-4">
                            <input
                              className="input-date-styles"
                              type="datetime-local"
                              name="fecInstall"
                              value={stateForm.fecVisit}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, fecVisit: target.value }))} />
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    // si la etapa es 55 o 67 (Espera dia de la instalacion) || (Confirmar visita de la instalacion)
                    (stateServCall[0]?.IdEtapa === 55 || stateServCall[0]?.IdEtapa === 67) && (
                      <div className="row-flex m-y-small">
                        <div className="col-8 col-sm-30">
                          <strong style={{ textDecoration: "underline" }}>{t("newCallComp.selectAction")}</strong>
                        </div>
                        <div className="col-4 col-sm-70-mod">
                          <select
                            name="actionCall"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            {
                              empPerm && (([3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                <>
                                  <option value="4">{t("newCallComp.tec_asistio")}</option>
                                  <option value="2">{t("newCallComp.tec_no_asistio")}</option>
                                </>
                              )
                            }
                            <option value="3">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                            <option value="0">{t("newCallComp.comment")}</option>
                          </select>
                        </div>
                      </div>
                    )
                  }

                  {
                    // Si la etapa es la 22 equipo bajo observacion
                    stateServCall[0]?.IdEtapa === 22 && (
                      <>
                        {/* FILA */}
                        <div className="row-flex m-y-small">
                          <div className="col-8 col-sm-30">
                            <strong style={{ textDecoration: "underline" }}>{t("newCallComp.selectAction")}</strong>
                          </div>
                          <div className="col-4 col-sm-70-mod">
                            <select
                              name="actionCall"
                              value={stateForm.action}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                              <option value=""></option>
                              <option value="1">{t("newCallComp.serviceCompleted")}</option>
                              <option value="2">{t("newCallComp.diagnostScalation")}</option>
                              <option value="0">{t("newCallComp.comment")}</option>
                              <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            </select>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es asignacion de tecnico
                    stateServCall[0]?.IdEtapa === 2 && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            {/* SELECCIONAR ACCION */}
                            <div className="col-8 col-sm-30">
                              <strong style={{ textDecoration: 'underline' }}>{t("newCallComp.selectAction")}</strong>
                            </div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="actionCall"
                                value={stateForm.action}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                                <option value=""></option>
                                <option value="2">{t("AsignacionTecnicoComp.technicianContacted")}</option>
                                <option value="0">{t("newCallComp.comment")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </select>
                            </div>
                            {/* TECNICO RESPONSABLE */}
                            <div className="col-8">
                              <strong>{t("newCallComp.techRespon")}</strong>
                            </div>
                            <div className="col-4">
                              <select
                                name="employeeRes"
                                value={stateForm.tecRespon}
                                onChange={({ target }) => setStateForm((val) => ({ ...val, tecRespon: target.value }))}>
                                <option value=""></option>
                                {
                                  stateServCall[0]?.employees.map((emp) => (
                                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    // Si la etapa es asignar especialista tecnico para la instalacion
                    stateServCall[0]?.IdEtapa === 53 && (
                      <div className="m-y-small">
                        <div className="row-flex">
                          {/* seleccionar accion */}
                          <div className="col-8">
                            <strong>{t("newCallComp.selectAction")}</strong>
                          </div>
                          <div className="col-4">
                            <select
                              name="action"
                              value={stateForm.action}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                              <option value=""></option>
                              <option value="3">{t("AsignacionTecnicoComp.technicianContacted")}</option>
                              <option value="0">{t("newCallComp.comment")}</option>
                              <option value="2">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                            </select>
                          </div>
                          {/* seleccionar el ingeniero involucrado */}
                          <div className="col-8">
                            <strong>{t("newCallComp.ing_respon")}</strong>
                          </div>
                          <div className="col-4">
                            <select
                              name="responInstall"
                              value={stateForm.tecRespon}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, tecRespon: target.value }))}>
                              <option value=""></option>
                              {
                                stateServCall[0]?.employees.map((emp) => (
                                  <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    // Si la etapa es programacion de preventivos
                    stateServCall[0]?.IdEtapa === 29 && (
                      <div className="row-flex m-y-small">
                        <div className="col-8">
                          <strong>{t("newCallComp.selectAction")}</strong>
                        </div>
                        <div className="col-4">
                          <select name="action"
                            value={stateForm.action}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                            <option value=""></option>
                            {
                              empPerm && ((empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || [3, 6, 12].includes(empPerm[0]?.CodPerfil)) && (
                                <>
                                  <option value="45">{t("ProgramacionVisita.visitScheduled")}</option>
                                  <option value="46">{t("ProgramacionVisita.problemSolved")}</option>
                                </>
                              )
                            }
                            <option value="0">{t("RequestPartsView.comentario")}</option>
                            <option value="5">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                          </select>
                        </div>
                        {
                          empPerm && ((empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || [3, 6, 12].includes(empPerm[0]?.CodPerfil)) && (
                            <>
                              <div className="col-8">
                                <strong>{t("newCallComp.fec_visita")}</strong>
                              </div>
                              <div className="col-4">
                                <input
                                  className="input-date-styles"
                                  type="datetime-local"
                                  name="fecVisita"
                                  value={stateForm.fecVisit}
                                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecVisit: target.value }))} />
                              </div>
                            </>
                          )
                        }
                      </div>
                    )
                  }

                  {
                    // Fila (Reportes y demás)
                    stateServCall[0]?.IdEtapa !== 99 && (
                      <div className="row-flex gap-small">
                        <div style={{ alignItems: 'center' }} className="row-flex j-content-space-b col-100-sm">
                          <div className="col-70-sm request-links">
                            <strong>{t("newCallComp.requestPartsAndTools")}</strong>
                          </div>
                          <button type="button" className="btn-call-details-steps" onClick={() => navigate(`/service/request-part/${stateServCall[0]?.CodServicio}`)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tools" viewBox="0 0 16 16">
                              <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                            </svg>
                          </button>
                        </div>
                        <div style={{ alignItems: 'center' }} className="row-flex j-content-space-b col-100-sm">
                          <div className="col-70-sm request-links">
                            <strong>{t("newCallComp.assetsRequest")}</strong>
                          </div>
                          <button type="button" className="btn-call-details-steps" onClick={() => navigate(`/service/asset-request/${stateServCall[0]?.CodServicio}`)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tools" viewBox="0 0 16 16">
                              <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                            </svg>
                          </button>
                        </div>
                        <div style={{ alignItems: 'center' }} className="row-flex j-content-space-b col-100-sm">
                          <div className="request-links">
                            <strong>{t("newCallComp.serviceReport")}</strong>
                          </div>
                          <button type="button" className="btn-call-details-steps" onClick={() => navigate(grupo === 1 ? `/service/service-report/${stateServCall[0]?.CodServicio}` : `/commercial/service/service-report/${stateServCall[0]?.CodServicio}`)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-text" viewBox="0 0 16 16">
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )
                  }


                  {/* PARA COMENTAR */}
                  <div className="row-flex d-f-column">
                    <div>
                      <strong>{t("newCallComp.comment")}</strong>
                    </div>
                    <div className="parent">
                      <textarea
                        className="textarea-description"
                        name="comentario"
                        value={stateForm.comment}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                        placeholder={language === "en" ? "Comments..." : "Comentarios..."} />
                    </div>
                  </div>

                  {/* AGREGAR COMENTARIO A LA LLAMADA - BOTONES DE ACCION */}
                  <div style={{ margin: '0 1rem 1.5rem 1rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '0.5rem', margin: '0.8rem 0' }}>
                      <div className={isLoadingData ? "wrapper" : ""}>
                        <button type={isLoadingData ? "button" : "submit"} className={`${isLoadingData ? "disabled" : ""} btn-primary`}>{t("newCallComp.save")}</button>
                      </div>
                      <div>
                        <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                      </div>
                    </div>
                  </div>
                  {/* {
                  window.screen.width >= 1023 && ( */}
                  <>
                    <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>{t("serviceCard.follow")}</h4>

                    <div className="table-container-mobile fade-animation seg-service-table" style={{ margin: '1rem 0' }}>
                      <table className="table">
                        <thead className="table-head">
                          <tr>
                            <th style={{ borderRadius: '5px 0 0 0' }}>{t("newCallComp.date")}</th>
                            <th>{t("newCallComp.currentStep")}</th>
                            <th>{t("newCallComp.nextStep")}</th>
                            <th>{t("newCallComp.responStepChange")}</th>
                            <th>{t("newCallComp.serviceRespon")}</th>
                            <th style={{ borderRadius: '0 5px 0 0' }}>{t("newCallComp.action")}</th>
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {
                            segIdCall && segIdCall.map((servSeg) => (
                              <tr key={servSeg.codeIndex} className={servSeg.Tabla === "Cot" ? "bg-gray" : ""}>
                                <td style={{ textAlign: 'center' }} className="col-10">{renderDate(servSeg.FecIngreso, language)}</td>
                                <td className="col-9">{servSeg.NomEtaActual}</td>
                                <td className="col-9">{servSeg.NomEtapa}</td>
                                <td className="col-7">{servSeg.NomEmpleado || "APP"}</td>
                                <td className="col-7">{servSeg.EmpResponsable}</td>
                                <td>{servSeg.Accion}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                    {/* Tabla de herramientas */}
                    <SegToolsTable lang={language} idCall={id} token={getTheToken().token} />


                    {/* TABLA PARA MOSTRAR LAS PARTES QUE TIENE ESTA LLAMADA */}

                    <SegPartsTable lang={language} token={getTheToken().token} idCall={id} />


                    {/* TABLA PARA MOSTRAR LOS REPORTES DE SERVICIO */}
                    <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">{t("serviceCard.reports")}</h4>
                    {
                      serviceReports && serviceReports.length > 0 ? (
                        <div className="table-container-mobile">
                          <table className="table">
                            <thead className="table-head">
                              <tr>
                                <th>

                                </th>
                                <th>{t("serviceCard.date")}</th>
                                <th>{t("serviceCard.serviceReport")}</th>
                                <th>{t("serviceCard.description")}</th>
                                <th>{t("serviceCard.horas")}</th>
                                <th>{t("serviceCard.horas_viaje")}</th>
                                <th>{t("serviceCard.horas_extras")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="table-body">
                              {
                                serviceReports && serviceReports.map((report) => (
                                  <tr key={report.CodRepServicio}>
                                    <td>
                                      {
                                        (empPerm && empPerm[0]?.CodPerfil === 6) && (
                                          <button className="btn-delete">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                            </svg>
                                          </button>
                                        )
                                      }
                                    </td>
                                    <td style={{ textAlign: 'center' }} className="col-10">{renderDate(report.FecReporte, language)}</td>
                                    <td style={{ textAlign: 'center' }}>
                                      {
                                        grupo === 1 ? <Link className="link-tag" to={`/service/service-report-detail/${report.CodRepServicio}`}>{report.NumReporte}</Link>
                                          :
                                          <Link className="link-tag" to={`/commercial/service-report-detail/${report.CodRepServicio}`}>{report.NumReporte}</Link>
                                      }
                                    </td>
                                    <td>{report.Descripcion}</td>
                                    <td className="txt-center">{report.Horas}</td>
                                    <td className="txt-center">{report.HorViaje}</td>
                                    <td className="txt-center">{report.HorExtras + report.HorExtNocturna + report.HorExtFeriado}</td>
                                    <td className="txt-center">
                                      <div style={{ display: "flex", gap: "0.3rem" }}>
                                        <button type="button" className="btn-docs" onClick={() => onOpenModalServRepor(report.CodRepServicio)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                                            <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                                            <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                                          </svg>
                                        </button>
                                        {
                                          report.Reporte && (
                                            <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadServiceReportDoc(report.CodRepServicio, "service report.pdf")}>
                                              {
                                                stateS.isLoading ?
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="var(--first-color)" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                  </svg> :
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                    <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                                  </svg>
                                              }
                                            </button>
                                          )
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{language === "en" ? "There is no service reports" : "Aún no hay reportes de servicio"} &#128064;</strong></div>)
                    }
                  </>
                  {/* )
                } */}
                </form>
              </div>

              {/* Modal para abrir la carga de documentos CA*/}
              {
                state.modalUploadCA && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalUploadCA: false }))}>
                    <div className="container-modal-upload-docs" onClick={(event) => event.stopPropagation()}>
                      <form onSubmit={onHandleSubmitUploadCA}>
                        {/* FILA */}
                        <div className="row-flex">
                          <div className="col-6">
                            <strong>Documento a subir</strong>
                          </div>
                          <div className="col-6 text-hidden-over">
                            <input
                              type="file"
                              name="file"
                              accept=".pdf"
                              onChange={(event) => handleFileChange(event)} />
                          </div>
                        </div>
                        {/* fila */}
                        <div className="row-flex">
                          <div className="col-6"><strong>Tipo documento</strong></div>
                          <div className="col-6">
                            <select
                              name="docuType"
                              value={documentType}
                              onChange={({ target }) => setDocumentType(target.value)}>
                              <option value=""></option>
                              <option value="1">CA</option>
                              <option value="2">POD</option>
                              <option value="3">Packing list</option>
                              <option value="4">Aplicación 1</option>
                              <option value="5">Aplicación 2</option>
                              <option value="6">Aplicación 3</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <span className="span txt-muted">El formato de archivo tiene que ser pdf y el peso máximo es 10mb</span>
                        </div>
                        {/* Botones de accion */}
                        <div className="row-flex j-content-space-around m-y-medium">
                          <button type="submit" className="btn-primary">Subir</button>
                          <button type="reset" className="btn-secondary" onClick={() => setState((val) => ({ ...val, modalUploadCA: false }))}>Cerrar</button>
                        </div>
                      </form>
                    </div>
                  </div>
                )
              }

              {/* Modal para cargar el reporte de servicio */}
              {
                state.modalUploadServRep && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalUploadServRep: false }))}>
                    <UploadServReportComp
                      onSendValue={onCloseModal}
                      onNotify={onNotify}
                      codRepServ={state.codRepServ} />
                  </div>
                )
              }

              {/* button search */}
              <div className="container-btn-search-2">
                <button type="button" className="btn-search" onClick={() => setState((val) => ({ ...val, modalSearchCall: true }))}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </div>
            </div >

            {/* modal para filtrar por una llamada */}
            {
              state.modalSearchCall && (
                <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalSearchCall: false }))}>
                  <div className="container-modal-search-id" onClick={(event) => event.stopPropagation()}>
                    <form style={{ width: "100%" }} onSubmit={onSearchCall}>
                      <div className="row-flex align-c">
                        <div className="col-3"><h5>Buscar llamada</h5></div>
                        <div className="col-6">
                          <input
                            className="input-text-control"
                            type="text"
                            name="searchCall"
                            value={stateForm.idServic}
                            onChange={({ target }) => {
                              if (isNaN(target.value)) {
                                return;
                              }
                              setStateForm((val) => ({ ...val, idServic: target.value }))
                            }} />
                        </div>
                        <div>
                          <button type="submit" className="btn-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )
            }
          </>
        )
      }

      {
        // modal para crear el POD
        state.modalPOD && (
          <GeneratePOD onClose={onHandleCloseGePOD} CodEquip={stateServCall[0]?.CodEquipo} />
        )
      }
    </>
  );
};