// este componente es para crear el detalle de la relacion de gastos

// react imports
import { useEffect, useState } from "react";

// react router import
import { useLocation, useNavigate, useParams } from "react-router-dom";


// global functions
import { HorasViaticos, getTheToken } from "../../global-functions/globalFunctions";
import { urls } from "../../api-urls/api-urls";




export const CreateDetalleRelacion = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the the params
  const { codSolG } = useParams();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data del servidor
  const [controller, setController] = useState(null); // estado para colocar
  const [stateForm, setStateForm] = useState({ fecSal: "", fecReg: "", horSal: "", horReg: "", origen: "", destino: "", cliente: "", concepto: "", subConcept: "", transporte: "", justifi: "", nroBoleIda: "", nroBoleReg: "" });

  // destructuring dataServer
  const { ciudad, conceptos, subConcept, transporte } = dataServer && dataServer;


  // funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      const response = await fetch(`${urls.relacionGasto}/${codSolG}/search?language=${language}&initialData=1`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  return (
    <>
      <div className="display-data container">
        <form className="card-shadow">
          {/* FILA */}
          <div className="row-flex">
            {/* fecha de salida */}
            <div className="col-7"><strong>Fecha de salida</strong></div>
            <div className="col-3">
              <input
                type="date"
                className="input-date-styles"
                name="fecSal"
                value={stateForm.fecSal}
                onChange={({ target }) => setStateForm((val) => ({ ...val, fecSal: target.value }))} />
            </div>
            {/* fecha de regreso */}
            <div className="col-7"><strong>Fecha de regreso</strong></div>
            <div className="col-3">
              <input
                type="date"
                className="input-date-styles"
                name="fecRegr"
                value={stateForm.fecReg}
                onChange={({ target }) => setStateForm((val) => ({ ...val, fecReg: target.value }))} />
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* hora de salida */}
            <div className="col-7"><strong>Hora de salida</strong></div>
            <div className="col-3">
              <select
                name="horSal"
                value={stateForm.horSal}
                onChange={({ target }) => setStateForm((val) => ({ ...val, horSal: target.value }))}>
                <option value=""></option>
                {
                  HorasViaticos && HorasViaticos.map((hor) => (
                    <option key={hor} value={hor}>{hor}</option>
                  ))
                }
              </select>
            </div>
            {/* hora de regreso */}
            <div className="col-7"><strong>Hora de regreso</strong></div>
            <div className="col-3">
              <select
                name="horReg"
                value={stateForm.horReg}
                onChange={({ target }) => setStateForm((val) => ({ ...val, horReg: target.value }))}>
                <option value=""></option>
                {
                  HorasViaticos && HorasViaticos.map((hor) => (
                    <option key={hor} value={hor}>{hor}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* Origen */}
            <div className="col-7"><strong>Origen</strong></div>
            <div className="col-3">
              <select
                name="origin"
                value={stateForm.origen}
                onChange={({ target }) => setStateForm((val) => ({ ...val, origen: target.value }))}>
                <option value=""></option>
                {
                  ciudad && ciudad.map((ciu) => (
                    <option key={ciu.Codigo} value={ciu.Codigo}>{ciu.Nombre}</option>
                  ))
                }
              </select>
            </div>
            {/* destino */}
            <div className="col-7"><strong>Destino</strong></div>
            <div className="col-3">
              <select
                name="origin"
                value={stateForm.destino}
                onChange={({ target }) => setStateForm((val) => ({ ...val, destino: target.value }))}>
                <option value=""></option>
                {
                  ciudad && ciudad.map((ciu) => (
                    <option key={ciu.Codigo} value={ciu.Codigo}>{ciu.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* Cliente */}
            <div className="col-7"><strong>Cliente</strong></div>
            <div className="col-80-94">
              <input
                type="text"
                className="input-text-control"
                name="customer"
                value={stateForm.cliente}
                onChange={({ target }) => setStateForm((val) => ({ ...val, cliente: target.value }))} />
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* concepto */}
            <div className="col-7"><strong>Concepto</strong></div>
            <div className="col-3">
              <select
                name="concept"
                value={stateForm.concepto}
                onChange={({ target }) => setStateForm((val) => ({ ...val, concepto: target.value }))}>
                <option value=""></option>
                {
                  conceptos && conceptos.map((con) => (
                    <option key={con.Codigo} value={con.Codigo}>{con.Descripcion}</option>
                  ))
                }
              </select>
            </div>
            {/* sub concepto */}
            <div className="col-7"><strong>Sub-concepto</strong></div>
            <div className="col-3">
              <select
                name="subConcep"
                value={stateForm.subConcept}
                onChange={({ target }) => setStateForm((val) => ({ ...val, subConcept: target.value }))}>
                <option value=""></option>
                {
                  subConcept && subConcept.map((subCon) => (
                    <option key={subCon.Codigo} value={subCon.Codigo}>{subCon.Descripcion}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* transporte */}
            <div className="col-7"><strong>Transporte</strong></div>
            <div className="col-3">
              <select
                name="trans"
                value={stateForm.transporte}
                onChange={({ target }) => setStateForm((val) => ({ ...val, transporte: target.value }))}>
                <option value=""></option>
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* justificacion */}
            <div className="col-7"><strong>Justificación</strong></div>
            <div className="col-80-94">
              <input
                type="text"
                className="input-text-control"
                name="justif"
                value={stateForm.justifi}
                onChange={({ target }) => setStateForm((val) => ({ ...val, justifi: target.value }))}
                autoComplete="off" />
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* nro boleto ida */}
            <div className="col-7"><strong>Nro. Boleto ida</strong></div>
            <div className="col-3">
              <input
                type="text"
                className="input-text-control"
                name="numBolId"
                value={stateForm.nroBoleIda}
                onChange={({ target }) => setStateForm((val) => ({ ...val, nroBoleIda: target.value }))}
                autoComplete="off" />
            </div>
            {/* nro boleto regreso */}
            <div className="col-7"><strong>Nro. Boleto regreso</strong></div>
            <div className="col-3">
              <input
                type="text"
                className="input-text-control"
                name="numBolReg"
                value={stateForm.nroBoleReg}
                onChange={({ target }) => setStateForm((val) => ({ ...val, nroBoleReg: target.value }))}
                autoComplete="off" />
            </div>
          </div>

          {/* botones de accion */}
          <div className="row-flex j-content-space-around m-y-medium">
            <button type="button" className="btn-primary">Guardar</button>
            <button type="reset" className="btn-secondary">Atrás</button>
          </div>
        </form>
      </div>
    </>
  );
};