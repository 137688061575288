// Este componentes es para crear el detalle de anticipo
import React, { useEffect, useState } from "react";

// Components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// React router importation
import { useLocation, useNavigate, useParams } from "react-router-dom";


// Global functions importation
import { HorasViaticos, getTheToken } from "../../global-functions/globalFunctions";

// Url importation
import { urls } from "../../api-urls/api-urls";

// React translation
import { useTranslation } from "react-i18next";



export const CreateDetalleAnticipo = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codSolG } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]);
  const [questionAction, setQuestionAction] = useState(""); // Para realizar preguntas de confirmacion
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false });
  const [stateForm, setStateForm] = useState({ fecSalida: "", fecRegreso: "", horSalida: "", horRegreso: "", origen: "", destino: "", cliente: "", concepto: "", subConcepto: "", transporte: "", otrosGastos: "", justificacion: "", zona: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false, codDet: 0 });


  // Destructuring data server
  const { initialData } = dataServer && dataServer;


  // Funcion para abrir el modal al dar click en guardar
  const onSaveOpenModal = () => {
    if (stateForm.fecSalida === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la fecha de salida.", error: true }));
    }

    if (stateForm.fecRegreso === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la fecha de regreso.", error: true }));
    }

    if (stateForm.horSalida === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la hora de salida.", error: true }));
    }

    if (stateForm.horRegreso === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la fecha de regreso.", error: true }));
    }

    if (stateForm.origen === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar cuál es el origen.", error: true }));
    }

    if (stateForm.destino === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar cuál es el destino.", error: true }));
    }

    if (stateForm.cliente === "" || stateForm.cliente.length < 2) {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar el cliente y debe ser un nombre válido.", error: true }));
    }

    if (stateForm.concepto === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar el concepto.", error: true }));
    }

    if (stateForm.transporte === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar cuál es el transporte que usará.", error: true }));
    }

    if (stateForm.fecSalida > stateForm.fecRegreso) {
      return setNotification((val) => ({ ...val, show: true, msg: "La fecha de salida no puede ser mayor a la fecha de regreso.", error: true }));
    }

    if (stateForm.origen === stateForm.destino) {
      return setNotification((val) => ({ ...val, show: true, msg: "La ciudad de origen debe ser distinta a la de destino.", error: true }));
    }

    if (stateForm.horSalida === stateForm.horRegreso) {
      return setNotification((val) => ({ ...val, show: true, msg: "La hora de salida no puede ser igual a la hora de regreso.", error: true }));
    }

    setQuestionAction("Está seguro que desea guardar esta información?");
    setState((val) => ({ ...val, showModal: true }));
  };



  // Funcion para traer la data del servidor
  const fetchDataServer = async () => {
    try {
      const response = await fetch(`${urls.anticipo}/${codSolG}/search?language=${language}&initialData=1`, {
        method: "POST",
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

  }, [language]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useEffect para redireccionar 
  useEffect(() => {
    if (notification.success) {
      const timer = setTimeout(() => {
        navigate(`/travel-expenses/expense-detail/${codSolG}/${notification.codDet}`);
      }, 4000);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.success]);



  // Funcion para manejar el envio del formulario
  const onCreateDetalleAdvance = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.anticipo}/${codSolG}/search?language=${language}&initialData=0`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();


      if (response.status === 200) {
        setState((val) => ({ ...val, showModal: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true, codDet: data?.codDetSolG }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };




  return (
    <>
      {/* Loading spinner */}
      {
        state.isLoading && (
          <div className="load-cont-transp">
            <div className="simple-spinner"><span></span></div>
            <p><strong>{language === "en" ? "Loading..." : "Cargando..."}</strong></p>
          </div>
        )
      }
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <div className="card-shadow">
              <form onSubmit={onCreateDetalleAdvance}>
                {/* FILA */}
                <div className="row-flex">
                  {/* Nro. Control */}
                  <div className="col-7">
                    <strong>Nro. Control</strong>
                  </div>
                  <div className="col-3">
                    <h4>{codSolG}</h4>
                  </div>
                  {/* Etapa */}
                  <div className="col-7">
                    <strong>Etapa</strong>
                  </div>
                  <div className="col-3">
                    <strong>Elaboracion de anticipo</strong>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Fecha de Salida */}
                  <div className="col-7">
                    <strong>Fecha de Salida</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecSal"
                      value={stateForm.fecSalida}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fecSalida: target.value }))} />
                  </div>
                  {/* Fecha de regreso */}
                  <div className="col-7">
                    <strong>Fecha de regreso</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecReturn"
                      value={stateForm.fecRegreso}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fecRegreso: target.value }))} />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Hora de salida */}
                  <div className="col-7">
                    <strong>Hora de salida</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="horSal"
                      value={stateForm.horSalida}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, horSalida: target.value }))}>
                      <option value=""></option>
                      {
                        HorasViaticos && HorasViaticos.map((hor) => (
                          <option key={hor} value={hor}>{hor}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* Hora de regreso */}
                  <div className="col-7">
                    <strong>Hora de regreso</strong>
                  </div>
                  <div className="col-3">
                    <select
                      className={stateForm.horSalida === "" ? "wrapper" : ""}
                      disabled={stateForm.horSalida === ""}
                      name="horReturn"
                      value={stateForm.horRegreso}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, horRegreso: target.value }))}>
                      <option value=""></option>
                      {
                        HorasViaticos && HorasViaticos.map((hor) => (
                          <option key={hor} value={hor}>{hor}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Zona</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="area"
                      value={stateForm.zona}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, zona: target.value }))}>
                      <option value=""></option>
                      {
                        initialData && initialData[0]?.zona.map((zona) => (
                          <option key={zona.CodZona} value={zona.CodZona}>{zona.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Origen */}
                  <div className="col-7">
                    <strong>Origen</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="origin"
                      value={stateForm.origen}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, origen: target.value }))}>
                      <option value=""></option>
                      {
                        initialData && initialData[0]?.cities.filter((ci) => ci.CodZona.toString() === stateForm.zona).map((city) => (
                          <option key={city.Codigo} value={city.Codigo}>{city.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* Destino */}
                  <div className="col-7">
                    <strong>Destino</strong>
                  </div>
                  <div className="col-3">
                    <select
                      className={stateForm.origen === "" ? "wrapper" : ""}
                      disabled={stateForm.origen === ""}
                      name="destin"
                      value={stateForm.destino}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, destino: target.value }))}>
                      <option value=""></option>
                      {
                        initialData && initialData[0]?.cities.filter((ci) => ci.CodZona.toString() === stateForm.zona).map((city) => (
                          <option key={city.Codigo} value={city.Codigo}>{city.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Cliente */}
                  <div className="col-7">
                    <strong>Cliente</strong>
                  </div>
                  <div className="col-80-94">
                    <input
                      className="input-text-control"
                      type="text"
                      value={stateForm.cliente}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, cliente: target.value }))} />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Concepto */}
                  <div className="col-7">
                    <strong>Concepto</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="concept"
                      value={stateForm.concepto}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, concepto: target.value }))}>
                      <option value=""></option>
                      {
                        initialData && initialData[0]?.concepts.map((con) => (
                          <option key={con.Codigo} value={con.Codigo}>{con.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* SubConceptop */}
                  <div className="col-7">
                    <strong>Sub-concepto</strong>
                  </div>
                  <div className="col-3">
                    <select
                      className={stateForm.concepto === "" ? "wrapper" : ""}
                      disabled={stateForm.concepto === ""}
                      name="sub-concept"
                      value={stateForm.subConcepto}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, subConcepto: target.value }))}>
                      <option value=""></option>
                      {
                        initialData && initialData[0]?.subConcepts.map((sub) => (
                          <option key={sub.Codigo} value={sub.Codigo}>{sub.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Transporte */}
                  <div className="col-7">
                    <strong>Transporte</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="transport"
                      value={stateForm.transporte}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, transporte: target.value }))}>
                      <option value=""></option>
                      {
                        initialData && initialData[0]?.transports.map((trans) => (
                          <option key={trans.Codigo} value={trans.Codigo}>{trans.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* Otros gastos */}
                  {/* <div className="col-7">
                    <strong>Otros gastos</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      value={stateForm.otrosGastos}
                      onChange={({ target }) => {
                        if (isNaN(target.value)) {
                          return;
                        }
                        setStateForm((val) => ({ ...val, otrosGastos: target.value }));
                      }}
                      autoComplete="off" />
                  </div> */}
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Justificacion */}
                  <div className="col-7">
                    <strong>Justificación</strong>
                  </div>
                  <div className="col-80-94">
                    <input
                      className="input-text-control"
                      type="text"
                      value={stateForm.justificacion}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, justificacion: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                {/* Botones de accion */}
                <div className="row-flex m-y-medium j-content-space-around">
                  <button type="button" className="btn-primary" onClick={onSaveOpenModal}>Guardar</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
                </div>

                {/* Modal para confirmar que desea guardar */}
                {
                  state.showModal && (
                    <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                      <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                        {/* Question mark */}
                        <div className="row-flex m-y-small align-c justify-center">
                          <div className="question-mark-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                            </svg>
                          </div>
                        </div>
                        <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                          {questionAction}
                        </h5>
                        {/* Botones de accion */}
                        <div className="row-flex m-y-medium j-content-space-around">
                          <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                          <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                        </div>
                      </div>
                    </div>
                  )
                }
              </form>
            </div>
          )
        }
      </div>
    </>
  );
};