// Este componente es para confirmar retiro de partes

import React, { useEffect, useState } from "react";


// React router dom importation
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";


// React translation
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";



export const ConfirRetPartesDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // React translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // GET the useParams
  const { codNotRet } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]);
  const [partes, setPartes] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, serverError: false, notFound: false, unauthorized: false, showModal: false });
  const [stateForm, setStateForm] = useState({ comment: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // Destructuring data server
  const { confirmRetiro } = dataServer && dataServer;


  // Funcion para enviar el formulario al servidor
  const onHandleConfirmParts = async (event) => {
    event.preventDefault();

    const finalObj = { stateForm, partes };

    console.log("Se envio el formulario");
    try {
      const response = await fetch(`${urls.confirmRetiroPart}/${codNotRet}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification({ show: true, msg: data?.msg, error: false, great: true, success: true });
      }

    } catch (error) {
      console.log(error);
    }
  };



  // Funcion para cambiar el valor de perdida
  const onHandleChangePerdida = (newVal, index) => {
    const updatePerdida = [...partes];

    updatePerdida[index].Perdida = newVal;

    setPartes(updatePerdida);
  };



  // useEffect para traer la data del servidor
  useEffect(() => {
    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.confirmRetiroPart}/${codNotRet}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, dataVisible: false, unauthorized: false }));
        }

        setState((val) => ({ ...val, isLoading: false }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

    document.title = language === "en" ? "Withdrawal parts confirmation - GIS App" : "Confirmación retiro de partes - GIS App"

  }, [codNotRet, language]);


  // useEffect para colocar las partes en el estado
  useEffect(() => {
    if (confirmRetiro && confirmRetiro.length > 0) {
      setPartes(confirmRetiro);
    }
  }, [confirmRetiro]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);

  // console.log(confirmRetiro);

  return (
    <>
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      <div className="container display-data">

        {
          state.dataVisible && (
            <>
              <form onSubmit={onHandleConfirmParts}>
                {/* FILA */}
                <div className="row-flex">
                  {/* Comentario */}
                  <div className="col-7"><strong>Comentario</strong></div>
                  <div className="col-83-87">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={stateForm.comment}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                      placeholder={t("RequestPartsView.si_tiene_comen")}
                      autoComplete="off" />
                  </div>
                </div>

                {/* Tabla para mostrar el detalle de las partes */}
                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Nota de retiro</th>
                        <th>Parte</th>
                        <th>Descripción</th>
                        <th>Transportista</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        partes.map((part, index) => (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="col-7 txt-center">{part.CodNotRetiro}</td>
                            <td><Link className="link-tag">{part.NumParte}</Link></td>
                            <td>{part.Descripcion}</td>
                            <td>{part.Transporte}</td>
                            <td className="col-10">
                              <select
                                name={`Estado-${part.CodDetSolRepuesto}`}
                                value={part.Perdida}
                                onChange={({ target }) => onHandleChangePerdida(target.value, index)}>
                                <option value=""></option>
                                <option value="0">Retirada</option>
                                <option value="1">Perdida</option>
                              </select>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Botones de accion */}
                <div className="row-flex j-content-space-around m-y-medium">
                  <button type="submit" className="btn-primary">Confirmar</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
                </div>

              </form>
            </>
          )
        }

      </div>
    </>
  );
};