
import React, { useState, useEffect } from "react";

/* --> Este componente es para crear los reportes de servicio que corresponden a las llamadas <-- */


// Components importation
import { ToastNotification } from "../toast-notification/ToastNotification";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../../views/error-403-view/Error403View";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";


// Urls importation
import { urls } from "../../api-urls/api-urls";


// Global function importation
import { getTheToken, renderDate, renderFormatTime } from "../../global-functions/globalFunctions";

// React translation
import { useTranslation } from "react-i18next";


export const ServiceReport = ({ grupo }) => {
  // language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // React translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // useLocation()
  const location = useLocation();

  // GET the useParams
  const { idCall } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]); // para cargar la data del servidor en el estado
  const [controller, setController] = useState(null); // para cargar el abort controller
  const [state, setState] = useState({ isLoading: false, secondLoading: false, dataVisible: false, unauthorized: false, serverError: false, badRequest: false, notFound: false, showModal: false, shoModalConfirm: false, showModalDelEmp: false, questionAction: "" });
  const [stateForm, setStateForm] = useState({
    prioridad: "", ingInvolucrados: "", descripcion: "", serialTuboInst: "", serialTransInst: "", serialTuboDes: "", serialTransDes: "", fecDesde: "", fecHasta: "", action: "", horas: 0, horExtras: 0, horExtNocturna: 0, horExtFeriado: 0, horViaje: 0, totHoras: 0
  });
  const [notification, setNotification] = useState({ show: false, msg: "", great: false, errorNoti: "", success: false, codRepServ: 0 });
  const [deleteFec, setDeleteFec] = useState({ codRepServ: 0, codRepSerFec: 0, temporal: 0 });
  const [deleteEmp, setDeleteEmp] = useState({ codRepServ: 0, codEmp: 0, temporal: 0 });


  // Destructuring data server
  const { infoCustomer, serFechaReport, HorasTotales, empRepServ, empRepServList } = dataServer && dataServer;


  // funcion para abrir el modal de confirmacion para eliminar un empleado
  const onDeleteEmployeeConfirm = (codRepServ, codEmp, temporal) => {

    setState((val) => ({ ...val, showModalDelEmp: true, questionAction: t("serviceCard.eliminar_empleado") }));

    setDeleteEmp((val) => ({ ...val, codRepServ, codEmp, temporal }));

  };

  // funcion que elimina el empleado
  const onDeleteEmployee = async () => {

    try {
      const response = await fetch(`${urls.serviceReport}/${deleteEmp.codRepServ}/${deleteEmp.codEmp}/search?language=${language}&temporal=${deleteEmp.temporal}`, {
        method: "DELETE",
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setState((val) => ({ ...val, showModalDelEmp: false }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
        setState((val) => ({ ...val, showModalDelEmp: false }));
      }

    } catch (error) {
      console.log(error);
    }
  };

  // funcion para cerrar el modal
  const onCloseModalEmp = () => {
    setState((val) => ({ ...val, showModalDelEmp: false }));
    setDeleteEmp((val) => ({ ...val, codRepServ: 0, codEmp: 0, temporal: 0 }));
  };


  // este abre el modal de confirmacion se utiliza para eliminar una fecha
  const onDeleteSerFechaConfirm = (codRepServ, codRepSerFec, temporal) => {

    setState((val) => ({ ...val, shoModalConfirm: true, questionAction: t("serviceCard.eliminar_fec") }));

    setDeleteFec((val) => ({ ...val, codRepServ, codRepSerFec, temporal }));

  };

  // cerrar el modal
  const onCloseDeleteSerFecha = () => {
    setState({ ...state, shoModalConfirm: false, questionAction: "" });
    setDeleteFec((val) => ({ ...val, codRepServ: 0, codRepSerFec: 0, temporal: 0 }));
  };

  // elimina la fecha en la base de datos
  const onDeleteSerFecha = async () => {

    try {
      const response = await fetch(`${urls.serviceReport}/${deleteFec.codRepServ}/${deleteFec.codRepSerFec}/${deleteFec.temporal}/search?language=${language}`, {
        method: "DELETE",
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setState((val) => ({ ...val, shoModalConfirm: false }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
        setState((val) => ({ ...val, shoModalConfirm: false }));
      }

    } catch (error) {
      console.log(error);
    }
  };



  // Funcion para calcular el total de horas
  const onHandleCalHor = (newVal) => {
    if (isNaN(newVal)) {
      return;
    }
    const totalHoras = Number(stateForm.horas) + Number(stateForm.horExtras) + Number(stateForm.horExtNocturna) + Number(stateForm.horExtFeriado) + Number(newVal);

    setStateForm((val) => ({ ...val, horViaje: newVal, totHoras: totalHoras }));
  };


  // Funcion para abrir el modal cuando se de click en guardar
  const onHandleSaveShowModal = () => {
    if (stateForm.prioridad === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("serviceCard.seleccionar_tempe"), errorNoti: true }));
    }

    if (stateForm.descripcion.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("serviceCard.seleccionar_traba_reali"), errorNoti: true }));
    }

    if (stateForm.descripcion.trim().length < 5) {
      return setNotification((val) => ({ ...val, show: true, msg: t("serviceCard.seleccionar_descrip_valida"), errorNoti: true }));
    }

    setState((val) => ({ ...val, showModal: true, questionAction: t("serviceCard.guardar_reporte") }));
  };


  // Funcion para agregar un empleado en la lista de reportes
  const onAddEmployee = async () => {
    setStateForm((val) => ({ ...val, action: "1" }));

    if (stateForm.ingInvolucrados === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("serviceCard.seleccionar_ing_involu"), errorNoti: true }));
    }

    const finalObj = { stateForm };

    try {
      const response = await fetch(`${urls.serviceReport}/${idCall}/search?language=${language}&initialData=no&action=1`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setStateForm((val) => ({ ...val, ingInvolucrados: "" }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };

  // funcion para agregar la fecha a la base de datos y realizar los calculos
  const onAddDate = async () => {

    const finalObj = { stateForm };

    try {
      const response = await fetch(`${urls.serviceReport}/${idCall}/search?language=${language}&initialData=no&action=2`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setStateForm((val) => ({ ...val, fecDesde: "", fecHasta: "" }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };



  // Funcion para crear los reportes de servicio
  const onCreateServiceReport = async (event) => {
    event.preventDefault();

    const empRepServi = dataServer.empRepServ
    const serFecReport = dataServer.serFechaReport

    const finalObj = { stateForm, empRepServi, serFecReport };

    try {
      setState((val) => ({ ...val, secondLoading: true }));
      const response = await fetch(`${urls.serviceReport}/${idCall}/search?language=${language}&initialData=no&action=3`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification({ show: true, msg: data?.msg, great: false, errorNoti: false, success: true, codRepServ: data?.codRepServ });
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }


      setState((val) => ({ ...val, secondLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // Funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.serviceReport}/${idCall}/search?language=${language}&initialData=yes&grupo=${grupo}`, {
        method: "POST",
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 400) {
        setState((val) => ({ ...val, dataVisible: false, badRequest: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, notFound: true }));
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      setNotification((val) => ({ ...val, show: true, msg: t("General_Comp.error_comunic_serv"), errorNoti: true }));
      // console.log(error);
      console.clear();
      setState((val) => ({ ...val, isLoading: false }));
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idCall, language]);


  // useEffect para recargar la data del servidor
  useEffect(() => {
    if (notification.success && notification.codRepServ === 0) {
      fetchDataServer();
    }
  }, [notification.success]);


  // Si la respuesta es satisfactoria, redireccionar a serviceReportDetail
  useEffect(() => {
    if (notification.success && notification.codRepServ > 0) {
      const timer = setTimeout(() => {
        if (grupo === 1) {
          navigate(`/service/service-report-detail/${notification.codRepServ}`);
        } else {
          navigate(`/commercial/service-report-detail/${notification.codRepServ}`);
        }
      }, 3000);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.success]);


  // useEffect para cargar las horas
  useEffect(() => {
    if (HorasTotales && HorasTotales.length > 0) {
      setStateForm((val) => ({ ...val, horas: HorasTotales[0]?.THorNormal, totHoras: HorasTotales[0]?.THoras, horExtFeriado: HorasTotales[0]?.THorFeriado, horExtNocturna: HorasTotales[0]?.THorNocturna, horExtras: HorasTotales[0]?.THorExtras }));
    }
  }, [HorasTotales]);



  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", great: false, errorNoti: false, success: false, codRepServ: 0 });
    }, 6000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  return (
    <>
      {
        // Error 403 component
        state.unauthorized && (
          <Error403View />
        )
      }
      {
        // Notification component
        notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)
      }
      {
        // Spinner loading secundario
        state.secondLoading && (<div className="simple-spinner"><span></span></div>)
      }
      {
        // Loading components
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onCreateServiceReport}>
              {/* FILA 2 */}
              <div className="row-flex m-y-small">
                {/* CLIENTE */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.customer")}</strong></div>
                <div className="col-80 col-sm-70-mod text-hidden-over"><span>{infoCustomer && infoCustomer[0]?.NomCliente}</span></div>
              </div>

              {/* FLA 3 */}
              <div className="row-flex">
                {/* EQUIPO */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.equipment")}</strong></div>
                <div className="col-4 col-sm-70-mod text-hidden-over"><span>{infoCustomer && infoCustomer[0]?.Equipo}</span></div>
                {/* MODELO */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.modelo")}</strong></div>
                <div className="col-3 col-sm-70-mod text-hidden-over"><span>{infoCustomer && infoCustomer[0]?.Modelo}</span></div>
              </div>
              <hr className="m-y-small" />

              {/* FILA 4 */}
              <div className="row-flex">
                {/* ESTADO DEL EQUIPO DESPUES DEL SERVICIO */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.estado_equip")}</strong></div>
                <div className="col-75 col-sm-70-mod row-flex">
                  {/* Operativo */}
                  <div className="col-4 col-sm-80 row-flex gap-medium" style={{ alignItems: 'center' }}>
                    <div className="input-control bg-green circle-temp">
                      <input
                        type="radio"
                        value="0"
                        name="temperature"
                        checked={stateForm.prioridad === "0"}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, prioridad: target.value }))} />
                    </div>
                    <div><span>{t("serviceCard.operativo")}</span></div>
                  </div>

                  {/* Operativo con fallas */}
                  <div className="col-3 col-sm-80 row-flex gap-medium" style={{ alignItems: 'center' }}>
                    <div className="input-control bg-yellow circle-temp">
                      <input
                        type="radio"
                        value="1"
                        name="temperature"
                        checked={stateForm.prioridad === "1"}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, prioridad: target.value }))} />
                    </div>
                    <div><span>{t("serviceCard.operativo_fallas")}</span></div>
                  </div>

                  {/* Parado */}
                  <div className="col-4 col-sm-80 row-flex gap-medium" style={{ alignItems: 'center' }}>
                    <div className="input-control bg-red circle-temp">
                      <input
                        type="radio"
                        value="4"
                        name="temperature"
                        checked={stateForm.prioridad === "4"}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, prioridad: target.value }))} />
                    </div>
                    <div><span>{t("serviceCard.equip_detenido")}</span></div>
                  </div>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex m-y-small">
                {/* INGENIEROS INVOLUCRADOS */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.ing_involucrados")}</strong></div>
                <div className="col-4 col-sm-50">
                  <select
                    name="ingenInvolucrados"
                    value={stateForm.ingInvolucrados}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ingInvolucrados: target.value }))}>
                    <option value=""></option>
                    {
                      empRepServList && empRepServList.map((emp) => (
                        <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-10">
                  <button type="button" className="btn-add-date gap-small row-flex align-c" onClick={onAddEmployee}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* lista de los empleados que participaran en el reporte */}
              {
                empRepServ && empRepServ.length > 0 && (
                  <div className="row-flex m-y-small d-column">
                    {
                      empRepServ && empRepServ.map((emp) => (
                        <div key={emp.CodEmpleado} className="col-6">
                          <div className="row-flex gap-small align-c m-l-small">
                            <div>
                              <button type="button" className="btn-delete" onClick={() => onDeleteEmployeeConfirm(emp.CodRepServicio, emp.CodEmpleado, emp.Temporal)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                </svg>
                              </button>
                            </div>
                            <div>
                              <span>{emp.NomEmpleado}</span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }

              {/* FILA 6 */}
              <div className="row-flex">
                {/* FECHA DEL SERVICIO (Desde) */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.fec_servicio")}</strong></div>
                <div className="col-4 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecDesde"
                    value={stateForm.fecDesde}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecDesde: target.value }))} />
                </div>
                {/* Fecha del servicio (Hasta) */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.fec_hasta")}</strong></div>
                <div className="col-5 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecHasta"
                    value={stateForm.fecHasta}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecHasta: target.value }))} />
                </div>
                <div className="col-7 col-100-sm flex-mob-btn-add-date">
                  <button type="button" className="btn-add-date gap-small row-flex align-c" onClick={onAddDate}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>
                    {t("serviceCard.add_fec")}
                  </button>
                </div>
              </div>

              <hr className="m-y-small" />

              {/* Tabla para mostrar las fechas */}
              {
                serFechaReport && serFechaReport.length > 0 && (
                  <div className="table-container-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th></th>
                          <th>{t("serviceCard.fec_ini")}</th>
                          <th>{t("serviceCard.fec_fin")}</th>
                          <th>{t("serviceCard.hor_normal")}</th>
                          <th>{t("serviceCard.hor_ext_diurnas")}</th>
                          <th>{t("serviceCard.hor_ext_nocturnas")}</th>
                          <th>{t("serviceCard.hor_ext_feriados")}</th>
                          <th>{t("serviceCard.total_hor")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          serFechaReport && serFechaReport.map((fec) => (
                            <tr key={fec.CodRepSerFecha}>
                              <td className="txt-center">
                                <button type="button" className="btn-del" onClick={() => onDeleteSerFechaConfirm(fec.CodRepServicio, fec.CodRepSerFecha, fec.Temporal)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                  </svg>
                                </button>
                              </td>
                              <td>{renderDate(fec.FecInicio, language)} / {renderFormatTime(fec.FecInicio)}</td>
                              <td>{renderDate(fec.FecFin, language)} / {renderFormatTime(fec.FecFin)}</td>
                              <td className="txt-center">{fec.HorNormal}</td>
                              <td className="txt-center">{fec.HorExtras}</td>
                              <td className="txt-center">{fec.HorExtNocturna}</td>
                              <td className="txt-center">{fec.HorExtFeriado}</td>
                              <td className="txt-center">{fec.TotalHoras}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                )
              }

              {/* FILA 7 */}
              <div className="row-flex m-y-small">
                {/* DESCRIPCION DEL TRABAJO */}
                <div className="col-5"><strong>{t("serviceCard.descrip_trabajo")}</strong></div>
                <div className="col-80" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '0.5rem' }}>
                  <textarea
                    className="textarea-description"
                    name="descripcionDelTrabajo"
                    value={state.descripcion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, descripcion: target.value }))}
                    placeholder={language === "en" ? "Describe the work done..." : "Describa el trabajo que realizó..."}
                    autoComplete="off" />
                  <strong className="txt-muted">{stateForm.descripcion.length} / max 4000</strong>
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* NUMERO SERIAL DEL TUBO */}
                <div className="col-5 col-sm-50"><strong>{t("serviceCard.num_serial")}</strong></div>
                <div className="col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    name="serialTubo"
                    type="text"
                    value={stateForm.serialTuboInst}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, serialTuboInst: target.value }))}
                    placeholder={language === "en" ? "Serial number tube if apply..." : "Número de Serial del tubo si aplica..."}
                    autoComplete="off" />
                </div>
                {/* Numero serial tubo desinstalado */}
                <div className="col-5 col-4 col-sm-50"><strong>{t("serviceCard.num_serial_tub_desins")}</strong></div>
                <div className="col-4 col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    type="text"
                    name="serTubDes"
                    value={stateForm.serialTuboDes}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, serialTuboDes: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex m-y-small">
                {/* NUMERO SERIAL TRANSDUCTOR instalado */}
                <div className="col-5 col-4 col-sm-50"><strong>{t("serviceCard.num_serial_trans")}</strong></div>
                <div className="col-4 col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    name="serialTransductor"
                    type="text"
                    value={stateForm.serialTransInst}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, serialTransInst: target.value }))}
                    placeholder={language === "en" ? "Serial number transducer if apply..." : "Número de Serial del transductor si aplica..."}
                    autoComplete="off" />
                </div>
                {/* Numero serial transductor desinstalado */}
                <div className="col-5 col-4 col-sm-50"><strong>{t("serviceCard.num_serial_trans_desins")}</strong></div>
                <div className="col-4 col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    type="text"
                    name="serTransDes"
                    value={stateForm.serialTransDes}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, serialTransDes: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex flex-end">
                <div className="col-42-87 col-100-sm row-flex flex-end">
                  {/* Servicio normal */}
                  <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_norm")}</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hours"
                      value={stateForm.horas}
                      readOnly />
                  </div>
                  {/* Servicio H extra diurnas */}
                  <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_hor_ext_diurn")}</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hExtras"
                      value={stateForm.horExtras}
                      readOnly />
                  </div>
                  {/* Servicio H extra nocturnas */}
                  <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_hor_ext_noctur")}</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hExtNoct"
                      value={stateForm.horExtNocturna}
                      readOnly />
                  </div>
                  {/* Servicio H/Extra sabados, domingos y feriados */}
                  <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_hor_ext_sab_dom")}</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hExtFeri"
                      value={stateForm.horExtFeriado}
                      readOnly />
                  </div>
                  {/* Horas en Viajes */}
                  <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.en_viajes")}</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="horViaj"
                      value={stateForm.horViaje}
                      onChange={({ target }) => onHandleCalHor(target.value)} />
                  </div>
                  {/* Total Horas */}
                  <div className="col-58"><strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.total_hor")}</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control wrapper"
                      type="text"
                      name="totalHor"
                      value={stateForm.totHoras}
                      readOnly />
                  </div>
                </div>
              </div>

              {/* BOTONES DE ACCION */}
              <div className="row-flex j-content-space-around m-y-medium">
                {
                  serFechaReport && serFechaReport.length > 0 && (
                    <button
                      className={`btn-primary ${state.secondLoading ? "disabled-class" : ""}`}
                      type="button"
                      disabled={state.secondLoading}
                      onClick={onHandleSaveShowModal}>{state.secondLoading ? t("serviceCard.guardando") : t("serviceCard.guardar")}</button>
                  )
                }
                <button className="btn-secondary" type="reset" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                        <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }
      </div>

      {
        state.shoModalConfirm && (
          <div className="background-modal" onClick={() => setState({ ...state, shoModalConfirm: false })}>
            <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
              {/* Question mark */}
              <div className="row-flex m-y-small align-c justify-center">
                <div className="question-mark-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                  </svg>
                </div>
              </div>
              <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                {state.questionAction}
              </h5>
              {/* Botones de accion */}
              <div className="row-flex m-y-medium j-content-space-around">
                <button type="button" className="btn-success" onClick={onDeleteSerFecha}>{t("RequestPartsView.si")}</button>
                <button type="button" className="btn-danger" onClick={onCloseDeleteSerFecha}>No</button>
              </div>
            </div>
          </div>
        )
      }

      {
        state.showModalDelEmp && (
          <div className="background-modal" onClick={() => setState({ ...state, showModalDelEmp: false })}>
            <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
              {/* Question mark */}
              <div className="row-flex m-y-small align-c justify-center">
                <div className="question-mark-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                  </svg>
                </div>
              </div>
              <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                {state.questionAction}
              </h5>
              {/* Botones de accion */}
              <div className="row-flex m-y-medium j-content-space-around">
                <button type="button" className="btn-success" onClick={onDeleteEmployee}>{t("RequestPartsView.si")}</button>
                <button type="button" className="btn-danger" onClick={onCloseModalEmp}>No</button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};