import React, { useEffect, useState } from "react";


// COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";

// REAT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// REACT TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";
import { useDownloadDocs } from "../../components/customs/useDownloadDocs";


export const EquipmentDetailView = () => {

  // Get the language 
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // REACT TRANSLATION
  const [t] = useTranslation("global");

  // NAVIGATE
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();

  // get the params
  const { codEquipo } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false });
  const [controller, setController] = useState(null); // controlador para abortar la peticion al servidor

  // DESTRUCTURING DATA SERVER
  const { equipment, equipmentContract, fecInicio, fecFin, frecuenciaNom, correctiveMaint, preventMaint, FMI, entriesWorkshop, sitePlanning, parts } = dataServer && dataServer;


  // custom hook for download doc
  const { downLoadFile, stateS } = useDownloadDocs();


  // funcion para realizar el fetch al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.equipments}/${codEquipo}/search?language=${language}&modify=0`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 500) {
        // 
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para descargar documentos
  const onHandleDownload = (doc = 0, name = "") => {

    const END_POINT = `${urls.getEqpSigned}/${codEquipo}/search?language=${language}&doc=${doc}`;

    downLoadFile(END_POINT, name, "application/pdf"); // falta el fileName
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codEquipo, language]);


  return (
    <>
      {/* Unauthorized compo */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {
        state.isLoading && (<LoadingComp />)
      }
      {/* VERIFICACION DE PERMISO */}
      {
        state.dataVisible && (
          <>
            <div className="container display-data">

              {/* FILA 1 */}
              <div className="row-flex">
                {/* MODELO */}
                <div className="col-9"><strong>{t("equipmentView.model")}</strong></div>
                <div><span>{equipment && equipment[0]?.CodDivision} - {equipment && equipment[0]?.Linea} - {equipment && equipment[0]?.Modalidad} - {equipment && equipment[0]?.TipEquipo} - {equipment && equipment[0]?.Modelo} - {equipment && equipment[0]?.Serial} - {equipment && equipment[0]?.Marca} - {equipment && equipment[0]?.NumSistema}</span></div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-9"><strong>{t("equipmentView.customer")}</strong></div>
                <div><Link className="link-tag" to={`/customers/customer-detail/${equipment && equipment[0]?.CodCliente}`}>{equipment && equipment[0]?.Cliente}</Link></div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* ALIAS */}
                <div className="col-9"><strong>Alias</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.Alias}</span></div>
                {/* UBICACION */}
                <div className="col-9"><strong>Ubicación</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.Ubicacion}</span></div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* VERSION SOFTWARE */}
                <div className="col-9"><strong>{t("equipmentView.softwareV")}</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.VerSoftware}</span></div>
                {/* TECNICO F1 */}
                <div className="col-9"><strong>{t("equipmentView.tecnician")} F1</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.NomF1}</span></div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* TECNICO F2 */}
                <div className="col-9"><strong>{t("equipmentView.tecnician")} F2</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.NomF2}</span></div>
                {/* TECNICO F3 */}
                <div className="col-9"><strong>{t("equipmentView.tecnician")} F3</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.NomF3}</span></div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* NUMERO DE SISTEMA */}
                <div className="col-9"><strong>{t("equipmentView.SID")}</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.NumSistema}</span></div>
                {/* NUMERO DE CARPETA */}
                <div className="col-9"><strong>{t("equipmentView.numFolder")}</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.NumCarpeta}</span></div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* SERIAL */}
                <div className="col-9"><strong>Serial</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.Serial}</span></div>
                {/* PSI SCODE */}
                <div className="col-9"><strong>PSI Scode</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.OCG}</span></div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* GON */}
                <div className="col-9"><strong>GON</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.FDO}</span></div>
                {/* PO# */}
                <div className="col-9"><strong>PO #</strong></div>
                <div className="col-3"><span>{equipment && equipment[0]?.NumOrden}</span></div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* FECHA DE GARANTIA */}
                <div className="col-9"><strong>{t("equipmentView.warrantyDate")}</strong></div>
                <div className="col-3"><span>{equipment && renderDate(equipment[0]?.FecGarantia, language)}</span></div>
                {/* GARANTIA PROVEEDOR */}
                <div className="col-9"><strong>{t("equipmentView.warrantySupplier")}</strong></div>
                <div className="col-3"><span>{equipment && renderDate(equipment[0]?.FecGarEmpresa, language)}</span></div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* FECHA DESPACHO */}
                <div className="col-9"><strong>{t("equipmentView.dateRelease")}</strong></div>
                <div className="col-3"><span>{equipment && renderDate(equipment[0]?.FecDespacho, language)}</span></div>
                {/* FECHA INSTALACION */}
                <div className="col-9"><strong>{t("equipmentView.installDate")}</strong></div>
                <div className="col-3"><span>{equipment && renderDate(equipment[0]?.FecInstalacion, language)}</span></div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* FECHA DESTINO POD */}
                <div className="col-9"><strong>{t("equipmentView.destinationDate")}</strong></div>
                <div className="col-3"><span>{equipment && renderDate(equipment[0]?.FecAmbiente, language)}</span></div>
                {/* FECHA DE EOGS */}
                <div className="col-9"><strong>{t("equipmentView.dateEOGS")}</strong></div>
                <div className="col-3"><span>{equipment && renderDate(equipment[0]?.FecEOL, language)}</span></div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex">
                {/* NRO. CONTRATO */}
                <div className="col-9"><strong>{t("equipmentView.contractNum")}</strong></div>
                <div className="col-3"><span style={{ textDecoration: 'underline' }}>{equipmentContract && equipmentContract}</span></div>
                {/* CARTA DE GARANTIA */}
                <div className="col-9"><strong>{t("equipmentView.warrantyLetter")}</strong></div>
                <div className="col-3"><span></span></div>
              </div>

              {/* FILA 13 */}
              <div className="row-flex">
                {/* DURACION */}
                <div className="col-9"><strong>{t("equipmentView.duration")}</strong></div>
                <div className="col-3"><span>{fecInicio && fecFin ? `${fecInicio && renderDate(fecInicio, language)} - ${fecFin && renderDate(fecFin, language)}` : ""}</span></div>
                {/* FRECUENCIA */}
                <div className="col-9"><strong>{t("equipmentView.frecuency")}</strong></div>
                <div className="col-3"><span>{frecuenciaNom && frecuenciaNom}</span></div>
              </div>

              {/* FILA 14 */}
              <div className="row-flex">
                {/* CONDICION GARANTIA */}
                <div className="col-9"><strong>{t("equipmentView.warrantyCondition")}</strong></div>
                <div><span>{equipment && equipment[0]?.ConGarantia}</span></div>
              </div>

              {/* FILA 15 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-9"><strong>{t("equipmentView.observation")}</strong></div>
                <div><span style={{ color: 'var(--danger-color)', textDecoration: 'underline' }}>{equipment && equipment[0]?.Observacion}</span></div>
              </div>

              {/* FILA 16 */}
              <div className="row-flex">
                {/* ACTA DE INSTALACION */}
                <div className="col-9">
                  <strong>{t("equipmentView.installCert")}</strong>
                </div>
                <div className="col-3"></div>
                {
                  equipment && equipment[0]?.Acta && (
                    <>
                      <div className="col-9">
                        <strong>Acta firmada</strong>
                      </div>
                      <div className="col-3">
                        <button type="button" className="btn-pdf" onClick={() => onHandleDownload(1, "ca.pdf")}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                            <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                            <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                          </svg>
                        </button>
                      </div>
                    </>
                  )
                }
              </div>

              {/* FILA 17 */}
              <div className="row-flex">
                {/* PACKING LIST */}
                <div className="col-9"><strong>Packing List</strong></div>
                <div className="col-3 row-flex gap-small">
                  {
                    equipment && equipment[0]?.Packing && (
                      <button type="button" className="btn-pdf" title="Packing" onClick={() => onHandleDownload(3, "Packing.pdf")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                          <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                        </svg>
                      </button>
                    )
                  }
                  {
                    equipment && equipment[0]?.Packing1 && (
                      <button type="button" className="btn-pdf" title="Packing 1" onClick={() => onHandleDownload(7, "Packing1.pdf")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                          <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                        </svg>
                      </button>
                    )
                  }
                  {
                    equipment && equipment[0]?.Packing2 && (
                      <button type="button" className="btn-pdf" title="Packing 2" onClick={() => onHandleDownload(8, "Packing2.pdf")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                          <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                        </svg>
                      </button>
                    )
                  }
                  {
                    equipment && equipment[0]?.Packing3 && (
                      <button type="button" className="btn-pdf" title="Packing 3" onClick={() => onHandleDownload(9, "Packing3.pdf")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                          <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                        </svg>
                      </button>
                    )
                  }
                </div>
                {/* POD */}
                <div className="col-9">
                  <strong>POD</strong>
                </div>
                {
                  equipment && equipment[0]?.POD && (
                    <div className="col-3">
                      <button type="button" className="btn-pdf" onClick={() => onHandleDownload(2, "POD.pdf")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                          <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                        </svg>
                      </button>
                    </div>
                  )
                }
              </div>

              {/* BOTONES DE PARA MODIFICAR EL EQUIPO */}
              <div className="row-flex m-y-small j-content-space-around m-y-medium">
                <button className="btn-primary" onClick={() => navigate(`/equipments/equipment-modify/${equipment && equipment[0]?.CodEquipo}`)}>{t("equipmentView.modify")}</button>
                <button className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* MANTENIMIENTOS CORRECTIVOS */}
              <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">{t("equipmentView.correctiveMaint")}</h3>
              {/* TABLA DE LOS MANTENIMIENTOS CORRECTIVOS */}
              {
                correctiveMaint && correctiveMaint.length > 0 ? (
                  <div className="table-container-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>ID</th>
                          <th>{t("equipmentView.order")}</th>
                          <th>{t("equipmentView.date")}</th>
                          <th>{t("equipmentView.step")}</th>
                          <th>{t("equipmentView.problem")}</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>{t("equipmentView.comment")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          correctiveMaint && correctiveMaint.map((mant) => (
                            <tr key={`${mant.CodServicio}-${mant.NumOrden}`}>
                              <td>
                                <Link className="link-tag" to={`/service/service-call/${mant.CodServicio}`}>{mant.CodServicio}</Link>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {mant.orders.length > 0 ? mant.orders.map((order, index) => (<><Link key={`${index}-${order.NumOrden}`} className="link-tag">{order.NumOrden}</Link> <br /></>)) : ""}
                              </td>
                              <td style={{ textAlign: 'center' }} className="col-10">{renderDate(mant.FecIngreso, language)}</td>
                              <td style={{ textAlign: 'center' }} className="col-10"><span className={mant.IdEtapa === 99 ? "completed" : ""}>{mant.etapa}</span></td>
                              <td style={{ textTransform: 'capitalize' }} className="col-5">{mant.Problema}</td>
                              <td style={{ textTransform: 'capitalize' }}>{mant.lastComment}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{language === "en" ? "This Equipment does not still have corrective maintenance." : "Este equipo aún no tiene mantenimientos correctivos."} &#128064;</strong></div>)
              }

              {/* MANTENIMIENTOS PREVENTIVOS */}
              <h3 className="m-y-small" style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>Mantenimientos Preventivos</h3>
              {/* TABLA PARA LOS MANTENIMIENTOS PREVENTIVOS */}
              {
                preventMaint && preventMaint.length > 0 ? (
                  <div className="container-table-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>ID</th>
                          <th>{t("equipmentView.order")}</th>
                          <th>{t("equipmentView.date")}</th>
                          <th>{t("equipmentView.step")}</th>
                          <th>{t("equipmentView.problem")}</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>{t("equipmentView.comment")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          preventMaint && preventMaint.map((prevent) => (
                            <tr key={prevent.idOrder}>
                              <td>
                                <Link className="link-tag" to={`/service/service-call/${prevent.CodServicio}`}>{prevent.CodServicio}</Link>
                              </td>
                              <td>
                                {prevent.orders.length > 0 ? prevent.orders.map((orderPrevent) => (<><Link key={orderPrevent.NumOrden} className="link-tag">{orderPrevent.NumOrden}</Link> <br /></>)) : ""}
                              </td>
                              <td style={{ textAlign: 'center' }} className="col-10">{renderDate(prevent.FecIngreso, language)}</td>
                              <td style={{ textAlign: 'center' }} className="col-10"><span className={prevent.IdEtapa === 99 ? "completed" : ""}>{prevent.etapa}</span></td>
                              <td style={{ textTransform: 'capitalize' }} className="col-5">{prevent.Problema}</td>
                              <td style={{ textTransform: 'capitalize' }}>{prevent.comment}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{language === "en" ? "This Equipment does not still have Preventive Maintenance." : "Este equipo aún no tiene mantenimientos preventivos."} &#128064;</strong></div>)
              }

              {/* FMI */}
              <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>FMI</h3>
              {/* TABLA DE LOS FMI */}
              {
                FMI && FMI.length > 0 ? (
                  <div className="container-table-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>ID</th>
                          <th>{t("equipmentView.order")}</th>
                          <th>{t("equipmentView.date")}</th>
                          <th>{t("equipmentView.step")}</th>
                          <th>{t("equipmentView.problem")}</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>{t("equipmentView.comment")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          FMI && FMI.map((items) => (
                            <tr key={items.idOrder}>
                              <td>
                                <Link className="link-tag" to={`/service/service-call/${items.CodServicio}`}>{items.CodServicio}</Link>
                              </td>
                              <td>
                                {items.orders.length > 0 ? items.orders.map((orderFMI) => (<><Link key={orderFMI.NumOrden} className="link-tag">{orderFMI.NumOrden}</Link><br /></>)) : ""}
                              </td>
                              <td style={{ textAlign: 'center' }} className="col-10">{renderDate(items.FecIngreso, language)}</td>
                              <td style={{ textAlign: 'center' }} className="col-10"><span className={items.IdEtapa === 99 ? "completed" : ""}>{items.etapa}</span></td>
                              <td style={{ textTransform: 'capitalize' }} className="col-5">{items.Problema}</td>
                              <td style={{ textTransform: 'capitalize' }}>{items.comment}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{language === "en" ? "This Equipment does not still have FMI." : "Este equipo aún no tiene FMI."} &#128064;</strong></div>)
              }

              {/* ENTRADAS AL ALMACEN */}
              <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>Entradas al Taller</h3>
              {/* TABLA DE LAS ENTRADAS A ALMACEN */}
              {
                entriesWorkshop && entriesWorkshop.length > 0 ? (
                  <div className="container-table-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>ID</th>
                          <th>{t("equipmentView.order")}</th>
                          <th>{t("equipmentView.date")}</th>
                          <th>{t("equipmentView.step")}</th>
                          <th>{t("equipmentView.problem")}</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>{t("equipmentView.comment")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          entriesWorkshop && entriesWorkshop.map((entry) => (
                            <tr key={entry.idOrder}>
                              <td>
                                <Link className="link-tag" to={`/service/service-call/${entry.CodServicio}`}>{entry.CodServicio}</Link>
                              </td>
                              <td>
                                {entry.orders.length > 0 ? entry.orders.map((orderEntry) => (<><Link key={orderEntry.NumOrden} className="link-tag">{orderEntry.NumOrden}</Link><br /></>)) : ""}
                              </td>
                              <td style={{ textAlign: 'center' }} className="col-10">{renderDate(entry.FecIngreso, language)}</td>
                              <td style={{ textAlign: 'center' }} className="col-10"><span className={entry.IdEtapa === 99 ? "completed" : ""}>{entry.etapa}</span></td>
                              <td style={{ textTransform: 'capitalize' }} className="col-5">{entry.Problema}</td>
                              <td style={{ textTransform: 'capitalize' }}>{entry.comment}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{language === "en" ? "This Equipment does not still have Entries to Workshop." : "Este equipo aún no tiene entradas al taller."} &#128064;</strong></div>)
              }

              {/* SITE PLANNING / INSTALLATION / APPLICATION */}
              <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>Site Planning / Installation / Application</h3>
              {/* TABLA PARA MOSTRAR SITE PLANNING / INSTALLATION / APPLICATION */}
              {
                sitePlanning && sitePlanning.length > 0 ? (
                  <div className="container-table-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>ID</th>
                          <th>{t("equipmentView.order")}</th>
                          <th>{t("equipmentView.date")}</th>
                          <th>{t("equipmentView.step")}</th>
                          <th>{t("equipmentView.problem")}</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>{t("equipmentView.comment")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          sitePlanning && sitePlanning.map((site) => (
                            <tr key={site.idOrder}>
                              <td><Link className="link-tag" to={`/service/service-call/${site.CodServicio}`}>{site.CodServicio}</Link></td>
                              <td style={{ textAlign: 'center' }} className="col-10"></td>
                              <td style={{ textAlign: 'center' }} className="col-10"></td>
                              <td><span className={site.IdEtapa === 99 ? "completed" : ""}>{site.etapa}</span></td>
                              <td style={{ textTransform: 'capitalize' }} className="col-5">{site.Problema}</td>
                              <td style={{ textTransform: 'capitalize' }}>{site.comment}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{language === "en" ? "This Equipment does not still have these services." : "Este equipo aún no tiene estos servicios."} &#128064;</strong></div>)
              }

              {/* REPUESTOS */}
              <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Repuestos</h3>
              {/* TABLA PARA MOSTRAR TODOS LOS REPUESTOS */}
              {
                parts && parts.length > 0 ? (
                  <div className="table-container-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>Solicitud</th>
                          <th>{t("equipmentView.order")}</th>
                          <th>{t("equipmentView.date")}</th>
                          <th>Nro. Parte</th>
                          <th>Descripción</th>
                          <th>{t("equipmentView.step")}</th>
                          <th>{t("equipmentView.comment")}</th>
                          <th>Cantidad</th>
                          <th>Existencia</th>
                          <th>Utilizado</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>Devuelto</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          parts && parts.map((part) => (
                            <tr key={`${part.idOrder}-${part.CodSolRepuesto}`}>
                              <td style={{ textAlign: 'center' }}><Link className="link-tag">{part.CodSolRepuesto}</Link></td>
                              <td style={{ textAlign: 'center' }}><Link className="link-tag">{part.NumOrden}</Link></td>
                              <td style={{ textAlign: 'center' }} className="col-10">{renderDate(part.FecIngreso, language)}</td>
                              <td style={{ textAlign: 'center' }}><Link className="link-tag">{part.NumParte}</Link></td>
                              <td>{part.Descripcion}</td>
                              <td><span className={part.IdEtapa === 99 ? "completed" : ""}>{part.etapa}</span></td>
                              <td style={{ textTransform: 'capitalize' }}>{part.comment}</td>
                              <td style={{ textAlign: 'center' }}>{part.Cantidad}</td>
                              <td style={{ textAlign: 'center' }}>{part.CanExistencia}</td>
                              <td style={{ textAlign: 'center' }}>{part.CanUsado}</td>
                              <td style={{ textAlign: 'center' }}>{part.Devuelto}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{language === "en" ? "This Equipment does not still have spare parts." : "Este equipo aún no tiene partes."} &#128064;</strong></div>)
              }
            </div>
          </>
        )
      }
    </>
  );
};