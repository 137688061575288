import React, { useEffect, useState } from "react";

// REACT ROUTER IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { PartDetailModify } from "../../components/logistic-components/PartDetailModify";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { Error403View } from "../error-403-view/Error403View";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate, renderIdTipSolicitud, renderTemperature } from "../../global-functions/globalFunctions";

// React translation
import { useTranslation } from "react-i18next";



export const OrderDetailView = () => {

  // navigation location
  const location = useLocation();

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // React translation
  const [t] = useTranslation("global");

  const { id } = useParams();

  // GET useNavigate()
  const navigate = useNavigate();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [stateSolRep, setStateSolRep] = useState([]); // Estado para cargar la solicitud y otros datos que vienen dentro de ella
  const [partes, setPartes] = useState([]); // Estado para cargar las partes
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false, loadingData: false, questionAction: "", btnSepa: false });
  const [stateForm, setStateForm] = useState({ dynamic: "1", almacen: "", tipAprobacion: "", empresa: localStorage.getItem("codEmpresa") ?? "", via: "", codSuplidor: "", nomSuplidor: "", contactSuppl: "", shipTo: "", contactEmpresa: "", aprobadoPor: "", incoterm: "", observa: "", comment: "", codZona: 0, facturarA: "", primaryAction: "" });
  const [openFilter, setOpenFilter] = useState(false);
  const [contactSu, setContactSu] = useState([]); // para cargar los contactos del suplidor
  const [contactEmpr, setContactEmpr] = useState([]); // para cargar los contactos de la empresa
  const [statePartModify, setStatePartModify] = useState({ showModal: false, codSolRep: 0, codDetSolRep: 0 });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false, redirect: false });
  const [reload, setReload] = useState(false);

  // DESTRUCTURING DATA FROM THE SERVER
  const { solRepuesto, detSolRep, contacSuppli, supplier, empEnvia, contactEmpEnvia, employeesPermission } = dataServer && dataServer;


  // Empresa 
  const EMPRESA = localStorage.getItem("codEmpresa") ?? "GLOBL";
  const ORDEN_COMPRA = 20; // Etapa de orden de compra

  let totalOrden = 0; // esta variable para tener el total de la orden de compra

  // Buscar el suplidor
  // Buscar el suplidor que se está escribiendo en el input
  const supplierFoundRes = supplier && supplier.filter((sup) => sup.Nombre?.toLowerCase().includes(stateForm?.nomSuplidor.toLowerCase()));

  // IdTipAprobaciones que incluyen aprobacion zse
  const APROBACIONES = ["2", "6", "7"];

  // console.log(supplierFoundRes)


  // funcion para descargar la aprobacion del soporte
  const onDownloadSoporteDoc = async (print = "", action = "") => {
    try {
      const response = await fetch(`${urls.orderDetail}?language=${language}&codSolRep=${id}&action=${action}&print=${print}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear enlance oculto
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = blobURL;
        a.download = action === "1" ? `Zone support approval ${id}` : `Purchase order`;

        document.body.appendChild(a);

        // simular click en el enlace para iniciar la descargar
        a.click();

        setNotification((val) => ({ ...val, show: true, msg: t("General_Comp.descarga_doc"), great: true }));

        // limpiar la url y el enlace de la descarga
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);
      }

    } catch (error) {
      console.log(error);
    }
  };

  // Manejador del submit para enviar el formulario
  const onSubmitCreateOrd = async (event) => {
    event.preventDefault();

    const finalObj = { stateForm, partes };
    setState((val) => ({ ...val, loadingData: true }));
    try {
      const response = await fetch(`${urls.orderDetail}/${id}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 201) { // Cuando la orden se crea por primera vez
        setState((val) => ({ ...val, showModal: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: false, great: true, success: true }));
      } else if (response.status === 200) { // Cuando se actualiza informacion
        setState((val) => ({ ...val, showModal: false }));
        setStateForm((val) => ({ ...val, comment: "" }));
        setNotification({ show: true, msg: data?.msg, error: false, great: true, success: true, redirect: data?.redirect });
      }

      setState((val) => ({ ...val, loadingData: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // Funcion para realizar el reload cuando se reciba del partDetailModify
  const onRealodFromChildComp = (val) => {
    setReload(val);
  };


  // Funcion para abrir el modal de modificar las partes
  const openModalModifyPart = (codSolRep, codDetSolRep) => {
    setStatePartModify({ codSolRep: codSolRep, codDetSolRep: codDetSolRep, showModal: true });
  };


  // Funcion para cerrar el modal de modificar las partes
  const onCloseModal = () => {
    setStatePartModify((val) => ({ ...val, showModal: false }));
    onRealodFromChildComp(false);
  };


  // funcion para abrir el modal para separar un item de la orden
  const onOpenModalSeparteItem = () => {
    setState((val) => ({ ...val, showModal: true, questionAction: t("LogisticViewComp.separar_item_pregunta") }));

    setStateForm((val) => ({ ...val, primaryAction: "5" }));
  };


  // Funcion para modificar el exportar de las partes
  const onHandleChangeExportar = (newVal = "", codDet = 0) => {
    setPartes((val) => {
      return val.map((part) => {
        if (codDet === part.CodDetSolRepuesto) {
          return { ...part, Exportar: newVal }
        }
        return part;
      });
    });
  };


  // funcion para modificar el separar de las partes
  const onHandleChangeSeparate = (newVal = false, codDet = 0) => {
    setPartes((val) => {
      return val.map((part) => {
        if (codDet === part.CodDetSolRepuesto) {
          return { ...part, Separar: newVal }
        }
        return part;
      });
    });
  };


  // Funcion para abrir el modal al dar click en guardar
  const onHandleSaveOrderModal = () => {
    if (stateForm.dynamic === "") {
      return setNotification({ show: true, msg: t("LogisticViewComp.selec_va_dyn"), error: true, great: false, success: false, redirect: false });
    }

    // Verificar que el almacen esté seleccionado
    if (stateForm.almacen === "") {
      return setNotification({ show: true, msg: t("LogisticViewComp.selec_almacen"), error: true, great: false, success: false, redirect: false });
    }

    // Verificar que la via este seleccionada
    if (stateForm.via === "") {
      return setNotification({ show: true, msg: t("LogisticViewComp.selec_via"), error: true, great: false, success: false, redirect: false });
    }

    // Verificar que la empresa este seleccionada
    if (stateForm.empresa === "") {
      return setNotification({ show: true, msg: t("LogisticViewComp.selec_empresa"), error: true, great: false, success: false, redirect: false });
    }

    // Verificar que esté seleccionado el suplidor
    if (stateForm.codSuplidor === "") {
      return setNotification({ show: true, msg: t("LogisticViewComp.selec_supplidor"), error: true, great: false, success: false, redirect: false });
    }

    // Verificar que esté seleccionado el incoterm
    if (stateForm.incoterm === "") {
      return setNotification({ show: true, msg: t("LogisticViewComp.selec_incoterm"), error: true, great: false, success: false, redirect: false });
    }

    // Verificar que se seleccione el tipo de aprobacion
    if (stateForm.tipAprobacion === "") {
      return setNotification({ show: true, msg: t("LogisticViewComp.seleccione_tip_apro"), error: true, great: false, success: false, redirect: false });
    }

    setStateForm((val) => ({ ...val, primaryAction: "1" }));
    let FinalMsg = "";

    if (stateSolRep[0]?.NumOrden === null) {
      FinalMsg = t("LogisticViewComp.seguro_crear_orden_comp");

    } else {
      FinalMsg = t("LogisticViewComp.seguro_guar_cambios");
    }

    setState((val) => ({ ...val, showModal: true, questionAction: FinalMsg }));

  };


  // Funcion para abrir el modal al dar click en dev soporte
  const onHandleDevSoporteModal = () => {
    setStateForm((val) => ({ ...val, primaryAction: "2" }));

    setState((val) => ({ ...val, showModal: true, questionAction: t("LogisticViewComp.devolver_apro_soporte") }));
  };


  // funcion para abrir el modal al dar click en dev administrativa
  const onHandleDevAdminModal = () => {
    setStateForm((val) => ({ ...val, primaryAction: "3" }));

    setState((val) => ({ ...val, showModal: true, questionAction: t("RequestPartsView.seguro_enviar_apro_admi") }));
  };


  // Funcion para abrir el modal al dar click en enviar
  const onHandleSendModal = () => {
    for (const part of partes) {
      if (Number(stateForm.dynamic) === 1) {
        if (!part.TipArticulo) {
          return setNotification({ show: true, msg: t("LogisticViewComp.verificar_articulos"), error: true, great: false, success: false, redirect: false });
        }
      }
    }

    if (stateForm.almacen === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("LogisticViewComp.selec_almacen"), error: true }));
    }

    if (stateForm.incoterm === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("LogisticViewComp.selec_incoterm"), error: true }));
    }

    if (stateForm.via === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("LogisticViewComp.selec_via"), error: true }));
    }

    if (stateForm.empresa === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("LogisticViewComp.selec_empresa"), error: true }));
    }

    let FinalMsg = "";

    if (Number(stateForm.dynamic) === 1) {
      FinalMsg = t("LogisticViewComp.seguro_envi_sist_admi");
    } else {
      FinalMsg = t("LogisticViewComp.seguro_envi_siguiente_etapa");
    }

    setStateForm((val) => ({ ...val, primaryAction: "4" }));

    setState((val) => ({ ...val, showModal: true, questionAction: FinalMsg }));
  };


  // Funcion para traer los datos del servidor
  const fetchDataToServer = async () => {
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.orderDetail}/${id}/search?language=${language}&empresa=${EMPRESA}`, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        if (data?.msg) {
          setNotification({ show: true, msg: data?.msg, error: false, great: true, success: false, redirect: data?.redirect });
        }
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) { // si el usuario no esta logeado o venció su token
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 500) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 503) {
        setNotification((val) => ({ ...val, show: true, msg: t("General_Comp.servidor_no_dispo"), error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // REQUEST DATA TO THE SERVER
  useEffect(() => {

    fetchDataToServer();

  }, [navigate, id, language]);


  // useEffect para recargar la data cuando se obtenga respuesta satisfactoria del servidor
  useEffect(() => {
    if (notification.success) {
      fetchDataToServer();
    }
  }, [notification.success]);


  // useEffect para recargar la data del servidor cuando se guarde la informacion en part detail
  useEffect(() => {
    if (reload) {
      fetchDataToServer();
    }
  }, [reload]);


  // useEffect para redireccionar a logistica principal si el redirect es true del backend
  useEffect(() => {
    if (notification.redirect) {
      const timer = setTimeout(() => {
        navigate("/logistic-principal"); // redirecciona a principal de logistica en 3 seg
      }, 4500);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.redirect]);


  // useEffect para cargar la data en el formulario
  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0 && solRepuesto[0]?.NumOrden !== null) {
      setStateForm((val) => ({
        ...val, dynamic: solRepuesto[0]?.EsOrdenLS ?? "", almacen: solRepuesto[0]?.CodDepartamento ?? "", tipAprobacion: solRepuesto[0]?.IdTipAprobacion.toString() ?? "", empresa: solRepuesto[0]?.CodEmpresa, via: solRepuesto[0]?.IdVia ?? "", codSuplidor: solRepuesto[0]?.CodProveedor,
        contactSuppl: solRepuesto[0]?.CodConProveedor ?? "", shipTo: solRepuesto[0]?.CodEmpEnvia.toString() ?? "", contactEmpresa: solRepuesto[0]?.CodConEmpEnvia.toString() ?? "", aprobadoPor: solRepuesto[0]?.Aprobado, incoterm: solRepuesto[0]?.Incoterm, observa: solRepuesto[0]?.ObsOrden,
        codZona: solRepuesto[0]?.codZona, facturarA: solRepuesto[0]?.FacturarA ?? "", primaryAction: ""
      }));
    }
  }, [solRepuesto]);

  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0 && solRepuesto[0]?.NumOrden === null) {
      setStateForm((val) => ({ ...val, tipAprobacion: solRepuesto[0]?.IdTipAprobacion?.toString() ?? "", codZona: solRepuesto && solRepuesto[0]?.codZona }));
    }
  }, [solRepuesto]);

  // Buscar el suplidor y colocarlo en el input
  useEffect(() => {
    if (supplier && supplier.length > 0 && stateForm.codSuplidor !== "") {

      const supplierChange = supplier && supplier.find((sup) => sup.CodProveedor.toString() === stateForm.codSuplidor);

      setStateForm((val) => ({ ...val, nomSuplidor: supplierChange?.Nombre ?? "" }));

      setOpenFilter(false);
    }
  }, [stateForm.codSuplidor]);


  // console.log(supplier)

  // useEffect para filtrar el contacto
  useEffect(() => {
    if (contacSuppli && contacSuppli.length > 0) {

      // Buscar el contacto de ese suplidor
      const contact = contacSuppli && contacSuppli.filter((cont) => cont.CodProveedor.toString() === stateForm.codSuplidor);
      setContactSu(contact);

    }
  }, [stateForm.codSuplidor]);


  // useEffect para el contacto de la empresa que envia
  useEffect(() => {
    if (contactEmpEnvia && contactEmpEnvia.length > 0) {

      // Buscar los contactos de esa empresa
      const contact = contactEmpEnvia && contactEmpEnvia.filter((cont) => cont.CodEmpEnvia.toString() === stateForm.shipTo);

      setContactEmpr(contact);
    }
  }, [stateForm.shipTo]);

  // console.log(typeof stateForm.codSuplidor)


  // useEffect para cargar la solicitud de repuesto en el estado
  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0) {
      setStateSolRep(solRepuesto);
    }
  }, [solRepuesto]);


  // useEffect para cargar las partes en el estado
  useEffect(() => {
    if (detSolRep && detSolRep.length > 0) {
      setPartes(detSolRep);
    }
  }, [detSolRep]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false, redirect: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useEffect para que aparezca el boton para separar los items de una orden
  useEffect(() => {
    const separarItems = partes.filter((filt) => filt.Separar);

    if (separarItems.length > 0) {
      setState((val) => ({ ...val, btnSepa: true }));
    } else {
      setState((val) => ({ ...val, btnSepa: false }));
    }
  }, [partes]);


  return (
    <>
      {
        // error 403
        state.unauthorized && (<Error403View />)
      }
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {/* Components loading */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (

            <form onSubmit={onSubmitCreateOrd}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* DYNAMICS */}
                <div className="col-8 col-sm-30">
                  <strong>Dynamics</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <select
                    name="dynamic"
                    value={stateForm.dynamic}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, dynamic: target.value }))}>
                    <option value=""></option>
                    <option value="1">{t("RequestPartsView.si")}</option>
                    <option value="0">No</option>
                  </select>
                </div>
                {/* NUMERO ORDEN DYNAMICS */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.orden_dyn")}</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <span></span>
                </div>
                {/* SELECCIONAR EL ALMACEN */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.alma")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    name="warehouse"
                    value={stateForm.almacen}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, almacen: target.value }))}>
                    <option value=""></option>
                    {
                      stateSolRep[0]?.departamentos.map((dep) => (
                        <option key={dep.CodDepartamento} value={dep.CodDepartamento}>{dep.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* ORDEN DE COMPRA # */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.orden_compra")}</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <h5 style={{ color: 'var(--first-color)' }}>{stateSolRep[0]?.NumOrden}</h5>
                </div>
                {/* SOLICITUD */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.soli")}</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <h5>
                    <Link className="link-tag" to={`/request-part-detail/${id}`}>{id}</Link>
                  </h5>
                </div>
                {/* CLIENTE */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.cliente")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <Link className="link-tag" to={`/customers/customer-detail/${stateSolRep[0]?.CodCliente}`}>{stateSolRep[0]?.NomCliente}</Link>
                </div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* FECHA/HORA */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.fecha")}</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <span>{renderDate(stateSolRep[0]?.FecIngreso, language)}</span>
                </div>
                {/* ID SERVICIO */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.id_serv")}</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <h5>
                    <Link className="link-tag" to={`/service/service-call/${stateSolRep[0]?.CodServicio}`}>{stateSolRep[0]?.CodServicio}</Link>
                  </h5>
                </div>
                {/* EQUIPO */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.equip")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod text-hidden-over">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${stateSolRep[0]?.CodEquipo}`}>{stateSolRep[0]?.equipment}</Link>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* TIPO DE APROBACION */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.tip_aproba")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <select
                    name="typeApproval"
                    value={stateForm.tipAprobacion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipAprobacion: target.value }))}>
                    <option value=""></option>
                    <option value="0">Stock</option>
                    <option value="3">{t("RequestPartsView.designado")}</option>
                    <option value="6">{t("RequestPartsView.garan_ventas")}</option>
                    <option value="9">{t("RequestPartsView.export")}</option>
                    <option value="1">{t("RequestPartsView.activo_fij")}</option>
                    <option value="4">FMI/STB</option>
                    <option value="7">{t("RequestPartsView.garan_sopor")}</option>
                    <option value="10">{t("RequestPartsView.compr_local")}</option>
                    <option value="2">{t("RequestPartsView.contr")}</option>
                    <option value="5">Missing</option>
                    <option value="8">{t("RequestPartsView.gasto")}</option>
                    <option value="11">{t("RequestPartsView.garantiaFoiFoa")}</option>
                  </select>
                </div>
                {/* TIPO DE SOLICITUD */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.tip_sol")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <span>{renderIdTipSolicitud(stateSolRep[0]?.IdTipSolicitud, language)}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Aprobacion del soporte */}
                <div className="col-8 col-sm-30">
                  <strong>{t("LogisticViewComp.aproba_soporte")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  {/* Si no se ha cargado la aprobacion */}
                  {
                    !stateSolRep[0]?.Soporte && APROBACIONES.includes(stateForm.tipAprobacion) && (
                      <span className="required">{t("LogisticViewComp.aproba_soporte_no_cargada")}</span>
                    )
                  }
                  {/* Si ya se cargó la aprobacion */}
                  {
                    stateSolRep[0]?.Soporte && (
                      <button type="button" className="btn-pdf" onClick={() => onDownloadSoporteDoc("1", "1")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                          <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                        </svg>
                      </button>
                    )
                  }
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* ETAPA */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.eta")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <span style={{ color: 'var(--green-color', fontWeight: 'bold' }}>{stateSolRep[0]?.nomEtapa}</span>
                </div>
                {/* EMPRESA */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.empresa")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    name="company"
                    value={stateForm.empresa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                    <option value=""></option>
                    <option value="GLOBL">Global Medica</option>
                    <option value="GMDH">GMD Healthcare Ltd</option>
                  </select>
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* VIA */}
                <div className="col-8 col-sm-30">
                  <strong>Via</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <select
                    name="via"
                    value={stateForm.via}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, via: target.value }))}>
                    <option value=""></option>
                    <option value="0">{t("RequestPartsView.aerea")}</option>
                    <option value="1">{t("RequestPartsView.maritima")}</option>
                    <option value="2">{t("RequestPartsView.terrestre")}</option>
                  </select>
                </div>
                {/* SO */}
                <div className="col-9 col-sm-30">
                  <strong>SO / SOA</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <span></span>
                </div>
                {/* PRIORIDAD */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.prio")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <span className={renderTemperature(stateSolRep[0]?.Prioridad)}></span>
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* SUPLIDOR */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.supli")}</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <input
                    className="input-text-control"
                    type="text"
                    name="suppli"
                    onFocus={() => setOpenFilter(!openFilter)}
                    value={stateForm.nomSuplidor}
                    readOnly />

                  {/* Filtrador de los suplidores */}
                  {
                    openFilter && (
                      <div className="background-modal" onClick={() => setOpenFilter(false)}>
                        <div className="container-modal-supplier" onClick={(event) => event.stopPropagation()}>
                          <div className="row-flex m-y-small">
                            <input
                              className="input-text-control"
                              type="text"
                              name="searchSuppl"
                              value={stateForm.nomSuplidor}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, nomSuplidor: target.value }))}
                              autoComplete="off" />

                            <ul className="list-supplier">
                              {
                                supplierFoundRes && supplierFoundRes.map((sup) => (
                                  <li key={sup.CodProveedor} id={sup.CodProveedor} onClick={({ target }) => setStateForm((val) => ({ ...val, codSuplidor: target.id }))}>{sup.Nombre}</li>
                                ))
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
                {/* CONTACTO */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.contact")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    name="contact"
                    className={stateForm.codSuplidor === "" ? "wrapper" : ""}
                    disabled={stateForm.codSuplidor === ""}
                    value={stateForm.contactSuppl}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, contactSuppl: target.value }))}>
                    <option value=""></option>
                    {
                      contactSu && contactSu.map((cont) => (
                        <option key={cont.CodConProveedor} value={cont.CodConProveedor}>{cont.Nombre} {cont.Apellido}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* SHIP TO */}
                <div className="col-8 col-sm-30">
                  <strong>Ship To</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <select
                    name="shipTo"
                    value={stateForm.shipTo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, shipTo: target.value }))}>
                    <option value=""></option>
                    {
                      empEnvia && empEnvia.map((emp) => (
                        <option key={emp.CodEmpEnvia} value={emp.CodEmpEnvia}>{emp.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* CONTACTO EMPRESA */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.contact")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    name="contactEmpr"
                    className={stateForm.shipTo === "" ? "wrapper" : ""}
                    disabled={stateForm.shipTo === ""}
                    value={stateForm.contactEmpresa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, contactEmpresa: target.value }))}>
                    <option value=""></option>
                    {
                      contactEmpr && contactEmpr.map((cont) => (
                        <option key={cont.CodConEmpEnvia} value={cont.CodConEmpEnvia}>{cont.NomContacto}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* APROBADO POR */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.apro_por")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <input
                    className="input-text-control"
                    type="text"
                    name="approvedBy"
                    value={stateForm.aprobadoPor}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, aprobadoPor: target.value }))} />
                </div>
                {/* INCOTERM */}
                <div className="col-9 col-sm-30">
                  <strong>Incoterm</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    name="incoterm"
                    value={stateForm.incoterm}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, incoterm: target.value }))}>
                    <option value=""></option>
                    <option value="FOB">FOB</option>
                    <option value="EXW">EXW</option>
                    <option value="CIF">CIF</option>
                    <option value="DDP">DDP</option>
                    <option value="DDU">DDU</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.facturar_a")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <input
                    className="input-text-control"
                    name="billTo"
                    type="text"
                    value={stateForm.facturarA}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, facturarA: target.value }))} />
                </div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* FALLA */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.falla")}</strong>
                </div>
                <div className="col-6 col-sm-70-mod">
                  <span style={{ textTransform: 'capitalize', color: 'var(--danger-color)' }}>{stateSolRep[0]?.Falla.toLowerCase()}</span>
                </div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.obser")}</strong>
                </div>
                <div className="col-87 col-sm-70-mod">
                  <textarea
                    className="textarea-description"
                    name="observation"
                    value={stateForm.observa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, observa: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex">
                {/* INSTRUCCIONES DE DESPACHO */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.instruc_despa")}</strong>
                </div>
                <div className="col-sm-70-mod">
                  <span style={{ color: 'red', fontWeight: 'bold' }}></span>
                </div>
              </div>

              {/* FILA 13 */}
              <div className="row-flex">
                {/* COMENTARIO */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.comentario")}</strong>
                </div>
                <div className="col-87 col-sm-70-mod">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={stateForm.comment}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                    autoComplete="off"
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              {/* TABLA DE LAS PARTES */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.exportar")}</th>
                      <th>WorkFlow</th>
                      <th>{t("TableParts.precio_unit")}</th>
                      <th>{t("TableParts.itbis")}</th>
                      <th>{t("TableParts.precio_total")}</th>
                      <th>{t("LogisticViewComp.separar")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes.map((part) => {

                        const total = part.Precio * part.Cantidad * (1 + part.Itbis / 100);

                        totalOrden += total;

                        return (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="txt-center">
                              <button type="button" className="btn-link-tag" onClick={() => openModalModifyPart(part.CodSolRepuesto, part.CodDetSolRepuesto)}>
                                {part.CodDetSolRepuesto}
                              </button>
                            </td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td><Link className={part.TipArticulo ? "color-part-add-dyn" : "link-required"} to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link></td>
                            <td>{part.Descripcion}</td>
                            <td>
                              <select
                                name={`export-${part.CodDetSolRepuesto}`}
                                value={part.Exportar}
                                onChange={({ target }) => onHandleChangeExportar(target.value, part.CodDetSolRepuesto)}>
                                <option value=""></option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                              </select>
                            </td>
                            <td className="txt-center">{part.WorkFlow}</td>
                            <td className="txt-right">
                              {formatCurrency(part.Precio, 'USD')}
                            </td>
                            <td className="txt-right">
                              {part.Itbis}
                            </td>
                            <td className="txt-right">
                              {formatCurrency(total, 'USD')}
                            </td>
                            <td className="txt-center">
                              <input
                                className="input-control-checkbox"
                                type="checkbox"
                                name={`separate-${part.CodDetSolRepuesto}`}
                                checked={part.Separar}
                                onChange={({ target }) => onHandleChangeSeparate(target.checked, part.CodDetSolRepuesto)} />
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr>
                      <td style={{ fontWeight: 'bold' }} className="txt-center">Total</td>
                      <td className="txt-right" colSpan={8} style={{ fontWeight: 'bold', color: 'var(--money-color)' }}>{formatCurrency(totalOrden, 'USD')}</td>
                      {
                        state.btnSepa && (
                          <td className="row-flex align-c justify-center fade-animation" onClick={onOpenModalSeparteItem}>
                            <button type="button" className="btn-delivery" title="Separar item de la orden.">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--first-color)" className="bi bi-send-arrow-up-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15.854.146a.5.5 0 0 1 .11.54L13.026 8.03A4.5 4.5 0 0 0 8 12.5c0 .5 0 1.5-.773.36l-1.59-2.498L.644 7.184l-.002-.001-.41-.261a.5.5 0 0 1 .083-.886l.452-.18.001-.001L15.314.035a.5.5 0 0 1 .54.111ZM6.637 10.07l7.494-7.494.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338Z" />
                                <path fillRule="evenodd" d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.354a.5.5 0 0 0-.722.016l-1.149 1.25a.5.5 0 1 0 .737.676l.28-.305V14a.5.5 0 0 0 1 0v-1.793l.396.397a.5.5 0 0 0 .708-.708l-1.25-1.25Z" />
                              </svg>
                            </button>
                          </td>
                        )
                      }
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Botones de accion */}
              <div className="row-flex j-content-space-around group-mobile m-y-medium">
                <button type="button" className="btn-warning" onClick={onHandleDevSoporteModal}>{t("PartModify.dev_soporte")}</button>
                <button type="button" className="btn-info" onClick={onHandleDevAdminModal}>{t("PartModify.dev_administr")}</button>
                <button type="button" className="btn-danger">{t("RequestPartsView.elimi")}</button>
                <button type="button" className="btn-primary" onClick={onHandleSaveOrderModal}>{t("RequestPartsView.guar")}</button>
                {
                  stateSolRep[0]?.NumOrden !== null && stateSolRep[0]?.IdEtapa === ORDEN_COMPRA && (<button type="button" className="btn-success" onClick={onHandleSendModal}>{t("RequestPartsView.enviar")}</button>)
                }
                <button type="button" className="btn-info" onClick={() => onDownloadSoporteDoc("1", "2")}>{t("Viaticos.imprimir")} (es)</button>
                <button type="button" className="btn-info" onClick={() => onDownloadSoporteDoc("2", "2")}>{t("Viaticos.imprimir")} (en)</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModal: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success" disabled={state.loadingData}>
                          {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                        </button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }

        {
          statePartModify.showModal && (
            <PartDetailModify
              solRepuesto={statePartModify.codSolRep}
              codDetSolRepuesto={statePartModify.codDetSolRep}
              onClose={onCloseModal}
              onReload={onRealodFromChildComp}
              employeePerm={employeesPermission} />
          )
        }

      </div>
    </>
  );
};