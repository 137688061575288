
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";



export const ProjectsView = () => {

  // Language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // use navigate
  const navigate = useNavigate();

  // use location
  const location = useLocation();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [stateZona, setStateZona] = useState([]); // Estado para cargar la zona cuando se filtre
  const [stateLinea, setStateLinea] = useState([]); // Estado para cargar la linea cuando se filtre
  const [stateModalidad, setStateModalidad] = useState([]); // Estado para cargar la modalidad cuando se filtre
  const [controller, setController] = useState(null); // Estado para cargar el controlador de abortar la peticion al servidor
  const [currentPage, setCurrentPage] = useState(1); // Para cambiar la paginacion
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false });
  const [stateForm, setStateForm] = useState({ empresa: "", zona: "", idNumber: "", cliente: "", solicitante: "", tipoServicio: "", division: "", linea: "", modalidad: "", modelo: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", great: false, error: false });

  // DESTRUCTURING DATA SERVER
  const { commercialProjects, zona, division, linea, modalidad, employeeSales, employeePermission } = dataServer && dataServer;


  // Funcion para abrir el filtro
  const openFilter = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // useEffect para traer la data
  useEffect(() => {

    const fetchDataServer = async () => {
      const abortController = new AbortController();

      setController(abortController);

      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.projectsCommercial}?language=${language}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&idServ=${stateForm.idNumber}&cliente=${stateForm.cliente}&solici=${stateForm.solicitante}&tipServi=${stateForm.tipoServicio}&division=${stateForm.division}&linea=${stateForm.linea}&modali=${stateForm.modalidad}&modelo=${stateForm.modelo}`, {
          headers: { "Authorization": getTheToken().token },
          signal: abortController.signal
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para filtrar la zona por la empresa
  useEffect(() => {
    if (zona && zona.length > 0) {
      const filterZona = zona && zona.filter((zon) => zon.CodEmpresa.toString() === stateForm.empresa);

      setStateZona(filterZona);
    }
  }, [stateForm.empresa]);

  // useEffect para filtrar la linea por la vision
  useEffect(() => {
    if (linea && linea.length > 0) {
      const filterLinea = linea && linea.filter((lin) => lin.CodDivision === stateForm.division);

      setStateLinea(filterLinea);
    }

    if (stateForm.division === "") {
      setStateForm((val) => ({ ...val, linea: "", modalidad: "", modelo: "" }));
    }
  }, [stateForm.division]);


  // useEffect para filtrar la modalidad por la linea
  useEffect(() => {
    if (modalidad && modalidad.length > 0) {
      const filterModalidad = modalidad && modalidad.filter((mod) => mod.CodLinea === stateForm.linea);

      setStateModalidad(filterModalidad);
    }
  }, [stateForm.linea]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", great: false, error: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);



  // Funcion para filtrar proyectos
  const onFilterProjects = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.projectsCommercial}?language=${language}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&idServ=${stateForm.idNumber}&cliente=${stateForm.cliente}&solici=${stateForm.solicitante}&tipServi=${stateForm.tipoServicio}&division=${stateForm.division}&linea=${stateForm.linea}&modali=${stateForm.modalidad}&modelo=${stateForm.modelo}`, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, showModal: false }));
        setDataServer(data);
      } else if (response.status === 404) {
        setNotification({ show: true, msg: data?.msg, great: false, error: true });
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {/* Toast component */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {/* Loading component */}
      {
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        <div className="table-container-mobile">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>#</th>
                <th>T</th>
                <th>Fecha</th>
                <th>Equipo</th>
                <th>Cliente</th>
                <th>Problema</th>
                <th>Responsable</th>
                <th>Etapa</th>
                <th>Servicio</th>
                <th>Fecha</th>
                <th>Accion</th>
                <th>D.</th>
                <th>D.</th>
                <th>D.</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                commercialProjects && commercialProjects.map((proj) => (
                  <tr key={proj.CodServicio}>
                    <td><Link className="link-tag" to={`/commercial/projects/projects-detail/${proj.CodServicio}`}>{proj.CodServicio}</Link></td>
                    <td style={{ textAlign: 'center' }}>{proj.TipContrato}</td>
                    <td style={{ textAlign: 'center' }} className="col-10">{renderDate(proj.FecIngreso, language)}</td>
                    <td style={{ textAlign: 'center' }}>{proj.NomTipEquipo}</td>
                    <td><Link className="link-tag" to={`/customers/customer-detail/${proj.CodCliente}`}>{proj.NomCliente}</Link></td>
                    <td style={{ textTransform: 'capitalize' }}>{proj.Problema}</td>
                    <td>{proj.NomEmpleado}</td>
                    <td>{proj.etapa}</td>
                    <td>{proj.tipServicio}</td>
                    <td>{renderDate(proj.FecModificacion, language)}</td>
                    <td style={{ textTransform: 'capitalize' }}>{proj.Accion}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <ButtonSearch onOpenFilter={openFilter} />

        {
          state.showModal && (
            <div className="background-modal" onClick={openFilter}>
              <div className="container-modal-filter-proyectos" onClick={(event) => event.stopPropagation()}>
                <form onSubmit={onFilterProjects}>
                  {/* FILA */}
                  <div className="row-flex">
                    {/* Empresa */}
                    <div className="col-4"><strong>Empresa</strong></div>
                    <div className="col-4">
                      <select
                        name="company"
                        value={stateForm.empresa}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                        <option value=""></option>
                        <option value="1">Global Medica</option>
                        <option value="2">GMD Healthcare Ltd</option>
                      </select>
                    </div>
                    {/* Zona */}
                    <div className="col-4"><strong>Zona</strong></div>
                    <div className="col-4">
                      <select
                        name="zona"
                        className={stateForm.empresa === "" ? "wrapper" : ""}
                        disabled={stateForm.empresa === ""}
                        value={stateForm.zona}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, zona: target.value }))}>
                        <option value=""></option>
                        {
                          stateZona && stateZona.map((zon) => (
                            <option key={zon.CodZona} value={zon.CodZona}>{zon.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* ID servicio */}
                    <div className="col-4"><strong>Id Servicio</strong></div>
                    <div className="col-4">
                      <input
                        className="input-text-control"
                        name="idService"
                        type="text"
                        value={stateForm.idNumber}
                        onChange={({ target }) => {
                          if (isNaN(target.value)) {
                            return;
                          }
                          setStateForm((val) => ({ ...val, idNumber: target.value }))
                        }}
                        autoComplete="off" />
                    </div>
                    {/* Cliente */}
                    <div className="col-4"><strong>Cliente</strong></div>
                    <div className="col-4">
                      <input
                        className="input-text-control"
                        name="cliente"
                        type="text"
                        value={stateForm.cliente}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, cliente: target.value }))} />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Solicitante */}
                    <div className="col-4"><strong>Solicitante</strong></div>
                    <div className="col-4">
                      <select
                        name="solicitante"
                        value={stateForm.solicitante}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, solicitante: target.value }))}>
                        <option value=""></option>
                        {
                          employeeSales && employeeSales.map((emp) => (
                            <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                          ))
                        }
                      </select>
                    </div>
                    {/* Tipo de servicio */}
                    <div className="col-4"><strong>Tipo de servicio</strong></div>
                    <div className="col-4">
                      <select
                        name="serviceType"
                        value={stateForm.tipoServicio}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, tipoServicio: target.value }))}>
                        <option value=""></option>
                        <option value="3">Instalación</option>
                        <option value="5">Aplicaciones</option>
                        <option value="9">Proyectos</option>
                        <option value="10">Pre instalación</option>
                      </select>
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Division */}
                    <div className="col-4"><strong>Division</strong></div>
                    <div className="col-4">
                      <select
                        name="division"
                        value={stateForm.division}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                        <option value=""></option>
                        {
                          division && division.map((divi) => (
                            <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                    {/* Linea */}
                    <div className="col-4"><strong>Linea</strong></div>
                    <div className="col-4">
                      <select
                        name="linea"
                        className={stateForm.division === "" ? "wrapper" : ""}
                        disabled={stateForm.division === ""}
                        value={stateForm.linea}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, linea: target.value }))}>
                        <option value=""></option>
                        {
                          stateLinea && stateLinea.map((lin) => (
                            <option key={lin.CodLinea} value={lin.CodLinea}>{lin.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Modalidad */}
                    <div className="col-4"><strong>Modalidad</strong></div>
                    <div className="col-4">
                      <select
                        name="modality"
                        className={stateForm.linea === "" ? "wrapper" : ""}
                        disabled={stateForm.linea === ""}
                        value={stateForm.modalidad}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, modalidad: target.value }))}>
                        <option value=""></option>
                        {
                          stateModalidad && stateModalidad.map((mod) => (
                            <option key={mod.CodModalidad} value={mod.CodModalidad}>{mod.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                    {/* Modelo */}
                    <div className="col-4"><strong>Modelo</strong></div>
                    <div className="col-4">
                      <input
                        className="input-text-control"
                        type="text"
                        name="model"
                        value={stateForm.modelo}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, modelo: target.value }))}
                        autoComplete="off" />
                    </div>
                  </div>

                  {/* Botones */}
                  <div className="row-flex m-y-medium j-content-space-around">
                    <button type="submit" className="btn-success">Buscar</button>
                    <button type="reset" className="btn-secondary" onClick={() => setState((val) => ({ ...val, showModal: false }))}>Cerrar</button>
                  </div>
                </form>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};