// vista para actualizar la que ya esta creada

import { useEffect, useState } from "react";


// components import
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { UploadDocsSolConsump } from "../../components/administration-compon/UploadDocsSolConsump";
import { Error403View } from "../error-403-view/Error403View";


// react router import
import { useLocation, useNavigate, useParams } from "react-router-dom";

// urls import
import { urls } from "../../api-urls/api-urls";

// global functions import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";


export const SolConsumoDetailView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");


  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codSol } = useParams();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data
  const [filtGeren, setFiltGeren] = useState([]); // estado para cargar la gerencia filtrada
  const [controller, setController] = useState(null); // estado para abortar la peticion al servidor
  const [stateForm, setStateForm] = useState({
    solic: "", nomEtapa: "", tarjeta: "", titu: "", division: "", gerencia: "", consuFin: "", numItiner: "", monto: "", moneda: "", fecReque: "",
    concept: "", comentario: "", idEtapa: 0, idGeren: "", idDivi: "", nomMoneda: "", idTarj: "", fecVen: "", documento: false, action: "", empSoli: 0,
    lider: 0, empresa: "", tipGasto: "", nomEmpresa: "", nomTipGasto: "", fecFinHospe: "", EmpAutoriza: null, montFinal: ""
  });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "", unauthorized: false, modalSave: false, loadingData: false, questionAction: "", modalDocs: false, modalConfirm: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false, codSol: 0 });
  const [errors, setErrors] = useState({ msg: "", error: false });
  const [reload, setReload] = useState(false);


  // destructuring data server
  const { solConsump, segSolConsump, cards, division, gerencia, empPerm, empresa } = dataServer && dataServer;



  // funcion para formatear los numeros
  const handleFormatCurrency = (newVal, type = 0) => {

    const val = newVal.replace(/[^\d.]/g, ''); // eliminar caracteres no numericos excepto el .

    const parts = val.split(".");
    // formatear la parte entera del numero con comas
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (type === 1) {
      setStateForm((val) => ({ ...val, monto: parts.join(".") }));
    } else {
      setStateForm((val) => ({ ...val, montFinal: parts.join(".") }));
    }

  };


  // funcion para abrir el modal al guardar
  const onHandleSaveModal = () => {

    if (stateForm.idEtapa > 1 && !stateForm.action.trim()) {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar la acción que quiere realizar.", error: true }));
    }

    if (stateForm.idEtapa === 2 && !stateForm.comentario.trim() && stateForm.action === "2") { // si esta en la etapa 2 y quieren devolverlo deben indicar un comentario
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar porque lo está devolviendo.", error: true }));
    }

    if (stateForm.idEtapa > 1 && !stateForm.comentario.trim() && stateForm.action === "3") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar el comentario que desea realizar.", error: true }));
    }

    if (stateForm.idEtapa === 3 && !stateForm.comentario && stateForm.action === "2") {
      return setErrors((val) => ({ ...val, error: true, msg: "Debe indicar las razones por las cuales no cargó la factura." }));
    }

    setErrors((val) => ({ ...val, error: false, msg: "" }));

    setState((val) => ({ ...val, modalSave: true, questionAction: "Está seguro que desea guardar los cambios?" }));

    if (stateForm.idEtapa === 1) {
      setStateForm((val) => ({ ...val, action: "1" }));
    }
  };


  // funcion para copiar la solicitud
  const onHandleCopyRequestModal = () => {
    setState((val) => ({ ...val, modalSave: true, questionAction: "Está seguro que desea copiar esta solicitud?" }));
    setStateForm((val) => ({ ...val, action: "10" }));
  };

  // funcion para recargar la data desde el hijo
  const onReloadDataFromSon = (val) => {
    setReload(val);
  };

  // funcion para abrir el modal al enviar
  const onHandleSendSupper = () => {
    setState((val) => ({ ...val, modalSave: true, questionAction: "Está seguro que desea enviar a su supervisor para aprobación?" }));
    setStateForm((val) => ({ ...val, action: "2" }));
  };

  // funcion para cerrar el modal de los documentos
  const onHandleCloseDocs = () => {
    setState((val) => ({ ...val, modalDocs: false }));
  };


  // funcion para manejar el formulario de submit
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.autorizaciones}/${codSol}/search?language=${language}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setState((val) => ({ ...val, modalSave: false }));
        setStateForm((val) => ({ ...val, action: "", comentario: "" }));
      } else if (response.status === 201) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
        navigate(`/administration/consumption-request-detail/${data?.codSol}`);
        setState((val) => ({ ...val, modalSave: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setState((val) => ({ ...val, modalSave: false }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setState((val) => ({ ...val, modalSave: false }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para descargar el documento
  const onHandleDownload = async (action = "") => {
    try {
      const response = await fetch(`${urls.autorizaciones}?language=${language}&action=${action}&codSol=${codSol}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      // const data = await
      if (response.status === 200) {

        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear un enlace oculto
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobURL;
        a.download = action === "9" ? "invoice.pdf" : "formulario-solicitud.pdf";
        document.body.appendChild(a);

        a.click();

        // limpiar la url
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);

        setNotification((val) => ({ ...val, show: true, msg: "Se descargó el documento.", great: true }));

      } else {
        const data = await response.json();

        if (response.status === 404) {
          setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        }

      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar la informacion al servidor 
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.autorizaciones}/${codSol}/search?language=${language}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffec para solicitar la data del servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, codSol]);


  // useEffect para actualizar la data
  useEffect(() => {
    if (notification.success) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [notification.success]);


  // useEffect para recargar la data desde el hijo
  useEffect(() => {
    if (reload) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
        setReload(false);
      };
    }
  }, [reload]);


  // useEffect para cargar los datos en el estado
  useEffect(() => {
    if (solConsump && solConsump.length > 0) {

      const idMoneda = {
        1: "RD$",
        2: "US$",
        3: "TT$"
      };

      const tipGast = {
        1: "Compra",
        2: "Hospedaje"
      };

      const FEC_REQUE = solConsump[0]?.IdEtapa === 1 ? solConsump[0]?.FecRequerida.slice(0, 10) : solConsump[0]?.FecRequerida

      const FecFinHospe = solConsump[0]?.FecFinHospedaje ? solConsump[0]?.FecFinHospedaje.slice(0, 10) : "";

      setStateForm((val) => ({
        ...val, solic: solConsump[0]?.CodSolicitud, nomEtapa: solConsump[0]?.NomEtapa, idEtapa: solConsump[0]?.IdEtapa, tarjeta: solConsump[0]?.NumTarjeta,
        titu: solConsump[0]?.NomTitular, nomMoneda: idMoneda[solConsump[0]?.Moneda] ?? "", idTarj: solConsump[0]?.IdTarjeta, consuFin: solConsump[0]?.ConsuFinal,
        numItiner: solConsump[0]?.NumItinerario, monto: solConsump[0]?.MontoConsumo, concept: solConsump[0]?.Concepto, fecReque: FEC_REQUE, gerencia: solConsump[0]?.NomGerencia,
        division: solConsump[0]?.NomGrupo, fecVen: solConsump[0]?.FecExp, documento: solConsump[0]?.Documento, idDivi: solConsump[0]?.CodDivisionEmpr.toString() ?? "",
        idGeren: solConsump[0]?.CodGerencia.toString() ?? "", empSoli: solConsump[0]?.CodEmpSolicita, moneda: solConsump[0]?.Moneda, lider: solConsump[0]?.CodLider,
        empresa: solConsump[0]?.CodEmpresa.toString() ?? "", tipGasto: solConsump[0]?.IdTipGasto.toString() ?? "", nomEmpresa: solConsump[0]?.NomEmpresa,
        nomTipGasto: tipGast[solConsump[0]?.IdTipGasto] ?? "", fecFinHospe: FecFinHospe, EmpAutoriza: solConsump[0]?.CodEmpAutoriza, montFinal: solConsump[0]?.MontoFinal ?? ""
      }));
    }
  }, [solConsump]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false, codSol: 0 });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para filtrar las gerences
  useEffect(() => {
    if (gerencia && gerencia.length > 0) {
      const gerencias = gerencia && gerencia.filter((ger) => ger.CodGrupo.toString() === stateForm.idDivi);

      setFiltGeren(gerencias);
    }
  }, [stateForm.idDivi]);


  // useEffect para abrir el modal
  useEffect(() => {
    if (empPerm && ([2, 3].includes(stateForm.idEtapa) && stateForm.EmpAutoriza !== null && (stateForm.lider === empPerm[0]?.CodEmpleado || [6, 7].includes(empPerm[0]?.CodPerfil)))) {
      setState((val) => ({ ...val, modalConfirm: true }));
    }
  }, [empPerm, stateForm.idEtapa]);


  // useEffect para redireccionar cuando se copie una solicitud
  // useEffect(() => { 
  //   if (notification.success && notification.codSol !== 0) {
  //     const timer = setTimeout(() => {
  //       navigate(`/administration`)
  //     }, 2500);
  //   }
  // }, [notification.success, notification.codSol]);






  return (
    <div className="container display-modal">
      {
        state.modalConfirm && (
          <div className="background-modal">
            <div className="container-modal-upload-docs gap-medium align-c justify-center" style={{ display: "flex", flexDirection: "column" }}>
              <h4 className="txt-center">Esta solicitud se encuentra aprobada.</h4>

              <div className="row-flex justify-center">
                <button type="button" className="btn-info" onClick={() => setState((val) => ({ ...val, modalConfirm: false }))}>OK!</button>
              </div>
            </div>
          </div>
        )
      }
      {
        // loading components
        state.isLoading && (<LoadingComp />)
      }
      {
        // error 403 component
        state.unauthorized && (<Error403View />)
      }
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        state.dataVisible && (
          <form onSubmit={onHandleSubmit}>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Solicitud</strong></div>
              <div className="col-3">
                <h4>{stateForm.solic}</h4>
              </div>
              <div className="col-7"><strong>Etapa</strong></div>
              <div className="col-3">
                <h5 className="active-class" style={{ textDecoration: "underline" }}>{stateForm.nomEtapa}</h5>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Tarjeta</strong></div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <select
                      name="tarje"
                      value={stateForm.idTarj}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, idTarj: target.value }))}>
                      <option value=""></option>
                      {
                        cards && cards.map((c) => (
                          <option key={c.CodId} value={c.CodId}>{c.NumTarjeta}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.tarjeta}</span>
                  )
                }
              </div>
              <div className="col-7"><strong>Titular</strong></div>
              <div className="col-3">
                <span>{stateForm.titu} / Fecha vencimiento {stateForm.fecVen}</span>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Empresa</strong>
              </div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <select
                      name="company"
                      value={stateForm.empresa}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                      <option value=""></option>
                      {
                        empresa && empresa.map((emp) => (
                          <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.nomEmpresa}</span>
                  )
                }
              </div>
              <div className="col-7">
                <strong>Tipo de gasto</strong>
              </div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <select
                      name="tipGasto"
                      value={stateForm.tipGasto}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, tipGasto: target.value }))}>
                      <option value=""></option>
                      <option value="1">Compra</option>
                      <option value="2">Hospedaje</option>
                    </select>
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.nomTipGasto}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>División empresa</strong></div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <select
                      name="group"
                      value={stateForm.idDivi}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, idDivi: target.value }))}>
                      <option value=""></option>
                      {
                        division && division.map((div) => (
                          <option key={div.CodGrupo} value={div.CodGrupo}>{div.NomGrupo}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.division}</span>
                  )
                }
              </div>
              <div className="col-7"><strong>Gerencia</strong></div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <select
                      name="geren"
                      value={stateForm.idGeren}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, idGeren: target.value }))}>
                      <option value=""></option>
                      {
                        filtGeren && filtGeren.map((ger) => (
                          <option key={ger.CodGerencia} value={ger.CodGerencia}>{ger.NomGerencia}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.gerencia}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Suplidor</strong></div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <input
                      className="input-text-control"
                      type="text"
                      name="consuFinal"
                      value={stateForm.consuFin}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, consuFin: target.value }))} />
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.consuFin}</span>
                  )
                }
              </div>
              {
                stateForm.tipGasto === "2" && (
                  <>
                    {
                      stateForm.idEtapa === 1 && (
                        <>
                          <div className="col-7">
                            <strong>Fin del Hospedaje</strong>
                          </div>
                          <div className="col-3">
                            <input
                              className="input-date-styles"
                              type="date"
                              name="fecFinHosp"
                              value={stateForm.fecFinHospe}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, fecFinHospe: target.value }))} />
                          </div>
                        </>
                      )
                    }
                    {
                      stateForm.idEtapa > 1 && stateForm.tipGasto === "2" && (
                        <>
                          <div className="col-7">
                            <strong>Fin del Hospedaje</strong>
                          </div>
                          <div className="col-3">
                            <span>{renderDate(stateForm.fecFinHospe, language)}</span>
                          </div>
                        </>
                      )
                    }
                  </>
                )
              }
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Número de itinerario</strong></div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <input
                      className="input-text-control"
                      type="text"
                      name="numItiner"
                      value={stateForm.numItiner}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, numItiner: target.value }))}
                      autoComplete="off" />
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.numItiner}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Monto</strong>
              </div>
              <div className="col-3">
                {
                  stateForm.idEtapa === 1 && (
                    <input
                      className="input-text-control"
                      type="text"
                      name="amount"
                      value={stateForm.monto}
                      onChange={({ target }) => handleFormatCurrency(target.value, 1)}
                      autoComplete="off" />
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span className="active-class">${stateForm.monto}</span>
                  )
                }
              </div>
              <div className="col-7">
                {
                  stateForm.idEtapa === 1 && (
                    <select
                      name="currency"
                      value={stateForm.moneda}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                      <option value="">Seleccionar la moneda</option>
                      <option value="1">RD$</option>
                      <option value="2">US$</option>
                      <option value="3">TT$</option>
                    </select>
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.nomMoneda}</span>
                  )
                }
              </div>
              <div className="col-7"><strong>Fecha requerida</strong></div>
              <div className="col-7">
                {
                  stateForm.idEtapa === 1 && (
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecReque"
                      value={stateForm.fecReque}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fecReque: target.value }))} />
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{renderDate(stateForm.fecReque, language)}</span>
                  )
                }
              </div>
            </div>

            {
              (stateForm.tipGasto === "2" && stateForm.idEtapa >= 3) && (
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Monto final</strong>
                  </div>
                  <div className="col-7">
                    <input
                      className="input-text-control"
                      type="text"
                      name="montFinal"
                      disabled={stateForm.idEtapa >= 4}
                      value={stateForm.montFinal}
                      onChange={({ target }) => handleFormatCurrency(target.value, 2)}
                      autoComplete="off" />
                  </div>
                </div>
              )
            }

            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Concepto</strong></div>
              <div className="col-80-94">
                {
                  stateForm.idEtapa === 1 && (
                    <textarea
                      className="textarea-description"
                      name="concept"
                      value={stateForm.concept}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, concept: target.value }))}
                      autoComplete="off" />
                  )
                }
                {
                  stateForm.idEtapa > 1 && (
                    <span>{stateForm.concept}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Cargar factura</strong>
              </div>
              <div className="col-10">
                <button type="button" className="btn-docs" onClick={() => setState((val) => ({ ...val, modalDocs: true }))}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                    <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                    <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </button>
              </div>
              {
                stateForm.documento && (
                  <div className="col-10">
                    <button type="button" className="btn-pdf" onClick={() => onHandleDownload("9")}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114m2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                        <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                      </svg>
                    </button>
                  </div>
                )
              }
              <div className="col-10"></div>
            </div>
            {/* fila */}
            {
              stateForm.idEtapa > 1 && (
                <div className="row-flex">
                  <div className="col-7"><strong>Acción</strong></div>
                  <div className="col-7">
                    <select
                      name="action"
                      value={stateForm.action}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                      <option value="">--Seleccionar acción--</option>
                      {
                        stateForm.idEtapa === 2 && (
                          <>
                            {
                              empPerm && (empPerm[0]?.CodEmpleado === stateForm.lider || [6, 7].includes(empPerm[0]?.CodPerfil)) && (
                                <>
                                  <option value="1">Aprobar</option>
                                  <option value="2">Devolver</option>
                                </>
                              )
                            }
                          </>
                        )
                      }
                      {
                        empPerm && ((empPerm[0]?.CodEmpleado === stateForm.empSoli || empPerm[0]?.CodPerfil === 6) && stateForm.idEtapa === 3) && (
                          <>
                            {
                              solConsump && solConsump[0]?.Documento && (
                                <option value="1">Factura cargada</option>
                              )
                            }
                            <option value="2">Factura no cargada</option>
                          </>
                        )
                      }
                      {
                        // etapa pendiente de cierre
                        stateForm.idEtapa === 4 && ((empPerm && empPerm[0]?.NivViatico >= 4 && empPerm[0]?.CodPerfil === 4) || empPerm[0]?.CodPerfil === 6) && (
                          <option value="1">Proceso culminado</option>
                        )
                      }
                      <option value="3">Comentario</option>
                    </select>
                  </div>
                </div>
              )
            }
            {
              stateForm.idEtapa === 3 && (
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Nota</strong>
                  </div>
                  <div className="col-80">
                    <strong className="required">Tiene que imprimir el formulario en físico para pasar a la siguiente etapa.</strong>
                  </div>
                </div>
              )
            }
            {/* fila */}
            <div className="row-flex m-y-small">
              <div className="col-7"><strong>Comentario</strong></div>
              <div className="col-80-94">
                <textarea
                  className="textarea-description"
                  name="concept"
                  value={stateForm.comentario}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                  autoComplete="off" />
                {
                  errors.error && (
                    <span className="required fade-animation">{errors.msg}</span>
                  )
                }
              </div>
            </div>
            {/* <hr className="m-y-small" /> */}

            <div className="row-flex j-content-space-around m-y-medium">
              <button type="button" className="btn-primary" onClick={onHandleSaveModal}>Guardar</button>
              {
                stateForm.idEtapa === 1 && (empPerm && (empPerm[0]?.CodEmpleado === stateForm.empSoli || empPerm[0]?.CodPerfil === 6)) && (
                  <button type="button" className="btn-success" onClick={onHandleSendSupper}>Enviar</button>
                )
              }
              {
                stateForm.idEtapa >= 3 && (
                  <>
                    <button type="button" className="btn-primary" onClick={() => onHandleDownload("10")}>Imprimir</button>
                  </>
                )
              }
              {
                stateForm.idEtapa >= 3 && (empPerm && (empPerm[0]?.CodEmpleado === stateForm.empSoli)) && (
                  <button type="button" className="btn-success" onClick={onHandleCopyRequestModal}>Copiar solicitud</button>
                )
              }
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
            </div>

            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>Fecha</th>
                    <th>Etapa</th>
                    <th>Empleado</th>
                    <th>Comentario</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    segSolConsump && segSolConsump.map((seg) => (
                      <tr key={seg.CodSegSolicitud}>
                        <td className="txt-center">{renderDate(seg.FecIngreso, language)}</td>
                        <td className="txt-center">{seg.NomEtapa}</td>
                        <td className="txt-center">{seg.NomEmpleado}</td>
                        <td style={{ color: seg.Valor !== "" ? seg.Valor : "black", fontWeight: seg.Valor !== "" ? "bold" : "" }}>{seg.Comentario}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            {
              state.modalSave && (
                <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalSave: false }))}>
                  <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                    {/* Question mark */}
                    <div className="row-flex m-y-small align-c justify-center">
                      <div className="question-mark-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                        </svg>
                      </div>
                    </div>
                    <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                      {state.questionAction}
                    </h5>
                    {/* Botones de accion */}
                    <div className="row-flex m-y-medium j-content-space-around">
                      <button type="submit" className="btn-success" disabled={state.loadingData}>
                        {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                      </button>
                      <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, modalSave: false }))}>No</button>
                    </div>
                  </div>
                </div>
              )
            }
          </form>
        )
      }

      {
        // modal para cargar los documentos
        state.modalDocs && (
          <UploadDocsSolConsump onClose={onHandleCloseDocs} lang={language} codSol={codSol} token={getTheToken().token} onHandleReload={onReloadDataFromSon} />
        )
      }
    </div>
  );
};