import React from "react";



export const CiudadDataView = () => {


  return (
    <>
      <div className="container display-data">
        <h1>Ciudad Data</h1>
      </div>
    </>
  );
};