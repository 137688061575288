// componente para ver los usuario que tienen tarjetas asociadas

import { useEffect, useState } from "react";


// components importation
import { ToastNotification } from "../toast-notification/ToastNotification";


// global functions
import { renderDate } from "../../global-functions/globalFunctions";


// urls importation
import { urls } from "../../api-urls/api-urls";

// react router import
import { useLocation, useNavigate } from "react-router-dom";



export const UsersComp = ({ onClose, lang, token }) => {

  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data que viene del servidor
  const [stateEmp, setStateEmp] = useState([]); // estado para cargar los usuarios que estan autorizados
  const [controller, setController] = useState(null); // estado para cargar la data y cancelarla
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });

  // destructuring data server
  const { tarjetasEmp } = dataServer && dataServer;


  // GET the useLocation()
  const location = useLocation();

  // GET the useNavigate
  const navigate = useNavigate();


  // funcion para solicitar la data al servidor
  const fetchData = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}&action=8`, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para manejar el envio de los datos al servidor
  const onHandleSubmit = async () => {
    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}`, {
        method: "PATCH",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateEmp)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para cambiar el valor de los usuarios
  const onHandleChangeVal = (newVal, id) => {
    setStateEmp((val) => {
      return val.map((newArr) => {
        if (newArr.CodId === id) {
          return { ...newArr, Activo: newVal };
        }
        return newArr;
      })
    });
  };


  // useEffect para cargar los usuarios
  useEffect(() => {
    if (tarjetasEmp && tarjetasEmp.length > 0) {
      setStateEmp(tarjetasEmp);
    }
  }, [tarjetasEmp]);



  // useEffect para realizar el fetch al servidor
  useEffect(() => {

    fetchData();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  return (
    <div className="background-modal">
      {
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="container-modal-users">
        <div className="modal-header">
          <h3>Usuarios autorizados</h3>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        {/* tabla para mostrar los usuarios */}
        <div className="modal-body">
          {
            stateEmp && stateEmp.length === 0 && (<strong>Al parecer no hay empleados autorizados</strong>)
          }
          {
            // renderizado de la tabla
            stateEmp && stateEmp.length > 0 && (
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Empleado autorizado</th>
                      <th>Autorizado en</th>
                      <th>Titular</th>
                      <th>Tarjeta</th>
                      <th>Autorizado por</th>
                      <th>Activo</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      stateEmp && stateEmp.map((emp) => (
                        <tr key={emp.CodId}>
                          <td>{emp.NomEmpAutorizado}</td>
                          <td>{renderDate(emp.FecIngreso)}</td>
                          <td>{emp.NomTitular}</td>
                          <td>{emp.NumTarjeta}</td>
                          <td>{emp.NomEmpIngreso}</td>
                          <td className="txt-center">
                            <input
                              className="input-control-checkbox"
                              type="checkbox"
                              name={`Activo-${emp.CodId}`}
                              checked={emp.Activo}
                              onChange={({ target }) => onHandleChangeVal(target.checked, emp.CodId)} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="button" className="btn-primary" onClick={onHandleSubmit}>Guardar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};