
// ESTA VISTA SE UTILIZA PARA CAMBIAR LAS ORDENES DE COMPRA CUANDO YA ESTAN EN SEGUIMIENTO DE ORDENES
import React, { useEffect, useState } from "react";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// COMPONENTS IMPORTATION
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions";

// React translation importation
import { useTranslation } from "react-i18next";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";


export const OrderTracker = () => {
  // language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // GEt the useLocation
  const location = useLocation();

  // GET the useParams
  const { orderNum, idEtapa } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // controlador para abortar la peticion al servidor
  const [stateForm, setStateForm] = useState({ action: "", carrier: "", comentario: "", guiaAerea: "", fechaDesp: "", fecEstim: "", SO: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, succes: false, etapa: 0 });
  const [partes, setPartes] = useState([]); // estado para cargar las partes y luego modificarlas en la etapa de nacionalizacion
  const [state, setState] = useState({ isLoading: false, saving: false });

  // DESTRUCTURING OBJECTS FROM SERVER
  const { partsOrder, shippingInstruc } = dataServer && dataServer;

  // console.log(partsOrder)

  // objeto para generar los nombres de las etapas
  const STEP_NAME = {
    "30": t("RequestPartsView.acu_recibo"),
    "31": t("RequestPartsView.guia_desp"),
    "36": t("RequestPartsView.fecha_desp"),
    "37": t("RequestPartsView.transito"),
    "38": t("RequestPartsView.nacionali")
  };

  // Funcion para cambiar la cantidad que llega a almacen
  const onHandleChangeCant = (codDet = 0, codSol = 0, newVal = "") => {

    if (isNaN(newVal)) {
      return;
    }

    if (Number(newVal) > 0) {
      setPartes((val) => {
        return val.map((part) => {
          if (codDet === part.CodDetSolRepuesto && codSol === part.CodSolRepuesto) {
            if (+newVal > part.Cantidad) {
              return part;
            }
            return { ...part, CantLlego: newVal };
          }
          return part;
        });
      });

    }

  };


  // fetch data to the server
  const fetchingDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.orderTrackingUpdate}/${orderNum}/${idEtapa}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "applicacion/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 400) {
        // estos parametros no son validos
      } else if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/"); // el usuario no esta autenticado o venció el token, tiene que iniciar sesion nuevamente
      } else if (response.status === 500) {
        // ocurrió un error en el navegador
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer los datos
  useEffect(() => {

    fetchingDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [orderNum, idEtapa]);


  // ON SUBMIT ORDER
  const onSubmitUpdateOrderTracking = async (event) => {
    event.preventDefault();

    if (stateForm.action.trim() === "") {
      return setNotification({ show: true, msg: t("RequestPartsView.selec_accion"), errorNoti: true, succes: false, etapa: 0 });
    }

    // Verificar que si la etapa es 36 y la acción es transito, la fecha no puede estar vacia
    if (stateForm.action.trim() === "1" && idEtapa === "36" && !stateForm.fechaDesp) {
      return setNotification({ show: true, msg: t("RequestPartsView.indicar_fec_desp"), errorNoti: true, succes: false, etapa: 0 });
    }

    if (stateForm.action.trim() === "1" && idEtapa === "36" && !stateForm.fecEstim) {
      return setNotification({ show: true, msg: t("RequestPartsView.indicar_fec_esti"), errorNoti: true, succes: false, etapa: 0 });
    }

    // Verificar que si la accion es 1 y la etapa es 30 el so no puede estar vacio
    if (idEtapa === "30" && stateForm.action.trim() === "1" && !stateForm.SO.trim()) {
      return setNotification({ show: true, msg: "Debe indicar cual es el SO", errorNoti: true, succes: false, etapa: 0 });
    }

    // Verificar que si la etapa es 30 y se quiere rechazar la parte debe tener una justificacion
    if (idEtapa === "30" && stateForm.action.trim() === "101" && stateForm.comentario.trim().length < 5) {
      return setNotification({ show: true, msg: "Debe indicar una justificacion para rechazar la parte", errorNoti: true, succes: false, etapa: 0 });
    }

    const END_POINT = `${urls.orderTrackingUpdate}/${orderNum}/${idEtapa}/search?language=${language}`;

    const finalObj = { stateForm, partes };

    try {
      setState((val) => ({ ...val, saving: true }));
      // enviar los datos al servidor para actualizar
      const response = await fetch(END_POINT, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setStateForm((val) => ({ ...val, action: "", carrier: "", comentario: "", guiaAerea: "", fechaDesp: "", fecEstim: "", SO: "" }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: false, succes: true, etapa: data?.etapa }));
      }

      setState((val) => ({ ...val, saving: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // efecto de redireccion
  useEffect(() => {
    if (notification.succes) {
      const timer = setTimeout(() => {
        if (notification.etapa === Number(idEtapa)) {
          // recargar la pagina
          fetchingDataServer();
        } else {

          if (notification.etapa > 38) { // Si la etapa que recibo del backend es mayor que nacionalizacion

            navigate("/logistic-principal");

          } else {

            navigate(`/order-tracking/${orderNum}/${notification.etapa}`);

          }
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
      }

    }
  }, [notification.succes]);


  // useEffect para cargar las partes para la etapa de Nacionalizacion
  useEffect(() => {
    if (partsOrder && partsOrder.length > 0) {
      setPartes(partsOrder);
    }
  }, [partsOrder]);



  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, succes: false, etapa: 0 });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);

  return (
    <>
      {
        state.isLoading && (<LoadingComp />)
      }
      {notification.show && (<ToastNotification success={notification.succes} error={notification.errorNoti} msg={notification.msg} />)}
      <div className="container display-data">

        <form onSubmit={onSubmitUpdateOrderTracking}>
          <div className="m-y-small">
            <p><strong>{t("RequestPartsView.eta_actual")}:</strong><strong className="active-class"> {STEP_NAME[idEtapa] ?? "N/A"}</strong></p>
          </div>
          <div className="card-shadow">
            {/* FILA 1 */}
            <div className="row-flex">
              {/*  */}
              <div className="col-7 col-sm-30">
                <strong>{t("RequestPartsView.accion")}</strong>
              </div>
              <div className="col-4 col-sm-70-mod">
                <select
                  name="GuiaAereaEtapa"
                  value={stateForm.action}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                  <option value=""></option>
                  {
                    // guia aerea
                    (partsOrder && partsOrder[0]?.IdEtapa === 31) && (
                      <>
                        <option value="36">{t("RequestPartsView.fecha_desp")}</option>
                        <option value="100">{t("RequestPartsView.comentario")}</option>
                        <option value="30">{t("RequestPartsView.acu_recibo")}</option>
                      </>
                    )
                  }

                  {
                    // fecha de despacho
                    (partsOrder && partsOrder[0]?.IdEtapa === 36) && (
                      <>
                        <option value="1">{t("RequestPartsView.transito")}</option>
                        <option value="100">{t("RequestPartsView.comentario")}</option>
                        <option value="6">{t("RequestPartsView.guia_desp")}</option>
                      </>
                    )
                  }
                  {
                    // Nacionalizacion
                    (partsOrder && partsOrder[0]?.IdEtapa === 38) && (
                      <>
                        <option value="1">{t("RequestPartsView.por_lleg_alma")}</option>
                        <option value="100">{t("RequestPartsView.comentario")}</option>
                        <option value="6">{t("RequestPartsView.transito")}</option>
                      </>
                    )
                  }

                  {
                    // Transito
                    (partsOrder && partsOrder[0]?.IdEtapa === 37) && (
                      <>
                        <option value="1">{t("RequestPartsView.nacionali")}</option>
                        <option value="100">{t("RequestPartsView.comentario")}</option>
                        <option value="6">{t("RequestPartsView.fec_despa_act")}</option>
                      </>
                    )
                  }

                  {
                    // Acuse de recibo
                    (partsOrder && partsOrder[0]?.IdEtapa === 30) && (
                      <>
                        <option value="1">{t("RequestPartsView.guia_desp")}</option>
                        <option value="101">{t("RequestPartsView.recha_parte")}</option>
                        <option value="2">{t("RequestPartsView.compra_local")}</option>
                        <option value="100">{t("RequestPartsView.comentario")}</option>
                        <option value="6">{t("RequestPartsView.orden_compra")}</option>
                      </>
                    )
                  }
                </select>
              </div>
            </div>

            {/* FILA 2 */}
            <div className="row-flex">
              {/* INSTRUCCIONES DE DESPACHO */}
              <div className="col-7 col-sm-30">
                <strong>{t("RequestPartsView.instruc_despa")}</strong>
              </div>
              <div className="col-6 col-sm-70-mod">
                <span style={{ color: 'var(--danger-colo)', fontWeight: 'bold' }}>{shippingInstruc && shippingInstruc[0]?.Despacho}</span>
              </div>
            </div>

            {
              // Guia aerea
              (partsOrder && partsOrder[0]?.IdEtapa === 31) && (
                <div className="row-flex">
                  {/* GUIA DE DESPACHO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.guia_desp")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-text-control"
                      name="guiaInputGuiaAereaEtapa"
                      type="text"
                      value={stateForm.guiaAerea}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, guiaAerea: target.value }))}
                      autoComplete="off" />
                  </div>
                  {/* CARRIER */}
                  <div className="col-7 col-sm-30">
                    <strong>Carrier</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-text-control"
                      name="carrierInputGuiaAereaEtapa"
                      type="text"
                      value={stateForm.carrier}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, carrier: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>
              )
            }

            {
              // Fecha de despacho
              (partsOrder && partsOrder[0]?.IdEtapa === 36) && (
                <div className="row-flex">
                  {/* FECHA DE DESPACHO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.fec_despa")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecDesp"
                      value={stateForm.fechaDesp}
                      onChange={({ target }) => setStateForm({ ...stateForm, fechaDesp: target.value })} />
                  </div>
                  {/* FECHA ESTIMADA LLEGADA */}
                  <div className="col-5 col-sm-30">
                    <strong>{t("RequestPartsView.fec_estim_lle")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecEstiLle"
                      value={stateForm.fecEstim}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fecEstim: target.value }))} />
                  </div>
                </div>
              )
            }

            {
              // Acuse de recibo
              (partsOrder && partsOrder[0]?.IdEtapa === 30) && (
                <div className="row-flex">
                  {/* SO input */}
                  <div className="col-7 col-sm-30">
                    <strong># SO / # SOA</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-text-control"
                      type="text"
                      name="soInpNum"
                      value={stateForm.SO}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, SO: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>
              )
            }

            {/* Comentarios */}
            <div className="row-flex">
              {/* COMENTARIO */}
              <div className="col-7 col-sm-30">
                <strong>{t("RequestPartsView.comentario")}</strong>
              </div>
              <div className="col-83-87 col-sm-70-mod">
                <textarea
                  className="textarea-description"
                  name="textareaComentarioGuiaAereaEtapa"
                  value={stateForm.comentario}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                  placeholder={t("RequestPartsView.si_tiene_comen")}
                  autoComplete="off" />
              </div>
            </div>
          </div>

          {/* TABLA PARA EL DETALLE DE LAS PARTES */}
          <div className="table-container-mobile m-y-medium-table">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>{t("TableParts.orden")}</th>
                  <th>{t("TableParts.canti")}</th>
                  <th>{t("TableParts.parte")}</th>
                  <th>{t("TableParts.descrip")}</th>
                  <th>{t("TableParts.suplidor")}</th>
                  <th>{t("TableParts.canti")}</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  partes && partes.map((parts) => (
                    <tr key={parts.CodDetSolRepuesto}>
                      <td className="txt-center col-9">
                        <Link className="link-tag" to={`/logistic/order-detail-reader/${parts.NumOrden}`}>{parts.NumOrden}</Link>
                      </td>
                      <td style={{ textAlign: 'center' }}>{parts.Cantidad}</td>
                      <td><Link className="link-tag" to={`/part-detail/${parts.CodDetSolRepuesto}/${parts.CodSolRepuesto}`}>{parts.NumParte}</Link></td>
                      <td>{parts.Descripcion}</td>
                      <td>{parts.NomProveedor}</td>
                      <td className="txt-center">
                        {(parts.IdEtapa < 38) && parts.Cantidad}
                        {
                          (parts.IdEtapa === 38) && (
                            <input
                              className="input-text-control txt-center"
                              type="text"
                              name={parts.CodDetSolRepuesto}
                              value={parts.CantLlego}
                              onChange={({ target }) => onHandleChangeCant(parts.CodDetSolRepuesto, parts.CodSolRepuesto, target.value)} />
                          )
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          {/* BOTONES DE Accion */}
          <div className="row-flex j-content-space-around m-y-medium">
            <button type={state.saving ? "button" : "submit"} className={`btn-primary ${state.saving ? "disabled" : ""}`}>{state.saving ? t("serviceCard.guardando") : t("RequestPartsView.guar")}</button>
            <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
          </div>
        </form>
      </div>
    </>
  );
};