// componente para buscar las ordenes de comercial

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";






export const SearchCommeOrder = ({ onClose, lang, token, setNewList }) => {


  // GET the react translation
  const [t] = useTranslation("global");

  // GET useNavigate
  const navigate = useNavigate();


  // GET the useSearchParams
  const [params, setParams] = useSearchParams();


  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ searching: false, isLoading: false });
  const [form, setForm] = useState({ solOrde: params.get("sol_ord") ?? "", poGon: params.get("po_gon") ?? "", etapa: params.get("nom_etap") ?? "", vendedor: params.get("nom_vend") ?? "", division: params.get("nom_divi") ?? "", linea: params.get("nom_lin") ?? "", modalidad: params.get("nom_modad") ?? "", cliente: params.get("nom_custo") ?? "" });

  const PAGE_SIZE = 20;


  // destructuring data server
  const { division, linea, modalidad, etapas, employees } = dataServ && dataServ;




  // funcion para cambiar los valores
  const onHandleChangeVal = (newVal = "", name = "", queryPar = "") => {

    if (name === "solOrde") {
      if (isNaN(newVal)) {
        return;
      }
    }

    setForm((val) => ({ ...val, [name]: newVal }));

    params.set("filt", 1);
    params.set(queryPar, newVal);

    setParams(params);
  };


  // funcion para buscar en el servidor
  const onHandleSearch = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.getPurchaseOrderCommercial}?language=${lang}&solOrden=${form.solOrde}&poGON=${form.poGon}&etapa=${form.etapa}&vendedor=${form.vendedor}&division=${form.division}&linea=${form.linea}&modalidad=${form.modalidad}&cliente=${form.cliente}&action=1&page=1&pageSize=${PAGE_SIZE}`;

    try {
      setState((val) => ({ ...val, searching: true }));

      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setNewList(data?.purchaseOrders, data?.Total, "", data?.purchaseOrders.length < PAGE_SIZE);
        onClose();
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNewList([], 0, data?.msg, false);
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para solicitar la data del servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.getPurchaseOrderCommercial}?language=${lang}&action=2`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);




  return (
    <div className="background-modal">
      <form onSubmit={onHandleSearch} className="container-select-customer container-filter-empl-h">
        <div className="modal-header">
          <h4>Búsqueda</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          {
            state.isLoading && (<strong>{t("PartModify.cargando_info")}</strong>)
          }

          {
            !state.isLoading && (
              <>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Sol. Orden</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="solOrden"
                      disabled={form.poGon !== ""}
                      value={form.solOrde}
                      onChange={({ target }) => onHandleChangeVal(target.value, "solOrde", "sol_ord")} />
                  </div>
                  <div className="col-7">
                    <strong>PO# GON</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="poGon"
                      value={form.poGon}
                      onChange={({ target }) => onHandleChangeVal(target.value, "poGon", "po_gon")} />
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Etapa</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="step"
                      value={form.etapa}
                      onChange={({ target }) => onHandleChangeVal(target.value, "etapa", "nom_etap")}>
                      <option value="">(Pendientes)</option>
                      <option value="-1">(Todas las abiertas)</option>
                      <option value="-2">(En espera)</option>
                      <option value="0">(Todas incluyendo cerradas)</option>
                      {
                        etapas && etapas.map((step) => (
                          <option key={step.IdEtapa} value={step.IdEtapa}>{step.NomEtapa}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-7">
                    <strong>Vendedor</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="salesPerson"
                      value={form.vendedor}
                      onChange={({ target }) => onHandleChangeVal(target.value, "vendedor", "nom_vend")}>
                      <option value=""></option>
                      {
                        employees && employees.map((emp) => (
                          <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Division</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="division"
                      value={form.division}
                      onChange={({ target }) => onHandleChangeVal(target.value, "division", "nom_divi")}>
                      <option value=""></option>
                      {
                        division && division.map((divi) => (
                          <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-7">
                    <strong>Linea</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="linea"
                      disabled={form.division === ""}
                      value={form.linea}
                      onChange={({ target }) => onHandleChangeVal(target.value, "linea", "nom_lin")}>
                      <option value=""></option>
                      {
                        linea && linea.filter((filtL) => filtL.CodDivision === form.division).map((lin) => (
                          <option key={lin.CodLinea} value={lin.CodLinea}>{lin.NomLinea}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Modalidad</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="modality"
                      disabled={form.linea === ""}
                      value={form.modalidad}
                      onChange={({ target }) => onHandleChangeVal(target.value, "modalidad", "nom_modad")}>
                      <option value=""></option>
                      {
                        modalidad && modalidad.filter((filtMo) => filtMo.CodLinea === form.linea).map((mo) => (
                          <option key={mo.CodModalidad} value={mo.CodModalidad}>{mo.NomModalidad}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-7">
                    <strong>Cliente</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="customer"
                      value={form.cliente}
                      onChange={({ target }) => onHandleChangeVal(target.value, "cliente", "nom_custo")} />
                  </div>
                </div>
              </>
            )
          }


        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success" disabled={state.searching}>{state.searching ? "Buscando..." : "Buscar"}</button>
          <button type="button" className="btn-warning">{t("serviceCard.limpiar_filtro")}</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};