// componente para buscar los suplidores del Dynamic dependiendo de la compania

import { useEffect, useState } from "react";

// react router import
import { useNavigate } from "react-router-dom";

// url import
import { urls } from "../../api-urls/api-urls";

// global functions
import { getTheToken } from "../../global-functions/globalFunctions";

// translation import
import { useTranslation } from "react-i18next";




export const SearchDYNSupplier = ({ company, onClose, language, onAddSupplier }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar los suplidores en el estado
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, msg: "", dataVisible: false, nameSup: "", codSup: 0 });


  // desctructuring data server
  const { suppliersDYN } = dataServer && dataServer;


  // filtrar el suplidor por el nombre
  const filtSupplier = suppliersDYN && suppliersDYN.filter((sup) => sup.NomProveedor?.toLowerCase().includes(state.nameSup.toLowerCase()));


  // funcion para traer los suplidores
  const fetchDatasuppliers = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.dataSupplier}?language=${language}&empresa=${company}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        // setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 404) {
        // setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDatasuppliers();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [company]);





  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h4>{t("LogisticViewComp.enlazar_suplidor")}</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("LogisticViewComp.nombre")}</strong>
            </div>
            <div className="col-6">
              <input
                className="input-text-control"
                type="text"
                name="nameSupp"
                value={state.nameSup}
                onChange={({ target }) => setState((val) => ({ ...val, nameSup: target.value }))}
                autoComplete="off" />
            </div>
          </div>

          {
            state.isLoading && (<h4>{t("LoginView.cargando")}</h4>)
          }

          {
            suppliersDYN && suppliersDYN.length > 0 && (
              <div className="list-supplier-container">
                {
                  filtSupplier && filtSupplier.map((sup) => (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "0.5rem" }} className="col border-bottom hover-class" key={sup.CodProveedor}>
                      <strong>{sup.NomProveedor}</strong>
                      <button type="button" style={{ cursor: "pointer" }} onClick={() => onAddSupplier(sup.CodProveedor, company)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                          <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                        </svg>
                      </button>
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
        <div className="modal-footer justify-center">
          <button type="button" className="btn-secondary" onClick={onClose}>{t("Viaticos.cerrar")}</button>
        </div>
      </div>
    </div>
  );
};