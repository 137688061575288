

import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { Error403View } from "../error-403-view/Error403View";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";

// React translation importation
import { useTranslation } from "react-i18next";



export const NotaEntregaDetailView = () => {

  // language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // usenavigate
  const navigate = useNavigate();

  // useparams
  const { codSolRepuesto } = useParams();

  // location
  const location = useLocation();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // componente para cargar la data del servidor
  const [solRepState, setSolRepState] = useState([]); // para setear el detalle de la solicitud en el estado
  const [partes, setPartes] = useState([]); // para setear el detalle de los repuestos en el estado
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, badRequest: false, serverError: false, notFound: false, showModal: false });
  const [stateForm, setStateForm] = useState({ formaEnvio: "", tecnicoEnv: "", enviadoA: "", codZona: 0, codServicio: 0, comment: "", primaryAction: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });
  const [questionAction, setQuestionAction] = useState("");

  // DESTRUCTURING DATA FROM THE SERVER
  const { solRep, detSolRep, employees } = dataServer && dataServer;

  const CONDUCE = 40; // ETAPA DE CONDUCE


  // Funcion para cambiar el serial en el estado de partes
  const onChangeSerial = (newVal, index) => {
    const updateSerial = [...partes];

    updateSerial[index].Serial = newVal;

    setPartes(updateSerial);
  };


  // Funcion para abrir el modal al dar click en enviar
  const onHandleSendModal = () => {
    setStateForm((val) => ({ ...val, primaryAction: "1" }));

    // Verificar que la forma de envio, enviado a y tecnico a enviar estén seleccionado
    if (stateForm.formaEnvio === "") {
      return setNotification({ show: true, msg: t("RequestPartsView.forma_envio"), error: true, great: false, success: false });
    }

    if (stateForm.enviadoA === "") {
      return setNotification({ show: true, msg: t("RequestPartsView.selec_envia"), error: true, great: false, success: false });
    }

    setQuestionAction(t("RequestPartsView.enviar_conf_tecni"));

    setState((val) => ({ ...val, showModal: true }));
  };


  // Funcion para abrir el modal al dar click en por despachar
  const onHandleDispatchModal = () => {
    setStateForm((val) => ({ ...val, primaryAction: "2" }));

    // Abrir el modal
    setState((val) => ({ ...val, showModal: true }));

    setQuestionAction(t("RequestPartsView.enviar_sol_despa_pregunta"));
  };


  // MENSAJES AUTOMATICOS
  const FORM_ENV_PERSONAL = stateForm.codZona === 1 ? "Entregado personalmente " : "Delivered personally ";
  const FORM_ENV_TRANS_INT = stateForm.codZona === 1 ? "Enviado por transporte interno " : "Shipped by internal transport ";
  const FORM_ENV_TRANS_EXT = stateForm.codZona === 1 ? "Enviado por transporte externo " : "Shipped by external transport ";
  const FORM_ENV_TAXI = stateForm.codZona === 1 ? "Enviado por taxi " : "Shipped by taxi ";

  const ENVIADO_A_CLIENTE = stateForm.codZona === 1 ? "Directo al cliente." : "Direct customer.";

  // Horario de envio
  const RENDER_HORARIO = {
    0: t("RequestPartsView.en_la_ma"),
    1: t("RequestPartsView.en_la_tar"),
    2: t("RequestPartsView.en_la_noc")
  };

  // useEffect para buscar
  useEffect(() => {

    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.notaEntrega}/${codSolRepuesto}/search?language=${language}&print=0`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 400) {
          // bad request
        } else if (response.status === 403) {
          setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        } else if (response.status === 500) {
          // ocurrio un error interno en el servidor
        }

        setState((val) => ({ ...val, isLoading: false }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

  }, [codSolRepuesto, language]);


  // useEffect para cargar los datos de la solicitud en el estado
  useEffect(() => {
    if (solRep && solRep.length > 0) {
      setSolRepState(solRep);
      setStateForm((val) => ({ ...val, tecnicoEnv: solRep[0]?.CodEmpIngreso, codZona: solRep[0]?.codZona, codServicio: solRep[0]?.CodServicio }));
    }
  }, [solRep]);

  useEffect(() => {
    if (stateForm.formaEnvio === "") {
      setStateForm((val) => ({ ...val, enviadoA: "" }));
    }
  }, [stateForm.formaEnvio]);


  // useEffect para cuando cambie la forma de envio
  useEffect(() => {
    let MENSAJE = "";

    switch (stateForm.formaEnvio) {
      case "0": // Personal
        MENSAJE = FORM_ENV_PERSONAL;
        break;
      case "1": // Transporte interno
        MENSAJE = FORM_ENV_TRANS_INT;
        break;
      case "2": // Transporte externo
        MENSAJE = FORM_ENV_TRANS_EXT;
        break;
      case "3": // Taxi
        MENSAJE = FORM_ENV_TAXI;
        break;
    }

    setStateForm((val) => ({ ...val, comment: MENSAJE }));
  }, [stateForm.formaEnvio]);


  // useEffect para cuando el enviadoa cambie
  useEffect(() => {
    if (stateForm.enviadoA === "0") {
      const employeeFound = employees && employees.find((emp) => emp.CodEmpleado === Number(stateForm.tecnicoEnv));

      setStateForm((val) => ({ ...val, comment: `${stateForm.comment} ${employeeFound?.NomEmpleado}` }));
    } else {

    }
  }, [stateForm.enviadoA]);

  // useEffect para cargar el detalle de las partes en el estado
  useEffect(() => {
    if (detSolRep && detSolRep.length > 0) {
      setPartes(detSolRep);
    }
  }, [detSolRep]);


  // useEffect para limpiar las notificacion
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5500);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.show]);


  // useEffect para cuando se reciba la respuesta satisfactoria, redireccionar a warehouse principal
  useEffect(() => {
    if (notification.success) {
      const timer = setTimeout(() => {
        navigate("/warehouse/principal");
      }, 3500);

      // Limpiar el useEffect
      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.success]);



  // Funcion para enviar el formulario al backend
  const onCreateDeliveryNote = async (event) => {
    event.preventDefault();

    // Verificar que la forma de envio, enviado a y tecnico a enviar estén seleccionado
    if (stateForm.formaEnvio === "") {
      return setNotification({ show: true, msg: t("RequestPartsView.forma_envio"), error: true, great: false, success: false });
    }

    if (stateForm.enviadoA === "") {
      return setNotification({ show: true, msg: t("RequestPartsView.selec_envia"), error: true, great: false, success: false });
    }

    // Objeto final a enviar
    const finalObj = { stateForm, partes };

    console.log("Se envió el formulario al backend");
    try {
      const response = await fetch(`${urls.notaEntrega}/${codSolRepuesto}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification({ show: true, msg: data?.msg, error: false, great: true, success: true });
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 201) {
        setNotification({ show: true, msg: data?.msg, error: false, great: true, success: true });
        setState((val) => ({ ...val, showModal: false }));
        window.open(`/warehouse/delivery-notes-print/${data?.codNotEntr}`);
      } else if (response.status === 400) {
        setNotification({ show: true, msg: data?.msg, error: true, great: false, success: false });
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification({ show: true, msg: data?.msg, error: true, great: false, success: false });
      }

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {/* Toast notification component */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {/* Unauthorized component */}
      {state.unauthorized && (<Error403View />)}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onCreateDeliveryNote}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* SOLICITUD */}
                <div className="col-8"><strong>{t("RequestPartsView.soli")}</strong></div>
                <div className="col-7"><h3>{solRepState[0]?.CodSolRepuesto}</h3></div>
                {/* ID SERVICIO */}
                <div className="col-10"><strong>{t("RequestPartsView.id_serv")}</strong></div>
                <div className="col-9"><Link className="link-tag" to={`/service/service-call/${solRepState[0]?.CodServicio}`}>{solRepState[0]?.CodServicio}</Link></div>
                {/* MODELO */}
                <div className="col-10"><strong>{t("RequestPartsView.modelo")}</strong></div>
                <div className="col-9"><span>{solRepState[0]?.nomEquipo[0]?.Modelo}</span></div>
                {/* SERIAL */}
                <div className="col-9"><strong>Serial</strong></div>
                <div className="col-7"><span>{solRepState[0]?.nomEquipo[0]?.Serial}</span></div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* FECHA */}
                <div className="col-8"><strong>{t("RequestPartsView.fecha")}</strong></div>
                <div className="col-34-8"><span>{renderDate(solRepState[0]?.FecIngreso, language)}</span></div>
                {/* SUPLIDOR */}
                <div className="col-10"><strong>{t("RequestPartsView.supli")}</strong></div>
                <div className="col-34-8"><span>{solRepState[0]?.nomEquipo[0]?.Marca}</span></div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* INSTALACION */}
                <div className="col-8"><strong>{t("RequestPartsView.instala")}</strong></div>
                <div className="col-5"><span></span></div>
                {/* OC */}
                <div className="col-8"><strong>O/C</strong></div>
                <div className="col-5"><span></span></div>
                {/* PRIORIDAD */}
                <div className="col-8"><strong>{t("RequestPartsView.prio")}</strong></div>
                <div className="col-7"></div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-8"><strong>{t("RequestPartsView.cliente")}</strong></div>
                <div className="col-34-8"><Link className="link-tag" to={`/customers/customer-detail/${solRepState[0]?.CodCliente}`}>{solRepState[0]?.NomCliente}</Link></div>
                {/* FDO */}
                <div className="col-10"><strong>FDO</strong></div>
                <div className="col-3"><span></span></div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* EQUIPO */}
                <div className="col-8"><strong>{t("RequestPartsView.equip")}</strong></div>
                <div className="col-34-8"><Link className="link-tag" to={`/equipments/equipment-detail/${solRepState[0]?.CodEquipo}`}>{language === "en" ? solRepState[0]?.nomEquipo[0]?.LineaEn : solRepState[0]?.nomEquipo[0]?.Linea} - {language === "en" ? solRepState[0]?.nomEquipo[0]?.ModalidadEn : solRepState[0]?.nomEquipo[0]?.Modalidad} - {language === "en" ? solRepState[0]?.nomEquipo[0]?.TipEquipoEn : solRepState[0]?.nomEquipo[0]?.TipEquipo} - {solRepState[0]?.nomEquipo[0]?.Modelo} - {solRepState[0]?.nomEquipo[0]?.Serial} - {solRepState[0]?.nomEquipo[0]?.Marca} - {solRepState[0]?.nomEquipo[0]?.NumSistema}</Link></div>
                {/* CONTRATO */}
                <div className="col-10"><strong>{t("RequestPartsView.contr")}</strong></div>
                <div className="col-3" style={{ border: '1px solid' }}><span></span></div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* TECNICO */}
                <div className="col-8"><strong>{t("RequestPartsView.ing_tec")}</strong></div>
                <div className="col-34-8"><span>{solRepState[0]?.NomEmpleado}</span></div>
                {/* FALLA */}
                <div className="col-10"><strong>{t("RequestPartsView.falla")}</strong></div>
                <div className="col-3"><span style={{ textTransform: 'capitalize', textDecoration: 'underline', color: 'var(--danger-color)' }}>{solRepState[0]?.Falla.toLowerCase()}</span></div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* UBICACION */}
                <div className="col-8"><strong>{t("RequestPartsView.ubicacion")}</strong></div>
                <div className="col-34-8"><span>{solRepState[0]?.nomEquipo[0]?.Ubicacion}</span></div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-8"><strong>{t("RequestPartsView.obser")}</strong></div>
                <div className="col-34-8"><span style={{ textTransform: 'capitalize', color: 'var(--green-color)', textDecoration: 'underline', fontWeight: 'bold' }}>{solRepState[0]?.lastComment.toLowerCase()}</span></div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* REQUERIDO PARA EL */}
                <div className="col-8"><strong>{t("RequestPartsView.requerido_para")}</strong></div>
                <div className="col-34-8">
                  {
                    solRepState[0]?.FecRequerida === null || solRepState[0]?.FecRequerida === ""
                      ? (<h4 className="required">{t("RequestPartsView.no_especificado")}</h4>)
                      : (<span style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>{renderDate(solRepState[0]?.FecRequerida, language)} {RENDER_HORARIO[solRepState[0]?.Horario] ?? "No se indicó el horario"}</span>)
                  }
                </div>
              </div>
              <hr className="m-y-small" />

              {/* FILA 10 */}
              <div className="row-flex">
                {/* NOTA DE ENTREGA */}
                <div className="col-8"><strong>{t("RequestPartsView.nota_entrega")}</strong></div>
                <div className="col-34-8"><span></span></div>
                {/* TECNICO A ENVIAR */}
                <div className="col-8"><strong>{t("RequestPartsView.tec_enviar")}</strong></div>
                <div className="col-3">
                  <select name="tecnicoEnviar" value={stateForm.tecnicoEnv} onChange={({ target }) => setStateForm((val) => ({ ...val, tecnicoEnv: target.value }))}>
                    <option value=""></option>
                    {
                      employees && employees.map((emp) => (
                        <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* FORMA DE ENVIO */}
                <div className="col-8"><strong>{t("RequestPartsView.form_envio")}</strong></div>
                <div className="col-34-8">
                  <select name="formaEnvio" value={stateForm.formaEnvio} onChange={({ target }) => setStateForm((val) => ({ ...val, formaEnvio: target.value }))}>
                    <option value=""></option>
                    <option value="0">Personal</option>
                    <option value="1">{t("RequestPartsView.trans_inter")}</option>
                    <option value="2">{t("RequestPartsView.trans_ext")}</option>
                    <option value="3">Taxi</option>
                  </select>
                </div>
                {/* ENVIADO A */}
                <div className="col-8"><strong>{t("RequestPartsView.enviado_a")}</strong></div>
                <div className="col-3">
                  <select
                    className={stateForm.formaEnvio === "" ? "wrapper" : ""}
                    name="enviadoA"
                    value={stateForm.enviadoA}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, enviadoA: target.value }))}
                    disabled={stateForm.formaEnvio === ""}>
                    <option value=""></option>
                    <option value="0">{t("RequestPartsView.tecnico")}</option>
                    <option value="1">{t("RequestPartsView.cliente")}</option>
                    <option value="2">{t("RequestPartsView.otro")}</option>
                  </select>
                </div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex m-y-small">
                {/* COMENTARIOS */}
                <div className="col-8"><strong>{t("RequestPartsView.comentario")}</strong></div>
                <div className="flex-auto">
                  <textarea
                    className="textarea-description"
                    name="comentario"
                    value={stateForm.comment}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                    autoComplete="off"
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              <hr className="m-y-small" />

              {/* Tabla para mostrar las partes */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.existe")}</th>
                      <th>{t("TableParts.orden")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>Serial</th>
                      <th>{t("TableParts.precio")}</th>
                      <th>{t("RequestPartsView.alma")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes.map((part, index) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td className="txt-center">{index + 1}</td>
                          <td className="txt-center col-10">{part.Cantidad}</td>
                          <td>{ }</td>
                          <td className="txt-center"><Link className="link-tag">{part.NumOrden}</Link></td>
                          <td className="col-7"><Link className="link-tag">{part.NumParte} ({part.Alternativo})</Link></td>
                          <td className="col-4">{part.Descripcion}</td>
                          <td className="col-9">
                            <input
                              className="input-text-control"
                              type="text"
                              name={`serial-${part.CodDetSolRepuesto}`}
                              value={part.Serial ?? ""}
                              onChange={({ target }) => onChangeSerial(target.value, index)}
                              autoComplete="off" />
                          </td>
                          <td style={{ color: 'var(--money-color)', fontWeight: 'bold' }} className="txt-center">
                            {formatCurrency(part.Precio * part.Cantidad, 'USD')}
                          </td>
                          <td className="txt-center">{part.CanAlmacen}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones de accion */}
              <div className="row-flex j-content-space-around m-y-medium">
                <button type="button" className="btn-primary" onClick={onHandleSendModal}>{t("RequestPartsView.enviar")}</button>
                <button type="button" className="btn-info" onClick={onHandleDispatchModal}>{t("RequestPartsView.por_despa")}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModal: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }
      </div>
    </>
  );
};