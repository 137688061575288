// este componente es solamente para mostrar el detalle de la orden en formato 
// reader

// react import
import { useEffect, useState } from "react";

// components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { PartDetailModify } from "../../components/logistic-components/PartDetailModify";

// react router dom import
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";


// useTranslation import
import { useTranslation } from "react-i18next";

// global functions import
import { formatCurrency, getTheToken, renderDate, renderIdTipSolicitud } from "../../global-functions/globalFunctions";

// url imports
import { urls } from "../../api-urls/api-urls";


export const OrderDetailViewRead = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();


  // GET the params
  const { orderNum } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data del servidor
  const [stateSolRep, setStateSolRep] = useState([]); // estado para colocar la data luego de desestructurarla
  const [controller, setController] = useState(null); // funcion para abortar la peticion al servidor
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, codSol: 0, codDetSol: 0, showModalA: false, reload: false });


  // destructuring data server
  const { orden, empPerm } = dataServer && dataServer;


  let totalOrden = 0; // esta variable para tener el total de la orden de compra

  // IdTipAprobaciones que incluyen aprobacion zse
  const APROBACIONES = ["2", "6", "7"];


  // funcion para cerrar el modal
  const onCloseModal = () => {
    setState((val) => ({ ...val, showModalA: false }));
  };

  // funcion para recargar la data
  const onReloadFromChildComp = (NewVal) => {
    setState((val) => ({ ...val, reload: NewVal }));
  };

  const onDownloadSoporteDoc = async (print = "", action = "") => {
    try {
      const response = await fetch(`${urls.orderDetail}?language=${language}&codSolRep=${stateSolRep[0]?.CodSolRepuesto}&action=${action}&print=${print}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear enlance oculto
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = blobURL;
        a.download = action === "1" ? `Zone support approval ${stateSolRep[0]?.CodSolRepuesto}` : `Purchase order`;

        document.body.appendChild(a);

        // simular click en el enlace para iniciar la descargar
        a.click();

        setNotification((val) => ({ ...val, show: true, msg: t("General_Comp.descarga_doc"), great: true }));

        // limpiar la url y el enlace de la descarga
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para realizar el fetch al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.orderDetail}?language=${language}&codOrden=${orderNum}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 400) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, orderNum]);


  // useEffect para cargar la data del servidor en el estado de stateSolRep
  useEffect(() => {
    if (orden && orden.length) {
      setStateSolRep(orden);
    }
  }, [orden]);


  // useEffect para limpiar las notificaciones del sistema
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para recargar la data
  useEffect(() => {
    if (state.reload) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [state.reload]);


  return (
    <>
      {
        // error 403
        state.unauthorized && (<Error403View />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="container display-data">

        {
          state.dataVisible && (
            <>
              {/* FILA */}
              <div className="row-flex">
                <div className="col-8 col-sm-30"><strong>Dynamics</strong></div>
                <div className="col-9 col-sm-70-mod">
                  <select
                    disabled
                    name="dynamic"
                    value={stateSolRep[0]?.EsOrdenLS.toString() ?? ""}>
                    <option value=""></option>
                    <option value="1">{t("RequestPartsView.si")}</option>
                    <option value="0">No</option>
                  </select>
                </div>
                {/* NUMERO ORDEN DYNAMICS */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.orden_dyn")}</strong></div>
                <div className="col-7 col-sm-70-mod"><span className="span-order-num" style={{ color: "red" }}>{stateSolRep[0]?.NumOrdenLS}</span></div>
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.alma")}</strong></div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    disabled
                    className="fake-select"
                    name="warehouse"
                    value={stateSolRep[0]?.CodDepartamento}>
                    <option value=""></option>
                    {
                      stateSolRep[0]?.departamentos.map((dep) => (
                        <option key={dep.CodDepartamento} value={dep.CodDepartamento}>{dep.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* ORDEN DE COMPRA # */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.orden_compra")}</strong></div>
                <div className="col-9 col-sm-70-mod"><h5 style={{ color: 'var(--first-color)' }}>{stateSolRep[0]?.NumOrden}</h5></div>
                {/* SOLICITUD */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.soli")}</strong></div>
                <div className="col-7 col-sm-70-mod">
                  <h5><Link className="link-tag">{stateSolRep[0]?.CodSolRepuesto}</Link></h5>
                </div>
                {/* CLIENTE */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.cliente")}</strong></div>
                <div className="col-39-29 col-sm-70-mod">
                  <Link className="link-tag" to={`/customers/customer-detail/${stateSolRep[0]?.CodCliente}`}>{stateSolRep[0]?.NomCliente}</Link>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* FECHA/HORA */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.fecha")}</strong></div>
                <div className="col-9 col-sm-70-mod">
                  <span>{renderDate(stateSolRep[0]?.FecOrden, language)}</span>
                </div>
                {/* ID SERVICIO */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.id_serv")}</strong></div>
                <div className="col-7 col-sm-70-mod">
                  <h5><Link className="link-tag" to={`/service/service-call/${stateSolRep[0]?.CodServicio}`}>{stateSolRep[0]?.CodServicio}</Link></h5>
                </div>
                {/* EQUIPO */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.equip")}</strong></div>
                <div className="col-39-29 col-sm-70-mod text-hidden-over">
                  <Link className="link-tag" to={`/equipments/equipment-detail/`}>{ }</Link>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* TIPO DE APROBACION */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.tip_aproba")}</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <select
                    disabled
                    name="typeApproval"
                    className="fake-select"
                    value={stateSolRep[0]?.IdTipAprobacion}>
                    <option value=""></option>
                    <option value="0">Stock</option>
                    <option value="3">{t("RequestPartsView.designado")}</option>
                    <option value="6">{t("RequestPartsView.garan_ventas")}</option>
                    <option value="9">{t("RequestPartsView.export")}</option>
                    <option value="1">{t("RequestPartsView.activo_fij")}</option>
                    <option value="4">FMI/STB</option>
                    <option value="7">{t("RequestPartsView.garan_sopor")}</option>
                    <option value="10">{t("RequestPartsView.compr_local")}</option>
                    <option value="2">{t("RequestPartsView.contr")}</option>
                    <option value="5">Missing</option>
                    <option value="8">{t("RequestPartsView.gasto")}</option>
                  </select>
                </div>
                {/* TIPO DE SOLICITUD */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.tip_sol")}</strong></div>
                <div className="col-39-29 col-sm-70-mod">
                  <span>{renderIdTipSolicitud(stateSolRep[0]?.IdTipSolicitud, language)}</span>
                </div>
              </div>

              {/* FILA */}
              {/* FILA */}
              <div className="row-flex">
                {/* Aprobacion del soporte */}
                <div className="col-8 col-sm-30"><strong>Aprobación del soporte</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  {/* Si no se ha cargado la aprobacion */}
                  {
                    !stateSolRep[0]?.Soporte && APROBACIONES.includes(stateSolRep[0]?.IdTipAprobacion) && (
                      <span className="required">No se cargó la aprobación del soporte</span>
                    )
                  }
                  {/* Si ya se cargó la aprobacion */}
                  {
                    stateSolRep[0]?.Soporte && (
                      <button type="button" className="btn-pdf" onClick={() => onDownloadSoporteDoc("1", "1")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                          <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                        </svg>
                      </button>
                    )
                  }
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* ETAPA */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.eta")}</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <span style={{ color: 'var(--green-color', fontWeight: 'bold' }}>{stateSolRep[0]?.NomEtapa}</span>
                </div>
                {/* EMPRESA */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.empresa")}</strong></div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    disabled
                    name="company"
                    className="fake-select"
                    value={stateSolRep[0]?.CodEmpresa ?? ""}>
                    <option value=""></option>
                    <option value="GLOBL">Global Medica</option>
                    <option value="GMDH">GMD Healthcare Ltd</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* VIA */}
                <div className="col-8 col-sm-30"><strong>Via</strong></div>
                <div className="col-9 col-sm-70-mod">
                  <select
                    disabled
                    className="fake-select"
                    name="via"
                    value={stateSolRep[0]?.IdVia.toString() ?? ""}>
                    <option value=""></option>
                    <option value="0">{t("RequestPartsView.aerea")}</option>
                    <option value="1">{t("RequestPartsView.maritima")}</option>
                    <option value="2">{t("RequestPartsView.terrestre")}</option>
                  </select>
                </div>
                {/* SO */}
                <div className="col-9 col-sm-30"><strong>SO / SOA</strong></div>
                <div className="col-7 col-sm-70-mod"><span></span></div>
                {/* PRIORIDAD */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.prio")}</strong></div>
                <div className="col-39-29 col-sm-70-mod"></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* SUPLIDOR */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.supli")}</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.NomProveedor}</span>
                </div>
                {/* CONTACTO */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.contact")}</strong></div>
                <div className="col-39-29 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.NomContacProv}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* SHIP TO */}
                <div className="col-8 col-sm-30"><strong>Ship To</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.NomEmpEnvia}</span>
                </div>
                {/* CONTACTO EMPRESA */}
                <div className="col-9 col-sm-30"><strong>{t("RequestPartsView.contact")}</strong></div>
                <div className="col-39-29 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.contactEmpEnvia[0]?.NomContacEmpEnvia}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* APROBADO POR */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.apro_por")}</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.Aprobado}</span>
                </div>
                {/* INCOTERM */}
                <div className="col-9 col-sm-30"><strong>Incoterm</strong></div>
                <div className="col-39-29 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.Incoterm}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.facturar_a")}</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.FacturarA}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* FALLA */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.falla")}</strong></div>
                <div className="col-80 col-sm-70-mod">
                  <span className="span" style={{ textTransform: 'capitalize', color: 'var(--danger-color)' }}>{stateSolRep[0]?.FacturarA}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.obser")}</strong></div>
                <div className="col-80 col-sm-70-mod">
                  <span className="span">{stateSolRep[0]?.ObsOrden}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* INSTRUCCIONES DE DESPACHO */}
                <div className="col-8 col-sm-30"><strong>{t("RequestPartsView.instruc_despa")}</strong></div>
                <div className="col-sm-70-mod">
                  <span style={{ color: 'red', fontWeight: 'bold' }}></span>
                </div>
              </div>

              {/* Tabla de las partes */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.exportar")}</th>
                      <th>WorkFlow</th>
                      <th>{t("TableParts.precio_unit")}</th>
                      <th>{t("TableParts.itbis")}</th>
                      <th>{t("TableParts.precio_total")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      stateSolRep[0]?.partes.map((part) => {

                        const total = part.Precio * part.Cantidad * (1 + part.Itbis / 100);

                        totalOrden += total;

                        return (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="txt-center">
                              <button type="button" className="btn-link-tag" onClick={() => setState((val) => ({ ...val, showModalA: true, codSol: part.CodSolRepuesto, codDetSol: part.CodDetSolRepuesto }))}>
                                {part.CodDetSolRepuesto}
                              </button>
                            </td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td>
                              <Link className={part.TipArticulo === null ? "link-required" : "color-part-add-dyn"} to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link>
                            </td>
                            <td>{part.Descripcion}</td>
                            <td>
                              <select
                                disabled
                                name={`export-${part.CodDetSolRepuesto}`}
                                value={part.Exportar.toString() ?? ""}>
                                <option value=""></option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                              </select>
                            </td>
                            <td className="txt-center">{part.WorkFlow}</td>
                            <td className="txt-right">
                              {formatCurrency(part.Precio, 'USD')}
                            </td>
                            <td className="txt-right">
                              {part.Itbis}
                            </td>
                            <td className="txt-right">
                              {formatCurrency(total, 'USD')}
                            </td>
                            <td className="">
                              {/* <button type="button" className="btn-delivery" title="Separar item de la orden.">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--first-color)" className="bi bi-send-arrow-up-fill" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M15.854.146a.5.5 0 0 1 .11.54L13.026 8.03A4.5 4.5 0 0 0 8 12.5c0 .5 0 1.5-.773.36l-1.59-2.498L.644 7.184l-.002-.001-.41-.261a.5.5 0 0 1 .083-.886l.452-.18.001-.001L15.314.035a.5.5 0 0 1 .54.111ZM6.637 10.07l7.494-7.494.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338Z" />
                                  <path fillRule="evenodd" d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.354a.5.5 0 0 0-.722.016l-1.149 1.25a.5.5 0 1 0 .737.676l.28-.305V14a.5.5 0 0 0 1 0v-1.793l.396.397a.5.5 0 0 0 .708-.708l-1.25-1.25Z" />
                                </svg>
                              </button> */}
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr>
                      <td style={{ fontWeight: 'bold' }} className="txt-center">Total</td>
                      <td className="txt-right" colSpan={8} style={{ fontWeight: 'bold', color: 'var(--money-color)' }}>{formatCurrency(totalOrden, 'USD')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="row-flex j-content-space-around m-y-small">
                <button type="button" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>
            </>
          )
        }

        {
          state.showModalA && (
            <PartDetailModify
              solRepuesto={state.codSol}
              codDetSolRepuesto={state.codDetSol}
              onClose={onCloseModal}
              onReload={onReloadFromChildComp}
              employeePerm={empPerm} />
          )
        }

      </div>
    </>
  );
};