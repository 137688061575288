// Este componente es para mostrar la lista de aprobaciones de anticipo y relacion de gastos
import React, { useEffect, useState } from "react";


// Components importation
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// React router importation
import { Link, useLocation, useNavigate } from "react-router-dom";

// Url importation
import { urls } from "../../api-urls/api-urls";

// Global functions
import { formatCurrency, getTheToken } from "../../global-functions/globalFunctions";

// React translation importation
import { useTranslation } from "react-i18next";



export const AprobacionPrincipal = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // State definition
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // Estado para tener el controlador para abortar las peticiones
  const [stateGeren, setStateGeren] = useState([]); // Estado para almacenar la gerencia
  const [stateZona, setStateZona] = useState([]); // Estado para almacenar las zonas
  const [areaFun, setAreaFun] = useState([]); // Estado para almacenar el area funcional
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, notFound: false, serverError: false, showModal: false });
  const [stateForm, setStateForm] = useState({ empresa: "", zona: "", responsable: "", etapa: "", division: "", gerencia: "", areaFun: "", nroControl: "" });


  // Destructuring data server
  const { aprobaList, divisionEmpr, gerencia, unidad, zona } = dataServer && dataServer;

  // console.log(aprobaList)

  // Page size
  const pageSize = 20;

  const isLastPage = aprobaList && aprobaList.length < pageSize;

  // Funcion para abrir el modal del filtro
  const onShowFilter = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // Funcion para modificar el cambio de pagina
  const onHandlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  // Funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortcontroller = new AbortController();

    setController(abortcontroller);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.anticipoApprove}?language=${language}&empresa=&zona=&responsable=&etapa=&division=&gerencia=&areaFun=&page=${currentPage}&pageSize=${pageSize}&nroControl=`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortcontroller.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [language, currentPage]);


  // useEffect para filtrar las zonas
  useEffect(() => {
    if (zona && zona.length > 0) {
      const zonaFound = zona && zona.filter((zon) => zon.CodEmpresa.toString() === stateForm.empresa);

      setStateZona(zonaFound);
    }
  }, [stateForm.empresa]);


  // useEffect para filtrar la gerencia
  useEffect(() => {
    if (gerencia && gerencia.length > 0) {
      const foundGeren = gerencia && gerencia.filter((geren) => geren.CodGrupo.toString() === stateForm.division);

      setStateGeren(foundGeren);
    }
  }, [stateForm.division]);

  // useEffect para filtrar el area funcional
  useEffect(() => {
    if (unidad && unidad.length > 0) {
      const foundUnidad = unidad && unidad.filter((uni) => uni.CodGerencia.toString() === stateForm.gerencia);

      setAreaFun(foundUnidad);
    }
  }, [stateForm.gerencia]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.show]);


  // Funcion para manejar el envio del formulario cuando se busque por los filtros que no sea numero de control
  const onSearchGeneral = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.anticipoApprove}?language=${language}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&responsable=${stateForm.responsable}&etapa=${stateForm.etapa}&division=${stateForm.division}&gerencia=${stateForm.gerencia}&areaFun=${stateForm.areaFun}&page=${currentPage}&pageSize=${pageSize}&nroControl=`, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, showModal: false }));
        setNotification({ show: true, msg: data?.msg, error: true, great: false });
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // Funcion para manejar el envio del formulario y solo buscar por un numero de control
  const onSearchByNroControl = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.anticipoApprove}?language=${language}&empresa=&zona=&responsable=&etapa=&division=&gerencia=&areaFun=&page=${currentPage}&pageSize=${pageSize}&nroControl=${stateForm.nroControl}`, {
        headers: { "Authorization": getTheToken().token }
      });

      console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, showModal: false }));
        setNotification({ show: true, msg: data?.msg, error: true, great: false });
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };




  return (
    <>
      {/* Loading component */}
      {state.isLoading && (
        <div className="load-cont-transp">
          <div className="simple-spinner"><span></span></div>
          <p><strong>{language === "en" ? "Loading..." : "Cargando..."}</strong></p>
        </div>
      )}
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              {/* FILA */}
              <div className="row-flex j-content-space-b">
                <div className="col-3">
                  <form onSubmit={onSearchByNroControl} className="row-flex">
                    <div className="col-3"><strong>{t("Viaticos.nro_control")}</strong></div>
                    <div className="col-39-2">
                      <input
                        className="input-text-control"
                        type="text"
                        name="nroControl"
                        value={stateForm.nroControl}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, nroControl: target.value }))} />
                    </div>
                    <button type="submit" className="btn-success">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>

              {/* Tabla para mostrar la informacion */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>{t("Viaticos.nro_control")}</th>
                      <th>{t("Viaticos.empresa")}</th>
                      <th>Empleado</th>
                      <th>{t("Viaticos.etapa")}</th>
                      <th>{t("Viaticos.moneda")}</th>
                      <th>{t("Viaticos.anti_soli")}</th>
                      <th>{t("Viaticos.anti_aprob")}</th>
                      <th>{t("Viaticos.rela_soli")}</th>
                      <th>{t("Viaticos.rela_aprob")}</th>
                      <th>Total pagar</th>
                      <th>{t("Viaticos.comentario")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      aprobaList && aprobaList.map((list) => (
                        <tr key={list.codeIndex}>
                          <td>
                            <Link className="link-tag" to={`/travel-expenses/approve-detail/${list.CodSolGasto}`}>
                              {list.CodSolGasto}
                            </Link>
                          </td>
                          <td>{list.NomEmpresa}</td>
                          <td>{list.NomEmpleado}</td>
                          <td>{list.Etapa}</td>
                          <td className="txt-center">{list.Moneda}</td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(list.Anticipo, 'USD')}
                          </td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(list.AntAprobado, 'USD')}
                          </td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(list.Relacionado, 'USD')}
                          </td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(list.RelAprobada, 'USD')}
                          </td>
                          <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                            {formatCurrency(list.RelAprobada, 'USD')}
                          </td>
                          <td>
                            {list.Comentario}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones de siguiente y anterior */}
              <div className="row-flex justify-center align-c gap-small">
                <button
                  className={`btn-info ${currentPage === 1 ? "disabled-class" : ""}`}
                  disabled={currentPage === 1}
                  style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                  onClick={() => onHandlePageChange(currentPage - 1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                  </svg>
                  Anterior
                </button>
                <strong style={{ textDecoration: 'underline' }}>{currentPage}</strong>
                <button
                  className={`btn-info ${isLastPage ? "disabled-class" : ""}`}
                  disabled={isLastPage}
                  style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                  onClick={() => onHandlePageChange(currentPage + 1)}>
                  Siguiente
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                  </svg>
                </button>
              </div>


              <ButtonSearch onOpenFilter={onShowFilter} />

              {
                state.showModal && (
                  <div className="background-modal" onClick={onShowFilter}>
                    <div className="container-modal-filter-travel-expenses" onClick={(event) => event.stopPropagation()}>
                      <form onSubmit={onSearchGeneral} className="m-y-medium">
                        {/* FILA */}
                        <div className="row-flex">
                          {/* Empresa */}
                          <div className="col-5"><strong>{t("Viaticos.empresa")}</strong></div>
                          <div className="col-30">
                            <select
                              name="company"
                              value={stateForm.empresa}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                              <option value=""></option>
                              <option value="1">Global Medica</option>
                              <option value="2">GMD Healthcare Ltd</option>
                            </select>
                          </div>
                          {/* Zona */}
                          <div className="col-5"><strong>{t("Viaticos.zona")}</strong></div>
                          <div className="col-30">
                            <select
                              name="zona"
                              className={stateForm.empresa === "" ? "wrapper" : ""}
                              disabled={stateForm.empresa === ""}
                              value={stateForm.zona}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, zona: target.value }))}>
                              <option value=""></option>
                              {
                                stateZona && stateZona.map((zon) => (
                                  <option key={zon.CodZona} value={zon.CodZona}>{zon.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Responsable */}
                          <div className="col-5"><strong>{t("Viaticos.responsable")}</strong></div>
                          <div className="col-30">
                            <input
                              className="input-text-control"
                              type="text"
                              name="responsable"
                              value={stateForm.responsable}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, responsable: target.value }))} />
                          </div>
                          {/* Etapa */}
                          <div className="col-5"><strong>{t("Viaticos.etapa")}</strong></div>
                          <div className="col-30">
                            <select
                              name="step"
                              value={stateForm.etapa}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, etapa: target.value }))}>
                              <option value=""></option>
                              <option value="2">Aprobación de anticipo</option>
                              <option value="6">Aprobación de relación de gastos</option>
                            </select>
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Division empresa */}
                          <div className="col-5"><strong>{t("Viaticos.divi_empr")}</strong></div>
                          <div className="col-30">
                            <select
                              name="diviCompan"
                              value={stateForm.division}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                              <option value=""></option>
                              {
                                divisionEmpr && divisionEmpr.map((divi) => (
                                  <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                          {/* Gerencia */}
                          <div className="col-5"><strong>{t("Viaticos.gerencia")}</strong></div>
                          <div className="col-30">
                            <select
                              name="geren"
                              className={stateForm.division === "" ? "wrapper" : ""}
                              disabled={stateForm.division === ""}
                              value={stateForm.gerencia}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
                              <option value=""></option>
                              {
                                stateGeren && stateGeren.map((geren) => (
                                  <option key={geren.CodGerencia} value={geren.CodGerencia}>{geren.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        {/* FILA */}
                        <div className="row-flex">
                          {/* Area funcional */}
                          <div className="col-5"><strong>{t("Viaticos.area_fun")}</strong></div>
                          <div className="col-30">
                            <select
                              name="areaFun"
                              className={stateForm.gerencia === "" ? "wrapper" : ""}
                              disabled={stateForm.gerencia === ""}
                              value={stateForm.areaFun}
                              onChange={({ target }) => setStateForm((val) => ({ ...val, areaFun: target.value }))}>
                              <option value=""></option>
                              {
                                areaFun && areaFun.map((uni) => (
                                  <option key={uni.CodUnidad} value={uni.CodUnidad}>{uni.Nombre}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        {/* Botones de accion */}
                        <div className="row-flex j-content-space-around m-y-medium">
                          <button type="submit" className="btn-success">{t("Viaticos.buscar")}</button>
                          <button type="reset" className="btn-secondary" onClick={onShowFilter}>{t("Viaticos.cerrar")}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </>
  );
};