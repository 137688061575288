import React, { useEffect, useState } from "react";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// REACT ROUTER IMPORTATION
import { Link, useNavigate } from "react-router-dom";




export const EmployeeDetail = ({ idEmpleado, closeModal }) => {

  // GET THE LANGUAGE
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // navigate importation
  const navigate = useNavigate();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false);
  const [fecNaci, setFecNaci] = useState("");

  // DESTRUCTURING DATA
  const { training, infoEmpl, empPerm } = dataServer && dataServer;






  // USUARIOS QUE PUEDEN VER ESTA INFO
  const userPermissions = [6, 7, 9];

  // fetchDataToServer
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.employees}?codEmpleado=${idEmpleado}&action=2`;

    setController(abortController);
    try {
      setIsLoading(true);

      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/"); // si venció el token del usuario o no está logeado
      } else if (response.status === 500) {
        // error interno en el servidor
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idEmpleado]);


  // useEffect para setear la fecha
  useEffect(() => {
    if (infoEmpl && infoEmpl.length > 0) {
      const [, element2, element3] = renderDate(infoEmpl[0]?.FecNacimiento, language).split(" ");

      if (language === "en") {

        setFecNaci(`${element2} ${element3.replace(",", "")}`);
      } else {

        setFecNaci(`${element2} ${element3}`);
      }
    }
  }, [infoEmpl]);





  return (
    <div className="background-modal">
      <div className="container-select-customer">

        <div className="modal-header">

        </div>

        <div className="modal-body">
          {/* FILA 1 */}
          <div className="row-flex">
            {/* NOMBRE */}
            <div className="col-4">
              <strong>Nombre</strong>
            </div>
            <div className="col-6">
              <span>{infoEmpl && infoEmpl[0]?.NomEmpleado}</span>
            </div>
          </div>

          {/* FILA 2 */}
          <div className="row-flex">
            {/* CUMPLEANOS */}
            <div className="col-4">
              <strong>Cumpleaños</strong>
            </div>
            <div className="col-6">
              <span>{fecNaci}</span>
            </div>
          </div>

          {/* FILA 3 */}
          <div className="row-flex">
            {/* FLOTA */}
            <div className="col-4">
              <strong>Celular</strong>
            </div>
            <div className="col-6">
              <span style={{ textDecoration: 'underline', color: 'blue' }}>{infoEmpl && infoEmpl[0]?.Telefonos}</span>
            </div>
          </div>

          {/* FILA 4 */}
          <div className="row-flex">
            <div className="col-4">
              <strong>Cargo</strong>
            </div>
            <div className="col-6">
              <span>{infoEmpl && infoEmpl[0]?.Cargo}</span>
            </div>
          </div>

          {/* FILA 5 */}
          <div className="row-flex">
            <div className="col-4">
              <strong>Supervisor</strong>
            </div>
            <div className="col-6">
              <span>{infoEmpl && infoEmpl[0]?.Supervisor}</span>
            </div>
          </div>
          <hr className="m-y-small" />
          {/* TABLA PARA MOSTRAR LAS CAPACITACIONES */}
          <div className="table-container-mobile m-y-small">
            {
              isLoading && (<div className="simple-spinner"><span></span></div>)
            }
            {
              (training && training) && training.length > 0 ? (
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Capacitación y Desarrollo</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      training && training.map((trai, index) => (
                        <tr key={index}>
                          <td>{trai.Nombre}</td>
                          <td>{renderDate(trai.FecDesde, language)} - {renderDate(trai.FecHasta, language)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              ) : <span style={{ color: 'var(--danger-color)' }}>{language === "es" ? "Este empleado no tiene ninguna capacitación" : "This employee has no training"}</span>
            }
          </div>
        </div>

        <div className="modal-footer j-content-space-around">
          {empPerm && userPermissions.includes(empPerm[0]?.CodPerfil) ? <Link style={{ textDecoration: 'none' }} className="btn-primary" to={`/human-capital/employee/modify/${idEmpleado}`}>Modificar</Link> : null}
          <button className="btn-info" onClick={closeModal}>OK!</button>
        </div>

      </div>
    </div>
  );
};