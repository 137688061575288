
import React, { useEffect, useState } from "react";


// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// REACT ROUTER IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// global functions
import { getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions";

// react translations
import { useTranslation } from "react-i18next";



export const OrdTransitWaitingDispatch = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // get params
  const { codSolRepuesto } = useParams();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [stateSolRep, setStateSolRep] = useState([]); // estado cargar el solRepuesto del servidor
  const [stateDetSolRep, setStateDetSolRep] = useState([]); // estado para cargar las partes
  const [controller, setController] = useState(null); // estado para cargar el controlador para abortar la peticion al servidor
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });
  const [state, setState] = useState({ isLoading: false, unauthorized: false, showModalConfir: false, questionAction: "", loadingData: false });
  const [stateForm, setStateForm] = useState({
    tecEnviar: "", enviarPor: "", contactoEnv: "", nomContacto: "", telefono: "", enviarAl: "", fecEntregaRequerida: "",
    turnoEntrega: "", comentario: "", primaryAction: 0
  });


  // DESTRUCTURING DATA SERVER
  const { solRepuesto, detSolRepuesto } = dataServer && dataServer;



  // funcion para abrir el modal al dar click en guardar
  const onHandleOpenModalSave = () => {

    if (stateForm.enviarPor === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Hola", error: true }));
    }

    setState((val) => ({ ...val, showModalConfir: true, questionAction: t("WareHouseView.seguro_enviar_not_entr") }));
  };



  // funcion para modificar los valores de las piezas
  const onHandleChangeVal = (newVal = "", codDet = 0, codSol = 0) => {
    if (isNaN(newVal) || Number(newVal) < 0) {
      return;
    }

    setStateDetSolRep((val) => {
      return val.map((det) => {
        if (codDet === det.CodDetSolRepuesto && codSol === det.CodSolRepuesto) {
          return { ...det, Despachar: newVal };
        }
        return det;
      });
    });
  };




  // funcion para realizar la peticion al servidor
  const fetchDataServer = async () => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.solDespacho}/${codSolRepuesto}/search?language=${language}`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codSolRepuesto, language]);

  // useEffect para modificar el estado de los inputs
  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0) {
      setStateForm((val) => ({ ...val, tecEnviar: solRepuesto[0]?.CodEmpIngreso }));
    }
  }, [solRepuesto, codSolRepuesto]);

  // useEffect para cargar la solicitud de repuesto en estado
  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0) {
      setStateSolRep(solRepuesto);
    }
  }, [solRepuesto]);

  // useEffect para cargar el estado de las partes en el estado
  useEffect(() => {
    if (detSolRepuesto && detSolRepuesto.length > 0) {
      setStateDetSolRep(detSolRepuesto);
    }
  }, [detSolRepuesto]);


  // useEffect para buscar el contacto cuando se seleccione
  useEffect(() => {
    if (stateSolRep && stateSolRep[0]?.contactos.length > 0) {
      const foundContact = stateSolRep[0]?.contactos.find((con) => con.CodContacto.toString() === stateForm.contactoEnv);

      let numContact = "";

      if (foundContact) {
        if (foundContact?.Celular.trim() !== "") {
          numContact = foundContact?.Celular.trim();
        } else {
          numContact = foundContact?.Telefono.trim();
        }

        if (stateForm.enviarPor === "3") {
          let accion = stateSolRep[0]?.CodZona === 1 ? `Se solicita enviar piezas al cliente, atención ${foundContact?.Nombre}, Nro. Teléfono ${numContact}` : `Requesting to send parts to the client, attention to ${foundContact?.Nombre}, Phone number ${numContact}`;
          setStateForm((val) => ({ ...val, comentario: accion }));
        }

        setStateForm((val) => ({ ...val, nomContacto: foundContact?.Nombre, telefono: numContact }));

      } else {
        setStateForm((val) => ({ ...val, nomContacto: "", telefono: "" }));
      }

    }
  }, [stateForm.contactoEnv]);

  // useEffect para cambiar el comentario cuando se seleccione enviar por
  useEffect(() => {
    const LABEL_TRANSPORTE_ES = stateForm.contactoEnv ? `Se solicita enviar piezas al cliente, atención ${stateForm.nomContacto.trim()}, Nro. Teléfono ${stateForm.telefono.trim()}` : "Se solicita enviar piezas al cliente, atención , Nro";
    const LABEL_TRANSPORTE_EN = stateForm.contactoEnv ? `Requesting to send parts to the client, attention to ${stateForm.nomContacto.trim()}, Phone number ${stateForm.telefono.trim()}` : "Requesting to send parts to the client, attention to , Phone number";

    const ENVIAR_POR = {
      0: stateSolRep[0]?.CodZona === 1 ? "Enviar por DHL " : "Ship by DHL ",
      1: stateSolRep[0]?.CodZona === 1 ? "Enviar por MRW " : "Ship by MRW ",
      2: stateSolRep[0]?.CodZona === 1 ? "Enviar por Fedex " : "Ship by Fedex ",
      3: stateSolRep[0]?.CodZona === 1 ? LABEL_TRANSPORTE_ES : LABEL_TRANSPORTE_EN
    };

    setStateForm((val) => ({ ...val, comentario: ENVIAR_POR[stateForm.enviarPor] ?? "" }));
  }, [stateForm.enviarPor]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);

  // // useEffect para cambiar el comentario si cambia el contacto y el enviar por es 3
  // useEffect(() => {

  // }, [stateForm.contactoEnv]);

  // console.log(stateInputs.fecEntregaRequerida)



  // HANDLE SUBMIT
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("se envio el formulario");
  };

  return (
    <>
      {
        // toast notificacion
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        {/* FILA 1 */}
        <form onSubmit={handleSubmit}>
          <div className="row-flex">
            {/* NUM ORDEN */}
            <div className="col-8"><strong>Orden</strong></div>
            <div className="col-8"><h5>{stateSolRep[0]?.NumOrden}</h5></div>
            {/* ID SERVIDIO */}
            <div className="col-8"><strong>Id. Servicio</strong></div>
            <div className="col-8">
              <Link className="link-tag" to={`/service/service-call/${stateSolRep[0]?.CodServicio}`}>
                {stateSolRep[0]?.CodServicio}
              </Link>
            </div>
            {/* MODELO */}
            <div className="col-8"><strong>Modelo</strong></div>
            <div className="col-8"><span>{ }</span></div>
          </div>

          {/* FILA 2 */}
          <div className="row-flex">
            {/* SERIAL */}
            <div className="col-8"><strong>Serial</strong></div>
            <div className="col-8"><span>{stateSolRep[0]?.Serial}</span></div>
            {/* FECHA */}
            <div className="col-8"><strong>Fecha</strong></div>
            <div className="col-8"><span>{renderDate(stateSolRep[0]?.FecIngreso, language)}</span></div>
            {/* SUPLIDOR */}
            <div className="col-8"><strong>Suplidor</strong></div>
            <div className="col-3"><span>{stateSolRep[0]?.NomProveedor}</span></div>
          </div>

          {/* FILA 3 */}
          <div className="row-flex">
            {/* INSTALACION */}
            <div className="col-8"><strong>Instalación</strong></div>
            <div className="col-8"><span>{renderDate(stateSolRep[0]?.FecInstalacion, language)}</span></div>
            {/* FDO */}
            <div className="col-8"><strong>FDO</strong></div>
            <div className="col-8"><span>{stateSolRep[0]?.FDO}</span></div>
            {/* O/C */}
            <div className="col-8"><strong>O/C</strong></div>
            <div className="col-8"><span>{stateSolRep[0]?.OCG}</span></div>
          </div>

          {/* FILA 4 */}
          <div className="row-flex">
            {/* CLIENTE */}
            <div className="col-8"><strong>Cliente</strong></div>
            <div className="col-82"><Link className="link-tag" to={`/customers/customer-detail/${stateSolRep[0]?.CodCliente}`}>{stateSolRep[0]?.NomCliente}</Link></div>
          </div>

          {/* FILA 5 */}
          <div className="row-flex">
            {/* EQUIPO */}
            <div className="col-8"><strong>Equipo</strong></div>
            <div className="col-82"><Link className="link-tag" to={`/equipments/equipment-detail/${stateSolRep[0]?.CodEquipo}`}>{stateSolRep[0]?.Equipo}</Link></div>
          </div>

          {/* FILA 6 */}
          <div className="row-flex">
            {/* CONTRATO */}
            <div className="col-8"><strong>Contrato</strong></div>
            <div className="col-4"><span>No Posee Contrato</span></div>
            {/* PRIORIDAD */}
            <div className="col-8"><strong>Prioridad</strong></div>
            <div className="col-8 row-flex align-c">
              <span className={renderTemperature(stateSolRep[0]?.Prioridad)}></span>
            </div>
          </div>

          {/* FILA 7 */}
          <div className="row-flex">
            {/* TECNICO */}
            <div className="col-8"><strong>Técnico</strong></div>
            <div className="col-4"><span>{stateSolRep[0]?.NomEmpIngreso}</span></div>
            {/* FALLA */}
            <div className="col-8"><strong>Falla</strong></div>
            <div className="flex-auto">
              <span style={{ textTransform: 'capitalize', color: 'var(--danger-color)', textDecoration: 'underline' }}>
                {stateSolRep[0]?.Falla}
              </span>
            </div>
          </div>

          {/* FILA 8 */}
          <div className="row-flex">
            {/* ULTIMA ACCION */}
            <div className="col-8"><strong>Ultima Acción</strong></div>
            <div className="col-87">
              <span style={{ textTransform: 'capitalize', fontWeight: 'bold', color: 'var(--green-color)', textDecoration: 'underline' }}>
                {stateSolRep[0]?.LastComment?.toLowerCase()}
              </span>
            </div>
          </div>

          <hr className="m-y-small" />

          {/* FILA 9 */}
          <div className="row-flex">
            {/* TECNICO A ENVIAR */}
            <div className="col-8"><strong>Técnico a Enviar</strong></div>
            <div className="col-4">
              <select
                name="tecnicoEnivar"
                value={stateForm.tecEnviar}
                onChange={({ target }) => setStateForm((val) => ({ ...val, tecEnviar: target.value }))}>
                {
                  stateSolRep && stateSolRep[0]?.employees.map((emp) => (
                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                  ))
                }
              </select>
            </div>
          </div>

          {/* FILA 10 */}
          <div className="row-flex">
            <div className="col-8"><strong>Enviar por</strong></div>
            <div className="col-4">
              <select
                name="enviarPor"
                value={stateForm.enviarPor}
                onChange={({ target }) => setStateForm((val) => ({ ...val, enviarPor: target.value }))}>
                <option value=""></option>
                <option value="0">DHL</option>
                <option value="1">MRW</option>
                <option value="2">Fedex</option>
                <option value="3">Por Transporte</option>
              </select>
            </div>
            {/* AL */}
            <div className="col-8"><strong>Al</strong></div>
            <div className="col-4">
              <select
                name="enviarAl"
                className={stateForm.enviarPor === "" ? "wrapper" : ""}
                disabled={stateForm.enviarPor === ""}
                value={stateForm.enviarAl}
                onChange={({ target }) => setStateForm((val) => ({ ...val, enviarAl: target.value }))}>
                <option value=""></option>
                <option value="0">Técnico</option>
                <option value="1">Cliente</option>
                <option value="2">Otro</option>
              </select>
            </div>
          </div>

          {/* FILA 11 */}
          <div className="row-flex">
            {/* SELECCIONAR EL CONTACTO */}
            <div className="col-8"><strong>Seleccionar Contacto</strong></div>
            <div className="col-4">
              <select
                name="contacto"
                value={stateForm.contactoEnv}
                onChange={({ target }) => setStateForm((val) => ({ ...val, contactoEnv: target.value }))}>
                <option value=""></option>
                {
                  stateSolRep && stateSolRep[0]?.contactos.map((cont) => (
                    <option key={cont.CodContacto} value={cont.CodContacto}>{cont.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>

          {/* FILA 12 */}
          <div className="row-flex">
            {/* PERSONA CONTACTO */}
            <div className="col-8"><strong>Persona Contacto</strong></div>
            <div className="col-4">
              <input
                className="input-text-control"
                type="text"
                name="persCont"
                value={stateForm.nomContacto}
                onChange={({ target }) => setStateForm((val) => ({ ...val, nomContacto: target.value }))}
                autoComplete="off" />
            </div>
            {/* TELEFONO CONTACTO */}
            <div className="col-8"><strong>Teléfono</strong></div>
            <div className="col-4">
              <input
                className="input-text-control"
                type="text"
                name="teleCont"
                value={stateForm.telefono}
                onChange={({ target }) => setStateForm((val) => ({ ...val, telefono: target.value }))} />
            </div>
          </div>

          {/* FILA 13 */}
          <div className="row-flex">
            {/* FECHA DE ENTREGA REQUERIDA */}
            <div className="col-8"><strong>Fecha de entrega Requerida</strong></div>
            <div className="col-4">
              <input
                className="input-date-styles"
                type="date"
                name="fecRequeridaEntrega"
                value={stateForm.fecEntregaRequerida}
                onChange={({ target }) => setStateForm((val) => ({ ...val, fecEntregaRequerida: target.value }))} />
            </div>
            {/* TURNO DE ENTREGA */}
            <div className="col-8"><strong>Turno de Entrega</strong></div>
            <div className="col-4">
              <select
                name="turnoEntrega"
                value={stateForm.turnoEntrega}
                onChange={({ target }) => setStateForm((val) => ({ ...val, turnoEntrega: target.value }))}>
                <option value=""></option>
                <option value="0">Mañana</option>
                <option value="1">Tarde</option>
                <option value="2">Noche</option>
              </select>
            </div>
          </div>

          {/* FILA 14 */}
          <div className="row-flex">
            {/* COMENTARIO */}
            <div className="col-8"><strong>Comentario</strong></div>
            <div className="flex-auto">
              <textarea
                className="textarea-description"
                name="comentario"
                value={stateForm.comentario}
                onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                placeholder={language === "en" ? "Comments..." : "Comentarios..."} />
            </div>
          </div>

          {/* BOTONES DE ACCION */}
          <div className="row-flex m-y-small j-content-space-around">
            {
              detSolRepuesto && detSolRepuesto.length > 0 && (
                <>
                  <button type="button" className="btn-primary" onClick={onHandleOpenModalSave}>Solicitar</button>
                  <button type="button" className="btn-success">Stock</button>
                </>
              )
            }
            <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
          </div>

          {/* TABLA PARA MOSTRAR LAS PARTES */}
          {
            detSolRepuesto && detSolRepuesto.length > 0 && (
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>Cantidad</th>
                      <th>Nro. Parte</th>
                      <th>Descripción</th>
                      <th>Despachar</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      stateDetSolRep && stateDetSolRep.map((part, index) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td className="txt-center">{index + 1}</td>
                          <td className="txt-center">{part.Cantidad}</td>
                          <td>
                            <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>
                              {part.NumParte} {part.Alternativo ? (part.Alternativo) : null}
                            </Link>
                          </td>
                          <td>{part.Descripcion}</td>
                          <td className="col-9">
                            <input
                              className="input-text-control txt-center"
                              type="text"
                              name={`dispatch-${part.CodDetSolRepuesto}`}
                              value={part.Despachar}
                              onChange={({ target }) => onHandleChangeVal(target.value, part.CodDetSolRepuesto, part.CodSolRepuesto)}
                              autoComplete="off" />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }


          {
            state.showModalConfir && (
              <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModalConfir: false }))}>
                <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                  {/* Question mark */}
                  <div className="row-flex m-y-small align-c justify-center">
                    <div className="question-mark-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                      </svg>
                    </div>
                  </div>
                  <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                    {state.questionAction}
                  </h5>
                  {/* Botones de accion */}
                  <div className="row-flex m-y-medium j-content-space-around">
                    <button type="submit" className="btn-success" disabled={state.loadingData}>
                      {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                    </button>
                    <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModalConfir: false }))}>No</button>
                  </div>
                </div>
              </div>
            )
          }
        </form>

      </div>
    </>
  );
};