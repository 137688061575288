// componente para aprobar las herramientas

import { useEffect, useState } from "react";


// component imports
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";



// react router dom importation
import { Link, useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { useTranslation } from "react-i18next";




export const AproHerraDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");


  // GET the useNavigate
  const navigate = useNavigate();

  // GET the params
  const { reqId } = useParams();

  // state definition
  const [dataServ, setDataServ] = useState([]); // para colocar la data del servidor en el estado
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ codSol: "", idServ: "", fecSol: "", notaSal: "", fecReque: "", fecDev: "", respon: "", cliente: "", direccion: "", etapa: 0, comentario: "", action: "", CodZona: 0 });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, modalA: false, questionAction: "", loading: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });



  // destructuring data server
  const { solHerra, detSolHerra, empPerm } = dataServ && dataServ;


  // funcion para abrir el modal
  const onHandleOpenModal = (action = "") => {
    if (action === "1") { // rechazar
      setStateForm((val) => ({ ...val, action: action }));
      setState((val) => ({ ...val, modalA: true, questionAction: "Está seguro que desea rechazar la solicitud?" }));
    } else {
      setStateForm((val) => ({ ...val, action: action }));
      setState((val) => ({ ...val, modalA: true, questionAction: "Está seguro que desea aprobar la solicitud?" }));
    }
  };



  // funcion para enviar el formulario al servidor
  const onHandleSubmitForm = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.toolsApproval}/${reqId}/search?language=${language}`;

    try {
      setState((val) => ({ ...val, loading: true }));
      const response = await fetch(END_POINT, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, modalA: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      }

      setState((val) => ({ ...val, loading: false }));

    } catch (error) {
      console.log(error);
    }
  };





  // funcion para traer la data del servidor
  const fetchDataServ = async () => {

    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.toolsApproval}/${reqId}/search?language=${language}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if (solHerra && solHerra.length > 0) {
      setStateForm((val) => ({
        ...val, codSol: solHerra[0]?.CodSolHerramienta, idServ: solHerra[0]?.CodServicio, fecSol: solHerra[0]?.FecSolicitud, notaSal: solHerra[0]?.NumSalida,
        fecReque: solHerra[0]?.FecRequerida, fecDev: solHerra[0]?.FecDevolucion, respon: solHerra[0]?.NomEmpResponsable, cliente: solHerra[0]?.NomCliente,
        direccion: solHerra[0]?.DirCliente, etapa: solHerra[0]?.IdEtapa, CodZona: solHerra[0]?.CodZona
      }));
    }
  }, [solHerra]);



  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);



  // useEffect para cargar nueva data del servidor
  useEffect(() => {
    if (notification.great) {
      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };

    }
  }, [notification.great]);






  return (
    <div className="container display-data">

      {
        state.isLoading && (<LoadingComp />)
      }

      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }

      <form onSubmit={onHandleSubmitForm}>
        <div className="row-flex">
          <div className="col-8">
            <strong>{t("LogisticViewComp.request")}</strong>
          </div>
          <div className="col-5">
            <span>{stateForm.codSol}</span>
          </div>
          <div className="col-9">
            <strong>{t("LogisticViewComp.serviceID")}</strong>
          </div>
          <div className="col-5">
            <Link className="link-tag" to={`/service/service-call/${stateForm.idServ}`}>{stateForm.idServ}</Link>
          </div>
          <div className="col-8">
            <strong>{t("WareHouseView.requestDate")}</strong>
          </div>
          <div className="col-5">
            <span>{renderDate(stateForm.fecSol, language)}</span>
          </div>
        </div>

        <div className="row-flex">
          <div className="col-8">
            <strong>{t("LogisticViewComp.nota_salida")}</strong>
          </div>
          <div className="col-5">
            <span>{stateForm.notaSal}</span>
          </div>
          <div className="col-9">
            <strong>{t("WareHouseView.requiredDate")}</strong>
          </div>
          <div className="col-5">
            <span>{renderDate(stateForm.fecReque, language)}</span>
          </div>
          <div className="col-8">
            <strong>{t("serviceCard.hora_reque")}</strong>
          </div>
          <div className="col-5">
            <span></span>
          </div>
        </div>

        <div className="row-flex">
          <div className="col-8">
            <strong>{t("RequestPartsView.fec_devol")}</strong>
          </div>
          <div className="col-5">
            <span>{renderDate(stateForm.fecDev, language)}</span>
          </div>
          <div className="col-9">
            <strong>{t("TableParts.respons")}</strong>
          </div>
          <div className="col-3">
            <span>{stateForm.respon}</span>
          </div>
        </div>

        <div className="row-flex">
          <div className="col-8">
            <strong>{t("RequestPartsView.cliente")}</strong>
          </div>
          <div className="col-85-71">
            <span>{stateForm.cliente}</span>
          </div>
        </div>

        <div className="row-flex">
          <div className="col-8">
            <strong>{t("customerView.address")}</strong>
          </div>
          <div className="col-85-71">
            <span>{stateForm.direccion}</span>
          </div>
        </div>

        <div className="row-flex">
          <div className="col-8">
            <strong>{t("Viaticos.comentario")}</strong>
          </div>
          <div className="col-85-71">
            <textarea
              className="textarea-description"
              name="comment"
              value={stateForm.comentario}
              onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
              autoComplete="off" />
          </div>
        </div>

        <div className="table-container-mobile m-y-small">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>#</th>
                <th>{t("serviceCard.codigo")}</th>
                <th>{t("serviceCard.nombre")}</th>
                <th>{t("serviceCard.ubicacion")}</th>
                <th>{t("serviceCard.cant")}</th>
                <th>{t("serviceCard.imagen")}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                detSolHerra && detSolHerra.map((det) => (
                  <tr key={det.CodeIndex}>
                    <td className="txt-center">{det.CodeIndex}</td>
                    <td className="txt-center">
                      <Link className="link-tag" to={`/service/asset-request-detail/${det.CodSol}/${det.CodDetSol}`}>{det.CodHerramienta}</Link>
                    </td>
                    <td>{det.Nombre}</td>
                    <td className="txt-center">{det.Ubicacion}</td>
                    <td className="txt-center">{det.Cantidad}</td>
                    <td></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <div className="row-flex m-y-small j-content-space-around">
          {
            // si la etapa es aprobacion de salida de activo
            empPerm && ([1, 3].includes(empPerm[0]?.CodPerfil) && empPerm[0]?.Coordinador && stateForm.etapa === 181) && (
              <>
                <button type="button" className="btn-danger" onClick={() => onHandleOpenModal("1")}>{t("serviceCard.rechazar")}</button>
                <button type="button" className="btn-primary" onClick={() => onHandleOpenModal("2")}>{t("TableParts.aprobar")}</button>
              </>
            )
          }
          <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
        </div>

        {
          state.modalA && (
            <div className="background-modal">
              <div className="container-modal-confirm">
                {/* Question mark */}
                <div className="row-flex m-y-small align-c justify-center">
                  <div className="question-mark-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                    </svg>
                  </div>
                </div>
                <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                  {state.questionAction}
                </h5>
                {/* Botones de accion */}
                <div className="row-flex m-y-medium j-content-space-around">
                  <button type="submit" className="btn-success" disabled={state.loading}>
                    {state.loading ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                  </button>
                  <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, modalA: false }))}>No</button>
                </div>
              </div>
            </div>
          )
        }
      </form>

    </div>
  );
};