import React, { useEffect, useState } from "react";

// ----> Importations of useTranslation() Hook
import { useTranslation } from "react-i18next";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";


// ----> COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// ----> Styles importation
import "../../views/customers/CustomersView.css";


// ----> URLS APIS
import { urls } from "../../api-urls/api-urls";

// ----> GLOBAL FUNCTION
import { getTheToken, renderContactsByCustomer, renderContractsByCustomer } from "../../global-functions/globalFunctions";




export const CustomersView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET THE TOKEN
  const token = getTheToken().token;

  // get the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // obtener los parametros del filter
  const FILTER_INPUTS = JSON.parse(localStorage.getItem("filtCust")) ?? "";

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [filterState, setFilterState] = useState([]); // estado para filtrar las provincias
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false);
  const [generalState, setGeneralState] = useState({ reload: false, openFilter: false });
  const [stateForm, setStateForm] = useState({ customer: FILTER_INPUTS.customer ?? "", zona: FILTER_INPUTS.zona ?? "", provincia: FILTER_INPUTS.provincia ?? "" });  // ----> State for search customer input
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });

  // DESTRUCTURING DATA FROM THE SERVER
  const { customers, contracts, contacts, state, zona } = dataServer && dataServer;

  // useTranslation
  const [t] = useTranslation("global");

  const onShowFilter = () => {
    setGeneralState((val) => ({ ...val, openFilter: !generalState.openFilter }));
  };


  // funcion para manejar el submit del formulario del filtro
  const onHandleFilter = async (event) => {
    event.preventDefault();

    localStorage.setItem("filtCust", JSON.stringify(stateForm));

    try {
      const response = await fetch(`${urls.getCustomer}?language=${language}&customer=${stateForm.customer}&zona=${stateForm.zona}&provincia=${stateForm.provincia}`, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setGeneralState((val) => ({ ...val, openFilter: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: false }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para realizar el fetch al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setIsLoading(true);
      const response = await fetch(`${urls.getCustomer}?language=${language}&customer=${stateForm.customer}&zona=${stateForm.zona}&provincia=${stateForm.provincia}`, {
        headers: { "Authorization": token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setGeneralState((val) => ({ ...val, reload: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/"); // si el token venció o no está registrado
      } else if (response.status === 500) {
        // ocurrió un error interno en el servidor
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect Declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para filtrar las provincias
  useEffect(() => {
    if (state && state.length > 0) {
      const filterProv = state && state.filter((pro) => pro.CodZona.toString() === stateForm.zona);

      setFilterState(filterProv);
    }
  }, [stateForm.zona]);


  // useEffect para recargar la data para simular buscar
  useEffect(() => {
    if (generalState.reload) {
      fetchDataServer();
    }
  }, [generalState.reload]);



  return (
    <>
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.great} msg={notification.msg} />)
      }
      {/* Filtro de clientes */}
      {
        generalState.openFilter && (
          <div className="background-modal" onClick={onShowFilter}>
            <div className="container-modal-filter-customer" onClick={(event) => event.stopPropagation()}>
              <div>
                <h4 style={{ textAlign: 'center', color: 'var(--first-color)', padding: '0.5rem 0 0 0' }}>
                  {t("customerView.filterBy")}
                </h4>

              </div>
              <hr />
              <form className="p-1" onSubmit={onHandleFilter}>
                <div className="row-flex">
                  <div className="col-4"><strong>{t("navigationMenu.customer")}</strong>
                  </div>
                  <div className="col-75">
                    <input
                      placeholder={t("customerView.searchByCustomerPlaceHold")}
                      className="input-text-control"
                      type="text"
                      name="searchCustomer"
                      value={stateForm.customer}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, customer: target.value }))} />
                  </div>
                </div>
                {/* Select tag para seleccionar las regiones */}
                <div className="row-flex">
                  <div className="col-4">
                    <strong>{t("customerView.zone")}</strong>
                  </div>
                  <div className="col-75">
                    <select
                      name="area"
                      value={stateForm.zona}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, zona: target.value }))}>
                      <option value=""></option>
                      {
                        zona && zona.map((zon) => (
                          <option key={zon.CodZona} value={zon.CodZona}>{zon.NomZona}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-4">
                    <strong>{t("customerView.region")}</strong>
                  </div>
                  <div className="col-75">
                    <select
                      name="state"
                      value={stateForm.provincia}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, provincia: target.value }))}>
                      <option value=""></option>
                      {
                        filterState && filterState.map((sta) => (
                          <option key={sta.CodEstado} value={sta.CodEstado}>{sta.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="row-flex j-content-space-around m-y-medium">
                  <button type="submit" className="btn-success">Buscar</button>
                  <button type="reset" onClick={onShowFilter} className="btn-secondary">{t("customerView.btnClose")}</button>
                </div>
              </form>
            </div>
          </div>
        )
      }
      {
        isLoading && (<LoadingComp />)
      }
      <div className="display-data container">

        <ButtonSearch onOpenFilter={onShowFilter} />

        <h4 className="m-y-small">{customers && customers.length} Registro</h4>

        <div className="table-container-mobile">

          <table className="table">
            <thead className="table-head">
              <tr>
                <th>{t("customerView.taxId")}</th>
                <th>{t("customerView.name")}</th>
                <th>{t("customerView.contact")}</th>
                <th>{t("customerView.province")}</th>
                <th>{t("customerView.telephone")}</th>
                <th>{t("customerView.contract")}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                customers && customers.map(customer => (
                  <tr key={customer.CodCliente}>
                    <td>{customer.CodCliente}</td>
                    <td>
                      <Link className="link-tag" to={`/customers/customer-detail/${customer.CodCliente}`}>
                        {customer.Nombre}
                      </Link>
                    </td>
                    <td>{contacts && renderContactsByCustomer(customer.CodCliente, contacts) || "No hay contacto"}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {customer.Estado ? customer.Estado.toLowerCase() : '' || "No hay por el momento "}
                    </td>
                    <td style={{ color: 'blue', textDecoration: 'underline' }}> {customer.Telefono}</td>
                    <td>
                      <Link className="link-tag">
                        {contracts && renderContractsByCustomer(customer.CodCliente, contracts)}
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>

        </div>

      </div>
    </>
  );
};