

// ----> ESTE ARCHIVO ES PARA TRABAJAR CON LAS FUNCIONES GLOBALES




// ----> RENDER FUNCTION --> PARA CAMBIAR EL FORMATO DE LAS FECHAS
export const renderDate = (date, language) => {

  if (!date) {
    return "";
  }
  // const dateComp = date.slice(0, 10);
  // const dateArr = dateComp.split('-');

  // const monthAbbreviations = {
  //   en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  //   es: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
  // };

  // const monthIndex = parseInt(dateArr[1], 10) - 1;
  // const monthAbbrev = monthAbbreviations[language][monthIndex];

  // return `${dateArr[2]}-${monthAbbrev}-${dateArr[0]}`;

  return new Date(date).toLocaleDateString(language, { timeZone: "UTC", weekday: "short", year: "numeric", month: "short", day: "numeric" });

};


// ----> RENDER FUNCTION equipment by ID
export const renderEquipmentByCod = (codEquipment, arrEquipment) => {
  const equipment = arrEquipment ? arrEquipment.find(equ => equ.CodEquipo === codEquipment) : "Error";

  return `${equipment.Linea} - ${equipment.Modalidad} - ${equipment.TipEquipo} - ${equipment.Modelo} - ${equipment.Serial} - ${equipment.Division} - ${equipment.NumSistema}`;
};



// ----> RENDER FUNCTION BY IdEtapa
export const renderStepsByServiceIdDetail = (idEtapa, arrEtapas, language) => {
  const step = arrEtapas ? arrEtapas.filter(step => step.IdEtapa !== idEtapa) : '';

  const options = step.map(st => {
    if (language === "es") {
      return {
        value: st.IdEtapa,
        label: st.Nombre
      };
    } else {
      return {
        value: st.IdEtapa,
        label: st.NombreEn
      }
    }
  });

  return options;
};


// ----> RENDER FUNCTION BY CustomerContract
export const renderContractsByCustomer = (CodCliente, arrContratos) => {
  const contracts = arrContratos ? arrContratos.filter(contrato => contrato.CodCliente === CodCliente) : '';

  const result = contracts ? contracts : '';

  return result.map(con => (<span key={con.CodContrato} style={{ display: 'block' }}>{con.NumContrato}</span>));
};

// ----> RENDER FUNCTION CUSTOMERS BY CodCliente
export const renderCustomerByCodCliente = (CodCliente, arrCustomer) => {

  const customer = arrCustomer && arrCustomer.find(customer => customer.CodCliente === CodCliente);
  const employee = arrCustomer && arrCustomer.find(empl => empl.CodCliente === CodCliente);

  return {
    customer: customer ? customer.Nombre : "",
    codEmpResponsable: employee ? employee.CodEmpResponsable : ""
  };

};

// ----> RENDER FUNCTION BY CustomerContacts
export const renderContactsByCustomer = (CodCliente, arrContactos) => {
  const contacts = arrContactos ? arrContactos.filter(contacto => contacto.CodCliente === CodCliente) : '';

  const result = contacts ? contacts : '';

  return result.map(contact => (<span key={contact.CodContacto} style={{ display: 'block' }}>{contact.Nombre} ({contact.Correo})</span>))
};

// ----> RENDER FUNCTION FOR SERVICE TYPE
export const renderServiceType = (IdTipServicio, language) => {
  let label = "";
  switch (IdTipServicio) {
    case 1:
      language === "es" ? label = "Mantenimiento Correctivo" : label = "Corrective Maintenance";
      break;
    case 2:
      language === "es" ? label = "Mantenimiento Preventivo" : label = "Preventive Maintenance";
      break;
    case 6:
      language === "es" ? label = "Taller" : label = "Workshop";
      break;
    case 3:
      language === "es" ? label = "Instalación" : label = "Installation";
      break;
    case 4:
      label = "FMI";
      break;
    case 5:
      language === "es" ? label = "Aplicaciones" : label = "Applications";
      break;
    case 7:
      language === "es" ? label = "Reposición de Stock" : label = "Stock Replacement";
      break;
    case 8:
      language === "es" ? label = "Soporte Interno" : label = "Internal Support";
      break;
    case 9:
      language === "es" ? label = "Proyecto" : label = "Project";
      break;
    case 10:
      language === "es" ? label = "Pre Instalación" : label = "Pre-installation"
      break;
    default:
      label = "Error";
  }
  return label;
};

// RENDER TEMPERATURE
export const renderTemperature = (prioridad) => {
  let temperature = "";
  // SWITCH
  switch (prioridad) {
    case 0:
      temperature = "green-temperature";
      break;
    case 1:
      temperature = "yellow-temperature";
      break;
    case 2:
      temperature = "yellow-temperature";
      break;
    case 4:
      temperature = "red-temperature";
      break;
  }

  return temperature;
};

// renderizar la temperatura en las cards
export const renderTempCard = (prioridad = 0) => {
  const temperatura = {
    0: "border-green",
    1: "border-yellow",
    4: "border-red"
  };

  return temperatura[prioridad] ?? "";
};

// RENDER IdTipSolicitud 
export const renderIdTipSolicitud = (idTipSolicitud, language) => {
  let tipSolicitud = "";
  switch (idTipSolicitud) {
    case 0:
      tipSolicitud = language === "es" ? "Cotización" : "Quotation";
      break;
    case 1:
      tipSolicitud = language === "es" ? "Garantía" : "Warranty";
      break;
    case 2:
      tipSolicitud = language === "es" ? "Contrato Performance" : "Performance Contract";
      break;
    case 3:
      tipSolicitud = "Missing in Shipment";
      break;
    case 4:
      tipSolicitud = "Dead on Arrival";
      break;
    case 5:
      tipSolicitud = language === "es" ? "Compra" : "Buy";
      break;
  }

  return tipSolicitud;
};


// ESTA FUNCION RETORNAR EL TOKEN SETEADO EN LOCALSTORAGE
export const getTheToken = () => {
  const userInfo = localStorage.getItem("userInfo");

  const userInfoParsed = JSON.parse(userInfo);

  if (userInfoParsed) {
    return userInfoParsed
  } else {
    return "";
  }

  // return userInfoParsed;
};


// Esta funcion es para formatear las monedas
export const formatCurrency = (val, currency) => {
  const numericVal = parseFloat(val);

  if (!isNaN(numericVal) && isFinite(numericVal)) {
    return new Intl.NumberFormat('en-EN', { style: 'currency', currency: currency }).format(numericVal);
  } else {
    return val;
  }

};


// Horas de salida y regreso para los viaticos
export const HorasViaticos = [
  "12:00 am",
  "01:00 am",
  "02:00 am",
  "03:00 am",
  "04:00 am",
  "05:00 am",
  "06:00 am",
  "07:00 am",
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 pm",
  "01:00 pm",
  "02:00 pm",
  "03:00 pm",
  "04:00 pm",
  "05:00 pm",
  "06:00 pm",
  "07:00 pm",
  "08:00 pm",
  "09:00 pm",
  "10:00 pm",
  "11:00 pm"
];

// Etapas de travel expenses
export const StepTravelExpenses = [
  {
    id_etapa: 1,
    nombre: "Elaboración de anticipo",
    nombreEn: "Advance preparation"
  },
  {
    id_etapa: 2,
    nombre: "Aprobación de anticipo",
    nombreEn: "Advance approval"
  },
  {
    id_etapa: 3,
    nombre: "Asignación de pago anticipo",
    nombreEn: "Advance payment allocation"
  },
  {
    id_etapa: 4,
    nombre: "Pago del anticipo",
    nombreEn: "Advance payment"
  },
  {
    id_etapa: 5,
    nombre: "Elaboración de relación de gastos",
    nombreEn: "Expenses preparation"
  },
  {
    id_etapa: 6,
    nombre: "Aprobación de relación de gastos",
    nombreEn: "Expenses approval"
  },
  {
    id_etapa: 7,
    nombre: "Recepción de documentos de la relación",
    nombreEn: "Expenses document receive"
  },
  {
    id_etapa: 8,
    nombre: "Pago de la relación de gastos",
    nombreEn: "Expenses payment"
  },
  {
    id_etapa: 9,
    nombre: "Descuento anticipo por nómina",
    nombreEn: "Advance discount for payroll"
  },
  {
    id_etapa: 10,
    nombre: "Relación de gastos cerrada",
    nombreEn: "Expenses closed"
  }
];


// funcion para renderizar la hora
export const renderFormatTime = (date) => {
  return new Date(date).toLocaleTimeString("en-US", { timeZone: "UTC" });
};


// funcion para darle formato de moneda a los valores
export const formatVal = (newVal = "") => {

  const val = newVal.toString().replace(/[^\d.]/g, ''); // eliminar caracteres no numericos excepto el .

  const parts = val.split(".");
  // formatear la parte entera del numero con comas
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};


// funcion debounce
export const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    const context = this;
    clearTimeout(timeout);

    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
