import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../../views/error-403-view/Error403View";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// REACT TRANSLATION
import { useTranslation } from "react-i18next";




export const WareHouseViewPrincipal = () => {

  // LANGUAGE
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // navigation
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // REACT TRANSLATION
  const [t] = useTranslation("global");

  // useSearchparams
  const [params, setParams] = useSearchParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  // const [dataState, order] = useState([]);
  const [controller, setController] = useState(null); // Estado para colocar el abortcontroller
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false });
  const [area, setChangeArea] = useState(params.get("area") ?? "");

  // DESTRUCTURING DATA SERVER
  const { ordersInTransit, empPerm, conducesPendDesp, partesLlegarAlma, notaRetiro, confirRetiroPartes, salidaHerraDespa } = dataServer && dataServer;


  // Fecha de hoy
  const FecHoy = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;


  // funcion para solicitar la data del servidor
  const fetchDataServer = async (areaParams = "") => {
    const abortController = new AbortController();


    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getWarehousePrincipal}?language=${language}&zone=${params.get("area") ?? areaParams}&filt=${params.get("filt") ?? "0"}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      // console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        return setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        // error interno en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };




  // Cambiar la zona y guardar en localStorage
  const onChangeArea = (newVal) => {
    params.set("area", newVal);
    params.set("filt", 1);
    setParams(params);
    setChangeArea(newVal);
    fetchDataServer(newVal);
  };


  // useEffect declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para cargar la zona en el estad
  useEffect(() => {
    if (empPerm && params.get("area") === null) {
      setChangeArea(empPerm[0]?.CodZona);
    }
  }, [empPerm]);




  return (
    <>
      {/* Component Error 404 not authorized */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {
        state.dataVisible && (
          <div className="container display-data">
            <div className="row-flex m-y-small">
              <div className="col-7"><strong>Cambiar zona</strong></div>
              <div className="col-5">
                <select name="changeZon" value={area} onChange={({ target }) => onChangeArea(target.value)}>
                  <option value=""></option>
                  <option value="1">República Dominicana</option>
                  <option value="6">Caribe</option>
                  <option value="7">Trinidad & Tobago</option>
                </select>
              </div>
            </div>
            <h4 style={{ color: 'var(--first-color)' }}>Ordenes en tránsito a la espera por instrucciones de despacho</h4>
            {/* TABLA PARA MOSTRAR LOS DATOS (ORDENES EN TRANSITO A LA ESPERA POR INSTRUCCIONES DE DESPACHO) */}
            {
              ordersInTransit && ordersInTransit.length > 0 ? (
                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th style={{ borderRadius: '5px 0 0 0' }}>#</th>
                        <th>Temp.</th>
                        <th>{t("WareHouseView.order")}</th>
                        <th>{t("WareHouseView.requestDate")}</th>
                        <th>{t("WareHouseView.zone")}</th>
                        <th>{t("WareHouseView.customer")}</th>
                        <th style={{ borderRadius: '0 5px 0 0' }}>{t("WareHouseView.equipment")}</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        ordersInTransit && ordersInTransit.map((order) => (
                          <tr key={order.CodeIndex}>
                            <td>{order.CodeIndex}</td>
                            <td><span className={renderTemperature(order.Prioridad)}></span></td>
                            <td style={{ textAlign: 'center' }}><Link className="link-tag" to={`/warehouse/dispatch-instructions/${order.CodSolRepuesto}`}>{order.NumOrden}</Link></td>
                            <td>{renderDate(order.FecRequerida, language)}</td>
                            <td>{order.Zona}</td>
                            <td><Link className="link-tag">{order.NomCliente}</Link></td>
                            <td><Link className="link-tag">{order.Equipo}</Link></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ) : (<div><strong style={{ color: 'var(--green-color)' }}>{t("WareHouseView.noOrdersInTransit")}</strong></div>)
            }

            {/* TABLA PARA MOSTRAR LOS CONDUCES PENDIENTES POR DESPACHO */}
            <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">Conduces Pendientes por Despachar</h4>
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th style={{ borderRadius: '5px 0 0 0' }}>#</th>
                    <th>Temp.</th>
                    <th>{t("WareHouseView.request")}</th>
                    <th>{t("WareHouseView.order")}</th>
                    <th>{t("WareHouseView.serviceId")}</th>
                    <th>{t("WareHouseView.comanda")}</th>
                    <th>{t("WareHouseView.requestDate")}</th>
                    <th>{t("WareHouseView.requiredDate")}</th>
                    <th>{t("WareHouseView.zone")}</th>
                    <th>{t("WareHouseView.customer")}</th>
                    <th style={{ borderRadius: '0 5px 0 0' }}>{t("WareHouseView.equipment")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    conducesPendDesp && conducesPendDesp.map((condu, indexCon) => (
                      <tr key={indexCon}>
                        <td>{indexCon + 1}</td>
                        <td><span className={renderTemperature(condu.Prioridad)}></span></td>
                        <td><Link className="link-tag" to={`/warehouse/delivery-notes/${condu.CodSolRepuesto}`}>{condu.CodSolRepuesto}</Link></td>
                        <td><Link className="link-tag">{condu.NumOrden}</Link></td>
                        <td><Link className="link-tag">{condu.CodServicio}</Link></td>
                        <td>{ }</td>
                        <td>{ }</td>
                        <td className={condu.FecRequerida < FecHoy ? "required" : ""}>
                          {renderDate(condu.FecRequerida, language)}
                        </td>
                        <td>{condu.Zona}</td>
                        <td><Link className="link-tag">{condu.nomCliente}</Link></td>
                        <td><Link className="link-tag">{condu.equipmentName}</Link></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            {/* Ordenes pendientes por llegada de partes */}
            <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">Ordenes pendientes por llegada de partes</h4>

            {
              partesLlegarAlma && partesLlegarAlma.length > 0 ? (
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Temp.</th>
                        <th>Orden</th>
                        <th>Fecha</th>
                        <th>Zona</th>
                        <th>Cliente</th>
                        <th>Equipo</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        partesLlegarAlma && partesLlegarAlma.map((sol) => (
                          <tr key={sol.codeIndex}>
                            <td>{sol.codeIndex}</td>
                            <td className="txt-center"><span className={renderTemperature(sol.Prioridad)}></span></td>
                            <td><Link className="link-tag" to={`/warehouse/parts-arrival-detail/${sol.NumOrden}`}>{sol.NumOrden}</Link></td>
                            <td>{renderDate(sol.Fecha, language)}</td>
                            <td>{language === "en" ? sol.ZonaEn : sol.Zona}</td>
                            <td><Link className="link-tag">{sol.nomCliente}</Link></td>
                            <td><Link className="link-tag">{sol.equipment}</Link></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ) : (<div><strong style={{ color: 'var(--green-color)' }}>No hay ordenes pendientes por llegar</strong></div>)
            }

            {/* Notas de retiro por elaborar */}
            <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">Notas de retiro por elaborar</h4>

            {/* Tabla para mostrar los datos */}
            {
              notaRetiro && notaRetiro.length > 0 ? (
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Temp.</th>
                        <th>Nota de entrega</th>
                        <th>Id Servicio</th>
                        <th>Fecha solicitud</th>
                        <th>Parte</th>
                        <th>Cliente</th>
                        <th>Zona</th>
                        <th>Equipo</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        notaRetiro && notaRetiro.map((not) => (
                          <tr key={not.codeIndex}>
                            <td>{not.codeIndex}</td>
                            <td><span className={renderTemperature(not.Prioridad)}></span></td>
                            <td className="txt-center"><Link className="link-tag" to={`/warehouse/withdrawal-detail/${not.CodSolRepuesto}`}>{not.CodNotEntrega}</Link></td>
                            <td className="txt-center"><Link className="link-tag">{not.CodServicio}</Link></td>
                            <td>{renderDate(not.fecIngreso, language)}</td>
                            <td>{not.Parte}</td>
                            <td><Link className="link-tag">{not.nomCliente}</Link></td>
                            <td>{language === "en" ? not.ZonaEn : not.Zona}</td>
                            <td>{not.nomEquip}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ) : (<div><strong style={{ color: 'var(--green-color)' }}>No hay ordenes pendientes por llegar</strong></div>)
            }


            {
              // confirmar retiro de partes
              confirRetiroPartes && confirRetiroPartes.length > 0 && (<h4 style={{ color: 'var(--first-color)' }} className="m-y-small">Confirmar retiro de partes ({confirRetiroPartes.length})</h4>)
            }


            {
              confirRetiroPartes && confirRetiroPartes.length > 0 && (
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Temp.</th>
                        <th>Nota de entrega</th>
                        <th>Id. Servicio</th>
                        <th>Fecha solicitud</th>
                        <th>Parte</th>
                        <th>Cliente</th>
                        <th>Zona</th>
                        <th>Equipo</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        confirRetiroPartes && confirRetiroPartes.map((part) => (
                          <tr key={part.codeIndex}>
                            <td>{part.codeIndex}</td>
                            <td>{part.Prioridad}</td>
                            <td className="txt-center">
                              <Link className="link-tag" to={`/warehouse/withdrawal-part-detail/${part.CodNotRetiro}`}>{part.CodNotRetiro}</Link>
                            </td>
                            <td className="txt-center">
                              <Link className="link-tag" to={`/service/service-call/${part.CodServicio}`}>{part.CodServicio}</Link>
                            </td>
                            <td>{renderDate(part.FecIngreso, language)}</td>
                            <td>{part.Parte}</td>
                            <td>
                              <Link className="link-tag">{part.NomCliente}</Link>
                            </td>
                            <td>{part.Zona}</td>
                            <td>{part.NomEquipo}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            }

            {
              // 
              salidaHerraDespa && salidaHerraDespa.length > 0 && (<h4 style={{ color: 'var(--first-color)' }} className="m-y-small">Notas de salida de herramientas pendientes por despachar ({salidaHerraDespa.length})</h4>)
            }

            {
              salidaHerraDespa && salidaHerraDespa.length > 0 && (
                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Temp.</th>
                        <th>Solicitud</th>
                        <th>ID</th>
                        <th>Fecha requerida</th>
                        <th>Fecha devolución</th>
                        <th>Zona</th>
                        <th>Responsable</th>
                        <th>Cliente</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        salidaHerraDespa && salidaHerraDespa.map((sal) => (
                          <tr key={sal.CodSolHerramienta}>
                            <td className="txt-center">
                              <div className="row-flex align-c justify-center">
                                <span className={renderTemperature(sal.Prioridad)}></span>
                              </div>
                            </td>
                            <td className="txt-center">
                              <Link className="link-tag" to={`/warehouse/tool-exit/${sal.CodSolHerramienta}`}>{sal.CodSolHerramienta}</Link>
                            </td>
                            <td className="txt-center">
                              <Link className="link-tag" to={`/service/service-call/${sal.CodServicio}`}>{sal.CodServicio}</Link>
                            </td>
                            <td>{renderDate(sal.FecRequerida, language)} {sal.Turno}</td>
                            <td>{renderDate(sal.FecDevolucion, language)}</td>
                            <td>{sal.Zona}</td>
                            <td>{sal.NomEmpleado}</td>
                            <td>{sal.NomCliente}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            }

            {/* salidaHerraDespa */}

          </div>
        )
      }
    </>
  );
};