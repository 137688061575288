// Este controlador es para poder crear ordenes de compra para comercial, esto es un modulo nuevo
import React, { useEffect, useState } from "react";


// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// React router dom importation
import { useLocation, useNavigate } from "react-router-dom";

// Urls importation
import { urls } from "../../api-urls/api-urls";

// Global function importation
import { getTheToken } from "../../global-functions/globalFunctions";




export const CreateOrderCommercial = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the navigate 
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // State definition
  const [dataServer, setDataServer] = useState([]); // Estado para cargar la data del servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, serverError: false, unauthorized: false, showModal: false, showModalCusto: false, showModalSupp: false });
  const [stateForm, setStateForm] = useState({ codCliente: "", nomCliente: "", codProveedor: "", nomProveedor: "", incoterm: "", facturarA: "", lugarEntrega: "", observa: "", moneda: "", codEmpResponsable: "" });
  const [addPart, setAddPart] = useState([]);


  // Destruturing data server
  const { customer, supplier, employees } = dataServer && dataServer;



  // Encontrar un cliente cuando escriban
  const customerFound = customer && customer.filter((cust) => cust.Nombre.toLowerCase().includes(stateForm.nomCliente.toLowerCase()));


  // Encontrar un suplidor cuando se escriba
  const supplierFound = supplier && supplier.filter((sup) => sup.Nombre.toLowerCase().includes(stateForm.nomProveedor.toLowerCase()));


  // useEffect para traer la data necesaria del servidor
  useEffect(() => {
    // Funcion para realizar el fetch de datos
    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.commercialOrderProj}?language=${language}&initialData=yes`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        }

        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();
  }, [language]);

  // useEffect para colocar el nombre del cliente en el estado
  useEffect(() => {
    if (customer && customer.length > 0) {
      // Buscar el nombre del cliente
      const findCustomer = customer.find((custo) => custo.CodCliente === stateForm.codCliente);

      setStateForm((val) => ({ ...val, nomCliente: findCustomer?.Nombre }));

      setState((val) => ({ ...val, showModalCusto: false }));
    }
  }, [stateForm.codCliente]);

  // useEffect para colocar el nombre del proveedor en el estado
  useEffect(() => {
    if (supplier && supplier.length > 0) {
      // Buscar el nombre del suplidor
      const findSupplier = supplier.find((sup) => sup.CodProveedor.toString() === stateForm.codProveedor);

      setStateForm((val) => ({ ...val, nomProveedor: findSupplier.Nombre }));

      setState((val) => ({ ...val, showModalSupp: false }));
    }
  }, [stateForm.codProveedor]);



  return (
    <>
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <div className="card-shadow">
                <div className="row-flex">
                  {/* Etapa */}
                  <div className="col-7"><strong>Etapa</strong></div>
                  <div className="col-3"><h5 className="active-class">Orden de compra</h5></div>
                </div>
                <form>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Solicitud */}
                    <div className="col-7"><strong>Solicitud</strong></div>
                    <div className="col-7"><h4></h4></div>
                    {/* Incoterm */}
                    <div className="col-7"><strong>Incoterm</strong></div>
                    <div className="col-7">
                      <select name="incoterm" value={stateForm.incoterm} onChange={({ target }) => setStateForm((val) => ({ ...val, incoterm: target.value }))}>
                        <option value=""></option>
                        <option value="CIF">CIF</option>
                        <option value="FOB">FBO</option>
                      </select>
                    </div>
                    {/* Orden dynamic */}
                    <div className="col-7"><strong>Orden Dynamics</strong></div>
                    <div className="col-4"><span></span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Fecha solicitud */}
                    <div className="col-7"><strong>Fecha solicitud</strong></div>
                    <div className="col-7"><span>21-Sep-2023</span></div>
                    {/* Moneda */}
                    <div className="col-7"><strong>Moneda</strong></div>
                    <div className="col-7">
                      <select name="currency" value={stateForm.moneda} onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                        <option value=""></option>
                        <option value="1">USD</option>
                        <option value="2">RD</option>
                      </select>
                    </div>
                    {/* Lugar de entrega */}
                    <div className="col-7"><strong>Lugar de entrega</strong></div>
                    <div className="col-4">
                      <input
                        className="input-text-control"
                        type="text"
                        name="deliveryPlac"
                        value={stateForm.lugarEntrega}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, lugarEntrega: target.value }))}
                        autoComplete="off" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Terminos GE */}
                    <div className="col-7"><strong>Términos GE</strong></div>
                    <div className="col-7">
                      <select name="terms">
                        <option value=""></option>
                        <option value="">5% Prior to production; 95% Prior to shipment</option>
                        <option value="">otros</option>
                      </select>
                    </div>
                    {/* Terminos Cliente */}
                    <div className="col-7"><strong>Terminos cliente</strong></div>
                    <div className="col-7">
                      <input
                        className="input-text-control"
                        type="text"
                        name="termCusto" />
                    </div>
                    {/* Empleado responsable */}
                    <div className="col-7"><strong>Responsable</strong></div>
                    <div className="col-4">
                      <select name="responsible" value={stateForm.codEmpResponsable} onChange={({ target }) => setStateForm((val) => ({ ...val, codEmpResponsable: target.value }))}>
                        <option value=""></option>
                        {
                          employees && employees.map((emp) => (
                            <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Cliente */}
                    <div className="col-7"><strong>Cliente</strong></div>
                    <div className="col-28-58">
                      <input
                        className="input-text-control"
                        type="text"
                        name="custo"
                        value={stateForm.nomCliente}
                        onFocus={() => setState((val) => ({ ...val, showModalCusto: true }))}
                        placeholder="Click acá para seleccionar el cliente"
                        readOnly />
                    </div>
                    {/* Contacto */}
                    <div className="col-7"><strong>Contacto</strong></div>
                    <div className="col-7">
                      <input
                        className="input-text-control"
                        type="text"
                        name="contact" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Proveedor */}
                    <div className="col-7"><strong>Proveedor</strong></div>
                    <div className="col-28-58">
                      <input
                        className="input-text-control"
                        type="text"
                        name="suppli"
                        value={stateForm.nomProveedor}
                        onFocus={() => setState((val) => ({ ...val, showModalSupp: true }))}
                        placeholder="Click acá para seleccionar el proveedor"
                        readOnly />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Facturar a */}
                    <div className="col-7"><strong>Facturar a</strong></div>
                    <div className="col-28-58">
                      <input
                        className="input-text-control"
                        type="text"
                        name="billTo"
                        value={stateForm.facturarA}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, facturarA: target.value }))}
                        autoComplete="off" />
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Observaciones */}
                    <div className="col-7"><strong>Observaciones</strong></div>
                    <div className="col-28-58">
                      <textarea
                        className="textarea-description"
                        name="obser"
                        value={stateForm.observa}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, observa: target.value }))} />
                    </div>
                  </div>

                  <hr className="m-y-small" />

                  {/* Botones de acción */}
                  <div className="row-flex m-y-medium j-content-space-around">
                    <button type="button" className="btn-primary">Guardar</button>
                    <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Cancelar</button>
                  </div>

                </form>
              </div>

              {/* Modal para seleccionar el cliente */}
              {
                state.showModalCusto && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModalCusto: false }))}>
                    <div className="container-modal-supplier" onClick={(event) => event.stopPropagation()}>
                      <div className="row-flex m-y-small">
                        <input
                          className="input-text-control"
                          type="text"
                          name="searchCusto"
                          value={stateForm.nomCliente}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, nomCliente: target.value }))}
                          autoComplete="off" />

                        {/* Lista de suplidores */}
                        <ul className="list-supplier">
                          {
                            customerFound && customerFound.map((sup) => (
                              <li key={sup.CodCliente} id={sup.CodCliente} onClick={({ target }) => setStateForm((val) => ({ ...val, codCliente: target.id }))}>{sup.Nombre}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              }

              {/* Modal para seleccionar el proveedor */}
              {
                state.showModalSupp && (
                  <div className="background-modal">
                    <div className="container-modal-supplier">
                      <div className="row-flex m-y-small">
                        <input
                          className="input-text-control"
                          type="text"
                          name="searchProvee"
                          value={stateForm.nomProveedor}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, nomProveedor: target.value }))}
                          autoComplete="off" />

                        {/* Lista de los proveedores */}
                        <ul className="list-supplier">
                          {
                            supplierFound && supplierFound.map((sup) => (
                              <li key={sup.CodProveedor} id={sup.CodProveedor} onClick={({ target }) => setStateForm((val) => ({ ...val, codProveedor: target.id }))}>{sup.Nombre}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </>
  );
};