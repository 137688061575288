// componente para ver el detalle de las herramientas solicitadas

import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { SelectHerramienta } from "../../components/serviceComponents/SelectHerramienta";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { InfoHerramienta } from "../../components/serviceComponents/InfoHerramienta";



// React router dom import
import { Link, useNavigate, useParams } from "react-router-dom";


// react translation import
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";







export const AssetRequestDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useParams
  const { reqId } = useParams();


  // state definition
  const [dataServ, setDataServ] = useState([]); // estado para cargar la data del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ fecReque: "", hora: "", devolucion: "", envio: "", direccion: "", comentario: "", ciudad: 0, estado: 0, cliente: "", codServ: 0, numSalida: 0, solicitud: 0, fecSol: "", action: "", empIng: 0, codZona: 0 });
  const [errors, setErrors] = useState({ fecRequerida: "", envio: "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "", unauthorized: false, modalA: false, etapa: 0, nomCliente: "", direccion: "", descripcion: "", nomRespon: "", modalB: false, codHerra: "", modalC: false, loading: false, questionAction: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // destructuring data server
  const { solHerra, detSolHerra, empPerm } = dataServ && dataServ;


  const SOL_HERRA = 171;


  const NOM_HORA = {
    0: t("RequestPartsView.en_la_ma"),
    1: t("RequestPartsView.en_la_tar")
  };

  Object.seal(NOM_HORA);


  // funcion para mostrar mensajes de error
  const onHandleBlur = (event) => {
    const { name, value } = event.target;

    if (value.trim() === "") {
      setErrors((val) => ({ ...val, [name]: t("LogisticViewComp.campo_vacio") }));
    } else {
      setErrors((val) => ({ ...val, [name]: "" }));
    }
  };


  // funcion para cerrar el modal de seleccionar las partes
  const onCloseModal = () => {
    setState((val) => ({ ...val, modalA: false }));
  };

  // funcion para cerrar el modal de ver la informacion de la herramienta
  const onCloseModalB = () => {
    setState((val) => ({ ...val, modalB: false, codHerra: "" }));
  };

  // funcion para el mensaje del hijo
  const onShowMsgFromSon = (error = false, msg = "") => {
    if (error) {
      setNotification((val) => ({ ...val, show: true, msg: msg, error: true }));
    } else {
      setNotification((val) => ({ ...val, show: true, msg: msg, great: true, success: true }));
    }
  };



  // funcion para abrir el modal de guardar
  const onHandleSaveModal = () => {
    setStateForm((val) => ({ ...val, action: "1" })); // guardar
    setState((val) => ({ ...val, modalC: true, questionAction: t("Viaticos.seguro_guardar") }));
  };

  // funcion para eliminar la solicitud
  const onHandleDeleteModal = () => {
    setStateForm((val) => ({ ...val, action: "2" })); // eliminar
    setState((val) => ({ ...val, modalC: true, questionAction: "Está seguro que desea eliminar esta solicitud?" }));
  };


  // funcion para enviar a aprobacion y abrir el modal
  const onHandleSendModal = () => {
    setStateForm((val) => ({ ...val, action: "3" })); // enviar aprobacion
    setState((val) => ({ ...val, modalC: true, questionAction: "Está seguro que desea enviar a aprobacion?" }));
  };



  // funcion para guardar los datos del formulario
  const onHandleSaveForm = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loading: true }));
      const response = await fetch(`${urls.requestTools}/${reqId}/search?action=3&language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, modalC: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) { }

      setState((val) => ({ ...val, loading: false }));

    } catch (error) {
      console.log(error);
    }
  };




  // funcion para solicitar los datos al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.requestTools}/${reqId}/search?language=${language}&action=2`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para cargar los datos en el estado
  useEffect(() => {
    if (solHerra && solHerra.length > 0) {
      let FecRequerida = "";
      let FecDevolucion = "";

      if (solHerra[0]?.IdEtapa === SOL_HERRA) {
        FecRequerida = solHerra[0]?.FecRequerida ? solHerra[0]?.FecRequerida.slice(0, 10) : "";
        FecDevolucion = solHerra[0]?.FecDevolucion ? solHerra[0]?.FecDevolucion.slice(0, 10) : "";
      } else {
        FecRequerida = solHerra[0]?.FecRequerida;
        FecDevolucion = solHerra[0]?.FecDevolucion;
      }

      setStateForm((val) => ({
        ...val, fecReque: FecRequerida, numSalida: solHerra[0]?.NumSalida, solicitud: solHerra[0]?.CodSolHerramienta, fecSol: solHerra[0]?.FecSolicitud,
        codServ: solHerra[0]?.CodServicio, devolucion: FecDevolucion, envio: solHerra[0]?.IdEnvio, direccion: solHerra[0]?.Descripcion ?? "", hora: solHerra[0]?.HorRequerida ?? "",
        ciudad: solHerra[0]?.CodCiudad, estado: solHerra[0]?.CodEstado, cliente: solHerra[0]?.CodCliente, empIng: solHerra[0]?.CodEmpIngreso, codZona: solHerra[0]?.CodZona
      }));// 15032 solicitud

      setState((val) => ({
        ...val, etapa: solHerra[0]?.IdEtapa, nomCliente: solHerra[0]?.NomCliente, direccion: solHerra[0]?.DirCliente, descripcion: solHerra[0]?.Descripcion,
        nomRespon: solHerra[0]?.NomEmpResponsable
      }));
    }
  }, [solHerra]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para recargar la data del servidor
  useEffect(() => {
    if (notification.success) {
      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };

    }
  }, [notification.success]);




  return (
    <div className="container display-data">
      {
        // componente para ver la informacion de
        state.modalB && (<InfoHerramienta onClose={onCloseModalB} codHerr={state.codHerra} token={getTheToken().token} lang={language} />)
      }
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.success} error={notification.error} msg={notification.msg} />)
      }
      {
        // componente para seleccionar las herramientas
        state.modalA && (<SelectHerramienta onClose={onCloseModal} lang={language} codSol={reqId} token={getTheToken().token} onNotify={onShowMsgFromSon} />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      {
        !state.dataVisible && state.msg && (
          <>
            <h4 style={{ color: "var(--danger-color)" }}>{state.msg}</h4>
            <div className="row-flex justify-center m-y-small">
              <button type="button" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
            </div>
          </>
        )
      }

      {
        // error 403 component
        state.unauthorized && (<Error403View />)
      }

      {
        state.dataVisible && (
          <form onSubmit={onHandleSaveForm}>
            <div className="row-flex">
              <div className="col-8">
                <strong>{t("LogisticViewComp.request")}</strong>
              </div>
              <div className="col-4">
                <h5>{stateForm.solicitud}</h5>
              </div>
              <div className="col-9">
                <strong>{t("LogisticViewComp.serviceID")}</strong>
              </div>
              <div className="col-5">
                <h5>{stateForm.codServ}</h5>
              </div>
              <div className="col-9">
                <strong>{t("WareHouseView.requestDate")}</strong>
              </div>
              <div className="col-7">
                <span>{renderDate(stateForm.fecSol, language)}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("LogisticViewComp.nota_salida")}</strong>
              </div>
              <div className="col-4">
                <h5>{stateForm.numSalida}</h5>
              </div>

              <div className="col-9">
                <strong>{t("WareHouseView.requiredDate")}</strong>
                {
                  state.etapa === SOL_HERRA && (<strong className="required">(*)</strong>)
                }
              </div>
              <div className="col-5">
                {
                  state.etapa === SOL_HERRA && (
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecRequerida"
                      onBlur={onHandleBlur}
                      value={stateForm.fecReque}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fecReque: target.value }))} />
                  )
                }
                {
                  state.etapa > SOL_HERRA && (
                    <span>{renderDate(stateForm.fecReque, language)}</span>
                  )
                }
                {errors.fecRequerida && (<p className="fade-animation" style={{ margin: 0, color: "var(--danger-color)" }}><span>{errors.fecRequerida}</span></p>)}
              </div>
              <div className="col-9">
                <strong>{t("serviceCard.hora_reque")}</strong>
              </div>
              <div className="col-7">
                {
                  state.etapa === SOL_HERRA && (
                    <select
                      name="horaRequerida"
                      value={stateForm.hora}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, hora: target.value }))}>
                      <option value=""></option>
                      <option value="0">{t("RequestPartsView.en_la_ma")}</option>
                      <option value="1">{t("RequestPartsView.en_la_tar")}</option>
                    </select>
                  )
                }
                {
                  state.etapa > SOL_HERRA && (
                    <span>{NOM_HORA[stateForm.hora] ?? t("RequestPartsView.no_especificado")}</span>
                  )
                }
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("RequestPartsView.fec_devol")}</strong>
              </div>
              <div className="col-4">
                {
                  state.etapa === SOL_HERRA && (
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecDevolucion"
                      value={stateForm.devolucion}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, devolucion: target.value }))} />
                  )
                }
                {
                  state.etapa > SOL_HERRA && (<span>{renderDate(stateForm.devolucion, language)}</span>)
                }
              </div>
              <div className="col-9">
                <strong>{t("TableParts.respons")}</strong>
              </div>
              <div className="col-5">
                <span>{state.nomRespon}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("RequestPartsView.cliente")}</strong>
              </div>
              <div className="col-49-17">
                <span>{state.nomCliente}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("customerView.address")}</strong>
              </div>
              <div className="col-49-17">
                <span>{state.direccion}</span>
              </div>
            </div>

            {
              state.etapa === SOL_HERRA && (
                <div className="row-flex">
                  <div className="col-8">
                    <strong>{t("RequestPartsView.envio")}</strong>
                    <strong className="required">(*)</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="envio"
                      value={stateForm.envio}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, envio: target.value }))}
                      onBlur={onHandleBlur}>
                      <option value=""></option>
                      <option value="0">DHL</option>
                      <option value="1">MRW</option>
                      <option value="2">Fedex</option>
                      <option value="3">Personal</option>
                      <option value="4">{t("RequestPartsView.cliente")}</option>
                    </select>
                    {errors.envio && (<p className="fade-animation" style={{ margin: 0, color: "var(--danger-color)" }}><span>{errors.envio}</span></p>)}
                  </div>
                </div>
              )
            }

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("RequestPartsView.direc_envio")}</strong>
              </div>
              <div className="col-85-71">
                {
                  state.etapa === SOL_HERRA && (
                    <textarea
                      className="textarea-description"
                      name="dirEnvio"
                      placeholder={language === "en" ? "Place the shipping address here..." : "Coloque la dirección de envío aquí..."}
                      value={stateForm.direccion}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, direccion: target.value }))}
                      autoComplete="off" />
                  )
                }
                {
                  state.etapa > SOL_HERRA && (<span>{state.descripcion}</span>)
                }
              </div>
            </div>

            {
              state.etapa === SOL_HERRA && (
                <div className="row-flex">
                  <div className="col-8">
                    <strong>{t("Viaticos.comentario")}</strong>
                  </div>
                  <div className="col-85-71">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={stateForm.comentario}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>
              )
            }

            {
              state.etapa === SOL_HERRA && (
                <div className="row-flex">
                  <div className="col-8">
                    <strong>{t("serviceCard.agregar_herra")}</strong>
                  </div>
                  <div className="col-10">
                    <button type="button" style={{ cursor: "pointer" }} onClick={() => setState((val) => ({ ...val, modalA: true }))}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                        <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                      </svg>
                    </button>
                  </div>
                </div>
              )
            }

            {
              detSolHerra && detSolHerra.length > 0 && (
                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Ubicación</th>
                        <th>Cant</th>
                        <th>Imagen</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        detSolHerra && detSolHerra.map((detHerra) => (
                          <tr key={detHerra.CodeIndex}>
                            <td className="txt-center">{detHerra.CodeIndex}</td>
                            <td>
                              <Link className="link-tag" to={`/service/asset-request-detail/${detHerra.CodSol}/${detHerra.CodDetSol}`}>{detHerra.CodHerramienta}</Link>
                            </td>
                            <td>{detHerra.Nombre}</td>
                            <td className="txt-center">{detHerra.Ubicacion}</td>
                            <td className="txt-center">{detHerra.Cantidad}</td>
                            <td className="txt-center">
                              <button type="button" className="btn-review" onClick={() => setState((val) => ({ ...val, codHerra: detHerra.CodHerramienta, modalB: true }))}>{t("serviceCard.ver")}</button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            }

            <div className="row-flex j-content-space-around m-y-medium">
              {
                empPerm && (state.etapa === SOL_HERRA && stateForm.empIng === empPerm[0]?.CodEmpleado) && (
                  <>
                    <button type="button" className="btn-primary" onClick={onHandleSaveModal}>{t("newCallComp.save")}</button>
                    <button type="button" className="btn-danger" onClick={onHandleDeleteModal}>{t("RequestPartsView.eliminar")}</button>
                    <button type="button" className="btn-success" onClick={onHandleSendModal}>{t("RequestPartsView.enviar")}</button>
                  </>
                )
              }
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
            </div>

            {
              state.modalC && (
                <div className="background-modal">
                  <div className="container-modal-confirm">
                    {/* Question mark */}
                    <div className="row-flex m-y-small align-c justify-center">
                      <div className="question-mark-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                        </svg>
                      </div>
                    </div>
                    <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                      {state.questionAction}
                    </h5>
                    {/* Botones de accion */}
                    <div className="row-flex m-y-medium j-content-space-around">
                      <button type="submit" className="btn-success" disabled={state.loading}>
                        {state.loading ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                      </button>
                      <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, modalC: false }))}>No</button>
                    </div>
                  </div>
                </div>
              )
            }
          </form>
        )
      }
    </div>
  );
};