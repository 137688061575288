import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.css';

// ----> Importation from i18next
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

// ----> Importations of translations
import global_spanish from "./translations/spanish/global.json";
import global_english from "./translations/english/global.json";

const lang = window.navigator.language.slice(0,2);

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("language") || lang,
  resources: {
    es: {
      global: global_spanish
    },
    en: {
      global: global_english
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

