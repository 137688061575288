// componente para ver la informacion de la herramienta

import { useEffect, useState } from "react";


// react router import
import { useNavigate } from "react-router-dom";


// react translation import
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";
import { formatCurrency, renderDate } from "../../global-functions/globalFunctions";






export const InfoHerramienta = ({ codHerr, onClose, token, lang }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  // state definition
  const [herra, setHerra] = useState([]);
  const [controller, setController] = useState(null);// estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "" });


  const NomTipo = {
    0: "Herramienta",
    1: "Suministro",
    2: "Kit"
  };

  Object.seal(NomTipo);

  const NomCalibracion = {
    1: "Mensual",
    2: "Bimestral",
    3: "Trimestral",
    6: "Semestral",
    12: "Anual"
  };

  Object.seal(NomCalibracion);

  const NomPerRevision = {
    1: "Mensual",
    2: "Bimestral",
    3: "Trimestral",
    4: "Semestral",
    5: "Anual"
  };

  Object.seal(NomPerRevision);


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.requestTools}?num_herra=${codHerr}&language=${lang}`, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setHerra(data?.herraInfo);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la informacion al servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);







  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h5>Estas consultando ( {codHerr} ) {herra[0]?.Nombre}</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          {
            state.isLoading && (<h5>{t("LoginView.cargando")}</h5>)
          }
          {
            !state.dataVisible && state.msg && (<h5>{state.msg}</h5>)
          }

          {
            state.dataVisible && (
              <>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Código</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.CodHerramienta}</span>
                  </div>
                  <div className="col-7">
                    <strong>Nro. Parte</strong>
                  </div>
                  <div className="col-3">
                    <span>{herra[0]?.NumParte}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Descripción</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.Nombre}</span>
                  </div>
                  <div className="col-7">
                    <strong>Tipo</strong>
                  </div>
                  <div className="col-3">
                    <span>{NomTipo[herra[0]?.IdTipo]}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>División</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.CodDivision}</span>
                  </div>
                  <div className="col-7">
                    <strong>Linea</strong>
                  </div>
                  <div className="col-3">
                    <span>{herra[0]?.CodLinea}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Modalidad</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.CodModalidad}</span>
                  </div>
                  <div className="col-7">
                    <strong>Serial</strong>
                  </div>
                  <div className="col-3">
                    <span>{herra[0]?.Serial}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Orden de compra</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.OrdCompra}</span>
                  </div>
                  <div className="col-7">
                    <strong>Fecha de adquisición</strong>
                  </div>
                  <div className="col-3">
                    <span>{renderDate(herra[0]?.FecAdquisicion, lang)}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Proveedor</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.NomProve}</span>
                  </div>
                  <div className="col-7">
                    <strong>Precio</strong>
                  </div>
                  <div className="col-3">
                    <span>{formatCurrency(herra[0]?.Precio, "USD")}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Dimensión (cm)</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.Dimension}</span>
                  </div>
                  <div className="col-7">
                    <strong>Peso (Kg)</strong>
                  </div>
                  <div className="col-3">
                    <span>{herra[0]?.Peso}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Fecha de calibración</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{renderDate(herra[0]?.FecCalibracion, lang)}</span>
                  </div>
                  <div className="col-7">
                    <strong>Período de calibración</strong>
                  </div>
                  <div className="col-3">
                    <span>{NomCalibracion[herra[0]?.PerCalibracion]}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Fecha de revisión</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{renderDate(herra[0]?.FecRevision)}</span>
                  </div>
                  <div className="col-7">
                    <strong>Período de revisión</strong>
                  </div>
                  <div className="col-3">
                    <span>{NomPerRevision[herra[0]?.PerRevision]}</span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Ubicación</strong>
                  </div>
                  <div className="col-36-58">
                    <span>{herra[0]?.Ubicacion}</span>
                  </div>
                  <div className="col-7">
                    <strong>Observaciones</strong>
                  </div>
                  <div className="col-3">
                    <span></span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Foto</strong>
                  </div>
                  <div className="col-36-58">
                    {
                      herra[0]?.Foto && (
                        <img className="tool-image" src={`data:image/jpeg;base64,${herra[0]?.Foto}`} alt="Tool" />
                      )
                    }
                  </div>
                </div>
              </>
            )
          }

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="button" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};