
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// REACT TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// global functions import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";


export const ToolsView = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();

  // REACT TRANSLATION
  const [t] = useTranslation("global");


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // estado para almacenar la data que viene del servidor
  const [isLoading, setIsLoading] = useState(false);


  // DESTRUCTURING DATA SERVER
  const { allTools } = dataServer && dataServer;


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(`${urls.tools}?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [language]);


  return (
    <>
      {
        isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        <div className="table-container-mobile">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th style={{ borderRadius: '5px 0 0 0' }}>{t("ToolsView.code")}</th>
                <th>{t("ToolsView.name")}</th>
                <th>{t("ToolsView.modality")}</th>
                <th>{t("ToolsView.partNum")}</th>
                <th>{t("ToolsView.lastCalibration")}</th>
                <th>Status</th>
                <th style={{ borderRadius: '0 5px 0 0' }}>{t("ToolsView.location")}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                allTools && allTools.map((tool, index) => (
                  <tr key={index}>
                    <td>{tool.CodHerramienta}</td>
                    <td><Link className="link-tag" to={`/warehouse/tools-detail/${tool.CodHerramienta}`}>{tool.Nombre}</Link></td>
                    <td>{tool.Modalidad}</td>
                    <td>{tool.NumParte}</td>
                    <td>{renderDate(tool.FecCalibracion, language)}</td>
                    <td><span className={tool.Activo ? "completed" : "inactive"}>{tool.status}</span></td>
                    <td>{tool.Ubicacion}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};