// componente para crear la relacion de gastos


// react router import
import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// react router import
import { useLocation, useNavigate } from "react-router-dom";

// react translation import
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";

// global function import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";



export const RelacionGastoNueva = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data en el estado
  const [filteredGerencia, setFilteredGerencia] = useState([]); // estado para filtrar la gerencia
  const [filteredUnidad, setFilteredUnidad] = useState([]); // estado para filtrar la unidad
  const [controller, setController] = useState(null); // estado para someter el controlador para abortar la peticion al servidor
  const [stateForm, setStateForm] = useState({ empresa: "", division: "", gerencia: "", areaFun: "", solicitante: "", fecSol: "", fecReq: "", formPago: "", transNom: "", numCuenta: "", moneda: "", comment: "", primaryAction: "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false, questionAction: "" });
  const [notification, setNotification] = useState({ show: true, msg: "", error: false, great: false, succes: false, codSolG: 0 });


  // destructuring dataServer
  const { grupo, gerencia, unidad, userInfo, fecSol } = dataServer && dataServer;


  // funcion para guardar crear la relacion de gastos
  const onSaveOpenModal = () => {
    // verificar los errores

    if (stateForm.empresa === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe inidicar la empresa a la que pertenece.", error: true }));
    }

    if (stateForm.division === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "La división no puede estar vacía.", error: true }));
    }

    if (stateForm.gerencia === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "La gerencia no puede estar vacía", error: true }));
    }

    if (stateForm.fecReq === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar cuál es la fecha requerida.", error: true }));
    }

    if (stateForm.formPago === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar cuál es la forma de pago.", error: true }));
    }

    if (stateForm.moneda === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la moneda.", error: true }));
    }

    setState((val) => ({ ...val, showModal: true, questionAction: "Está seguro que desea crear la relación de gastos?" }));
  };


  // funcion para manejar la funcion del submit del formulario
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.relacionGasto}?initialData=2&language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {
        setState((val) => ({ ...val, showModal: false, questionAction: "" }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, succes: true, codSolG: data?.solGasto }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para realizar la peticion de los datos al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.relacionGasto}?language=${language}&initialData=1`, {
        method: "POST",
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para realizar la peticion al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para cargar en el formulario la empresa, la gerencia, el grupo, la unidad
  useEffect(() => {
    if (userInfo && userInfo.length > 0) {
      setStateForm((val) => ({
        ...val, empresa: userInfo[0]?.CodEmpresa,
        division: userInfo[0]?.CodGrupo?.toString() ?? "", gerencia: userInfo[0]?.CodGerencia?.toString() ?? "",
        areaFun: userInfo[0]?.CodUnidad?.toString() ?? "", solicitante: userInfo[0]?.NomEmpleado, fecSol: fecSol && fecSol
      }));
    }
  }, [userInfo]);


  // useEffect para filtrar la gerencia
  useEffect(() => {
    if (gerencia && gerencia.length > 0) {
      const filter = gerencia && gerencia.filter((ge) => ge.CodGrupo.toString() === stateForm.division);

      setFilteredGerencia(filter);

    }
  }, [stateForm.division]);


  // useEffect para filtrar el area funcional
  useEffect(() => {
    if (unidad && unidad.length > 0) {
      const filter = unidad && unidad.filter((uni) => uni.CodGerencia.toString() === stateForm.gerencia);

      setFilteredUnidad(filter);
    }
  }, [stateForm.gerencia]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, succes: false, codSolG: 0 });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para realizar la redireccion para cuando se cree la solicitud
  useEffect(() => {
    if (notification.succes) {
      const timer = setTimeout(() => {
        navigate(`/travel-expenses/expense-report-detail/${notification.codSolG}`);
      }, 4500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notification.succes]);



  return (
    <>
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="display-data container">
        <form onSubmit={onHandleSubmit} className="card-shadow">
          {/* FILA */}
          <div className="row-flex">
            {/* empresa */}
            <div className="col-7"><strong>{t("Viaticos.empresa")}</strong></div>
            <div className="col-3">
              <select
                name="company"
                value={stateForm.empresa}
                onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                <option value=""></option>
                <option value="1">Global Medica</option>
                <option value="2">GMD HealthCare Ltd</option>
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* division empresa */}
            <div className="col-7"><strong>{t("Viaticos.divi_empr")}</strong></div>
            <div className="col-3">
              <select
                name="diviComp"
                value={stateForm.division}
                onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                <option value=""></option>
                {
                  grupo && grupo.map((g) => (
                    <option key={g.CodGrupo} value={g.CodGrupo}>{g.Nombre}</option>
                  ))
                }
              </select>
            </div>
            {/* gerencia */}
            <div className="col-7"><strong>{t("Viaticos.gerencia")}</strong></div>
            <div className="col-3">
              <select
                name="geren"
                value={stateForm.gerencia}
                onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
                <option value=""></option>
                {
                  filteredGerencia.map((ge) => (
                    <option key={ge.CodGerencia} value={ge.CodGerencia}>{ge.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* area funcional */}
            <div className="col-7"><strong>{t("Viaticos.area_fun")}</strong></div>
            <div className="col-3">
              <select
                name="areaFun"
                value={stateForm.areaFun}
                onChange={({ target }) => setStateForm((val) => ({ ...val, areaFun: target.value }))}>
                <option value=""></option>
                {
                  filteredUnidad.map((uni) => (
                    <option key={uni.CodUnidad} value={uni.CodUnidad}>{uni.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* Solicitante */}
            <div className="col-7"><strong>{t("Viaticos.solicitante")}</strong></div>
            <div className="col-3">
              <input
                type="text"
                className="input-text-control"
                value={stateForm.solicitante}
                readOnly />
            </div>
            {/* beneficiario */}
            <div className="col-7"><strong>{t("Viaticos.beneficiario")}</strong></div>
            <div className="col-3">
              <input
                type="text"
                className="input-text-control"
                value={stateForm.solicitante}
                readOnly />
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* fecha solicitud */}
            <div className="col-7"><strong>{t("Viaticos.fec_soli")}</strong></div>
            <div className="col-3">
              <input
                type="text"
                name="fecSol"
                className="input-text-control"
                value={renderDate(stateForm.fecSol, language)}
                readOnly />
            </div>
            {/* fecha requerida */}
            <div className="col-7"><strong>{t("Viaticos.fec_reque")}</strong></div>
            <div className="col-3">
              <input
                type="date"
                name="fecReque"
                className="input-date-styles"
                value={stateForm.fecReq}
                onChange={({ target }) => setStateForm((val) => ({ ...val, fecReq: target.value }))} />
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* forma de pago */}
            <div className="col-7"><strong>{t("Viaticos.form_pag")}</strong></div>
            <div className="col-3">
              <select
                name="formPag"
                value={stateForm.formPago}
                onChange={({ target }) => setStateForm((val) => ({ ...val, formPago: target.value }))}>
                <option value=""></option>
                <option value="1">Depósito</option>
                <option value="2">Efectivo</option>
                <option value="3">Tarjeta de crédito</option>
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* transferir a nombre */}
            <div className="col-7"><strong>{t("Viaticos.trans_nomb")}</strong></div>
            <div className="col-3">
              <input
                type="text"
                className="input-text-control"
                name="transFNom"
                value={stateForm.transNom}
                onChange={({ target }) => setStateForm((val) => ({ ...val, transNom: target.value }))} />
            </div>
            {/* numero de cuenta */}
            <div className="col-7"><strong>{t("Viaticos.num_cuenta")}</strong></div>
            <div className="col-3">
              <input
                type="text"
                className="input-text-control"
                name="numCuent"
                value={stateForm.numCuenta}
                onChange={({ target }) => setStateForm((val) => ({ ...val, numCuenta: target.value }))} />
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* moneda */}
            <div className="col-7"><strong>{t("Viaticos.moneda")}</strong></div>
            <div className="col-3">
              <select
                name="currency"
                value={stateForm.moneda}
                onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                <option value=""></option>
                <option value="0">RD$ Pesos</option>
                <option value="1">US$ Dollar</option>
                <option value="2">€ Euro</option>
                <option value="3">TT$ Dollar</option>
              </select>
            </div>
          </div>
          {/* FILA */}
          <div className="row-flex">
            {/* comentario */}
            <div className="col-7"><strong>{t("Viaticos.comentario")}</strong></div>
            <div className="col-82-16">
              <textarea
                name="comment"
                className="textarea-description"
                value={stateForm.comment}
                onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                autoComplete="off" />
            </div>
          </div>

          {/* botones de accion */}
          <div className="row-flex m-y-medium j-content-space-around">
            <button type="button" className="btn-primary" onClick={onSaveOpenModal}>Guardar</button>
            <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
          </div>

          {
            state.showModal && (
              <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                  {/* Question mark */}
                  <div className="row-flex m-y-small align-c justify-center">
                    <div className="question-mark-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                      </svg>
                    </div>
                  </div>
                  <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                    {state.questionAction}
                  </h5>
                  {/* Botones de accion */}
                  <div className="row-flex m-y-medium j-content-space-around">
                    <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                    <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                  </div>
                </div>
              </div>
            )
          }
        </form>
      </div>
    </>
  );
};