// pendientes para recursos humanos

import { useEffect, useState } from "react";


// react router import
import { useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";



export const PendingsRH = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useSearchParams
  const [params, setParams] = useSearchParams();


  // state definition
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ fecDesde: params.get("date_from") ?? "", fecHasta: params.get("date_to") ?? "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "" });


  // destructuring data server
  const { vacacion } = dataServ && dataServ;


  // funcion para cambiar los valores de los inputs
  const onHandleChangeVal = (newVal = "", type = 0) => {
    params.set("filt", 1);
    switch (type) {
      case 1: // fecha desde
        params.set("date_from", newVal);
        setStateForm((val) => ({ ...val, fecDesde: newVal }));
        setParams(params);
        break;
      case 2: // fecha hasta
        params.set("date_to", newVal);
        setStateForm((val) => ({ ...val, fecHasta: newVal }));
        setParams(params);
        break;
    }
  };


  // funcion para enviar el formulario al servidor
  const onHandleSubmitForm = async (event) => {
    event.preventDefault();

    try {

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.RHPendings}?language=${language}&fec_desde=${stateForm.fecDesde}&fec_hasta=${stateForm.fecHasta}&filt=${params.get("filt") ?? "0"}`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);





  return (
    <div className="container display-data">

      <form onSubmit={onHandleSubmitForm}>
        <div className="row-flex">
          <div className="col-10">
            <strong>Fecha</strong>
          </div>
          <div className="col-3 row-flex gap-small">
            <div className="col-3">
              <input
                className="input-date-styles"
                type="date"
                name="from"
                value={stateForm.fecDesde}
                onChange={({ target }) => onHandleChangeVal(target.value, 1)} />
            </div>
            <div className="row-flex align-c">
              <strong>al</strong>
            </div>
            <div className="col-3">
              <input
                className="input-date-styles"
                type="date"
                name="to"
                value={stateForm.fecHasta}
                onChange={({ target }) => onHandleChangeVal(target.value, 2)} />
            </div>
            <button type="submit" className="btn-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </div>
      </form>

      <h4 className="m-y-small">Vacaciones pendientes</h4>

      {
        vacacion && Object.keys(vacacion).map((codLider) => (
          <div key={codLider}>
            <h5 className="border-bottom m-y-small">{vacacion[codLider]?.NomLider}</h5>

            <div className="row-flex gap-small">

              {
                vacacion[codLider]?.empleados.map((vac) => (
                  <div key={vac.CodVacacion} className="card-shadow col-4 card-hei-emp" style={{ border: "2px solid var(--green-color)" }}>
                    <p style={{ padding: 0 }} className="txt-center">
                      <span>Nombre</span>
                    </p>
                    <p className="txt-center">
                      <strong>{vac.NomEmpleado}</strong>
                    </p>
                    <p className="txt-center">
                      <span>Periodo</span>
                    </p>
                    <p style={{ padding: 0 }} className="txt-center">
                      <span className="txt-muted">{renderDate(vac.FecInicio, language)}</span> <strong> al </strong> <span className="txt-muted">{renderDate(vac.FecFin, language)}</span>
                    </p>
                    <p className="txt-center">
                      <span>Reíntegro</span>
                    </p>
                    <p style={{ padding: 0 }} className="txt-center">
                      <span className="txt-muted">{renderDate(vac.FecReintegro, language)}</span >
                    </p>
                    <p className="txt-center">
                      <span>Motivo</span>
                    </p>
                    <p style={{padding: 0}} className="txt-center">
                      <strong>{vac.NomTipoS}</strong>
                    </p>
                    <p className="txt-center">
                      <strong>{vac.Dias} días calendarios</strong>
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};