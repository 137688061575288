// componente para buscar el seguimiento de logistica

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";
import { useNavigate, useSearchParams } from "react-router-dom";






export const SearchSeguimiento = ({ onClose, lang, token, pageSize, setNewState }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the userNavigate
  const navigate = useNavigate();

  // GET the useSearchParams
  const [params, setParams] = useSearchParams();


  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState();
  const [form, setForm] = useState({ numOrden: params.get("num_ord") ?? "", numPart: params.get("num_part") ?? "", etapa: params.get("nom_") ?? "", soNumber: params.get("num_so") ?? "", guiaDesp: params.get("num_tracking") ?? "", fecDesp: params.get("fec_desp") ?? "", workflow: params.get("workflow") ?? "", division: params.get("nom_divi") ?? "", linea: params.get("nom_lin") ?? "", modalidad: params.get("nom_moda") ?? "", zona: params.get("nom_area") ?? "", cliente: params.get("nom_cli") ?? "", suplidor: params.get("nom_suppl") ?? "" }); // para buscar diferentes cosas
  const [state, setState] = useState({ isLoading: false, searching: false });

  // destructuring data server
  const { proveedor, etapas, zonas, division, linea, modalidad } = dataServ && dataServ;


  // funcion para limpiar los filtros
  const onHandleClearFilter = () => {

    for (const [key,] of params) {
      // console.log(newParams.get(key))
      params.set(key, "");
    }

    const newState = { ...form };
    for (const prop in newState) {
      newState[prop] = "";
    }

    setParams(params);
    setForm(newState);
  };



  // funcion para cambiar los valores del formulario
  const onHandleChangeVal = (newVal = "", name = "", queryPar = "") => {

    if (name === "numOrden") {
      if (isNaN(newVal)) {
        return;
      }
    }

    setForm((val) => ({ ...val, [name]: newVal }));

    params.set(queryPar, newVal);
    setParams(params);

  };


  // funcion para filtrar
  const onSearchFilter = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, searching: true }));
      const response = await fetch(`${urls.getFollowUpOrders}?zona=${form.zona}&etapa=${form.etapa}&suplidor=${form.suplidor}&numOrden=${form.numOrden}&numPart=${form.numPart}&soNumber=${form.soNumber}&guiaDesp=${form.guiaDesp}&fecDesp=${form.fecDesp}&division=${form.division}&linea=${form.linea}&modalidad=${form.modalidad}&cliente=${form.cliente}&workflow=${form.workflow}&language=${lang}&page=1&pageSize=${pageSize}`, {
        headers: {
          "Authorization": token
        },
      });

      const data = await response.json();

      if (response.status === 200) {
        let page = 1;
        if (data?.orders.length === pageSize) {
          page = page + 1;
        }
        setNewState(data?.orders, data?.Total, data?.orders.length < pageSize, page);
        onClose();
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        // setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para traer los datos para los filtros
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.getFollowUpOrders}?language=${lang}&action=2`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  return (
    <div className="background-modal">
      <form onSubmit={onSearchFilter} className="container-select-customer" >
        <div className="modal-header">
          <h5>Búsqueda</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>

        <div className="modal-body">

          {
            state.isLoading && (<strong>{t("LoginView.cargando")}</strong>)
          }

          {
            !state.isLoading && (
              <>
                <div className="row-flex">
                  {/* BUSCAR POR ORDEN DE COMPRA */}
                  <div className="col-7">
                    <strong>{t("TableParts.orden")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="numOrd"
                      value={form.numOrden}
                      onChange={({ target }) => onHandleChangeVal(target.value, "numOrden", "num_ord")} />
                  </div>
                  {/* BUSCAR POR # PARTE */}
                  <div className="col-7">
                    <strong>{t("TableParts.parte")}</strong>
                  </div>
                  <div className="col-3 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="numPart"
                      value={form.numPart}
                      onChange={({ target }) => onHandleChangeVal(target.value, "numPart", "num_part")} />
                  </div>
                </div>
                {/* FILA 2 */}
                <div className="row-flex">
                  {/* FILTRAR POR ETAPA */}
                  <div className="col-7">
                    <strong>{t("TableParts.eta")}</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="step"
                      value={form.etapa}
                      onChange={({ target }) => onHandleChangeVal(target.value, "etapa", "nom_step")}>
                      <option value="">{t("RequestPartsView.pendiente")}</option>
                      <option value="0">{t("RequestPartsView.todo")}</option>
                      <option value="-2">{t("RequestPartsView.equip_parados")}</option>
                      {
                        etapas && etapas.map((eta) => (
                          <option key={eta.IdEtapa} value={eta.IdEtapa}>{eta.NomEtapa}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* BUSCAR POR SO/SOA */}
                  <div className="col-7">
                    <strong>SO/SOA</strong>
                  </div>
                  <div className="col-3 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="so"
                      value={form.soNumber}
                      onChange={({ target }) => onHandleChangeVal(target.value, "soNumber", "num_so")} />
                  </div>
                </div>
                {/* FILA 3 */}
                <div className="row-flex">
                  {/* BUSCAR POR GUIA DE DESPACHO */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.guia_desp")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="guiaDesp"
                      value={form.guiaDesp}
                      onChange={({ target }) => onHandleChangeVal(target.value, "guiaDesp", "num_tracking")} />
                  </div>
                  {/* BUSCAR POR FECHA DE DESPACHO */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.fecha_desp")}</strong>
                  </div>
                  <div className="col-3 flex-grow">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecDesp"
                      value={form.fecDesp}
                      onChange={({ target }) => onHandleChangeVal(target.value, "fecDesp", "fec_desp")} />
                  </div>
                </div>
                {/* FILA 4 */}
                <div className="row-flex">
                  {/* BUSCAR POR WORKFLOW */}
                  <div className="col-7">
                    <strong>WorkFlow</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="workFlow"
                      value={form.workflow}
                      onChange={({ target }) => onHandleChangeVal(target.value, "workflow", "workflow")} />
                  </div>
                  {/* BUSCAR POR DIVISION */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.division")}</strong>
                  </div>
                  <div className="col-3 flex-grow">
                    <select
                      name="division"
                      value={form.division}
                      onChange={({ target }) => onHandleChangeVal(target.value, "division", "nom_divi")}>
                      <option value=""></option>
                      {
                        division && division.map((divi) => (
                          <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {/* FILA 5 */}
                <div className="row-flex">
                  {/* BUSCAR POR LINEA */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.linea")}</strong>
                  </div>
                  <div className="col-3">
                    <select
                      name="line"
                      disabled={form.division === ""}
                      value={form.linea}
                      onChange={({ target }) => onHandleChangeVal(target.value, "linea", "nom_lin")}>
                      <option value=""></option>
                      {
                        linea && linea.filter((filtLin) => filtLin.CodDivision === form.division).map((line) => (
                          <option key={line.CodLinea} value={line.CodLinea}>{line.NomLinea}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* BUSCAR POR MODALIDAD */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.modalidad")}</strong>
                  </div>
                  <div className="col-3 flex-grow">
                    <select
                      name="modality"
                      disabled={form.linea === ""}
                      value={form.modalidad}
                      onChange={({ target }) => onHandleChangeVal(target.value, "modalidad", "nom_moda")}>
                      <option value=""></option>
                      {
                        modalidad && modalidad.filter((modFil) => modFil.CodLinea === form.linea).map((moda) => (
                          <option key={moda.CodModalidad} value={moda.CodModalidad}>{moda.NomModa}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {/* FILA 6 */}
                <div className="row-flex">
                  {/* BUSCAR POR ZONA */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.zona")}</strong>
                  </div>
                  <div className="col-83-87 flex-grow">
                    <select
                      name="area"
                      value={form.zona}
                      onChange={({ target }) => onHandleChangeVal(target.value, "zona", "nom_area")}>
                      <option value=""></option>
                      {
                        zonas && zonas.map((zon) => (
                          <option key={zon.CodZona} value={zon.CodZona}>{zon.NomZona}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {/* FILA 7 */}
                <div className="row-flex">
                  {/* BUSCAR CLIENTE */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.cliente")}</strong>
                  </div>
                  <div className="col-83-87 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="customer"
                      value={form.cliente}
                      onChange={({ target }) => onHandleChangeVal(target.value, "cliente", "nom_cli")} />
                  </div>
                </div>
                {/* FILA 8 */}
                <div className="row-flex">
                  {/* BUSCAR POR PROVEEDOR */}
                  <div className="col-7">
                    <strong>{t("RequestPartsView.supli")}</strong>
                  </div>
                  <div className="col-83-87 flex-grow">
                    <select
                      name="suppl"
                      value={form.suplidor}
                      onChange={({ target }) => onHandleChangeVal(target.value, "suplidor", "nom_suppl")}>
                      <option value="">Todos</option>
                      {
                        proveedor && proveedor.map((sup) => (
                          <option key={sup.CodProveedor} value={sup.CodProveedor}>{sup.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </>
            )
          }
        </div>

        {/* Botones de accion */}
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success" disabled={state.searching}>{state.searching ? t("PartModify.buscando") : t("Viaticos.buscar")}</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>{t("Viaticos.cerrar")}</button>
          <button type="reset" className="btn-warning" onClick={onHandleClearFilter}>{t("serviceCard.limpiar_filtro")}</button>
        </div>
      </form>
    </div>
  );
};