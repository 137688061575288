import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { TopNavbarMobile } from "../../components/top-searchbar-mobile/TopNavbarMobile";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// REACT ROUTER DOM IMPORTATION
import { useNavigate, useParams } from "react-router-dom";

// GLOBAL TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";


export const PropuestaDetail = () => {

  // translations
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // get the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET THE PARAMS
  const { codPropuesta } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // para mostrar el loading cuando la data del servidor esté cargando
  const [stateFormData, setStateFormData] = useState({
    cliente: "", repreLegal: "", cedula: "", nacionalidad: "", estCivil: "", correo: "", fecInicio: "", fecFin: "", director: "",
    coberDiasFeriados: false, coberServiFinSemana: false, coberHorarioExt: false, coberInSite: false, coberServiDiaSemana: false, coberVisitasCorrec: false,
    FECoberNight: false, FECoberWeekends: false, FEHotel: false, FlightsCober: false, internalUPS: false, xrayTubes: false, inkPrinter: false, imageIntensi: false,
    digitalDetector: false, magnetComp: false, cryoGens: false, inkPrint: false, usTransducer: false, externalUps: false, injectors: false, tiempoRespuesta: "",
    frecuenciaPago: "", numeroVisitasCorrectivas: "", opciones: "", excluidas: "", cronogramaPagos: "", responsable: "", dolarParte: "", montoPropuesta: "",
    dolarManoObra: "", montoPenalizado: "", cuotas: "", comentario: "", tipo: "", margen: "", manoObraPorcentaje: ""
  });


  // DESTRUCTURING DATA SERVER
  const { proposal, employees, directors, segProposal, employeePermission } = dataServer && dataServer;


  // useEffect para traer la data
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(`${urls.getLisProposal}/${codPropuesta}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 400) {
          // bad request
        } else if (response.status === 500) {
          // error en el servidor
        }
        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [codPropuesta]);

  // useEffect para cuando la data del servidor esté cargada se setee en el estado
  useEffect(() => {
    if (proposal && proposal) {
      setStateFormData({
        cliente: "", repreLegal: proposal[0]?.RepLegal, cedula: proposal[0]?.CedRepLegal, nacionalidad: "", estCivil: proposal[0]?.EstRepLegal, correo: proposal[0]?.EmailCliente, fecInicio: proposal[0]?.FecInicio.slice(0, 10) || "", fecFin: proposal[0]?.FecFin.slice(0, 10) || "", director: proposal[0]?.CodEmpDirector,
        coberDiasFeriados: proposal[0]?.IdFeriado, coberServiFinSemana: proposal[0]?.IdFinSemana, coberHorarioExt: proposal[0]?.IdExtendido, coberInSite: proposal[0]?.IdSite, coberServiDiaSemana: proposal[0]?.IdDiaSemana, coberVisitasCorrec: proposal[0]?.IdVisita,
        FECoberNight: proposal[0]?.Opc1, FECoberWeekends: proposal[0]?.Opc2, FEHotel: proposal[0]?.Opc3, FlightsCober: proposal[0]?.Opc4, internalUPS: proposal[0]?.Opc5, xrayTubes: proposal[0]?.Opc6, inkPrinter: proposal[0]?.Opc7, imageIntensi: proposal[0]?.Opc8,
        digitalDetector: proposal[0]?.Opc9, magnetComp: proposal[0]?.Opc10, cryoGens: proposal[0]?.Opc11, inkPrint: proposal[0]?.Opc12, usTransducer: proposal[0]?.Opc13, externalUps: proposal[0]?.Opc14, injectors: proposal[0]?.Opc15, tiempoRespuesta: proposal[0]?.TieRespuesta,
        frecuenciaPago: proposal[0]?.IdFrecuencia, numeroVisitasCorrectivas: proposal[0]?.VisCorrectiva, opciones: proposal[0]?.Opcion.toLowerCase() || "", excluidas: proposal[0]?.Excluida, cronogramaPagos: proposal[0]?.Pago.toLowerCase() || "", responsable: proposal[0]?.CodEmpResponsable, dolarParte: "", montoPropuesta: proposal[0]?.Monto,
        dolarManoObra: "", montoPenalizado: proposal[0]?.MonPenalizado, cuotas: "", comentario: "", tipo: "", margen: "", manoObraPorcentaje: ""
      });
    }

  }, [proposal]);

  return (
    <>
      {
        isLoading && (<LoadingComp />)
      }
      <TopNavbarMobile />
      <div className="container display-data">
        <form>
          {/* FILA 1 */}
          <div className="row-flex">
            {/* NRO DE PROPUESTA */}
            <div className="col-7"><strong>Nro. Propuesta</strong></div>
            <div className="col-4"><input style={{ width: '100%' }} className="input-text-control" type="text" value={proposal && proposal[0]?.NumPropuesta} readOnly /></div>
            {/* NRO. DE CONTRATO */}
            <div className="col-7"><strong>Nro. Contrato</strong></div>
            <div className="col-3"><strong style={{ color: 'var(--green-color)' }}>{proposal && proposal[0].CodContrato}</strong></div>
          </div>

          {/* FILA 2 */}
          <div className="row-flex m-y-small">
            {/* CLIENTE */}
            <div className="col-7"><strong>Cliente</strong></div>
            <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.cliente} onChange={({ target }) => setStateFormData({ ...stateFormData, cliente: target.value })} /></div>
          </div>

          {/* FILA 3 */}
          <div className="row-flex">
            {/* REPRESENTANTE LEGAL */}
            <div className="col-7"><strong>Representante Legal</strong></div>
            <div className="col-4"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.repreLegal} onChange={({ target }) => setStateFormData({ ...stateFormData, repreLegal: target.value })} /></div>
            {/* CI */}
            <div className="col-7"><strong>Cédula</strong></div>
            <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.cedula} onChange={({ target }) => setStateFormData({ ...stateFormData, cedula: target.value })} /></div>
          </div>

          {/* FILA 4 */}
          <div className="row-flex m-y-small">
            {/* NACIONALIDAD */}
            <div className="col-7"><strong>Nacionalidad</strong></div>
            <div className="col-4"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.nacionalidad} onChange={({ target }) => setStateFormData({ ...stateFormData, nacionalidad: target.value })} /></div>
            {/* ESTADO CIVIL */}
            <div className="col-7"><strong>Estado Civil</strong></div>
            <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.estCivil} onChange={({ target }) => setStateFormData({ ...stateFormData, estCivil: target.value })} /></div>
          </div>

          {/* FILA 5 */}
          <div className="row-flex">
            {/* CORREO */}
            <div className="col-7"><strong>Correo</strong></div>
            <div className="col-4"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.correo} onChange={({ target }) => setStateFormData({ ...stateFormData, correo: target.value })} /></div>
          </div>

          {/* FILA 6 */}
          <div className="row-flex m-y-small">
            {/* FECHA INICIO */}
            <div className="col-7"><strong>Fecha Inicio</strong></div>
            <div className="col-4"><input className="input-date-styles" type="date" value={stateFormData.fecInicio} onChange={({ target }) => setStateFormData({ ...stateFormData, fecInicio: target.value })} /></div>
            {/* FECHA FIN */}
            <div className="col-7"><strong>Fecha Fin</strong></div>
            <div className="col-3"><input className="input-date-styles" type="date" value={stateFormData.fecFin} onChange={({ target }) => setStateFormData({ ...stateFormData, fecFin: target.value })} /></div>
          </div>

          {/* FILA 7 */}
          <div className="row-flex">
            {/* DIRECTOR */}
            <div className="col-7"><strong>Director</strong></div>
            <div className="col-4">
              <select name="director" value={stateFormData.director} onChange={({ target }) => setStateFormData({ ...stateFormData, director: target.value })}>
                <option value=""></option>
                {
                  directors && directors.map((direct) => (
                    <option key={direct.CodEmpleado} value={direct.CodEmpleado}>{direct.NomEmpleado}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <hr className="m-y-small" />

          {/* FILA 8 */}
          <div className="row-flex">
            {/* COBERTURA DIAS FERIADOS */}
            <div className="col-4 row-flex j-content-space-b">
              <div><strong>Cobertura Días Feriados</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.coberDiasFeriados} onChange={({ target }) => setStateFormData({ ...stateFormData, coberDiasFeriados: target.checked })} /></div>
            </div>
            {/* COBERTURA DEL SERVICIO EN FINES DE SEMANA */}
            <div className="col-4 row-flex j-content-space-b">
              <div><strong>Cobertura del Servicio en Fines de Semana</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.coberServiFinSemana} onChange={({ target }) => setStateFormData({ ...stateFormData, coberServiFinSemana: target.checked })} /></div>
            </div>
            {/* COBERTURA DEL SERVICIO EN HORARIO EXTENDIDO */}
            <div className="col-4 row-flex j-content-space-b">
              <div><strong>Cobertura del Servicio en Horario Extendido</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.coberHorarioExt} onChange={({ target }) => setStateFormData({ ...stateFormData, coberHorarioExt: target.checked })} /></div>
            </div>
          </div>

          {/* FILA 9 */}
          <div className="row-flex m-y-small">
            {/* COBERTURA IN-SITE */}
            <div className="col-4 row-flex j-content-space-b">
              <div><strong>Cobertura In-Site</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.coberInSite} onChange={({ target }) => setStateFormData({ ...stateFormData, coberInSite: target.checked })} /></div>
            </div>
            {/* COBERTURA DEL SERVICIO EN DIA DE SEMANA */}
            <div className="col-4 row-flex j-content-space-b">
              <div><strong>Cobertura del Servicio en día de Semana</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.coberServiDiaSemana} onChange={({ target }) => setStateFormData({ ...stateFormData, coberServiDiaSemana: target.checked })} /></div>
            </div>
            {/* COBERTURA DE VISITAS CORRECTIVAS */}
            <div className="col-4 row-flex j-content-space-b">
              <div><strong>Cobertura de Visitas Correctivas</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.coberVisitasCorrec} onChange={({ target }) => setStateFormData({ ...stateFormData, coberVisitasCorrec: target.checked })} /></div>
            </div>
          </div>
          <hr />

          {/* FILA 10 */}
          <div className="row-flex m-y-small">
            {/* FE COVERAGE NIGHT SHIFT */}
            <div className="col-4 row-flex">
              <div><strong>FE Coverage Night Shift</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.FECoberNight} onChange={({ target }) => setStateFormData({ ...stateFormData, FECoberNight: target.checked })} /></div>
            </div>
            {/* FE COVERAGE WEEKENDS AND HOLIDAYS */}
            <div className="col-4 row-flex">
              <div><strong>FE Coverage Weekends and Holidays</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.FECoberWeekends} onChange={({ target }) => setStateFormData({ ...stateFormData, FECoberWeekends: target.checked })} /></div>
            </div>
            {/* FE HOTEL EXPENSES INCLUDED */}
            <div className="col-4 row-flex">
              <div><strong>FE Hotel Expenses Included</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.FEHotel} onChange={({ target }) => setStateFormData({ ...stateFormData, FEHotel: target.checked })} /></div>
            </div>
          </div>

          {/* FILA 11 */}
          <div className="row-flex">
            {/* FE COVERAGE NIGHT SHIFT */}
            <div className="col-4 row-flex">
              <div><strong>Flights Coverage Included</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.FlightsCober} onChange={({ target }) => setStateFormData({ ...stateFormData, FlightsCober: target.checked })} /></div>
            </div>
            {/* FE COVERAGE WEEKENDS AND HOLIDAYS */}
            <div className="col-4 row-flex">
              <div><strong>Internal UPS</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.internalUPS} onChange={({ target }) => setStateFormData({ ...stateFormData, internalUPS: target.checked })} /></div>
            </div>
            {/* FE HOTEL EXPENSES INCLUDED */}
            <div className="col-4 row-flex">
              <div><strong>X-ray tubes</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.xrayTubes} onChange={({ target }) => setStateFormData({ ...stateFormData, xrayTubes: target.checked })} /></div>
            </div>
          </div>

          {/* FILA 12 */}
          <div className="row-flex m-y-small">
            {/* FE COVERAGE NIGHT SHIFT */}
            <div className="col-4 row-flex">
              <div><strong>Ink Printer</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.inkPrinter} onChange={({ target }) => setStateFormData({ ...stateFormData, inkPrinter: target.checked })} /></div>
            </div>
            {/* FE COVERAGE WEEKENDS AND HOLIDAYS */}
            <div className="col-4 row-flex">
              <div><strong>Image Intensifiers</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.imageIntensi} onChange={({ target }) => setStateFormData({ ...stateFormData, imageIntensi: target.checked })} /></div>
            </div>
            {/* FE HOTEL EXPENSES INCLUDED */}
            <div className="col-4 row-flex">
              <div><strong>Digital Detectors</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.digitalDetector} onChange={({ target }) => setStateFormData({ ...stateFormData, digitalDetector: target.checked })} /></div>
            </div>
          </div>

          {/* FILA 13 */}
          <div className="row-flex">
            {/* FE COVERAGE NIGHT SHIFT */}
            <div className="col-4 row-flex">
              <div><strong>Magnet Components</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.magnetComp} onChange={({ target }) => setStateFormData({ ...stateFormData, magnetComp: target.checked })} /></div>
            </div>
            {/* FE COVERAGE WEEKENDS AND HOLIDAYS */}
            <div className="col-4 row-flex">
              <div><strong>Cryogens</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.cryoGens} onChange={({ target }) => setStateFormData({ ...stateFormData, cryoGens: target.checked })} /></div>
            </div>
            {/* FE HOTEL EXPENSES INCLUDED */}
            <div className="col-4 row-flex">
              <div><strong>Ink Printer</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.inkPrint} onChange={({ target }) => setStateFormData({ ...stateFormData, inkPrint: target.checked })} /></div>
            </div>
          </div>

          {/* FILA 14 */}
          <div className="row-flex m-y-small">
            {/* FE COVERAGE NIGHT SHIFT */}
            <div className="col-4 row-flex">
              <div><strong>US Transducer</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.usTransducer} onChange={({ target }) => setStateFormData({ ...stateFormData, usTransducer: target.checked })} /></div>
            </div>
            {/* FE COVERAGE WEEKENDS AND HOLIDAYS */}
            <div className="col-4 row-flex">
              <div><strong>External UPS</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.externalUps} onChange={({ target }) => setStateFormData({ ...stateFormData, externalUps: target.checked })} /></div>
            </div>
            {/* FE HOTEL EXPENSES INCLUDED */}
            <div className="col-4 row-flex">
              <div><strong>Injectors</strong></div>
              <div><input className="input-control-checkbox" type="checkbox" name="" checked={stateFormData.injectors} onChange={({ target }) => setStateFormData({ ...stateFormData, injectors: target.checked })} /></div>
            </div>
          </div>
          <hr />

          {/* FILA 15 */}
          <div className="row-flex m-y-small">
            {/* TIEMPO DE RESPUESTA */}
            <div className="col-7"><strong>Tiempo de Repuesta</strong></div>
            <div className="col-7"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.tiempoRespuesta} onChange={({ target }) => setStateFormData({ ...stateFormData, tiempoRespuesta: target.value })} /></div>
            {/* FRECUENCIA DE PAGO */}
            <div className="col-7"><strong>Frecuencia de Pago</strong> <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span></div>
            <div className="col-7">
              <select name="" value={stateFormData.frecuenciaPago} onChange={({ target }) => setStateFormData({ ...stateFormData, frecuenciaPago: target.value })}>
                <option value=""></option>
                <option value="1">Mensuales</option>
                <option value="2">Bimestrales</option>
                <option value="3">Trimestrales</option>
                <option value="7">Cuatrimestrales</option>
                <option value="4">Semestrales</option>
                <option value="5">Anuales</option>
                <option value="6">Bimensuales</option>
              </select>
            </div>
            {/* NUMERO DE VISITAS CORRECTIVAS */}
            <div className="col-7"><strong>Número de Visitas Correctivas</strong></div>
            <div className="col-7"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.numeroVisitasCorrectivas} onChange={({ target }) => setStateFormData({ ...stateFormData, numeroVisitasCorrectivas: target.value })} /></div>
          </div>

          {/* FILA 16 */}
          <div className="row-flex">
            {/* OPCIONES */}
            <div className="col-7"><strong>Opciones</strong></div>
            <div className="col-3"><textarea style={{ textTransform: 'capitalize' }} className="textarea-description" name="" value={stateFormData.opciones} onChange={({ target }) => setStateFormData({ ...stateFormData, opciones: target.value })} /></div>
            {/* EXCLUIDAS */}
            <div className="col-7"><strong>Excluidas</strong></div>
            <div className="col-4"><textarea className="textarea-description" name="" value={stateFormData.excluidas} onChange={({ target }) => setStateFormData({ ...stateFormData, excluidas: target.value })} /></div>
          </div>

          {/* FILA 17 */}
          <div className="row-flex m-y-small">
            {/* CRONOGRAMA DE PAGOS */}
            <div className="col-7"><strong>Cronograma de Pagos</strong></div>
            <div className="flex-auto"><textarea style={{ textTransform: 'capitalize' }} className="textarea-description" name="" value={stateFormData.cronogramaPagos} onChange={({ target }) => setStateFormData({ ...stateFormData, cronogramaPagos: target.value })} /></div>
          </div>

          {/* FILA 18 */}
          <div className="row-flex">
            {/* RESPONSABLE */}
            <div className="col-7"><strong>Responsable</strong></div>
            <div className="col-4">
              <select name="" value={stateFormData.responsable} onChange={({ target }) => setStateFormData({ ...stateFormData, responsable: target.value })}>
                <option value=""></option>
                {
                  employees && employees.map((empl) => (
                    <option key={empl.CodEmpleado} value={empl.CodEmpleado}>{empl.NomEmpleado}</option>
                  ))
                }
              </select>
            </div>
            {/* DOLAR PARTE */}
            <div className="col-7"><strong>Dolar Parte</strong></div>
            <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.dolarParte} onChange={({ target }) => setStateFormData({ ...stateFormData, dolarParte: target.value })} /></div>
          </div>

          {/* FILA 19 */}
          <div className="row-flex m-y-small">
            {/* MONTO DE LA PROPUESTA */}
            <div className="col-7"><strong>Monto de la Propuesta</strong></div>
            <div className="col-4"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.montoPropuesta} readOnly /></div>
            {/* DOLAR MANO DE OBRA */}
            <div className="col-7"><strong>Dolar Mano de Obra</strong></div>
            <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.dolarManoObra} onChange={({ target }) => setStateFormData({ ...stateFormData, dolarManoObra: target.value })} /></div>
          </div>

          {/* FILA 20 */}
          <div className="row-flex">
            {/* MONTO PENALIZADO */}
            <div className="col-7"><strong>Monto Penalizado</strong></div>
            <div className="col-4"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.montoPenalizado} onChange={({ target }) => setStateFormData({ ...stateFormData, montoPenalizado: target.value })} /></div>
            {/* # CUOTAS */}
            <div className="col-7"><strong># Cuotas</strong></div>
            <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.cuotas} onChange={({ target }) => setStateFormData({ ...stateFormData, cuotas: target.value })} /></div>
          </div>

          {/* FILA 21 */}
          <div className="row-flex m-y-small">
            {/* COMENTARIO */}
            <div className="col-7"><strong>Comentario</strong></div>
            <div className="flex-auto"><textarea className="textarea-description" name="" value={stateFormData.comentario} onChange={({ target }) => setStateFormData({ ...stateFormData, comentario: target.value })} placeholder={t("PostSalesView.commentTextArea")} /></div>
          </div>

          {/* BOTONES DE ACCION */}
          <div className="row-flex j-content-space-around">
            <button className="btn-primary">Guardar</button>
            <button className="btn-danger">Eliminar</button>
            <button className="btn-info">Enviar</button>
            <button className="btn-primary">Imprimir</button>
            <button className="btn-primary">Imprimir Logo</button>
            <button className="btn-secondary">Cancelar</button>
          </div>

          {/* TABLA DE SEGUIMIENTO */}
          <div className="table-container-mobile m-y-small">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th style={{ borderRadius: '5px 0 0 0' }}>Fecha</th>
                  <th>Etapa Actual</th>
                  <th>Próxima Etapa</th>
                  <th>Responsable</th>
                  <th style={{ borderRadius: '0 5px 0 0' }}>Acción</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  segProposal && segProposal.map((seg) => (
                    <tr key={seg.CodSegPropuesta}>
                      <td>{renderDate(seg.FecIngreso, language)}</td>
                      <td>{ }</td>
                      <td>{ }</td>
                      <td>{seg.employeeName}</td>
                      <td style={{ textTransform: 'capitalize' }}>{seg.Accion.toLowerCase() || ""}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          {/* FILA 22 */}
          <div className="row-flex">
            {/* AGREGAR EQUIPO */}
            <div className="col-7"><strong>Agregar Equipo</strong></div>
          </div>
          <hr className="m-y-small" />

          {/* FILA 23 */}
          <div className="row-flex">
            {/* TIPO */}
            <div className="col-7"><strong>Tipo</strong></div>
            <div className="col-4">
              <select name="" value={stateFormData.tipo} onChange={({ target }) => setStateFormData({ ...stateFormData, tipo: target.value })}>
                <option value=""></option>
                <option value="0">DDP</option>
                <option value="1">DAP</option>
                <option value="2">CIP</option>
              </select>
            </div>
          </div>

          {/* FILA 24 */}
          <div className="row-flex m-y-small">
            {/* % MARGEN */}
            <div className="col-7"><strong>% Margen</strong></div>
            <div className="col-4"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.margen} onChange={({ target }) => setStateFormData({ ...stateFormData, margen: target.value })} /></div>
            {/* % MANO DE OBRA */}
            <div className="col-7"><strong>% Mano de Obra</strong></div>
            <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" value={stateFormData.manoObraPorcentaje} onChange={({ target }) => setStateFormData({ ...stateFormData, manoObraPorcentaje: target.value })} /></div>
          </div>

          <hr />

          {/* TABLA DONDE SE MUESTRAN LOS EQUIPOS */}
          <div className="table-container-mobile m-y-small">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th style={{ borderRadius: '5px 0 0 0' }}>#</th>
                  <th></th>
                  <th>Equipo</th>
                  <th>Frecuencia</th>
                  <th>Tipo de Propuesta</th>
                  <th>GE / VARIAN</th>
                  <th>GLOBAL</th>
                  <th>G. Nac</th>
                  <th>Mano de Obra</th>
                  <th>Otros</th>
                  <th>Total USD$</th>
                  <th>Total Mensual US$</th>
                  <th>Partes Mensual US$</th>
                  <th>Mano de Obra Mensual USD</th>
                  <th style={{ borderRadius: '0 5px 0 0' }}>Comentario</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

        </form>


      </div>
    </>
  );
};