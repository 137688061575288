// componente para actualizar las ordenes de comercial en seguimiento

import { useEffect, useState } from "react";
import { urls } from "../../api-urls/api-urls";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";






export const UpdateOrderComp = ({ onClose, codOrd, lang, token, setNotify }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [listDetOrd, setListDetOrd] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [form, setForm] = useState({ action: "", opID: "", origen: "", accion: "", comentario: "", zona: 0, fecScode: "", fecDespacho: "", fecSalida: "", fecGuia: "", guia: "", peso: "", numCaja: "", monEmbarq: "", moneda: "", fecAduana: "", fecRecepcion: "", idTipo: 0, nroFact: "" });
  const [state, setState] = useState({ isLoading: false, updating: false });



  // funcion para actualizar el input del detalle de la orden
  const onHandleChangeVal = (newVal = false, codDet = 0) => {
    setListDetOrd((val) => {
      return val.map((newV) => {
        if (codDet === newV.CodDetOrden) {
          return { ...newV, Update: newVal };
        }
        return newV;
      });
    });
  };


  // funcion para actualizar el input del SID
  const onHandleChangeSID = (newVal = "", codDet = 0) => {
    setListDetOrd((val) => {
      return val.map((newV) => {
        if (codDet === newV.CodDetOrden) {
          return { ...newV, NumSistema: newVal };
        }
        return newV;
      });
    });
  };


  // funcion para cambiar los valores de los inputs
  const onHandleChangeValForm = (newVal = "", name = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };



  // funcion para actualizar la orden de compra
  const onHandleUpdate = async (event) => {
    event.preventDefault();

    const finalObj = { form, listDetOrd };

    if (form.accion === "") {
      return setNotify("Debe seleccionar una accion", true);
    }

    const END_POINT = `${urls.getPurchaseOrderCommercial}/${codOrd}/search?language=${lang}`;
    try {
      setState((val) => ({ ...val, updating: true }));
      const response = await fetch(END_POINT, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotify(data?.msg, false);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, updating: false }));

    } catch (error) {
      console.log(error);
    }
  };




  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.getPurchaseOrderCommercial}/${codOrd}/search?language=${lang}&action=2`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setListDetOrd(data?.detOrden);
        setForm((val) => ({ ...val, zona: data?.Orden[0]?.CodZona, idTipo: data?.Orden[0]?.IdTipo }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);





  return (
    <div className="background-modal">
      <form onSubmit={onHandleUpdate} className="container-select-customer">
        <div className="modal-header">
          <h4>Actualizar orden</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          {
            state.isLoading && (<h5>{t("PartModify.cargando_info")}</h5>)
          }

          {
            !state.isLoading && (
              <>
                <h5 style={{ color: "var(--first-color)" }}>{listDetOrd && listDetOrd[0]?.NomEtapa}</h5>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Acción</strong>
                  </div>
                  <div className="col-5">
                    <select
                      name="action"
                      value={form.accion}
                      onChange={({ target }) => onHandleChangeValForm(target.value, "accion")}>
                      <option value=""></option>
                      {
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 145) && ( // Esperando Confirmacion de Booking
                          <>
                            <option value="1">Orden bookeada</option>
                            <option value="2">No bookeada</option>
                          </>
                        )
                      }
                      {
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 146) && ( // Pendiente enviar OKTS
                          <>
                            <option value="1">Se envía OKTS</option>
                            <option value="2">--Regresar--</option>
                          </>
                        )
                      }
                      {
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 147) && ( // Esperando confirmacion de Scode
                          <>
                            <option value="148">Scode confirmado</option>
                          </>
                        )
                      }
                      {
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 148) && ( // Esperando fecha de despacho
                          <>
                            <option value="1">Se estimó fecha de despacho</option>
                            <option value="2">Reenviar OKTS</option>
                          </>
                        )
                      }
                      {
                        // Esperando salida de fabrica
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 149) && (
                          <>
                            <option value="1">Salida de fábrica confirmada</option>
                            <option value="2">Cambió fecha de despacho</option>
                          </>
                        )
                      }
                      {
                        // Esperando guia AWB/BL
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 150) && (
                          <>
                            <option value="1">Fecha guía</option>
                            <option value="2">Cambió fecha de fábrica</option>
                          </>
                        )
                      }
                      {
                        // Notificacion de pago
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 151) && (
                          <>
                            <option value="1">Se notificó al cliente</option>
                            <option value="2">No</option>
                          </>
                        )
                      }
                      {
                        // En transito
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 152) && (
                          <>
                            <option value="1">Fecha de llegada a aduanas</option>
                            <option value="2">Cambió la guía</option>
                          </>
                        )
                      }
                      {
                        // Nacionalizacion
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 153) && (
                          <>
                            <option value="1">Llegó a destino</option>
                            <option value="2">No</option>
                          </>
                        )
                      }
                      {
                        // Recepcion final
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 154) && (
                          <>
                            <option value="1">Fecha recepción</option>
                            <option value="2">No llegó a destino</option>
                          </>
                        )
                      }
                      {
                        // Recepcion contable
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 156) && (
                          <>
                            <option value="1">Se ingresó contablemente</option>
                            <option value="2">No</option>
                          </>
                        )
                      }
                      {
                        // Facturacion de ventas
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 157) && (
                          <>
                            <option value="1">Se facturó el equipo</option>
                            <option value="2">No</option>
                          </>
                        )
                      }
                      {
                        // Espera de POD
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 158) && (
                          <>
                            <option value="1">Cerrar sin POD</option>
                            <option value="2">Regresar</option>
                          </>
                        )
                      }
                      <option value="0">Comentario</option>
                    </select>
                  </div>
                  {
                    listDetOrd && listDetOrd[0]?.IdEtapa === 147 && ( // esperando confirmacion de Scode
                      <div className="col-7">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="scodeFec"
                          value={form.fecScode}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "fecScode")} />
                      </div>
                    )
                  }
                  {
                    // esperando fecha de despacho
                    (listDetOrd && listDetOrd[0]?.IdEtapa === 148) && (
                      <div className="col-7">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="fecDesp"
                          value={form.fecDespacho}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "fecDespacho")} />
                      </div>
                    )
                  }
                  {
                    (listDetOrd && listDetOrd[0]?.IdEtapa === 149) && (
                      <div className="col-7">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="fecSalFab"
                          value={form.fecSalida}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "fecSalida")} />
                      </div>
                    )
                  }
                  {
                    // Esperando Guia AWB/BL
                    (listDetOrd && listDetOrd[0]?.IdEtapa === 150) && (
                      <div className="col-7">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="fecGuia"
                          value={form.fecGuia}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "fecGuia")} />
                      </div>
                    )
                  }
                  {
                    // En transito
                    (listDetOrd && listDetOrd[0]?.IdEtapa === 152) && (
                      <div className="col-7">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="fecAdua"
                          value={form.fecAduana}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "fecAduana")} />
                      </div>
                    )
                  }
                  {
                    // En transito
                    (listDetOrd && listDetOrd[0]?.IdEtapa === 154) && (
                      <div className="col-7">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="fecRecepcion"
                          value={form.fecRecepcion}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "fecRecepcion")} />
                      </div>
                    )
                  }
                  {
                    // Facturacion de ventas
                    (listDetOrd && listDetOrd[0]?.IdEtapa === 157) && (
                      <div className="col-7">
                        <input
                          className="input-text-control"
                          type="text"
                          name="nroFact"
                          value={form.nroFact}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "nroFact")} />
                      </div>
                    )
                  }
                </div>

                {
                  // Esperando Guia AWB/BL
                  (listDetOrd && listDetOrd[0]?.IdEtapa === 150) && (
                    <div className="row-flex">
                      <div className="col-34-29">
                        <strong>Nro. AWB/BL</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="awbbl"
                          value={form.guia}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "guia")} />
                      </div>
                      <div className="col-5">
                        <strong>Nro. Cajas</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="numCaj"
                          value={form.numCaja}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "numCaja")} />
                      </div>
                      <div className="col-5">
                        <strong>Gross weight Kg.</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="weight"
                          value={form.peso}
                          onChange={({ target }) => onHandleChangeValForm(target.value, "peso")} />
                      </div>
                      <div className="flex-grow border">

                      </div>
                    </div>
                  )
                }

                {
                  listDetOrd && listDetOrd[0]?.IdEtapa === 145 && ( // Esperando confirmacion de booking
                    <>
                      <div className="row-flex">
                        <div className="col-7">
                          <strong>OP ID#</strong>
                        </div>
                        <div className="col-5">
                          <input
                            className="input-text-control"
                            type="text"
                            name="OPID"
                            value={form.opID}
                            onChange={({ target }) => onHandleChangeValForm(target.value, "opID")}
                            autoComplete="off" />
                        </div>
                      </div>

                      <div className="row-flex">
                        <div className="col-7">
                          <strong>Origen</strong>
                        </div>
                        <div className="col-80 flex-grow">
                          <textarea
                            className="textarea-description"
                            name="origen"
                            value={form.origen}
                            onChange={({ target }) => onHandleChangeValForm(target.value, "origen")}
                            autoComplete="off" />
                        </div>
                      </div>
                    </>
                  )
                }

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Comentario</strong>
                  </div>
                  <div className="col-80 flex-grow">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={form.comentario}
                      onChange={({ target }) => onHandleChangeValForm(target.value, "comentario")}
                      autoComplete="off" />
                  </div>
                </div>
              </>
            )
          }

          <hr className="m-y-small" />

          {
            listDetOrd && listDetOrd.length > 0 && (
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Nro. Orden</th>
                      <th>Cant</th>
                      <th>Equipo</th>
                      <th>Cliente</th>
                      <th>Proveedor</th>
                      {
                        (listDetOrd && listDetOrd[0]?.IdEtapa === 152) && (
                          <th>System ID</th>
                        )
                      }
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      listDetOrd && listDetOrd.map((lstOrd) => (
                        <tr key={lstOrd.CodDetOrden}>
                          <td className="txt-center">{lstOrd.NumOrden}</td>
                          <td className="txt-center">{lstOrd.Cantidad}</td>
                          <td>{lstOrd.Modelo}</td>
                          <td>{lstOrd.NomCliente}</td>
                          <td>{lstOrd.NomProveedor}</td>
                          {
                            lstOrd.IdEtapa === 152 && (
                              <td>
                                <input
                                  className="input-text-control"
                                  type="text"
                                  value={lstOrd.NumSistema ?? ""}
                                  onChange={({ target }) => onHandleChangeSID(target.value, lstOrd.CodDetOrden)} />
                              </td>
                            )
                          }
                          <td className="txt-center">
                            <input
                              className="input-control-checkbox"
                              type="checkbox"
                              name={`action-${lstOrd.CodDetOrden}`}
                              checked={lstOrd.Update}
                              onChange={({ target }) => onHandleChangeVal(target.checked, lstOrd.CodDetOrden)} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success">Actualizar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};