// componente para renderizar la tabla de las herramientas solicitadas

import { useEffect, useState } from "react";

// react router dom importation
import { Link } from "react-router-dom";

// global function imports
import { renderDate } from "../../global-functions/globalFunctions";

// useTranslations import
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";







export const SegToolsTable = ({ lang, idCall, token }) => {


  // GET the react translation
  const [t] = useTranslation("global");


  // state definition
  const [tools, setTools] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, msg: "" });
 

  // funcion para traer la data del servidor
  const fetchDataServ = async () => {

    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.serviceCallObj}/request-tools/${idCall}/search?language=${lang}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        }
      });

      console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setTools(data?.tools);
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cargar solicitar las herramientas
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idCall, lang]);





  return (
    <>
      <h4 style={{ color: "var(--first-color)", textDecoration: "underline" }}>{t("serviceCard.herramientas_soli")}</h4>

      {
        // Tabla para las herramientas
        tools && tools.length > 0 ? (
          <div className="table-container-mobile m-y-small">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>{t("LogisticViewComp.request")}</th>
                  <th>{t("LogisticViewComp.nota_salida")}</th>
                  <th>{t("LogisticViewComp.fec_salida")}</th>
                  <th>{t("LogisticViewComp.codigo")}</th>
                  <th>{t("TableParts.descrip")}</th>
                  <th>{t("Viaticos.solicitante")}</th>
                  <th>{t("newCallComp.currentStep")}</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  tools && tools.map((herr) => (
                    <tr key={`${herr.CodSolHerramienta}-${herr.CodHerramienta}`}>
                      <td className="txt-center">
                        <Link className="link-tag" to={`/service/asset-request-detail/${herr.CodSolHerramienta}`}>{herr.CodSolHerramienta}</Link>
                      </td>
                      <td className="txt-center">
                        {isNaN(herr.NumSalida) ? <strong style={{ color: "var(--danger-color)" }}>{herr.NumSalida}</strong> : herr.NumSalida}
                      </td>
                      <td className="txt-center">{renderDate(herr.FecSalida, lang)}</td>
                      <td className="txt-center">{herr.CodHerramienta}</td>
                      <td>{herr.NomHerramienta}</td>
                      <td>{herr.NomEmpleado}</td>
                      <td>{herr.NomEtapa}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        ) : (<div className="m-y-small"><strong style={{ color: "var(--danger-color)", fontSize: "0.9rem" }}>{t("serviceCard.no_herramientas")}</strong></div>)
      }
    </>
  );
};