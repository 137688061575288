
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { CreateOKTSComponent } from "../../components/commercial-orders-comp/CreateOKTSComponent";
import { UpdateOrderComp } from "../../components/commercial-orders-comp/UpdateOrderComp";


// REACT ROUTER IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";

// react useTranslation import
import { useTranslation } from "react-i18next";




export const CommePurchaseOrderDetail = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();

  // params
  const { codOrden } = useParams();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // para guardar la data que viene del servidor
  const [detOrdenState, setDetOrdenState] = useState([]); // estado para cargar el detalle de la orden
  const [controller, setController] = useState(null); // controlador para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ // este state es para todos los campos
    tipOrden: "", urgente: false, enEspera: false, moneda: "", cambio: "", via: "", almacen: "", proveedor: "",
    contacto: "", cliente: "", alias: "", consignatario: "", freightFW: "", incoterms: "", fechaCL: "", region: "",
    trimestre: "", observacion: "", instrucEspeciales: "", accion: "", fecSeguimiento: "", comentario: "", termPagosCliente: "",
    nomProveedor: "", codProveedor: "", estatusPago: "", accion: "1", opID: "", origen: "", fecScode: "", fecSegPago: "", fecDespacho: "",
    fecSalida: "", fecGuia: "", guia: "", nroCajas: 0, grossWeight: 0.00, por: "", montPend: ""
  });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, cliente: "", supplidor: "", filtSuplidor: false, filtCliente: false, questionAction: "", showModalSave: false, loadingData: false, modalOKTS: false, modalSeg: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });
  const [uploadModal, setUploadModal] = useState(false); // modal para cargar los documentos
  const [filterContSuppliers, setFilterContSuppliers] = useState([]); // state para cuando el suplidor

  // DESTRUCTURING DATA SERVER
  const { orderDetail, orderDetailSeg, detOrden, employeePermission } = dataServer && dataServer;


  // ENCONTRAR PROVEEDOR
  const foundSupplier = orderDetail && orderDetail[0]?.suppliers.filter((sup) => sup.Nombre?.toLowerCase().includes(state.supplidor.toLowerCase()));

  // buscar el cliente
  const foundCustomer = orderDetail && orderDetail[0]?.customers.filter((cus) => cus.Nombre.toLowerCase().includes(state.cliente.toLowerCase()));

  // Definicion de etapas
  const CONFIR_BOOKING = 145; // Esperando confirmacion de Booking
  const PEND_ENV_OKTS = 146; // Pendiente Enviar OKTS
  const ESPE_CONFIR_SCODE = 147; // Esperando Confirmacion de Scode
  const ESPE_FEC_DESPA = 148; // Esperando Fecha de Despacho
  const ESPE_SAL_FABRI = 149; // Espera salida de fabrica
  const ESPE_GUIA = 150; // Esperando Guía AWB/BL
  const TRANSITO = 152; // En Transito


  // funcion para notificar al componente padre desde el hijo
  const onNotifyCompFat = (msg = "", error = false) => {
    if (error) {
      setNotification((val) => ({ ...val, show: true, msg, error }));
    } else {
      setNotification((val) => ({ ...val, show: true, msg, great: true, success: true }));
    }
  };


  // funcion para abrir y cerrar el modal de seguimiento
  const onHandleOpenCloseSeg = () => {
    setState((val) => ({ ...val, modalSeg: !state.modalSeg }));
  };


  // funcion para agregar el cliente al estado
  const onAddCustomerAndSupplie = (cod, type = "") => {
    if (type === "Customer") {
      setStateForm((val) => ({ ...val, cliente: cod }));
      setState((val) => ({ ...val, filtCliente: false }));
    } else if (type === "Supplier") {
      setStateForm((val) => ({ ...val, proveedor: cod.toString() }));
      setState((val) => ({ ...val, filtSuplidor: false }));
    }
  };


  // funcion para cambiar el estado del modal de crear el OKTS
  const onHandleCloseOKTS = () => {
    setState((val) => ({ ...val, modalOKTS: false }));
  };


  // funcion para cambiar el valor del actualizar
  const onUpdateChecked = (detOrd = 0, newVal) => {
    setDetOrdenState((val) => {
      return val.map((equip) => {
        if (equip.CodDetOrden === detOrd) {
          return { ...equip, Update: newVal };
        }
        return equip;
      });
    });
  };


  // funcion para abrir el modal para guardar la orden
  const onHandleSaveModal = () => {
    // abrir el moda
    setState((val) => ({ ...val, showModalSave: true }));
  };


  // funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getPurchaseOrderCommercial}/${codOrden}/search?language=${language}&action=1`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codOrden, language]);


  // useEffect para renderizar la nueva data del servidor
  useEffect(() => {
    if (notification.success) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [notification.success]);


  //  useEffect para cargar los datos en el estado
  useEffect(() => {
    if (orderDetail && orderDetail.length > 0) {
      const FecSeguimiento = orderDetail[0]?.FecSeguimiento ? orderDetail[0]?.FecSeguimiento.slice(0, 10) : ""
      setStateForm((val) => ({
        ...val, tipOrden: orderDetail[0]?.IdTipo, urgente: orderDetail[0]?.Urgente, enEspera: orderDetail[0]?.EnEspera, moneda: orderDetail[0]?.IdMoneda, cambio: orderDetail[0]?.CamMoneda, via: orderDetail[0]?.IdVia, almacen: orderDetail[0]?.CodDepartamento ?? "", proveedor: orderDetail[0]?.CodProveedor.toString(),
        contacto: orderDetail[0]?.CodConProveedor.toString(), cliente: orderDetail[0]?.CodCliente, alias: orderDetail[0]?.Alias || "", consignatario: orderDetail[0]?.LEntrega, freightFW: orderDetail[0]?.FreightFw, incoterms: orderDetail[0]?.Incoterm, fechaCL: orderDetail[0]?.FecCL || "", region: orderDetail[0]?.IdRegion,
        trimestre: orderDetail[0]?.IdTrimestre, observacion: orderDetail[0]?.ObsOrden.toLowerCase() || "", instrucEspeciales: orderDetail[0]?.Especial, termPagosCliente: orderDetail[0]?.ConVenta,
        nomProveedor: orderDetail[0]?.nomProveedor, codProveedor: orderDetail[0]?.CodProveedor, estatusPago: orderDetail[0]?.EstPago, fecSeguimiento: FecSeguimiento
      }));

      // setFilterContSuppliers(orderDetail[0]?.contProveedor);
    }
  }, [orderDetail]);


  // useEffect para colocar el nombre del cliente en el input
  useEffect(() => {
    if (orderDetail && orderDetail[0]?.customers.length > 0) {
      const foundCustomer = orderDetail && orderDetail[0]?.customers.find((cus) => cus.CodCliente === stateForm.cliente);

      setState((val) => ({ ...val, cliente: foundCustomer?.Nombre }));
    }
  }, [stateForm.cliente]);


  // useEffect para colocar el nombre del suplidor en el input
  useEffect(() => {
    if (orderDetail && orderDetail[0]?.suppliers) {
      const foundSupplier = orderDetail && orderDetail[0]?.suppliers.find((sup) => sup.CodProveedor.toString() === stateForm.proveedor);

      setState((val) => ({ ...val, supplidor: foundSupplier?.Nombre }));
    }
  }, [stateForm.proveedor]);

  // useEffect para buscar los contactos de ese suplidor
  useEffect(() => {
    if (orderDetail && orderDetail[0]?.contProveedor.length > 0) {
      const filterContSuppliers = orderDetail && orderDetail[0]?.contProveedor.filter((con) => con.CodProveedor.toString() === stateForm.proveedor);

      setFilterContSuppliers(filterContSuppliers);
    }
  }, [stateForm.proveedor]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.show]);

  // useEffect para cargar la data del detalle de la orden en el estado
  useEffect(() => {
    if (detOrden && detOrden.length > 0) {
      setDetOrdenState(detOrden);
    }
  }, [detOrden]);

  // handle submit para guardar la data en el servidor
  const handleUpdateOrder = async (event) => {
    event.preventDefault();

    const finalObj = { stateForm, detOrdenState };

    try {
      const response = await fetch(`${urls.getPurchaseOrderCommercial}/${codOrden}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setStateForm((val) => ({ ...val, comentario: "" }));
        setState((val) => ({ ...val, showModalSave: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setState((val) => ({ ...val, showModalSave: false }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {
        // component notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={handleUpdateOrder}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* # ORDEN */}
                <div className="col-9"><strong># Orden</strong></div>
                <div className="col-9"><h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>{orderDetail && orderDetail[0]?.NumOrden}</h4></div>
                {/* FECHA */}
                <div className="col-10"><strong>Fecha/Hora</strong></div>
                <div className="col-9"><span>{orderDetail && renderDate(orderDetail[0]?.FecIngreso, language)}</span></div>
                {/* SOLICITUD */}
                <div className="col-10"><strong>Solicitud</strong></div>
                <div className="col-10"><h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>{orderDetail && orderDetail[0]?.CodOrden}</h4></div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* TIPO DE SOLICITUD */}
                <div className="col-9"><strong>Tipo de Orden</strong></div>
                <div className="col-3">
                  <select
                    name="orderType"
                    value={stateForm.tipOrden}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipOrden: target.value }))}>
                    <option value=""></option>
                    <option value="0">Para la Venta</option>
                    <option value="1">Stock</option>
                    <option value="2">Demo</option>
                    <option value="3">Indent</option>
                    <option value="4">Biotech</option>
                  </select>
                </div>
                {/* ETAPA */}
                <div className="col-7"><strong>Etapa</strong></div>
                <div className="col-3"><span className="step-radius-commercial">{orderDetail && orderDetail[0]?.NomEtapa}</span></div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* MONEDA */}
                <div className="col-9"><strong>Moneda</strong></div>
                <div className="flex-container col-3">
                  <div className="col-4">
                    <select
                      name="idMoneda"
                      value={stateForm.moneda}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                      <option value="0">Dólar</option>
                      <option value="1">Euro</option>
                      <option value="2">Peso</option>
                    </select>
                  </div>
                  {/* CAMBIO */}
                  <div className="col-4"><strong>Cambio</strong></div>
                  <div className="flex-auto">
                    <input
                      className="input-text-control"
                      type="text"
                      name="cambio"
                      value={stateForm.cambio}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, cambio: target.value }))} />
                  </div>
                </div>
                {/* URGENTE -- EN ESPERA */}
                <div className="col-7"></div>
                <div className="flex-container align-c col-3">
                  {/* ES URGENTE */}
                  <div className="col-7"><strong>Urgente?</strong></div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="urgente"
                      checked={stateForm.urgente}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, urgente: target.checked }))} />
                  </div>
                  <div className="col-5"><strong>En Espera?</strong></div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="enEspera"
                      checked={stateForm.enEspera}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, enEspera: target.checked }))} /></div>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* VIA */}
                <div className="col-9"><strong>Via</strong></div>
                <div className="col-3">
                  <select
                    name="via"
                    value={stateForm.via}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, via: target.value }))}>
                    <option value="0">Aerea</option>
                    <option value="1">Maritima</option>
                    <option value="2">Terrestre</option>
                  </select>
                </div>
                {/* ALMACEN */}
                <div className="col-7"><strong>Almacén</strong></div>
                <div className="col-3 flex-grow">
                  <select
                    name="wareHouse"
                    value={stateForm.almacen}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, almacen: target.value }))}>
                    <option value=""></option>
                    {
                      orderDetail && orderDetail[0]?.dynDepart.map((dep) => (
                        <option key={dep.CodDepartamento} value={dep.CodDepartamento}>{dep.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* PROVEEDOR */}
                <div className="col-9"><strong>Proveedor</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    placeholder={language === "en" ? "Type the supplier" : "Escriba el proveedor"}
                    type="text"
                    name="supplier"
                    readOnly
                    value={state.supplidor}
                    onFocus={() => setState((val) => ({ ...val, filtSuplidor: true }))} />
                </div>
                {/* CONTACTO */}
                <div className="col-7"><strong>Contacto</strong></div>
                <div className="col-3 flex-grow">
                  <select
                    name="contact"
                    value={stateForm.contacto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, contacto: target.value }))}>
                    <option value=""></option>
                    {
                      filterContSuppliers && filterContSuppliers.map((cont) => (
                        <option key={cont.CodConProveedor} value={cont.CodConProveedor}>{cont.Nombre} {cont.Apellido}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-9"><strong>Cliente</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="customer"
                    readOnly
                    value={state.cliente}
                    onFocus={() => setState((val) => ({ ...val, filtCliente: true }))}
                    placeholder={language === "en" ? "Type the customer" : "Escriba el cliente"} />
                </div>
                {/* ALIAS */}
                <div className="col-7">
                  <strong>Alias</strong>
                </div>
                <div className="col-3 flex-grow">
                  <input
                    className="input-text-control"
                    type="text"
                    name="alias"
                    value={stateForm.alias}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, alias: target.value }))} />
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* CONSIGNATARIO */}
                <div className="col-9">
                  <strong>Consignatario</strong>
                </div>
                <div className="col-82 flex-grow">
                  <textarea
                    className="textarea-description"
                    name="consignatario"
                    value={stateForm.consignatario}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, consignatario: target.value }))} />
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* FREIGHT FW */}
                <div className="col-9">
                  <strong>Freight FW</strong>
                </div>
                <div className="col-82 flex-grow">
                  <textarea
                    className="textarea-description"
                    name="freightFW"
                    value={stateForm.freightFW}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, freightFW: target.value }))} />
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* INCOTERM */}
                <div className="col-9">
                  <strong>Incoterm</strong>
                </div>
                <div className="col-8">
                  <select
                    name="incoterm"
                    value={stateForm.incoterms}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, incoterms: target.value }))}>
                    <option value="CIF">CIF</option>
                    <option value="EXW">EXW</option>
                    <option value="FOB">FOB</option>
                    <option value="FAS">FAS</option>
                    <option value="FCA">FCA</option>
                    <option value="CFR">CFR</option>
                    <option value="CPT">CPT</option>
                    <option value="DAT">DAT</option>
                    <option value="DAP">DAP</option>
                    <option value="DDP">DDP</option>
                  </select>
                </div>
                {/* FECHA CL */}
                <div className="col-10"><strong>Fecha CL</strong></div>
                <div className="col-8">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fechaCL"
                    value={stateForm.fechaCL}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fechaCL: target.value }))} />
                </div>
                {/* REGION */}
                <div className="col-10"><strong>Region</strong></div>
                <div className="col-7">
                  <select
                    name="region"
                    value={stateForm.region}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, region: target.value }))}>
                    <option value=""></option>
                    <option value="1">Caribe - DI</option>
                    <option value="2">Caribe - US & LCS</option>
                    <option value="3">Caribe - Suministros</option>
                    <option value="4">RD - DI</option>
                    <option value="5">RD - US & LCS</option>
                    <option value="6">RD - Suministros</option>
                    <option value="9">TT - DI</option>
                    <option value="10">TT - US & LCS</option>
                    <option value="11">TT - Supplies</option>
                    <option value="7">VZLA - DI</option>
                    <option value="8">VZLA - US & LCS</option>
                  </select>
                </div>
                {/* TRIMESTRE */}
                <div className="col-10"><strong>Trimestre</strong></div>
                <div className="col-8">
                  <select
                    name="trimestre"
                    value={stateForm.trimestre}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, trimestre: target.value }))}>
                    <option value=""></option>
                    <option value="1">Q1</option>
                    <option value="2">Q2</option>
                    <option value="3">Q3</option>
                    <option value="4">Q4</option>
                    <option value="5">Q5</option>
                    <option value="6">Q6</option>
                    <option value="7">Q7</option>
                    <option value="8">Q8</option>
                  </select>
                </div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-9">
                  <strong>Observación</strong>
                </div>
                <div className="col-82 flex-grow">
                  <textarea
                    style={{ textTransform: 'capitalize' }}
                    className="textarea-description"
                    name="observacion"
                    value={stateForm.observacion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, observacion: target.value }))} />
                </div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* INSTRUCCIONES ESPECIALES DE DESPACHO */}
                <div className="col-9">
                  <strong>Instrucciones Especiales de Despacho</strong>
                </div>
                <div className="col-82 flex-grow">
                  <textarea
                    className="textarea-description"
                    name="instruccEspeciales"
                    value={stateForm.instrucEspeciales}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, instrucEspeciales: target.value }))} />
                </div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex">
                {/* DOCUMENTOS */}
                <div className="col-9"><strong>Documentos</strong></div>
                <div className="col-7">
                  <button type="button" onClick={() => setUploadModal(true)} className="btn-docs">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                      <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                      <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </button>
                </div>
                {/* FACTURA */}
                <div className="col-9"><strong>Factura</strong></div>
                <div className="col-7"></div>
                {/* OKTS */}
                <div className="col-9">
                  <button type="button" className="btn-link-tag" onClick={() => setState((val) => ({ ...val, modalOKTS: true }))}>OKTS</button>
                </div>
              </div>

              <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Anticipos</h3>
              {/* FILA 13 */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th># Recibo</th>
                      <th>Fecha Factura</th>
                      <th>Monto USD$</th>
                      <th>Saldo USD$</th>
                      <th>Descripción</th>
                    </tr>
                  </thead>
                </table>
              </div>



              {/* FILA */}
              <div className="row-flex">
                <div className="col-9">
                  <strong>Fecha de seguimiento</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecSeguimiento"
                    value={stateForm.fecSeguimiento}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecSeguimiento: target.value }))} />
                </div>
              </div>


              {/* FILA 14 */}
              <div className="row-flex m-y-small">
                <div className="col-9">
                  <strong>Comentario</strong>
                </div>
                <div className="col-82 flex-grow">
                  <textarea
                    className="textarea-description"
                    value={stateForm.comentario}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))} /></div>
              </div>

              {/* FILA 15 */}
              <div className="row-flex j-content-space-around">
                {/* BOTONES DE ACCION */}
                <button type="button" className="btn-primary" onClick={onHandleSaveModal}>Guardar</button>
                <button type="button" className="btn-info">Factura Global</button>
                <button type="button" className="btn-info">Factura Biotech</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                <button type="button" className="btn-success" onClick={onHandleOpenCloseSeg}>Seguimiento</button>
              </div>

              {/* FILA 16 */}
              <div className="row-flex m-y-small">
                {/* AGREGAR EQUIPO */}
                <div className="col-9">
                  <strong>Agregar Equipo</strong>
                </div>
                <div className="col-9">
                  <button type="button" style={{ cursor: "pointer" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                      <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* FILA 17 */}
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>Cantidad</th>
                      <th>Artículo</th>
                      <th>Descripción</th>
                      <th>Servicio</th>
                      <th>Usuario Final</th>
                      <th>Origen</th>
                      <th>Vend</th>
                      <th>NTD$</th>
                      <th>Total NTD$</th>
                      <th>Venta $</th>
                      <th>Total Venta $</th>
                      <th>CA</th>
                      <th>POD</th>
                      <th>Guía</th>
                      <th>PL</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      detOrdenState && detOrdenState.map((equi) => (
                        <tr key={equi.CodDetOrden}>
                          <td className="txt-center">
                            <button type="button" className="btn-link-tag" onClick={() => navigate(`/commercial/purchase-order/order-detail/${equi.CodOrden}/${equi.CodDetOrden}`)}>{equi.CodDetOrden}</button>
                          </td>
                          <td className="txt-center">{equi.Cantidad}</td>
                          <td>{equi.NumParte}</td>
                          <td>
                            {
                              equi.CodModelo !== null ? <Link className={equi.CodEquipo !== null ? "link-tag" : "fake-link"} to={equi.CodEquipo !== null ? `/equipments/equipment-detail/${equi.CodEquipo}` : null}>{equi.NomModelo}</Link> : equi.Descripcion
                            }
                          </td>
                          <td className="txt-center">
                            {equi.CodServicio ? <Link className="link-tag" to={`/service/service-call/${equi.CodServicio}`}>{equi.CodServicio}</Link> : "NA"}
                          </td>
                          <td>
                            <Link className="link-tag" to={`/customers/customer-detail/${equi.CodCliente}`}>{equi.NomCliente}</Link>
                          </td>
                          <td>{equi.Origen}</td>
                          <td className="txt-center">{equi.Inicial}</td>
                          <td style={{ color: "var(--money-color)", fontWeight: "bold" }}>{formatCurrency(equi.NTD, "USD")}</td>
                          <td style={{ color: "var(--money-color)", fontWeight: "bold" }}>{formatCurrency(equi.NTD * equi.Cantidad, "USD")}</td>
                          <td style={{ color: "var(--money-color)", fontWeight: "bold" }}>{formatCurrency(equi.Precio, "USD")}</td>
                          <td style={{ color: "var(--money-color)", fontWeight: "bold" }}>{formatCurrency(equi.Precio * equi.Cantidad, "USD")}</td>
                          <td>
                            {equi.CA ?
                              <button type="button" className="btn-pdf">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                  <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                  <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                </svg>
                              </button>
                              : null}
                          </td>
                          <td>
                            {
                              equi.POD ?
                                <button type="button" className="btn-pdf">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                    <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                  </svg>
                                </button>
                                : null
                            }
                          </td>
                          <td className="txt-center">
                            {equi.DocGuia ? <button type="button" className="btn-link-tag">{equi.Guia}</button> : equi.Guia}
                          </td>
                          <td>
                            {
                              equi.PackList ?
                                <button type="button" className="btn-pdf">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                    <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                  </svg>
                                </button>
                                : null
                            }
                          </td>
                          <td>
                            <input
                              className="input-control-checkbox"
                              type="checkbox"
                              name={`Order-${equi.CodDetOrden}`}
                              checked={equi.Update}
                              onChange={({ target }) => onUpdateChecked(equi.CodDetOrden, target.checked)} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* FILA 18 */}
              <div className="row-flex m-y-small">
                {/* TERMINOS DE PAGO DEL CLIENTE */}
                <div className="col-9">
                  <strong>Terminos de Pago del Cliente</strong>
                </div>
                <div className="col-82 flex-grow">
                  <textarea
                    className="textarea-description"
                    name="termPago"
                    value={stateForm.termPagosCliente}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, termPagosCliente: target.value }))} /></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Estatus pago del cliente */}
                <div className="col-9">
                  <strong>Estatus pago del cliente</strong>
                </div>
                <div className="col-82 flex-grow">
                  <textarea
                    className="textarea-description"
                    name="statusPagClie"
                    value={stateForm.estatusPago}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, estatusPago: target.value }))} />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Fecha seguimiento del pago */}
                <div className="col-9"><strong>Fecha seguimiento del pago</strong></div>
                <div className="col-7">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecSegPag"
                    value={stateForm.fecSegPago}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecSegPago: target.value }))} />
                </div>
              </div>

              {/* SEGUIMIENTO */}
              <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Seguimiento</h3>

              {/* TABLA DE SEGUIMIENTO */}
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Fecha</th>
                      <th>Etapa Actual</th>
                      <th>Etapa Anterior</th>
                      <th>Responsable cambio Etapa</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      orderDetailSeg && orderDetailSeg.map((seg) => (
                        <tr key={seg.CodSegOrden}>
                          <td>{renderDate(seg.FecIngreso, language)}</td>
                          <td>{seg.etapaActual}</td>
                          <td>{seg.etapaAnterior}</td>
                          <td>{seg.NomEmpleado}</td>
                          <td style={{ textTransform: 'capitalize' }}>{seg.Accion.toLowerCase() || ""}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {
                state.showModalSave && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success" disabled={state.loadingData}>
                          {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                        </button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }
      </div >

      {
        // modal para filtrar los suplidores
        state.filtSuplidor && (
          <div className="background-modal" onClick={() => setState((val) => ({ ...val, filtSuplidor: false }))}>
            <div className="container-modal-filter-customer p-1" onClick={(event) => event.stopPropagation()}>
              <div>
                <input
                  className="input-text-control"
                  type="text"
                  name="searchSupplier"
                  value={state.supplidor}
                  onChange={({ target }) => setState((val) => ({ ...val, supplidor: target.value }))} />
              </div>

              {/* buscar el nombre del empleado */}
              <div className="m-y-small container-filter-cust">
                {
                  foundSupplier && foundSupplier.map((sup) => (
                    <div key={sup.CodProveedor} className="row-flex border-bottom hover-class">
                      <div className="col-80">
                        <strong>{sup.Nombre}</strong>
                      </div>
                      <div className="col-5 row-flex flex-end">
                        <button style={{ cursor: "pointer" }} type="button" onClick={() => onAddCustomerAndSupplie(sup.CodProveedor, "Supplier")}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                            <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }

      {
        // modal para filtrar los suplidores
        state.filtCliente && (
          <div className="background-modal" onClick={() => setState((val) => ({ ...val, filtCliente: false }))}>
            <div className="container-modal-filter-customer p-1" onClick={(event) => event.stopPropagation()}>
              <div>
                <input
                  className="input-text-control"
                  type="text"
                  name="searchCustomer"
                  value={state.cliente}
                  onChange={({ target }) => setState((val) => ({ ...val, cliente: target.value }))} />
              </div>

              {/* buscar el nombre del empleado */}
              <div className="m-y-small container-filter-cust">
                {
                  foundCustomer && foundCustomer.map((cust) => (
                    <div key={cust.CodCliente} className="row-flex border-bottom hover-class">
                      <div className="col-80">
                        <strong>{cust.Nombre}</strong>
                      </div>
                      <div className="col-5 row-flex flex-end">
                        <button style={{ cursor: "pointer" }} type="button" onClick={() => onAddCustomerAndSupplie(cust.CodCliente, "Customer")}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                            <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }

      {/* MODAL PARA CARGAR LOS DOCUMENTOS */}
      {
        uploadModal && (
          <div className="background-modal" onClick={() => setUploadModal(false)}>
            <div className="container-modal" onClick={(event) => event.stopPropagation()}>
              <form>
                <h3 style={{ textAlign: 'center', color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Subir Documento</h3>
                {/* FILA 1 */}
                <div className="row-flex">
                  {/* NOMBRE */}
                  <div className="col-4"><strong>Nombre</strong></div>
                  <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" /></div>
                </div>

                {/* FILA 2 */}
                <div className="row-flex">
                  {/* DOUCMENTO A SUBIR */}
                  <div className="col-4"><strong>Documento a Subir</strong></div>
                  <div className="col-3"><input type="file" name="sourceDocument" /></div>
                </div>

                {/* fila */}
                <div className="row-flex">
                  <div className="col-4"><strong>Tipo</strong></div>
                  <div className="col-3">
                    <select name="typeDoc" id="">
                      <option value=""></option>
                      <option value="1">CA</option>
                      <option value="2">POD</option>
                      <option value="3">Packing list</option>
                      <option value="4">Aplicación 1</option>
                      <option value="5">Aplicación 2</option>
                      <option value="6">Aplicación 3</option>
                    </select>
                  </div>
                </div>

                {/* FILA 3 */}
                <div className="row-flex">
                  {/* TIPO DE DOCUMENTO */}
                  <div className="col-4"><strong>Archivo</strong></div>
                  <div className="col-3">
                    <select name="fileType">
                      <option value="0">PDF</option>
                      <option value="1">Excel</option>
                    </select>
                  </div>
                </div>

                {/* FILA 4 */}
                <div className="row-flex">
                  <div className="col-4"><strong>Visible a Servicio</strong></div>
                  <div className="col-3"><input className="input-control-checkbox" type="checkbox" name="visibleServicio" /></div>
                </div>

                {/* BOTONES DE ACCION */}
                <div className="row-flex m-y-small j-content-space-around">
                  <button className="btn-primary">Subir</button>
                  <button type="reset" onClick={() => setUploadModal(false)} className="btn-secondary">Cancelar</button>
                </div>
              </form>
            </div>
          </div>
        )
      }

      {
        state.modalSeg && (<UpdateOrderComp onClose={onHandleOpenCloseSeg} codOrd={codOrden} token={getTheToken().token} lang={language} setNotify={onNotifyCompFat} />)
      }

      {
        // modal para generar el OKTS
        state.modalOKTS && (
          <CreateOKTSComponent onClose={onHandleCloseOKTS} arrOKTS={orderDetail[0]?.OKTS} arrFirm={orderDetail[0]?.firmantes} />
        )
      }
    </>
  );
};