// este componente es para ver el detalle del backlog luego de que se crea

import { useEffect, useState } from "react";


// components imports
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { SelectCustomerComp } from "../../components/commercial-orders-comp/SelectCustomerComp";
import { SelectEquipmentComp } from "../../components/commercial-orders-comp/SelectEquipmentComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// react router dom import
import { useLocation, useNavigate, useParams } from "react-router-dom";

// global functions import
import { formatVal, getTheToken, renderDate } from "../../global-functions/globalFunctions";

// urls imports
import { urls } from "../../api-urls/api-urls";

// react translation
import { useTranslation } from "react-i18next";




export const BackLogDetail = () => {

  // language
  const lang = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codOrdenDet } = useParams();

  // END POINT para consultar los equipos
  const END_POINT_EQUIP = `${urls.commercialRequest}?language=${lang}&action=4`;
  const END_POINT_CUSTO = `${urls.commercialRequest}?language=${lang}&action=5`;



  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data inicial
  const [contacto, setContacto] = useState([]); // estado para colocar el contacto
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({
    fecCompro: "", suministro: "", quote: "", codModel: 0, codClien: "", descriptLar: "", vendedor: "", gon: "", poa: "", opp: "", cantidad: "", nroPart: "", systemID: "",
    ntd: "0.00", precioVent: "0.00", numCot: "", anticipo: "", montoAnti: "0.00", fecAntici: "", tieGaran: "", destino: "", conVenta: "", codContacto: "", nomContac: "", telefConta: ""
  });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, modalCust: false, cliente: "", descrip: "", modalSave: false, questionAction: "", loadingData: false, modalEquip: false, etapa: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // destructuring data server
  const { detOrden, segDetOrden, employees, destino, contacts } = dataServer && dataServer;



  // funcion para abrir el modal de guardar
  const onHandleSaveMod = () => {

    setState((val) => ({ ...val, modalSave: true, questionAction: "Está seguro de guardar los cambios?" }));
  };



  // funcion para guardar los datos en el serv
  const onHandleSaveForm = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loadingData: true }));
      const response = await fetch(`${urls.commercialRequest}/${codOrdenDet}/search?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
        setState((val) => ({ ...val, modalSave: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, loadingData: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // funcion para cerrar el modal de los clientes
  const onHandleCloseModCusto = () => {
    setState((val) => ({ ...val, modalCust: false }));
  };

  // funcion para cerrar el modal de los equipos
  const onHandleCloseModEquip = () => {
    setState((val) => ({ ...val, modalEquip: false }));
  };

  // funcion para agregar el cliente al estado
  const onHandleAddCustomer = (cod = "", nom = "") => {
    setState((val) => ({ ...val, cliente: nom }));
    setStateForm((val) => ({ ...val, codClien: cod }));
  };

  // funcion para agregar el modelo al estado
  const onHandleAddModel = (cod = 0, nom = "") => {
    setState((val) => ({ ...val, descrip: nom }));
    setStateForm((val) => ({ ...val, codModel: cod }));
  };



  // funcion para traer la data del servidor
  const fetchDataServer = async (action = "") => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.commercialRequest}/${codOrdenDet}/search?language=${lang}&action=${action}&custom=${stateForm.codClien}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        if (action === "1") {
          setDataServer(data);
        } else {
          setContacto(data?.contacts);
        }
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cargar la data del servidor
  useEffect(() => {

    fetchDataServer("1");

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);//816858781


  // useEffect para cargar la informacion en el estado
  useEffect(() => {
    if (detOrden && detOrden.length > 0) {

      setState((val) => ({ ...val, cliente: detOrden[0]?.NomCliente, descrip: detOrden[0]?.Equipo ?? "", etapa: detOrden[0]?.Etapa }));

      const FEC_COMPRO = detOrden[0]?.FecCompromiso ? detOrden[0]?.FecCompromiso.slice(0, 10) : "";

      const SUMINISTRO = Number(detOrden[0]?.EsSuministro).toString();

      const FEC_ANTICIPO = detOrden[0]?.FecAnticipo ? detOrden[0]?.FecAnticipo.slice(0, 10) : "";

      let anti = "";
      let monAnti = 0.00;

      if (detOrden[0]?.MonAnticipo !== null) {
        monAnti = detOrden[0]?.MonAnticipo; // monto de anticipo
        if (detOrden[0]?.MonAnticipo > 0) {
          anti = "0";
        } else {
          anti = "1";
        }
      }

      // garantia
      let garan = "";
      if (detOrden[0]?.Garantia !== null) {
        if (detOrden[0]?.Garantia.trim() !== "") {
          garan = "0";
        } else {
          garan = "1";
        }
      }

      setStateForm((val) => ({
        ...val, fecCompro: FEC_COMPRO, suministro: SUMINISTRO, codClien: detOrden[0]?.CodCliente, codModel: detOrden[0]?.CodModelo ?? "",
        quote: detOrden[0]?.Quote ?? "", vendedor: detOrden[0]?.CodEmpleado ?? "", descriptLar: detOrden[0]?.DesLarga ?? "", gon: detOrden[0]?.GON ?? "",
        poa: detOrden[0]?.POA ?? "", opp: detOrden[0]?.OPP ?? "", cantidad: detOrden[0]?.Cantidad, nroPart: detOrden[0]?.NumParte ?? "", systemID: detOrden[0]?.NumSistema ?? "",
        ntd: detOrden[0]?.NTD ?? 0.00, precioVent: detOrden[0]?.Precio ?? 0.00, numCot: detOrden[0]?.NumCot ?? "", anticipo: anti, montoAnti: monAnti ?? "", fecAntici: FEC_ANTICIPO, tieGaran: garan ?? "",
        destino: detOrden[0]?.CodDestino ?? "", conVenta: detOrden[0]?.ConVenta ?? "", nomContac: detOrden[0]?.Contacto ?? "", telefConta: detOrden[0]?.Telefono ?? ""
      }));

      setContacto(contacts);

    }
  }, [detOrden]);


  // useEffect por si cambian el cliente, trae los contactos de ese cliente
  useEffect(() => {
    if (detOrden && detOrden[0]?.CodCliente !== stateForm.codClien) {
      fetchDataServer("2");
      setStateForm((val) => ({ ...val, nomContac: "", telefConta: "" }));
    }
  }, [stateForm.codClien]);


  // useEffect para colocar el estado de los campos en contacto
  useEffect(() => {
    if (stateForm.codContacto) {
      const findContact = contacto && contacto.find((cont) => cont.CodContacto.toString() === stateForm.codContacto);

      setStateForm((val) => ({ ...val, nomContac: findContact?.Nombre, telefConta: findContact?.Telefono }));
    }
  }, [stateForm.codContacto]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 4000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);




  return (
    <div className="container display-data">
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // componente para seleccionar el cliente
        state.modalCust && (<SelectCustomerComp onClose={onHandleCloseModCusto} onAddCust={onHandleAddCustomer} EndPoint={END_POINT_CUSTO} HttMethod={"POST"} token={getTheToken().token} />)
      }
      {
        // componente para seleccionar el equipo
        state.modalEquip && (<SelectEquipmentComp onClose={onHandleCloseModEquip} EndPoint={END_POINT_EQUIP} HttpMethod={"POST"} token={getTheToken().token} onAddModel={onHandleAddModel} />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        !state.dataVisible && (<button type="button" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>)
      }
      {
        state.dataVisible && (
          <>
            <form onSubmit={onHandleSaveForm}>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Fecha de compromiso</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecCompro"
                    value={stateForm.fecCompro}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecCompro: target.value }))} />
                </div>
                <div className="col-19">
                  <strong>Orden</strong>
                </div>
                <div className="col-7">
                  <strong style={{ color: "rgb(227,46,55)" }}>Por solicitar</strong>
                </div>
                <div className="col-7">
                  <strong>Es equipo?</strong>
                </div>
                <div className="col-5">
                  <select
                    name="isEquip"
                    value={stateForm.suministro}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, suministro: target.value }))}>
                    <option value=""></option>
                    <option value="0">Si</option>
                    <option value="1">No</option>
                  </select>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Cliente</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="customer"
                    onFocus={() => setState((val) => ({ ...val, modalCust: true }))}
                    value={state.cliente}
                    readOnly />
                </div>
                <div className="col-7">
                  <strong>Etapa</strong>
                </div>
                <div className="col-7">
                  <strong style={{ color: "rgb(227,46,55)" }}>{state.etapa}</strong>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Descripción</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="descrip"
                    onFocus={() => setState((val) => ({ ...val, modalEquip: true }))}
                    value={state.descrip}
                    readOnly />
                </div>
                <div className="col-7">
                  <strong># Quote</strong>
                </div>
                <div className="col-34-29">
                  <input
                    className="input-text-control"
                    type="text"
                    name="quote"
                    value={stateForm.quote}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, quote: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Descripción larga</strong>
                </div>
                <div className="col-3">
                  <textarea
                    className="textarea-description"
                    name="descripLong"
                    value={stateForm.descriptLar}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, descriptLar: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-7">
                  <strong>Vendedor</strong>
                </div>
                <div className="col-34-29">
                  <select
                    name="salesPer"
                    value={stateForm.vendedor}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, vendedor: target.value }))}>
                    <option value=""></option>
                    {
                      employees && employees.map((emp) => (
                        <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>GON</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control"
                    type="text"
                    name="gon"
                    value={stateForm.gon}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, gon: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-19">
                  <strong>POA#</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control"
                    type="text"
                    name="poa"
                    value={stateForm.poa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, poa: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-7">
                  <strong>OPP</strong>
                </div>
                <div className="col-5">
                  <input
                    className="input-text-control"
                    type="text"
                    name="opp"
                    value={stateForm.opp}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, opp: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Cantidad</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control"
                    type="text"
                    name="quantity"
                    value={stateForm.cantidad}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, cantidad: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-19">
                  <strong>Nro. Parte</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control"
                    type="text"
                    name="partNum"
                    value={stateForm.nroPart}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nroPart: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-7">
                  <strong>System ID</strong>
                </div>
                <div className="col-5">
                  <input
                    className="input-text-control"
                    type="text"
                    name="systemID"
                    value={stateForm.systemID}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, systemID: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              <div className="row-flex">
                <div className="col-7">
                  <strong>NTD $</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control txt-right"
                    type="text"
                    name="ntd"
                    value={formatVal(stateForm.ntd)}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ntd: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-19">
                  <strong>Precio de Venta</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control txt-right"
                    type="text"
                    name="salesPric"
                    value={formatVal(stateForm.precioVent)}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, precioVent: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-7">
                  <strong># Cot Venta</strong>
                </div>
                <div className="col-5">
                  <input
                    className="input-text-control"
                    type="text"
                    name="cotVen"
                    value={stateForm.numCot}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, numCot: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Anticipo</strong>
                </div>
                <div className="col-7">
                  <select
                    name="anticipo"
                    value={stateForm.anticipo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, anticipo: target.value }))}>
                    <option value=""></option>
                    <option value="0">Si</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-19">
                  <strong>Monto anticipo</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control txt-right"
                    type="text"
                    name="montAntic"
                    value={formatVal(stateForm.montoAnti)}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, montoAnti: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-7">
                  <strong>Fecha anticipo</strong>
                </div>
                <div className="col-5">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecAnti"
                    value={stateForm.fecAntici}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecAntici: target.value }))} />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Posee garantía extendida?</strong>
                </div>
                <div className="col-7">
                  <select
                    name="garanExt"
                    value={stateForm.tieGaran}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tieGaran: target.value }))}>
                    <option value=""></option>
                    <option value="0">Si</option>
                    <option value="1">No</option>
                  </select>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Destino</strong>
                </div>
                <div className="col-3">
                  <select
                    name="destin"
                    value={stateForm.destino}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, destino: target.value }))}>
                    <option value=""></option>
                    {
                      destino && destino.map((des) => (
                        <option key={des.CodDestino} value={des.CodDestino}>{des.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Términos de pago del cliente</strong>
                </div>
                <div className="col-81-91">
                  <textarea
                    className="textarea-description"
                    name="termPagClie"
                    value={stateForm.conVenta}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, conVenta: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              <hr className="m-y-small" />

              {/* fila */}
              <div className="row-flex">
                <div className="col-3">
                  <strong style={{ textDecoration: "underline" }}>Cliente que aceptó la cotización</strong>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Contacto</strong>
                </div>
                <div className="col-3">
                  <select
                    name="contact"
                    value={stateForm.codContacto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, codContacto: target.value }))}>
                    <option value=""></option>
                    {
                      contacto && contacto.map((cont) => (
                        <option key={cont.CodContacto} value={cont.CodContacto}>{cont.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Nombre</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="nomContact"
                    value={stateForm.nomContac}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nomContac: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-7">
                  <strong>Teléfono</strong>
                </div>
                <div className="col-34-29">
                  <input
                    className="input-text-control"
                    type="text"
                    name="teleContact"
                    value={stateForm.telefConta}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, telefConta: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              <div className="row-flex j-content-space-around m-y-medium">
                <button type="button" className="btn-primary" onClick={onHandleSaveMod}>Guardar</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              {
                state.modalSave && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalSave: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success" disabled={state.loadingData}>
                          {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                        </button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, modalSave: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>

            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>Fecha</th>
                    <th>Etapa actual</th>
                    <th>Próxima Etapa</th>
                    <th>Responsable cambio de Etapa</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    segDetOrden && segDetOrden.map((seg) => (
                      <tr key={seg.CodSegDetOrden}>
                        <td className="txt-center">{renderDate(seg.FecIngreso, lang)}</td>
                        <td>{seg.EtapaActual}</td>
                        <td>{seg.Etapa}</td>
                        <td>{seg.NomEmpleado}</td>
                        <td>{seg.Accion}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
    </div>
  );
};