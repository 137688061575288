import React, { useEffect, useState } from "react";

// IMPORTATION OF REACT ROUTER DOM
import { useParams, useNavigate, Link } from "react-router-dom";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { formatVal, getTheToken, renderDate, renderIdTipSolicitud } from "../../global-functions/globalFunctions";

// React translation
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../global-functions/globalFunctions.js";


// useFetch custom hook
import { useFetchData } from "../../components/customs/part-view/useFetchData.js";





export const PartView = () => {

  // GET the useParams
  const { codDetSolR, solRepuesto } = useParams();


  // GET the navigate
  const navigate = useNavigate();


  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  const { state, form, otherInf, tblDetSegRepuesto, firstInfo, onHandleChangeForm, onSaveInfoModal, onCloseModal } = useFetchData(`${urls.partDetail}/${solRepuesto}/${codDetSolR}/search?language=${language}&flag=0`);
  // GET the react translation
  const [t] = useTranslation("global");


  // STATE DEFINITION
  const [notification, setNotification] = useState({ show: true, msg: "", error: false, great: false, success: false });


  // DESTRUCTURING DATA SERVER

  // desabilitar el input si exportado es true 
  const INPUT_DISABLED = state.exportado && otherInf.prof;



  // handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.partDetail}/${solRepuesto}/${codDetSolR}/search?language=${language}&flag=0`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.status === 200) {
        onCloseModal();
        setNotification({ show: true, msg: data?.msg, error: false, great: true, success: true });
      } else if (response.status === 404) {
        onCloseModal();
        setNotification({ show: true, msg: data?.msg, error: true, great: false, success: false });
      }

    } catch (error) {
      console.log(error);
    }
  };




  // useEffect para limpiar las notification
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);



  return (
    <>
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <form onSubmit={handleSubmit}>
                {/* FILA 1 */}
                <div className="row-flex">
                  {/* ORDEN DE COMPRA */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.orden_compra")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <Link className="link-tag">
                      <h3 style={{ color: 'blue' }}>{firstInfo && firstInfo[0]?.NumOrden}</h3>
                    </Link>
                  </div>
                  {/* SOLICITUD */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.solicitud")}</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod">
                    <Link className="link-tag" to={`/request-part-detail/${firstInfo && firstInfo[0]?.CodSolRepuesto}`}>
                      <h3 style={{ color: 'blue' }}>{firstInfo && firstInfo[0]?.CodSolRepuesto}</h3>
                    </Link>
                  </div>
                  {/* FECHA HORA */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.fecha")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{firstInfo && renderDate(firstInfo[0]?.FecIngreso, language)}</span>
                  </div>
                </div>

                {/* FILA 2 */}
                <div className="row-flex">

                  {/* ID SERVICIO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.id_serv")}</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod">
                    <Link className="link-tag" to={`/service/service-call/${firstInfo && firstInfo[0]?.CodServicio}`}>
                      <h3 style={{ color: 'blue' }}>{firstInfo && firstInfo[0]?.CodServicio}</h3>
                    </Link>
                  </div>
                  {/* EQUIPO */}
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* CLIENTE */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.cliente")}</strong>
                  </div>
                  <div className="col-80 col-sm-70-mod text-hidden-over">
                    <Link className="link-tag" to={`/customers/customer-detail/${firstInfo && firstInfo[0]?.CodCliente}`}>{firstInfo && firstInfo[0]?.NomCliente}</Link>
                  </div>
                </div>

                {/* EQUIPO */}
                <div className="row-flex">
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.equip")}</strong>
                  </div>
                  <div className="col-80 col-sm-70-mod">
                    <Link className="link-tag" to={`/equipments/equipment-detail/${firstInfo && firstInfo[0]?.CodEquipo}`}>{firstInfo && firstInfo[0]?.Equipo}</Link>
                  </div>
                </div>

                {/* FILA 3 */}
                <div className="row-flex">
                  {/* APROBACION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.aprobacion")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{firstInfo && renderIdTipSolicitud(firstInfo[0]?.IdTipSolicitud, language)}</span>
                  </div>
                  {/* FDO */}
                  <div className="col-7 col-sm-30">
                    <strong>FDO</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod"><span></span></div>
                  {/* PRIORIDAD */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.prio")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod"></div>
                </div>

                {/* FILA 4 */}

                {/* FILA 5 */}
                <div className="row-flex">
                  {/* SUPLIDOR */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.supli")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod"><span></span></div>
                  {/* SHIP TO */}
                  <div className="col-7 col-sm-30">
                    <strong>Ship To</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod"><span></span></div>
                  {/* CONTACTO */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.contact")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod"><span></span></div>
                </div>

                {/* FILA 6 */}
                <div className="row-flex">
                  {/* TECNICO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.tecnico")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{firstInfo && firstInfo[0]?.NomEmpleado}</span>
                  </div>
                  {/* ETAPA */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.eta")}</strong>
                  </div>
                  <div className="col-3 col-sm-70-mod">
                    <span style={{ color: 'var(--green-color)', textDecoration: 'underline', fontWeight: 'bold' }}>{firstInfo && firstInfo[0]?.NomEtapa}</span>
                  </div>
                </div>

                {/* FILA 7 */}
                <div className="row-flex">
                  {/* OBSERVACION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.obser")}</strong>
                  </div>
                  <div className="col-80 col-sm-70-mod">
                    <span>{((firstInfo && firstInfo.length > 0 && firstInfo[0].IdEtapa === 13) ? firstInfo[0].ObsSolicitud : (firstInfo && firstInfo.length > 0) ? firstInfo[0].ObsOrden : "") || ""}</span>
                  </div>
                </div>

                {/* FILA 8 */}
                <div className="row-flex">
                  {/* QUE ES */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.que_es")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{otherInf.queEs}</span>
                  </div>
                  {/* FUNCION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.funcion")}</strong>
                  </div>
                  <div className="col-3 col-sm-70-mod">
                    <span>{otherInf.funcion}</span>
                  </div>
                </div>

                {/* FILA 9 */}
                <div className="row-flex">
                  {/* CARACTERISTICAS FISICAS */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.caract_fisi")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{otherInf.carFisica}</span>
                  </div>
                  {/* CARACTERISTICAS TECNICAS */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.caract_tec")}</strong>
                  </div>
                  <div className="col-3 col-sm-70-mod">
                    <span>{otherInf.carTecnica}</span>
                  </div>
                </div>

                {/* FILA 10 */}
                <div className="row-flex">
                  {/* PIEZA POR DEVOLVER */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.pieza_devolve")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="devolver"
                      checked={form.devolver}
                      onChange={({ target }) => onHandleChangeForm("devolver", target.checked)} />
                  </div>
                </div>

                <hr className="m-y-small" />

                {/* FILA 11 */}
                <div className="row-flex">
                  {/* NRO. PARTE */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.parte")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span style={{ textDEcoration: 'underline' }}>{otherInf.numParte}</span>
                  </div>
                  {/* DESCRIPCION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.descrip")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{otherInf.descripcion}</span>
                  </div>
                  {/* PRECIO */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("TableParts.precio")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>{formatCurrency(otherInf.precio, "USD")}</span>
                  </div>
                </div>

                {/* FILA 12 */}
                <div className="row-flex gap-3-5">
                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.guia_export")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="guiaExpor"
                      value={form.guiaExport}
                      onChange={({ target }) => onHandleChangeForm("guiaExport", target.value)}
                      autoComplete="off" />
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.fecha_export")}</strong>
                    <input
                      className="input-date-styles"
                      disabled={INPUT_DISABLED}
                      type="date"
                      name="fecExport"
                      value={form.fechaExport}
                      onChange={({ target }) => onHandleChangeForm("fechaExport", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-9 col-sm-30 d-flex align-c gap-small">
                    <div className="d-flex">
                      <strong>{t("RequestPartsView.para_export")}</strong>
                    </div>
                    <div>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="paraExport"
                        checked={form.paraExport}
                        onChange={({ target }) => onHandleChangeForm("paraExport", target.checked)} />
                    </div>
                  </div>

                  <div className="col-9 col-sm-70-mod d-flex align-c gap-small">
                    <div className="d-flex">
                      <strong>{t("RequestPartsView.exportado")}</strong>
                    </div>
                    <div>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="exportado"
                        checked={form.exportado}
                        onChange={({ target }) => onHandleChangeForm("exportado", target.checked)} />
                    </div>
                  </div>
                </div>

                {/* FILA 13 */}
                <div className="row-flex gap-3-5">
                  <div className="col-5 col-sm-70-mod">
                    <strong>SO / SOA</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="SOA"
                      value={form.so}
                      onChange={({ target }) => onHandleChangeForm("so", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.guia_desp")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="guiaDespacho"
                      value={form.guiaDespa}
                      onChange={({ target }) => onHandleChangeForm("guiaDespa", target.value)}
                      autoComplete="off" />
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <strong>Carrier</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="carrier"
                      value={form.carrier}
                      onChange={({ target }) => onHandleChangeForm("carrier", target.value)} />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.fecha_desp")}</strong>
                    <input
                      className="input-date-styles"
                      disabled={INPUT_DISABLED}
                      type="date"
                      name="fecDespacho"
                      value={form.fecDespacho}
                      onChange={({ target }) => onHandleChangeForm("fecDespacho", target.value)} />
                  </div>

                </div>

                {/* FILA 14 */}
                <div className="row-flex gap-3-5">
                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.entrada_almacen")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="entAlmac"
                      value={form.entrAlma}
                      onChange={({ target }) => onHandleChangeForm("entrAlma", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.salida_almacen")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="salAlmac"
                      value={form.salidaAlma}
                      onChange={({ target }) => onHandleChangeForm("salidaAlma", target.value)}
                      autoComplete="off" />
                  </div>
                </div>

                {
                  [3, 6].includes(otherInf.prof) && (
                    <div className="row-flex gap-3-5">

                      <div className="col-5 col-sm-70-mod">
                        <strong>{t("TableParts.flete_import")}</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="fleteImport"
                          value={formatVal(form.fleteImport)}
                          onChange={({ target }) => onHandleChangeForm("fleteImport", target.value)}
                          autoComplete="off" />
                      </div>

                      <div className="col-5 col-sm-70-mod">
                        <strong>{t("TableParts.flete_export")}</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="fleteExport"
                          value={formatVal(form.fleteExport)}
                          onChange={({ target }) => onHandleChangeForm("fleteExport", target.value)}
                          autoComplete="off" />
                      </div>

                      <div className="col-5 col-sm-70-mod">
                        <strong>{t("TableParts.costo_mane")}</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="fleteExport"
                          value={formatVal(form.handlingCost)}
                          onChange={({ target }) => onHandleChangeForm("handlingCost", target.value)}
                          autoComplete="off" />
                      </div>
                    </div>
                  )
                }

                {/* FILA 15 */}
                <div className="row-flex">
                  <div className="flex-auto col-sm-70-mod">
                    <strong>{t("TableParts.coment")}</strong>
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={form.comment}
                      onChange={({ target }) => onHandleChangeForm("comment", target.value)}
                      autoComplete="off"
                      placeholder={t("RequestPartsView.si_tiene_comen")} />
                  </div>
                </div>

                {/* BUTTONS */}
                <div className="row-flex m-y-small j-content-space-around">
                  <button type="button" className="btn-primary" onClick={onSaveInfoModal}>{t("RequestPartsView.guar")}</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                </div>

                {/* TABLA */}
                <div className="table-container-mobile m-y-small seg-service-table">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>{t("RequestPartsView.fecha")}</th>
                        <th>{t("TableParts.etapa_actual")}</th>
                        <th>{t("TableParts.proxima_etapa")}</th>
                        <th>{t("TableParts.responsable_etapa")}</th>
                        <th className="col-6">{t("TableParts.coment")}</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        tblDetSegRepuesto && tblDetSegRepuesto.map((elements) => (
                          <tr key={elements.CodSegDetSolRepuesto}>
                            <td style={{ textAlign: 'center' }}>{renderDate(elements.FecIngreso, language)}</td>
                            <td>{elements.NomEtapa}</td>
                            <td>{elements.NomEtaProxima}</td>
                            <td>{elements.NomEmpleado}</td>
                            <td>{elements.Accion || ""}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
                {
                  state.showModal && (
                    <div className="background-modal" onClick={onCloseModal}>
                      <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                        {/* Question mark */}
                        <div className="row-flex m-y-small align-c justify-center">
                          <div className="question-mark-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                            </svg>
                          </div>
                        </div>
                        <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                          {state.questionAct}
                        </h5>
                        {/* Botones de accion */}
                        <div className="row-flex m-y-medium j-content-space-around">
                          <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                          <button type="button" className="btn-danger" onClick={onCloseModal}>No</button>
                        </div>
                      </div>
                    </div>
                  )
                }
              </form>
            </>
          )
        }
      </div>
    </>
  );
};