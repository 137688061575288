// vista para los pagos de contabilidad en viaticos


// components
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { ProcessPaymentViaticos } from "../../components/administration-compon/ProcessPaymentViaticos";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";



import { useEffect, useState } from "react";

// react router import
import { useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { formatCurrency, getTheToken } from "../../global-functions/globalFunctions";




export const ListPagosViat = () => {

  // GET the language
  const lang = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // search params
  const [params, setParams] = useSearchParams();

  // state definition
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data del servidor
  const [stateSolGasto, setStateSolGasto] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ empresa: params.get("comp") ?? "", responsable: params.get("nam_resp") ?? "", nroControl: params.get("num_contr") ?? "", etapa: params.get("num_step") ?? "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "", modalA: false, unauthorized: false, searching: false, total: 0, modalB: false, checkedAll: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });



  // destructuring data
  const { Steps, empresa, tasa, empPerm } = dataServ && dataServ;


  // funcion para cambiar los valores del array stateSolGasto
  const onHandleChangeValu = (newVal = false, cod = 0) => {
    setStateSolGasto((val) => {
      return val.map((solGas) => {
        if (solGas.CodSolGasto === cod) {
          return { ...solGas, Update: newVal };
        }
        return solGas;
      });
    });
  };


  // funcion para actualizar todos los elementos del array
  const onHandleUpdateAll = (newVal) => {
    setState((val) => ({ ...val, checkedAll: newVal }));

    const updateAll = stateSolGasto.map((newObj) => {
      return { ...newObj, Update: newVal };
    });

    setStateSolGasto(updateAll);

  };


  // funcion para llamar al componente de notification
  const onHandleCallToastNotification = (msgSon = "", great = false) => {
    if (great) {
      // notification de todo OK
      setNotification((val) => ({ ...val, show: true, msg: msgSon, great: true, success: true }));
    } else {
      // ocurrio un error
      setNotification((val) => ({ ...val, show: true, msg: msgSon, great: false, error: true }));
    }
  };



  // funcion para cambiar los valores del estado de filtrar y los query params
  const onHandleChangeForm = (newVal = "", type = 0) => {
    params.set("filt", "1");
    switch (type) {
      case 1: // cambiar el valor de la empresa
        setStateForm((val) => ({ ...val, empresa: newVal }));
        params.set("comp", newVal);
        setParams(params);
        break;
      case 2: // cambiar el valor del responsable
        setStateForm((val) => ({ ...val, responsable: newVal }));
        params.set("nam_resp", newVal);
        setParams(params);
        break;
      case 3: // cambiar el valor del nro de control
        if (isNaN(newVal)) {
          return;
        }
        setStateForm((val) => ({ ...val, nroControl: newVal }));
        params.set("num_contr", newVal);
        setParams(params);
        break;
      case 4: // cambiar el valor de la etapa
        setStateForm((val) => ({ ...val, etapa: newVal }));
        params.set("num_step", newVal);
        setParams(params);
        break;
    }
  };

  // funcion para buscar
  const onHandleSearch = async (event) => {
    event.preventDefault();

    const abortController = new AbortController();

    try {
      setState((val) => ({ ...val, searching: true }));
      const response = await fetch(`${urls.paymentExpenses}?empresa=${params.get("comp") ?? ""}&nro_control=${params.get("num_contr") ?? ""}&etapa=${params.get("num_step") ?? ""}&respon=${params.get("nam_resp") ?? ""}&filt=1`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setStateSolGasto(data?.SolGasto);
        setState((val) => ({ ...val, msg: "", total: 0 }));
      } else if (response.status === 404) {
        setStateSolGasto([]);
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.paymentExpenses}?empresa=${params.get("comp") ?? ""}&nro_control=${params.get("num_contr") ?? ""}&etapa=${params.get("num_step") ?? ""}&respon=${params.get("nam_resp") ?? ""}&filt=${params.get("filt") ?? "0"}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true, msg: "" }));
        setDataServ(data);
        setStateSolGasto(data?.SolGasto);
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 404) {
        setDataServ(data);
        setStateSolGasto([]);
        setState((val) => ({ ...val, msg: data?.msg, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 400) {
        setState((val) => ({ ...val, dataVisible: true, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la informacion
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);

  // useEffect para recargar la data nuevamente
  useEffect(() => {
    if (notification.success) {
      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };

    }
  }, [notification.success]);


  // useEffect para cuando cambie el estado
  useEffect(() => {
    if (stateSolGasto && stateSolGasto.length > 0) {
      const filtUpdate = stateSolGasto.filter((filt) => filt.Update);
      if (filtUpdate.length > 0) {
        let total = 0;
        for (const solGas of filtUpdate) {
          if ([3, 4, 9].includes(solGas.IdEtapa)) {
            total += solGas.AntAprobado;
          } else {
            total += solGas.RelAprobada - solGas.AntAprobado;
          }

          if (solGas.IdMoneda !== filtUpdate[0]?.IdMoneda) {
            // arrojar mensaje de porque se está escondiendo
            setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar los anticipos de la misma moneda.", error: true }));
            return setState((val) => ({ ...val, modalB: false }));
          } else {
            setState((val) => ({ ...val, modalB: true }));
          }
        }
        setState((val) => ({ ...val, total: total }));
      } else {
        setState((val) => ({ ...val, modalB: false, total: 0 })); // ocultar el boton para agregar los pagos
      }
    }
  }, [stateSolGasto]);


  // useEFfect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);



  // useEffect para colocar la empresa en el filtro
  useEffect(() => {
    if (empPerm && params.get("filt") === null) {
      setStateForm((val) => ({ ...val, empresa: empPerm[0]?.CodEmpresa }));
      params.set("comp", empPerm[0]?.CodEmpresa);
      setParams(params);
    }
  }, [empPerm]);




  return (
    <div className="container display-data">
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      {
        // Unauthorized component
        state.unauthorized && (<Error403View />)
      }

      {
        state.dataVisible && (
          <>
            <form className="m-y-small" onSubmit={onHandleSearch}>
              <div className="row-flex">
                <div className="col-10">
                  <strong>Empresa</strong>
                </div>
                <div className="col-8">
                  <select
                    name="company"
                    value={stateForm.empresa}
                    onChange={({ target }) => onHandleChangeForm(target.value, 1)}>
                    <option value=""></option>
                    {
                      empresa && empresa.map((empr) => (
                        <option key={empr.CodEmpresa} value={empr.CodEmpresa}>{empr.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-10">
                  <strong>Responsable</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control"
                    type="text"
                    name="responsible"
                    value={stateForm.responsable}
                    onChange={({ target }) => onHandleChangeForm(target.value, 2)} />
                </div>
                <div className="col-9">
                  <strong>Nro. Control</strong>
                </div>
                <div className="col-8">
                  <input
                    className="input-text-control"
                    type="text"
                    value={stateForm.nroControl}
                    onChange={({ target }) => onHandleChangeForm(target.value, 3)} />
                </div>
                <div className="col-10">
                  <strong>Etapa</strong>
                </div>
                <div className="col-8">
                  <select
                    name="etapa"
                    value={stateForm.etapa}
                    onChange={({ target }) => onHandleChangeForm(target.value, 4)}>
                    <option value=""></option>
                    {
                      Steps && Steps.map((step) => (
                        <option key={step.Id} value={step.Id}>{lang === "en" ? step.NombreEn : step.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                <button type="submit" className="btn-success flex-auto">{state.searching ? "Buscando..." : "Buscar"}</button>
              </div>
            </form>

            {
              state.msg !== "" && (<h5 style={{ color: "var(--danger-color)" }} className="m-y-small">{state.msg}</h5>)
            }

            {
              stateSolGasto && stateSolGasto.length > 0 && (
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Nro. Control</th>
                        <th>Responsable</th>
                        <th>Etapa</th>
                        <th>Moneda</th>
                        <th>Anticipo solicitado</th>
                        <th>Anticipo aprobado</th>
                        <th>Relación solicitada</th>
                        <th>Relación aprobada</th>
                        <th>Total pagar</th>
                        <th>
                          {/* (Todo) */}
                          <input
                            className="input-control-checkbox"
                            type="checkbox"
                            name="all"
                            checked={state.checkedAll}
                            onChange={({ target }) => onHandleUpdateAll(target.checked)} />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        stateSolGasto && stateSolGasto.map((solG) => (
                          <tr key={solG.CodSolGasto}>
                            <td className="txt-center">{solG.CodSolGasto}</td>
                            <td>{solG.NomEmpleado}</td>
                            <td>{solG.NomEtapa}</td>
                            <td className="txt-center">{solG.NomMoneda}</td>
                            <td className="txt-right">
                              <strong style={{ color: "var(--green-color)" }}>{formatCurrency(solG.Anticipo, "USD")}</strong>
                            </td>
                            <td className="txt-right">
                              <strong style={{ color: "var(--green-color)" }}>{formatCurrency(solG.AntAprobado, "USD")}</strong>
                            </td>
                            <td className="txt-right">
                              <strong style={{ color: "var(--green-color)" }}>{formatCurrency(solG.Relacionado, "USD")}</strong>
                            </td>
                            <td className="txt-right">
                              <strong style={{ color: "var(--green-color)" }}>{formatCurrency(solG.RelAprobada, "USD")}</strong>
                            </td>
                            <td className="txt-right">
                              <strong style={{ color: solG.TotPagar < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(solG.TotPagar, "USD")}</strong>
                            </td>
                            <td className="txt-center">
                              <input
                                className="input-control-checkbox"
                                type="checkbox"
                                name={solG.CodSolGasto}
                                checked={solG.Update}
                                onChange={({ target }) => onHandleChangeValu(target.checked, solG.CodSolGasto)} />
                            </td>
                          </tr>
                        ))
                      }
                      <tr>
                        <td colSpan={9} className="txt-right">
                          <strong style={{ color: state.total < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(state.total, "USD")}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }

            {
              // modalB para mostrar el boton de agregar
              state.modalB && (
                <div className="btn-add-part-container">
                  <button className="btn-add-part" onClick={() => setState((val) => ({ ...val, modalA: true }))}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              )
            }
          </>
        )
      }

      {
        // modalA para abrir el modal para procesar los pagos
        state.modalA && (
          <ProcessPaymentViaticos
            onClose={() => setState((val) => ({ ...val, modalA: false }))}
            lang={lang}
            tasaCam={tasa}
            arrayUpdate={stateSolGasto.filter((filt) => filt.Update)}
            token={getTheToken().token}
            onNotify={onHandleCallToastNotification} />)
      }
    </div>
  );
};