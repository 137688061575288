// componente para eliminar un contacto

import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";





export const DeleteContactComp = ({ codCon, lang, token, onNotify, onClose }) => {

  // GET the react translation
  const [t] = useTranslation("global");


  // funcion para eliminar el contacto
  const onDeleteUser = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.getCustomer}/${codCon}/search?language=${lang}`;
    try {
      const response = await fetch(END_POINT, {
        method: "DELETE",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        onClose();
        onNotify(data?.msg, false, true, true);
      } else if (response.status === 404) {
        onNotify(data?.msg, true, false, false);
        onClose();
      }

    } catch (error) {
      console.log(error);
    }
  };




  return (
    <div className="background-modal">
      <form onSubmit={onDeleteUser} className="container-modal-filter-customer">
        <div className="row-flex m-y-small align-c justify-center">
          <div className="question-mark-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
            </svg>
          </div>
        </div>
        <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
          {t("customerView.seguro_eliminar_contact")}
        </h5>
        {/* Botones de accion */}
        <div className="row-flex m-y-medium j-content-space-around">
          <button type="submit" className="btn-success">
            {t("RequestPartsView.si")}
          </button>
          <button type="reset" className="btn-danger" onClick={onClose}>No</button>
        </div>
      </form>
    </div>
  );
}; 