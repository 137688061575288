// este componente es de Comercial para las aplicaciones pendientes por confirmar

import { useEffect, useState } from "react";
import { urls } from "../../api-urls/api-urls";
import { Link, useNavigate } from "react-router-dom";



export const AplicacionesPendConfi = ({ lang, token, onClose }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data del servidor
  const [aplications, setAplications] = useState([]); // estado para colocar las aplicaciones
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false);


  // destructuring data server
  const { pendConf } = dataServer && dataServer;


  // funcion para cambiar los valores del estado aplications
  const onHandleChangeDate = (newVal = "", cod = 0) => {
    setAplications((val) => {
      return val.map((apli) => {
        if (apli.CodServicio === cod) {
          return { ...apli, FecAccion: newVal };
        }
        return apli;
      });
    });
  };


  // funcion para agregar un comentario en un servicio
  const onHandleAddComment = (newVal = "", cod = 0) => {
    setAplications((val) => {
      return val.map((apli) => {
        if (apli.CodServicio === cod) {
          return { ...apli, Comentario: newVal };
        }
        return apli;
      });
    });
  };


  // funcion para enviar la data al servidor
  const onHandleSendData = async () => {
    try {
      const response = await fetch(`${urls.myTasks}?language=${lang}&action=1`, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(aplications)
      });

      const data = await response.json();

      if (response.status === 200) {
        console.log(data?.msg);
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar la peticion al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setIsLoading(true);
      const response = await fetch(`${urls.myTasks}?action=2&language=${lang}&filtro=0`, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        return navigate("/");
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la informacion al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para cargar en el estado los datos de los servicios
  useEffect(() => {
    if (pendConf && pendConf.length > 0) {
      setAplications(pendConf);
    }
  }, [pendConf]);



  return (
    <div className="background-modal">
      <div className="container-modal-commercial-profile">
        <div className="modal-header">
          <h5>Aplicaciones pendientes por confirmar</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          {
            isLoading && (
              <div className="row-flex justify-center">
                <div className="col-3">
                  <strong>Cargando información...</strong>
                </div>
              </div>
            )
          }
          {
            aplications && aplications.map((app) => (
              <div key={app.CodServicio} className="m-y-small card-shadow">
                <div className="row-flex">
                  <div className="col-7">
                    <strong>ID Servicio</strong>
                  </div>
                  <div className="col-3">
                    <Link className="link-tag" to={`/commercial/service/service-call/${app.CodServicio}`}>{app.CodServicio}</Link>
                  </div>
                  <div className="col-7">
                    <strong>Etapa</strong>
                  </div>
                  <div className="col-36-51">
                    <span className="txt-muted">{app.NomEtapa}</span>
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Cliente</strong>
                  </div>
                  <div className="col-83-87">
                    <span className="txt-muted">{app.NomCliente}</span>
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Equipo</strong>
                  </div>
                  <div className="col-3">
                    <span className="txt-muted">{app.NomTipEquipo}</span>
                  </div>
                  <div className="col-7">
                    <strong>Fecha de aplicación</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-date-styles"
                      type="datetime-local"
                      name={`date-${app.CodServicio}`}
                      value={app.FecAccion ?? ""}
                      onChange={({ target }) => onHandleChangeDate(target.value, app.CodServicio)} />
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Comentario</strong>
                  </div>
                  <div className="col-80-94">
                    <input
                      className="input-text-control"
                      type="text"
                      name={`comment-${app.CodServicio}`}
                      value={app.Comentario}
                      onChange={({ target }) => onHandleAddComment(target.value, app.CodServicio)}
                      autoComplete="off" />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="button" className="btn-primary" onClick={onHandleSendData}>Actualizar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};