import React from "react";
import { Link, useSearchParams } from "react-router-dom";

// COMPONENTS IMPORTATION
 

export const CommercialReportsView = () => {



  return (
    <div className="container display-data">
      <h3 className="border-bottom">Reportes de Comercial</h3>

      <div className="row-flex m-y-small">
        <div className="col-3 row-flex d-column">
          <Link className="link-tag" to="/commercial/reports-view/open-calls-report">Llamadas abiertas</Link>
        </div>
      </div>
    </div>
  );
};