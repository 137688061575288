import React from "react";


// STYLES IMPORTATION
import "../../views/error-403-view/Error403View.css";

// NAVIGATE IMPORTATION
import { useNavigate } from "react-router-dom";


export const Error403View = () => {

  // navigate
  const navigate = useNavigate();

  return (
    <>
      <div className="container-parent-error-403">
        <div className="message">You are not authorized.</div>
        <div className="message2">You tried to access a page you did not have prior authorization for. If you think you need to access to this route, contact to the admin.</div>
        <div className="container-error-403">
          <div className="neon">403</div>
          <div className="door-frame">
            <div className="door">
              <div className="rectangle">
              </div>
              <div className="handle">
              </div>
              <div className="window">
                <div className="eye-error-403">
                </div>
                <div className="eye-error-403 eye-error-403-2">
                </div>
                <div className="leaf">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-flex btn-back-container justify-center">
          <button className="btn-neon" onClick={() => navigate(-1, { replace: true })}>Back</button>
        </div>
      </div>
    </>
  );
};