import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// REACT ROUTER IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";


// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// REACT TRANSLATION
import { useTranslation } from "react-i18next";
import { SearchCommeOrder } from "../../components/commercial-orders-comp/SearchCommeOrder";



export const CommercialPurchaseOrder = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // REACT TRANSLATION
  const [t] = useTranslation("global");


  // GET the useSearchParams
  const [params] = useSearchParams();

  const PAGE_SIZE = 20;


  // STATE DEFINITION
  const [listOrders, setListOrders] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, unauthorized: false, modalFil: false, dataVisible: false, notFound: false, notFoundMsg: "", searching: false, total: 0, moreData: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false }); // estado para las notificaciones
  const [currentPag, setCurrentpag] = useState(1);



  // funcion para abrir el modal
  const onOpenCloseModal = () => {
    setState((val) => ({ ...val, modalFil: !state.modalFil }));
  };


  // funcion para actualizar el estado desde el hijo
  const onSetNewListFromChild = (newList = [], total = 0, msg = "", moreD = false) => {
    setListOrders(newList);
    setState((val) => ({ ...val, total, notFoundMsg: msg, moreData: moreD }));
  };


  // funcion para realizar el fetch de la data
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.getPurchaseOrderCommercial}?language=${language}&solOrden=${params.get("sol_ord") ?? ""}&poGON=${params.get("po_gon") ?? ""}&etapa=${params.get("nom_etap") ?? ""}&vendedor=${params.get("nom_vend") ?? ""}&division=${params.get("nom_divi") ?? ""}&linea=${params.get("nom_lin") ?? ""}&modalidad=${params.get("nom_modad") ?? ""}&cliente=${params.get("nom_custo") ?? ""}&action=1&page=${currentPag}&pageSize=${PAGE_SIZE}`;


    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setCurrentpag(currentPag + 1);

        if (data?.purchaseOrders.length < PAGE_SIZE) {
          setCurrentpag(1);
        }

        setListOrders([...listOrders].concat(data?.purchaseOrders));

        setState((val) => ({ ...val, notFound: false, notFoundMsg: "", total: data?.Total, moreData: data?.purchaseOrders.length < PAGE_SIZE }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/")
      } else if (response.status === 404) {
        setState((val) => ({ ...val, notFoundMsg: data?.msg, notFound: true, total: 0 }));
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer la data del server
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);



  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);




  return (
    <>
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">

        <h4 className="m-y-small">{listOrders.length} de {state.total} ordenes</h4>

        {
          state.notFound && (
            <h4 className="required">{state.notFoundMsg}</h4>
          )
        }
        {
          listOrders && listOrders.length > 0 && (
            <>
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("CommercialComp.purchase")}</th>
                      <th>GON</th>
                      <th>{t("CommercialComp.date")}</th>
                      <th>{t("CommercialComp.customer")}</th>
                      <th>{t("CommercialComp.product")}</th>
                      <th>{t("CommercialComp.step")}</th>
                      <th>{t("CommercialComp.actionDate")}</th>
                      <th>{t("CommercialComp.action")}</th>
                      <th>{t("CommercialComp.production")}</th>
                      <th>{t("CommercialComp.invoice")}</th>
                      <th>POD</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      listOrders && listOrders.map((order, index) => (
                        <tr key={index}>
                          <td>
                            <Link className="link-tag" to={`/commercial/purchase-order/order-detail/${order.CodOrden}`}>{order.CodOrden}</Link>
                          </td>
                          <td>{order.NumOrden}</td>
                          <td>{order.GON}</td>
                          <td>{renderDate(order.FecIngreso, language)}</td>
                          <td className="col-5">
                            <Link className="link-tag" to={`/customers/customer-detail/${order.CodCliente}`}>{order.NomCliente}</Link>
                          </td>
                          <td className="col-7" style={{ textAlign: 'center' }}>{order.product}</td>
                          <td>{order.NomEtapa}</td>
                          <td>{renderDate(order.FecSeguimiento, language)}</td>
                          <td className="col-7">{order.comment}</td>
                          <td style={{ textAlign: 'center' }}>{order.TieProduccion ? order.TieProduccion.toFixed(1) : "N/A"}</td>
                          <td>{order.nroFactura}</td>
                          <td style={{ textAlign: 'center' }}>{order.POD}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="m-y-small row-flex align-c justify-center">
                {
                  state.moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button className="btn-primary" onClick={fetchDataServer}>{t("serviceCard.ver_mas")}</button>
                }
              </div>
            </>
          )
        }


        {
          // modal para filtrar las ordenes de comercial
          state.modalFil && (
            <SearchCommeOrder onClose={onOpenCloseModal} lang={language} token={getTheToken().token} setNewList={onSetNewListFromChild} />
          )
        }



        {/* boton de busqyeda */}
        <ButtonSearch onOpenFilter={onOpenCloseModal} />
      </div>
    </>
  );
};