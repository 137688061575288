import React, { useEffect, useState } from "react";

// ----> REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";


// STYLES CSS IMPORTATIONS
import "../../views/employees/EmployeesView.css";

// ----> Hook translation importation
import { useTranslation } from "react-i18next";

// ESTA FOTO ES PARA CUANDO HAYA UN EMPLEADO QUE NO TENGA FOTOS, SE LE ASIGNA ESTE
import FotoUser from "../../assets/perfil.JPG";

// ----> COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { EmployeeDetail } from "../../components/human-resources/EmployeeDetail";
import { Error500Comp } from "../../components/errors/Error500Comp";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { SearchEmployees } from "../../components/human-resources/SearchEmployees";


// ----> URLS API IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions";


export const EmployeesView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // ----> useTranslation() Hook importation
  const [t] = useTranslation("global");

  // GET the useLocation
  const location = useLocation();

  // get the navigate function
  const navigate = useNavigate();

  // pageSize
  const PAGE_SIZE = 20;

  // GET the useSearchParams
  const [params] = useSearchParams();


  // STATE DECLARATION
  const [listEmpl, setListEmpl] = useState([]); // estado para setear el listado de los empleados
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, showModal: false, dataVisible: false, errorServ: false, codEmpleado: 0, modalSearch: false, total: 0, msg: "" });
  const [errorServer, setErrorServer] = useState(false); // este estado es para mostrar el componente de erro 500 en dado caso que ocurra
  const [moreData, setMoreData] = useState(false);
  const [currentPag, setCurrentPag] = useState(1);



  // DESTRUCTURING DATA SERVER


  // on close modal hijo
  const onCloseModalSon = () => {
    setState((val) => ({ ...val, showModal: false, codEmpleado: 0 }));
  };

  // funcion para abrir el modal para de filtrar los empleados
  const onHandleOpenModSearc = () => {
    setState((val) => ({ ...val, modalSearch: !state.modalSearch }));
  };


  // funcion para setear en el listado los datos desde el hijo
  const onSetStateFromChild = (newVal = [], total = 0) => {
    setListEmpl(newVal);
    setState((val) => ({ ...val, total }));
  };


  // funcion para setear el mensaje en caso de error
  const onSetMsgFromChild = (newVal = "") => {
    setState((val) => ({ ...val, msg: newVal }));
  };




  // fetch data to the server
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.employees}?action=1&language=${language}&page=${currentPag}&pageSize=${PAGE_SIZE}&empresa=${params.get("nom_empr") ?? ""}&divi_empr=${params.get("divi_empr") ?? ""}&geren=${params.get("nom_geren") ?? ""}&area_func=${params.get("area_fun") ?? ""}&unidad=${params.get("nom_unid") ?? ""}&grupo=${params.get("nom_grup") ?? ""}&supervisor=${params.get("nom_super") ?? ""}&perfil=${params.get("nom_perf") ?? ""}&zona=${params.get("nom_area") ?? ""}&division=${params.get("divi_empl") ?? ""}&linea=${params.get("nom_lin") ?? ""}&nom_empl=${params.get("nom_empl") ?? ""}&status=${params.get("status") ?? "active"}`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setCurrentPag(currentPag + 1);

        if (data?.allEmployees.length < PAGE_SIZE) {
          setCurrentPag(1);
        }

        setListEmpl([...listEmpl].concat(data?.allEmployees));
        setState((val) => ({ ...val, total: data?.Total }));

        setMoreData(data?.allEmployees.length < PAGE_SIZE);

        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/"); // si el usuario no está logeado o el token expiró
      } else if (response.status === 404) {
        // no se encontró nada 
      } else if (response.status === 500) {
        setState((val) => ({ ...val, dataVisible: false }));
        setErrorServer(true);
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };



  // useEffect declarations
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);




  return (
    <>
      {
        errorServer && (<Error500Comp />)
      }
      {/* Container */}
      <h5 className="m-y-small">{listEmpl && listEmpl.length} de {state.total} empleados</h5>

      {
        state.msg && (<h5 style={{ color: "var(--danger-color)" }}>{state.msg}</h5>)
      }

      <div style={{ display: 'flex', gap: '0.3rem', flexWrap: 'wrap', flex: 'auto', justifyContent: 'center' }}>
        {
          state.showModal && (<EmployeeDetail idEmpleado={state.codEmpleado} closeModal={onCloseModalSon} />)
        }
        {
          state.isLoading && (<LoadingComp />)
        }



        {
          state.dataVisible && (
            <>
              {
                listEmpl && listEmpl.map(employee => (
                  <div key={employee.CodEmpleado} className="card-employees">
                    {/* Foto del empleado */}
                    <div className="ps-employees-view">
                      {employee.Foto ?
                        <img className="im-employees-view" src={`data:image/jpeg;base64,${employee.Foto}`} alt={employee.NomEmpleado} />
                        : <img className="im-employees-view" src={FotoUser} />}
                    </div>
                    {/* Nombre del empleado */}
                    <div>
                      <Link
                        className="link-tag"
                        style={{ display: 'block', textAlign: 'center' }}>
                        {employee.NomEmpleado}
                      </Link>
                    </div>
                    {/* SUPERVISOR */}
                    <div>
                      <strong style={{ display: 'block', textAlign: 'center' }}>
                        Supervisor
                      </strong>
                      <span style={{ display: 'block', textAlign: 'center' }}>
                        {employee.NomLider}
                      </span>
                    </div>
                    {/* CARGO */}
                    <div>
                      <strong style={{ display: 'block', textAlign: 'center' }}>
                        {t("humanResources.position")}
                      </strong>
                      <span style={{ display: 'block', textAlign: 'center' }}>
                        {employee.Cargo}
                      </span>
                    </div>
                    {/* CORREO */}
                    <div>
                      <strong style={{ display: 'block', textAlign: 'center' }}>
                        {t("humanResources.emailLabel")}
                      </strong>
                      <span style={{ display: 'block', textAlign: 'center', color: 'var(--link-color)', textDecoration: 'underline' }}>
                        {employee.correo}
                      </span>
                    </div>
                    {/* Boton ver más */}
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                      <button style={{
                        fontWeight: 'bold',
                        fontSize: '0.8rem',
                        marginTop: '0.5rem'
                      }} className="btn-primary"
                        onClick={() => setState((val) => ({ ...val, showModal: true, codEmpleado: employee.CodEmpleado }))}>{t("humanResources.showMore")}</button>
                    </div>


                  </div>
                ))
              }
            </>
          )
        }

      </div>

      <ButtonSearch onOpenFilter={onHandleOpenModSearc} />

      {
        state.modalSearch && (<SearchEmployees onClose={onHandleOpenModSearc} token={getTheToken().token} lang={language} setNewList={onSetStateFromChild} setMsg={onSetMsgFromChild} />)
      }

      {
        listEmpl && listEmpl.length > 0 && (
          <div className="row-flex justify-center m-y-small">
            {
              moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button className="btn-primary" onClick={fetchDataServer}>{t("serviceCard.ver_mas")}</button>
            }
          </div>
        )
      }
    </>
  );
};