// componente para procesar los pagos de viaticos y relaciones de gastos

import { useEffect, useState } from "react";



// global functions
import { formatCurrency } from "../../global-functions/globalFunctions";


// react router dom importation
import { useNavigate } from "react-router-dom";

// urls importation
import { urls } from "../../api-urls/api-urls";



export const ProcessPaymentViaticos = ({ onClose, lang, tasaCam, arrayUpdate, token, onNotify }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [stateForm, setStateForm] = useState({ tasa: "", comentario: "", action: "1" });
  const [state, setState] = useState({ isLoading: false });


  let total = 0;


  // funcion para enviar los datos al servidor
  const onHandleProcessPaym = async (event) => {
    event.preventDefault();

    const abortController = new AbortController();

    const finalObj = { stateForm, arrayUpdate };
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.paymentExpenses}?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal,
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        onNotify(data?.msg, true);
        onClose();
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para devolver e imprimir
  const onHandleUpdate = async (action = "") => {
    const abortController = new AbortController();


    try {
      setState((val) => ({ ...val, isLoading: true }));



      setState((val) => ({ ...val, isLoading: true }));

    } catch (error) {
      console.log(error);
    }
  };




  // useEffect para cargar la tasa en el estado
  useEffect(() => {
    if (tasaCam && tasaCam.length > 0) {
      setStateForm((val) => ({ ...val, tasa: tasaCam[0]?.TASA }));
    }
  }, [tasaCam]);






  return (
    <div className="background-modal">
      <form onSubmit={onHandleProcessPaym} className="container-select-customer">
        <div className="modal-header">
          <h5>Procesar pagos</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          {
            arrayUpdate && arrayUpdate[0]?.IdMoneda === 1 && (
              <div className="row-flex">
                <div className="col-7">
                  <strong>Tipo de cambio</strong>
                </div>
                <div className="col-7">
                  <input
                    className="input-text-control"
                    type="text"
                    name="exchange"
                    value={stateForm.tasa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tasa: target.value }))} />
                </div>
              </div>
            )
          }

          <div className="row-flex">
            <div className="col-7">
              <strong>Comentario</strong>
            </div>
            <div className="col-85-71">
              <textarea
                className="textarea-description"
                name="comment"
                value={stateForm.comentario}
                onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))} />
            </div>
          </div>

          <div className="table-container-mobile">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>Nro. Control</th>
                  <th>Responsable</th>
                  <th>Solicitado</th>
                  <th>Aprobado</th>
                  <th>Total pagar</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  arrayUpdate && arrayUpdate.map((solG) => {

                    let Anticipo = 0;
                    let AntAprobado = 0;
                    let RelAprobada = 0;
                    let RelAprobadaTot = 0;

                    if ([3, 4, 9].includes(solG.IdEtapa)) {
                      Anticipo = solG.Anticipo;
                      AntAprobado = solG.AntAprobado;
                      total += solG.AntAprobado;
                    } else {
                      AntAprobado = solG.AntAprobado;
                      RelAprobada = solG.RelAprobada;
                      if (solG.RelAprobada - solG.AntAprobado > 0) {
                        RelAprobadaTot = RelAprobada - AntAprobado;
                      } else {
                        RelAprobadaTot = RelAprobada - AntAprobado;
                      }
                      total += solG.RelAprobada - solG.AntAprobado;
                    }

                    return (
                      <tr key={solG.CodSolGasto}>
                        <td className="txt-center">{solG.CodSolGasto}</td>
                        <td>{solG.NomEmpleado}</td>
                        <td className="txt-right">
                          {[3, 4, 9].includes(solG.IdEtapa) ?
                            <strong style={{ color: Anticipo < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(Anticipo, "USD")}</strong>
                            : <strong style={{ color: AntAprobado < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(AntAprobado, "USD")}</strong>}
                        </td>
                        <td className="txt-right">
                          {[3, 4, 9].includes(solG.IdEtapa) ?
                            <strong style={{ color: AntAprobado < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(AntAprobado, "USD")}</strong>
                            : <strong style={{ color: RelAprobada < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(RelAprobada, "USD")}</strong>}
                        </td>
                        <td className="txt-right">
                          {[3, 4, 9].includes(solG.IdEtapa) ?
                            <strong style={{ color: AntAprobado < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(AntAprobado, "USD")}</strong>
                            : <strong style={{ color: RelAprobadaTot < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(RelAprobadaTot, "USD")}</strong>}
                        </td>
                      </tr>
                    )
                  })
                }
                <tr>
                  <td colSpan={4}></td>
                  <td className="txt-right">
                    <strong style={{ color: total < 0 ? "var(--danger-color)" : "var(--green-color)" }}>{formatCurrency(total, "USD")}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-primary">Aprobar</button>
          <button type="button" className="btn-info">Devolver</button>
          <button type="button" className="btn-success">Imprimir</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};