
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { Error403View } from "../error-403-view/Error403View";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";


export const ToolsDetailView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET react transtlation
  const [t] = useTranslation("global");

  // GET navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // GET the params
  const { codHerr } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [stateHerr, setStateHerr] = useState([]); // estado para cargar las herramientas
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, showModalSave: false, loadingData: false, unauthorized: false, dataVisible: false, questionAction: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false }); // estado para las notificaciones
  const [stateForm, setStateForm] = useState({
    codigo: "", nroParte: "", descripcion: "", tipo: "", division: "", linea: "", modalidad: "", serial: "", ordCompra: "",
    fecAdquision: "", proveedor: "", precio: 0, dimension: "", peso: 0, fecCalibracion: "", periodCalibracion: "",
    fecRevision: "", periodRevision: "", ubicacion: "", observaciones: "", activo: false
  });

  // DESTRUCTURING DATA SERVER
  const { tool } = dataServer && dataServer;


  // funcion para manejar el submit del formulario al servidor
  const onHandleUpdate = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loadingData: true }));
      const response = await fetch(`${urls.tools}/${codHerr}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
        setState((val) => ({ ...val, showModalSave: false }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setState((val) => ({ ...val, showModalSave: false }));
      }

      setState((val) => ({ ...val, loadingData: false }));

    } catch (error) {
      console.log(error)
    }
  };

  // funcion para realizar la solicitud al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.tools}/${codHerr}/search?language=${language}&historial=no`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codHerr, language, navigate]);

  // useEffect para setear la data en el estado para poder cambiar los valores
  useEffect(() => {
    if (tool && tool.length > 0) {
      const FecAdquisicion = tool[0]?.FecAdquisicion ? tool[0]?.FecAdquisicion.slice(0, 10) : "";
      const FecCalibracion = tool[0]?.FecCalibracion ? tool[0]?.FecCalibracion.slice(0, 10) : "";
      const FecRevision = tool[0]?.FecRevision ? tool[0]?.FecRevision.slice(0, 10) : "";

      setStateForm((val) => ({
        ...val, codigo: tool[0]?.CodHerramienta || "", nroParte: tool[0]?.NumParte || "", descripcion: tool[0]?.Nombre || "", tipo: tool[0]?.IdTipo.toString() || "", division: tool[0]?.CodDivision || "",
        linea: tool[0]?.CodLinea || "", modalidad: tool[0]?.CodModalidad || "", serial: tool[0]?.Serial || "", ordCompra: tool[0]?.OrdCompra || "", fecAdquision: FecAdquisicion,
        proveedor: tool[0]?.CodProveedor.toString() || "", precio: tool[0]?.Precio || 0, dimension: tool[0]?.Dimension || "", peso: tool[0]?.Peso || 0, fecCalibracion: FecCalibracion, periodCalibracion: tool[0]?.PerCalibracion.toString() || "",
        fecRevision: FecRevision, periodRevision: tool[0]?.PerRevision || "", ubicacion: tool[0]?.Ubicacion || "", observaciones: tool[0]?.Observacion || "", activo: tool[0]?.Activo
      }));

      setStateHerr(tool);
    }
  }, [tool]);


  // useEffect para borrar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  return (
    <>
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // toast notificacion
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // 403 Component
        state.unauthorized && (<Error403View />)
      }
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onHandleUpdate}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* CODIGO */}
                <div className="col-8"><strong>Codigo</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="codigo"
                    value={stateForm.codigo}
                    readOnly
                    disabled />
                </div>
                {/* NRO. PARTE */}
                <div className="col-8"><strong>Nro. Parte</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="numParte"
                    value={stateForm.nroParte}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nroParte: target.value }))} />
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* DESCRIPCION */}
                <div className="col-8"><strong>Descripción</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="descripcion"
                    value={stateForm.descripcion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, descripcion: target.value }))} />
                </div>
                {/* TIPO */}
                <div className="col-8"><strong>Tipo</strong></div>
                <div className="col-3">
                  <select
                    name="tipoHerr"
                    value={stateForm.tipo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipo: target.value }))}>
                    <option value=""></option>
                    <option value="0">Herramienta</option>
                    <option value="1">Suministro</option>
                    <option value="2">Asignada</option>
                  </select>
                </div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* DIVISION */}
                <div className="col-8"><strong>División</strong></div>
                <div className="col-3">
                  <select
                    name="division"
                    value={stateForm.division}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                    <option value=""></option>
                    {
                      stateHerr[0]?.divisions.map((divi) => (
                        <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* LINEA */}
                <div className="col-8"><strong>Línea</strong></div>
                <div className="col-3">
                  <select
                    name="linea"
                    value={stateForm.linea}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, linea: target.value }))}>
                    <option value=""></option>
                    {
                      stateHerr[0]?.linea.map((lin) => (
                        <option key={lin.CodLinea} value={lin.CodLinea}>{lin.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* MODALIDAD */}
                <div className="col-8"><strong>Modalidad</strong></div>
                <div className="col-3">
                  <select
                    name="modalidad"
                    value={stateForm.modalidad}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, modalidad: target.value }))}>
                    <option value=""></option>
                    {
                      stateHerr[0]?.modalidad.map((mod) => (
                        <option key={mod.CodModalidad} value={mod.CodModalidad}>{mod.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
                {/* SERIAL */}
                <div className="col-8"><strong>Serial</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="serial"
                    value={stateForm.serial}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, serial: target.value }))} />
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* ORDEN DE COMPRA */}
                <div className="col-8"><strong>Orden de Compra</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="ordCompra"
                    value={stateForm.ordCompra}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ordCompra: target.value }))} />
                </div>
                {/* FECHA DE ADQUISION */}
                <div className="col-8"><strong>Fecha de Adquisión</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-date-styles"
                    type="date"
                    name="fecAdquisicion"
                    value={stateForm.fecAdquision}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecAdquision: target.value }))} />
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* PROVEEDOR */}
                <div className="col-8"><strong>Proveedor</strong></div>
                <div className="col-3">
                  <select
                    name="proveedor"
                    value={stateForm.proveedor}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, proveedor: target.value }))}>
                    <option value=""></option>
                    {
                      stateHerr[0]?.proveedores.map((sup) => (
                        <option key={sup.CodProveedor} value={sup.CodProveedor}>{sup.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* PRECIO */}
                <div className="col-8"><strong>Precio</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="precio"
                    value={stateForm.precio}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, precio: target.value }))} />
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* DIMENSION */}
                <div className="col-8"><strong>Dimensión (cm)</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="dimension"
                    value={stateForm.dimension}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, dimension: target.value }))} />
                </div>
                {/* PESO KG */}
                <div className="col-8"><strong>Peso (Kg)</strong></div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="peso"
                    value={stateForm.peso}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, peso: target.value }))} /></div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* FECHA DE CALIBRACION */}
                <div className="col-8"><strong>Fecha de Calibración</strong></div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecCalibracion"
                    value={stateForm.fecCalibracion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecCalibracion: target.value }))} />
                </div>
                {/* PERIODO DE CALIBRACION */}
                <div className="col-8"><strong>Periodo de Calibración</strong></div>
                <div className="col-3">
                  <select
                    name="periodCalibracion"
                    value={stateForm.periodCalibracion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, periodCalibracion: target.value }))}>
                    <option value=""></option>
                    <option value="1">Mensual</option>
                    <option value="2">Bimestral</option>
                    <option value="3">Trimestral</option>
                    <option value="6">Semestral</option>
                    <option value="12">Anual</option>
                  </select>
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* FECHA DE REVISION */}
                <div className="col-8"><strong>Fecha de Revisión</strong></div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecRevision"
                    value={stateForm.fecRevision}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecRevision: target.value }))} />
                </div>
                {/* PERIODO DE REVISION */}
                <div className="col-8"><strong>Periodo de Revisión</strong></div>
                <div className="col-3">
                  <select
                    name="periodRevision"
                    value={stateForm.periodRevision}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, periodRevision: target.value }))}>
                    <option value=""></option>
                    <option value="1">Mensual</option>
                    <option value="2">Bimestral</option>
                    <option value="3">Trimestral</option>
                    <option value="4">Semestral</option>
                    <option value="5">Anual</option>
                  </select>
                </div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* UBICACION */}
                <div className="col-8"><strong>Ubicación</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="ubicacion"
                    value={stateForm.ubicacion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ubicacion: target.value }))}
                    placeholder={language === "en" ? "Where is the tool location" : "Dónde está ubicada la herramienta?"} />
                </div>
                {/* OBSERVACIONES */}
                <div className="col-8"><strong>Observaciones</strong></div>
                <div className="col-3">
                  <textarea
                    className="textarea-description"
                    name="observaciones"
                    value={stateForm.observaciones}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, observaciones: target.value }))}
                    placeholder={language === "en" ? "Any observations? write it here.!" : "Tiene alguna observación? Coloquela aquí.!"} />
                </div>
              </div>

              {/* botones de accion */}
              <div className="row-flex m-y-medium j-content-space-around">
                <button type="button" className="btn-primary" onClick={() => setState((val) => ({ ...val, showModalSave: true, questionAction: t("Viaticos.seguro_guardar") }))}>Guardar</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* FOTO */}
                <div className="col-8"><strong>Foto</strong></div>
                <div className="col-3">
                  <img className="tool-image" src={`data:image/jpeg;base64,${tool && tool[0]?.Foto}`} alt={language === "en" ? "Tool photo" : "Foto Herramienta"} />
                </div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex">
                <div className="col-8"><strong>Activo</strong></div>
                <div className="col-3">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="activo"
                    checked={stateForm.activo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, activo: target.checked }))} />
                </div>
                {/* HISTORIAL */}
                <div className="col-9" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <strong>Historial</strong>
                  <button type="button" style={{ cursor: "pointer", border: "none", outline: "none", backgroundColor: "transparent" }} onClick={() => navigate(`/warehouse/tools-detail/history/${codHerr}`)}>
                    <svg style={{ boxShadow: "var(--box-shadow-bottom)" }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--first-color)" className="bi bi-journal-text" viewBox="0 0 16 16">
                      <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex j-content-space-b">
                {/* ingresado por */}
                <div className="col-8"><strong>Ingresado por</strong></div>
                <div className="col-3"><span>{stateHerr[0]?.NomEmpIngreso}</span></div>
                {/* modificado por */}
                <div className="col-8"><strong>Modificado por</strong></div>
                <div className="col-3"><span>{stateHerr[0]?.nomEmpModificacion}</span></div>
                {/* fecha ingreso */}
                <div className="col-8"><strong>Fecha ingreso</strong></div>
                <div className="col-3"><span>{renderDate(stateHerr[0]?.FecIngreso, language)}</span></div>
                {/* fecha modificacion */}
                <div className="col-8"><strong>Fecha modificación</strong></div>
                <div className="col-3"><span>{renderDate(stateHerr[0]?.FecModificacion, language)}</span></div>
              </div>

              {
                state.showModalSave && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success" disabled={state.loadingData}>
                          {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                        </button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }
      </div>
    </>
  );
};