// componente para las salidas de herramientas

import { useEffect, useState } from "react";


// components import
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { Error403View } from "../error-403-view/Error403View";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";


// react router importation
import { Link, useNavigate, useParams } from "react-router-dom";


// urls importation
import { urls } from "../../api-urls/api-urls";


// global functions importation
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";





export const SalidaHerramientaView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the params
  const { reqId } = useParams();



  // state definition
  const [dataServ, setDataServ] = useState([]); // estado para cargar la data
  const [stateHerra, setStateHerra] = useState([]); // estado para cargar la data de las herramientas
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false });
  const [stateForm, setStateForm] = useState({ codSol: "", idServ: "", fecSol: "", numSal: 0, fecReq: "", fecDevol: "", cliente: "", direccion: "", solEnvio: "", tecEnviar: "", guia: "", courier: "", enviadoA: "", comentario: "", enviarCon: "", CodZona: 0 });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });


  // destructuring data server
  const { solHerra, empPerm, employees, detHerra } = dataServ && dataServ;


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {

    const abortController = new AbortController();

    const END_POINT = `${urls.toolExitWarehouse}/${reqId}/search?language=${language}`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification((val) => ({ ...val, show: false, msg: "", error: false, great: false }));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para cargar los datos en el estado
  useEffect(() => {
    if (solHerra && solHerra.length > 0) {

      setStateForm((val) => ({
        ...val, codSol: solHerra[0]?.CodSol, idServ: solHerra[0]?.CodServicio, fecSol: solHerra[0]?.FecSolicitud, numSal: solHerra[0]?.MaxSalida, fecReq: solHerra[0]?.FecRequerida,
        fecDevol: solHerra[0]?.FecDevolucion, cliente: solHerra[0]?.NomCliente, direccion: solHerra[0]?.DirCliente, solEnvio: solHerra[0]?.Descripcion, tecEnviar: solHerra[0]?.CodEmpResponsable,
        CodZona: solHerra[0]?.CodZona
      }));
    }
  }, [solHerra]);


  // useEffect para cargar las herramientas en el estado
  useEffect(() => {
    if (detHerra && detHerra.length > 0) {
      setStateHerra(detHerra);
    }
  }, [detHerra]);








  return (
    <div className="container display-data">
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // Error 403 component
        state.unauthorized && (<Error403View />)
      }

      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      {
        state.dataVisible && (
          <>
            <div className="row-flex">
              <div className="col-8">
                <strong>Solicitud</strong>
              </div>
              <div className="col-7">
                <strong style={{ fontSize: "0.9rem", color: "var(--first-color)" }}>{stateForm.codSol}</strong>
              </div>
              <div className="col-8">
                <strong>Id Servicio</strong>
              </div>
              <div className="col-7">
                <Link className="link-tag" to={`/service/service-call/${stateForm.idServ}`}>{stateForm.idServ}</Link>
              </div>
              <div className="col-8">
                <strong>Fecha solicitud</strong>
              </div>
              <div className="col-3">
                <span>{renderDate(stateForm.fecSol, language)}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>Nota de salida</strong>
              </div>
              <div className="col-7">
                <strong style={{ fontSize: "0.9rem", color: "var(--first-color)" }}>{stateForm.numSal}</strong>
              </div>
              <div className="col-8">
                <strong>Fecha requerida</strong>
              </div>
              <div className="col-7">
                <span>{renderDate(stateForm.fecReq, language)}</span>
              </div>
              <div className="col-8">
                <strong>Fecha devolución</strong>
              </div>
              <div className="col-3">
                <span>{renderDate(stateForm.fecDevol, language)}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>Cliente</strong>
              </div>
              <div className="col-86-91">
                <span>{stateForm.cliente}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>Dirección</strong>
              </div>
              <div className="col-86-91">
                <span>{stateForm.direccion}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>Solicitud envío</strong>
              </div>
              <div className="col-86-91">
                <strong style={{ color: "var(--danger-color)", fontSize: "0.7rem" }}>{stateForm.solEnvio}</strong>
              </div>
            </div>

            <hr className="m-y-small" />

            <div className="row-flex">
              <div className="col-8">
                <strong>Nota de salida</strong>
              </div>
              <div className="col-41-08 row-flex">
                <span style={{ fontSize: "0.9rem", fontWeight: "bold", color: "var(--first-color)" }}>{stateForm.numSal}</span>
              </div>
              <div className="col-8">
                <strong>Técnico a enviar</strong>
              </div>
              <div className="col-3">
                <select
                  name="tecEnvi"
                  value={stateForm.tecEnviar}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, tecEnviar: target.value }))}>
                  <option value="">--Seleccione el técnico--</option>
                  {
                    employees && employees.map((empl) => (
                      <option key={empl.CodEmpleado} value={empl.CodEmpleado}>{empl.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>Guía</strong>
              </div>
              <div className="col-41-08 row-flex">
                <div className="col-6">
                  <input
                    className="input-text-control"
                    type="text"
                    name="trackingNum" />
                </div>
                <div className="col-6">
                  <select name="courier">
                    <option value="">--Seleccione el courier--</option>
                  </select>
                </div>
              </div>
              <div className="col-8">
                <strong>Enviado a</strong>
              </div>
              <div className="col-3">
                <select name="sendTo">
                  <option value="">--Seleccione una opción--</option>
                  <option value="0">Técnico</option>
                  <option value="1">Cliente</option>
                  <option value="2">Otro</option>
                </select>
              </div>
            </div>

            {
              stateForm.CodZona !== 1 && (
                <div className="row-flex">
                  <div className="col-8">
                    <strong>Enviar con</strong>
                  </div>
                  <div className="col-41-08 row-flex">
                    <div className="col-6">
                      <select
                        name="SendWith"
                        value={stateForm.enviarCon}
                        onChange={({ target }) => setStateForm((val) => ({ ...val, enviarCon: target.value }))}>
                        <option value=""></option>
                        <option value="0">Ingeniero</option>
                        <option value="1">Proceso logístico</option>
                      </select>
                    </div>
                    {
                      stateForm.enviarCon === "0" && (
                        <div className="col-6 fade-animation">
                          <select name="ingenieroCon" >
                            <option value="">--Seleccione el ingeniero--</option>
                            {
                              employees && employees.map((empl) => (
                                <option key={empl.CodEmpleado} value={empl.CodEmpleado}>{empl.NomEmpleado}</option>
                              ))
                            }
                          </select>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }

            <div className="row-flex">
              <div className="col-8">
                <strong>Comentario</strong>
              </div>
              <div className="col-86-91">
                <textarea
                  className="textarea-description"
                  name="comment" />
              </div>
            </div>

            <div className="row-flex m-y-small j-content-space-around">
              <button type="button" className="btn-success">Enviar</button>
              <button type="button" className="btn-info">Devolver</button>
              <button type="button" className="btn-danger">Eliminar</button>
              <button type="button" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
            </div>

            <div className="table-container-mobile m-y-small">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>#</th>
                    <th>Código</th>
                    <th>Nombre</th>
                    <th>Ubicación</th>
                    <th>Cantidad</th>
                    <th>Imagen</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    stateHerra && stateHerra.map((herr) => (
                      <tr key={herr.CodDet}>
                        <td className="txt-center">{herr.CodIndex}</td>
                        <td>
                          <Link className="link-tag">{herr.CodHerramienta}</Link>
                        </td>
                        <td>{herr.Nombre}</td>
                        <td>{herr.Ubicacion}</td>
                        <td className="txt-center">{herr.Cantidad}</td>
                        <td></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }

    </div>
  );
};