// componente para buscar las solicitudes de vacaciones

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";








export const SearchRequestVacation = ({ onClose, lang, token, location, onSetNewList }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the query params
  const [params, setParams] = useSearchParams();

  // State definition
  const [form, setForm] = useState({ empresa: params.get("nom_empr") ?? "", division: params.get("nom_divi") ?? "", gerencia: params.get("nom_geren") ?? "", empleado: params.get("nom_empl") ?? "", supervisor: params.get("nom_super") ?? "", tipo: params.get("id_tipo") ?? "", etapa: params.get("nom_etapa") ?? "", control: params.get("num_contr") ?? "" });
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la solicitud al servidor
  const [state, setState] = useState({ isLoading: false, searching: false });

  // destructuring data server
  const { empresa, division, gerencia, permisos, etapas, empPerm } = dataServ && dataServ;

  // page size
  const PAGE_SIZE = 20;



  // funcion para buscar las vacaciones
  const onHandleSearchForm = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.vacations}?action=2&language=${lang}&cod_vaca=${form.control}&empresa=${form.empresa}&division=${form.division}&gerencia=${form.gerencia}
    &nom_empl=${form.empleado}&nom_super=${form.supervisor}&tipo=${form.tipo}&etapa=${form.etapa}&page=1&pageSize=20&action=1`;

    try {
      setState((val) => ({ ...val, searching: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        onSetNewList(data?.listVaca, 0, "", false, data?.Total);
        onClose();
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        // no hay nada
        onSetNewList([], 404, data?.msg, true, 0);
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para modificar los valores y setear los query params
  const onHandleChangeVal = (newVal = "", name, queryPar) => {

    if (name === "control") {
      if (isNaN(newVal)) {
        return;
      }
    }

    setForm((val) => ({ ...val, [name]: newVal }));

    params.set("filt", 1);
    params.set(queryPar, newVal);
    setParams(params);
  };


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.vacations}?action=2&language=${lang}`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [location.pathname]);


  useEffect(() => {
    if (empPerm && params.get("filt") === null) {
      setForm((val) => ({ ...val, empleado: empPerm[0]?.NomEmpleado, division: empPerm[0]?.CodGrupo.toString(), gerencia: empPerm[0]?.CodGerencia.toString(), empresa: empPerm[0]?.CodEmpresa }));
    }
  }, [empPerm]);



  return (
    <div className="background-modal">
      <form onSubmit={onHandleSearchForm} className="container-select-customer container-filter-empl-h">
        <div className="modal-header">
          <h4>Búsqueda</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          {
            state.isLoading && (<strong>Cargando información...</strong>)
          }

          {
            !state.isLoading && (
              <div className="display-data">
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Empresa</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <select
                      name="company"
                      value={form.empresa}
                      onChange={({ target }) => onHandleChangeVal(target.value, "empresa", "nom_empr")}>
                      <option value=""></option>
                      {
                        empresa && empresa.map((empr) => (
                          <option key={empr.CodEmpresa} value={empr.CodEmpresa}>{empr.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-7">
                    <strong>División</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <select
                      name="division"
                      value={form.division}
                      onChange={({ target }) => onHandleChangeVal(target.value, "division", "nom_divi")}>
                      <option value=""></option>
                      {
                        empPerm && location.pathname === "/human-capital/vacations/request" && (
                          division && division.filter((filtDiv) => filtDiv.CodGrupo === empPerm[0]?.CodGrupo).map((divi) => (
                            <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Descripcion}</option>
                          ))
                        )
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Gerencia</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <select
                      name="gerencia"
                      value={form.gerencia}
                      onChange={({ target }) => onHandleChangeVal(target.value, "gerencia", "nom_geren")}>
                      <option value=""></option>
                      {
                        gerencia && gerencia.filter((filtGere) => filtGere.CodGrupo.toString() === form.division).map((ger) => (
                          <option key={ger.CodGerencia} value={ger.CodGerencia}>{ger.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-7">
                    <strong>Tipo</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <select
                      name="tipo"
                      value={form.tipo}
                      onChange={({ target }) => onHandleChangeVal(target.value, "tipo", "id_tipo")}>
                      <option value="">--Todo--</option>
                      {
                        permisos && permisos.map((per) => (
                          <option key={per.id} value={per.id}>{lang === "en" ? per.NombreEn : per.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Etapa</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <select
                      name="etapa"
                      value={form.etapa}
                      onChange={({ target }) => onHandleChangeVal(target.value, "etapa", "nom_etapa")}>
                      <option value="">--Pendientes--</option>
                    </select>
                  </div>
                  <div className="col-7">
                    <strong>Empleado</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="employee"
                      value={form.empleado}
                      onChange={({ target }) => onHandleChangeVal(target.value, "empleado", "nom_empl")} />
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Supervisor</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="supervisor"
                      value={form.supervisor}
                      onChange={({ target }) => onHandleChangeVal(target.value, "supervisor", "nom_super")} />
                  </div>
                  <div className="col-7">
                    <strong>Número de control</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="nroCont"
                      value={form.control}
                      onChange={({ target }) => onHandleChangeVal(target.value, "control", "num_contr")}
                      autoComplete="off" />
                  </div>
                </div>
              </div>
            )
          }

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success">Buscar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};