
import React, { useEffect, useState } from "react";

/*
--- Este componente es para crear la solicitudes de partes u otra solicitudes,
--- solamente para esto, cuando se guarda luego se redirige a la url /request-part-detail/:codSolR 
*/

// COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error500Comp } from "../../components/errors/Error500Comp";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URL Importation
import { urls } from "../../api-urls/api-urls";

// REACT TRANSLATION
import { useTranslation } from "react-i18next";

// GLOBAL FUNCTION IMPORTATION
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

export const RequestPartsView = () => {

  // get the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();

  // get the params
  const { idCall } = useParams();

  // GET the react translation
  const [t] = useTranslation("global");

  // Fecha
  const date = new Date();
  const fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // estado para setear la data en el estado
  const [genState, setGenState] = useState({ isLoading: false, serverError: false, unathorized: false });
  const [dataVisible, setDataVisible] = useState(false); // para ocultar la data en funcion de la respuesta del servidor
  const [stateForm, setStateForm] = useState({ tipSol: "", observ: "", priority: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, solR: 0 });

  // DESTRUCTURING DATA
  const { servDetail, employeesPermission, nomContract, fecEOL } = dataServer && dataServer;


  // Funcion para crear la solicitud
  const onCreateRequest = async (event) => {
    event.preventDefault();

    // Validar que se hayan seleccionado los campos
    if (!stateForm.tipSol) {
      return setNotification((val) => ({ ...val, show: true, msg: t("RequestPartsView.selec_tip_sol"), errorNoti: true }));
    }

    try {
      // Enviar datos al formilario
      const response = await fetch(`${urls.requestDetail}/${idCall}/search?initData=no&language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      // Verificar el estatus de la respuesta del servidor
      if (response.status === 201) {
        // se creó la solicitud y debe ser redireccionado
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, solR: data?.codSolRep }));
      } else {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

    } catch (error) {
      console.log(error)
    }
  };

  // Funcion para traer la data del servidor
  const fetchDataServer = async () => {
    setGenState({ ...genState, isLoading: true });

    const response = await fetch(`${urls.requestDetail}/${idCall}/search?initData=yes&language=${language}`, {
      method: "POST",
      headers: { "Authorization": getTheToken().token }
    });

    const data = await response.json();

    if (response.status === 200) {
      setDataServer(data);
      setDataVisible(true);
    } else if (response.status === 401) {
      localStorage.setItem("lastNav", location.pathname);
      localStorage.removeItem("userInfo");
      return navigate("/");
    } else if (response.status === 400) {
      // bad request
    } else if (response.status === 403) {
      // no autorizado
      setGenState({ ...genState, unathorized: true });
      setDataVisible(false);
    } else if (response.status === 500) {
      // error del servidor
      setGenState({ ...genState, serverError: true });
    }

    setGenState({ ...genState, isLoading: false });
  };

  // useEffect para traer los datos del servidor
  useEffect(() => {
    try {

      fetchDataServer();

    } catch (error) {
      console.log(error)
    }
  }, [language]);


  // Limpiar las notificaciones del estado 
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, solR: 0 });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);

  // UseEffect para agregar la prioridad de la llamada
  useEffect(() => {
    if (servDetail && servDetail.length > 0) {
      setStateForm({ ...stateForm, priority: servDetail[0]?.Prioridad.toString() });
    }
  }, [servDetail]);

  // useEffect para redireccionar a la nueva solicitud
  useEffect(() => {
    if (notification.solR > 0 && notification.show) {
      const timer = setTimeout(() => {
        navigate(`/request-part-detail/${notification.solR}`);
      }, 6300);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.show, notification.solR]);



  return (
    <>
      {/* Notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)}
      {/* Server error components */}
      {genState.serverError && (<Error500Comp />)}
      {/* Loading component */}
      {genState.isLoading && (<LoadingComp />)}
      {
        dataVisible && (
          <div className="container display-data">
            <form onSubmit={onCreateRequest}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* SOLICITUD */}
                <div className="col-8 col-sm-20"><strong>{t("RequestPartsView.soli")}</strong></div>
                <div className="col-4 col-sm-30"><h3></h3></div>
                {/* ID SERVICIO */}
                <div className="col-10 col-sm-20"><strong>{t("RequestPartsView.id_serv")}</strong></div>
                <div className="col-8 col-sm-30"><Link className="link-tag" to={`/service/service-call/${idCall}`}>{idCall}</Link></div>
                {/* MODELO */}
                <div className="col-10 col-sm-20"><strong>{t("RequestPartsView.modelo")}</strong></div>
                <div className="col-4 col-sm-30"><span>{servDetail[0]?.equipment[0]?.Modelo}</span></div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* FECHA */}
                <div className="col-8 col-sm-20"><strong>{t("RequestPartsView.fecha")}</strong></div>
                <div className="col-4 col-sm-30"><span>{renderDate(fullDate, language)}</span></div>
                {/* INSTALACION */}
                <div className="col-10 col-sm-20"><strong>{t("RequestPartsView.instala")}</strong></div>
                <div className="col-8 col-sm-30"><span></span></div>
                {/* SERIAL */}
                <div className="col-10 col-sm-20"><strong>Serial</strong></div>
                <div className="col-4 col-sm-30"><span>{servDetail[0]?.equipment[0]?.Serial}</span></div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* SUPLIDOR */}
                <div className="col-8 col-sm-20"><strong>{t("RequestPartsView.supli")}</strong></div>
                <div className="col-4 col-sm-80"><span></span></div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* TIPO DE SOLICITUD */}
                <div className="col-8 col-sm-20"><strong>{t("TipSol.tip_sol")}</strong><strong className="required">(*)</strong></div>
                <div className="col-4 col-sm-30">
                  <select name="tipoSolicitud" value={stateForm.tipSol} onChange={({ target }) => setStateForm({ ...stateForm, tipSol: target.value })}>
                    <option value=""></option>
                    <option value="0">{t("TipSol.coti")}</option>
                    <option value="1">{t("TipSol.garan")}</option>
                    <option value="2">{t("TipSol.contra_perfo")}</option>
                    <option value="3">Missing in Shipment</option>
                    <option value="4">Dead on Arrival</option>
                    <option value="5">{t("TipSol.ord_compr")}</option>
                  </select>
                </div>
                {/* GON */}
                <div className="col-10 col-sm-20"><strong>GON</strong></div>
                <div className="col-8 col-sm-30"><span>{servDetail[0]?.equipment[0]?.FDO}</span></div>
                {/* O/C */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20"><strong>O/C</strong></div>
                <div className="col-4 col-sm-80"><span>{servDetail[0]?.equipment[0]?.NumOrden}</span></div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-8 col-sm-20"><strong>{t("RequestPartsView.cliente")}</strong></div>
                <div className="col-46-7 col-sm-80 text-hidden-over"><Link className="link-tag" to={`/customers/customer-detail/${servDetail[0]?.CodCliente}`}>{servDetail[0]?.nomCliente}</Link></div>
                {/* PRIORIDAD */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20"><strong>{t("RequestPartsView.prio")}</strong></div>
                <div className="col-4 col-sm-80">
                  <div className="row-flex j-content-space-around box-temperature col-70-sm">
                    <div className="input-control bg-green circle-temp">
                      <input
                        type="radio"
                        value="0"
                        name="temperature"
                        checked={stateForm.priority === "0"}
                        onChange={({ target }) => setStateForm({ ...stateForm, priority: target.value })} />
                    </div>
                    <div className="input-control bg-yellow circle-temp">
                      <input
                        type="radio"
                        value="1"
                        name="temperature"
                        checked={stateForm.priority === "1"}
                        onChange={({ target }) => setStateForm({ ...stateForm, priority: target.value })} />
                    </div>
                    <div className="input-control bg-red circle-temp">
                      <input
                        type="radio"
                        value="4"
                        name="temperature"
                        checked={stateForm.priority === "4"}
                        onChange={({ target }) => setStateForm({ ...stateForm, priority: target.value })} />
                    </div>
                  </div>
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* EQUIPO */}
                <div className="col-8 col-sm-20"><strong>{t("RequestPartsView.equip")}</strong></div>
                <div className="col-46-7 col-sm-80"><Link className="link-tag" to={`/equipments/equipment-detail/${servDetail[0]?.CodEquipo}`}>{servDetail[0]?.equipment[0]?.Linea} - {servDetail[0]?.equipment[0]?.Modalidad} - {servDetail[0]?.equipment[0]?.TipEquipo} - {servDetail[0]?.equipment[0]?.Modelo} - {servDetail[0]?.equipment[0]?.Serial} - {servDetail[0]?.equipment[0]?.Marca} - {servDetail[0]?.equipment[0]?.NumSistema}</Link></div>
                {/* CONTRATO */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20"><strong>{t("RequestPartsView.contr")}</strong></div>
                <div className="col-4 col-sm-80">
                  <span>{nomContract}</span>
                  <strong className="required">{fecEOL !== null && t("RequestPartsView.equip_eol")}</strong>
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* INGENIERO TECNICO */}
                <div className="col-8 col-sm-20"><strong>{t("RequestPartsView.ing_tec")}</strong></div>
                <div className="col-4 col-sm-80"><span>{employeesPermission[0]?.NomEmpleado}</span></div>
                {/* CARPETA */}
                <div className="col-10 col-sm-20"><strong>{t("RequestPartsView.carp")}</strong></div>
                <div className="col-8 col-sm-80"><span></span></div>
                {/* FALLA */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20"><strong>{t("RequestPartsView.falla")}</strong></div>
                <div className="col-4 col-sm-80">
                  <textarea
                    className="textarea-description"
                    name="falla"
                    value={servDetail[0]?.Problema}
                    readOnly />
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* ETAPA */}
                <div className="col-8 col-sm-20"><strong>{t("RequestPartsView.eta")}</strong></div>
                <div className="col-4 col-sm-80"><span>{t("RequestPartsView.sol_rep_eta")}</span></div>
                <div className="col-10 m-left-auto-comp-serv col-sm-20"><strong>{t("RequestPartsView.obser")}</strong></div>
                <div className="col-4 col-sm-80">
                  <textarea
                    className="textarea-description"
                    name="observacion"
                    value={stateForm.observ}
                    onChange={({ target }) => setStateForm({ ...stateForm, observ: target.value })}
                    placeholder={t("RequestPartsView.obser_place_hold")} />
                </div>
              </div>

              {/* BOTONES DE ACCION */}
              <div className="row-flex j-content-space-around m-y-small">
                <button type="submit" className="btn-primary">{t("RequestPartsView.guar")}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

            </form>
          </div>
        )
      }
    </>
  );
};