
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { TopNavbarMobile } from "../../components/top-searchbar-mobile/TopNavbarMobile";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";

// urls importation
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken } from "../../global-functions/globalFunctions";


export const FMIDetail = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // usenavigate
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // useparams
  const { codFMI } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // state para cargar la data del servidor
  const [isLoading, setIsLoading] = useState(false);
  const [stateInputs, setStateInputs] = useState({
    numFMI: "", revisarPor: "", descripcion: "", textCorreo: "", fecTope: "",
  }); // state para cargar la data del servidor para poder modificarla

  // DESTRUCTURING DATA SERVER
  const { FMIDetail, employeePermission } = dataServer && dataServer;


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(`${urls.fmi}/${codFMI}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 400) {
          // bad request
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error)
    }
  }, [codFMI]);

  return (
    <>
      <TopNavbarMobile />
      <div className="container display-data">
        <h1>FMI detail</h1>
      </div>
    </>
  );
};