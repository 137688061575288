import React, { useEffect, useState } from "react";


// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error404Comp } from "../../components/errors/Error404Comp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// URL importation
import { urls } from "../../api-urls/api-urls";

// React router dom importation
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Global functions
import { getTheToken } from "../../global-functions/globalFunctions";

// React translation
import { useTranslation } from "react-i18next";



export const LlegadaPartesDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codSolRep } = useParams();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false });
  const [stateForm, setStateForm] = useState({ guiaLleg: "", entrAlm: "", comment: "", action: "" });
  const [partes, setPartes] = useState([]);
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, succes: false });
  const [questionAction, setQuestionAction] = useState("");


  // Destructuring data server
  const { detSolRep } = dataServer && dataServer;



  // Funcion para modificar el serial
  const onHandleChangeSerial = (index, newVal) => {
    const updateSerial = [...partes];

    updateSerial[index].Serial = newVal;

    setPartes(updateSerial);
  };


  // Funcion para modificar la cantidad que llego
  const onHandleChangeCantLleg = (index, newVal) => {
    const cantidad = [...partes];
    if (parseFloat(newVal) > cantidad[index].Cantidad) {
      return;
    }

    if (isNaN(newVal)) {
      return;
    }

    const updateCantLleg = [...partes];

    updateCantLleg[index].CanLlego = newVal;

    setPartes(updateCantLleg);
  };


  // Funcion para abrir el modal en guardar
  const onHandleSave = () => {
    setState((val) => ({ ...val, showModal: true }));
    setStateForm((val) => ({ ...val, action: "1" }));
    setQuestionAction("Está seguro de enviar las piezas a la siguiente etapa?");
  };


  // Funcion para abrir el modal en devolver
  const onHandleReturn = () => {
    setState((val) => ({ ...val, showModal: true }));
    setStateForm((val) => ({ ...val, action: "2" }));
    setQuestionAction("Esta seguro de enviar las piezas a nacionalizacion?");
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));

        const response = await fetch(`${urls.partArrivalWarehouse}/${codSolRep}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 400) {
          // bad request
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        } else if (response.status === 500) {
          setState((val) => ({ ...val, dataVisible: false, serverError: true }));
        }

        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

  }, [language, codSolRep]);


  // useEffect para montar las partes en el estado
  useEffect(() => {
    if (detSolRep && detSolRep.length > 0) {
      setPartes(detSolRep);

      setStateForm((val) => ({ ...val, guiaLleg: detSolRep[0]?.GuiDespacho, entrAlm: detSolRep[0]?.EntAlmacen }));
    }
  }, [detSolRep]);

  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, succes: false });
    }, 6000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useffect para hacer la redireccion a la principal de almacen
  useEffect(() => {
    if (notification.succes) {

      const timer = setTimeout(() => {
        navigate("/warehouse/principal");
      }, 5500);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.succes]);


  // Funcion para manejar el envio del formulario al backend
  const onSubmitRequest = async (event) => {
    event.preventDefault();

    const finalObj = { stateForm, partes };

    try {
      const response = await fetch(`${urls.partArrivalWarehouse}/${codSolRep}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, succes: true }));
        setStateForm({ guiaLleg: "", entrAlm: "", comment: "", action: "" });
        return setState((val) => ({ ...val, showModal: false }));
      }

    } catch (error) {
      setNotification((val) => ({ ...val, show: true, msg: t("General_Comp.error_comunic_serv"), errorNoti: true }));
      setState((val) => ({ ...val, isLoading: false }));
      console.clear();
    }
  };



  return (
    <>
      {/* Notification component */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)}
      {/* Component loading */}
      {state.isLoading && (<LoadingComp />)}
      {/* Component error 404 */}
      {!state.dataVisible && (<Error404Comp msgPrin={"Oops no hay nada en p"} navigateTo={"/warehouse/principal"} />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <div className="m-y-small">
                <p><strong>Etapa actual: </strong> <strong className="active-class">Por llegar a almacén</strong></p>
              </div>
              <form onSubmit={onSubmitRequest}>

                {/* FILA */}
                <div className="row-flex">
                  {/* Guia de llegada */}
                  <div className="col-7"><strong>Guía de llegada</strong></div>
                  <div className="col-5">
                    <input
                      className="input-text-control"
                      type="text"
                      name="guiaLleg"
                      value={stateForm.guiaLleg}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, guiaLleg: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Entrada almacen */}
                  <div className="col-7"><strong>Entrada almacén</strong></div>
                  <div className="col-5">
                    <input
                      className="input-text-control"
                      type="text"
                      name="entrAlma"
                      value={stateForm.entrAlm}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, entrAlm: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Comentario */}
                  <div className="col-7"><strong>Comentario</strong></div>
                  <div className="col-83-87">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={stateForm.comment}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                <hr className="m-y-small" />

                {/* Tabla para las partes */}
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#Orden</th>
                        <th>Cantidad</th>
                        <th>Nro. Parte</th>
                        <th>Descripcion</th>
                        <th>Técnico solicitante</th>
                        <th>Equipo</th>
                        <th>Modalidad</th>
                        <th>Serial</th>
                        <th>Cant. Llegó</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        partes && partes.map((part, index) => (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="txt-center"><Link className="link-tag">{part.NumOrden}</Link></td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td><Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte} ({part.Alternativo})</Link></td>
                            <td>{part.Descripcion}</td>
                            <td>{part.nomEmpleado}</td>
                            <td>{part.nomEquip} - {part.modelo}</td>
                            <td>{part.modalidad}</td>
                            <td className="col-10">
                              <input
                                className="input-text-control txt-center"
                                type="text"
                                name={`serial-${part.CodDetSolRepuesto}`}
                                value={part.Serial}
                                onChange={({ target }) => onHandleChangeSerial(index, target.value)}
                                autoComplete="off" />
                            </td>
                            <td className="col-10">
                              <input
                                className="input-text-control txt-center"
                                type="text"
                                name={`cantLlego-${part.CodDetSolRepuesto}`}
                                value={part.CanLlego}
                                onChange={({ target }) => onHandleChangeCantLleg(index, target.value)}
                                autoComplete="off" />
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Botones de accion */}
                <div className="row-flex m-y-medium gap-medium j-content-space-around">
                  <button type="button" className="btn-primary" onClick={onHandleSave}>{t("RequestPartsView.guar")}</button>
                  <button type="button" className="btn-warning" onClick={onHandleReturn}>{t("RequestPartsView.devol")}</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                </div>

                {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
                {
                  state.showModal && (
                    <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                      <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                        {/* Question mark */}
                        <div className="row-flex m-y-small align-c justify-center">
                          <div className="question-mark-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                            </svg>
                          </div>
                        </div>
                        <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                          {questionAction}
                        </h5>
                        {/* Botones de accion */}
                        <div className="row-flex m-y-medium j-content-space-around">
                          <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                          <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                        </div>
                      </div>
                    </div>
                  )
                }

              </form>
            </>
          )
        }
      </div>
    </>
  );
};