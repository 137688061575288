// modal para subir los documentos de las solicitudes de consumo

import { useEffect, useState } from "react";


// components import
import { ToastNotification } from "../toast-notification/ToastNotification";

// urls importations
import { urls } from "../../api-urls/api-urls";
import { useLocation, useNavigate } from "react-router-dom";


export const UploadDocsSolConsump = ({ onClose, lang, codSol, token, onHandleReload }) => {

  // GET the useNavigate()
  const navigate = useNavigate();

  // GET the useLocation()
  const location = useLocation();


  // state definition
  const [doc, setDoc] = useState(null); // estado para cargar el documento
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });


  // funcion para cargar el documento en el estado
  const onHandleDoc = ({ target }) => {
    const file = target.files[0];

    setDoc(file);
  };

  // console.log(codSol)


  // funcion para el manejo del formulario
  const onHandleUploadDoc = async (event) => {
    event.preventDefault();

    try {

      if (doc) {
        const formData = new FormData();

        formData.append("file", doc);

        const response = await fetch(`${urls.autorizaciones}/${codSol}/search?language=${lang}`, {
          method: "POST",
          headers: {
            "Authorization": token,
          },
          body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
          setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
          onHandleReload(true);
          setDoc(null);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 404) {
          setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        }


      } else {
        return setNotification((val) => ({ ...val, show: true, msg: "Debe cargar un documento para enviarlo al servidor.", error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para cambiar el valor del reload en el padre
  useEffect(() => {
    if (notification.great) {
      const timer = setTimeout(() => {
        onHandleReload(false)
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notification.great]);



  return (
    <div className="background-modal" onClick={onClose}>
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="container-modal-upload-docs" onClick={(event) => event.stopPropagation()}>
        <h4 className="border-bottom">Cargar factura</h4>
        <form className="m-y-small" onSubmit={onHandleUploadDoc}>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Documento</strong></div>
            <div className="col-71-42">
              <input
                type="file"
                name="file"
                accept=".pdf"
                onChange={onHandleDoc} />
            </div>
          </div>
          {/* fila */}
          <div>
            <span className="txt-muted span">El formato permitido es PDF y el peso máximo es 10mb.</span>
          </div>

          {/* botones de accion */}
          <div className="row-flex j-content-space-around m-y-medium">
            <button type="submit" className="btn-success">Subir</button>
            <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
          </div>
        </form>
      </div>
    </div>
  );
};