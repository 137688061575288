import React, { useEffect, useState } from "react";


//-----> Styles importation
import "../../views/home/HomeView.css";



// images import
import ballongs from "../../assets/cumpleanos-img/ballons-birthday.png";


import { getTheToken } from "../../global-functions/globalFunctions";

// REACT ROUTER IMPORTATION
import { Link, useNavigate } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";
import { useTranslation } from "react-i18next";


export const HomeView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // navigate
  const navigate = useNavigate();

  // GET the react translation
  const [t] = useTranslation("global");

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false);


  // DESTRUCTURING DATA SERVER
  const { birthDay, birthDayCurrent, Month } = dataServer && dataServer;


  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.homePage}?language=${language}`;

    try {
      setIsLoading(true);
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 500) {
        // ocurrió un error en el servidor
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  return (
    <>
      <div className="display-data">
        <div className="container display-data">

          <div className="cumple-img-container">
            {
              birthDayCurrent && birthDayCurrent.length > 0 && (
                <>
                  <img className="ballons-hap-one" src={ballongs} alt="" />
                  <img className="ballons-hap-two" src={ballongs} alt="" />
                </>
              )
            }
            {/* <img src={Month && CUMPLE_IMG_BG[Month]} alt="Cumple" /> */}
            <div className="cont-list">
              <div className="header-cont-cumpl">
                <span className="first-ganc"></span>
                <span className="second-ganc"></span>
                <span className="first-whol"></span>
                <span className="second-whol"></span>
                {
                  birthDay && birthDay.length > 0 && (
                    <>
                      <h2 style={{ color: "var(--white-color)", fontWeight: 300, textAlign: "center" }}>{t("LoginView.cumpleanios")}</h2>
                      <h2 style={{ color: "var(--white-color)", textAlign: "center", textTransform: "uppercase" }}>{Month}</h2>
                    </>
                  )
                }
                {
                  birthDayCurrent && birthDayCurrent.length > 0 && (
                    <>
                      <h2 style={{ color: "var(--white-color)", fontWeight: 300, textAlign: "center" }}>{t("LoginView.feliz")}</h2>
                      <h2 style={{ color: "var(--white-color)", textAlign: "center" }}>{t("LoginView.cumpleanios")}</h2>
                    </>
                  )
                }
              </div>
              <div className="body-cont-cumpl">
                {
                  isLoading && (<h5>{t("PartModify.cargando_info")}</h5>)
                }
                <ul style={{ listStyle: "none", justifyContent: birthDayCurrent && birthDayCurrent.length === 1 ? "center" : "space-evenly" }} className={birthDayCurrent && birthDayCurrent.length > 0 && "lst-cumpl"}>
                  {
                    birthDay && birthDay.map((lst) => (
                      <li key={lst.CodEmpleado}>
                        <Link className="link-tag" to={`/human-capital/employees?nom_empl=${lst.NomEmpleado}`}>{lst.Dia} {lst.NomEmpleado}</Link>
                      </li>
                    ))
                  }
                  {
                    birthDayCurrent && birthDayCurrent.map((lst) => (
                      <li key={lst.CodEmpleado}>
                        <Link to={`/human-capital/employees?nom_empl=${lst.NomEmpleado}`}>
                          <img src={`data:image/jpeg;base64,${lst.Foto}`} alt={lst.NomEmpleado} className="cumpl-img" />
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="m-y-medium t-gear">
            <h1 className="txt-center">Esta sección está en construcción</h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
              <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
              <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
            </svg>
          </div> */}


        </div>
      </div>
    </>
  );
};