// componente para autorizar los empleados

import { useEffect, useState } from "react";


// components importation
import { ToastNotification } from "../toast-notification/ToastNotification";


// url importation
import { urls } from "../../api-urls/api-urls";

// global functions import
import { getTheToken } from "../../global-functions/globalFunctions";

// react router dom import
import { useLocation, useNavigate } from "react-router-dom";


export const AuthorizeEmployee = ({ onClose, lang }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data en el estado
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ empleado: "", tarje: "", observacion: "" });
  const [notification, setNotification] = useState({ show: true, msg: "", error: false, great: false, success: false });


  // destructuring data server
  const { employees, cards } = dataServer && dataServer;


  // funcion para el manejo del formulario
  const onHandleAuthorize = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}&action=2`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
        setStateForm((val) => ({ ...val, empleado: "", tarje: "", observacion: "" }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para solicitar los datos al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}&action=1`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  return (
    <div className="background-modal" onClick={onClose}>
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="container-modal-add-employee-auth" onClick={(event) => event.stopPropagation()}>
        <h5 className="border-bottom">Autorizar empleado</h5>
        <form className="m-y-small" onSubmit={onHandleAuthorize}>
          <div className="row-flex">
            <div className="col-3"><strong>Empleado</strong></div>
            <div className="col-61-58">
              <select
                name="employee"
                value={stateForm.empleado}
                onChange={({ target }) => setStateForm((val) => ({ ...val, empleado: target.value }))}>
                <option value=""></option>
                {
                  employees && employees.map((emp) => (
                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-3"><strong>Tarjeta</strong></div>
            <div className="col-61-58">
              <select
                name="card"
                value={stateForm.tarje}
                onChange={({ target }) => setStateForm((val) => ({ ...val, tarje: target.value }))}>
                <option value=""></option>
                {
                  cards && cards.map((tarj) => (
                    <option key={tarj.CodId} value={tarj.CodId}>{tarj.NumTarjeta}</option>
                  ))
                }
              </select>
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-3"><strong>Observación</strong></div>
            <div className="col-61-58">
              <textarea
                className="textarea-description"
                name="observati"
                value={stateForm.observacion}
                onChange={({ target }) => setStateForm((val) => ({ ...val, observacion: target.value }))}
                autoComplete="off" />
            </div>
          </div>
          {/* boton para autorizar */}
          <div className="row-flex m-y-small j-content-space-around">
            <button type="submit" className="btn-primary">Autorizar</button>
            <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
          </div>
        </form>
      </div>
    </div>
  );
};