// componente para consultar el detalle de las herramientas y ver el seguimient

import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";



import { useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// React translation import
import { useTranslation } from "react-i18next";




export const AssetRequestHerraDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useParams
  const { reqId, reqDet } = useParams();

  // State definition
  const [dataServ, setDataSer] = useState([]); // estado para cargar la data del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, msg: "" });
  const [stateForm, setStateForm] = useState({ codSol: "", idServ: "", fecSol: "", notSal: "", fecReq: "", fecDev: "", cliente: "", respon: "", dirClie: "", numPar: "", descrip: "" });


  // destructuring data server
  const { principalInfo, segHerra } = dataServ && dataServ;




  // funcion para traer la data del servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.requestTools}/${reqId}/${reqDet}/search?language=${language}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataSer(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, msg: data?.msg }));
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // console.log(dataServ?.principalInfo)

  // useEffect para cargar la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if (principalInfo && principalInfo.length > 0) {
      setStateForm((val) => ({
        ...val, codSol: principalInfo[0]?.CodSol, idServ: principalInfo[0]?.CodServicio, fecSol: principalInfo[0]?.FecSolicitud,
        notSal: principalInfo[0]?.NumSalida, fecReq: principalInfo[0]?.FecRequerida, fecDev: principalInfo[0]?.FecDevolucion,
        cliente: principalInfo[0]?.NomCliente, respon: principalInfo[0]?.NomEmpleado, dirClie: principalInfo[0]?.DirCliente,
        numPar: principalInfo[0]?.HerNumParte, descrip: principalInfo[0]?.HerNombre
      }));
    }
  }, [principalInfo]);






  return (
    <div className="container display-data">
      {
        state.unauthorized && (<Error403View />)
      }

      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      {
        !state.dataVisible && state.msg && (<h4 style={{ color: "var(--danger-color)" }}>{state.msg}</h4>)
      }

      {
        state.dataVisible && (
          <>
            <div className="row-flex">
              <div className="col-8">
                <strong>{t("LogisticViewComp.request")}</strong>
              </div>
              <div className="col-5">
                <span>{stateForm.codSol}</span>
              </div>
              <div className="col-8">
                <strong>{t("LogisticViewComp.serviceID")}</strong>
              </div>
              <div className="col-5">
                <span>{stateForm.idServ}</span>
              </div>
              <div className="col-8">
                <strong>{t("WareHouseView.requestDate")}</strong>
              </div>
              <div className="col-5">
                <span>{renderDate(stateForm.fecSol, language)}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("LogisticViewComp.nota_salida")}</strong>
              </div>
              <div className="col-5">
                <span>{stateForm.notSal}</span>
              </div>
              <div className="col-8">
                <strong>{t("WareHouseView.requiredDate")}</strong>
              </div>
              <div className="col-5">
                <span>{renderDate(stateForm.fecReq, language)}</span>
              </div>
              <div className="col-8">
                <strong>{t("RequestPartsView.fec_devol")}</strong>
              </div>
              <div className="col-5">
                <span>{renderDate(stateForm.fecDev, language)}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("RequestPartsView.cliente")}</strong>
              </div>
              <div className="col-52-5">
                <span>{stateForm.cliente}</span>
              </div>
              <div className="col-8">
                <strong>{t("TableParts.respons")}</strong>
              </div>
              <div className="col-5">
                <span>{stateForm.respon}</span>
              </div>
            </div>

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("customerView.address")}</strong>
              </div>
              <div className="col-85-71">
                <span>{stateForm.dirClie}</span>
              </div>
            </div>

            <hr className="m-y-small" />

            <div className="row-flex">
              <div className="col-8">
                <strong>{t("TableParts.parte")}</strong>
              </div>
              <div className="col-5">
                <span>{stateForm.numPar}</span>
              </div>
              <div className="col-8">
                <strong>{t("TableParts.descrip")}</strong>
              </div>
              <div className="col-52-19">
                <span>{stateForm.descrip}</span>
              </div>
            </div>

            <div className="table-container-mobile m-y-small">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>Fecha{t("TableParts.fecha")}</th>
                    <th>{t("TableParts.eta")}</th>
                    <th>{t("TableParts.responsable_etapa")}</th>
                    <th>{t("TableParts.accion")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    segHerra && segHerra.map((seg) => (
                      <tr key={seg.CodSegDetSolHerramienta}>
                        <td>{renderDate(seg.FecIngreso, language)}</td>
                        <td>{seg.NomEtapa}</td>
                        <td>{seg.NomEmpleado}</td>
                        <td>{seg.Accion}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }

      <div className="row-flex j-content-space-around m-y-small">
        <button type="button" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
      </div>

    </div>
  );
};