// Este componente es para crear un nuevo anticipo

import React, { useEffect, useState } from "react";


// Components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// React router importation
import { useLocation, useNavigate } from "react-router-dom";

// Global function importation
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// Url importation
import { urls } from "../../api-urls/api-urls";


// React translation
import { useTranslation } from "react-i18next";



export const CreateAnticipo = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();


  // State definition
  const [dataServer, setDataServer] = useState([]); // Estado para cargar la informacion inicial en el estado
  const [questionAction, setQuestionAction] = useState(""); // Estado para crear la pregunta
  const [state, setState] = useState({ isLoading: false, dataVisible: false, serverError: false, showModal: false });
  const [stateForm, setStateForm] = useState({ empresa: "", division: "", gerencia: "", areaFun: "", fecRequerida: "", formaPago: "", tranfeNombre: "", numCuenta: "", moneda: "", fecSolic: "", solicitante: "", beneficiario: "", comment: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false, nroControl: 0 });


  // Destructuring data server
  const { finalObj } = dataServer && dataServer;

  // Fecha solicitud
  const FecSol = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;


  // Funcion para abrir el modal al dar click en guardar
  const onSaveInfoModal = () => {
    if (stateForm.empresa === "") {
      return setNotification({ show: true, msg: "Debe seleccionar la empresa.", error: true, great: false, success: false, nroControl: 0 });
    }

    if (stateForm.division === "") {
      return setNotification({ show: true, msg: "Debe seleccionar la division a la que pertenece", error: true, great: false, success: false, nroControl: 0 });
    }

    if (stateForm.gerencia === "") {
      return setNotification({ show: true, msg: "Debe seleccionar la gerencia a la que pertenece", error: true, great: false, success: false, nroControl: 0 });
    }

    if (stateForm.fecRequerida === "") {
      return setNotification({ show: true, msg: "Debe seleccionar la fecha requerida", error: true, great: false, great: false, success: false, nroControl: 0 });
    }

    if (stateForm.formaPago === "") {
      return setNotification({ show: true, msg: "Debe seleccionar la forma de pago", error: true, great: false, success: false, nroControl: 0 });
    }

    if (stateForm.moneda === "") {
      return setNotification({ show: true, msg: "Debe seleccionar la moneda", error: true, great: false, success: false, nroControl: 0 });
    }

    setQuestionAction("Está seguro que desea crear la solicitud de anticipo?");
    setState((val) => ({ ...val, showModal: true }));
  };


  // Funcion para hacer el fetch de datos
  const fetchDataServer = async () => {
    try {
      const response = await fetch(`${urls.anticipo}?language=${language}&initialData=1`, {
        method: "POST",
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

  }, [language]);


  // useEffect para montar algunos datos en el form
  useEffect(() => {
    if (finalObj && finalObj.length > 0) {
      setStateForm((val) => ({
        ...val, division: finalObj[0]?.CodGrupo.toString() ?? "", empresa: finalObj[0]?.CodEmpresa, gerencia: finalObj[0]?.CodGerencia.toString() ?? "",
        solicitante: finalObj[0]?.Solicitante, beneficiario: finalObj[0]?.Solicitante, tranfeNombre: finalObj[0]?.Solicitante
      }));
    }
  }, [finalObj]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false, nroControl: 0 });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useEffect para realizar la redireccion
  useEffect(() => {
    if (notification.success) {
      const timer = setTimeout(() => {
        navigate(`/travel-expenses/expense-detail/${notification.nroControl}`);
      }, 4000);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.success]);


  // console.log(initialData)

  // Funcion para manejar el envio del formulario
  const onCreateAdvance = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.anticipo}?language=${language}&initialData=0`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, showModal: false }));
        setNotification({ show: true, msg: data?.msg, error: false, great: true, success: true, nroControl: data?.nroControl });
      }

    } catch (error) {
      console.log(error);
    }
  };




  return (
    <>
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <div className="card-shadow">
              <form onSubmit={onCreateAdvance}>
                {/* FILA */}
                <div className="row-flex">
                  {/* Nro. Control */}
                  <div className="col-7"><strong>Nro. Control</strong></div>
                  <div className="col-3"><h4>{ }</h4></div>
                  {/* Etapa */}
                  <div className="col-7"><strong>Etapa</strong></div>
                  <div className="col-3"><strong className="active-class" style={{ textDecoration: 'underline' }}>{"Elaboración de anticipo"}</strong></div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Empresa */}
                  <div className="col-7"><strong>Empresa</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="company"
                      value={stateForm.empresa}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                      <option value=""></option>
                      <option value="1">Global Medica</option>
                      <option value="2">GMD HealthCare Ltd</option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Division empresa */}
                  <div className="col-7"><strong>División empresa</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="divisionComp"
                      value={stateForm.division}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                      <option value=""></option>
                      {
                        finalObj && finalObj[0]?.divisionEmpr.map((divi) => (
                          <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* Gerencia */}
                  <div className="col-7"><strong>Gerencia</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="gerencia"
                      value={stateForm.gerencia}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
                      <option value=""></option>
                      {
                        finalObj && finalObj[0]?.gerencia.map((gere) => (
                          <option key={gere.CodGerencia} value={gere.CodGerencia}>{gere.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Area funcional */}
                  <div className="col-7"><strong>Area Funcional</strong></div>
                  <div className="col-3">
                    <select
                      name="areaFun"
                      value={stateForm.areaFun}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, areaFun: target.value }))}>
                      <option value=""></option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Solicitante */}
                  <div className="col-7"><strong>Solicitante</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="requestor"
                      value={stateForm.solicitante}
                      readOnly />
                  </div>
                  {/* Beneficiario */}
                  <div className="col-7"><strong>Beneficiario</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="benefi"
                      value={stateForm.beneficiario}
                      readOnly />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Fecha solicitud */}
                  <div className="col-7"><strong>Fecha de solicitud</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="fecRequest"
                      value={renderDate(FecSol, language)}
                      readOnly />
                  </div>
                  {/* Fecha */}
                  <div className="col-7"><strong>Fecha requerida</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecRequir"
                      value={stateForm.fecRequerida}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, fecRequerida: target.value }))} />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Forma de pago */}
                  <div className="col-7"><strong>Forma de pago</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="formPag"
                      value={stateForm.formaPago}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, formaPago: target.value }))}>
                      <option value=""></option>
                      <option value="1">Depósito</option>
                      <option value="2">Efectivo</option>
                      <option value="3">Tarjeta de crédito</option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Tranferir a nombre de */}
                  <div className="col-7"><strong>Tranferir a nombre</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="tranfName"
                      value={stateForm.tranfeNombre}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, tranfeNombre: target.value }))} />
                  </div>
                  {/* Numero de cuenta */}
                  <div className="col-7"><strong>Número de cuenta</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="accountNum"
                      value={stateForm.numCuenta}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, numCuenta: target.value }))} />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Moneda */}
                  <div className="col-7"><strong>Moneda</strong><strong className="required">(*)</strong></div>
                  <div className="col-3">
                    <select
                      name="moneda"
                      value={stateForm.moneda}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                      <option value=""></option>
                      <option value="0">RD$ Pesos</option>
                      <option value="1">US$ Dollar</option>
                      <option value="2">€ Euro</option>
                      <option value="3">TT$ Dollar</option>
                    </select>
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Comentario */}
                  <div className="col-7"><strong>Comentario</strong></div>
                  <div className="col-82-16">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={stateForm.comment}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                {/* Botones de accion */}
                <div className="row-flex j-content-space-around m-y-medium">
                  <button type="button" className="btn-primary" onClick={onSaveInfoModal}>Guardar</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
                </div>

                {/* Modal para confirmar que desea guardar */}
                {
                  state.showModal && (
                    <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                      <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                        {/* Question mark */}
                        <div className="row-flex m-y-small align-c justify-center">
                          <div className="question-mark-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                            </svg>
                          </div>
                        </div>
                        <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                          {questionAction}
                        </h5>
                        {/* Botones de accion */}
                        <div className="row-flex m-y-medium j-content-space-around">
                          <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                          <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                        </div>
                      </div>
                    </div>
                  )
                }
              </form>
            </div>
          )
        }
      </div>
    </>
  );
};