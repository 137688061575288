import React, { useEffect, useState } from "react";

// ----> Importations of useTranslation() Hook
import { useTranslation } from "react-i18next";


// ----> COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// ----> USEFETCH COMPONENTS
// import useFetchEquipments from "../../customs-hooks/equipments-view/useFetchEquipments";

// ----> URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// ----> REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";


// GLOBAL FUNCTIONS 
import { getTheToken } from "../../global-functions/globalFunctions";


export const EquipmentView = () => {

  // GET the language value from localStorage
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);


  // ----> Destructuring Translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // obtener el filtro del localStorage
  const FILTER_INPUT = JSON.parse(localStorage.getItem("filtEqui")) ?? "";


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [listEquipment, setListEquipment] = useState([]); // estado para colocar la lista de los equipos
  const [filtLinea, setFiltLinea] = useState([]); // estado para filtrar la linea
  const [filtModalidad, setFiltModalidad] = useState([]); // estado para filtrar la modalidad
  const [filtTipEquip, setFiltTipEquip] = useState([]); // estado para filtrar los tipos de equipos
  const [filtModelo, setFiltModelo] = useState([]); // estado para filtrar los modelos
  const [controller, setController] = useState(null); // estado para abortar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false); // state para cuando se esté cargando la data
  const [showFilterModal, setShowFilterModal] = useState(false); // este state es para abrir el modal del filtrado
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [stateFilter, setStateFilter] = useState({
    division: FILTER_INPUT.division ?? "", linea: FILTER_INPUT.linea ?? "", modalidad: FILTER_INPUT.modalidad ?? "",
    tipEquipo: FILTER_INPUT.tipEquipo ?? "", modelo: FILTER_INPUT.modelo ?? "", contrato: FILTER_INPUT.contrato ?? "1",
    cliente: FILTER_INPUT.cliente ?? "", zona: FILTER_INPUT.zona ?? "", sidSerialGON: FILTER_INPUT.sidSerialGON ?? "",
    tecnico: FILTER_INPUT.tecnico ?? ""
  });

  // DESTRUCTURING DATA FROM THE SERVER
  const { equipments, division, linea, modalidad, tipEquip, modelo } = dataServer && dataServer;


  // FUNCTION DEFINITIONS
  const onShowModalFilter = () => {
    setShowFilterModal(!showFilterModal);
  };


  // funcion para limpiar el filtro
  const onClearInputs = () => {
    setStateFilter((val) => ({
      ...val, division: "", linea: "", modalidad: "", tipEquipo: "", modelo: "",
      contrato: "", cliente: "", zona: "", sidSerialGON: "", tecnico: ""
    }));

    localStorage.setItem("filtEqui", JSON.stringify({
      division: "", linea: "", modalidad: "", tipEquipo: "", modelo: "",
      contrato: "", cliente: "", zona: "", sidSerialGON: "", tecnico: ""
    }));

  };


  // funcion para realizar la busqueda
  const onSearchEquipment = async (event) => {
    event.preventDefault();

    localStorage.setItem("filtEqui", JSON.stringify(stateFilter));

    try {
      const response = await fetch(`${urls.equipments}?contrato=${stateFilter.contrato}&zona=${stateFilter.zona}&division=${stateFilter.division}&linea=${stateFilter.linea}&modalidad=${stateFilter.modalidad}&tipEquipo=${stateFilter.tipEquipo}&modelo=${stateFilter.modelo}&cliente=${stateFilter.cliente}&sidSerialGon=${stateFilter.sidSerialGON}&tecnico=${stateFilter.tecnico}&language=${language}`, {
        headers: { "Authorization": getTheToken().token },
      });

      const data = await response.json();

      if (response.status === 200) {
        setListEquipment(data?.equipments);
        setShowFilterModal(false);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setDataServer(data);
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para traer la data del servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setIsLoading(true);
      const response = await fetch(`${urls.equipments}?contrato=${stateFilter.contrato}&zona=${stateFilter.zona}&division=${stateFilter.division}&linea=${stateFilter.linea}&modalidad=${stateFilter.modalidad}&tipEquipo=${stateFilter.tipEquipo}&modelo=${stateFilter.modelo}&cliente=${stateFilter.cliente}&sidSerialGon=${stateFilter.sidSerialGON}&tecnico=${stateFilter.tecnico}&language=${language}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) { // el usuario no se encuentra autenticado
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setDataServer(data);
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para cargar los equipos en el estado
  useEffect(() => {
    if (equipments && equipments.length > 0) {
      setListEquipment(equipments);
    }
  }, [equipments]);


  // useEffect para buscar las lineas cuando se haya buscado la division
  useEffect(() => {
    if (linea && linea.length > 0) {
      const filterLines = linea && linea.filter((lin) => lin.CodDivision === stateFilter.division);

      setFiltLinea(filterLines);
    }
  }, [stateFilter.division, FILTER_INPUT.division]);

  // useEffect para buscar la modalidad cuando se haya buscado la linea
  useEffect(() => {
    if (modalidad && modalidad.length > 0) {
      const filterModalidad = modalidad && modalidad.filter((moda) => moda.CodLinea === stateFilter.linea);

      setFiltModalidad(filterModalidad);
    }
  }, [stateFilter.linea, FILTER_INPUT.linea]);


  // useEffect para buscar el tipo de equipo cuando se haya buscado la modalidad
  useEffect(() => {
    if (tipEquip && tipEquip.length > 0) {
      const filterTipEquip = tipEquip && tipEquip.filter((tipEq) => tipEq.CodModalidad === stateFilter.modalidad);

      setFiltTipEquip(filterTipEquip);
    }
  }, [stateFilter.modalidad, FILTER_INPUT.modalidad]);


  // useEffect para buscar el modelo cuando se haya buscado el tipo de equipo
  useEffect(() => {
    if (modelo && modelo.length > 0) {
      const filterModelo = modelo && modelo.filter((mode) => mode.CodTipEquipo.toString() === stateFilter.tipEquipo.toString());

      setFiltModelo(filterModelo);
    }
  }, [stateFilter.tipEquipo, FILTER_INPUT.tipEquipo]);




  return (
    <>
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // Loading component
        isLoading && (<LoadingComp />)
      }
      {/* MODAL PARA FILTRAR */}
      {
        showFilterModal && (
          <div className="background-modal" onClick={onShowModalFilter}>
            <div className="container-modal" onClick={(event) => event.stopPropagation()}>
              {/* FILA 1 */}
              <form onSubmit={onSearchEquipment}>
                <div className="row-flex m-y-small">
                  {/* DIVISION */}
                  <div className="col-7"><strong>Division</strong></div>
                  <div className="col-3">
                    <select
                      name="division"
                      value={stateFilter.division}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, division: target.value }))}>
                      <option value=""></option>
                      {
                        division && division.map((divi) => (
                          <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* LINEA */}
                  <div className="col-7"><strong>Linea</strong></div>
                  <div className="col-3">
                    <select
                      name="linea"
                      value={stateFilter.linea}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, linea: target.value }))}>
                      <option value=""></option>
                      {
                        filtLinea && filtLinea.map((lin) => (
                          <option key={lin.CodLinea} value={lin.CodLinea}>{lin.NomLinea}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA 2 */}
                <div className="row-flex">
                  {/* MODALIDAD */}
                  <div className="col-7"><strong>Modalidad</strong></div>
                  <div className="col-3">
                    <select
                      name="modalidad"
                      value={stateFilter.modalidad}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, modalidad: target.value }))}>
                      <option value=""></option>
                      {
                        filtModalidad && filtModalidad.map((moda) => (
                          <option key={moda.CodModalidad} value={moda.CodModalidad}>{moda.NomModalidad}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* TIPO DE EQUIPO */}
                  <div className="col-7"><strong>Tipo de Equipo</strong></div>
                  <div className="col-3">
                    <select
                      name="tipEquipo"
                      value={stateFilter.tipEquipo}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, tipEquipo: target.value }))}>
                      <option value=""></option>
                      {
                        filtTipEquip && filtTipEquip.map((tipEq) => (
                          <option key={tipEq.CodTipEquipo} value={tipEq.CodTipEquipo}>{tipEq.NomTipEquipo}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA 3 */}
                <div className="row-flex m-y-small">
                  {/* MODELO */}
                  <div className="col-7"><strong>Modelo</strong></div>
                  <div className="col-3">
                    <select
                      name="modelo"
                      value={stateFilter.modelo}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, modelo: target.value }))}>
                      <option value=""></option>
                      {
                        filtModelo && filtModelo.map((mode) => (
                          <option key={mode.CodModelo} value={mode.CodModelo}>{mode.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* CONTRATO */}
                  <div className="col-7"><strong>Contrato</strong></div>
                  <div className="col-3">
                    <select
                      name="contrato"
                      value={stateFilter.contrato}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, contrato: target.value }))}>
                      <option value="">Todos</option>
                      <option value="1">Performance</option>
                      <option value="2">Mano de Obra</option>
                      <option value="3">Garantía Extendida</option>
                      <option value="4">Primium</option>
                      <option value="0">Garantía</option>
                    </select>
                  </div>
                </div>

                {/* FILA 4 */}
                <div className="row-flex">
                  {/* CLIENTE */}
                  <div className="col-7"><strong>Cliente</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      placeholder={language === "en" ? "Search by customer name" : "Buscar por nombre de cliente"}
                      type="text"
                      name="customer"
                      value={stateFilter.cliente}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, cliente: target.value }))} />
                  </div>
                  {/* ZONA */}
                  <div className="col-7"><strong>Zona</strong></div>
                  <div className="col-3">
                    <select
                      name="zona"
                      value={stateFilter.zona}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, zona: target.value }))}>
                      <option value=""></option>
                      <option value="6">Caribe</option>
                      <option value="1">República Dominicana</option>
                      <option value="7">Trinidad & Tobago</option>
                    </select>
                  </div>
                </div>

                {/* FILA 5 */}
                <div className="row-flex m-y-small">
                  {/* SID/SERIAL/GON */}
                  <div className="col-7"><strong>SID/Serial/GON</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      placeholder={language === "en" ? "Search by SID / Serial or GON" : "Buscar por SID / Serial o GON"}
                      type="text"
                      name="sid"
                      value={stateFilter.sidSerialGON}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, sidSerialGON: target.value }))} />
                  </div>
                  {/* BUSCAR POR EL TECNICO */}
                  <div className="col-7"><strong>{t("equipmentView.tecnician")}</strong></div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      placeholder={language === "en" ? "Search by engineer name" : "Buscar por el nombre del ingeniero"}
                      type="text"
                      name="techni"
                      value={stateFilter.tecnico}
                      onChange={({ target }) => setStateFilter((val) => ({ ...val, tecnico: target.value }))} />
                  </div>
                </div>

                {/* botones de accion */}
                <div className="row-flex j-content-space-around m-y-small">
                  <button type="submit" className="btn-success">{t("serviceCard.buscar")}</button>
                  <button type="button" className="btn-warning" onClick={onClearInputs}>{t("serviceCard.limpiar_filtro")}</button>
                  <button type="reset" className="btn-secondary" onClick={onShowModalFilter}>{t("serviceCard.cerrar")}</button>
                </div>
              </form>
              {
                isLoading && (
                  <div className="simple-spinner"><span></span></div>
                )
              }
            </div>
          </div>
        )
      }
      <div className="container display-data">
        <div className="container-btn-search">
          <button className="btn-search" onClick={onShowModalFilter}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
        </div>
        <div className="table-container-mobile">
          <strong>{listEquipment.length} {language === "en" ? "Registers" : "Registros"}</strong>
          <table className="table">
            <thead className="table-head">
              <tr>
                <th style={{ borderRadius: '5px 0 0 0' }}>System ID</th>
                <th>{t("equipmentView.model")}</th>
                <th>Serial</th>
                <th>{t("equipmentView.tecResponsibleF1")}</th>
                <th>{t("equipmentView.customer")}</th>
                <th style={{ borderRadius: '0 5px 0 0' }}>{t("equipmentView.observation")}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                listEquipment.map(equipment => (
                  <tr key={equipment.CodEquipo}>
                    <td>
                      <Link className="link-tag" to={`/equipments/equipment-detail/${equipment.CodEquipo}`}>
                        {equipment.NumSistema || 'SN'}
                      </Link>
                    </td>
                    <td >
                      {equipment.Modelo}
                    </td>
                    <td style={{ width: '10%' }}>
                      {equipment.Serial}
                    </td>
                    <td style={{ width: '15%' }}>
                      {equipment.NomEmpleado}
                    </td>
                    <td>
                      <Link className="link-tag" to={`/customers/customer-detail/${equipment.CodCliente}`}>{equipment.Cliente}</Link>
                    </td>
                    <td style={{ textTransform: 'capitalize', width: '20%' }}>
                      {equipment.Observacion ? equipment.Observacion.toLowerCase() : ''}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};