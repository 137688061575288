
import React, { useState } from "react";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// URLS Api importation
import { urls } from "../../api-urls/api-urls";



export const ServiceReportsPrincipal = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the useLocation
  const location = useLocation();

  // GET the useNavigate
  const navigate = useNavigate();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);

  return (
    <>
      <div className="container display-data">
        <h1 style={{ color: 'var(--first-color)' }}>Reportes de servicio</h1>

        {/* FILA en forma de columnas */}
        <div className="row-flex">
          {/* COLUMNA 1 */}
          <div className="col-4">
            {/* estadisticas generales */}
            <div><strong>Estadísticas Generales</strong></div>
            {/* LISTA DE ENLACES */}
            <div className="m-y-small">
              <div><Link className="link-tag">Llamadas actualmente abiertas</Link></div>
              <div><Link className="link-tag">Llamadas abiertas en un periodo</Link></div>
              <div><Link className="link-tag">Llamadas cerradas en un periodo</Link></div>
              <div><Link className="link-tag">Casos atendidos</Link></div>
            </div>

            {/* Estadisticas Detallas */}
            <div><strong>Estadísticas Detallas</strong></div>
            {/* LISTA DE ENLACES */}
            <div className="m-y-small">
              <div><Link className="link-tag">Llamadas actualmente abiertas</Link></div>
              <div><Link className="link-tag">Llamadas abiertas en un periodo</Link></div>
              <div><Link className="link-tag">Llamadas cerradas en un periodo</Link></div>
              <div><Link className="link-tag">Actualización de llamadas</Link></div>
              <div><Link className="link-tag">Técnico</Link></div>
            </div>

            {/* Estadisticas Partes */}
            <div><strong>Estadísticas Partes</strong></div>
            {/* LISTA DE ENLACES */}
            <div className="m-y-small">
              <div><Link className="link-tag">Ordenes cerradas</Link></div>
              <div><Link className="link-tag">Ordenes abiertas</Link></div>
              <div><Link className="link-tag">Cotizaciones</Link></div>
              <div><Link className="link-tag">Piezas en llamadas</Link></div>
              <div><Link className="link-tag">Base Instalada</Link></div>
              <div><Link className="link-tag">Base Instalada Modelo</Link></div>
              <div><Link className="link-tag">Up Time Anual</Link></div>
              <div><Link className="link-tag">Up Time Mensual</Link></div>
              <div><Link className="link-tag">Listado de seguimiento de base instalada</Link></div>
              <div><Link className="link-tag">Datos de clientes</Link></div>
            </div>

          </div>
          {/* COLUMNA 2 */}
          <div className="col-3">
            {/* Programacion Call Center */}
            <div><strong>Programación Call Center</strong></div>
            {/* LISTA DE ENLACES */}
            <div className="m-y-small">
              <div><Link className="link-tag">Planificación Mantenimientos</Link></div>
              <div><Link className="link-tag">Horas trabajadas reporte de servicio</Link></div>
              <div><Link className="link-tag">Horas facturadas reporte de servicio</Link></div>
              <div><Link className="link-tag">Llamadas realizadas</Link></div>
              <div><Link className="link-tag">Visitas por llamadas</Link></div>
              <div><Link className="link-tag">Ejecución mantenimientos preventivos</Link></div>
              <div><Link className="link-tag">Gastos por llamada</Link></div>
            </div>

          </div>
          {/* COLUMNA 3 */}
          <div className="col-3">
            {/* Evaluación */}
            <div><strong>Evaluación</strong></div>
            {/* LISTA DE ENLACES */}
            <div className="m-y-small">
              <div><Link className="link-tag">Técnico</Link></div>
              <div><Link className="link-tag">Gerente</Link></div>
              <div><Link className="link-tag">Operaciones</Link></div>
              <div><Link className="link-tag">Nro. Cambio de etapa</Link></div>
              <div><Link className="link-tag">Gráfico % de cumplimiento llamadas abiertas gerentes</Link></div>
              <div><Link className="link-tag">Gráfico días promedio ordenes abiertas</Link></div>
              <div><Link className="link-tag">Gráfico % de cumplimiento etapas ordenes</Link></div>
              <div><Link className="link-tag">Gráfico promedio de facturación</Link></div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};