import React from 'react';

import { AppRouter } from './router/AppRouter';



const App = () => {

  // useEffect(() => {

  //   const handleResize = () => {
  //     window.location.reload();
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };

  // }, []) --------> Esto irá para cuando esté en producció

  return (
    <>
      <AppRouter />
    </>
  );
};

export default App;


