// para mostrar el historial de una herramienta

// warehouse/tools-detail/history/:codHerr

import { useEffect, useState } from "react";

// components import
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";

// react router import
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";

// global functions
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";


export const ToolsHistory = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET location
  const location = useLocation();

  // GET useNavigate
  const navigate = useNavigate();

  // GET params
  const { codHerr } = useParams();


  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });


  // Destructuring dataServer
  const { history, herramienta } = dataServer && dataServer;


  // funcion para realizar la peticion al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.tools}/${codHerr}/search?language=${language}&historial=yes`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la informacion al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codHerr, language]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  return (
    <div className="container">
      {
        // error 403
        state.unauthorized && (<Error403View />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        state.dataVisible && (
          <>
            <div className="row-flex">
              {/* codigo */}
              <div className="col-7"><strong>Código</strong></div>
              <div className="col-3"><span>{herramienta && herramienta[0]?.CodHerramienta}</span></div>
              {/* nro de parte */}
              <div className="col-7"><strong>Nro. Parte</strong></div>
              <div className="col-3"><span>{herramienta && herramienta[0]?.NumParte}</span></div>
            </div>

            <div className="row-flex">
              {/* descripcion */}
              <div className="col-7"><strong>Descripción</strong></div>
              <div className="col-3"><span>{herramienta && herramienta[0]?.Nombre}</span></div>
              {/* tipo */}
              <div className="col-7"><strong>Tipo</strong></div>
              <div className="col-3"><span>{herramienta && herramienta[0]?.NomTipo}</span></div>
            </div>

            <hr className="m-y-small" />

            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>Nro. Solicitud</th>
                    <th>Nota de salida</th>
                    <th>Fecha salida</th>
                    <th>Fecha devolución</th>
                    <th>Solicitante</th>
                    <th>Recibida</th>
                    <th>Devuelta</th>
                    <th>Almacén</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    history && history.map((his) => (
                      <tr key={`${his.CodDetSolHerramienta}${his.CodSolHerramienta}`}>
                        <td className="txt-center">
                          <Link className="link-tag">{his.CodSolHerramienta}</Link>
                        </td>
                        <td className="txt-center">
                          <Link className="link-tag">{his.NumSalida}</Link>
                        </td>
                        <td className="txt-center">{renderDate(his.FecSalida, language)}</td>
                        <td className="txt-center">{renderDate(his.FecDevolucion, language)}</td>
                        <td>{his.EmpResponsable}</td>
                        <td className="txt-center">{his.LABEL_RECIBIDA}</td>
                        <td className="txt-center">{his.LABEL_DEVUELTA}</td>
                        <td className="txt-center">{his.LABEL_ALMACEN}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            <div className="row-flex j-content-space-around m-y-medium">
              <button type="button" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
            </div>

          </>
        )
      }
    </div>
  );
};