import React, { useEffect, useState } from "react";


// ----> Hook importation for translations
import { useTranslation } from "react-i18next";

// ----> components importations
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { Error500Comp } from "../../components/errors/Error500Comp";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTION IMPORTATIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";




export const NewEmployee = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the useLocation
  const location = useLocation();

  // GET the navigate
  const navigate = useNavigate();

  const [t] = useTranslation("global");

  // DATE
  const date = new Date();
  const dateFormatted = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  // STATE DIFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, serverError: false, unauthorized: false, dataVisible: false });
  const [stateInputs, setStateInputs] = useState({
    nombre: "", apellido: "", codLider: 0, codDivisionProduct: "", divisionCompany: "", codLinea: "", codZona: 0, codPerfil: 0, codNivCargo: 0, coordinador: false, codEstado: 0, codCiudad: 0,
    codGerencia: 0, codUnidad: 0, unidad: "", cargo: "", inicial: "", cedula: "", aveCalle: "", ediQta: "", urbSector: "", codPostal: "", codTelefono: "", telefono: "",
    codCelular: "", celular: "", fecNacimiento: "", lugNacimiento: "", profesion: "", estCivil: "", correo: "", fecIngEmpresa: "", SSO: 0, token: 0, nomEmergencia: "", parentesco: "",
    codTelEmergencia: "", telEmergencia: "", nivViatico: 0, activo: false, codEmpIngreso: 0, codEmpModificacion: 0, fecIngreso: "", fecModificacion: "", codEmpresa: "", extension: "",
    vacaciones: false, codPais: 0, FESSO: "", numCuentaBan: ""
  });
  const [filterProvin, setFilterProvin] = useState([]);


  // DESTRUCTURING DATA SERVER
  const { states, regions, division, company, divisionCompany, grupOcupacional, supervisor, areas, employeePermission } = dataServer && dataServer;


  // useEffect PARA FILTRAR LAS PROVINCIAS CUANDO CAMBIE EL codEstado
  useEffect(() => {
    if (regions && regions.length > 0) {
      const regionsFound = regions.filter((region) => region.CodEstado === Number(stateInputs.codEstado));
      setFilterProvin(regionsFound);
    }
  }, [stateInputs.codEstado, regions]);


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.employees}?initialData=true&language=${language}`, {
          method: "POST",
          headers: {
            "Authorization": getTheToken().token,
            "Content-Type": "application/json"
          }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        } else if (response.status === 500) {
          setState((val) => ({ ...val, serverError: true }));
        }

        setState((val) => ({ ...val, isLoading: false }));
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [language]);


  // FUNCION PARA ENVIAR LOS DATOS Y CREAR EL EMPLEADO
  const onSubmitCreateEmployee = async (event) => {
    event.preventDefault();
    console.log(stateInputs)
    try {
      const response = await fetch(`${urls.employees}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": getTheToken().token
        },
        body: JSON.stringify(stateInputs)
      });

      if (response.status === 201) {

      }

    } catch (error) {
      console.log(error);
    }

  };

  return (
    <>
      {
        // unauthorized component
        state.unauthorized && (<Error403View />)
      }
      {
        // server Error component
        state.serverError && (<Error500Comp />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        state.dataVisible && (
          <>
            <div className="display-data">
              <div className="container">
                <form onSubmit={onSubmitCreateEmployee}>
                  {/* FILA 1 */}
                  <div className="row-flex">
                    {/* NOMBRE */}
                    <div className="col-7"><strong>{t("humanResources.name")}</strong><span className="required">(*)</span></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        type="text"
                        name="Nombre"
                        value={stateInputs.nombre}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, nombre: target.value })} />
                    </div>
                    {/* APELLIDOS */}
                    <div className="col-7"><strong>{t("humanResources.lastName")}</strong><span className="required">(*)</span></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="Apellido"
                        type="text"
                        value={stateInputs.apellido}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, apellido: target.value })} />
                    </div>
                  </div>

                  {/* FILA 2 */}
                  <div className="row-flex">
                    {/* INICIALES */}
                    <div className="col-7"><strong>Iniciales</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        type="text"
                        name="iniciales"
                        value={stateInputs.inicial}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, inicial: target.value })} />
                    </div>
                  </div>

                  {/* FILA 3 */}
                  <div className="row-flex">
                    {/* CEDULA */}
                    <div className="col-7"><strong>{t("humanResources.idCardLabel")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="Cedula"
                        type="text"
                        value={stateInputs.cedula}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, cedula: target.value })} />
                    </div>
                    {/* FECHA NACIMIENTO */}
                    <div className="col-7"><strong>{t("humanResources.dateOfBirth")}</strong></div>
                    <div className="col-4">
                      <input
                        className="input-date-styles"
                        type="date"
                        name="fecNacimiento"
                        value={stateInputs.fecNacimiento}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, fecNacimiento: target.value })} />
                    </div>
                  </div>

                  {/* FILA 4 */}
                  <div className="row-flex">
                    {/* ESTADO CIVIL */}
                    <div className="col-7"><strong>{t("humanResources.maritalStatus")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="EstCivil"
                        type="text"
                        value={stateInputs.estCivil}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, estCivil: target.value })} />
                    </div>
                    {/* PROFESION */}
                    <div className="col-7"><strong>{t("humanResources.profession")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="Profesion"
                        type="text"
                        value={stateInputs.profesion}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, profesion: target.value })} />
                    </div>
                  </div>

                  {/* FILA 5 */}
                  <div className="row-flex">
                    {/* TELEFONO */}
                    <div className="col-7"><strong>{t("humanResources.telephoneLabel")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '20%' }}
                        className="input-text-control"
                        name="CodTelefono"
                        type="text"
                        value={stateInputs.codTelefono}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, codTelefono: target.value })} />
                      <input
                        style={{ width: '75%', marginLeft: '5%' }}
                        className="input-text-control"
                        name="Telefono"
                        type="text"
                        value={stateInputs.telefono}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, telefono: target.value })} />
                    </div>
                    {/* TELEFONO FLOTA */}
                    <div className="col-7"><strong>{t("humanResources.phoneCompany")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '20%' }}
                        className="input-text-control"
                        name="CodCelular"
                        type="text"
                        value={stateInputs.codCelular}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, codCelular: target.value })} />
                      <input
                        style={{ width: '75%', marginLeft: '5%' }}
                        className="input-text-control"
                        name="Celular"
                        type="text"
                        value={stateInputs.celular}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, celular: target.value })} />
                    </div>
                    {/* EXT */}
                    <div className="col-7">
                      <strong>Ext.</strong>
                      <input
                        style={{ width: '50%', marginLeft: '5%' }}
                        className="input-text-control"
                        name="Extension"
                        type="text"
                        value={stateInputs.extension}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, extension: target.value })} />
                    </div>
                  </div>

                  {/* FILA 6 */}
                  <div className="row-flex">
                    {/* SSO GE */}
                    <div className="col-7"><strong>SSO GE</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="SSO"
                        type="text"
                        value={stateInputs.SSO}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, SSO: target.value })} />
                    </div>
                  </div>

                  {/* DIRECCION DE RESIDENCIA */}
                  <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">{t("humanResources.addressResidence")}</h4>
                  <hr />

                  {/* FILA 7 */}
                  <div className="row-flex">
                    {/* AVENIDA / CALLE */}
                    <div className="col-7"><strong>{t("humanResources.avenueStreet")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="AveCalle"
                        type="text"
                        value={stateInputs.aveCalle}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, aveCalle: target.value })} />
                    </div>
                    {/* EDIF/QUINTA */}
                    <div className="col-7"><strong>{t("humanResources.building")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="EdiQta"
                        type="text"
                        value={stateInputs.ediQta}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, ediQta: target.value })} />
                    </div>
                  </div>

                  {/* FILA 8 */}
                  <div className="row-flex">
                    {/* URBANIZACION/SECTOR */}
                    <div className="col-7"><strong>{t("humanResources.urbaSector")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="UrbSector"
                        type="text"
                        value={stateInputs.urbSector}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, urbSector: target.value })} />
                    </div>
                    {/* CODIGO POSTAL */}
                    <div className="col-7"><strong>{t("humanResources.postalCode")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="CodPostal"
                        type="text"
                        value={stateInputs.codPostal}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, codPostal: target.value })} />
                    </div>
                  </div>

                  {/* FILA 9 */}
                  <div className="row-flex">
                    {/* PROVINCIA */}
                    <div className="col-7"><strong>{t("humanResources.regionLabel")}</strong></div>
                    <div className="col-4">
                      <select name="CodEstado" value={stateInputs.codEstado} onChange={({ target }) => setStateInputs({ ...stateInputs, codEstado: target.value })}>
                        <option value=""></option>
                        {
                          states && states.map((est) => (
                            <option key={est.CodEstado} value={est.CodEstado}>{est.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                    {/* CIUDAD */}
                    <div className="col-7"><strong>{t("humanResources.cityLabel")}</strong></div>
                    <div className="col-4">
                      <select name="CodCiudad" value={stateInputs.codCiudad} onChange={({ target }) => setStateInputs({ ...stateInputs, codCiudad: target.value })}>
                        <option value=""></option>
                        {
                          filterProvin && filterProvin.map((city) => (
                            <option key={city.CodCiudad} value={city.CodCiudad}>{city.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* EN CASO DE EMERGENCIA */}
                  <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">{t("humanResources.emergencyLabel")}</h4>
                  <hr />

                  {/* FILA 10 */}
                  <div className="row-flex">
                    {/* NOMBRE DEL CONTACTO EMERGENCIA */}
                    <div className="col-7"><strong>{t("humanResources.name")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="NomEmergencia"
                        type="text"
                        value={stateInputs.nomEmergencia}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, nomEmergencia: target.value })} />
                    </div>
                    {/* PARENTESCO */}
                    <div className="col-7"><strong>{t("humanResources.relationShip")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="Parentesco"
                        type="text"
                        value={stateInputs.parentesco}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, parentesco: target.value })} />
                    </div>
                  </div>

                  {/* FILA 11 */}
                  <div className="row-flex">
                    {/* TELEFONO */}
                    <div className="col-7"><strong>{t("humanResources.telephoneLabel")}</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '20%' }}
                        className="input-text-control"
                        name="CodTelEmergencia"
                        type="text"
                        value={stateInputs.codTelEmergencia}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, codTelEmergencia: target.value })} />
                      <input
                        style={{ width: '75%', marginLeft: '5%' }}
                        className="input-text-control"
                        name="TelEmergencia"
                        type="text"
                        value={stateInputs.telEmergencia}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, telEmergencia: target.value })} />
                    </div>
                  </div>

                  {/* PERFIL */}
                  <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">{t("humanResources.profile")}</h4>
                  <hr />

                  {/* FILA 12 */}
                  <div className="row-flex">
                    {/* DIVISION PRODUCTO */}
                    <div className="col-7"><strong>{t("humanResources.productDivision")}</strong></div>
                    <div className="col-4">
                      <select name="productDivision" value={stateInputs.codDivisionProduct} onChange={({ target }) => setStateInputs({ ...stateInputs, codDivisionProduct: target.value })}>
                        <option value=""></option>
                        {
                          division && division.map((divi) => (
                            <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                    {/* LINEA PRODUCTO */}
                    <div className="col-7"><strong>{t("humanResources.productLine")}</strong></div>
                    <div className="col-4">
                      <select>
                        <option value=""></option>
                      </select>
                    </div>
                  </div>

                  {/* FILA 13 */}
                  <div className="row-flex">
                    {/* ZONA */}
                    <div className="col-7"><strong>{t("humanResources.zone")}</strong></div>
                    <div className="col-4">
                      <select name="zona" value={stateInputs.codZona} onChange={({ target }) => setStateInputs({ ...stateInputs, codZona: target.value })}>
                        <option value=""></option>
                        {
                          areas && areas.map((zon) => (
                            <option key={zon.CodZona} value={zon.CodZona}>{language === "en" ? zon.NombreEn : zon.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* FILA 14 */}
                  <div className="row-flex">
                    {/* PERFIL */}
                    <div className="col-7"><strong>{t("humanResources.profile")}</strong></div>
                    {
                      employeePermission && employeePermission[0]?.CodPerfil === 6 ? (
                        <div className="col-4">
                          <select name="CodPerfil" value={stateInputs.codPerfil} onChange={({ target }) => setStateInputs({ ...stateInputs, codPerfil: target.value })}>
                            <option value=""></option>
                            <option value="4">{t("humanResources.administration")}</option>
                            <option value="8">{t("humanResources.warehouse")}</option>
                            <option value="6">{t("humanResources.admin")}</option>
                            <option value="12">{t("humanResources.commercial")}</option>
                            <option value="7">Director</option>
                            <option value="5">{t("humanResources.finances")}</option>
                            <option value="11">IT</option>
                            <option value="3">{t("humanResources.operations")}</option>
                            <option value="9">{t("humanResources.humanResources")}</option>
                            <option value="1">{t("humanResources.service")}</option>
                            <option value="10">Site Planning</option>
                          </select>
                        </div>
                      ) : (<div className="col-4"><strong>{language === "en" ? "Contact to the admin to assign the profile to the employee" : "Contacte al administrador para que asigne el perfil al empleado"}</strong></div>)
                    }
                    {/* GERENTE / SUPERVISOR */}
                    <div className="col-7"><strong>Gerente / Supervisor</strong></div>
                    <div className="col-10">
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="gerente"
                        checked={stateInputs.coordinador}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, coordinador: target.checked })} />
                    </div>
                    <div className="col-4">
                      <input
                        style={{ marginLeft: '5%', width: '90%' }}
                        className="input-text-control"
                        type="text"
                        name="gerenteInput" />
                    </div>
                  </div>

                  {/* FILA 15 */}
                  <div className="row-flex">
                    <div className="col-7"><strong>Perfil Viático</strong></div>
                    <div className="col-4">
                      <select name="nivViatico" value={stateInputs.nivViatico} onChange={({ target }) => setStateInputs({ ...stateInputs, nivViatico: target.value })}>
                        <option value=""></option>
                        <option value="0">Empleado</option>
                        <option value="1">Supervisor</option>
                        <option value="2">Gerente</option>
                        <option value="3">Director</option>
                        <option value="4">Asistente Administrativo</option>
                        <option value="5">Pagos</option>
                      </select>
                    </div>
                  </div>

                  {/* DATOS DE LA EMPRESA */}
                  <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">Datos Empresa</h4>
                  <hr />
                  {/* FILA 16 */}
                  <div className="row-flex">
                    {/* EMPRESA */}
                    <div className="col-7"><strong>Empresa</strong><span className="required">(*)</span></div>
                    <div className="col-4">
                      <select name="CodEmpresa" value={stateInputs.codEmpresa} onChange={({ target }) => setStateInputs({ ...stateInputs, codEmpresa: target.value })}>
                        <option value=""></option>
                        {
                          company && company.map((comp) => (
                            <option key={comp.CodEmpresa} value={comp.CodEmpresa}>{comp.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                    {/* DIVISION */}
                    <div className="col-7"><strong>División</strong><span className="required">(*)</span></div>
                    <div className="col-4">
                      <select value={stateInputs.divisionCompany} onChange={({ target }) => setStateInputs({ ...stateInputs, divisionCompany: target.value })}>
                        <option value=""></option>
                        {
                          divisionCompany && divisionCompany.map((divi) => (
                            <option key={divi.CodGrupo} value={divi.CodGrupo}>{language === "en" ? divi.NombreEn : divi.Nombre}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* FILA 17 */}
                  <div className="row-flex">
                    {/* GERENCIA */}
                    <div className="col-7"><strong>Gerencia</strong></div>
                    <div className="col-4">
                      <select name="CodGerencia" value={stateInputs.codGerencia} onChange={({ target }) => setStateInputs({ ...stateInputs, codGerencia: target.value })}>
                        <option value=""></option>
                        {/* {
                    gerencia && gerencia.map((gere) => (
                      <option key={gere.CodGerencia} value={gere.CodGerencia}>{language === "es" ? gere.Nombre : gere.NombreEn}</option>
                    ))
                  } */}
                      </select>
                    </div>
                    {/* AREA FUNCIONAL */}
                    <div className="col-7"><strong>Area Funcional</strong></div>
                    <div className="col-4">
                      <select>
                        <option value=""></option>
                      </select>
                    </div>
                  </div>

                  {/* FILA 18 */}
                  <div className="row-flex">
                    {/* GRUPO OCUPACIONAL */}
                    <div className="col-7"><strong>Grupo Ocupacional</strong><span className="required">(*)</span></div>
                    <div className="col-4">
                      <select>
                        <option value=""></option>
                      </select>
                    </div>
                    {/* UNIDAD */}
                    <div className="col-7"><strong>Unidad</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="unidad"
                        type="text"
                        value={stateInputs.unidad}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, unidad: target.value })} />
                    </div>
                  </div>

                  {/* FILA 19 */}
                  <div className="row-flex">
                    {/* CARGO */}
                    <div className="col-7"><strong>Cargo</strong><span className="required">(*)</span></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="Cargo"
                        type="text"
                        value={stateInputs.cargo}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, cargo: target.value })} />
                    </div>
                    {/* SUPERVISOR */}
                    <div className="col-7"><strong>Supervisor</strong><span className="required">(*)</span></div>
                    <div className="col-4">
                      <select name="CodLider" value={stateInputs.codLider} onChange={({ target }) => setStateInputs({ ...stateInputs, codLider: target.value })}>
                        <option value=""></option>
                        {
                          supervisor && supervisor.map((emplo) => (
                            <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  {/* FILA 20 */}
                  <div className="row-flex">
                    {/* CORREO */}
                    <div className="col-7"><strong>Correo</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        name="Correo"
                        type="text"
                        value={stateInputs.correo}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, correo: target.value })} />
                    </div>
                    {/* FECHA INGRESO */}
                    <div className="col-7"><strong>Fecha Ingreso</strong></div>
                    <div className="col-4">
                      <input
                        className="input-date-styles"
                        type="date"
                        name="fecIngEmpresa"
                        value={stateInputs.fecIngEmpresa}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, fecIngEmpresa: target.value })} />
                    </div>
                  </div>

                  {/* FILA 21 */}
                  <div className="row-flex">
                    {/* CUENTA DE BANCO */}
                    <div className="col-7"><strong>Cuenta de Banco</strong></div>
                    <div className="col-4">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        type="text"
                        name="cuentaBanco"
                        value={stateInputs.numCuentaBan}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, numCuentaBan: target.value })} />
                    </div>
                    {/* GENERAR EVALUACION */}
                    <div className="col-7"><strong>General Evaluación Inicial</strong></div>
                  </div>

                  {/* FILA 22 */}
                  <div className="row-flex">
                    {/* ACTIVO */}
                    <div className="col-7"><strong>Activo</strong></div>
                    <div className="col-4">
                      <input
                        className="input-control-checkbox"
                        name="Activo"
                        type="checkbox"
                        checked={stateInputs.activo}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, activo: target.checked })} />
                    </div>
                    {/* LLEVAR VACACIONES */}
                    <div className="col-7"><strong>Llevar Vacaciones</strong></div>
                    <div className="col-4">
                      <input
                        className="input-control-checkbox"
                        name="Vacaciones"
                        type="checkbox"
                        checked={stateInputs.vacaciones}
                        onChange={({ target }) => setStateInputs({ ...stateInputs, vacaciones: target.checked })} />
                    </div>
                  </div>

                  {/* FILA 23 */}
                  <div style={{ marginTop: '1rem' }} className="row-flex">
                    {/* INGRESA POR */}
                    <div className="col-7"><strong>Ingresa Por</strong></div>
                    <div className="col-4"><span>{employeePermission && employeePermission[0]?.NomEmpleado}</span></div>
                    {/* MODIFICADO POR */}
                    <div className="col-7"><strong>Modificado Por</strong></div>
                    <div className="col-4"><span>{employeePermission && employeePermission[0]?.NomEmpleado}</span></div>
                  </div>

                  {/* FILA 24 */}
                  <div className="row-flex">
                    {/* FECHA INGRESO */}
                    <div className="col-7"><strong>Fecha Ingreso</strong></div>
                    <div className="col-4"><span>{renderDate(dateFormatted, language)}</span></div>
                    {/* FECHA MODIFICACION */}
                    <div className="col-7"><strong>Fecha Modificación</strong></div>
                    <div className="col-4"><span>{renderDate(dateFormatted, language)}</span></div>
                  </div>

                  {/* FILA 25 BOTONES */}
                  <div className="row-flex j-content-space-around m-y-small">
                    <button type="submit" className="btn-primary">Guardar</button>
                    <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Cancelar</button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};