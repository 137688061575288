// componente para crear nueva card

import { useEffect, useState } from "react";


// url imports
import { urls } from "../../api-urls/api-urls";


// components importation
import { ToastNotification } from "../toast-notification/ToastNotification";


// global function import
import { getTheToken } from "../../global-functions/globalFunctions";


// react router imports
import { useLocation, useNavigate } from "react-router-dom";



export const NewCardEmplo = ({ onClose, lang }) => {


  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();

  // state definition
  const [formTarje, setFormTarje] = useState({ numTarj: "", fecExpi: "", titu: "", activo: true, observa: "", nomBanc: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });


  // funcion para agregar los numeros de la tarjeta
  const onHandleAddFormmatCard = (newVal) => {
    const formattedVal = newVal.replace(/\D/g, "");
    const cardVal = formattedVal.replace(/(\d{4})/g, "$1-");

    setFormTarje((val) => ({ ...val, numTarj: cardVal.slice(0, 19) }));
  };



  // funcion para manejar el formulario de guardar una nueva tarjeta
  const onHandleNewCard = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}&action=1`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formTarje)
      });

      const data = await response.json();

      if (response.status === 201) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
        setFormTarje((val) => ({ ...val, numTarj: "", fecExpi: "", titu: "", observa: "", nomBanc: "" }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);

  return (
    <div className="background-modal" onClick={onClose}>
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="container-modal-add-card" onClick={(event) => event.stopPropagation()}>
        <h5 className="border-bottom">Nueva tarjeta</h5>
        <form className="m-y-small" onSubmit={onHandleNewCard}>
          <div className="row-flex">
            <div className="col-4"><strong>Número de tarjeta</strong></div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="numCard"
                value={formTarje.numTarj}
                onChange={({ target }) => onHandleAddFormmatCard(target.value)}
                autoComplete="off" />
            </div>
            <div className="col-5"><strong>Fecha expiración</strong></div>
            <div className="col-5">
              <input
                className="input-text-control"
                type="text"
                name="fecExpir"
                value={formTarje.fecExpi}
                onChange={({ target }) => setFormTarje((val) => ({ ...val, fecExpi: target.value }))} />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Titular</strong></div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="holder"
                value={formTarje.titu}
                onChange={({ target }) => setFormTarje((val) => ({ ...val, titu: target.value }))} />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Banco</strong></div>
            <div className="col-75">
              <input
                className="input-text-control"
                type="text"
                name="banc"
                value={formTarje.nomBanc}
                onChange={({ target }) => setFormTarje((val) => ({ ...val, nomBanc: target.value }))} />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Observación</strong></div>
            <div className="col-75">
              <textarea
                className="textarea-description"
                name="observ"
                value={formTarje.observa}
                onChange={({ target }) => setFormTarje((val) => ({ ...val, observa: target.value }))}
                autoComplete="off" />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-4"><strong>Activo</strong></div>
            <div className="col-7">
              <input
                className="input-control-checkbox"
                type="checkbox"
                name="active"
                checked={formTarje.activo}
                onChange={({ target }) => setFormTarje((val) => ({ ...val, activo: target.value }))} />
            </div>
          </div>
          <div className="row-flex m-y-small j-content-space-around">
            <button type="submit" className="btn-primary">Guardar</button>
            <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
          </div>
        </form>
      </div>
    </div>
  );
};