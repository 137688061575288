import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ModifyDataSupplier } from "../../components/data-supplier/ModifyDataSupplier";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// global functions import
import { getTheToken } from "../../global-functions/globalFunctions";

// react translation
import { useTranslation } from "react-i18next";




export const DataSupplierView = () => {

  // Language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // get the token
  const token = getTheToken().token;

  // GET the useLocation();
  const location = useLocation();

  // NAVIGATE
  const navigate = useNavigate();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, openModal: false, codProveedor: 0, supplier: [] });
  const [searchSupplier, setSearchSupplier] = useState("");

  // DESTRUCTURING DATA FROM THE SERVER
  const { suppliers } = dataServer && dataServer;

  // SUPPLIER FOUND
  const supplierFound = !searchSupplier ? suppliers : suppliers && suppliers.filter(supplier => supplier.Nombre.toLowerCase().includes(searchSupplier.toLowerCase()));


  // funcion para abrir el modal para modificar el suplidor
  const openModalModify = (codPro = 0) => {
    // buscar el suplidor
    const supplierFound = suppliers && suppliers.find((sup) => sup.CodProveedor === codPro);
    setState((val) => ({ ...val, openModal: !state.openModal, codProveedor: codPro, supplier: [supplierFound] }));
  };

  // useEffect
  useEffect(() => {
    // feth data to server
    const fetchDataToServer = async () => {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(urls.dataSupplier, {
        headers: { "Authorization": token }
      });

      const data = await response.json();

      if (response.status === 500) {
        // error en el servidor
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/"); // si el usuario no se encuentra logeado o su token expiró
      } else if (response.status === 200) {
        setDataServer(data);
      }

      setState((val) => ({ ...val, isLoading: false }));
    };

    fetchDataToServer();
  }, [token, navigate]);


  return (
    <>
      {
        // componente para modificar la data del suplidor
        state.openModal && (<ModifyDataSupplier detail={state.supplier} codPro={state.codProveedor} onClose={openModalModify} />)
      }
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {/* BUSCAR POR NOMBRE DEL PROVEEDOR */}
        <div className="row-flex">
          <div className="row-flex col-85-71">
            <div className="col-9"><strong>{t("LogisticViewComp.supplier")}</strong></div>
            <div className="col-4">
              <input
                className="input-text-control"
                type="text"
                placeholder={language === "es" ? "Buscar suplidor por nombre" : "Search supplier by name"}
                value={searchSupplier}
                onChange={({ target }) => setSearchSupplier(target.value)} />
            </div>
          </div>
          <div className="row-flex flex-end col-7">
            <button type="button" className="btn-success" onClick={() => navigate("/supplier-data/create-supplier")}>{t("LogisticViewComp.nuevo")}</button>
          </div>
        </div>

        {/* TABLA DE LOS PROVEEDORES */}
        <div className="table-container-mobile m-y-small">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>{t("LogisticViewComp.nombre")}</th>
                <th>{t("LogisticViewComp.direccion")}</th>
                <th>{t("LogisticViewComp.activo")}</th>
                <th>{t("LogisticViewComp.ingr_por")}</th>
                <th>{t("LogisticViewComp.modif_por")}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                supplierFound && supplierFound.map((supp) => (
                  <tr key={supp.CodProveedor}>
                    <td><Link className="link-tag" onClick={() => openModalModify(supp.CodProveedor)}>{supp.Nombre}</Link></td>
                    <td>{supp.Direccion}</td>
                    <td>{supp.Activo ? (<span style={{ color: 'var(--green-color)', fontWeight: 'bold' }}>{language === "es" ? "Activo" : "Active"}</span>) : (<span style={{ color: 'var(--danger-color)', fontWeight: 'bold' }}>{language === "es" ? "Inactivo" : "Inactive"}</span>)}</td>
                    <td>{supp.Ingreso}</td>
                    <td>{supp.Modificacion}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};