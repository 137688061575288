// Este componente es para mostrar el detalle de los anticipos

import React, { useEffect, useState } from "react";


// components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";

// React router importation
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Urls importation
import { urls } from "../../api-urls/api-urls";

// Global function importation
import { formatCurrency, getTheToken, HorasViaticos } from "../../global-functions/globalFunctions";


// react translation import
import { useTranslation } from "react-i18next";



export const AnticipoDetalleDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codSolG, codDet } = useParams();

  let MONTO = 0;


  // State definition
  const [dataServer, setDataServer] = useState([]); // Estado para traer la data inicial
  const [stateDetSolG, setStateDetSolG] = useState([]); // Estado para cargar el detalle de la solicitud luego que se reciba del backend
  const [stateTipGas, setStateTipGas] = useState([]); // estado para cargar los tipos de gastos
  const [controller, setController] = useState(null); // Estado para cargar el abortcontroller
  const [state, setState] = useState({ isLoading: false, dataVisible: false, serverError: false, notFound: false, monto: 0, showModal: false, questionAction: "", saving: false });
  const [stateForm, setStateForm] = useState({ fecSalida: "", fecRegreso: "", horaSali: "", horaRegr: "", origen: "", destino: "", cliente: "", concepto: "", subConcep: "", transporte: "", otrosGastos: "", justifi: "", zona: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });


  // Destructuring data server
  const { detSolGasto } = dataServer && dataServer;

  // Etapa elaboracion de anticipo
  const ELAB_ANTI = 1;

  // Id Monedas
  const ID_MONEDAS = {
    0: 'DOP',
    1: 'USD',
    2: 'EUR',
    3: 'TTD'
  };

  const MONEDA_ISO = ID_MONEDAS[stateDetSolG[0]?.IdMoneda] ?? 'DOP';



  // funcion para modificar el estado de los tipos de gastos
  const onHandleChangeVal = (newVal = "", tipG = 0) => {
    if (isNaN(newVal)) {
      return;
    }

    if (newVal === "") {
      newVal = 0;
    }

    setStateTipGas((val) => {
      return val.map((tipGas) => {
        if (tipG === tipGas.CodTipGasto) {
          return { ...tipGas, Monto: parseFloat(newVal) };
        }
        return tipGas;
      });
    });
  };


  // funcion para abrir el modal de guardar
  const onHandleSave = () => {

    setState((val) => ({ ...val, showModal: true, questionAction: t("Viaticos.seguro_guardar") }));
  };


  // funcion para enviar el formulario al servidor
  const onHandleSubForm = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.anticipo}/${codSolG}/${codDet}/search?language=${language}`;

    const finalObj = { stateForm, stateTipGas };

    try {
      setState((val) => ({ ...val, saving: true }));

      const response = await fetch(END_POINT, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
        setState((val) => ({ ...val, showModal: false }));
      }

      setState((val) => ({ ...val, saving: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // Funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.anticipo}/${codSolG}/${codDet}/search?language=${language}`;

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // refrescar la data
  useEffect(() => {
    if (notification.great) {

      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };

    }
  }, [notification.great]);


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if (detSolGasto && detSolGasto.length > 0) {
      setStateDetSolG(detSolGasto);


      const FecSalida = stateDetSolG[0]?.FecSalida ? stateDetSolG[0]?.FecSalida.slice(0, 10) : "";
      const FecRegreso = stateDetSolG[0]?.FecLlegada ? stateDetSolG[0]?.FecLlegada.slice(0, 10) : "";

      setStateTipGas(detSolGasto[0]?.tipGasto);

      setStateForm((val) => ({
        ...val, fecSalida: FecSalida, fecRegreso: FecRegreso, horaSali: stateDetSolG[0]?.HorSalida ?? "", horaRegr: stateDetSolG[0]?.HorLlegada ?? "", origen: stateDetSolG[0]?.CodCiuOrigen.toString() ?? "",
        destino: stateDetSolG[0]?.CodCiuDestino.toString() ?? "", cliente: stateDetSolG[0]?.Cliente ?? "", concepto: stateDetSolG[0]?.CodConcepto.toString() ?? "", subConcep: stateDetSolG[0]?.CodSubConcepto.toString() ?? "",
        transporte: stateDetSolG[0]?.IdTransporte ?? "", justifi: stateDetSolG[0]?.Justificacion ?? "", zona: stateDetSolG[0]?.CodZona.toString() ?? ""
      }));

    }
  }, [detSolGasto]);


  useEffect(() => {
    if (stateTipGas && stateTipGas.length > 0) {
      for (const tipG of stateTipGas) {
        MONTO += tipG.Monto;
        setState((val) => ({ ...val, monto: MONTO }));
      }
    }
  }, [stateTipGas]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);




  return (
    <>
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {/* Loading component */}
      {state.isLoading && (
        <div className="load-cont-transp">
          <div className="simple-spinner"><span></span></div>
          <p><strong>{language === "en" ? "Loading..." : "Cargando..."}</strong></p>
        </div>
      )}
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onHandleSubForm}>
              {/* FILA */}
              <div className="row-flex">
                {/* Nro. Control */}
                <div className="col-7">
                  <strong>Nro. Control</strong>
                </div>
                <div className="col-3">
                  <h4>{codSolG}</h4>
                </div>
                {/* Etapa */}
                <div className="col-7">
                  <strong>Etapa</strong>
                </div>
                <div className="col-3">
                  <strong className="active-class" style={{ textDecoration: 'underline' }}>{stateDetSolG[0]?.Etapa}</strong>
                </div>
              </div>
              {/* FILA */}
              <div className="row-flex">
                {/* Fecha salida */}
                <div className="col-7">
                  <strong>Fecha salida</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecSali"
                    value={stateForm.fecSalida}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecSalida: target.value }))} />
                </div>
                {/* Fecha de regreso */}
                <div className="col-7">
                  <strong>Fecha de regreso</strong></div>
                <div className="col-3 flex-grow">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecReturn"
                    value={stateForm.fecRegreso}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecRegreso: target.value }))} />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Hora de salida */}
                <div className="col-7">
                  <strong>Hora de salida</strong>
                </div>
                <div className="col-3">
                  <select
                    name="horSal"
                    value={stateForm.horaSali}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, horaSali: target.value }))}>
                    <option value=""></option>
                    {
                      HorasViaticos.map((horas) => (
                        <option key={horas} value={horas}>{horas}</option>
                      ))
                    }
                  </select>
                </div>
                {/* Hora de regreso */}
                <div className="col-7">
                  <strong>Hora de regreso</strong>
                </div>
                <div className="col-3 flex-grow">
                  <select
                    name="horReturn"
                    value={stateForm.horaRegr}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, horaRegr: target.value }))}>
                    <option value=""></option>
                    {
                      HorasViaticos.map((horas) => (
                        <option key={horas} value={horas}>{horas}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              <div className="row-flex">
                <div className="col-7">
                  <strong>Zona</strong>
                </div>
                <div className="col-3">
                  <select
                    name="zona"
                    value={stateForm.zona}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, zona: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG[0]?.zona.map((zo) => (
                        <option key={zo.CodZona} value={zo.CodZona}>{zo.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Origen */}
                <div className="col-7">
                  <strong>Origen</strong>
                </div>
                <div className="col-3">
                  <select
                    name="origin"
                    value={stateForm.origen}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, origen: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG && stateDetSolG[0]?.ciudad.filter((ci) => ci.CodZona.toString() === stateForm.zona).map((ciu) => (
                        <option key={ciu.Codigo} value={ciu.Codigo}>{ciu.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
                {/* Destino */}
                <div className="col-7">
                  <strong>Destino</strong>
                </div>
                <div className="col-3 flex-grow">
                  <select
                    name="destino"
                    value={stateForm.destino}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, destino: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG && stateDetSolG[0]?.ciudad.filter((ci) => ci.CodZona.toString() === stateForm.zona).map((ciu) => (
                        <option key={ciu.Codigo} value={ciu.Codigo}>{ciu.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Cliente */}
                <div className="col-7">
                  <strong>Cliente</strong>
                </div>
                <div className="col-80-94 flex-grow">
                  <input
                    className="input-text-control"
                    type="text"
                    name="customer"
                    value={stateForm.cliente}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, cliente: target.value }))} />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Concepto */}
                <div className="col-7">
                  <strong>Concepto</strong>
                </div>
                <div className="col-3">
                  <select
                    name="concept"
                    value={stateForm.concepto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, concepto: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG && stateDetSolG[0]?.concepto.map((con) => (
                        <option key={con.Codigo} value={con.Codigo}>{con.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
                {/* Sub concepto */}
                <div className="col-7">
                  <strong>Sub-concepto</strong>
                </div>
                <div className="col-3 flex-grow">
                  <select
                    name="subConcept"
                    value={stateForm.subConcep}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, subConcep: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG && stateDetSolG[0]?.subConcepto.map((sub) => (
                        <option key={sub.Codigo} value={sub.Codigo}>{sub.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Transporte */}
                <div className="col-7">
                  <strong>Transporte</strong>
                </div>
                <div className="col-3">
                  <select
                    name="transport"
                    value={stateForm.transporte}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, transporte: target.value }))}>
                    <option value=""></option>
                    {
                      stateDetSolG && stateDetSolG[0]?.transporte.map((trans) => (
                        <option key={trans.Codigo} value={trans.Codigo}>{trans.Descripcion}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Justificacion */}
                <div className="col-7">
                  <strong>Justificación</strong>
                </div>
                <div className="col-80-94 flex-grow">
                  <input
                    className="input-text-control"
                    type="text"
                    name="justifi"
                    value={stateForm.justifi}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, justifi: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              {/* Tabla para mostrar el detalle de los gastos */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Tipo de Gasto</th>
                      <th>{MONEDA_ISO}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      stateTipGas && stateTipGas.map((tip) => {

                        // MONTO += tip.Monto;

                        return (

                          <tr key={tip.CodTipGasto}>
                            <td className="col-6">{tip.Descripcion}</td>
                            <td className="col-6 txt-right" style={{ fontWeight: 'bold' }}>
                              <input
                                className="input-text-control txt-right"
                                type="text"
                                name={`gast-${tip.CodTipGasto}`}
                                disabled={stateDetSolG[0]?.IdEtapa > ELAB_ANTI}
                                value={tip.Monto}
                                onChange={({ target }) => onHandleChangeVal(target.value, tip.CodTipGasto)}
                                autoComplete="off" />
                              {/* {formatCurrency(tip.Monto, MONEDA_ISO)} */}
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td className="txt-right" style={{ fontWeight: 'bold', color: 'var(--money-color)' }}>
                        {formatCurrency(state.monto, MONEDA_ISO)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Botones de accion */}
              <div className="row-flex j-content-space-around m-y-medium">
                {
                  stateDetSolG[0]?.IdEtapa === ELAB_ANTI && (
                    <button type="button" className="btn-primary" onClick={onHandleSave}>Guardar</button>
                  )
                }
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success" disabled={state.saving}>{state.saving ? t("LoginView.cargando") : t("RequestPartsView.si")}</button>
                        <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }
      </div>
    </>
  );
};