// componente para buscar los empleados

import { useEffect, useState } from "react";

// urls importation
import { urls } from "../../api-urls/api-urls";

// react router dom importation
import { useSearchParams } from "react-router-dom";




export const SearchEmployees = ({ onClose, token, lang, setNewList, setMsg }) => {



  // GET the useSearchParams
  const [params, setParams] = useSearchParams();


  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null); // controlador para cancelar la data del servidor
  const [stateForm, setStateForm] = useState({ empresa: params.get("nom_empr") ?? "", diviEmpre: params.get("divi_empr") ?? "", gerencia: params.get("nom_geren") ?? "", areaFun: params.get("area_fun") ?? "", unidad: params.get("nom_unid") ?? "", grupo: params.get("nom_grup") ?? "", supervisor: params.get("nom_super") ?? "", perfil: params.get("nom_perf") ?? "", zona: params.get("nom_area") ?? "", division: params.get("divi_empl") ?? "", linea: params.get("nom_lin") ?? "", empleado: params.get("nom_empl") ?? "", activo: params.get("status") ?? "active" });
  const [state, setState] = useState({ isLoading: false });


  // destructuring data server
  const { company, diviEmpr, gerencia, areaFunc, grupOcupa, zona, perfil, division, linea } = dataServ && dataServ;



  // funcion para realizar la busqueda
  const onHandleSubmitForm = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.employees}?action=1&language=${lang}&page=1&pageSize=20&empresa=${params.get("nom_empr") ?? ""}&divi_empr=${params.get("divi_empr") ?? ""}&geren=${params.get("nom_geren") ?? ""}&area_func=${params.get("area_fun") ?? ""}&unidad=${params.get("nom_unid") ?? ""}&grupo=${params.get("nom_grup") ?? ""}&supervisor=${params.get("nom_super") ?? ""}&perfil=${params.get("nom_perf") ?? ""}&zona=${params.get("nom_area") ?? ""}&division=${params.get("divi_empl") ?? ""}&linea=${params.get("nom_lin") ?? ""}&nom_empl=${params.get("nom_empl") ?? ""}&status=${params.get("status") ?? "active"}`

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setNewList(data?.allEmployees, data?.Total);
        onClose();
      } else if (response.status === 404) {
        setNewList([], 0);
        setMsg(data?.msg);
        onClose();
      }

    } catch (error) {
      console.log(error);
    }
  };




  // funcion para cambiar los valores del form
  const onHandleChangeVal = (newVal = "", type = 0) => {
    switch (type) {
      case 1: // empresa
        params.set("nom_empr", newVal);
        setStateForm((val) => ({ ...val, empresa: newVal }));
        break;
      case 2: // division empresa
        params.set("divi_empr", newVal);
        setStateForm((val) => ({ ...val, diviEmpre: newVal }));
        break;
      case 3: // gerencia
        params.set("nom_geren", newVal);
        setStateForm((val) => ({ ...val, gerencia: newVal }));
        break;
      case 4: // area funcional
        params.set("area_fun", newVal);
        setStateForm((val) => ({ ...val, areaFun: newVal }));
        break;
      case 5: // unidad
        params.set("nom_unid", newVal);
        setStateForm((val) => ({ ...val, unidad: newVal }));
        break;
      case 6: // grupo
        params.set("nom_grup", newVal);
        setStateForm((val) => ({ ...val, grupo: newVal }));
        break;
      case 7: // supervisor
        params.set("nom_super", newVal);
        setStateForm((val) => ({ ...val, supervisor: newVal }));
        break;
      case 8: // perfil
        params.set("nom_perf", newVal);
        setStateForm((val) => ({ ...val, perfil: newVal }));
        break;
      case 9: // zona
        params.set("nom_area", newVal);
        setStateForm((val) => ({ ...val, zona: newVal }));
        break;
      case 10: // division
        params.set("divi_empl", newVal);
        setStateForm((val) => ({ ...val, division: newVal }));
        break;
      case 11: // linea
        params.set("nom_lin", newVal);
        setStateForm((val) => ({ ...val, linea: newVal }));
        break;
      case 12: // empleado
        params.set("nom_empl", newVal);
        setStateForm((val) => ({ ...val, empleado: newVal }));
        break;
      case 13: // activo
        params.set("status", newVal);
        setStateForm((val) => ({ ...val, activo: newVal }));
        break;
    }

    // setear los params
    setParams(params);
  };



  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.employees}?action=3&language`;

    setController(abortController);
    try {
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);




  return (
    <div className="background-modal">
      <form onSubmit={onHandleSubmitForm} className="container-select-customer container-filter-empl-h">
        <div className="modal-header">

        </div>
        <div className="modal-body">
          <div className="row-flex">
            <div className="col-8">
              <strong>Empresa</strong>
            </div>
            <div className="col-5">
              <select
                name="company"
                value={stateForm.empresa}
                onChange={({ target }) => onHandleChangeVal(target.value, 1)}>
                <option value=""></option>
                {
                  company && company.map((comp) => (
                    <option key={comp.CodEmpresa} value={comp.CodEmpresa}>{comp.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-8">
              <strong>División empresa</strong>
            </div>
            <div className="col-5">
              <select
                name="diviEmpr"
                value={stateForm.diviEmpre}
                onChange={({ target }) => onHandleChangeVal(target.value, 2)}>
                <option value=""></option>
                {
                  diviEmpr && diviEmpr.map((divi) => (
                    <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Descripcion}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-8">
              <strong>Gerencia</strong>
            </div>
            <div className="col-7 flex-grow">
              <select
                name="management"
                value={stateForm.gerencia}
                onChange={({ target }) => onHandleChangeVal(target.value, 3)}>
                <option value=""></option>
                {
                  gerencia && gerencia.filter((geFil) => geFil.CodGrupo.toString() === stateForm.diviEmpre).map((geren) => (
                    <option key={geren.CodGerencia} value={geren.CodGerencia}>{geren.Descripcion}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-8">
              <strong>Area funcional</strong>
            </div>
            <div className="col-5">
              <select
                name="areaFunc"
                value={stateForm.areaFun}
                onChange={({ target }) => onHandleChangeVal(target.value, 4)}>
                <option value=""></option>
                {
                  areaFunc && areaFunc.filter((areaFil) => areaFil.CodGerencia.toString() === stateForm.gerencia).map((areaFun) => (
                    <option key={areaFun.CodUnidad} value={areaFun.CodUnidad}>{areaFun.Descripcion}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-8">
              <strong>Unidad</strong>
            </div>
            <div className="col-5">
              <input
                className="input-text-control"
                type="text"
                name="unid"
                value={stateForm.unidad}
                onChange={({ target }) => onHandleChangeVal(target.value, 5)}
                autoComplete="off" />
            </div>
            <div className="col-8">
              <strong>Grupo</strong>
            </div>
            <div className="col-8 flex-grow">
              <select
                name="grupoOcu"
                value={stateForm.grupo}
                onChange={({ target }) => onHandleChangeVal(target.value, 6)}>
                <option value=""></option>
                {
                  grupOcupa && grupOcupa.map((grup) => (
                    <option key={grup.CodNivCargo} value={grup.CodNivCargo}>{grup.Descripcion}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-8">
              <strong>Supervisor</strong>
            </div>
            <div className="col-5">
              <input
                className="input-text-control"
                type="text"
                name="supervisor"
                value={stateForm.supervisor}
                onChange={({ target }) => onHandleChangeVal(target.value, 7)}
                autoComplete="off" />
            </div>
            <div className="col-8">
              <strong>Perfil</strong>
            </div>
            <div className="col-5">
              <select
                name="profile"
                value={stateForm.perfil}
                onChange={({ target }) => onHandleChangeVal(target.value, 8)}>
                <option value=""></option>
                {
                  perfil && perfil.map((per) => (
                    <option key={per.CodPerfil} value={per.CodPerfil}>{per.NomPerfil}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-8">
              <strong>Zona</strong>
            </div>
            <div className="col-5">
              <select
                name="area"
                value={stateForm.zona}
                onChange={({ target }) => onHandleChangeVal(target.value, 9)}>
                <option value=""></option>
                {
                  zona && zona.map((zon) => (
                    <option key={zon.CodZona} value={zon.CodZona}>{zon.NomZona}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-8">
              <strong>División</strong>
            </div>
            <div className="col-5">
              <select
                name="division"
                value={stateForm.division}
                onChange={({ target }) => onHandleChangeVal(target.value, 10)}>
                <option value=""></option>
                {
                  division && division.map((divi) => (
                    <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-8">
              <strong>Línea</strong>
            </div>
            <div className="col-8 flex-grow">
              <select
                name="linea"
                value={stateForm.linea}
                onChange={({ target }) => onHandleChangeVal(target.value, 11)}>
                <option value=""></option>
                {
                  linea && linea.filter((linFil) => linFil.CodDivision === stateForm.division).map((lin) => (
                    <option key={lin.CodLinea} value={lin.CodLinea}>{lin.Descripcion}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-8">
              <strong>Empleado</strong>
            </div>
            <div className="col-5">
              <input
                className="input-text-control"
                type="text"
                name="employee"
                value={stateForm.empleado}
                onChange={({ target }) => onHandleChangeVal(target.value, 12)}
                autoComplete="off" />
            </div>
            <div className="col-8">
              <strong>Estatus</strong>
            </div>
            <div className="col-5">
              <select
                name="status"
                value={stateForm.activo}
                onChange={({ target }) => onHandleChangeVal(target.value, 13)}>
                <option value=""></option>
                <option value="active">Activos</option>
                <option value="inactive">No activos</option>
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success">Buscar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};