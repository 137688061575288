import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { SearchQuotationComp } from "../../components/post-sales-component/SearchQuotationComp";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";


// REACT TRANSLATION
import { useTranslation } from "react-i18next";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// REACT ROUTER DOM IMPORTATION
import { Link, useNavigate, useSearchParams } from "react-router-dom";





export const QuotationViewPrincipal = () => {

  // LANGUAGE
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // NAVIGATE
  const navigate = useNavigate();

  // REACT TRANSLATION
  const [t] = useTranslation("global");

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [params, setParams] = useSearchParams(); // para los parametros de la url
  const [listQuot, setListQuot] = useState([]); // estado para colocar las cotizaciones
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, notFound: false, modalSearch: false, page: 1, total: 0, moreData: false });

  // DESTRUCTURING DATA SERVER
  const { employeePermission } = dataServer && dataServer;


  // actualizar las cotizaciones que vengan del hijo
  const updateListQuot = (list = [], pageSon = 0, totalSon = 0, more = false) => {
    setListQuot(list);
    setState((val) => ({ ...val, page: pageSon, total: totalSon, moreData: more }));
  };



  // funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.getListQuotations}?language=${language}&action=1&filtro=${params.get("filt") ?? "0"}&cliente=${params.get("cus") ?? ""}&cotizacion=${params.get("quot") ?? ""}&empresa=${params.get("comp") ?? ""}&zona=${params.get("area") ?? ""}&etapa=${params.get("ste") ?? ""}&division=${params.get("div") ?? ""}&linea=${params.get("lin") ?? ""}&idServ=${params.get("idSe") ?? ""}&numPart=${params.get("numPar") ?? ""}&tipo=${params.get("tip") ?? ""}&page=${state.page}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        // console.log(data)
        setListQuot([...listQuot].concat(data?.listQuotation));
        setState((val) => ({ ...val, dataVisible: true, moreData: data?.MoreData, page: data?.nextPage, total: data?.total }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        // ocurrio un error interno en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para setear los parametros en la url
  // useEffect(() => {
  //   initialParams();
  // }, []);




  return (
    <div className="container display-data">
      {/* Unauthorized component */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading components */}
      {state.isLoading && (<LoadingComp />)}
      <div className="row-flex m-y-small">
        <strong>{listQuot.length} de {state.total}</strong>
      </div>
      <ButtonSearch onOpenFilter={() => setState((val) => ({ ...val, modalSearch: true }))} />
      {
        // componente para filtrar
        state.modalSearch && (<SearchQuotationComp onClose={() => setState((val) => ({ ...val, modalSearch: false }))} urlsObj={urls} lang={language} token={getTheToken().token} updateState={updateListQuot} />)
      }

      {/* TABLA PARA MOSTRAR LAS COTIZACIONES */}
      {
        state.dataVisible && (
          <>
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>{t("PostSalesView.quotation")}</th>
                    <th>{t("PostSalesView.serviceId")}</th>
                    <th>{t("PostSalesView.type")}</th>
                    <th>{t("PostSalesView.date")}</th>
                    <th>{t("PostSalesView.step")}</th>
                    <th>{t("PostSalesView.customer")}</th>
                    <th>{t("PostSalesView.date")}</th>
                    <th>{t("PostSalesView.comment")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    listQuot && listQuot.map((quot, index) => (
                      <tr key={index}>
                        <td className="txt-center">
                          <Link className="link-tag" to={`/post-sales/quotation-detail/${quot.CodSolRepuesto}/${quot.Cotizacion === "S/N" ? "new" : quot.Cotizacion}`}>{quot.Cotizacion}</Link>
                        </td>
                        <td className="txt-center">
                          <Link className="link-tag" to={`/service/service-call/${quot.CodServicio}`}>{quot.CodServicio}</Link>
                        </td>
                        <td>{quot.tipCotizacion}</td>
                        <td>{renderDate(quot.FecCotizacion, language)}</td>
                        <td>{quot.NomEtapa}</td>
                        <td>
                          <Link className="link-tag" to={`/customers/customer-detail/${quot.CodCliente}`}>{quot.NomCliente}</Link>
                        </td>
                        <td>{renderDate(quot.FecCotizacion, language)}</td>
                        <td style={{ textTransform: 'capitalize' }} className="col-3">
                          <strong>{quot.NomEmpleado}:</strong> {quot.lastComment?.toLowerCase() ?? ""}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className="row-flex justify-center m-y-small">
              {
                !state.moreData && (
                  <button type="button" className="btn-primary" onClick={fetchDataServer}>Cargar más</button>
                )
              }
              {
                state.moreData && (<strong style={{ color: "var(--danger-color)" }}>No hay más datos que cargar</strong>)
              }
            </div>
          </>
        )
      }
    </div>
  );
};