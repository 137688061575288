// solicitud de consumo

import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";



import { useLocation, useNavigate } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";


// GET react translation
import { useTranslation } from "react-i18next";


export const CreateSolConsumoView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [filtGerencia, setFiltGerencia] = useState([]); // estado para filtrar la gerencia
  const [stateForm, setStateForm] = useState({ tarje: "", titular: "", fecVenc: "", division: "", gerencia: "", consuFinal: "", numItiner: "", monto: "", moneda: "", fecReque: "", concepto: "", empresa: "", tipGasto: "", fecFinHospe: "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "", modalSave: false, loadingData: false, questionAction: "", codSol: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // destructuring data server
  const { division, gerencia, cards, empresa } = dataServer && dataServer;


  // funcion para formatear los numeros
  const handleFormatCurrency = (newVal) => {

    const val = newVal.replace(/[^\d.]/g, ''); // eliminar caracteres no numericos excepto el .

    const parts = val.split(".");
    // formatear la parte entera del numero con comas
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    setStateForm((val) => ({ ...val, monto: parts.join(".") }));
  };


  // funcion para abrir el modal para confirmar
  const onHandleSaveModal = () => {
    if (!stateForm.tarje || !stateForm.division || !stateForm.gerencia || !stateForm.consuFinal.trim() || !stateForm.monto.trim() || !stateForm.moneda || !stateForm.fecReque || !stateForm.concepto.trim()) {
      return setNotification((val) => ({ ...val, show: true, msg: "Los campos obligatorios no pueden estar vacíos.", error: true }));
    }

    if (stateForm.tipGasto === "2" && !stateForm.fecFinHospe) {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la fecha de terminación del hospedaje.", error: true }));
    }

    setState((val) => ({ ...val, modalSave: true, questionAction: "Está seguro que desea crear esta solicitud?" }));
  };


  // funcion para manejar el envio del formulario al servidor
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loadingData: true }));
      const response = await fetch(`${urls.autorizaciones}?language=${language}&action=3`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setState((val) => ({ ...val, codSol: data?.codSol, modalSave: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: false }));
      }

      setState((val) => ({ ...val, loadingData: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para traer la data del servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.autorizaciones}?language=${language}&action=2`, {
        headers: {
          "Authorization": getTheToken().token,
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, msg: data?.msg, dataVisible: false }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para filtrar la data de la gerencia
  useEffect(() => {
    if (gerencia && gerencia.length > 0) {
      const filterGerenc = gerencia && gerencia.filter((ger) => ger.CodGrupo.toString() === stateForm.division);

      setFiltGerencia(filterGerenc);
    }
  }, [stateForm.division]);


  // useEffect para cambiar el estado cuando seleccionen una tarjeta
  useEffect(() => {
    if (cards && cards.length > 0) {
      const findCard = cards && cards.find((c) => c.CodId.toString() === stateForm.tarje);

      setStateForm((val) => ({ ...val, titular: findCard?.NomTitular, fecVenc: findCard?.FecExp }));
    }
  }, [stateForm.tarje]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);

  // useEffect para redireccionar cuando se cree la solicitud
  useEffect(() => {
    if (notification.success) {
      const timer = setTimeout(() => {
        navigate(`/administration/consumption-request-detail/${state.codSol}`);
      }, 3500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [notification.success]);



  return (
    <div className="container display-data">
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }

      {
        state.dataVisible && (
          <>
            <h3>Solicitud consumo de tarjeta</h3>

            <form className="m-y-small" onSubmit={onHandleSubmit}>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Tarjeta</strong><strong className="required">(*)</strong></div>
                <div className="col-3">
                  <select
                    name="card"
                    value={stateForm.tarje}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tarje: target.value }))}>
                    <option value=""></option>
                    {
                      cards && cards.map((c) => (
                        <option key={c.CodId} value={c.CodId}>{c.NumTarjeta}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-7"><strong>Titular</strong></div>
                <div className="col-3">
                  <span>{stateForm.titular ? `${stateForm.titular} / ${stateForm.fecVenc}` : null}</span>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Empresa</strong>
                  <strong className="required">(*)</strong>
                </div>
                <div className="col-3">
                  <select
                    name="company"
                    value={stateForm.empresa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                    <option value=""></option>
                    {
                      empresa && empresa.map((emp) => (
                        <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-7">
                  <strong>Tipo de gasto</strong>
                  <strong className="required">(*)</strong>
                </div>
                <div className="col-3">
                  <select
                    name="tipGasto"
                    value={stateForm.tipGasto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipGasto: target.value }))}>
                    <option value=""></option>
                    <option value="1">Compra</option>
                    <option value="2">Hospedaje</option>
                    <option value="3">Membresia</option>
                  </select>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Division empresa</strong><strong className="required">(*)</strong></div>
                <div className="col-3">
                  <select
                    name="diviEmpr"
                    value={stateForm.division}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                    <option value=""></option>
                    {
                      division && division.map((divi) => (
                        <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.NomGrupo}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-7"><strong>Gerencia</strong><strong className="required">(*)</strong></div>
                <div className="col-3">
                  <select
                    name="gerenc"
                    disabled={stateForm.division === ""}
                    value={stateForm.gerencia}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
                    <option value=""></option>
                    {
                      filtGerencia && filtGerencia.map((geren) => (
                        <option key={geren.CodGerencia} value={geren.CodGerencia}>{geren.NomGerencia}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Suplidor</strong><strong className="required">(*)</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="conFinal"
                    value={stateForm.consuFinal}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, consuFinal: target.value }))} />
                </div>
                {
                  stateForm.tipGasto === "2" && (
                    <>
                      <div className="col-7 fade-animation">
                        <strong>Fin del hospedaje</strong>
                        <strong className="required">(*)</strong>
                      </div>
                      <div className="col-3 fade-animation">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="fecFinHosp"
                          value={stateForm.fecFinHospe}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, fecFinHospe: target.value }))} />
                      </div>
                    </>
                  )
                }
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Número de itinerario</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="numItiner"
                    value={stateForm.numItiner}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, numItiner: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Monto</strong><strong className="required">(*)</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="amount"
                    value={stateForm.monto}
                    onChange={({ target }) => handleFormatCurrency(target.value)}
                    autoComplete="off" />
                </div>
                <div className="col-7">
                  <select
                    name="currency"
                    value={stateForm.moneda}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                    <option value="">--Seleccionar la moneda--</option>
                    <option value="1">RD$</option>
                    <option value="2">US$</option>
                    <option value="3">TT$</option>
                  </select>
                </div>
                <div className="col-7"><strong>Fecha requerida</strong></div>
                <div className="col-7">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecReque"
                    value={stateForm.fecReque}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecReque: target.value }))} />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Concepto</strong><strong className="required">(*)</strong></div>
                <div className="col-80-94">
                  <textarea
                    className="textarea-description"
                    name="concept"
                    value={stateForm.concepto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, concepto: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              {/* botones de accion */}
              <div className="row-flex j-content-space-around m-y-medium">
                <button type="button" className="btn-primary" onClick={onHandleSaveModal}>Guardar</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              {
                state.modalSave && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalSave: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success" disabled={state.loadingData}>
                          {state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                        </button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, modalSave: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          </>
        )
      }
    </div>
  );
};