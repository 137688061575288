// componente para mostrar los pendientes de operaciones


// react import
import { useEffect, useState } from "react";


// react router dom import
import { useNavigate, useSearchParams } from "react-router-dom";


// components importation
import { LoadingComp } from "../Loading-comp/LoadingComp";
import { ServiceStatistics } from "./ServiceStatistics";


// Global functions imports
import { getTheToken } from "../../global-functions/globalFunctions";

// url imports
import { urls } from "../../api-urls/api-urls";
import { useTranslation } from "react-i18next";




export const OperationProfile = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  // params definition
  const [params, setParams] = useSearchParams();


  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, zona: params.get("area") ?? "", grupo: params.get("grp") ?? "" });
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data que viene del server
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor


  // destructuring data server
  const { empPerm } = dataServ && dataServ;



  // funcion para cambiar la zona
  const onHandleChangeVal = (newVal = "", name = "", para = "") => {
    params.set("filt", 1);
    params.set(para, newVal);
    setParams(params);
    setState((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para solicitar la funcion al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.myTasks}?language=${language}&filtro=${params.get("filt") ?? "0"}&zona=${state.zona}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        return navigate(-1, { replace: true });
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, state.zona, state.grupo]);



  // useEffect para cargar la zona en el estado
  useEffect(() => {
    if (empPerm && empPerm.length > 0) {

      if (params.get("area") === null) {
        setState((val) => ({ ...val, zona: empPerm[0]?.CodZona }));
      }

      if (params.get("grp") === null) {
        setState((val) => ({ ...val, grupo: empPerm[0]?.CodGrupo ?? "" }));
      }

      if (empPerm[0]?.CodPerfil !== 3) {
        setState((val) => ({ ...val, dataVisible: false }));
        navigate("/home");
      }

    }

  }, [empPerm]);




  return (
    <div className="container display-data">

      {
        state.isLoading && (<LoadingComp />)
      }

      <h4 className="m-y-small">{t("serviceCard.hola")} {empPerm && empPerm[0]?.NomEmpleado.split(" ")[0]}{t("serviceCard.pendientes")}</h4>

      <div className="row-flex gap-medium d-col-rever">
        <ServiceStatistics area={state.zona} lang={language} token={getTheToken().token} grup={state.grupo} />

        {/* Card para los filtros */}
        <div className="card-shadow card-info-filter">
          <h5>Filtros</h5>
          <form>
            <div className="row-flex">
              <div className="col-3">
                <strong>Zona</strong>
              </div>
              <div className="col-61-58">
                <select
                  name="zona"
                  value={state.zona}
                  onChange={({ target }) => onHandleChangeVal(target.value, "zona", "area")}>
                  <option value=""></option>
                  <option value="1">República Dominicana</option>
                  <option value="6">Caribe</option>
                  <option value="7">Trinidad y Tobago</option>
                </select>
              </div>
            </div>
            <div className="row-flex">
              <div className="col-3">
                <strong>Grupo</strong>
              </div>
              <div className="col-61-58">
                <select
                  name="grp"
                  value={state.grupo}
                  onChange={({ target }) => onHandleChangeVal(target.value, "grupo", "grp")}>
                  <option value=""></option>
                  <option value="1">Servicio</option>
                  <option value="3">Ventas</option>
                </select>
              </div>
            </div>
          </form>
        </div>

      </div>

    </div>
  );
};