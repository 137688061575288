import React, { useEffect, useState } from "react";

// IMPORTATION OF REACT ROUTER DOM
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// GLOBAL FUNCTIONS
import { renderDate, renderTemperature } from "../../global-functions/globalFunctions";

// IMPORT THE TRANSLATION 
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";


export const OrdePendPorColocar = ({ lang, area, company, token }) => {

  // GET the translation
  const [t] = useTranslation("global");

  // GET the useSearchParams
  const [params] = useSearchParams();

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, msg: "", dataVisible: false });

  // destructuring dataServer
  const { orderPlaced } = dataServ && dataServ;


  // funcion para realizar la peticion al servidor
  const fetchDataLogistic = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true, dataVisible: false }));

      const response = await fetch(`${urls.logisticPrincipal}/orders/pending-placed?empresa=${company}&zona=${area}&language=${lang}&filt=${params.get("filt") ?? "0"}`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();
      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true, msg: "" }));
        setDataServ(data);
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataLogistic();

    return () => {
      if (controller) {
        controller.abort();
      }
    }

  }, [company, lang, area]);



  return (
    <>
      {
        state.msg && (
          <h5 style={{ color: 'var(--green-color)' }} className="m-l-small m-y-small">{state.msg} &#128561;</h5>
        )
      }

      {
        state.isLoading && (<h4 style={{ color: "var(--green-color)" }}>{t("PartModify.cargando_info")}</h4>)
      }

      {
        state.dataVisible && (
          <div className="table-container-mobile">
            <table className="table fade-animation">
              <thead className="table-head">
                <tr>
                  <th>#</th>
                  <th>Temp.</th>
                  <th>{t("LogisticView.order")}</th>
                  <th>{t("LogisticView.request")}</th>
                  <th>{t("LogisticView.date")}</th>
                  <th>{t("LogisticView.area")}</th>
                  <th>{t("LogisticView.customer")}</th>
                  <th>{t("LogisticView.equipment")}</th>
                  <th>{t("LogisticView.comment")}</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  orderPlaced && orderPlaced.map((orders) => (
                    <tr key={orders.CodIndex}>
                      <td>{orders.CodIndex}</td>
                      <td>
                        <span className={renderTemperature(orders.Prioridad)}></span>
                      </td>
                      <td>
                        <Link className="link-tag" to={`/order-detail/${orders.CodSolRepuesto}`}>{orders.Num}</Link>
                      </td>
                      <td>
                        <Link className="link-tag" to={`/request-part-detail/${orders.CodSolRepuesto}`}>{orders.CodSolRepuesto}</Link>
                      </td>
                      <td>{renderDate(orders.FecIngreso, lang)}</td>
                      <td style={{ textTransform: 'capitalize' }}>{orders.Zona}</td>
                      <td>
                        <Link className="link-tag" to={`/customers/customer-detail/${orders.CodCliente}`}>{orders.NomCliente}</Link>
                      </td>
                      <td>
                        <Link className="link-tag" to={`/equipments/equipment-detail/${orders.CodEquipo}`}>{orders.Equipo}</Link>
                      </td>
                      <td>
                        <div>
                          <strong style={{ paddingRight: '0.5rem' }}>({renderDate(orders.FecLastComment, lang)})</strong> <strong>{orders.NomEmpComment}:</strong>
                        </div>
                        {orders.Accion ?? ""}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )
      }
    </>
  );
};