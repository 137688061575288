
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions";

// USE TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";



export const SchedulingServiceView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useLocation
  const location = useLocation();

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the react translation
  const [t] = useTranslation("global");

  // STATE DEFINITION
  const [zona, setZona] = useState(localStorage.getItem("codZona") ?? ""); // para cambiar la zona
  const [dataServer, setDataServer] = useState([]);
  const [dataVisible, setDataVisible] = useState(false); // para ocultar / mostrar la data en funcion de la respuesta del servidor
  const [isLoading, setIsLoading] = useState(false); // para ocultar / mostrar el loading
  const [unauthorized, setUnAuthorized] = useState(false); // para mostrar / ocultar el componente de acceso restringido


  // DESTRUCTURING DATA SERVER
  const { employeesByArea, viaticosEnEspera, solRepuestoPend, datesArr } = dataServer && dataServer;

  const DR = 1; // Republica Dominicana
  const CAR = 6; // Caribe
  const TT = 7; // Trinidad y Tobago


  // CONST Labels
  const NO_SOL_REP_PEND = language === "en" ? "At the moment there are no request for spare parts pending." : "Por el momento no hay solicitudes de repuestos pendientes.";


  // Empleado por zona
  const DRZONA = employeesByArea && employeesByArea.filter((emp) => emp.CodZona === DR);
  const CARZONA = employeesByArea && employeesByArea.filter((emp) => emp.CodZona === CAR);
  const TTZONA = employeesByArea && employeesByArea.filter((emp) => emp.CodZona === TT);


  // Funcion para guardar la zona en el localStorage
  const onChangeZone = (newVal) => {
    localStorage.setItem("codZona", newVal);
    setZona(newVal);
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);

        const response = await fetch(`${urls.serviceScheduling}/search?language=${language}&zona=${zona}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setDataVisible(true);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          return setUnAuthorized(true);
        }

        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [language, zona]);


  return (
    <>
      {/* ACCESO RESTRINGIDO */}
      {unauthorized && (<Error403View />)}
      {/* LOADING COMPONENT */}
      {isLoading && (<LoadingComp />)}
      {
        dataVisible && (
          <div className="container display-data">
            {/* FILA 1 */}
            <div className="row-flex">
              {/* CAMBIAR EMPRESA */}
              <div className="col-8 col-sm-30"><strong>{t("SchedulingServiceView.changeArea")}</strong></div>
              <div className="col-4 col-sm-45">
                <select name="changeArea" value={zona} onChange={({ target }) => onChangeZone(target.value)}>
                  <option value=""></option>
                  <option value="1">{t("SchedulingServiceView.dr")}</option>
                  <option value="6">{t("SchedulingServiceView.car")}</option>
                  <option value="7">Trinidad & Tobago</option>
                </select>
              </div>
            </div>

            {/* FILA 2 */}
            <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">{t("SchedulingServiceView.weekSche")}</h4>

            {/* Tabla de la programacion semanal */}
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>{t("SchedulingServiceView.empl")}</th>
                    {
                      datesArr?.map((date) => (
                        <th key={date}>{renderDate(date, language)}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody className="table-body">
                  <tr style={{ backgroundColor: 'var(--second-color)' }}>
                    <td colSpan={11} style={{ textAlign: 'center' }}>
                      <strong style={{ textDecoration: 'underline' }}>{t("SchedulingServiceView.dr")}</strong>
                    </td>
                  </tr>
                  {
                    DRZONA?.map((emp) => (
                      <React.Fragment key={emp.CodEmpleado}>
                        <tr>
                          <td rowSpan={2}>
                            {emp.NomEmpleado}
                          </td>
                          {
                            datesArr?.map((date) => {
                              const pendingWorks1 = emp.detalle.filter((det) => det.Fecha.split("T")[0] === date);

                              return (
                                <td key={`${date}-${emp.CodEmpleado}`}>
                                  {
                                    pendingWorks1.map((pend) => (
                                      <p key={`${pend.CodServicio}-${date}-${pend.CodZona}-${emp.CodEmpleado}`}>
                                        <Link style={{ fontSize: '14px' }} className="link-tag" to={`/service/service-call/${pend.CodServicio}`}>
                                          {pend.Alias ? pend.Alias : pend.NomCliente} {pend.CodServicio ? (pend.CodServicio) : ""}
                                        </Link>
                                      </p>
                                    ))
                                  }
                                </td>
                              );
                            })
                          }
                        </tr>
                        <tr>
                          {
                            datesArr?.map((date) => {
                              const pendingWorks2 = emp.detalle2.filter((det) => det.Fecha.split("T")[0] === date);

                              return (
                                <td key={`${date}-${emp.CodEmpleado}`}>
                                  {
                                    pendingWorks2.map((pend) => (
                                      <p key={`${pend.CodServicio}-${date}-${pend.CodZona}-${emp.CodEmpleado}`}>
                                        <Link style={{ fontSize: '14px' }} className="link-tag" to={`/service/service-call/${pend.CodServicio}`}>
                                          {pend.Alias ? pend.Alias : pend.NomCliente} {pend.CodServicio ? (pend.CodServicio) : ""}
                                        </Link>
                                      </p>
                                    ))
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                      </React.Fragment>
                    ))
                  }
                  <tr style={{ backgroundColor: 'var(--second-color)' }}>
                    <td colSpan={11} style={{ textAlign: 'center' }}>
                      <strong style={{ textDecoration: 'underline' }}>{t("SchedulingServiceView.car")}</strong>
                    </td>
                  </tr>
                  {
                    CARZONA?.map((emp) => (
                      <React.Fragment key={emp.CodEmpleado}>
                        <tr>
                          <td rowSpan={2}>
                            {emp.NomEmpleado}
                          </td>
                          {
                            datesArr?.map((date) => {
                              const pendingWorks1 = emp.detalle.filter((det) => det.Fecha.split("T")[0] === date);

                              return (
                                <td key={`${date}-${emp.CodEmpleado}`}>
                                  {
                                    pendingWorks1.map((pend) => (
                                      <p key={`${pend.CodServicio}-${date}-${pend.CodZona}-${emp.CodEmpleado}`}>
                                        <Link style={{ fontSize: '14px' }} className="link-tag" to={`/service/service-call/${pend.CodServicio}`}>
                                          {pend.Alias ? pend.Alias : pend.NomCliente} {pend.CodServicio ? (pend.CodServicio) : ""}
                                        </Link>
                                      </p>
                                    ))
                                  }
                                </td>
                              );
                            })
                          }
                        </tr>
                        <tr>
                          {
                            datesArr?.map((date) => {
                              const pendingWorks2 = emp.detalle2.filter((det) => det.Fecha.split("T")[0] === date);

                              return (
                                <td key={`${date}-${emp.CodEmpleado}`}>
                                  {
                                    pendingWorks2.map((pend) => (
                                      <p key={`${pend.CodServicio}-${date}-${pend.CodZona}-${emp.CodEmpleado}`}>
                                        <Link style={{ fontSize: '14px' }} className="link-tag" to={`/service/service-call/${pend.CodServicio}`}>
                                          {pend.Alias ? pend.Alias : pend.NomCliente} {pend.CodServicio ? (pend.CodServicio) : ""}
                                        </Link>
                                      </p>
                                    ))
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                      </React.Fragment>
                    ))
                  }
                  <tr style={{ backgroundColor: 'var(--second-color)' }}>
                    <td colSpan={11} style={{ textAlign: 'center' }}>
                      <strong style={{ textDecoration: 'underline' }}>Trinidad y Tobago</strong>
                    </td>
                  </tr>
                  {
                    TTZONA?.map((emp) => (
                      <React.Fragment key={emp.CodEmpleado}>
                        <tr>
                          <td rowSpan={2}>
                            {emp.NomEmpleado}
                          </td>
                          {
                            datesArr?.map((date) => {
                              const pendingWorks1 = emp.detalle.filter((det) => det.Fecha.split("T")[0] === date);

                              return (
                                <td key={`${date}-${emp.CodEmpleado}`}>
                                  {
                                    pendingWorks1.map((pend) => (
                                      <p key={`${pend.CodServicio}-${date}-${pend.CodZona}-${emp.CodEmpleado}`}>
                                        <Link style={{ fontSize: '14px' }} className="link-tag" to={`/service/service-call/${pend.CodServicio}`}>
                                          {pend.Alias ? pend.Alias : pend.NomCliente} {pend.CodServicio ? (pend.CodServicio) : ""}
                                        </Link>
                                      </p>
                                    ))
                                  }
                                </td>
                              );
                            })
                          }
                        </tr>
                        <tr>
                          {
                            datesArr?.map((date) => {
                              const pendingWorks2 = emp.detalle2.filter((det) => det.Fecha.split("T")[0] === date);

                              return (
                                <td key={`${date}-${emp.CodEmpleado}`}>
                                  {
                                    pendingWorks2.map((pend) => (
                                      <p key={`${pend.CodServicio}-${date}-${pend.CodZona}-${emp.CodEmpleado}`}>
                                        <Link style={{ fontSize: '14px' }} className="link-tag" to={`/service/service-call/${pend.CodServicio}`}>
                                          {pend.Alias ? pend.Alias : pend.NomCliente} {pend.CodServicio ? (pend.CodServicio) : ""}
                                        </Link>
                                      </p>
                                    ))
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                      </React.Fragment>
                    ))
                  }
                </tbody>
              </table>
            </div>

            {/* Viaticos por espera de gastos de boletos */}
            <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Viáticos por espera de gastos de boletos</h4>

            {/* tabla para mostrar los viaticos pendientes */}
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>#</th>
                    <th>Anticipo</th>
                    <th>Especialista técnico</th>
                    <th>Cliente</th>
                    <th>Fecha de salida</th>
                    <th>Fecha de regreso</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    viaticosEnEspera && viaticosEnEspera.map((via) => (
                      <tr key={via.codeIndex}>
                        <td style={{ textAlign: 'center' }}>{via.codeIndex}</td>
                        <td><Link className="link-tag">{via.CodSolGasto}</Link></td>
                        <td>{via.NomEmpleado}</td>
                        <td>{via.Cliente}</td>
                        <td style={{ textAlign: 'center' }}>{renderDate(via.FecSalida, language)}</td>
                        <td style={{ textAlign: 'center' }}>{renderDate(via.FecLLegada, language)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            {/* Tabla de las solicitudes de repuestos pendientes */}
            <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Solicitudes de repuestos pendientes</h4>

            {
              solRepuestoPend && solRepuestoPend.length > 0 ? (
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Prioridad</th>
                        <th>Solicitud</th>
                        <th>Fecha</th>
                        <th>Zona</th>
                        <th>Cliente</th>
                        <th>Equipo</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        solRepuestoPend && solRepuestoPend.map((solR) => (
                          <tr key={solR.codeIndex}>
                            <td>{solR.codeIndex}</td>
                            <td></td>
                            <td>{solR.CodSolRepuesto}</td>
                            <td>{renderDate(solR.Fecha, language)}</td>
                            <td>{solR.Zona}</td>
                            <td>{solR.nomCliente}</td>
                            <td>{solR.nomEquip}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ) : (<div className="m-y-small"><strong style={{ color: 'var(--first-color)' }}>{NO_SOL_REP_PEND}</strong></div>)
            }


          </div>
        )
      }
    </>
  );
};