// vista para los reportes de solicitud de consumo

import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";


import { Link, useLocation, useNavigate } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";



export const ReportsConsumRequest = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data principal
  const [dataReport, setDataReport] = useState([]); // estado para cargar la data de los reportes
  const [filtGeren, setFiltGeren] = useState([]); // estado para filtrar la gerencia
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ division: "", gerencia: "", etapa: "4", factura: "", fecDesde: "", fecHasta: "", moneda: "" });
  const [state, setState] = useState({ isLoading: false, msg: "", unauthorized: false, dataVisible: false, loadingData: false });


  // destructuring data server
  const { division, gerencia } = dataServer && dataServer;





  // funcion para realizar el fetch al servidor primera informacion
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.autorizaciones}?language=${language}&action=5`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar el detalle de los reportes
  const fetchDataReports = async (event) => {
    event.preventDefault();

    const abortController = new AbortController();

    try {
      setState((val) => ({ ...val, loadingData: true }));
      const response = await fetch(`${urls.autorizaciones}?language=${language}&action=6&division=${stateForm.division}&gerencia=${stateForm.gerencia}&etapa=${stateForm.etapa}&fecDesde=${stateForm.fecDesde}&fecHasta=${stateForm.fecHasta}&factura=${stateForm.factura}&moneda=${stateForm.moneda}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      // console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setDataReport(data?.reports);
        setState((val) => ({ ...val, msg: "" }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setDataReport([]);
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, loadingData: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para imprimir el reporte
  const onHandlePrintReport = async () => {
    try {
      const response = await fetch(`${urls.autorizaciones}?language=${language}&action=7&division=${stateForm.division}&gerencia=${stateForm.gerencia}&etapa=${stateForm.etapa}&fecDesde=${stateForm.fecDesde}&fecHasta=${stateForm.fecHasta}&factura=${stateForm.factura}&moneda=${stateForm.moneda}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {

        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear enlace oculto
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobURL;
        a.download = "report.pdf";
        document.body.appendChild(a);

        a.click();

        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);

      } else {

        if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        }

      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para filtrar la gerencia
  useEffect(() => {
    if (gerencia && gerencia.length > 0) {
      const filtGerencias = gerencia && gerencia.filter((ger) => ger.CodGrupo.toString() === stateForm.division);

      setFiltGeren(filtGerencias);
    }
  }, [stateForm.division]);




  return (
    <div className="container-reports display-data">
      {
        state.isLoading && (<LoadingComp />)
      }
      <div className="row-flex">
        <div className="col-9">
          <button type="button" className="btn-primary" onClick={() => navigate(-1, { replace: true })}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
            </svg>
            Atrás
          </button>
        </div>
      </div>
      {/* manejar el envio del formulario */}
      <form className="m-y-small" onSubmit={fetchDataReports}>
        <div className="row-flex">
          <div className="col-10">
            <strong>Filtros:</strong>
          </div>
        </div>
        {/* fila */}
        <div className="row-flex">
          <div className="col-9">
            <strong>División empresa</strong>
          </div>
          <div className="col-7">
            <select
              name="diviEmp"
              value={stateForm.division}
              onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
              <option value=""></option>
              {
                division && division.map((div) => (
                  <option key={div.CodGrupo} value={div.CodGrupo}>{div.NomGrupo}</option>
                ))
              }
            </select>
          </div>
          <div className="col-9">
            <strong>Gerencia</strong>
          </div>
          <div className="col-8">
            <select
              name="geren"
              disabled={!stateForm.division}
              value={stateForm.gerencia}
              onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
              <option value=""></option>
              {
                filtGeren && filtGeren.map((gere) => (
                  <option key={gere.CodGerencia} value={gere.CodGerencia}>{gere.NomGerencia}</option>
                ))
              }
            </select>
          </div>
          <div className="col-10">
            <strong>Etapa</strong>
          </div>
          <div className="col-8">
            <select
              name="step"
              value={stateForm.etapa}
              onChange={({ target }) => setStateForm((val) => ({ ...val, etapa: target.value }))}>
              <option value=""></option>
              <option value="2">Aprobación de consumo</option>
              <option value="3">Pendiente de factura</option>
              <option value="4">Pendiente de cierre</option>
              <option value="5">Culminado</option>
            </select>
          </div>
          <div className="col-9">
            <strong>Factura cargada</strong>
          </div>
          <div className="col-10">
            <select
              name="invoice"
              value={stateForm.factura}
              onChange={({ target }) => setStateForm((val) => ({ ...val, factura: target.value }))}>
              <option value=""></option>
              <option value="1">Si</option>
              <option value="2">No</option>
            </select>
          </div>
        </div>
        {/* fila */}
        <div className="row-flex align-c">
          <div className="col-9"><strong>Desde</strong></div>
          <div className="col-7">
            <input
              className="input-date-styles"
              type="date"
              name="fecDesde"
              value={stateForm.fecDesde}
              onChange={({ target }) => setStateForm((val) => ({ ...val, fecDesde: target.value }))} />
          </div>
          <div className="col-9">
            <strong>Hasta</strong>
          </div>
          <div className="col-8">
            <input
              className="input-date-styles"
              type="date"
              name="fecDesde"
              value={stateForm.fecHasta}
              onChange={({ target }) => setStateForm((val) => ({ ...val, fecHasta: target.value }))} />
          </div>
          <div className="col-10">
            <strong>Moneda</strong>
          </div>
          <div className="col-8">
            <select
              name="currency"
              value={stateForm.moneda}
              onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
              <option value=""></option>
              <option value="1">RD$</option>
              <option value="2">US$</option>
              <option value="3">TT$</option>
            </select>
          </div>
          <div className="col-10">
            <button type="submit" className="btn-success">{state.loadingData ? "Buscando" : "Buscar"}</button>
          </div>
        </div>
      </form>

      {
        dataReport && dataReport.length > 0 && (
          <button type="button" className="btn-success" onClick={onHandlePrintReport}>Imprimir reporte</button>
        )
      }

      {
        state.msg && (
          <h4 style={{ color: "red" }}>{state.msg}</h4>
        )
      }

      {
        dataReport && dataReport.length > 0 && (
          <div className="table-container-mobile m-y-small">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>Fecha</th>
                  <th>Solicitud</th>
                  <th>Solicitante</th>
                  <th>Consumidor final</th>
                  <th>Monto</th>
                  <th>Autorizado por</th>
                  <th>Etapa</th>
                  <th>Fecha documento</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  dataReport && dataReport.map((rep) => (
                    <tr key={rep.CodSolicitud}>
                      <td className="txt-center">{renderDate(rep.FecIngreso, language)}</td>
                      <td className="txt-center">
                        <Link className="link-tag" to={`/administration/consumption-request-detail/${rep.CodSolicitud}`}>{rep.CodSolicitud}</Link>
                      </td>
                      <td className="txt-center">{rep.EmpSolicita}</td>
                      <td className="txt-center">{rep.ConsuFinal}</td>
                      <td className="txt-center">
                        <strong className="active-class">{formatCurrency(rep.MontoConsumo, "USD")}</strong>
                      </td>
                      <td className="txt-center">{rep.EmpAutoriza}</td>
                      <td className="txt-center">{rep.NomEtapa}</td>
                      <td>{renderDate(rep.FecDocumento, language)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )
      }
    </div>
  );
};