// este componente es para mostrar la lista de todos los servicios de aplicaciones

import { useState } from "react";

// react router import
import { useNavigate } from "react-router-dom";



export const ApplicationListView = () => {

  // GET the language
  const lang = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data del servidor

  return (
    <div className="container display-data">

      <div className="table-container-mobile">
        <table className="table">
          <thead className="table-head">
            <tr>
              <th>ID</th>
              <th>Fecha</th>
              <th>Equipo</th>
              <th>Cliente</th>
              <th>Problema</th>
              <th>Responsable</th>
              <th>Etapa</th>
              <th>Servicio</th>
              <th>Comentario</th>
            </tr>
          </thead>
          <tbody className="table-body">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};