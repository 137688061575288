import React, { useEffect, useState } from "react";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { PartDetailModify } from "../../components/logistic-components/PartDetailModify";
import { Error403View } from "../error-403-view/Error403View";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// FUNCTION GLOBAL
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// REACT TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";


export const SolPrecioView = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the location
  const location = useLocation();

  // NAVIGATE
  const navigate = useNavigate();

  // GET the useParams
  const { id } = useParams();

  // GET the react translation
  const [t] = useTranslation("global");


  // STATE DECLARATION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false, codSolRep: 0, codDetSolRep: 0, reload: false, update: false });
  const [questionAction, setQuestionAction] = useState("");
  const [stateForm, setStateForm] = useState({ idTipAprobacion: "", comentario: "", workFlow: "", action: "" });
  const [openModalPartDetail, setOpenModalPartDetail] = useState(false);
  const [detSolRep, setDetSolRep] = useState([]); // este estado es para cargar el detalle de las partes
  const [notification, setNotification] = useState({ show: false, msg: "", errorNot: false, great: false, success: false });


  // FUNCION PARA ABRIR EL MODAL Y ENVIARLE EL CodDetSolRepuesto
  const onOpenModal = (codSolRep, codDetSolRep) => {
    setOpenModalPartDetail(!openModalPartDetail);
    setState((val) => ({ ...val, codSolRep, codDetSolRep }));
  };


  // destructuring data server
  const { detSolRepuesto, solRepuesto, employeesPermission } = dataServer && dataServer;




  // handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();

    const finalObj = { stateForm, detSolRep };

    try {
      setState((val) => ({ ...val, update: true }));
      const response = await fetch(`${urls.priceRequest}/${id}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification({ show: true, msg: data?.msg, errorNot: false, great: true, success: true });
        setStateForm((val) => ({ ...val, comentario: "", action: "" }));
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }

  };


  // Funcion para cambiar el precio de lista
  const onHandleListPrice = (index, newVal) => {
    const updateListPrice = [...detSolRep];

    const Descuento = updateListPrice[index].Descuento;

    const Price = newVal * (1 - Descuento / 100)

    updateListPrice[index] = {
      ...updateListPrice[index],
      Lista: newVal,
      Precio: Price.toFixed(2)
    };

    setDetSolRep(updateListPrice);
  };


  // funcion para traer los nuevos datos del servidor
  const onReloadFromChild = (newVal) => {
    setState((val) => ({ ...val, reload: newVal }));
  };

  // const precioDescuento = lista - (lista * (updatePrecio[index].Descuento / 100));

  // Funcion para cambiar el descuento
  const onHandleDiscount = (index, newVal) => {
    const updateDiscount = [...detSolRep];

    const precioLista = parseFloat(updateDiscount[index].Lista);

    const precioDescuento = precioLista - ((precioLista * newVal) / 100);

    if (isNaN(newVal)) {
      return;
    }

    updateDiscount[index] = {
      ...updateDiscount[index],
      Descuento: newVal,
      Precio: parseFloat(precioDescuento).toFixed(2)
    };

    setDetSolRep(updateDiscount);
  };

  // Funcion para cambiar el Itbis
  const onHandleItbis = (index, newVal) => {
    const updateItbis = [...detSolRep];
    updateItbis[index].Itbis = parseFloat(newVal);

    setDetSolRep(updateItbis);
  };

  // Funcion para cambiar el devolver
  const onHandleReturn = (index, newVal) => {
    const updateReturn = [...detSolRep];
    updateReturn[index].Devolver = newVal;

    setDetSolRep(updateReturn);
  };

  // Funcion para cambiar el disponible
  const onHandleAvailability = (index, newVal) => {
    const updateAvaila = [...detSolRep];
    updateAvaila[index].Disponible = newVal;

    setDetSolRep(updateAvaila);
  };

  // Funcion para cambiar los comentario de las partes
  const onHandleBackOrder = (index, newVal) => {
    const updateBackOrder = [...detSolRep];
    updateBackOrder[index].BackOrder = newVal;

    setDetSolRep(updateBackOrder);
  };



  // Funcion para abrir el modal cuando se clickee el boton de aprobar
  const onHandleApprove = () => {
    setState((val) => ({ ...val, showModal: true }));
    setQuestionAction(t("RequestPartsView.enviar_a_cotiza"));
    setStateForm((val) => ({ ...val, action: "1" }));
  };


  // Funcion para abrir el modal cuando se clikee el boton de rechazar
  const onHandleReject = () => {
    setState((val) => ({ ...val, showModal: true }));
    setQuestionAction(t("RequestPartsView.enviar_a_apro_admin"));
    setStateForm((val) => ({ ...val, action: "2" }));
  };


  // Funcion para abrir el modal cuando se clikee el boton de comentar
  const onHandleComment = () => {
    setState((val) => ({ ...val, showModal: true }));
    setQuestionAction(t("RequestPartsView.accion_comment"));
    setStateForm((val) => ({ ...val, action: "3" }));
  };


  // console.log(window.screen.width)


  // Funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.priceRequest}/${id}/search?language=${language}`, {
        headers: { "Authorization": getTheToken().token }
      });

      const data = await response.json();

      // console.clear();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 404) {
        // 
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };


  // useEffect DECLARATION
  useEffect(() => {

    fetchDataServer();

    document.title = language === "es" ? "Detalle de la Solicitud" : "Request Detail";

  }, [navigate, id, language]);


  // useEffect para cargar las partes
  useEffect(() => {

    if (detSolRepuesto && detSolRepuesto.length > 0) {
      setDetSolRep(detSolRepuesto);
    }

  }, [detSolRepuesto]);

  // useEffect para cargar el tipo de aprobacion
  useEffect(() => {
    if (solRepuesto && solRepuesto.length > 0) {
      setStateForm((val) => ({ ...val, idTipAprobacion: solRepuesto[0]?.IdTipAprobacion }));
    }
  }, [solRepuesto]);


  // useEffect para cargar el workflow
  useEffect(() => {
    if (detSolRepuesto && detSolRepuesto.length > 0) {
      setStateForm((val) => ({ ...val, workFlow: detSolRepuesto[0]?.WorkFlow ?? "" }));
    }
  }, [detSolRepuesto]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNot: false, great: true, success: false });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useEffect para redirigir a principal de logistica
  useEffect(() => {
    if (notification.success) {

      const timer = setTimeout(() => {
        navigate("/logistic-principal");
      }, 6300);

      return () => {
        clearTimeout(timer);
      }

    }
  }, [notification.success]);


  // useEffect para solicitar la nueva data del servidor
  useEffect(() => {
    if (state.reload) {
      fetchDataServer();

      return () => {
        setState((val) => ({ ...val, reload: false }));
      };
    }
  }, [state.reload]);

  // console.log(detSolRep)


  return (
    <>
      {/* Error 403 component */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {/* Notification component */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNot} msg={notification.msg} />)}
      {
        openModalPartDetail && (<PartDetailModify
          employeePerm={employeesPermission}
          solRepuesto={state.codSolRep}
          onReload={onReloadFromChild}
          codDetSolRepuesto={state.codDetSolRep}
          onClose={onOpenModal} />)
      }
      {
        state.dataVisible && (
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div style={{ boxShadow: 'var(--box-shadow-bottom)' }} className="p-1 b-radius">
                {/* PRIMERA FILA, solicitud, id servicio, modelo del equipo, serial */}
                <div className="row-flex">
                  {/* SOLICITUD */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.soli")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span style={{ fontWeight: 'bold', color: 'var(--first-color)' }}>{id}</span>
                  </div>
                  {/* id.Servicio */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.id_serv")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>
                      <Link className="link-tag" to={`/service/service-call/${solRepuesto && solRepuesto[0]?.CodServicio}`}>{solRepuesto && solRepuesto[0]?.CodServicio}</Link>
                    </span>
                  </div>
                  {/* MODELO */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.modelo")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{solRepuesto && solRepuesto[0]?.Modelo}</span>
                  </div>
                </div>

                {/* SEGUNDA FILA */}
                <div className="row-flex">
                  {/* FECHA */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.fecha")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>{solRepuesto && renderDate(solRepuesto[0]?.FecIngreso, language)}</span>
                  </div>
                  {/* SERIAL */}
                  <div className="col-10 col-sm-30">
                    <strong>Serial</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>{solRepuesto && solRepuesto[0]?.Serial}</span>
                  </div>
                  {/* FDO */}
                  <div className="col-10 col-sm-30">
                    <strong>FDO</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{solRepuesto && solRepuesto[0]?.FDO}</span>
                  </div>
                </div>

                {/* TERCERA FILA */}
                <div className="row-flex">
                  {/* CLIENTE */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.cliente")}</strong>
                  </div>
                  <div className="col-58 col-sm-70-mod">
                    <Link className="link-tag" to={`/customers/customer-detail/${solRepuesto && solRepuesto[0]?.CodCliente}`}>{solRepuesto && solRepuesto[0]?.NomCliente}</Link>
                  </div>

                  {/* O/C */}
                  <div className="col-10 col-sm-30">
                    <strong>O/C</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{solRepuesto && solRepuesto[0]?.NumOrden}</span>
                  </div>
                </div>

                {/* CUARTA FILA */}
                <div className="row-flex">
                  {/* EQUIPO */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.equip")}</strong>
                  </div>
                  <div className="col-58 col-sm-70-mod">
                    <Link className="link-tag" to={`/equipments/equipment-detail/${solRepuesto && solRepuesto[0]?.CodEquipo}`}>{solRepuesto && solRepuesto[0]?.NomEquipo}</Link>
                  </div>
                  {/* CONTRATO */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.contr")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod"><span></span></div>
                  {/* PRIORIDAD */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.prio")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod"><span></span></div>
                </div>

                {/* QUINTA FILA */}
                <div className="row-flex">
                  {/* TECNICO */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.ing_tec")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>{solRepuesto && solRepuesto[0]?.NomEmpIngreso}</span>
                  </div>
                  {/* FALLA */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.falla")}</strong>
                  </div>
                  <div className="flex-auto col-sm-70-mod">
                    <span style={{ color: 'var(--danger-color)', textDecoration: 'underline' }}>{solRepuesto && solRepuesto[0]?.Falla}</span>
                  </div>
                </div>

                {/* ULTIMA FILA */}
                <div className="row-flex">
                  {/* ULTIMA ACCION */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.ult_accion")}</strong>
                  </div>
                  <div className="flex-auto col-sm-70-mod">
                    <span style={{ textTransform: 'capitalize', color: 'green', textDecoration: 'underline' }}>{solRepuesto && solRepuesto[0]?.LastComment}</span>
                  </div>
                </div>
              </div>

              {/* TIPO DE APROBACION */}
              <div className="row-flex m-y-small">
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.tip_aproba")}</strong>
                </div>
                <div className="col-4 col-sm-70-mod">
                  <select
                    name="IdTipAprobacion"
                    value={stateForm.idTipAprobacion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, idTipAprobacion: target.value }))}>
                    <option value=""></option>
                    <option value="0">Stock</option>
                    <option value="3">{t("RequestPartsView.designado")}</option>
                    <option value="6">{t("RequestPartsView.garan_ventas")}</option>
                    <option value="9">{t("RequestPartsView.export")}</option>
                    <option value="1">{t("RequestPartsView.activo_fij")}</option>
                    <option value="4">FMI/STB</option>
                    <option value="7">{t("RequestPartsView.garan_sopor")}</option>
                    <option value="10">{t("RequestPartsView.compr_local")}</option>
                    <option value="2">{t("RequestPartsView.contr")}</option>
                    <option value="5">Missing</option>
                    <option value="8">{t("RequestPartsView.gasto")}</option>
                  </select>
                </div>
              </div>


              {/* COMENTARIO */}
              <div className="m-y-small row-flex">
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.comentario")}</strong>
                </div>
                <div className="col-88-89 col-sm-70-mod">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={stateForm.comentario}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))} />
                </div>
              </div>
              {/* WORKFLOW */}
              <div className="row-flex">
                <div className="col-9 col-sm-30">
                  <strong>Workflow</strong>
                </div>
                <div className="col-4 col-sm-70-mod">
                  <input
                    className="input-text-control"
                    type="text"
                    name="workflow"
                    value={stateForm.workFlow}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, workFlow: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              <hr className="m-y-small" />

              {/* TABLA PARA COLOCAR LAS PARTES */}
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.precio_lista")}</th>
                      <th>{t("TableParts.descuento")}</th>
                      <th>{solRepuesto && solRepuesto[0]?.CodDYN !== "GLOBL" ? "VAT" : "ITBIS"}</th>
                      <th>{t("TableParts.precio")}</th>
                      <th>{t("TableParts.devolver")}</th>
                      <th>{t("TableParts.dispo")}</th>
                      <th>{t("TableParts.coment")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      detSolRep && detSolRep.map((partes, index) => (
                        <tr key={partes.CodDetSolRepuesto}>
                          <td className="txt-center" style={{ width: '1rem' }}>
                            <button type="button" className="btn-link-tag" onClick={() => onOpenModal(partes.CodSolRepuesto, partes.CodDetSolRepuesto)}>
                              {partes.CodDetSolRepuesto}
                            </button>
                          </td>
                          <td className="txt-center">{partes.Cantidad}</td>
                          <td className="col-7">
                            <Link className="link-tag" to={`/part-detail/${partes.CodDetSolRepuesto}/${partes.CodSolRepuesto}`}>{partes.NumParte} {partes.Alternativo ? (partes.Alternativo) : ""}</Link>
                          </td>
                          <td className="col-3">{partes.Descripcion}</td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control"
                              type="text"
                              name={`precioLista-${partes.CodDetSolRepuesto}`}
                              value={partes.Lista}
                              onChange={({ target }) => onHandleListPrice(index, target.value)}
                              autoComplete="off" />
                          </td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control"
                              type="text"
                              name={`descuento-${partes.CodDetSolRepuesto}`}
                              value={partes.Descuento}
                              onChange={({ target }) => onHandleDiscount(index, target.value)}
                              autoComplete="off" />
                          </td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control"
                              type="text"
                              name={`itbis-${partes.CodDetSolRepuesto}`}
                              value={partes.Itbis}
                              onChange={({ target }) => onHandleItbis(index, target.value)} />
                          </td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control input-width-sol-price"
                              type="text"
                              name={`precCal-${partes.CodDetSolRepuesto}`}
                              value={partes.Precio}
                              readOnly />
                          </td>
                          <td className="input-select-sol-price">
                            <select name={`devolver-${partes.CodDetSolRepuesto}`} value={partes.Devolver} onChange={({ target }) => onHandleReturn(index, target.value)}>
                              <option value="1">{t("RequestPartsView.si")}</option>
                              <option value="0">No</option>
                            </select>
                          </td>
                          <td className="input-select-sol-price">
                            <select name={`disponible-${partes.CodDetSolRepuesto}`} value={partes.Disponible} onChange={({ target }) => onHandleAvailability(index, target.value)}>
                              <option value="1">{t("RequestPartsView.si")}</option>
                              <option value="0">No</option>
                            </select>
                          </td>
                          <td className="col-8">
                            <input
                              className="input-text-control"
                              type="text"
                              name={`comentario-${partes.CodDetSolRepuesto}`}
                              value={partes.BackOrder}
                              onChange={({ target }) => onHandleBackOrder(index, target.value)}
                              placeholder={language === "en" ? "Part's comments..." : "Comentarios de las partes..."}
                              autoComplete="off" />
                          </td>
                        </tr>
                      )
                      )
                    }
                  </tbody>
                </table>
              </div>

              {/* BOTONOS DE ACCION */}
              <div className="row-flex j-content-space-around m-y-medium">
                {/* BTN APROBAR */}
                <button type="button" className="btn-primary" onClick={onHandleApprove}>{t("TableParts.aprobar")}</button>
                {/* BTN RECHAZAR */}
                <button type="button" className="btn-warning" onClick={onHandleReject}>{t("serviceCard.rechazar")}</button>
                {/* BTN COMENTAR */}
                <button type="button" className="btn-success" onClick={onHandleComment}>{t("RequestPartsView.comentario")}</button>
                {/* BTN CANCELAR */}
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModal: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type={state.update ? "button" : "submit"} className={`btn-success ${state.update && "disabled"}`} disabled={state.update}>{t("RequestPartsView.si")}</button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }

            </form>

          </div>
        )
      }
    </>
  );
};