// componente para mostrar la lista de los equipos en cliente

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";
import { renderDate } from "../../global-functions/globalFunctions";
import { Link } from "react-router-dom";
import { PDFIcon } from "../icons/AppIcons";





export const EquipmentsList = ({ idCus, lang, token, action }) => {

  // GET the react translation
  const [t] = useTranslation("global");


  // state definition
  const [listEqup, setListEqup] = useState([]); // array para colocar la lista de los equipos
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false });



  // funcion para solicitar los datos al servidor
  const fetchDataServ = async () => {

    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.getCustomer}/equipments/list/customer/${idCus}/search?language=${lang}&action=${action}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(END_POINT, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setListEqup(data?.equipments);
      } else if (response.status === 404) {

      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    }
  };


  // useEffect para solicitar la informacion al servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idCus, lang]);


  return (
    <div className="m-y-small">
      {
        action === "1" && (
          <>
            <h3 style={{ color: 'var(--first-color)' }}>{t("equipmentView.equipments")}</h3>
            <hr className="m-y-small" />
          </>
        )
      }

      {
        state.isLoading && (<h4>{t("PartModify.cargando_info")}</h4>)
      }

      {
        (listEqup && listEqup.length > 0) && (
          <div className="table-container-mobile">
            <table className="table">
              <thead className="table-head">
                {
                  action === "1" && ( // tabla para informacion con los contratos
                    <tr>
                      <th>System ID</th>
                      <th>{t("equipmentView.model")}</th>
                      <th>{t("equipmentView.warranty")}</th>
                      <th>End of Guarantee Life (EOGL)</th>
                      <th>{t("equipmentView.contract")}</th>
                    </tr>
                  )
                }
                {
                  action === "2" && (
                    <tr>
                      <th>{t("navigationMenu.division")}</th>
                      <th>{t("navigationMenu.line")}</th>
                      <th>{t("navigationMenu.modality")}</th>
                      <th>{t("customerView.tipo")}</th>
                      <th>{t("navigationMenu.model")}</th>
                      <th>SID</th>
                    </tr>
                  )
                }
              </thead>
              <tbody className="table-body">
                {
                  // para mostrar los equipos con la informacion del contrato
                  (listEqup && action === "1") && listEqup.map((equip) => (
                    <tr key={equip.CodEquipo}>
                      <td>
                        <Link className="link-tag" to={`/equipments/equipment-detail/${equip.CodEquipo}`}>
                          {equip.NumSistema || "SN"}
                        </Link>
                      </td>
                      <td>
                        <p style={{ padding: 0, margin: 0 }}><strong>{t("RequestPartsView.linea")}: </strong><span className="span">{equip.Linea}</span></p>
                        <p style={{ padding: 0, margin: 0 }}><strong>{t("RequestPartsView.modalidad")}: </strong><span className="span">{equip.Modalidad}</span></p>
                        <p style={{ padding: 0, margin: 0 }}><strong>{t("navigationMenu.equipType")}: </strong><span className="span">{equip.TipEquipo}</span></p>
                        <p style={{ padding: 0, margin: 0 }}><strong>{t("serviceCard.modelo")}: </strong><span className="span">{equip.Modelo}</span></p>
                        <p style={{ padding: 0, margin: 0 }}><strong>Serial: </strong><span className="span">{equip.Serial}</span></p>
                      </td>
                      <td className="txt-center" style={{ color: equip.ColorGaran }}>{renderDate(equip.FecGarantia, lang)}</td>
                      <td className="txt-center">
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                          {renderDate(equip.FecEOL, lang)}
                          {
                            equip.EOL && (
                              <div style={{ display: "flex", justifyContent: "center" }}>
                                <button className="btn-pdf">
                                  <PDFIcon />
                                </button>
                              </div>
                            )
                          }
                        </div>
                      </td>
                      <td>
                        {
                          equip.NumContrato && (
                            <>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.nro_cont")}: </strong><span className="span">{equip.NomContract} GLOBAL {equip.NumContrato}</span>
                              </p>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.duracion")}: </strong><span className="span">{renderDate(equip.FecInicio, lang)} al {renderDate(equip.FecFin, lang)}</span>
                              </p>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.frecuencia")}: </strong><span className="span">{equip.Frecuencia}</span>
                              </p>
                            </>
                          )
                        }
                      </td>
                    </tr>
                  ))
                }
                {
                  (listEqup && action === "2") && listEqup.map((equip) => (
                    <tr key={equip.CodEquipo}>
                      <td>{equip.Division}</td>
                      <td>{equip.Linea}</td>
                      <td>{equip.Modalidad}</td>
                      <td>{equip.TipEquipo}</td>
                      <td>{equip.Modelo}</td>
                      <td>
                        <Link className="link-tag" to={`/equipments/equipment-detail/${equip.CodEquipo}`}>{equip.NumSistema}</Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )

        // (<div className="m-y-small"><strong style={{ color: 'var(--first-color)' }}>{NO_EQUIPMENT}</strong></div>)
      }
    </div>
  );
};