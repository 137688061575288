// componente para agregar los componentes 
import { ToastNotification } from "../toast-notification/ToastNotification";

import { useEffect, useState } from "react";
import { urls } from "../../api-urls/api-urls";
import { useNavigate } from "react-router-dom";

// react translation importation
import { useTranslation } from "react-i18next";






export const AddComponent = ({ onClose, lang, codEq, token }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateForm, setStateForm] = useState({ nroParte: "", nombre: "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, msg: "", searching: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });




  // funcion para agregar el componente
  const onHandleAddComp = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.getDataCA}?language=${lang}&action=2&codEquip=${codEq}`, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {

      }
      // console.log("Partes agregadas ", stateForm);

    } catch (error) {
      console.log(error);
    }
  };




  // funcion para buscar la parte al servidor
  const onSearchPart = async () => {
    const abortController = new AbortController();

    try {
      setState((val) => ({ ...val, searching: true }));

      const response = await fetch(`${urls.getDataCA}?action=3&language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal,
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 200) {
        setStateForm((val) => ({ ...val, nombre: data?.comp }));
      } else if (response.status === 404) {

      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // realizar el fetch de los datos
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.getDataCA}?language=${lang}&codEquip=${codEq}&action=1`, {
        method: "POST",
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      console.clear();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, msg: data?.msg }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para llamar la funcion para solicitar la data al servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  return (
    <div className="background-modal">
      {
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <form onSubmit={onHandleAddComp} className="container-select-component">
        <div className="modal-header">
          <h3>Agregar componente</h3>
          <button type="reset" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          {
            state.searching && (
              <h5 className="txt-center">{t("LoginView.cargando")}</h5>
            )
          }
          {
            state.isLoading && (
              <h5 className="txt-center">{t("LoginView.cargando")}</h5>
            )
          }
          {
            !state.dataVisible && state.msg.trim() !== "" && (
              <h5>{state.msg}</h5>
            )
          }
          {
            state.dataVisible && (
              <>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Nro. Parte</strong>
                  </div>
                  <div className="col-82-16">
                    <input
                      className="input-text-control"
                      type="text"
                      name="numPart"
                      value={stateForm.nroParte}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, nroParte: target.value }))}
                      onBlur={onSearchPart} />
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Nombre</strong>
                  </div>
                  <div className="col-82-16">
                    <input
                      className="input-text-control"
                      type="text"
                      name="namePart"
                      value={stateForm.nombre}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, nombre: target.value }))} />
                  </div>
                </div>
              </>
            )
          }
        </div>
        <div className="modal-footer j-content-space-around">
          {
            state.dataVisible && (
              <>
                <button type="submit" className="btn-primary">Guardar</button>
                <button type="reset" className="btn-secondary">Cerrar</button>
              </>
            )
          }
        </div>
      </form>
    </div>
  );
};