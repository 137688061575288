import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { TopNavbarMobile } from "../../components/top-searchbar-mobile/TopNavbarMobile";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// REACT ROUTER IMPORTATION
import { Link, useNavigate } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";


export const PropuestasPostVenta = () => {

  // get the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // NAVIGATE
  const navigate = useNavigate();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // DESTRUCTURING DATA SERVER
  const { allProposals, employeePermission } = dataServer && dataServer;


  // useEffect
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(urls.getLisProposal, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          // ocurrio un error interno en el servidor
        }
        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {
        isLoading && (<LoadingComp />)
      }
      <TopNavbarMobile />
      <div className="container display-data">
        {/* TABLA PARA MOSTRAR LAS PROPUESTAS */}
        <div className="container-table-mobile">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th style={{ borderRadius: '5px 0 0 0' }}>Nro. Propuesta</th>
                <th>Cliente</th>
                <th>Provincia / Isla</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Monto Propuesta $</th>
                <th style={{ borderRadius: '0 5px 0 0' }}>Observación</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                allProposals && allProposals.map((proposal) => (
                  <tr key={proposal.CodPropuesta}>
                    <td style={{ textAlign: 'center' }}><Link className="link-tag" to={`/post-sales/proposal/proposal-detail/${proposal.CodPropuesta}`}>{proposal.NumPropuesta}</Link></td>
                    <td><Link className="link-tag">{proposal.Cliente}</Link></td>
                    <td style={{ textAlign: 'center' }}>{proposal.Estado}</td>
                    <td>{renderDate(proposal.FecInicio, language)}</td>
                    <td>{renderDate(proposal.FecFin, language)}</td>
                    <td>{proposal.TotDivision}</td>
                    <td className="col-4" style={{ textTransform: 'capitalize' }}><strong style={{ textDecoration: 'underline' }}>{renderDate(proposal.comment[0]?.FecIngreso, language)}</strong> {proposal.comment[0]?.Accion.toLowerCase() || ""}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};