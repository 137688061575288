import React from "react";

// ----> Styles import for ReportsView
import "../../views/reports/ReportsView.css";


export const ReportsView = () => {

  return (
    <>
      <div className="display_data">
        <h1>Reports View</h1>
      </div>
    </>
  );
};