import React from "react";



export const ModeloDataView = () => {

  return (
    <>
    <div className="container display-data">
      <h1>Modelo Data View</h1>
    </div>
    </>
  );
};