import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";


// REACT ROUTER COMPONENTS IMPORTATION
import { Link, useNavigate } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";


export const ShipToLogisticView = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // navigate
  const navigate = useNavigate();

  // get the token
  const token = getTheToken().token;

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchCompany, setSearchCompany] = useState("");

  // DESTRUCTURING DATA SERVER
  const { shipTo } = dataServer && dataServer;

  // COMPANY FOUND
  const companyFound = !searchCompany ? shipTo : shipTo && shipTo.filter(company => company.Nombre.toLowerCase().includes(searchCompany.toLowerCase()));


  // useEffect
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(`${urls.dataShipTo}?language=${language}`, {
          headers: { "Authorization": token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 500) {
          // ocurrió un error interno en el servidor
        } else if (response.status === 401) {
          return navigate("");
        }

        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [navigate, token, language]);



  return (
    <>
      {isLoading && (<LoadingComp />)}
      <div className="container display-data">
        <div className="row-flex">
          {/* BUSCAR POR EMPRESA */}
          <div className="col-9"><strong>Empresa</strong></div>
          <div className="col-7">
            <input
              className="input-text-control"
              type="text"
              name="searchComp"
              value={searchCompany}
              onChange={({ target }) => setSearchCompany(target.value)}
              placeholder={language === "es" ? "Buscar por empresa" : "Search by company name"}
              autoComplete="off" />
          </div>
          {/* Boton para agregar un nuevo ship to */}
          <div className="col-10 col-sm-20"><strong>Agregar ship to</strong></div>
          <div className="col-9 col-sm-80">
            <button type="button" className="btn-add">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </button>
          </div>
        </div>

        {/* TABLA DE LOS PROVEEDORES */}
        <div className="table-container-mobile m-y-small">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Activo</th>
                <th>Teléfono</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                companyFound && companyFound.map((comp) => (
                  <tr key={comp.CodEmpEnvia}>
                    <td className="col-4"><Link className="link-tag" to={`/ship-to/detail/${comp.CodEmpEnvia}`}>{comp.Nombre}</Link></td>
                    <td className="col-4">{comp.Direccion}</td>
                    <td style={{ textAlign: 'center' }}><strong className={comp.Activo ? "completed" : "inactive"}>{comp.activeOrInactive}</strong></td>
                    <td style={{ textDecoration: 'underline', color: 'blue' }}>{comp.CodTelefono} {comp.Telefono}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};