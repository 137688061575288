// componente para mostrar el detalle de la relacion de gastos (encabezado)



// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { Error403View } from "../error-403-view/Error403View";


import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";

// Global functions import
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";



export const RelacionGastoDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codSolG } = useParams();

  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data del servidor
  const [stateSolGasto, setStateSolGasto] = useState([]); // estado para colocar la solicitud de sol gasto en el estado
  const [stateGeren, setStateGeren] = useState([]); // estado para filtrar la gerencia
  const [stateUnidad, setStateUnidad] = useState([]); // estado para filtrar la unidad
  const [controller, setController] = useState(null); // estado para colocar el abort controller
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, showModal: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, succes: false });
  const [stateForm, setStateForm] = useState({
    empresa: "", division: "", gerencia: "", areaFun: "", solicitante: "", beneficiario: "", fecSoli: "",
    fecReque: "", formaPago: "", tranferirA: "", nroCuenta: "", moneda: "", primaryAction: "", nroReporte: "", comentario: ""
  });


  // Destructuring data from the server
  const { solGastoHeader, segSolGasto } = dataServer && dataServer;


  // si es igual a 0 no renderiza el boton de enviar
  const detalleExist = stateSolGasto && stateSolGasto[0]?.detalleMonto.length;


  // CONST ETAPAS
  const ELABO_RELACION = 5;
  const DESC_NOMINA = 9;


  // Id Monedas
  const ID_MONEDAS = {
    0: 'DOP $',
    1: 'USD',
    2: 'EUR',
    3: 'TTD'
  };

  const MONEDA_ISO = ID_MONEDAS[stateForm.moneda] ?? 'DOP';


  // funcion para realizar el fetch de la data
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.relacionGasto}/${codSolG}/search?language=${language}`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, codSolG]);


  // useEffect para cargar en el estado el header de la solicitud
  useEffect(() => {
    if (solGastoHeader && solGastoHeader.length > 0) {

      const FecSolicitud = solGastoHeader[0]?.FecIngreso ? solGastoHeader[0]?.FecIngreso.slice(0, 10) : "";

      const FecRequerida = solGastoHeader[0]?.FecRequerida ? solGastoHeader[0]?.FecRequerida.slice(0, 10) : "";

      setStateForm({
        empresa: solGastoHeader[0]?.CodEmpresa, division: solGastoHeader[0]?.CodGrupo.toString(), gerencia: solGastoHeader[0]?.CodGerencia.toString(),
        areaFun: solGastoHeader[0]?.CodUnidad.toString(), solicitante: solGastoHeader[0]?.EmpSolicitante, beneficiario: solGastoHeader[0]?.EmpSolicitante,
        fecSoli: FecSolicitud, fecReque: FecRequerida, formaPago: solGastoHeader[0]?.ForPago, tranferirA: solGastoHeader[0]?.EntFondo ?? "",
        nroCuenta: solGastoHeader[0]?.NumCuenta ?? "", moneda: solGastoHeader[0]?.IdMoneda.toString(), primaryAction: "", nroReporte: "", comentario: ""
      });

      setStateSolGasto(solGastoHeader);
    }
  }, [solGastoHeader]);


  // useEffect para filtrar la gerencia
  useEffect(() => {
    if (solGastoHeader && solGastoHeader.length > 0) {
      const filterGeren = solGastoHeader && solGastoHeader[0]?.gerencia.filter((gere) => gere.CodGrupo.toString() === stateForm.division);

      setStateGeren(filterGeren);
    }
  }, [stateForm.division]);

  // useEffect para filtrar la unidad por la gerencia
  useEffect(() => {
    if (solGastoHeader && solGastoHeader.length > 0) {
      const filterUnidad = solGastoHeader && solGastoHeader[0]?.unidad.filter((uni) => uni.CodGerencia.toString() === stateForm.gerencia);

      setStateUnidad(filterUnidad);
    }
  }, [stateForm.gerencia]);


  // useEffect para limpiar la notificacion del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, succes: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);




  return (
    <>
      {/* Error 403 (Unauthorized component) */}
      {state.unauthorized && (<Error403View />)}
      {/* Toast notification */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <form>
              {/* FILA */}
              <div className="row-flex">
                {/* Nro de control */}
                <div className="col-7"><strong>{t("Viaticos.nro_control")}</strong></div>
                <div className="col-3"><h4>{codSolG}</h4></div>
                {/* Etapa */}
                <div className="col-7"><strong>{t("Viaticos.etapa")}</strong></div>
                <div className="col-3"><strong className="active-class" style={{ textDecoration: 'underline' }}>{solGastoHeader && solGastoHeader[0]?.Etapa}</strong></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Empresa */}
                <div className="col-7"><strong>{t("Viaticos.empresa")}</strong></div>
                <div className="col-3">
                  <select
                    name="company"
                    value={stateForm.empresa}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, empresa: target.value }))}>
                    <option value=""></option>
                    <option value="1">Global Medica</option>
                    <option value="2">GMD Healthcare Ltd</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Division empresa */}
                <div className="col-7"><strong>{t("Viaticos.divi_empr")}</strong></div>
                <div className="col-3">
                  <select
                    name="division"
                    value={stateForm.division}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, division: target.value }))}>
                    <option value=""></option>
                    {
                      stateSolGasto[0]?.division.map((divi) => (
                        <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* Gerencia */}
                <div className="col-7"><strong>{t("Viaticos.gerencia")}</strong></div>
                <div className="col-3">
                  <select
                    name="gerencia"
                    value={stateForm.gerencia}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, gerencia: target.value }))}>
                    <option value=""></option>
                    {
                      stateGeren && stateGeren.map((gere) => (
                        <option key={gere.CodGerencia} value={gere.CodGerencia}>{gere.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Area funcional */}
                <div className="col-7"><strong>{t("Viaticos.area_fun")}</strong></div>
                <div className="col-3">
                  <select
                    name="areaFun"
                    value={stateForm.areaFun}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, areaFun: target.value }))}>
                    <option value=""></option>
                    {
                      stateUnidad && stateUnidad.map((uni) => (
                        <option key={uni.CodUnidad} value={uni.CodUnidad}>{uni.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Solicitante */}
                <div className="col-7"><strong>{t("Viaticos.solicitante")}</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="requestor"
                    value={stateForm.solicitante}
                    readOnly />
                </div>
                {/* Beneficiario */}
                <div className="col-7"><strong>{t("Viaticos.beneficiario")}</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="beneficiary"
                    value={stateForm.beneficiario}
                    readOnly />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Fecha de solicitud */}
                <div className="col-7"><strong>{t("Viaticos.fec_soli")}</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="fecRequest"
                    value={renderDate(stateForm.fecSoli, language)}
                    readOnly />
                </div>
                {/* Fecha requerida */}
                <div className="col-7"><strong>{t("Viaticos.fec_reque")}</strong></div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecReque"
                    value={stateForm.fecReque}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fecReque: target.value }))} />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Forma de pago */}
                <div className="col-7"><strong>{t("Viaticos.form_pag")}</strong></div>
                <div className="col-3">
                  <select
                    name="formPag"
                    value={stateForm.formaPago}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, formaPago: target.value }))}>
                    <option value=""></option>
                    <option value="1">{t("Viaticos.deposito")}</option>
                    <option value="2">{t("Viaticos.efectivo")}</option>
                    <option value="3">{t("Viaticos.tarje_cred")}</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Transferir a nombre de */}
                <div className="col-7"><strong>{t("Viaticos.trans_nomb")}</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="transNom"
                    value={stateForm.tranferirA}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tranferirA: target.value }))} />
                </div>
                {/* Numero de cuenta */}
                <div className="col-7"><strong>{t("Viaticos.num_cuenta")}</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="numCuenta"
                    value={stateForm.nroCuenta}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nroCuenta: target.value }))} />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Moneda */}
                <div className="col-7"><strong>{t("Viaticos.moneda")}</strong></div>
                <div className="col-3">
                  <select
                    name="currency"
                    value={stateForm.moneda}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                    <option value=""></option>
                    <option value="0">RD$ Pesos</option>
                    <option value="1">US$ Dollar</option>
                    <option value="2">€ Euro</option>
                    <option value="3">TT$ Dollar</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Comentario */}
                <div className="col-7"><strong>{t("Viaticos.comentario")}</strong></div>
                <div className="col-82-16">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={stateForm.comentario}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                    autoComplete="off" />
                </div>
              </div>


              {/* botones de accion */}
              <div className="row-flex j-content-space-around m-y-small">
                <button type="button" className="btn-primary">Guardar</button>
                <button type="button" className="btn-danger">Eliminar</button>
                {
                  (stateSolGasto[0]?.IdEtapa === ELABO_RELACION || stateSolGasto[0]?.IdEtapa === DESC_NOMINA) && (
                    <>
                      {
                        detalleExist !== 0 && (<button type="button" className="btn-success">Enviar</button>)
                      }
                    </>
                  )
                }
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              <hr className="m-y-medium" />

              {
                stateSolGasto[0]?.EmpCodGrupo === 1 && stateSolGasto[0]?.IdEtapa === ELABO_RELACION && (
                  <>
                    <div className="row-flex">
                      {/* Nro de reporte */}
                      <div className="col-7"><strong>Nro. Reporte</strong></div>
                      <div className="col-3 row-flex align-c">
                        <div className="col-87">
                          <input
                            className="input-text-control"
                            type="text"
                            name="nroReport"
                            value={stateForm.nroReporte}
                            onChange={({ target }) => setStateForm((val) => ({ ...val, nroReporte: target.value }))}
                            autoComplete="off" />
                        </div>
                        <div className="col-8">
                          <button type="button" className="btn-success" style={{ padding: '0.3rem 0.4rem', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      {/* Msg condicional */}
                      <div className="col-45 row-flex align-c">
                        <h6 className="required">{t("Viaticos.falta_report_serv")}</h6>
                      </div>
                    </div>
                  </>
                )
              }

              {/* tabla para mostrar los reportes de servicio */}
              {
                stateSolGasto[0]?.EmpCodGrupo === 1 && (
                  <>
                    {
                      stateSolGasto[0]?.reportesAsoci.length > 0 && (
                        <>
                          <h4 className="m-y-small" style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>Reportes asociados</h4>
                          <div className="table-container-mobile m-y-small">
                            <table className="table">
                              <thead className="table-head">
                                <tr>
                                  <th></th>
                                  <th>Nro. Reporte</th>
                                  <th>Cliente</th>
                                  <th>Equipo</th>
                                  <th>Contrato</th>
                                </tr>
                              </thead>
                              <tbody className="table-body">
                                {
                                  stateSolGasto[0]?.reportesAsoci.map((rep) => (
                                    <tr key={rep.CodRepSolGasto}>
                                      <td></td>
                                      <td>{rep.Numreporte}</td>
                                      <td>{rep.NomCliente}</td>
                                      <td>{rep.NomEquipo}</td>
                                      <td className="txt-center">{rep.NumContrato}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </>
                      )
                    }
                  </>
                )
              }

              {/* Tabla para mostrar el detalle de los montos */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th></th>
                      <th>Cliente</th>
                      <th>Equipo</th>
                      <th>Origen</th>
                      <th>Destino</th>
                      <th>Anticipo aprobado {MONEDA_ISO}</th>
                      <th>Relacionado {MONEDA_ISO}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      stateSolGasto[0]?.detalleMonto.map((det) => (
                        <tr key={det.CodDetSolGasto}>
                          <td></td>
                          <td>{det.NomCliente}</td>
                          <td>{det.Descripcion === null ? "N/A" : det.Descripcion}</td>
                          <td>{det.CiuOrigen}</td>
                          <td>{det.CiuDestino}</td>
                          <td className="txt-right">
                            {formatCurrency(det.Total1, 'USD')}
                          </td>
                          <td className="txt-right">
                            {formatCurrency(det.Total, 'USD')}
                          </td>
                          <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button type="button" className="btn-delivery" onClick={() => navigate(`/travel-expenses/expense-report-detail/${det.CodSolGasto}/${det.CodDetSolGasto}`)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-journal-plus" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                    <tr>
                      <td colSpan={5} style={{ fontWeight: 'bold' }}>Total</td>
                      <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                        {formatCurrency(solGastoHeader[0]?.Total[0]?.Total1, 'USD')}
                      </td>
                      <td className="txt-right" style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>
                        {formatCurrency(solGastoHeader[0]?.Total[0]?.Total, 'USD')}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={8} className="txt-center">
                        <button type="button" className="btn-success" onClick={() => navigate(`/travel-expenses/create-detail-expense-report/${codSolG}`)}>Agregar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              {/* tabla para mostrar las etapas y comentarios */}
              <h4 className="m-y-small" style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>Seguimiento</h4>
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Fecha</th>
                      <th>Etapa actual</th>
                      <th>Próxima etapa</th>
                      <th>Responsable</th>
                      <th>Comentario</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      segSolGasto && segSolGasto.map((seg) => (
                        <tr key={seg.CodSegSolGasto}>
                          <td>{renderDate(seg.FecIngreso, language)}</td>
                          <td>{seg.EtapaActual}</td>
                          <td>{seg.EtapaProx}</td>
                          <td>{seg.NomEmpleado}</td>
                          <td>{seg.Comentario}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

            </form>
          )
        }
      </div>
    </>
  );
};