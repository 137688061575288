// este modulo es para crear las autorizaciones de las tarjetas

import { useEffect, useState } from "react";


// components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { AuthorizeEmployee } from "../../components/administration-compon/AuthorizeEmployee";
import { NewCardEmplo } from "../../components/administration-compon/NewCardEmplo";
import { Error403View } from "../error-403-view/Error403View";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { UsersComp } from "../../components/administration-compon/UsersComp";
import { CompListTC } from "../../components/administration-compon/CompListTC";


// react router dom import
import { Link, useLocation, useNavigate } from "react-router-dom";

// url imports
import { urls } from "../../api-urls/api-urls";


// global functions import
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";




export const AuthorizationsView = () => {

  // GET the language
  const languange = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation 
  const location = useLocation();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, modalAuthor: false, modalNewCard: false, modalAsignar: false, msg: "", modalUsers: false, unauthorized: false, modalTC: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // destructuring data server
  const { pendientes } = dataServer && dataServer;


  // funcion para cerrar el modal de autorizar empleado
  const onCloseModalAuthor = () => {
    setState((val) => ({ ...val, modalAuthor: false }));
  };

  // funcion para cerrar el modal de los usuarios
  const onCloseModalUsers = () => {
    setState((val) => ({ ...val, modalUsers: false }));
  };


  // funcion para cerrar el modal de las tarjetas
  const onCloseModalTC = () => {
    setState((val) => ({ ...val, modalTC: false }));
  };


  // funcion para cerrar el modal de cread una nueva card
  const onCloseModalNewCard = () => {
    setState((val) => ({ ...val, modalNewCard: false }));
  };



  // funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.autorizaciones}?language=${languange}&action=3`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 404) {
        setDataServer(data); // para que se carguen los empleados que tienen tarjetas
        setState((val) => ({ ...val, msg: data?.msg, dataVisible: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cargar la data
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [languange]);




  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);




  return (
    <div className="container display-data">
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // Error 403 component
        state.unauthorized && (<Error403View />)
      }
      {
        // toast notificacion
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        state.dataVisible && (
          <>
            <div className="row-flex align-c j-content-space-b">
              <h4 className="m-y-small">Solicitudes pendientes de cierre</h4>
              <div className="row-flex gap-small col-52-19">
                <div className="col-5">
                  <button type="button" className="btn-primary" onClick={() => setState((val) => ({ ...val, modalAuthor: true }))}>Autorizar</button>
                </div>
                <div className="col-4">
                  <button type="button" className="btn-primary" onClick={() => setState((val) => ({ ...val, modalNewCard: true }))}>Nueva tarjeta</button>
                </div>
                <div className="col-5">
                  <button type="button" className="btn-primary" onClick={() => setState((val) => ({ ...val, modalUsers: true }))}>Usuarios</button>
                </div>
                <div className="col-4 row-flex gap-small">
                  <button type="button" className="btn-link-tag" onClick={() => setState((val) => ({ ...val, modalTC: true }))}>TC</button>
                  <button type="button" className="btn-link-tag" onClick={() => navigate("/administration/report-consumption-request")}>Reportes</button>
                </div>
              </div>
            </div>
            {
              pendientes && pendientes.length > 0 && (
                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Fecha</th>
                        <th>Solicitud</th>
                        <th>Consumidor final</th>
                        <th>Monto</th>
                        <th>Solicitante</th>
                        <th>Etapa</th>
                        <th>Autorizado por</th>
                        <th>Cargó documento</th>
                        <th>Comentario</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        pendientes && pendientes.map((pen) => (
                          <tr key={pen.CodSolicitud}>
                            <td>{renderDate(pen.FecIngreso, languange)}</td>
                            <td className="txt-center">
                              <Link className="link-tag" to={`/administration/consumption-request-detail/${pen.CodSolicitud}`}>{pen.CodSolicitud}</Link>
                            </td>
                            <td>{pen.ConsuFinal}</td>
                            <td className="txt-center">
                              <strong className="active-class">{formatCurrency(pen.MontoConsumo, "USD")}</strong>
                            </td>
                            <td>{pen.NomEmpleado}</td>
                            <td>{pen.NomEtapa}</td>
                            <td>{pen.EmpAutoriza}</td>
                            <td className="txt-center">{pen.Documento ? <strong className="active-class">Si</strong> : <strong style={{ color: "red" }}>No</strong>}</td>
                            <td>{pen.Comentario}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            }
            {
              state.msg && (
                <h5 style={{ color: "red" }}>{state.msg}</h5>
              )
            }

            {
              // modal para las autorizaciones
              state.modalAuthor && (
                <AuthorizeEmployee onClose={onCloseModalAuthor} lang={languange} />
              )
            }

            {
              // modal para crear una tarjeta
              state.modalNewCard && (
                <NewCardEmplo onClose={onCloseModalNewCard} lang={languange} />
              )
            }

            {
              // modal para ver los usuarios que tienen tarjetas asociadas
              state.modalUsers && (
                <UsersComp onClose={onCloseModalUsers} lang={languange} token={getTheToken().token} />
              )
            }

            {
              // modal para ver las tarjetas ingresadas
              state.modalTC && (<CompListTC onClose={onCloseModalTC} lang={languange} token={getTheToken().token} />)
            }

          </>
        )
      }
    </div>
  );
};