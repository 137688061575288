import React, { useEffect, useState } from "react";

// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// React router dom importation
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Url importation
import { urls } from "../../api-urls/api-urls";

// Global functions importation
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// useTranslation
import { useTranslation } from "react-i18next";
import { Error403View } from "../error-403-view/Error403View";


export const AproSoporteDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codSolRep } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cargar el controlador que aborta la peticion al servidor
  const [questionAction, setQuestionAction] = useState("");
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, succes: false });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false, modalFile: false, empSolicita: 0 });
  const [partes, setPartes] = useState([]); // Estado para modificar las partes de detSolRep para modificar los valores
  const [stateForm, setStateForm] = useState({ comment: "", primaryAction: "" });
  const [selectedFile, setSelectedFile] = useState(null);

  // DESTRUCTURING DATA SERVER
  const { solRep, detSolRep, empPerm } = dataServer && dataServer;



  // Funcion para manejar el abrir el enviar y definir la accion
  const onHandleSendModal = () => {
    setQuestionAction("Está seguro que desea enviar a orden de compra?");
    setState((val) => ({ ...val, showModal: true }));
    setStateForm((val) => ({ ...val, primaryAction: "1" }));
  };


  // Funcion para manejar el devolver y definir la accion
  const onHandleReturnModal = () => {
    setQuestionAction("Está seguro que desea enviar a aprobación técnica?");
    setState((val) => ({ ...val, showModal: true }));
    setStateForm((val) => ({ ...val, primaryAction: "2" }));
  };

  // Funcion para cargar el archivo al estado 
  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    setSelectedFile(file);
  };


  // Manejo del envio de documentos al servidor
  const onUploadDocument = async (event) => {
    event.preventDefault();

    if (selectedFile) {

      const formData = new FormData();

      formData.append('file', selectedFile);

      const response = await fetch(`${urls.supportApproval}/${codSolRep}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          // "Content-Type": "multipart/form-data"
        },
        body: formData
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, modalFile: false }));
        setNotification({ show: true, msg: data?.msg, error: false, great: true, succes: false });
        setSelectedFile(null);
      } else if (response.status === 400) {
        setNotification({ show: true, msg: data?.msg, error: true, great: false, succes: false });
      } else if (response.status === 404) {
        setNotification({ show: true, msg: data?.msg, error: true, great: false, succes: false });
      } else if (response.status === 413) {
        // el archivo es muy pesado no se puede procesar
        setNotification({ show: true, msg: "El archivo que intenta subir excede los 10 mb.", error: true, great: false, succes: false });
      }

    } else { // no hay nada para enviar

    }

  };



  // Manejo del submit del formulario
  const onSendRequest = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.supportApproval}/${codSolRep}/search?language=${language}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setNotification({ show: true, msg: data?.msg, error: false, great: true, succes: true });
        setStateForm({ comment: "", primaryAction: "" });
        setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, showModal: false }));
        setNotification({ show: true, msg: data?.msg, error: true, great: false, succes: false });
      }

    } catch (error) {
      console.log(error);
    }

  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    const fetchDataServer = async () => {
      const abortController = new AbortController();

      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.supportApproval}/${codSolRep}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token },
          signal: abortController.signal
        });

        const data = await response.json();

        if (response.status === 200) {
          setState((val) => ({ ...val, dataVisible: true }));
          setDataServer(data);
        } else if (response.status === 400) {
          // bad request
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
        } else if (response.status === 500) {
          setState((val) => ({ ...val, serverError: true }));
        }

        setState((val) => ({ ...val, isLoading: false }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codSolRep, language]);

  // console.log(state.dataVisible)


  // useEffect para cargar la data del detalle de las partes
  useEffect(() => {
    if (detSolRep && detSolRep.length > 0) {
      setPartes(detSolRep);
    }
  }, [detSolRep]);


  // useEffect para cargar el empleado que solicita en el estado
  useEffect(() => {
    if (solRep && solRep.length > 0) {
      setState((val) => ({ ...val, empSolicita: solRep[0]?.CodEmpIngreso }));
    }
  }, [solRep]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, succes: false });
    }, 6000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // useEffect para redireccionar a la ventana principal de servicio
  useEffect(() => {
    if (notification.succes) {
      const timer = setTimeout(() => {
        // redireccionar a principal servicio
        navigate("/my-tasks")
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notification.succes]);


  return (
    <>
      {
        state.unauthorized && (
          <Error403View />
        )
      }
      {
        // notification component
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)}
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        state.dataVisible && (
          <div className="container display-data">

            <form onSubmit={onSendRequest}>
              {/* FILA */}
              <div className="row-flex">
                {/* Solicitud */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.soli")}</strong></div>
                <div className="col-7 col-sm-8"><h4>{codSolRep}</h4></div>
                {/* ID Servicio */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.id_serv")}</strong></div>
                <div className="col-7 col-sm-35"><Link className="link-tag" to={`/service/service-call/${solRep && solRep[0]?.CodServicio}`}>{solRep && solRep[0]?.CodServicio}</Link></div>
                {/* Modelo */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.modelo")}</strong></div>
                <div className="col-4 col-sm-45"><span>{solRep && solRep[0]?.equipment[0]?.Modelo}</span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Serial */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.fecha")}</strong></div>
                <div className="col-7 col-sm-80"><span>{solRep && renderDate(solRep[0]?.FecIngreso, language)}</span></div>
                {/* Serial */}
                <div className="col-7 col-sm-20"><strong>Serial</strong></div>
                <div className="col-7 col-sm-80"><span>{solRep && solRep[0]?.equipment[0]?.Serial}</span></div>

                {/* Suplidor */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.supli")}</strong></div>
                <div className="col-4 col-sm-80 text-hidden-over"><span>{solRep && solRep[0]?.equipment[0]?.Marca}</span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Cliente */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.cliente")}</strong></div>
                <div className="col-48-87 col-sm-80 text-hidden-over"><Link className="link-tag" to={`/customers/customer-detail/${solRep && solRep[0]?.CodCliente}`}>{solRep && solRep[0]?.NomCliente}</Link></div>
                {/* FDO */}
                <div className="col-7 col-sm-20"><strong>FDO</strong></div>
                <div className="col-4 col-sm-80"><span></span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Equipo */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.equip")}</strong></div>
                <div className="col-48-87 col-sm-80"><Link className="link-tag" to={`/equipments/equipment-detail/${solRep && solRep[0]?.equipment[0]?.CodEquipo}`}>{solRep && solRep[0]?.equipment[0]?.Linea} - {solRep && solRep[0]?.equipment[0]?.Modalidad} - {solRep && solRep[0]?.equipment[0]?.TipEquipo} - {solRep && solRep[0]?.equipment[0]?.Modelo} - {solRep && solRep[0]?.equipment[0]?.Serial} - {solRep && solRep[0]?.equipment[0]?.Marca} - {solRep && solRep[0]?.equipment[0]?.NumSistema}</Link></div>
                {/* OC */}
                <div className="col-7 col-sm-20"><strong>O/C</strong></div>
                <div className="col-4 col-sm-80"><span></span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Tecnico */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.ing_tec")}</strong></div>
                <div className="col-28-58 col-sm-80"><span>{solRep && solRep[0]?.NomEmpleado}</span></div>
                {/* Contrato */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.contr")}</strong></div>
                <div className="col-7 col-sm-80"><span>{solRep && solRep[0]?.idTipContrato}</span></div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Falla */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.falla")}</strong></div>
                <div className="col-28-58 col-sm-80"><span style={{ textTransform: 'capitalize', color: 'red', textDecoration: 'underline' }}>{solRep && solRep[0]?.Falla}</span></div>
                {/* Observacion */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.obser")}</strong></div>
                <div className="col-39-29 col-sm-80"><span></span></div>
              </div>

              {/* FILA */}
              {
                empPerm && (empPerm[0]?.CodEmpleado === state.empSolicita || empPerm[0]?.CodPerfil === 6) && (
                  <div className="row-flex">
                    <div className="col-7 col-sm-20"><strong>Cargar aprobación</strong></div>
                    <div className="col-10 col-70-sm">
                      <button type="button" className="btn-docs" onClick={() => setState((val) => ({ ...val, modalFile: true }))}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                          <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
                          <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </button>
                    </div>
                    <div className="col-7">
                      {
                        solRep && solRep[0]?.Soporte && (<strong className="active-class">Archivo cargado</strong>)
                      }
                    </div>
                  </div>
                )
              }


              {/* FILA */}
              <div className="row-flex">
                {/* Comentario */}
                <div className="col-7 col-sm-20"><strong>{t("RequestPartsView.comentario")}</strong></div>
                <div className="col-82-16 col-sm-80">
                  <textarea
                    className="textarea-description"
                    name="comentario"
                    value={stateForm.comment}
                    onChange={({ target }) => setStateForm({ comment: target.value })}
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              {/* TABLA PARA MOSTRAR LAS PARTES */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes && partes.map((part, index) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td style={{ textAlign: 'center' }}>{index + 1}</td>
                          <td style={{ textAlign: 'center' }}>{part.Cantidad}</td>
                          <td><Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link></td>
                          <td>{part.Descripcion}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones de accion */}
              <div className="row-flex m-y-medium j-content-space-around">
                {
                  empPerm && (empPerm[0]?.CodEmpleado === state.empSolicita || empPerm[0]?.CodPerfil === 6) && (
                    <>
                      <button type="button" className="btn-primary" onClick={onHandleSendModal}>{t("RequestPartsView.enviar")}</button>
                      <button type="button" className="btn-warning" onClick={onHandleReturnModal}>{t("RequestPartsView.devol")}</button>
                    </>
                  )
                }
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState({ ...state, showModal: false })}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>{questionAction}</h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                        <button type="button" className="btn-danger" onClick={() => setState({ ...state, showModal: false })}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>

            {/* Modal para subir los documentos */}
            {
              state.modalFile && (
                <div className="background-modal" onClick={() => setState((val) => ({ ...val, modalFile: false }))}>
                  <div className="container-modal-upload-docs" onClick={(event) => event.stopPropagation()}>
                    <form onSubmit={onUploadDocument} encType="multipart/form-data">
                      {/* FILA */}
                      <div className="row-flex">
                        <div className="col-6"><strong>Documento a subir</strong></div>
                        <div className="col-6 text-hidden-over">
                          <input
                            type="file"
                            name="file"
                            accept=".pdf"
                            onChange={handleFileChange} />
                        </div>
                      </div>

                      {/* Botones de accion */}
                      <div className="row-flex j-content-space-around m-y-small">
                        <button type="submit" className="btn-primary">Aceptar</button>
                        <button type="reset" className="btn-secondary" onClick={() => setState((val) => ({ ...val, modalFile: false }))}>Cancelar</button>
                      </div>
                    </form>
                  </div>
                </div>
              )
            }

          </div>
        )
      }
    </>
  );
};