
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { Error500Comp } from "../errors/Error500Comp";
import { ToastNotification } from "../toast-notification/ToastNotification";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTION
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { useTranslation } from "react-i18next";


export const ModifyContactCustomer = ({ onClose, codContacto, codCliente }) => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [stateInput, setStateInput] = useState({
    name: "", cedula: "", codTelefono: "", telefono: "", codCelular: "", celular: "", tipo: "", fax: "", correo: "",
    departament: "", posicion: "", especialidad: "", principal: false, activo: false
  });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false });


  // DESTRUCTURING DATA SERVER
  const { contact, employeePermission } = dataServer && dataServer;


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);

        const response = await fetch(`${urls.getCustomer}/${codCliente}/${codContacto}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
          setServerError(true);
        }

        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [codContacto, language]);


  // useEffect para cargar la data en el estado y luego poder modificarla
  useEffect(() => {
    if (contact && contact.length > 0) {
      setStateInput((val) => ({
        ...val, name: contact[0]?.Nombre, cedula: contact[0]?.Cedula, codTelefono: contact[0]?.CodTelefono, telefono: contact[0]?.Telefono, codCelular: contact[0]?.CodCelular, celular: contact[0]?.Celular, tipo: contact[0]?.IdTipo, fax: contact[0]?.Fax, correo: contact[0]?.Correo,
        departament: contact[0]?.Departamento, posicion: contact[0]?.Cargo, especialidad: "", principal: contact[0]?.Principal, activo: contact[0]?.Activo
      }));
    }
  }, [contact]);


  // ACTUALIZAR EL CONTACTO
  const onUpdateContact = async (event) => {
    event.preventDefault();

    try {
      setSaving(true);
      const response = await fetch(`${urls.getCustomer}/${codCliente}/${codContacto}/search`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateInput)
      });

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: language === "en" ? "The contact was successfully updated." : "El contacto se actualizó correctamente.", great: true }));
      } else {
        setNotification((val) => ({ ...val, show: true, msg: language === "en" ? "An error ocurred while trying to update the contact, try it again, if the error persists contact the admin." : "Ocurrió un error al intentar actualizar el contacto, intentelo nuevamente, si el error persiste contacte al administrador", errorNoti: true }));
      }

      setSaving(false);

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect PARA LIMPIAR LAS NOTIFICACIONES
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false });
    }, 6600);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);



  return (
    <>
      {/* MOSTRAR LAS NOTIFICACIONES */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)}

      {/* ERROR DEL SERVIDOR */}
      {serverError && (<Error500Comp />)}
      <div className="background-modal">
        <form className="container-select-customer" onSubmit={onUpdateContact}>
          <div className="modal-header">
            <h4>{t("customerView.modificar_contact")}</h4>
            <button type="button" className="btn-close" onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
          </div>
          <div className="modal-body">

            {isLoading && (<div className="simple-spinner"><span></span></div>)}
            {/* FILA 1 */}
            <div className="row-flex">
              {/* NOMBRE */}
              <div className="col-7">
                <strong>{t("customerView.name")}</strong>
              </div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="contactName"
                  value={stateInput.name}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, name: target.value }))}
                  placeholder={t("customerView.nombre_place_hold")} />
              </div>
              {/* CEDULA */}
              <div className="col-7">
                <strong>{t("customerView.cedula")}</strong>
              </div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="cedulaContact"
                  value={stateInput.cedula}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, cedula: target.value }))}
                  placeholder={t("customerView.cedula_place_hold")} />
              </div>
            </div>

            {/* FILA 2 */}
            <div className="row-flex">
              {/* NUMERO DE TELEFONO */}
              <div className="col-7">
                <strong>{t("customerView.telephone")}</strong>
              </div>
              <div className="col-70 row-flex">
                <div className="col-4">
                  <input
                    className="input-text-control"
                    type="text"
                    name="codTele"
                    value={stateInput.codTelefono}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, codTelefono: target.value }))}
                    placeholder="000" />
                </div>
                <div className="col-46-7">
                  <input
                    className="input-text-control"
                    type="text"
                    name="phoneContact"
                    value={stateInput.telefono}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, telefono: target.value }))}
                    placeholder="000-0000" />
                </div>
              </div>

            </div>

            <div className="row-flex">
              {/* CELULAR */}
              <div className="col-7">
                <strong>{t("customerView.cell")}</strong>
              </div>
              <div className="col-70 row-flex">
                <div className="col-4">
                  <input
                    className="input-text-control"
                    type="text"
                    name="codCell"
                    value={stateInput.codCelular}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, codCelular: target.value }))}
                    placeholder="000" />
                </div>
                <div className="col-46-7">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="celularContact"
                    value={stateInput.celular}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, celular: target.value }))}
                    placeholder="000-0000" />
                </div>
              </div>
            </div>

            {/* FILA 3 */}
            <div className="row-flex">
              {/* TIPO DE CONTACTO */}
              <div className="col-7">
                <strong>{t("customerView.tipo")}</strong>
              </div>
              <div className="col-3">
                <select
                  name="tipoContact"
                  value={stateInput.tipo}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, tipo: target.value }))}>
                  <option value=""></option>
                  <option value="0">{t("customerView.post_venta")}</option>
                  <option value="1">{t("customerView.inge_tec")}</option>
                  <option value="2">{t("customerView.cobranza")}</option>
                  <option value="3">{t("customerView.devol_parte")}</option>
                </select>
              </div>
            </div>

            {/* FILA 4 */}
            <div className="row-flex">
              {/* FAX */}
              <div className="col-7"><strong>Fax</strong></div>
              <div className="col-3">
                <input
                  style={{ width: '100%' }}
                  className="input-text-control"
                  type="text"
                  name="faxContact"
                  value={stateInput.fax}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, fax: target.value }))}
                  placeholder={t("customerView.fax_contact")} />
              </div>
              {/* EMAIL */}
              <div className="col-7">
                <strong>{t("customerView.email")}</strong>
              </div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="emailContact"
                  value={stateInput.correo}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, correo: target.value }))}
                  placeholder="example@example.com" />
              </div>
            </div>

            {/* FILA 5 */}
            <div className="row-flex">
              {/* DEPARTAMENTO */}
              <div className="col-7">
                <strong>{t("customerView.department")}</strong>
              </div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="departContact"
                  value={stateInput.departament}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, departament: target.value }))}
                  placeholder={t("customerView.departamento_contact")} />
              </div>
              {/* POSICION */}
              <div className="col-7">
                <strong>{t("customerView.posicion")}</strong>
              </div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="positionContact"
                  value={stateInput.posicion}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, posicion: target.value }))}
                  placeholder={t("customerView.posicion_contact")} />
              </div>
            </div>

            {/* FILA 6 */}
            <div className="row-flex">
              {/* ESPECIALIDAD */}
              <div className="col-7">
                <strong>{t("customerView.especialidad")}</strong>
              </div>
              <div className="col-3">
                <select
                  name="especiContact"
                  value={stateInput.especialidad}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, especialidad: target.value }))}>
                  <option value=""></option>
                </select>
              </div>
            </div>

            {/* FILA 7 */}
            <div className="row-flex">
              {/* PRINCIPAL */}
              <div className="col-7">
                <strong>Principal</strong>
              </div>
              <div className="col-3">
                {
                  employeePermission && employeePermission[0]?.CodPerfil === 6 ? (
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="principal"
                      checked={stateInput.principal}
                      onChange={({ target }) => setStateInput((val) => ({ ...val, principal: target.checked }))} />
                  ) : (<span style={{ color: 'var(--danger-color)', textDecoration: 'underline' }}>{language === "en" ? "You must contact the admin." : "Debe contactar al administrador."}</span>)
                }
              </div>
              {/* ACTIVO */}
              <div className="col-7">
                <strong>{t("customerView.activo")}</strong>
              </div>
              <div className="col-3">
                <input
                  className="input-control-checkbox"
                  type="checkbox"
                  name="activo"
                  checked={stateInput.activo}
                  onChange={({ target }) => setStateInput((val) => ({ ...val, activo: target.checked }))} />
              </div>
            </div>

            {/* FILA 8 */}
            <div style={{ marginTop: '0.5rem' }} className="row-flex">
              {/* INGRESADO POR */}
              <div className="col-7">
                <strong>{t("customerView.ingresado_por")}</strong>
              </div>
              <div className="col-3">
                <span>{contact && contact[0]?.nomEmpIng}</span>
              </div>
              {/* MODIFICADO POR */}
              <div className="col-7">
                <strong>{t("customerView.modificado_por")}</strong>
              </div>
              <div className="col-3">
                <span>{contact && contact[0]?.nomEmpModificacion}</span>
              </div>
            </div>

            {/* FILA 9 */}
            <div className="row-flex">
              {/* FECHA DE INGRESO */}
              <div className="col-7">
                <strong>{t("customerView.fecha_ingr")}</strong>
              </div>
              <div className="col-3">
                <span>{contact && renderDate(contact[0]?.FecIngreso, language)}</span>
              </div>
              {/* FECHA MODIFICACION */}
              <div className="col-7">
                <strong>{t("customerView.fecha_modificacion")}</strong>
              </div>
              <div className="col-3">
                <span>{contact && renderDate(contact[0]?.FecModificacion, language)}</span>
              </div>
            </div>

          </div>
          <div className="modal-footer j-content-space-around">
            <button type={saving ? "button" : "submit"} className="btn-primary" disabled={saving}>{saving ? t("LoginView.cargando") : t("LogisticViewComp.actualizar")}</button>
            <button type="reset" className="btn-secondary" onClick={onClose}>{t("LogisticViewComp.cerrar")}</button>
          </div>

        </form>
      </div>
    </>
  );
};