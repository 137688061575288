// component para avanzar la solicitud de orden a aprobacion de director

import { useEffect, useState } from "react";

// url imports
import { urls } from "../../api-urls/api-urls";

// react router import
import { Link, useNavigate } from "react-router-dom";

// global functions
import { formatCurrency } from "../../global-functions/globalFunctions";



export const ProcesarRequestOrd = ({ onClose, Orders, lang, token }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion del servidor
  const [stateForm, setStateForm] = useState({ quote: "", gon: "", vendedor: "", destino: "", poa: "", conVenta: "", comentario: "", action: "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, msg: "", loading: false });


  // destructuring data server
  const { destino, employees, detOrden } = dataServer && dataServer;


  // funcion para manejar el envio del formulario al servidor
  const onHandleSendOrder = async () => {
    const finalObj = { stateForm, Orders };
    try {
      setState((val) => ({ ...val, loading: true }));
      const response = await fetch(`${urls.commercialRequest}?language=${lang}&action=2`, {
        method: "PUT",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        console.log(data?.msg);
      } else if (response.status === 401) {
        return navigate("/");
      }

      setState((val) => ({ ...val, loading: false }));

    } catch (error) {
      console.log(error);
    }
  };




  // funcion para solicitar la informacion inicial
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.commercialRequest}?language=${lang}&action=1`, {
        method: "PUT",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal,
        body: JSON.stringify(Orders)
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServer(data);
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cargar la informacion del servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para cargar los valores en el estado
  useEffect(() => {
    if (detOrden && detOrden.length > 0) {
      setStateForm((val) => ({
        ...val, gon: detOrden[0]?.GON ?? "", vendedor: detOrden[0]?.CodEmpleado ?? "", destino: detOrden[0]?.CodDestino ?? "", conVenta: detOrden[0]?.ConVenta ?? "",
        quote: detOrden[0]?.Quote ?? ""
      }));
    }
  }, [detOrden]);




  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h5>Actualizar Solicitud</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          {
            state.unauthorized && (
              <strong style={{ color: "var(--danger-color)" }}>{state.msg}</strong>
            )
          }
          {
            state.dataVisible && (
              <>
                <div className="row-flex">
                  {
                    state.isLoading && (
                      <div className="col-7">
                        <strong>Cargando información...</strong>
                      </div>
                    )
                  }
                  <div className="col-7">
                    <strong>Solicitud</strong>
                  </div>
                  <div className="col-7">
                    <strong style={{ color: "var(--danger-color)" }}>Por Solicitar</strong>
                  </div>
                  <div className="col-7">
                    <strong>Orden</strong>
                  </div>
                  <div className="col-7">
                    <strong style={{ color: "var(--danger-color)" }}>Por Solicitar</strong>
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Cliente</strong>
                  </div>
                  <div className="col-42-87">
                    <span className="txt-center"></span>
                  </div>
                  <div className="col-7">
                    <strong>Quote #</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      type="text"
                      name="quote"
                      value={stateForm.quote}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, quote: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Etapa</strong>
                  </div>
                  <div className="col-7">
                    <strong style={{ color: "var(--danger-color)" }}>Por generar solicitud</strong>
                  </div>
                  <div className="col-7">
                    <strong>GON</strong>
                  </div>
                  <div className="col-7">
                    <input
                      className="input-text-control"
                      type="text"
                      name="GON"
                      value={stateForm.gon}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, gon: target.value }))}
                      autoComplete="off" />
                  </div>
                  <div className="col-7">
                    <strong>Vendedor</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="salesPer"
                      value={stateForm.vendedor}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, vendedor: target.value }))}>
                      <option value=""></option>
                      {
                        employees && employees.map((emp) => (
                          <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Destino</strong>
                  </div>
                  <div className="col-42-87">
                    <select
                      name="destino"
                      value={stateForm.destino}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, destino: target.value }))}>
                      <option value=""></option>
                      {
                        destino && destino.map((des) => (
                          <option key={des.CodDestino} value={des.CodDestino}>{des.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-7">
                    <strong>POA</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      type="text"
                      name="poa"
                      value={stateForm.poa}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, poa: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Condición venta</strong>
                  </div>
                  <div className="col-82-16">
                    <textarea
                      className="textarea-description"
                      name="condVent"
                      value={stateForm.conVenta}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, conVenta: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>
                <hr className="m-y-small" />
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Comentario</strong>
                  </div>
                  <div className="col-82-16">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={stateForm.comentario}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Cantidad</th>
                        <th>Equipo</th>
                        <th>Usuario Final</th>
                        <th>Vendedor</th>
                        <th>Precio $</th>
                        <th>Total $</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        detOrden && detOrden.map((det) => (
                          <tr key={det.CodDetOrden}>
                            <td className="txt-center">{det.Cantidad}</td>
                            <td>{det.NomEquipo}</td>
                            <td>
                              <Link className="link-tag" to={`/customers/customer-detail/${det.CodCliente ?? ""}`}>{det.NomCliente}</Link>
                            </td>
                            <td>{det.NomEmpleado}</td>
                            <td>
                              <span className="completed">{formatCurrency(det.NTD, "USD")}</span>
                            </td>
                            <td>
                              <span className="completed">{formatCurrency(det.NTD * det.Cantidad, "USD")}</span>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </>
            )
          }
        </div>
        <div className="modal-footer j-content-space-around">
          {
            state.dataVisible && (
              <button className="btn-success" onClick={onHandleSendOrder}>{state.loading ? "Enviando..." : "Enviar"}</button>
            )
          }
          <button className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};