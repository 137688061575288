
import React, { useEffect, useState } from "react";

// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// REACT router dom importation
import { Link, useLocation, useNavigate } from "react-router-dom";

// Url importation
import { urls } from "../../api-urls/api-urls";

// Global functions
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { useTranslation } from "react-i18next";


export const AdministrativeApprovals = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translatation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false });

  // DESTRUCTURING DATA SERVER
  const { aprobAdmist } = dataServer && dataServer;

  // useEffect para traer los datos del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setState({ ...state, isLoading: true });
        const response = await fetch(`${urls.administrativeAppro}?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          return setState({ ...state, dataVisible: true });
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          return setState({ ...state, dataVisible: false, unauthorized: true });
        } else if (response.status === 500) {
          return setState({ ...state, dataVisible: false, serverError: true });
        }
      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [language]);

  // console.log(aprobAdmist)

  return (
    <>
      {/* Loading components */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">

        {
          aprobAdmist && aprobAdmist.length > 0 ? (
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>{t("RequestPartsView.soli")}</th>
                    <th>{t("RequestPartsView.id_serv")}</th>
                    <th>{t("RequestPartsView.fecha")}</th>
                    <th>{t("RequestPartsView.solicitante")}</th>
                    <th>{t("RequestPartsView.cliente")}</th>
                    <th>{t("RequestPartsView.equip")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    aprobAdmist && aprobAdmist.map((solAdm) => (
                      <tr key={solAdm.codeIndex}>
                        <td><Link className="link-tag" to={`/warehouse/administrative-approval/approval-detail/${solAdm.CodSolRepuesto}`}>{solAdm.CodSolRepuesto}</Link></td>
                        <td><Link className="link-tag" to={`/service/service-call/${solAdm.CodServicio}`}>{solAdm.CodServicio}</Link></td>
                        <td>{renderDate(solAdm.FecIngreso, language)}</td>
                        <td>{solAdm.NomEmpleado}</td>
                        <td className="col-4"><Link className="link-tag" to={`/customers/customer-detail/${solAdm.CodCliente}`}>{solAdm.NomCliente}</Link></td>
                        <td className="col-3"><Link className="link-tag" to={`/equipments/equipment-detail/${solAdm.CodEquipo}`}>{solAdm.equipment}</Link></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          ) : (<div className="m-y-small"><strong>{language === "en" ? "There are no pending administrative approvals." : "No hay pendientes en aprobación administrativa."} &#128064;</strong></div>)
        }
      </div>
    </>
  );
};