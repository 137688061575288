import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { Error403View } from "../error-403-view/Error403View";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { SearchSeguimiento } from "../../components/logistic-components/SearchSeguimiento";

// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// React translation
import { useTranslation } from "react-i18next";



export const SeguimientoLogistica = () => {

  // LANGUAGE
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // Etapas que se pueden actualizar
  const STEP_UPDATE = [20, 30, 31, 36, 37, 38, 39];


  // GET useSearchParams
  const [params] = useSearchParams();


  // STATE DEFINITION
  const [state, setState] = useState({ isLoading: false, dataVisible: false, errorServer: false, unauthorized: false, showModal: false, showBtnAdd: false, showModalUpdate: false, loadingData: false, modalSearch: false, total: 0, moreData: false });
  const [orderList, setOrderList] = useState([]); // para setear en el estado las ordenes
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, succes: false });
  const [stateInput, setStateInput] = useState({ soNumber: "", action: "", comment: "", fecDespa: "", fecLlega: "", guiaDesp: "", carrier: "" }); // estado para manejar los datos del formulario para actualizar las partes
  const [orderUpdate, setOrderUpdate] = useState([]);
  const [controller, setController] = useState(null); // estado para cargar el controlador para abortar la peticion al servidor


  const pageSize = 20;

  // funcion para setear el estado del hijo en el padre
  const onSetNewState = (newList = [], total = 0, moreD = false, page) => {
    setOrderList(newList);
    setCurrentPage(page);
    setState((val) => ({ ...val, moreData: moreD, total }));
  };


  // funcion para modificar el valor del array de las partes
  const onHandleChangeVal = (newVal = 0, codSol = 0, codDet = 0, cantidad = 0) => {
    if (isNaN(newVal)) {
      return;
    }

    if (Number(newVal) > cantidad) {
      return
    }

    setOrderUpdate((val) => {
      return val.map((newOrd) => {
        if (codSol === newOrd.CodSolRepuesto && codDet === newOrd.CodDetSolRepuesto) {
          return { ...newOrd, CantMover: newVal };
        }
        return newOrd;
      });
    });
  };


  // Funcion para colocar en true al mover una parte
  const onHandleChangeMover = (index, newVal) => {
    const updateMover = [...orderList];

    updateMover[index].Mover = newVal;

    const ordenesMover = orderList.filter((ord) => ord.Mover);

    const lengthOrders = ordenesMover.length;

    if (lengthOrders > 0) {
      setState((val) => ({ ...val, showBtnAdd: true }));
    } else {
      setState((val) => ({ ...val, showBtnAdd: false }));
    }

    if (lengthOrders > 1) {

      for (let i = 0; i < lengthOrders; i++) {
        if (ordenesMover[i]?.IdEtapa !== ordenesMover[0]?.IdEtapa) {
          setNotification((val) => ({ ...val, show: true, msg: "Solo puede seleccionar las piezas que correspondan a la misma etapa", errorNoti: true }));
          setState((val) => ({ ...val, showBtnAdd: false }));
          return;
        }
      }

    }
    // Actualizar el estado de las ordenes que se moveran
    setOrderUpdate(ordenesMover);


    setOrderList(updateMover);
  };


  // ABRIR EL MODAL DE BUSQUEDA
  const onShowModalSearch = () => {
    setState((val) => ({ ...val, modalSearch: !state.modalSearch }));
  };


  // Funcion para realizar la peticion al servidor
  const fethDataServer = async (action = "") => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getFollowUpOrders}?zona=${params.get("nom_area") ?? ""}&etapa=${params.get("nom_step") ?? ""}&suplidor=${params.get("nom_suppl") ?? ""}&numOrden=${params.get("num_ord") ?? ""}&numPart=${params.get("num_part") ?? ""}&soNumber=${params.get("num_so") ?? ""}&guiaDesp=${params.get("num_tracking") ?? ""}&fecDesp=${params.get("fec_desp") ?? ""}&division=${params.get("nom_divi") ?? ""}&linea=${params.get("nom_lin") ?? ""}&modalidad=${params.get("nom_moda") ?? ""}&cliente=${params.get("nom_cli") ?? ""}&workflow=${params.get("workflow") ?? ""}&language=${language}&page=${currentPage}&pageSize=${pageSize}&action=1`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setCurrentPage(currentPage + 1);
        if (data?.orders.length < pageSize) {
          setCurrentPage(1);
        }

        if (action === "1") { // primer render
          setOrderList([...orderList].concat(data?.orders));
        } else { // segundo render
          setOrderList([]);
          setOrderList(data?.orders);
        }


        setState((val) => ({ ...val, dataVisible: true, moreData: data?.orders.length < pageSize, total: data?.Total }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        // esto es si el usuario no tiene permisos para ver esto
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: true }));
        // no se encontró nada
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      } else if (response.status === 503) {
        controller.abort();
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect declaration
  useEffect(() => {

    fethDataServer("1");

    // Cambiar el titulo del documento
    document.title = language === "es" ? "Seguimiento - Logística" : "Follow Up - Logistic";

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);


  // useEffect para solicitar nuevamente la informacion al servidor
  useEffect(() => {
    if (notification.succes) {

      fethDataServer("2");

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [notification.succes]);


  // useEffect para limpiar el success
  useEffect(() => {
    if (notification.succes) {
      setNotification((val) => ({ ...val, succes: false }));
    }
  }, [notification.succes]);


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, succes: false });
    }, 6000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);



  // Funcion para el manejo del formulario para actualizar las piezas
  const onUpdateOrder = async (event) => {
    event.preventDefault();

    if (stateInput.action === "") {
      return setNotification((val) => ({ ...val, show: true, msg: t("TableParts.seleccionar_accion"), errorNoti: true }));
    }

    const finalObj = { stateInput, orderUpdate };

    try {
      setState((val) => ({ ...val, loadingData: true }));
      const response = await fetch(urls.getFollowUpOrders, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {

        setState((val) => ({ ...val, showBtnAdd: false }));
        setOrderUpdate([]); // actualizar el array de las ordenes a actualizar
        setState((val) => ({ ...val, showModalUpdate: false }));
        setStateInput({ soNumber: "", action: "", comment: "", fecDespa: "", fecLlega: "", guiaDesp: "", carrier: "" });
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, succes: true }));
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

      setState((val) => ({ ...val, loadingData: false }));

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {
        // unauthorized component
        state.unauthorized && (<Error403View />)
      }
      {/* Notificacion component */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)}
      {/* MODAL PARA FILTRO */}
      {
        state.modalSearch && (
          <SearchSeguimiento onClose={onShowModalSearch} token={getTheToken().token} lang={language} pageSize={pageSize} setNewState={onSetNewState} />
        )
      }
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        <p className="m-y-small">
          <strong className="border-record">{orderList.length} de {state.total} ordenes</strong>
        </p>
        {
          state.dataVisible && (
            <>
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Temp</th>
                      <th>{t("TableParts.orden")}</th>
                      <th>{t("TableParts.solicitud")}</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.cliente")}</th>
                      <th>{t("TableParts.suplidor")}</th>
                      <th>{t("TableParts.datos")}</th>
                      <th>{t("TableParts.etapa")}</th>
                      <th>Sel</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      orderList && orderList.map((order, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: 'center' }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <span className={renderTemperature(order.Prioridad)}></span>
                            </div>
                          </td>
                          <td className="txt-center">
                            <Link className="link-tag" to={`/logistic/order-detail-reader/${order.NumOrden}`}>{order.NumOrden}</Link>
                          </td>
                          <td className="txt-center">
                            <Link className="link-tag" to={`/request-part-detail/${order.CodSolRepuesto}`}>{order.CodSolRepuesto}</Link>
                          </td>
                          <td style={{ textAlign: 'center' }}>{order.Cantidad}</td>
                          <td>
                            <Link className="link-tag" to={`/part-detail/${order.CodDetSolRepuesto}/${order.CodSolRepuesto}`}>{order.NumParte}</Link>
                          </td>
                          <td>{order.Descripcion}</td>
                          <td>{order.NomCliente}</td>
                          <td>{order.NomProveedor}</td>
                          <td className="col-7">
                            <span style={{ display: 'block' }}><strong>{t("TableParts.precio")}:</strong> {formatCurrency(order.Precio, 'USD')}</span>
                            <span style={{ display: 'block' }}><strong>WorkFlow:</strong> {order.WorkFlow}</span>
                            <span style={{ display: 'block' }}>{order.SO !== null && (<><strong>SO/SOA:</strong> {order.SO}</>)}</span>
                            <span style={{ display: 'block' }}>{order.GuiDespacho !== null && (<><strong>{t("RequestPartsView.guia")}:</strong> {order.GuiDespacho}</>)}</span>
                            <span style={{ display: 'block' }}>{order.FecDespacho !== null && (<><strong>{t("RequestPartsView.fec_despa_act")}:</strong> {renderDate(order.FecDespacho, language)}</>)}</span>
                          </td>
                          <td>{order.NomEtapa}</td>
                          <td className="txt-center">
                            {order.IdEtapa !== 99 && (
                              <input
                                className="input-control-checkbox"
                                type="checkbox"
                                name={`action-${order.CodDetSolRepuesto}`}
                                checked={order.Mover}
                                onChange={({ target }) => onHandleChangeMover(index, target.checked)} />
                            )}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones para el paginado */}
              {
                orderList && orderList.length > 0 && (
                  <div className="row-flex m-y-medium align-c justify-center">
                    {
                      state.moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button type="button" className="btn-primary" onClick={() => fethDataServer("1")}>{t("serviceCard.ver_mas")}</button>
                    }
                  </div>
                )
              }

              {/* Button de search */}
              <ButtonSearch onOpenFilter={onShowModalSearch} />

              {/* Boton para agregar las partes */}
              {
                state.showBtnAdd && (
                  <div className="btn-add-part-container">
                    <button className="btn-add-part" onClick={() => setState((val) => ({ ...val, showModalUpdate: true }))}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </button>
                  </div>
                )
              }

              {/* Modal para actualizar */}
              {
                state.showModalUpdate && (
                  <div className="background-modal">
                    {/* Mostrar la etapa de guia de despacho */}
                    {
                      STEP_UPDATE.includes(orderUpdate[0]?.IdEtapa) && ( // esto es para que no se actualice una parte que no esté en la etapa de orden de compra
                        <form className="container-select-customer" onSubmit={onUpdateOrder}>
                          <div className="modal-header">
                            <h5 className="txt-center m-y-small" style={{ color: 'var(--first-color)' }}>{t("TableParts.partes_actualizar")}</h5>
                            <button type="button" className="btn-close" onClick={() => setState((val) => ({ ...val, showModalUpdate: false }))}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                              </svg>
                            </button>
                          </div>
                          {/* FILA */}
                          <div className="modal-body">
                            <div className="row-flex">
                              <div className="col-4"><strong>{t("RequestPartsView.accion")}</strong></div>
                              <div className="col-3">
                                <select
                                  name="action"
                                  value={stateInput.action}
                                  onChange={({ target }) => setStateInput((val) => ({ ...val, action: target.value }))}>
                                  <option value=""></option>
                                  <option value="0">{t("RequestPartsView.comentario")}</option>
                                  {
                                    orderUpdate[0]?.IdEtapa === 36 && ( // fecha de despacho
                                      <>
                                        <option value="1">{t("RequestPartsView.transito")}</option>
                                        <option value="2">{t("RequestPartsView.guia_desp")}</option>
                                      </>
                                    )
                                  }
                                  {
                                    orderUpdate[0]?.IdEtapa === 38 && ( // nacionalizacion
                                      <>
                                        <option value="1">{t("RequestPartsView.por_lleg_alma")}</option>
                                        <option value="2">{t("RequestPartsView.transito")}</option>
                                      </>
                                    )
                                  }
                                  {
                                    orderUpdate[0]?.IdEtapa === 30 && ( // acuse de recibo
                                      <>
                                        <option value="1">{t("RequestPartsView.guia_desp")}</option>
                                        <option value="2">{t("RequestPartsView.recha_parte")}</option>
                                        <option value="3">{t("RequestPartsView.compra_local")}</option>
                                        <option value="4">{t("RequestPartsView.orden_compra")}</option>
                                      </>
                                    )
                                  }
                                  {
                                    orderUpdate[0]?.IdEtapa === 31 && ( // guia de despacho
                                      <>
                                        <option value="1">{t("RequestPartsView.fecha_desp")}</option>
                                        <option value="2">{t("RequestPartsView.acu_recibo")}</option>
                                      </>
                                    )
                                  }
                                  {
                                    orderUpdate[0]?.IdEtapa === 37 && ( // en transito
                                      <>
                                        <option value="1">{t("RequestPartsView.nacionali")}</option>
                                        <option value="2">{t("RequestPartsView.fecha_desp")}</option>
                                      </>
                                    )
                                  }
                                </select>
                              </div>
                            </div>

                            {/* FILA condicional */}
                            {
                              orderUpdate[0]?.IdEtapa === 31 && ( // guia de despacho
                                <>
                                  {/* FILA */}
                                  <div className="row-flex">
                                    {/* Guia de despacho */}
                                    <div className="col-4"><strong>{t("RequestPartsView.guia_desp")}</strong></div>
                                    <div className="col-3">
                                      <input
                                        className="input-text-control"
                                        type="text"
                                        name="tracking-num"
                                        value={stateInput.guiaDesp}
                                        onChange={({ target }) => setStateInput((val) => ({ ...val, guiaDesp: target.value }))} />
                                    </div>
                                  </div>
                                  {/* FILA */}
                                  <div className="row-flex">
                                    {/* Carrier */}
                                    <div className="col-4"><strong>Carrier</strong></div>
                                    <div className="col-3">
                                      <input
                                        className="input-text-control"
                                        type="text"
                                        name="carrier"
                                        value={stateInput.carrier}
                                        onChange={({ target }) => setStateInput((val) => ({ ...val, carrier: target.value }))} />
                                    </div>
                                  </div>
                                </>
                              )
                            }

                            {/* FILA condicional */}
                            {
                              orderUpdate[0]?.IdEtapa === 30 && ( // acuse de recibido
                                <div className="row-flex">
                                  {/* SO */}
                                  <div className="col-4"><strong>SO / SOA</strong></div>
                                  <div className="col-3">
                                    <input
                                      className="input-text-control"
                                      type="text"
                                      name="so"
                                      value={stateInput.soNumber}
                                      onChange={({ target }) => setStateInput((val) => ({ ...val, soNumber: target.value }))}
                                      autoComplete="off" />
                                  </div>
                                </div>
                              )
                            }

                            {/* FILA Condicional */}
                            {
                              orderUpdate[0]?.IdEtapa === 36 && ( // fecha de despacho
                                <>
                                  {/* FILA */}
                                  <div className="row-flex">
                                    {/* Fecha de despacho */}
                                    <div className="col-4"><strong>{t("RequestPartsView.fecha_desp")}</strong></div>
                                    <div className="col-3">
                                      <input
                                        className="input-date-styles"
                                        type="date"
                                        name="ship-date"
                                        value={stateInput.fecDespa}
                                        onChange={({ target }) => setStateInput((val) => ({ ...val, fecDespa: target.value }))} />
                                    </div>
                                  </div>
                                  {/* FILA */}
                                  <div className="row-flex">
                                    {/* Fecha estimada de llegada */}
                                    <div className="col-4"><strong>{t("RequestPartsView.fec_estim_lle")}</strong></div>
                                    <div className="col-3">
                                      <input
                                        className="input-date-styles"
                                        type="date"
                                        name="arrival-date"
                                        value={stateInput.fecLlega}
                                        onChange={({ target }) => setStateInput((val) => ({ ...val, fecLlega: target.value }))} />
                                    </div>
                                  </div>
                                </>
                              )
                            }

                            {/* FILA */}
                            <div className="row-flex">
                              {/* Comentario */}
                              <div className="col-4"><strong>{t("RequestPartsView.comentario")}</strong></div>
                              <div className="col-75">
                                <textarea
                                  className="textarea-description"
                                  name="comment"
                                  value={stateInput.comment}
                                  onChange={({ target }) => setStateInput((val) => ({ ...val, comment: target.value }))}
                                  autoComplete="off" />
                              </div>
                            </div>

                            {/* Tabla para mostrar las partes */}
                            <div className="table-container-mobile m-y-small">
                              <table className="table">
                                <thead className="table-head">
                                  <tr>
                                    <th>{t("TableParts.orden")}</th>
                                    <th>{t("TableParts.canti")}</th>
                                    <th>{t("TableParts.parte")}</th>
                                    <th>{t("TableParts.descrip")}</th>
                                    <th>{t("equipmentView.canti")}</th>
                                  </tr>
                                </thead>
                                <tbody className="table-body">
                                  {
                                    orderUpdate && orderUpdate.map((ord, index) => (
                                      <tr key={index + 1}>
                                        <td className="txt-center">{ord.NumOrden}</td>
                                        <td className="txt-center">{ord.Cantidad}</td>
                                        <td>
                                          <Link className="link-tag">{ord.NumParte}</Link>
                                        </td>
                                        <td>{ord.Descripcion}</td>
                                        <td>
                                          <input
                                            className="input-text-control txt-center"
                                            type="text"
                                            name={`cantSep-${ord.CodSolRepuesto}-${ord.CodDetSolRepuesto}`}
                                            value={ord.CantMover}
                                            onChange={({ target }) => onHandleChangeVal(target.value, ord.CodSolRepuesto, ord.CodDetSolRepuesto, ord.Cantidad)} />
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>


                          {/* Botones de acción */}
                          <div className="modal-footer j-content-space-around m-y-medium">
                            <button
                              type="submit"
                              className="btn-primary"
                              disabled={state.loadingData}>{state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.guar")}</button>
                            <button type="reset" className="btn-secondary" onClick={() => setState((val) => ({ ...val, showModalUpdate: false }))}>{t("RequestPartsView.cerrar")}</button>
                          </div>

                        </form>
                      )
                    }
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </>
  );
};