// este componente es para cargar los reportes de servicio

import { useState } from "react";


// urls imports
import { urls } from "../../api-urls/api-urls";

// global function import
import { getTheToken } from "../../global-functions/globalFunctions";



export const UploadServReportComp = ({ codRepServ, onSendValue, onNotify }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // state definition
  const [servReportFile, setServReportFile] = useState(null);

  console.log(servReportFile)


  // funcion para cargar el archivo
  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    setServReportFile(file);
  };

  // funcion para cerrar el modal y enviar el valor al padre
  const onHandleCloseModal = (value) => {
    onSendValue(value);
  };


  // funcion para manejar el envio del formulario
  const onHandleUploadDoc = async (event) => {
    event.preventDefault();

    try {
      if (servReportFile) {
        const formData = new FormData();

        formData.append("file", servReportFile);

        // se envia el archivo al servidor
        const response = await fetch(`${urls.serviceReport}?codRepServ=${codRepServ}&language=${language}`, {
          method: "POST",
          headers: { "Authorization": getTheToken().token },
          body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
          setServReportFile(null);
          onNotify(data?.msg, false, true, true);
        } else if (404) {
          onNotify(data?.msg, true, false, false);
        }

      } else {
        // no hay nada
        onNotify("No se ha cargado ningún archivo.", true, false, false);
      }

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <div className="container-modal-upload-docs" onClick={(event) => event.stopPropagation()}>
        <form onSubmit={onHandleUploadDoc}>
          <div className="row-flex">
            <div className="col-6">
              <strong>Documento a subir</strong>
            </div>
            <div className="col-6 text-hidden-over">
              <input
                type="file"
                name="file"
                accept=".pdf, .jpg"
                onChange={handleFileChange} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-6"><strong>Documentos soportados</strong></div>
            <div className="col-6"><span className="span txt-muted">PDF | JPG</span></div>
          </div>
          <div className="row-flex j-content-space-around m-y-small">
            <button type="submit" className="btn-primary">Subir</button>
            <button type="reset" className="btn-secondary" onClick={() => onHandleCloseModal(false)}>Cerrar</button>
          </div>
        </form>
      </div>
    </>
  );
};