import React from "react";

// React translation importation
import { useTranslation } from "react-i18next";

// React router dom importation
import { useNavigate } from "react-router-dom";


// Slogan importation
import SloganEn from "../../assets/slogan-en.png";
import SloganEs from "../../assets/slogan-es.png";



export const Error404Comp = ({ msgPrin, navigateTo }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  return (
    <>
      <div className="container data-display">
        <div className="cont-error-404 m-y-medium row-flex align-c" style={{ flexDirection: 'column' }}>
          <div className="col-58">
            <h1 className="txt-center" style={{ color: 'var(--first-color)' }}>{msgPrin}</h1>
            <p className="m-y-small txt-center"><strong className="required">{t("RequestPartsView.no_debe_error_404")}</strong></p>
          </div>
          <div className="row-flex justify-center m-y-medium">
            <button className="btn-primary" onClick={() => navigate(navigateTo)}>{t("navigationMenu.backBtn")}</button>
          </div>
        </div>
        {/* SLOGAN */}
        <div className="container-slogan anim-cont-slogan">
          {language === "es" ? <img style={{ opacity: '0.7' }} className="slogan" src={SloganEs} alt="Slogan Es" /> : <img style={{ opacity: '0.7' }} className="slogan" src={SloganEn} alt="Slogan En" />}
        </div>
      </div>
    </>
  );
};