// vista para crear los componentes de los equipos

import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";


// react router dom importation
import { useNavigate, useParams } from "react-router-dom";

// url imports
import { urls } from "../../api-urls/api-urls";

// Global function import
import { getTheToken } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";
import { AddComponent } from "../../components/serviceComponents/AddComponent";



export const ServiceEquipComponents = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useParams
  const { codServ } = useParams();


  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data detl servidor
  const [componentState, setComponentState] = useState([]); // estado para cargar los componentes
  const [controller, setController] = useState(null); // estado para abortar la solicitud al servidor
  const [stateForm, setStateForm] = useState({ fecInsta: "", serial: "", contact: "", nomContact: "", cargo: "", tecInsta: "", gerenServ: "" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, modalComp: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: "" });


  // destructuring data server
  const { equipInfo, contact, servEmplo, coordinador, components } = dataServer && dataServer;



  // funcion para someter el formulario al servidor
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    const finalObj = { stateForm, componentState };

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getDataCA}/${codServ}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para cambiar los valores para la tabla de componentes
  const onHandleChangeVal = (newVal, type = 0, codEqui = 0, codIden = 0) => {
    switch (type) {
      case 1: // cambiar los valores de cantidad
        if (isNaN(newVal)) {
          return;
        }
        setComponentState((val) => {
          return val.map((comp) => {
            if (codEqui === comp.CodEquipo && codIden === comp.CodIdentificador) {
              return { ...comp, Cantidad: newVal };
            }
            return comp;
          });
        });
        break;
      case 2: // cambiar los valores de modelo
        setComponentState((val) => {
          return val.map((comp) => {
            if (codEqui === comp.CodEquipo && codIden === comp.CodIdentificador) {
              return { ...comp, Modelo: newVal };
            }
            return comp;
          });
        });
        break;
      case 3: // cambiar los valores del serial
        setComponentState((val) => {
          return val.map((comp) => {
            if (codEqui === comp.CodEquipo && codIden === comp.CodIdentificador) {
              return { ...comp, Serial: newVal };
            }
            return;
          });
        });
        break;
    }
  };


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getDataCA}/${codServ}/search?language=${language}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [codServ]);


  // useEffect para cargar la informacion en el estado
  useEffect(() => {
    if (equipInfo && equipInfo.length > 0) {
      const FEC_INSTA = equipInfo[0]?.FecInstalacion ? equipInfo[0]?.FecInstalacion.slice(0, 10) : "";

      setStateForm((val) => ({
        ...val, fecInsta: FEC_INSTA, serial: equipInfo[0]?.SerEquipo ?? "", nomContact: equipInfo[0]?.Contacto ?? "", cargo: equipInfo[0]?.Cargo ?? "",
        tecInsta: equipInfo[0]?.CodEmpleado, gerenServ: equipInfo[0]?.CodEmpleadoF3
      }));
    }
  }, [equipInfo]);


  // useEffect para filtrar los contactos
  useEffect(() => {
    if (contact && contact.length > 0 && stateForm.contact !== "") {
      const findContact = contact && contact.find((con) => con.CodContacto.toString() === stateForm.contact);

      setStateForm((val) => ({ ...val, nomContact: findContact?.Nombre, cargo: findContact?.Cargo }));
    }
  }, [stateForm.contact]);

  // useEffect para cargar los componentes en el estado
  useEffect(() => {
    if (components && components.length > 0) {
      setComponentState(components);
    }
  }, [components]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);




  return (
    <div className="container display-data">
      {
        state.modalComp && (<AddComponent onClose={() => setState((val) => ({ ...val, modalComp: false }))} lang={language} codEq={codServ} token={getTheToken().token} />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <form onSubmit={onHandleSubmit}>
        {/* FILA */}
        <div className="row-flex">
          <div className="col-7"><strong>{t("equipmentView.installDate")}</strong></div>
          <div className="col-3">
            <input
              className="input-date-styles"
              type="date"
              name="fecInstall"
              value={stateForm.fecInsta}
              onChange={({ target }) => setStateForm((val) => ({ ...val, fecInsta: target.value }))} />
          </div>
          <div className="col-7"><strong>Serial</strong></div>
          <div className="col-3">
            <input
              className="input-text-control"
              type="text"
              name="serial"
              value={stateForm.serial}
              onChange={({ target }) => setStateForm((val) => ({ ...val, serial: target.value }))}
              autoComplete="off" />
          </div>
        </div>
        {/* FILA */}
        <div className="row-flex">
          <div className="col-7"><strong>{t("customerView.contact")}</strong></div>
          <div className="col-3">
            <select
              name="contact"
              value={stateForm.contact}
              onChange={({ target }) => setStateForm((val) => ({ ...val, contact: target.value }))} >
              <option value=""></option>
              {
                contact && contact.map((con) => (
                  <option key={con.CodContacto} value={con.CodContacto}>{con.Nombre}</option>
                ))
              }
            </select>
          </div>
        </div>
        {/* FILA */}
        <div className="row-flex">
          <div className="col-7"><strong>{t("equipmentView.contact_install")}</strong></div>
          <div className="col-3">
            <input
              className="input-text-control"
              type="text"
              name="contactInstall"
              value={stateForm.nomContact}
              onChange={({ target }) => setStateForm((val) => ({ ...val, nomContact: target.value }))}
              autoComplete="off" />
          </div>
          <div className="col-7"><strong>{t("equipmentView.cargo")}</strong></div>
          <div className="col-3">
            <input
              className="input-text-control"
              type="text"
              name="cargoInstall"
              value={stateForm.cargo}
              onChange={({ target }) => setStateForm((val) => ({ ...val, cargo: target.value }))}
              autoComplete="off" />
          </div>
        </div>
        {/* FILA */}
        <div className="row-flex">
          <div className="col-7"><strong>{t("equipmentView.tec_install")}</strong></div>
          <div className="col-3">
            <select
              name="tecInstall"
              value={stateForm.tecInsta}
              onChange={({ target }) => setStateForm((val) => ({ ...val, tecInsta: target.value }))}>
              <option value=""></option>
              {
                servEmplo && servEmplo.map((emp) => (
                  <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                ))
              }
            </select>
          </div>
          <div className="col-7"><strong>{t("equipmentView.geren_serv")}</strong></div>
          <div className="col-3">
            <select
              name="gerenSer"
              value={stateForm.gerenServ}
              onChange={({ target }) => setStateForm((val) => ({ ...val, gerenServ: target.value }))}>
              <option value=""></option>
              {
                coordinador && coordinador.map((emp) => (
                  <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                ))
              }
            </select>
          </div>
        </div>

        {/* tabla de los componentes */}
        <div className="table-container-mobile m-y-small">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th></th>
                <th>
                  {t("equipmentView.componente")}
                  <button type="button" style={{ cursor: "pointer", marginLeft: "0.3rem" }} onClick={() => setState((val) => ({ ...val, modalComp: true }))}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                      <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                    </svg>
                  </button>
                </th>
                <th>{t("equipmentView.canti")}</th>
                <th>{t("equipmentView.model")}</th>
                <th>Serial</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                componentState && componentState.map((comp) => (
                  <tr key={comp.CodIdentificador}>
                    <td className="txt-center">
                      <button type="button" className="btn-close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                      </button>
                    </td>
                    <td>{comp.NumParte} {comp.Nombre}</td>
                    <td>
                      <input
                        className="input-text-control txt-center"
                        type="text"
                        name={`Cant${comp.CodIdentificador}`}
                        value={comp.Cantidad ?? ""}
                        onChange={({ target }) => onHandleChangeVal(target.value, 1, comp.CodEquipo, comp.CodIdentificador)}
                        autoComplete="off" />
                    </td>
                    <td>
                      <input
                        className="input-text-control txt-center"
                        type="text"
                        name={`Mod${comp.CodIdentificador}`}
                        value={comp.Modelo ?? ""}
                        onChange={({ target }) => onHandleChangeVal(target.value, 2, comp.CodEquipo, comp.CodIdentificador)} />
                    </td>
                    <td>
                      <input
                        className="input-text-control txt-center"
                        type="text"
                        name={`${comp.CodIdentificador}`}
                        value={comp.Serial ?? ""}
                        onChange={({ target }) => onHandleChangeVal(target.value, 3, comp.CodEquipo, comp.CodIdentificador)}
                        autoComplete="off" />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <div className="row-flex m-y-small j-content-space-around">
          <button type="submit" className="btn-primary">{t("RequestPartsView.guar")}</button>
          <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
        </div>
      </form>
    </div>
  );
};