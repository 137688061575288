// vista para mostrar el detalle de un equipo en la orden de comercial

import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";


// react router dom
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// url importation
import { urls } from "../../api-urls/api-urls";

// global function import
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";




export const CommercialBackLogDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // GET the useParams
  const { codOrden, codOrdenDet } = useParams();


  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ dataVisible: false, isLoading: false, unauthorized: false, });
  const [stateForm, setStateForm] = useState({
    fecCompro: "", esEquip: "", cliente: "", descrip: "", descripLarga: "", vendedor: "", gon: "", poa: "", cantidad: 0, nroParte: "", opp: "", systemID: "",
    ntd: 0, preVenta: 0, cotVenta: "", anticipo: "", porcentaje: "", montoAnticipo: 0, fecAnticipo: "", garanExten: "", ntdContPart: 0, garanServi: 0, manObra: 0, totalGaran: 0, garanExtenText: "", destino: "",
    termPago: "", contacto: "", nomContac: "", telefConta: "", opID: "", origen: "", fecOKTS: "", fecScode: "", ultimaFDP: "", fecSalida: "", fecGuia: "", nroAWB: "",
    nroCajas: 0, grosWeight: 0, montPend: 0, moneda: "", fecAduana: "", agenAduan: "", montCarLocal: 0, montLiqui: 0, montRetirDoc: 0, montAlma: 0, cargAgenAdua: 0, montFlete: 0,
    montSeguro: 0, fecRecep: "", nroFactu: "", comentario: ""
  });
  const [detOrdenState, setDetOrdenState] = useState([]);


  // destructuring data server
  const { detOrden, segDetOrden } = dataServer && dataServer;


  // funcion para solicitar la data al servidor
  const fetchDataServer = async () => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getPurchaseOrderCommercial}/${codOrden}/${codOrdenDet}/search?language=${language}`, {
        headers: {
          "Authorization": getTheToken().token,
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, codOrden, codOrdenDet]);


  // useEffect para cargar la data en el estado
  useEffect(() => {

    if (detOrden && detOrden.length > 0) {

      const FecAntici = detOrden[0]?.FecAnticipo ? detOrden[0]?.FecAnticipo.slice(0, 10) : "";
      const FecOKTS = detOrden[0]?.FecOKTS ? detOrden[0]?.FecOKTS.slice(0, 10) : "";
      const FecScode = detOrden[0]?.FecScode ? detOrden[0]?.FecScode.slice(0, 10) : "";
      const FecGuia = detOrden[0]?.FecGuia ? detOrden[0]?.FecGuia.slice(0, 10) : "";
      const FecAduana = detOrden[0]?.FecAduana ? detOrden[0]?.FecAduana.slice(0, 10) : "";
      const FecCompro = detOrden[0]?.FecCompromiso ? detOrden[0]?.FecCompromiso.slice(0, 10) : "";
      const TieAnticipo = detOrden[0]?.MonAnticipo > 0 ? "0" : "1"

      setStateForm((val) => ({
        ...val, fecCompro: FecCompro, esEquip: detOrden[0]?.EsSuministro, cliente: detOrden[0]?.NomCliente, descrip: detOrden[0]?.Descripcion, descripLarga: detOrden[0]?.DesLarga ?? "", vendedor: detOrden[0]?.CodEmpleado?.toString() ?? "",
        gon: detOrden[0]?.GON ?? "", poa: detOrden[0]?.POA ?? "", cantidad: detOrden[0]?.Cantidad ?? 0, nroParte: detOrden[0]?.NumParte ?? "", opp: detOrden[0]?.OPP ?? "", systemID: detOrden[0]?.NumSistema ?? "", ntd: detOrden[0]?.NTD ?? 0,
        preVenta: detOrden[0]?.Precio ?? 0, cotVenta: detOrden[0]?.NumCot ?? "", anticipo: TieAnticipo ?? "", porcentaje: "", montoAnticipo: detOrden[0]?.MonAnticipo ?? 0, fecAnticipo: FecAntici, garanExten: "", ntdContPart: 0,
        garanServi: 0, manObra: 0, totalGaran: 0, garanExtenText: "", destino: detOrden[0]?.CodDestino?.toString() ?? "", termPago: detOrden[0]?.ConVenta ?? "", contacto: "", nomContac: detOrden[0]?.Contacto ?? "", telefConta: detOrden[0]?.Telefono ?? "",
        opID: detOrden[0]?.WHR ?? "", origen: detOrden[0]?.Origen ?? "", fecOKTS: FecOKTS, fecScode: FecScode, ultimaFDP: "", fecSalida: "", fecGuia: FecGuia, nroAWB: detOrden[0]?.Guia ?? "",
        nroCajas: 0, grosWeight: 0, montPend: 0, moneda: detOrden[0]?.Moneda?.toString() ?? "", fecAduana: FecAduana, agenAduan: "", montCarLocal: 0, montLiqui: 0, montRetirDoc: 0, montAlma: 0, cargAgenAdua: 0, montFlete: 0,
        montSeguro: 0, fecRecep: "", nroFactu: ""
      }));

      setDetOrdenState(detOrden);
    }

  }, [detOrden]);

  return (
    <div className="container display-data">
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // unauthorized component
        state.unauthorized && (<Error403View />)
      }
      {
        state.dataVisible && (
          <form>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Fecha</strong></div>
              <div className="col-7"><span>{renderDate(detOrdenState[0]?.FecIngreso, language)}</span></div>
              <div className="col-7"><strong>Fecha compromiso</strong></div>
              <div className="col-8">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecCompro" />
              </div>
              <div className="col-9"><strong>Orden</strong></div>
              <div className="col-9">
                <Link className="link-tag">{detOrden[0]?.CodOrden}</Link>
              </div>
              <div className="col-8"><strong>Es Equipo</strong></div>
              <div className="col-9">
                <select
                  name="esEquip"
                  value={stateForm.esEquip}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, esEquip: target.value }))}>
                  <option value=""></option>
                  <option value="0">Si</option>
                  <option value="1">No</option>
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>ID Servicio</strong></div>
              <div className="col-7">
                <Link className="link-tag"></Link>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Cliente</strong></div>
              <div className="col-52-19">
                <input
                  className="input-text-control"
                  type="text"
                  name="customer"
                  readOnly
                  value={stateForm.cliente} />
              </div>
              <div className="col-9"><strong>Etapa</strong></div>
              <div className="col-23-61">
                <span className="step-radius-commercial">{detOrdenState[0]?.NomEtapa}</span>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Descripción</strong></div>
              <div className="col-52-19">
                <input
                  className="input-text-control"
                  type="text"
                  name="descrip"
                  value={stateForm.descrip}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, descrip: target.value }))} />
              </div>
              <div className="col-9"><strong>Quote #</strong></div>
              <div className="col-23-61"></div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Descripción larga</strong></div>
              <div className="col-87">
                <textarea
                  className="textarea-description"
                  name="longDescrip"
                  value={stateForm.descripLarga}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, descripLarga: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Vendedor</strong></div>
              <div className="col-41-08">
                <select
                  name="vendedor"
                  value={stateForm.vendedor}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, vendedor: target.value }))}>
                  <option value=""></option>
                  {
                    detOrdenState[0]?.salesEmplo.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-9"><strong>GON</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control"
                  type="text"
                  name="GON"
                  value={stateForm.gon}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, gon: target.value }))} />
              </div>
              <div className="col-8"><strong>POA#</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control"
                  type="text"
                  name="POA"
                  value={stateForm.poa}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, poa: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Cantidad</strong></div>
              <div className="col-7">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="cantidad"
                  value={stateForm.cantidad}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, cantidad: target.value }))} />
              </div>
              <div className="col-7"><strong>Nro. Parte</strong></div>
              <div className="col-8">
                <input
                  className="input-text-control"
                  type="text"
                  name="nroPart"
                  value={stateForm.nroParte}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, nroParte: target.value }))} />
              </div>
              <div className="col-9"><strong>OPP</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control"
                  type="text"
                  name="opp"
                  value={stateForm.opp}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, opp: target.value }))} />
              </div>
              <div className="col-8"><strong>System ID</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control"
                  type="text"
                  name="systemID"
                  value={stateForm.systemID}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, systemID: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>NTD $</strong></div>
              <div className="col-41-08">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="ntd"
                  value={formatCurrency(stateForm.ntd, 'USD')}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, ntd: target.value }))} />
              </div>
              <div className="col-9"><strong>Precio venta</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="salesPrice"
                  value={formatCurrency(stateForm.preVenta, 'USD')}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, preVenta: target.value }))} />
              </div>
              <div className="col-8"><strong># Cot Venta</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control"
                  type="text"
                  name="cotVenta"
                  value={stateForm.cotVenta}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, cotVenta: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Anticipo</strong></div>
              <div className="col-7">
                <select
                  name="anticipo"
                  value={stateForm.anticipo}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, anticipo: target.value }))}>
                  <option value=""></option>
                  <option value="0">Si</option>
                  <option value="1">No</option>
                </select>
              </div>
              <div className="col-7">
                <input
                  style={{ width: "90%" }}
                  className="input-text-control txt-right"
                  type="text"
                  name="porcentaje"
                  value={stateForm.porcentaje}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, porcentaje: target.value }))} />
                <strong style={{ paddingLeft: "0.2rem" }}>%</strong>
              </div>
              <div className="col-8">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montAnti"
                  value={formatCurrency(stateForm.montoAnticipo, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montoAnticipo: target.value }))} />
              </div>
              <div className="col-9"><strong>Fecha</strong></div>
              <div className="col-9">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecAnticipo"
                  value={stateForm.fecAnticipo}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecAnticipo: target.value }))} />
              </div>
            </div>
            <div className="row-flex">
              <div className="col-8"><strong>Garantía extendida</strong></div>
              <div className="col-7">
                <select
                  name="garantExten"
                  value={stateForm.garanExten}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, garanExten: target.value }))}>
                  <option value=""></option>
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>NTD contrato de partes</strong></div>
              <div className="col-7">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="NTDContrPart"
                  value={stateForm.ntdContPart}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, ntdContPart: target.value }))} />
              </div>
              <div className="col-7"><strong>Garantía de servicio (Por partes)</strong></div>
              <div className="col-8">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="garantServ"
                  value={stateForm.garanServi}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, garanServi: target.value }))} />
              </div>
              <div className="col-9"><strong>Mano de obra</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control"
                  type="text"
                  name="manObra"
                  value={stateForm.manObra}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, manObra: target.value }))} />
              </div>
              <div className="col-8"><strong>Total garantía</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="totalGaran"
                  value={stateForm.totalGaran}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, totalGaran: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Garantía extendida</strong></div>
              <div className="col-87">
                <input
                  className="input-text-control"
                  type="text"
                  name="garantExtenTxt"
                  value={stateForm.garanExtenText}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, garanExtenText: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Destino</strong></div>
              <div className="col-28-58">
                <select
                  name="destino"
                  value={stateForm.destino}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, destino: target.value }))}>
                  <option value=""></option>
                  {
                    detOrdenState[0]?.destinos.map((des) => (
                      <option key={des.CodDestino} value={des.CodDestino}>{des.Nombre}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Terminos de pago del cliente</strong></div>
              <div className="col-87">
                <textarea
                  className="textarea-description"
                  name="termPagCustom"
                  value={stateForm.termPago}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, termPago: target.value }))} />
              </div>
            </div>

            <hr className="m-y-small" />

            <h5 className="m-y-small">Cliente que aceptó la cotización</h5>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Contacto</strong></div>
              <div className="col-41-08">
                <select
                  name="contact"
                  value={stateForm.contacto}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, contacto: target.value }))}>
                  <option value=""></option>
                </select>
              </div>
              <div className="col-9"><strong>Nombre</strong></div>
              <div className="col-34-8">
                <input
                  className="input-text-control"
                  type="text"
                  name="name"
                  value={stateForm.nomContac}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, nomContac: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Teléfono</strong></div>
              <div className="col-7">
                <input
                  className="input-text-control"
                  type="text"
                  name="telephone"
                  value={stateForm.telefConta}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, telefConta: target.value }))} />
              </div>
            </div>

            <hr className="m-y-small" />
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>OP ID #</strong></div>
              <div className="col-7">
                <input
                  className="input-text-control"
                  type="text"
                  name="OPID"
                  value={stateForm.opID}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, opID: target.value }))} />
              </div>
              <div className="col-7"><strong>Origen</strong></div>
              <div className="col-34-72">
                <textarea
                  className="textarea-description"
                  name="origen"
                  value={stateForm.origen}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, origen: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Fecha OKTS</strong></div>
              <div className="col-7">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecOKTS"
                  value={stateForm.fecOKTS}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecOKTS: target.value }))} />
              </div>
              <div className="col-7"><strong>Fecha Scode</strong></div>
              <div className="col-8">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecScode"
                  value={stateForm.fecScode}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecScode: target.value }))} />
              </div>
              <div className="col-9"><strong>Ultima FDP</strong></div>
              <div className="col-9">
                <input
                  className="input-date-styles"
                  type="date"
                  name="lastFDP"
                  value={stateForm.ultimaFDP}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, ultimaFDP: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Fecha salida</strong></div>
              <div className="col-7">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecSalida"
                  value={stateForm.fecSalida}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecSalida: target.value }))} />
              </div>
              <div className="col-7"><strong>Fecha guía</strong></div>
              <div className="col-8">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecGuia"
                  value={stateForm.fecGuia}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecGuia: target.value }))} />
              </div>
              <div className="col-9"><strong>Nro AWB/BL</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control"
                  type="text"
                  name="nroAWB"
                  value={stateForm.nroAWB}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, nroAWB: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Nro. Cajas</strong></div>
              <div className="col-7">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="nroCajas"
                  value={stateForm.nroCajas}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, nroCajas: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>Gross Weight Kg</strong></div>
              <div className="col-8">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="grossWeight"
                  value={stateForm.grosWeight}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, grosWeight: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-9"><strong>Monto pendiente</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montPend"
                  value={formatCurrency(stateForm.montPend, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montPend: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-8">
                <select
                  name="moneda"
                  value={stateForm.moneda}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, moneda: target.value }))}>
                  <option value=""></option>
                  <option value="0">US $</option>
                  <option value="1">RD $</option>
                </select>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Fecha aduana</strong></div>
              <div className="col-7">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecAduans"
                  value={stateForm.fecAduana}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecAduana: target.value }))} />
              </div>
              <div className="col-7"><strong>Agente aduanal</strong></div>
              <div className="col-8">
                <input
                  className="input-date-styles"
                  type="text"
                  name="agentAduan"
                  value={stateForm.agenAduan}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, agenAduan: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-9"><strong>Monto cargos locales RD$</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montCargLocal"
                  value={formatCurrency(stateForm.montCarLocal, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montCarLocal: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Monto liquidación (ITBIS + Arancel + tasas) RD$</strong></div>
              <div className="col-7">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montLiqui"
                  value={formatCurrency(stateForm.montLiqui, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montLiqui: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>Monto retiro de documentos RD$</strong></div>
              <div className="col-8">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montRetDocs"
                  value={formatCurrency(stateForm.montRetirDoc, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montRetirDoc: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-9"><strong>Monto almacenaje RD$</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montAlmaRD"
                  value={formatCurrency(stateForm.montAlma, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montAlma: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Cargos agente aduanal RD$</strong></div>
              <div className="col-7">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="carAgenAduRD"
                  value={formatCurrency(stateForm.cargAgenAdua, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, cargAgenAdua: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>Monto flete</strong></div>
              <div className="col-8">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montFlet"
                  value={formatCurrency(stateForm.montFlete, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montFlete: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-9"><strong>Monto seguro</strong></div>
              <div className="col-9">
                <input
                  className="input-text-control txt-right"
                  type="text"
                  name="montSegu"
                  value={formatCurrency(stateForm.montSeguro, "USD")}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, montSeguro: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-8"><strong>Fecha recepción</strong></div>
              <div className="col-7">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecRecep"
                  value={stateForm.fecRecep}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecRecep: target.value }))} />
              </div>
              <div className="col-7"><strong>Nro. Factura</strong></div>
              <div className="col-8">
                <input
                  className="input-text-control"
                  type="text"
                  name="nroFact"
                  value={stateForm.nroFactu}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, nroFactu: target.value }))}
                  autoComplete="off" />
              </div>
            </div>

            <hr className="m-y-small" />
            <div className="row-flex">
              <div className="col-8"><strong>Comentario</strong></div>
              <div className="col-87">
                <textarea
                  className="textarea-description"
                  name="comment"
                  value={stateForm.comentario}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                  autoComplete="off" />
              </div>
            </div>

            <div className="table-container-mobile m-y-small fadeInAnimation seg-service-table">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>Fecha</th>
                    <th>Etapa actual</th>
                    <th>Etapa anterior</th>
                    <th>Responsable cambio de etapa</th>
                    <th>Comentario</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    segDetOrden && segDetOrden.map((seg) => (
                      <tr key={seg.CodSegDetOrden}>
                        <td>{renderDate(seg.FecIngreso, language)}</td>
                        <td>{seg.NomEtaActual}</td>
                        <td>{seg.NomEtapaAnte}</td>
                        <td>{seg.NomEmpIngreso}</td>
                        <td>{seg.Accion}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            <div className="row-flex m-y-small j-content-space-around">
              <button type="button" className="btn-primary">Guardar</button>
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
            </div>
          </form>
        )
      }
    </div>
  );
};