// este componente muestra el resultado del reporte de piezas para exportar

// react imports 
import { useEffect, useState } from "react";


// components importation
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// react router dom import
import { Link, useLocation, useNavigate } from "react-router-dom";

// urls importation
import { urls } from "../../api-urls/api-urls";


// Global functions import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";



export const LogisticPiezasParaExportView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useLocation
  const location = useLocation();

  // GET the useNavigate()
  const navigate = useNavigate();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // estado para colocar la data del servidor
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [state, setState] = useState({ isLoading: false });
  const [controller, setController] = useState(null); // controlador para abortar la peticion del servidor


  // destructuring data server
  const { reportPartExport } = dataServer && dataServer;

  // este reporte es el nro 12
  const body = {
    report: 12,
  };



  const fetchDataServer = async () => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const urlParams = new URLSearchParams(document.location.search);

      const params = {
        division: urlParams.get("division"),
        supplier: urlParams.get("supplier"),
        customer: urlParams.get("customer"),
        line: urlParams.get("line"),
        modality: urlParams.get("modality"),
        tipEquipment: urlParams.get("tipEquipment"),
        model: urlParams.get("model"),
        zone: urlParams.get("zone"),
        fromDate: urlParams.get("fromDate"),
        untilDate: urlParams.get("untilDate"),
        company: urlParams.get("company"),
        nroPart: urlParams.get("nroPart"),
        workflow: urlParams.get("workflow")
      };

      const response = await fetch(`${urls.logisticReports}?division=${params.division}&step=&supplier=${params.supplier}&customer=${params.customer}&line=${params.line}&modality=${params.modality}&tipEquipment=${params.tipEquipment}&model=${params.model}&zone=${params.zone}&fromDate=${params.fromDate}&untilDate=${params.untilDate}&company=${params.company}&nroPart=${params.nroPart}&workflow=${params.workflow}&language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal,
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para limpiar la data del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, []);


  return (
    <>
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // Toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="container">
        <h4 className="m-y-small">{t("General_Comp.reporte_partes_export")}</h4>
        <div className="">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>Item</th>
                <th>{t("TableParts.solicitud")}</th>
                <th>{t("TableParts.tip_orden")}</th>
                <th>{t("TableParts.orden")}</th>
                <th>{t("TableParts.fecha")}</th>
                <th>{t("TableParts.canti")}</th>
                <th>{t("TableParts.parte")}</th>
                <th>{t("TableParts.descrip")}</th>
                <th>WorkFlow</th>
                <th>NTD</th>
                <th>{t("TableParts.cliente")}</th>
                <th>{t("TableParts.zona")}</th>
                <th>{t("TableParts.provincia")}</th>
                <th>Contacto</th>
                <th>{t("TableParts.equip")}</th>
                <th>Serial</th>
                <th>{t("TableParts.report")}</th>
                <th>{t("TableParts.respons")}</th>
                <th>{t("TableParts.ubic")}</th>
                <th>{t("TableParts.coment")}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                reportPartExport && reportPartExport.map((part) => (
                  <tr key={part.CodSeg}>
                    <td>{part.CodSeg}</td>
                    <td>{part.CodSolRepuesto}</td>
                    <td>{part.NomTipAprob}</td>
                    <td>{part.NumOrden}</td>
                    <td>{renderDate(part.FecOrden, language)}</td>
                    <td>{part.Cantidad}</td>
                    <td>
                      <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link>
                    </td>
                    <td>{part.Descripcion}</td>
                    <td>{part.WorkFlow}</td>
                    <td>{part.Precio}</td>
                    <td>{part.Cliente}</td>
                    <td>{part.Zona}</td>
                    <td>{part.Estado}</td>
                    <td>
                      {
                        part.contacto?.map((cont) => (
                          <div key={cont.CodContacto}>
                            <p><strong>Nombre: </strong> <span>{cont.Nombre} {cont.Apellido}</span></p>
                            {
                              cont.ContactTele ? <p><strong>Teléfono: </strong> <span>{cont.ContactTele}</span></p> : null
                            }
                            {
                              cont.CelularContac ? <p><strong>Celular: </strong> <span>{cont.CelularContac}</span></p> : null
                            }
                            {
                              cont.Correo ? <p><strong>Email: </strong> <span style={{ color: "blue", textDecoration: "underline" }}>{cont.Correo}</span></p> : null
                            }
                          </div>
                        ))
                      }
                    </td>
                    <td>{part.NumSistema}</td>
                    <td>{part.Serial}</td>
                    <td>{part.NumReporte}</td>
                    <td>{part.Responsable}</td>
                    <td>{part.Ubicacion}</td>
                    <td style={{ textTransform: "capitalize" }}>{part.Comentario}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};