// componente para cargar la imagen del usuario

import { useState } from "react";
import { urls } from "../../api-urls/api-urls";
import { useNavigate } from "react-router-dom";






export const UploadFotoUser = ({ onClose, token, codEmpl, lang, setNotify }) => {

  // GET the useNavigate
  const navigate = useNavigate();


  // state definition
  const [doc, setDoc] = useState(null); // estado para cargar la foto



  // funcion para someter el formulario
  const onHandleSubmitForm = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.employees}/upload-photo/${codEmpl}/search?language=${lang}`;

    try {
      if (doc) {
        // hay documento
        const formData = new FormData();

        formData.append("file", doc);

        const response = await fetch(END_POINT, {
          method: "POST",
          headers: {
            "Authorization": token
          },
          body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
          onClose();
          setNotify(data?.msg, false);
        } else if (response.status === 404) {
          setNotify(data?.msg, true);
        } else if (response.status === 401) {
          localStorage.removeItem("userInfo");
          return navigate("/");
        }

      } else {
        // no hay documento
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para cargar el documento en el estado
  const onHandleDoc = ({ target }) => {
    const file = target.files[0];

    console.log(file);

    setDoc(file);
  };



  return (
    <div className="background-modal">
      <form onSubmit={onHandleSubmitForm} className="container-select-customer container-filt-empl-dyn-h">
        <div className="modal-header">
          <h4>Cargar foto</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          <div className="row-flex">
            <div className="col-7">
              <strong>Archivo</strong>
            </div>
            <div className="col-4 flex-grow">
              <input
                type="file"
                name="file"
                accept="jpg"
                onChange={onHandleDoc} />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Formatos admitidos</strong>
            </div>
            <div className="col-7 flex-grow">
              <span className="txt-muted">JPG | JPEG | PNG</span>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Peso máximo</strong>
            </div>
            <div className="col-8 flex-grow">
              <span className="txt-muted">10Mb</span>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Preview</strong>
            </div>
            {
              doc && (
                <div className="col-8 flex-grow">
                  <img className="im-employees-view" src={URL.createObjectURL(doc)} alt="preview" />
                </div>
              )
            }
          </div>

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success">Cargar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};