// reporte para las llamadas abiertas


import { useEffect, useState } from "react";


// components import
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";


// react router dom
import { useNavigate } from "react-router-dom";


// react router dom import
import { useSearchParams } from "react-router-dom";


// url imports
import { urls } from "../../api-urls/api-urls";

// global functions import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// import react translation
import { useTranslation } from "react-i18next";





export const LlamadasAbiertas = () => {

  // GET the language
  const lang = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  // State defintion
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data
  const [listCall, setListCall] = useState([]); // estado para setear la lista de llamadas
  const [params, setParams] = useSearchParams();
  const [stateForm, setStateForm] = useState({
    division: params.get("div") ?? "", linea: params.get("lin") ?? "", modalidad: params.get("mod") ?? "", tipEquip: params.get("tipEq") ?? "", modelo: params.get("model") ?? "",
    empresa: params.get("empr") ?? "", zona: params.get("area") ?? "", cliente: params.get("cus") ?? "", desde: params.get("from") ?? "", hasta: params.get("to") ?? ""
  });
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, searching: false, msg: "", moreData: false, total: 0 });
  const [page, setPage] = useState(1);


  // destructuring data server
  const { division, linea, modalidad, tipEquipo, modelo, empresa, zona } = dataServ && dataServ;




  // funcion para traer la data inicial del reporte
  const fetchDataServ = async (action = "") => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.getCommercialReports}?language=${lang}&action=${action}&division=${params.get("div") ?? ""}&linea=${params.get("lin") ?? ""}&modalidad=${params.get("mod") ?? ""}&tipEquip=${params.get("tipEq") ?? ""}&modelo=${params.get("model") ?? ""}&empresa=${params.get("empr") ?? ""}&zona=${params.get("area") ?? ""}&cliente=${params.get("cus") ?? ""}&desde=${params.get("from") ?? ""}&hasta=${params.get("to") ?? ""}&page=${page}&pageSize=50`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        if (action === "1") {
          setDataServ(data);
        } else {
          setState((val) => ({ ...val, total: data?.total, moreData: true, msg: "" }));
          if (data?.serviceCalls.length === 50) {
            setPage(page + 1);
          } else {
            setState((val) => ({ ...val, moreData: false }));
          }
          setListCall([...listCall].concat(data?.serviceCalls));
        }
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };




  // cambiar los parametros de la url
  const onHandleChangeParams = (newVal, type = 0) => {
    switch (type) {
      case 1: // cambiar el valor de la division
        setStateForm((val) => ({ ...val, division: newVal }));
        params.set("div", newVal);
        setParams(params);
        break;
      case 2:
        setStateForm((val) => ({ ...val, linea: newVal }));
        params.set("lin", newVal);
        setParams(params);
        break;
      case 3:
        setStateForm((val) => ({ ...val, modalidad: newVal }));
        params.set("mod", newVal);
        setParams(params);
        break;
      case 4:
        setStateForm((val) => ({ ...val, tipEquip: newVal }));
        params.set("tipEq", newVal);
        setParams(params);
        break;
      case 5:
        setStateForm((val) => ({ ...val, modelo: newVal }));
        params.set("model", newVal);
        setParams(params);
        break;
      case 6:
        setStateForm((val) => ({ ...val, empresa: newVal }));
        params.set("empr", newVal);
        setParams(params);
        break;
      case 7:
        setStateForm((val) => ({ ...val, zona: newVal }));
        params.set("area", newVal);
        setParams(params);
        break;
      case 8:
        setStateForm((val) => ({ ...val, cliente: newVal }));
        params.set("cus", newVal);
        setParams(params);
        break;
      case 9:
        setStateForm((val) => ({ ...val, desde: newVal }));
        params.set("from", newVal);
        setParams(params);
        break;
      case 10:
        setStateForm((val) => ({ ...val, hasta: newVal }));
        params.set("to", newVal);
        setParams(params);
        break;
    }
  };


  const onHandleSearch = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, searching: true }));

      const response = await fetch(`${urls.getCommercialReports}?language=${lang}&action=2&division=${params.get("div") ?? ""}&linea=${params.get("lin") ?? ""}&modalidad=${params.get("mod") ?? ""}&tipEquip=${params.get("tipEq") ?? ""}&modelo=${params.get("model") ?? ""}&empresa=${params.get("empr") ?? ""}&zona=${params.get("area") ?? ""}&cliente=${params.get("cus") ?? ""}&desde=${params.get("from") ?? ""}&hasta=${params.get("to") ?? ""}&page=${page}&pageSize=50`, {
        headers: {
          "Authorization": getTheToken().token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, total: data?.total, moreData: true, msg: "" }));
        setListCall([...listCall].concat(data?.serviceCalls));
        if (data?.serviceCalls.length === 50) {
          setPage(page + 1);
        } else {
          setState((val) => ({ ...val, moreData: false }));
        }
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {

      } else if (response.status === 400) {
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServ("1");

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);




  return (
    <div className="container-reports display-data">
      <button type="button" className="btn-primary m-y-small" onClick={() => navigate("/commercial/reports-view")}>{t("navigationMenu.backBtn")}</button>
      <h3 className="border-bottom">Reportes de las llamadas</h3>
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      <form className="m-y-small" onSubmit={onHandleSearch}>
        <div className="row-flex">
          <div className="col-8">
            <strong>División</strong>
          </div>
          <div className="col-7">
            <select
              name="division"
              value={stateForm.division}
              onChange={({ target }) => onHandleChangeParams(target.value, 1)}>
              <option value=""></option>
              {
                division && division.map((div) => (
                  <option key={div.CodDivision} value={div.CodDivision}>{div.Nombre}</option>
                ))
              }
            </select>
          </div>
          <div className="col-8">
            <strong>Línea</strong>
          </div>
          <div className="col-7">
            <select
              name="line"
              value={stateForm.linea}
              onChange={({ target }) => onHandleChangeParams(target.value, 2)}>
              <option value=""></option>
              {
                linea && linea.filter((filtLin) => filtLin.CodDivision === stateForm.division).map((lin) => (
                  <option key={lin.CodLinea} value={lin.CodLinea}>{lin.NomLinea}</option>
                ))
              }
            </select>
          </div>
          <div className="col-8">
            <strong>Modalidad</strong>
          </div>
          <div className="col-3">
            <select
              name="modality"
              value={stateForm.modalidad}
              onChange={({ target }) => onHandleChangeParams(target.value, 3)}>
              <option value=""></option>
              {
                modalidad && modalidad.filter((filtMod) => filtMod.CodLinea === stateForm.linea).map((mod) => (
                  <option key={mod.CodModalidad} value={mod.CodModalidad}>{mod.NomModalidad}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="row-flex">
          <div className="col-8">
            <strong>Tipo de Equipo</strong>
          </div>
          <div className="col-7">
            <select
              name="tipEquip"
              value={stateForm.tipEquip}
              onChange={({ target }) => onHandleChangeParams(target.value, 4)}>
              <option value=""></option>
              {
                tipEquipo && tipEquipo.filter((filtTipEq) => filtTipEq.CodModalidad === stateForm.modalidad).map((tipEq) => (
                  <option key={tipEq.CodTipEquipo} value={tipEq.CodTipEquipo}>{tipEq.NomTipEquipo}</option>
                ))
              }
            </select>
          </div>
          <div className="col-8">
            <strong>Modelo</strong>
          </div>
          <div className="col-7">
            <select
              name="model"
              value={stateForm.modelo}
              onChange={({ target }) => onHandleChangeParams(target.value, 5)}>
              <option value=""></option>
              {
                modelo && modelo.filter((filtMode) => filtMode.CodTipEquipo.toString() === stateForm.tipEquip).map((model) => (
                  <option key={model.CodModelo} value={model.CodModelo}>{model.Nombre}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="row-flex">
          <div className="col-8">
            <strong>Empresa</strong>
          </div>
          <div className="col-7">
            <select
              name="company"
              value={stateForm.empresa}
              onChange={({ target }) => onHandleChangeParams(target.value, 6)}>
              <option value=""></option>
              {
                empresa && empresa.map((empr) => (
                  <option key={empr.CodEmpresa} value={empr.CodEmpresa}>{empr.Nombre}</option>
                ))
              }
            </select>
          </div>
          <div className="col-8">
            <strong>Zona</strong>
          </div>
          <div className="col-7">
            <select
              name="area"
              value={stateForm.zona}
              onChange={({ target }) => onHandleChangeParams(target.value, 7)}>
              <option value=""></option>
              {
                zona && zona.filter((filtArea) => filtArea.CodEmpresa.toString() === stateForm.empresa).map((area) => (
                  <option key={area.CodZona} value={area.CodZona}>{area.NomZona}</option>
                ))
              }
            </select>
          </div>
          <div className="col-8">
            <strong>Cliente</strong>
          </div>
          <div className="col-3">
            <input
              className="input-text-control"
              type="text"
              name="customer"
              value={stateForm.cliente}
              onChange={({ target }) => onHandleChangeParams(target.value, 8)} />
          </div>
        </div>
        <div className="row-flex">
          <div className="col-8">
            <strong>Desde</strong>
          </div>
          <div className="col-7">
            <input
              className="input-date-styles"
              type="date"
              name="from"
              value={stateForm.desde}
              onChange={({ target }) => onHandleChangeParams(target.value, 9)} />
          </div>
          <div className="col-8">
            <strong>Hasta</strong>
          </div>
          <div className="col-7">
            <input
              className="input-date-styles"
              type="date"
              name="to"
              value={stateForm.hasta}
              onChange={({ target }) => onHandleChangeParams(target.value, 10)} />
          </div>
          <div className="col-45-83 row-flex flex-end">
            <button className="btn-success" type="submit">
              {state.searching ? "Buscando" : "Buscar"}
            </button>
          </div>
        </div>
      </form>

      {
        state.msg.trim() !== "" && (<h5 style={{ color: "var(--danger-color)" }}>{state.msg}</h5>)
      }

      {
        listCall && listCall.length > 0 && (
          <>
            <h5>{listCall.length} de {state.total}</h5>
            <div className="table-container-mobile m-y-small">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>ID Servicio</th>
                    <th>Fecha</th>
                    <th>Equipo</th>
                    <th>Cliente</th>
                    <th>Problema</th>
                    <th>Responsable</th>
                    <th>Etapa</th>
                    <th>Tipo de servicio</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    listCall && listCall.map((serv) => (
                      <tr key={serv.CodServicio}>
                        <td>{serv.CodServicio}</td>
                        <td>{renderDate(serv.FecIngreso, lang)}</td>
                        <td>{serv.NomTipEquipo}</td>
                        <td>{serv.NomCliente}</td>
                        <td>{serv.Problema}</td>
                        <td>{serv.NomEmpleado}</td>
                        <td>{serv.NomEtapa}</td>
                        <td>{ }</td>
                        <td>{serv.Accion}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }

      {
        state.moreData && (
          <div className="row-flex justify-center m-y-small">
            <button type="button" className="btn-primary" onClick={() => fetchDataServ("2")}>Cargar más</button>
          </div>
        )
      }

      {
        !state.moreData && (
          <div className="row-flex justify-center m-y-small">
            <strong style={{ color: "var(--danger-color)" }}>{t("serviceCard.no_mas_datos")}</strong>
          </div>
        )
      }
    </div>
  );
};