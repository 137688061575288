

import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// USE TRANSLATION FROM REACT
import { useTranslation } from "react-i18next";



export const ProjectsDetailView = () => {

  // LANGUAGE
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // Translation
  const [t] = useTranslation("global");

  // use navigate
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // use params
  const { idService } = useParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // para cargar la data del servidor
  const [controller, setController] = useState(null); // Estado para cargar el controlador de abort la peticion al servidor
  const [isLoading, setIsLoading] = useState(false); // para mostrar el loading cuando esté cargando la informacion
  const [stateInputs, setStateInputs] = useState({
    tipoServicio: "", temperatura: "", etapa: "", telefono: "", correo: "", trabajoRealizar: "", comentario: ""
  }); // estado para cargar la informacion del servidor en los inputs

  const STATUSRUNNING = language === "en" ? "Running" : "Ejecutándose";
  const STATUSCOMPLETED = language === "en" ? "Completed" : "Completado";

  // DESTRUCTURING DATA SERVER
  const { commercialProjects, allSteps, segServicio, employeePermission } = dataServer && dataServer;

  // users allow to see this component
  const usersAllow = [1, 6, 10, 3, 5, 12, 7];


  // useEffect para traer la data del servidor
  useEffect(() => {

    const fetchDataServer = async () => {
      const abortController = new AbortController();

      setController(abortController);

      try {
        setIsLoading(true);
        const response = await fetch(`${urls.projectsCommercial}/${idService}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token },
          signal: abortController.signal
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 400) {
          // la peticion no es valida
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

        setIsLoading(false);

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idService, language]);

  // useEffect para cargar la data que se puede modificar en el estado
  useEffect(() => {
    if (commercialProjects && commercialProjects.length > 0) {
      setStateInputs({
        tipoServicio: commercialProjects[0]?.IdTipServicio, temperatura: commercialProjects[0]?.Prioridad,
        etapa: commercialProjects[0]?.IdEtapa, telefono: "", correo: "", trabajoRealizar: commercialProjects[0]?.Problema,
        comentario: ""
      });
    }
  }, [commercialProjects]);


  return (
    <>
      {isLoading && (<LoadingComp />)}

      <>
        <div className="container display-data">
          <form>
            {/* FILA 1 */}
            <div className="row-flex">
              {/* ID SERVICIO */}
              <div className="col-8"><strong>Id. Llamada</strong></div>
              <div className="col-7"><h3>{commercialProjects && commercialProjects[0]?.CodServicio}</h3></div>
              {/* FECHA */}
              <div className="col-10"><strong>Fecha</strong></div>
              <div className="col-9"><span style={{ textDecoration: 'underline' }}>{commercialProjects && renderDate(commercialProjects[0]?.FecIngreso, language)}</span></div>
              {/* NIVEL */}
              <div className="col-10"><strong>Nivel</strong></div>
              <div className="col-9"><span></span></div>
              {/* ETAPA */}
              <div className="col-10"><strong>Etapa</strong></div>
              <div className="col-5">
                <select name="etapa" value={stateInputs.etapa} onChange={({ target }) => setStateInputs({ ...stateInputs, etapa: target.value })}>
                  <option value=""></option>
                  {
                    allSteps && allSteps.map((step) => (
                      <option key={step.IdEtapa} value={step.IdEtapa}>{language === "en" ? step.NombreEn : step.Nombre}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            {/* FILA 2 */}
            <div className="row-flex m-y-small">
              {/* CLIENTE */}
              <div className="col-8"><strong>Cliente</strong></div>
              <div className="col-3"><span>{commercialProjects && commercialProjects[0]?.NomCliente}</span></div>
              {/* TEMPERATURA */}
              <div className="col-8"><strong>Temperatura</strong></div>
              <div className="col-8"></div>
              {/* ESTATUS */}
              <div className="col-10"><strong>Estatus</strong></div>
              <div className="col-7"><span className="status-state">{commercialProjects && commercialProjects[0]?.IdEstatus === 1 ? STATUSRUNNING : STATUSCOMPLETED}</span></div>
            </div>

            {/* FILA 3 */}
            <div className="row-flex">
              {/* EQUIPO */}
              <div className="col-8"><strong>Equipo</strong></div>
              <div className="col-3"><span>{commercialProjects && commercialProjects[0]?.equipment}</span></div>
              {/* VENDEDOR */}
              <div className="col-8"><strong>Vendedor</strong></div>
              <div className="col-3"><span></span></div>
            </div>

            {/* FILA 4 */}
            <div className="row-flex m-y-small">
              {/* USUARIO FINAL */}
              <div className="col-8"><strong>Usuario Final</strong></div>
              <div className="col-3"><Link className="link-tag" to={`/customers/customer-detail/${commercialProjects && commercialProjects[0]?.CodClienteF}`}>{commercialProjects && commercialProjects[0]?.clienteFinal}</Link></div>
              {/* TRABAJO A REALIZAR */}
              <div className="col-8"><strong>Trabajo a Realizar</strong></div>
              <div className="col-34-8"><textarea style={{ textTransform: 'capitalize' }} className="textarea-description" name="trabajoRealizar" value={stateInputs.trabajoRealizar} onChange={({ target }) => setStateInputs({ ...stateInputs, trabajoRealizar: target.value })} /></div>
            </div>

            {/* FILA 5 */}
            <div className="row-flex">
              {/* MODELO */}
              <div className="col-8"><strong>Modelo</strong></div>
              <div className="col-3"><Link className="link-tag">{commercialProjects && commercialProjects[0]?.NomTipEquipo}</Link></div>
            </div>

            {/* FILA 6 */}
            <div className="row-flex m-y-small">
              {/* TIPO DE SERVICIO */}
              <div className="col-8"><strong>Tipo Servicio</strong></div>
              <div className="col-3">
                <select name="tipoServicio" value={stateInputs.tipoServicio} onChange={({ target }) => setStateInputs({ ...stateInputs, tipoServicio: target.value })}>
                  <option value="9">Negociación</option>
                  <option value="10">Pre Instalación</option>
                </select>
              </div>
            </div>

            {/* FILA 7 */}
            <div className="row-flex">
              {/* TELEFONO */}
              <div className="col-8"><strong>Telefono</strong></div>
              <div className="col-3"><input style={{ width: '100%' }} className="input-text-control" type="text" /></div>
              {/* CORREO ELECTRONICO */}
              <div className="col-8"><strong>Correo</strong></div>
              <div className="col-34-8"><input style={{ width: '100%' }} className="input-text-control" type="text" /></div>
            </div>

            {/* FILA 8 */}
            <div className="row-flex m-y-small">
              {/* COMENTARIO */}
              <div className="col-8"><strong>Comentario</strong></div>
              <div className="flex-auto"><textarea className="textarea-description" name="comentario" placeholder={language === "en" ? "Comments..." : "Comentarios..."} /></div>
            </div>

            {/* BOTONES DE ACCION */}
            <div className="row-flex j-content-space-around">
              <button type="submit" className="btn-primary">Guardar</button>
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Cancelar</button>
            </div>

            <h3 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Seguimiento</h3>

            {/* TABLA PARA MOSTRAR EL SEGUIMIENTO */}
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th style={{ borderRadius: '5px 0 0 0' }}>Fecha</th>
                    <th>Estatus</th>
                    <th>Etapa Actual</th>
                    <th>Proxima Etapa</th>
                    <th>Responsable cambio Etapa</th>
                    <th>Responsable del Servicio</th>
                    <th style={{ borderRadius: '0 5px 0 0' }}>Comentarios</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    segServicio && segServicio.map((seg, index) => (
                      <tr key={index}>
                        <td>{renderDate(seg.FecIngreso, language)}</td>
                        <td style={{ textAlign: 'center' }}>{seg.IdEstatus === 1 ? STATUSRUNNING : STATUSCOMPLETED}</td>
                        <td>{seg.etapaActual}</td>
                        <td>{seg.etapaAnterior}</td>
                        <td>{seg.nomEmpCambioEtapa}</td>
                        <td>{seg.nomEmpResponsable}</td>
                        <td style={{ textTransform: 'capitalize' }}>{seg.Accion}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </>
    </>
  );
};