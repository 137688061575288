import React from "react";

// ----> inline styles 
import { Link } from "react-router-dom";



export const ErrorNotification = ({ errorDisplayMsg, url, urlMsg, otherMsg, onClose }) => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  return (
    <>
      <div className="container-modal">
        <div className="container-circle-error">
          <div className="circle-error-noti">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        </div>
        {/* ERROR Msg DISPLAY */}
        <h3 className="m-y-small" style={{ textAlign: 'center', textDecoration: 'underline', color: 'var(--danger-color)' }}>{language === "en" ? "An error ocurred" : "Ocurrió un error"}</h3>
        <div className="p-1 m-y-small">
          <span>{errorDisplayMsg}</span>
          <br />
          {
            url && <span style={{ textDecoration: 'underline' }} className="link-tag">{urlMsg}</span>
          }
          <br />
          <span>{otherMsg}</span>
        </div>
        <div className="row-flex justify-center">
          <button className="btn-secondary" onClick={onClose}>{language === "en" ? "Close" : "Cerrar"}</button>
        </div>
      </div>
    </>
  );
};