
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ErrorNotification } from "../../components/error-notification/ErrorNotification";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { SelectCustomerComp } from "../../components/commercial-orders-comp/SelectCustomerComp";
import { SelectEquipmentComp } from "../../components/commercial-orders-comp/SelectEquipmentComp";


// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// REACT TRANSLATIONS IMPORTATION
import { useTranslation } from "react-i18next";
import { SelectEmployeeComp } from "../../components/equipment-components/SelectEmployeeComp";


export const EquipmentModifyView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // GET params
  const { codEquipo } = useParams();



  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [stateInput, setStateInput] = useState({
    codModelo: "", codCliente: "", alias: "", ubicacion: "", pais: "", provincia: "", ciudad: "", verSoftware: "",
    tecnicoInstaCode: "", tecnicoInstalCode1: "", tecnicoInsta2: "", tecnicoInsta3: "", tecnicoF1: "", tecnicoF2: "",
    gerenteServicio: "", numFactura: "", serial: "", numCarpeta: "", numSistema: "", psiScode: "", GON: "",
    po: "", fecGarProveedor: "", fecGarGlobal: "", fecDespacho: "", fecAduana: "", fecDestCliente: "",
    fecInstalacion: "", contactoInstal: "", cargo: "", fecEOL: "", garExtendida: "", observacion: "",
    ventRepuesto: false, ventManObra: false, proyeccion: false, activo: false, pendiente: false
  });
  const [state, setState] = useState({ isLoading: false, loadingSave: false, dataVisible: false, unauthorized: false, filtCustomer: false, filtModel: false, filtEmpl: false, search: "", input: "" }); // estado general
  const [stateSearch, setStateSearch] = useState({ modelo: "", cliente: "", tecnicoInsta: "", tecnicoInsta1: "", tecnicoInsta2: "", tecnicoInsta3: "", tecnicoF1: "", tecnicoF2: "", gerenteServ: "" });
  const [badRequestError, setBadRequestError] = useState({ show: false, msg: "", urlMsg: "", url: false, otherMsg: "" }); // este estado es para que se muestre cuando ocurra un bad request 400
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, succes: false });


  // DESTRUCTURING DATA SERVER
  const { equipment, cities, states, employees } = dataServer && dataServer;

  // Endpoints
  const END_POINT_CUSTOMER = `${urls.equipments}/${codEquipo}/search?modify=1&sub=1`;
  const END_POINT_MODEL = `${urls.equipments}/${codEquipo}/search?modify=1&sub=2`;
  const END_POINT_EMPLOYEES = `${urls.equipments}/${codEquipo}/search?modify=1&sub=3`;


  // funcion para abrir el modal de los empleados
  const onOpenFiltEmployee = (stateInp = "", stateSear = "") => {
    setState((val) => ({ ...val, filtEmpl: true, search: stateInp, input: stateSear }));
  };

  // FUNCION PARA CERRAR EL MODAL DE LA NOTIFICACION DE ERROR
  const onCloseModalBadRequest = () => {
    setBadRequestError({ show: false, msg: "", urlMsg: "", url: false, otherMsg: "" })
  };

  // funcion para cerrar el modal de los cliente
  const onCloseFiltCustomer = () => {
    setState((val) => ({ ...val, filtCustomer: false }));
  };

  // Funcion para cerrar el modal de los modelos
  const onCloseFiltModels = () => {
    setState((val) => ({ ...val, filtModel: false }));
  };

  // funcion para cerrar el modal de los empleados
  const onCloseFiltEmplo = () => {
    setState((val) => ({ ...val, filtEmpl: false }));
  };

  // funcion para agregar el cliente al estado
  const onAddCustomer = (codCli = "", nombre = "") => {
    setStateInput((val) => ({ ...val, codCliente: codCli }));
    setStateSearch((val) => ({ ...val, cliente: nombre }));
    setState((val) => ({ ...val, filtCustomer: false }));
  };

  // funcion para agregar el model al estado
  const onAddModelo = (codMo = "", nombre = "") => {
    setStateInput((val) => ({ ...val, codModelo: codMo }));
    setStateSearch((val) => ({ ...val, modelo: nombre }));
    setState((val) => ({ ...val, filtModel: false }));
  };

  // funcion para agregar el empleado al estado
  const onAddEmployee = (codEmpl = 0, nombre = "", stateIn = "", stateSear = "") => {
    // tengo que actualizar el codigo del empleado, y el nombre
    setStateInput((val) => ({ ...val, [stateIn]: codEmpl }));
    setStateSearch((val) => ({ ...val, [stateSear]: nombre }));
    setState((val) => ({ ...val, filtEmpl: false }));
  };



  // funcion para solicitar la peticion al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.equipments}/${codEquipo}/search?modify=1`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 400) {
        setBadRequestError({
          show: true,
          msg: language === "en" ?
            "An error occurred while processing the request, the url structure should only be as follows:"
            :
            "Ocurrió un error al procesar la solicitud, la estructura de la url solo debe ser de la siguiente manera:",
          url: true,
          urlMsg: language === "en" ?
            "domain/equipments/equipment-modify/0000"
            :
            "dominio/equipments/equipment-modify/0000",
          otherMsg: language === "en" ?
            "Which means where the equipment number goes should not go special letters or characters."
            :
            "Lo que quiere decir que donde va el número de equipo no deben ir letras o carácteres especiales."
        });
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false }));
        setNotification({ show: true, msg: language === "en" ? "Your are not authorized. You tried to access a page you did not have prior authorization for. If you understand you must have authorization, contact the administrator." : "Usted no tiene previa autorización para ver esta información. Si entiende que debe tenerla contacte al administrador.", errorNoti: true })
      } else if (response.status === 500) {
        // ocurrio un error interno en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServer();

    document.title = language === "en" ? "Modify Equipment - GIS APP" : "Modificar Equipo - GIS APP";

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codEquipo, language, navigate]);


  // useEffect PARA CARGAR LA DATA EN EL ESTADO Y PODER MODIFICARLA
  useEffect(() => {
    if (equipment && equipment.length > 0) {
      setStateInput((val) => ({
        ...val, codModelo: equipment[0]?.CodModelo.toString(), codCliente: equipment[0]?.CodCliente, alias: equipment[0]?.Alias ?? "", ubicacion: equipment[0]?.Ubicacion ?? "", pais: equipment[0]?.CodPais, provincia: equipment[0]?.CodEstado, ciudad: equipment[0]?.CodCiudad, verSoftware: equipment[0]?.VerSoftware ?? "",
        tecnicoInstaCode: equipment[0]?.CodEmpleado.toString() ?? "", tecnicoInstalCode1: equipment[0]?.CodEmpleado1.toString() ?? "", tecnicoInsta2: equipment[0]?.CodEmpleado2.toString() ?? "", tecnicoInsta3: equipment[0]?.CodEmpleado3.toString() ?? "", tecnicoF1: equipment[0]?.CodEmpleadoF1.toString() ?? "", tecnicoF2: equipment[0]?.CodEmpleadoF2.toString() ?? "",
        gerenteServicio: equipment[0]?.CodEmpleadoF3.toString() ?? "", numFactura: equipment[0]?.NumFactura ?? "", serial: equipment[0]?.Serial ?? "", numCarpeta: equipment[0]?.NumCarpeta ?? "", numSistema: equipment[0]?.NumSistema ?? "", psiScode: equipment[0]?.OCG ?? "", GON: equipment[0]?.FDO ?? "",
        po: equipment[0]?.NumOrden ?? "", fecGarProveedor: equipment[0]?.FecGarEmpresa.slice(0, 10), fecGarGlobal: equipment[0]?.FecGarantia.slice(0, 10), fecDespacho: equipment[0]?.FecDespacho.slice(0, 10), fecAduana: equipment[0]?.FecVenezuela.slice(0, 10), fecDestCliente: equipment[0]?.FecAmbiente.slice(0, 10),
        fecInstalacion: equipment[0]?.FecInstalacion.slice(0, 10), contactoInstal: equipment[0]?.Contacto ?? "", cargo: equipment[0]?.Cargo ?? "", fecEOL: equipment[0]?.FecEOL.slice(0, 10), garExtendida: equipment[0]?.ConGarantia ?? "", observacion: equipment[0]?.Observacion ?? "",
        ventRepuesto: equipment[0]?.VenRepuesto, ventManObra: equipment[0]?.VenManObra, proyeccion: equipment[0]?.Proyeccion, activo: equipment[0]?.Activo, pendiente: equipment[0]?.Pendiente
      }));

      setStateSearch((val) => ({ ...val, modelo: equipment[0]?.NomModelo, cliente: equipment[0]?.NomCliente, tecnicoInsta: equipment[0]?.TecInstalador, tecnicoInsta1: equipment[0]?.TecInstalador1 ?? "", tecnicoInsta2: equipment[0]?.TecInstalador2 ?? "", tecnicoInsta3: equipment[0]?.TecInstalador3 ?? "", tecnicoF1: equipment[0]?.TecnicoF1 ?? "", tecnicoF2: equipment[0]?.TecnicoF2 ?? "", gerenteServ: equipment[0]?.GerenteServicio ?? "" }));
    }
  }, [equipment]);



  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, succes: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);


  // FUNCION PARA ACTUALIZAR EL EQUIPO
  const onUpdateEquipment = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loadingSave: true }));
      const response = await fetch(`${urls.equipments}/${codEquipo}/search?language=${language}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": getTheToken().token
        },
        body: JSON.stringify(stateInput)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

      setState((val) => ({ ...val, loadingSave: false }));

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {
        // componente para seleccionar el cliente
        state.filtCustomer && (
          <SelectCustomerComp
            onClose={onCloseFiltCustomer}
            onAddCust={onAddCustomer}
            EndPoint={END_POINT_CUSTOMER}
            HttMethod={"GET"}
            token={getTheToken().token} />
        )
      }

      {
        // componente para seleccionar el modelo
        state.filtModel && (
          <SelectEquipmentComp
            onClose={onCloseFiltModels}
            EndPoint={END_POINT_MODEL}
            onAddModel={onAddModelo}
            token={getTheToken().token}
            HttpMethod={"GET"} />
        )
      }

      {
        // componente para seleccionar los diferentes empleados
        state.filtEmpl && (
          <SelectEmployeeComp
            onClose={onCloseFiltEmplo}
            stateInput={state.search}
            stateSearch={state.input}
            token={getTheToken().token}
            EndPoint={END_POINT_EMPLOYEES}
            onAddEmpl={onAddEmployee} />
        )
      }

      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onUpdateEquipment}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* MODELO */}
                <div className="col-7">
                  <strong>Modelo</strong>
                </div>
                <div className="col-80">
                  <input
                    className="input-text-control"
                    type="text"
                    name="model"
                    readOnly
                    value={stateSearch.modelo}
                    onFocus={() => setState((val) => ({ ...val, filtModel: true }))} />
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-7">
                  <strong>Cliente</strong>
                </div>
                <div className="col-80">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="customer"
                    value={stateSearch.cliente}
                    readOnly
                    onFocus={() => setState((val) => ({ ...val, filtCustomer: true }))} />
                </div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* ALIAS */}
                <div className="col-7">
                  <strong>Alias</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="alias"
                    value={stateInput.alias}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, alias: target.value }))}
                    placeholder={language === "en" ? "Place the alias customer" : "Indique el alias del cliente"} />
                </div>
                {/* UBICACION */}
                <div className="col-7">
                  <strong>Ubicación</strong>
                </div>
                <div className="col-3">
                  <input
                    style={{ width: '100%' }}
                    className="input-text-control"
                    type="text"
                    name="location"
                    value={stateInput.ubicacion}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, ubicacion: target.value }))}
                    placeholder={language === "en" ? "Place the location of the equipment" : "Coloque la ubicación del equipo"} />
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* PAIS */}
                <div className="col-7">
                  <strong>País</strong>
                </div>
                <div className="col-3">
                  <select
                    name="pais"
                    value={stateInput.pais}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, pais: target.value }))}>
                    <option value=""></option>
                    <option value="1">República Dominicana</option>
                    <option value="3">Islas del Caribe</option>
                  </select>
                </div>
                {/* PROVINCIA */}
                <div className="col-7">
                  <strong>Provincia</strong>
                </div>
                <div className="col-3">
                  <select
                    name="provincia"
                    value={stateInput.provincia}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, provincia: target.value }))}>
                    <option value=""></option>
                    {
                      states && states.map((state) => (
                        <option key={state.CodEstado} value={state.CodEstado}>{state.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* CIUDAD */}
                <div className="col-7">
                  <strong>Ciudad</strong>
                </div>
                <div className="col-3">
                  <select
                    name="ciudad"
                    value={stateInput.ciudad}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, ciudad: target.value }))}>
                    <option value=""></option>
                    {
                      cities && cities.map((city) => (
                        <option key={city.CodCiudad} value={city.CodCiudad}>{city.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* VERSION SOFTWARE */}
                <div className="col-7">
                  <strong>Versión Software</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="versionSoft"
                    value={stateInput.verSoftware}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, verSoftware: target.value }))}
                    placeholder={language === "en" ? "Place the version software here if apply" : "Coloque la versión de software aquí si aplica"} />
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* TECNICO INSTALADOR */}
                <div className="col-7">
                  <strong>Técnico Instalador</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="tecInsta"
                    readOnly
                    value={stateSearch.tecnicoInsta}
                    onFocus={() => onOpenFiltEmployee("tecnicoInstaCode", "tecnicoInsta")}
                    placeholder={language === "en" ? "Search the installer engineer" : "Busque el técnico instalador"} />
                </div>
                {/* TECNICO INSTALADOR 1 */}
                <div className="col-7">
                  <strong>Técnico Instalador 1</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="tecniInsta1"
                    value={stateSearch.tecnicoInsta1}
                    readOnly
                    onFocus={() => onOpenFiltEmployee("tecnicoIns", "tecnicoInsta1")}
                    placeholder={language === "en" ? "Search the installer engineer 1" : "Busque el técnico instalador 1"} />
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* TECNICO INSTALADOR 2 */}
                <div className="col-7">
                  <strong>Técnico Instalador 2</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="tecniInsta2"
                    value={stateSearch.tecnicoInsta2}
                    readOnly
                    onFocus={() => onOpenFiltEmployee("tecnicoInsta2", "tecnicoInsta2")}
                    placeholder={language === "en" ? "Search the installer engineer 2" : "Busque el técnico instalador 2"} />
                </div>
                {/* TECNICO INSTALADOR 3 */}
                <div className="col-7">
                  <strong>Técnico Instalador 3</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="tecniInsta3"
                    readOnly
                    value={stateSearch.tecnicoInsta3}
                    onFocus={() => onOpenFiltEmployee("tecnicoInsta3", "tecnicoInsta3")}
                    placeholder={language === "en" ? "Search the installer engineer 3" : "Busque el técnico instalador 3"} />
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* TECNICO F1 */}
                <div className="col-7">
                  <strong>Técnico F1</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="tecniF1"
                    readOnly
                    value={stateSearch.tecnicoF1}
                    onFocus={() => onOpenFiltEmployee("tecnicoF1", "tecnicoF1")}
                    placeholder={language === "en" ? "Search the technical engineer F1" : "Busque el ingeniero técnico F1"} />
                </div>
                {/* TECNICO F2 */}
                <div className="col-7">
                  <strong>Técnico F2</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="tecniF2"
                    readOnly
                    value={stateSearch.tecnicoF2}
                    onFocus={() => onOpenFiltEmployee("tecnicoF2", "tecnicoF2")}
                    placeholder={language === "en" ? "Search the technical engineer F2" : "Busque el ingeniero técnico F2"} />
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* GERENTE DE SERVICIO */}
                <div className="col-7">
                  <strong>Gerente de Servicio</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="gerentServ"
                    readOnly
                    value={stateSearch.gerenteServ}
                    onFocus={() => onOpenFiltEmployee("gerenteServicio", "gerenteServ")}
                    placeholder={language === "en" ? "Search the service manager" : "Busque el gerente de servicio"} />
                </div>
                {/* NUMERO FACTURA */}
                <div className="col-7">
                  <strong>Número de Factura</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="numFact"
                    value={stateInput.numFactura}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, numFactura: target.value }))}
                    placeholder={language === "en" ? "Indicates what is the invoice number" : "Indique cuál es el número de factura"} />
                </div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* SERIAL */}
                <div className="col-7">
                  <strong>Serial</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="serialNum"
                    value={stateInput.serial}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, serial: target.value }))}
                    placeholder={language === "en" ? "which one is the serial number?" : "Cuál es el número de serial?"} />
                </div>
                {/* NUMERO DE CARPETA */}
                <div className="col-7">
                  <strong>Número de Carpeta</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="numCarp"
                    value={stateInput.numCarpeta}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, numCarpeta: target.value }))}
                    placeholder={language === "en" ? "which one is the folder number?" : "Cuál es el número de carpeta?"} />
                </div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* NUMERO DE SISTEMA */}
                <div className="col-7">
                  <strong>Número de Sistema</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="SIDNum"
                    value={stateInput.numSistema}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, numSistema: target.value }))}
                    placeholder={language === "en" ? "which one is the system ID?" : "Cuál es el número de sistema?"} />
                </div>
                {/* NUMERO DE PSI SCODE */}
                <div className="col-7">
                  <strong>PSI Scode</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="psiScode"
                    value={stateInput.psiScode}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, psiScode: target.value }))}
                    placeholder={language === "en" ? "which one is the PSI Scode?" : "Cuál es el PSI Scode?"} />
                </div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex">
                {/* GON */}
                <div className="col-7">
                  <strong>GON</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="GONNum"
                    value={stateInput.GON}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, GON: target.value }))}
                    placeholder={language === "en" ? "which one is the GON number?" : "Cuál es el número de GON?"} />
                </div>
                {/* PO# */}
                <div className="col-7">
                  <strong>PO#</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="poNum"
                    value={stateInput.po}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, po: target.value }))}
                    placeholder={language === "en" ? "which one is the PO number?" : "Cuál es el número de PO?"} />
                </div>
              </div>

              {/* FILA 13 */}
              <div className="row-flex">
                {/* FECHA DE GARANTIA PROVEEDOR A GLOBAL */}
                <div className="col-7">
                  <strong>Fecha de Garantía Proveedor a Global</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecGarantiaGlobal"
                    value={stateInput.fecGarProveedor}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, fecGarProveedor: target.value }))} />
                </div>
                {/* FECHA DE GARANTIA GLOBAL AL CLIENTE */}
                <div className="col-7">
                  <strong>Fecha de Garantía Global al Cliente</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecGarGlobalCliente"
                    value={stateInput.fecGarGlobal}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, fecGarGlobal: target.value }))} />
                </div>
              </div>

              {/* FILA 14 */}
              <div className="row-flex">
                {/* FECHA DESPACHO */}
                <div className="col-7">
                  <strong>Fecha Despacho</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecDespacho"
                    value={stateInput.fecDespacho}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, fecDespacho: target.value }))} />
                </div>
                {/* FECHA ADUANA */}
                <div className="col-7">
                  <strong>Fecha Aduana</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecAduana"
                    value={stateInput.fecAduana}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, fecAduana: target.value }))} />
                </div>
              </div>

              {/* FILA 15 */}
              <div className="row-flex">
                {/* FECHA DESTINO (CLIENTE) */}
                <div className="col-7">
                  <strong>Fecha Destino (Cliente)</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecDestinoCliente"
                    value={stateInput.fecDestCliente}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, fecDestCliente: target.value }))} />
                </div>
                {/* FECHA INSTALACION */}
                <div className="col-7">
                  <strong>Fecha Instalación</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecInstalacion"
                    value={stateInput.fecInstalacion}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, fecInstalacion: target.value }))} />
                </div>
              </div>

              {/* FILA 16 */}
              <div className="row-flex">
                {/* CONTACTO INSTALACION */}
                <div className="col-7">
                  <strong>Contacto Instalación</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="contactInsta"
                    value={stateInput.contactoInstal}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, contactoInstal: target.value }))}
                    placeholder={language === "en" ? "Who is the installation contact?" : "Quién es el contacto de instalación?"} />
                </div>
                {/* CARGO */}
                <div className="col-7"><strong>Cargo</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="cargoContact"
                    value={stateInput.cargo}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, cargo: target.value }))}
                    placeholder={language === "en" ? "Indicates what is the position of the installation contact" : "Indique cuál es el cargo del contacto de instalación"} />
                </div>
              </div>

              {/* FILA 17 */}
              <div className="row-flex">
                {/* FECHA EOF */}
                <div className="col-7">
                  <strong>Fecha EOL</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecInstalacion"
                    value={stateInput.fecEOL}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, fecEOL: target.value }))} />
                </div>
              </div>

              {/* FILA 18 */}
              <div className="row-flex">
                {/* GARANTIA EXTENDIDA */}
                <div className="col-7">
                  <strong>Garantía Extendida</strong>
                </div>
                <div className="flex-auto">
                  <textarea
                    className="textarea-description"
                    name="garExten"
                    value={stateInput.garExtendida}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, garExtendida: target.value }))}
                    placeholder={language === "en" ? "What the extended warranty includes?" : "Qué incluye la garantía extendida?"} />
                </div>
              </div>

              {/* FILA 19 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-7">
                  <strong>Observación</strong>
                </div>
                <div className="flex-auto">
                  <textarea
                    className="textarea-description"
                    name="observacion"
                    value={stateInput.observacion}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, observacion: target.value }))}
                    placeholder={language === "en" ? "Do you have any observation for this equipment?" : "Tiene alguna observación para este equipo?"} />
                </div>
              </div>

              {/* FILA 20 */}
              <div className="row-flex">
                {/* CONTRATO */}
                <div className="col-7">
                  <strong>Contrato</strong>
                </div>
                <div className="col-4"></div>
                {/* COMPONENTES */}
                <div className="col-8">
                  <Link className="link-tag">Componentes</Link>
                </div>
                {/* CRONOGRAMA */}
                <div className="col-8">
                  <Link className="link-tag">Cronograma</Link>
                </div>
              </div>

              {/* FILA 21 */}
              <div className="row-flex">
                {/* VENTA DE REPUESTO */}
                <div className="col-7">
                  <strong>Venta de Repuestos</strong>
                </div>
                <div className="col-9">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="ventaRepuestos"
                    checked={stateInput.ventRepuesto}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, ventRepuesto: target.checked }))} />
                </div>
                {/* VENTA DE MANO DE OBRA */}
                <div className="col-8">
                  <strong>Venta de Mano de Obra</strong>
                </div>
                <div className="col-9">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="ventaRepuestos"
                    checked={stateInput.ventManObra}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, ventManObra: target.checked }))} />
                </div>
              </div>

              {/* FILA 22 */}
              <div className="row-flex">
                {/* ACTA DE INSTALACION */}
                <div className="col-7">
                  <strong>Acta de Instalación</strong>
                </div>

              </div>

              {/* FILA 23 */}
              <div className="row-flex">
                {/* DOCUMENTOS */}
                <div className="col-7">
                  <strong>Documentos</strong>
                </div>
              </div>

              {/* FILA 24 */}
              <div className="row-flex">
                {/* PROYECCION */}
                <div className="col-7">
                  <strong>Proyección</strong>
                </div>
                <div className="col-9">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="proyeccion"
                    checked={stateInput.proyeccion}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, proyeccion: target.checked }))} />
                </div>
              </div>

              {/* FILA 25 */}
              <div className="row-flex">
                {/* ACTIVO */}
                <div className="col-7">
                  <strong>Activo</strong>
                </div>
                <div className="col-9">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="activo"
                    checked={stateInput.activo}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, activo: target.checked }))} />
                </div>
                {/* PENDIENTE */}
                <div className="col-7">
                  <strong>Pendiente</strong>
                </div>
                <div className="col-9">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="pendiente"
                    checked={stateInput.pendiente}
                    onChange={({ target }) => setStateInput((val) => ({ ...val, pendiente: target.checked }))} />
                </div>
              </div>

              <hr className="m-y-small" />

              {/* FILA 26 */}
              <div className="row-flex">
                {/* INGRESADOR POR */}
                <div className="col-7">
                  <strong>Ingresado Por</strong>
                </div>
                <div className="col-3">
                  <span>{equipment && equipment[0]?.NomEmpIngreso}</span>
                </div>
                <div className="col-7">
                  <strong>Modificado Por</strong>
                </div>
                <div className="col-3">
                  <span>{equipment && equipment[0]?.NomEmpModi}</span>
                </div>
              </div>

              {/* FILA 27 */}
              <div className="row-flex">
                {/* INGRESADOR POR */}
                <div className="col-7">
                  <strong>Fecha Ingreso</strong>
                </div>
                <div className="col-3">
                  <span>{equipment && renderDate(equipment[0]?.FecIngreso, language)}</span>
                </div>
                <div className="col-7">
                  <strong>Fecha Modificación</strong>
                </div>
                <div className="col-3">
                  <span>{equipment && renderDate(equipment[0]?.FecModificacion, language)}</span>
                </div>
              </div>

              {/* BOTONES DE ACCION */}
              <div style={{ margin: '1rem 0 3rem 0' }} className="row-flex j-content-space-around">
                <button type={state.loadingSave ? "button" : "submit"} className="btn-primary" disabled={state.loadingSave}>{state.loadingSave ? t("serviceCard.guardando") : "Actualizar"}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

            </form >
          )
        }
      </div >
      {
        state.isLoading && (<LoadingComp />)
      }
      {/* NOTIFICATION ERROR */}
      {
        notification.show && (
          <ToastNotification
            success={notification.great}
            error={notification.errorNoti}
            msg={notification.msg} />
        )
      }

      {
        badRequestError.show && (
          <div className="background-modal">
            <ErrorNotification
              errorDisplayMsg={badRequestError.msg}
              url={badRequestError.url}
              urlMsg={badRequestError.urlMsg}
              otherMsg={badRequestError.otherMsg}
              onClose={onCloseModalBadRequest} />
          </div>
        )
      }
    </>
  );
};