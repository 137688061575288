// vista para devolver las piezas de servicio

import { useEffect, useState } from "react";


// components import
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { Error403View } from "../error-403-view/Error403View";

// react router import
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";

// useTranslation import
import { useTranslation } from "react-i18next";



export const PartDevolucion = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET react translation
  const [t] = useTranslation("global");

  // GET the useLocation
  const location = useLocation();

  // GET the useParams
  const { codNotEntr } = useParams();

  // GET the useNavigate
  const navigate = useNavigate();


  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [partes, setPartes] = useState([]); // estado para cargar las partes
  const [controller, setController] = useState(null);
  const [stateForm, setStateForm] = useState({ formaEnvio: "", guia: "", contacto: "", telefono: "", queja: "", textQueja: "", comentario: "" });
  const [state, setState] = useState({ dataVisible: false, isLoading: false, unauthorized: false, loading: false, showModalSave: false, questionAction: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });


  // destructuring data server
  const { parts } = dataServer && dataServer;


  // funcion para modificar el estado
  const onChangeEstado = (newVal, codDet) => {
    setPartes((prevData) => {
      return prevData.map((val) => {
        if (codDet === val.CodDetSolRepuesto) {
          return { ...val, Estado: newVal };
        }
        return val;
      });
    });
  };

  // funcion para modificar el status
  const onChangeStatus = (newVal, codDet) => {
    setPartes((prevData) => {
      return prevData.map((val) => {
        if (codDet === val.CodDetSolRepuesto) {
          return { ...val, Status: newVal };
        }
        return val;
      });
    });
  };

  // funcion para abrir el modal y guardar los cambios
  const onSaveOpenModal = () => {
    setState((val) => ({ ...val, showModalSave: true, questionAction: "Hola?" }));
  };



  // funcion para manejar el envio del formulario
  const onHandleReturn = async (event) => {
    event.preventDefault();

    const finalObj = { stateForm, partes };

    try {
      setState((val) => ({ ...val, loading: true }));
      const response = await fetch(`${urls.devolParte}/${codNotEntr}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, showModalSave: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, loading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar la peticion al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.devolParte}/${codNotEntr}/search?language=${language}`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codNotEntr, language]);


  // useEffect para cargar las partes en el estado
  useEffect(() => {
    if (parts && parts.length > 0) {
      setPartes(parts);
    }
  }, [parts]);


  // useEffect para limpiar la notificacion del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);



  return (
    <>
      {
        state.unauthorized && (<Error403View />)
      }
      {
        // Notification toast
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onHandleReturn}>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Forma de envío</strong></div>
                <div className="col-3">
                  <select
                    name="formEnv"
                    value={stateForm.formaEnvio}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, formaEnvio: target.value }))}>
                    <option value=""></option>
                    <option value="0">DHL</option>
                    <option value="1">MRW</option>
                    <option value="2">Fedex</option>
                    <option value="3">Por transporte</option>
                  </select>
                </div>
                <div className="col-7"><strong>Guía Nro.</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="trackingNum"
                    value={stateForm.guia}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, guia: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Persona contacto</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="contact"
                    value={stateForm.contacto}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, contacto: target.value }))}
                    autoComplete="off" />
                </div>
                <div className="col-7"><strong>Teléfono</strong></div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="phoneNum"
                    value={stateForm.telefono}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, telefono: target.value }))} />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="row-flex col-47-62">
                  <div className="col-80"><strong>Tiene alguna Queja y/o sugerencia con respecto a la(s) pieza(s) o envío</strong></div>
                  <div className="col-5">
                    <select
                      name="queja"
                      value={stateForm.queja}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, queja: target.value }))}>
                      <option value=""></option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Queja</strong></div>
                <div className="col-80-94">
                  <textarea
                    className="textarea-description"
                    name="textQue"
                    value={stateForm.textQueja}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, textQueja: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              {/* fila */}
              <div className="row-flex">
                <div className="col-7"><strong>Comentario</strong></div>
                <div className="col-80-94">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={stateForm.comentario}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, comentario: target.value }))}
                    autoComplete="off" />
                </div>
              </div>

              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>{t("TableParts.nota_entr")}</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.cliente")}</th>
                      <th>{t("TableParts.equip")}</th>
                      <th>{t("TableParts.estado")}</th>
                      <th>{t("TableParts.status")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes && partes.map((par) => {

                        let MOSTRAR;

                        if (par.CodRepServicio !== null) {
                          if (par.Usado === 1) {
                            // se pueden mostrar las opciones
                            MOSTRAR = <select
                              name={`state${par.CodDetSolRepuesto}`}
                              value={par.Estado}
                              onChange={({ target }) => onChangeEstado(target.value, par.CodDetSolRepuesto)}>
                              <option value=""></option>
                              <option value="0">{t("TableParts.perdida")}</option>
                              <option value="1">{t("TableParts.entr_cliente")}</option>
                              <option value="2">{t("TableParts.buena")}</option>
                              <option value="3">{t("TableParts.mala")}</option>
                              <option value="4">{t("TableParts.para_prueba")}</option>
                            </select>;
                          } else {
                            // se indica no se uso
                            MOSTRAR = t("newCallComp.no_uso");
                          }
                        } else {
                          MOSTRAR = t("newCallComp.indicar_utilizar_rep_serv");
                        }
                        // debe indicar si la pieza se utilizó o no en el reporte de servicio
                        return (
                          <tr key={par.CodDetSolRepuesto}>
                            <td className="txt-center">
                              <Link className="link-tag">{par.NumNotEntrega}</Link>
                            </td>
                            <td className="txt-center">{par.Cantidad}</td>
                            <td className="txt-center">
                              <Link className="link-tag" to={`/part-detail/${par.CodDetSolRepuesto}/${par.CodSolRepuesto}`}>{par.NumParte}</Link>
                            </td>
                            <td>{par.Descripcion}</td>
                            <td>{par.NomCliente}</td>
                            <td>{par.NomEquipo}</td>
                            <td>{MOSTRAR}</td>
                            <td>
                              {
                                par.CodRepServicio !== null ?
                                  <input
                                    className="input-text-control"
                                    type="text"
                                    name={`status${par.CodDetSolRepuesto}`}
                                    value={par.Status}
                                    onChange={({ target }) => onChangeStatus(target.value, par.CodDetSolRepuesto)}
                                    autoComplete="off" />
                                  :
                                  null
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>

              {/* botones de accion */}
              <div className="row-flex j-content-space-around m-y-small">
                <button type="button" className="btn-primary" onClick={onSaveOpenModal}>{t("TableParts.devolver")}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {
                state.showModalSave && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success" disabled={state.loading}>
                          {state.loading ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                        </button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModalSave: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          )
        }
      </div>
    </>
  );
};