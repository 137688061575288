

// CUSTOM HOOK PARA EL CAMBIO DE ESTADO DE LOS DIFERENTES INPUTS

import { useState } from "react";


const useCustomServiceComp = () => {

  // obtener la informacion del localStorage
  // const FILTER_INPUT = JSON.parse(localStorage.getItem("servFilt")) ?? "";

  // STATE DEFINITION
  const [openFilter, setOpenFilter] = useState(false);
  // const [filterInputs, setFilterInputs] = useState({
  //   serviceId: FILTER_INPUT.serviceId ?? "", customerName: FILTER_INPUT.customerName ?? "", company: FILTER_INPUT.company ?? "1",
  //   zone: FILTER_INPUT.zone ?? "1", temperature: FILTER_INPUT.temperature ?? "", responsible: FILTER_INPUT.responsible ?? "",
  //   step: FILTER_INPUT.step ?? "", serviceType: FILTER_INPUT.serviceType ?? "1", division: FILTER_INPUT.division ?? "",
  //   line: FILTER_INPUT.line ?? "", modality: FILTER_INPUT.modality ?? "", reportsNumber: FILTER_INPUT.reportsNumber ?? ""
  // });
  const [serverError, setServerError] = useState(false); // para mostrar cuando ocurra un error 500
  const [unauthorized, setUnAuthorized] = useState(false); // para mostrar cuando ocurra un error 403 (el usuario no tiene permisos para ver este contenido)
  const [dataVisible, setDataVisible] = useState(false);

  // Funcion para abrir el filtro de servicio
  const onOpenFilter = () => {
    setOpenFilter(!openFilter);
  };


  // RETORNAR LO QUE SE QUIERE UTILIZAR
  return {
    openFilter,
    onOpenFilter,
    serverError,
    setServerError,
    unauthorized,
    setUnAuthorized,
    dataVisible,
    setDataVisible,
    setOpenFilter
  };

};

export default useCustomServiceComp;