// componente para trabajar las devoluciones (los ingenieros ven esto)



export const DevolucionView = () => {

  return (
    <>
      <div className="container display-data">
        <h1>Trabajar las devoluciones</h1>
      </div>
    </>
  );
};