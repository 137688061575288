// ----> React importations
import React from "react";

// ----> Styles for PageNotFund
import "../../views/page-not-fund/PageNotFound.css";

export const PageNotFound = () => {

  return (
    <>
      <div className="wrapper-page-not-found">
        <div className="error">
          <div className="number">4</div>
          <div className="illustration">
            <div className="circle"></div>
            <div className="clip">
              <div className="paper">
                <div className="face">
                  <div className="eyes">
                    <div className="eye eye-left"></div>
                    <div className="eye eye-right"></div>
                  </div>
                  <div className="cheeks cheeks-left"></div>
                  <div className="cheeks cheeks-right"></div>
                  <div className="mouth"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="number">4</div>
        </div>
        <div className="text-page-not-found">Oops. The page you're looking for doesn't exist</div>
        <button className="button-page-not-found">Back Home</button>
      </div>
    </>
  );
};