// componente para generar el POD

import { useEffect, useState } from "react";


// components importation
import { ToastNotification } from "../toast-notification/ToastNotification";


// react router dom importation
import { useLocation, useNavigate } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";

// react translation import
import { useTranslation } from "react-i18next";


export const GeneratePOD = ({ onClose, CodEquip }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [containers, setContainers] = useState([]); // estado agregar los contenedores
  const [controller, setController] = useState(null); // controlador para abortar la peticion al servidor
  const [stateForm, setStateForm] = useState({ fecRecep: "", contacto: "", cargo: "", modelo: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });
  const [nextId, setNextId] = useState(1);


  // destructuring data server
  const { container, equipInfo } = dataServer && dataServer;


  // funcion para agregar los contenedores
  const onHandleAddContainers = () => {
    setContainers((prevData) => [
      ...prevData,
      {
        CodEquPOD: nextId,
        CodEquipo: CodEquip,
        Cantidad: nextId,
        Dimension: "",
        Peso: ""
      }
    ]);
    setNextId((val) => val + 1);
  };


  // funcion para modificar las dimensiones
  const onUpdateDimisions = (val, index) => {
    setContainers((prevCont) => {
      return prevCont.map((container) => {
        if (index === container.CodEquPOD) {
          return { ...container, Dimension: val };
        }
        return container;
      });
    });
  };

  // funcion para modificar el peso
  const onUpdatePeso = (val, index) => {
    setContainers((prevCont) => {
      return prevCont.map((container) => {
        if (index === container.CodEquPOD) {
          return { ...container, Peso: val };
        }
        return container;
      });
    });
  };


  // funcion para descargar el POD
  const onHandlePrintPOD = async () => {

    try {
      const response = await fetch(`${urls.createPOD}/${CodEquip}/search?language=${language}&createPOD=1`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobUrl = URL.createObjectURL(blob);

        // crear un enlace oculto
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobUrl;
        a.download = "POD";
        document.body.appendChild(a);

        // simular click
        a.click();

        // limpiar la url y el enlace de descarga
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);

        // mostrar notificacion
        setNotification((val) => ({ ...val, show: true, msg: t("newCallComp.descarga_archivo"), great: true }));

      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        const data = await response.json();

        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 404) {
        const data = await response.json();

        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para manejar el formulario
  const onHandleSaveForm = async (event) => {
    event.preventDefault();

    // verificar si todos los campos están completos
    let dimen = 0;
    let peso = 0;

    if (containers.length === 0) {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la cantidad de contenedores", error: true }));
    }

    for (const inf of containers) {
      if (inf.Dimension.trim() === "") {
        dimen += 1;
      }
      if (inf.Peso.trim() === "") {
        peso += 1;
      }
    }

    if (dimen > 0 || peso > 0) {
      return setNotification((val) => ({ ...val, show: true, msg: "Faltan campos por llenar.", error: true }));
    }

    if (stateForm.contacto.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la persona de contacto.", error: true }));
    }

    if (stateForm.cargo.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar el cargo.", error: true }));
    }

    if (stateForm.fecRecep === "") {
      return setNotification((val) => ({ ...val, show: true, msg: "Debe indicar la fecha de recepción", error: true }));
    }

    const finalObj = { stateForm, containers };

    try {
      const response = await fetch(`${urls.createPOD}/${CodEquip}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar los datos al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      const response = await fetch(`${urls.createPOD}/${CodEquip}/search?language=${language}&createPOD=2`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para solicitar la data al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, CodEquip]);


  // useEffect para limpiar el estado de las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if (equipInfo && equipInfo.length > 0) {
      setStateForm((val) => ({ ...val, contacto: equipInfo[0]?.Contacto ?? "", cargo: equipInfo[0]?.Cargo ?? "", fecRecep: equipInfo[0]?.FecAmbiente?.slice(0, 10) ?? "", modelo: equipInfo[0]?.NomModelo }));
    }
  }, [equipInfo]);

  // useEffect para cargar el array de los container que vienen de la db
  useEffect(() => {
    if (container && container.length > 0) {
      setContainers(container);

      setNextId(container.length + 1);
    }
  }, [container]);



  return (
    <>
      {
        // notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="background-modal" onClick={onClose}>
        <div className="container-modal" onClick={(event) => event.stopPropagation()}>
          <form onSubmit={onHandleSaveForm}>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Modelo</strong></div>
              <div className="col-3"><strong>{stateForm.modelo}</strong></div>
              <div className="col-7"><strong>Fecha Recepción</strong></div>
              <div className="col-3">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecRecep"
                  value={stateForm.fecRecep}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecRecep: target.value }))} />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Persona contacto</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="perCont"
                  value={stateForm.contacto}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, contacto: target.value }))}
                  autoComplete="off" />
              </div>
              <div className="col-7"><strong>Cargo</strong></div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="position"
                  value={stateForm.cargo}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, cargo: target.value }))}
                  autoComplete="off" />
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7"><strong>Agregar contenedores</strong></div>
              <div className="col-7" style={{ display: "flex", alignItems: "center" }}>
                <button type="button" style={{ display: "flex", padding: "0.15rem", cursor: "pointer" }} onClick={onHandleAddContainers}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--first-color)" className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                </button>
              </div>
            </div>

            {/* tabla para mostrar los contenedores */}
            {
              containers && containers.length > 0 && (
                <div className="table-container-mobile m-y-small">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Contenedor</th>
                        <th>Dimensión (cm)</th>
                        <th>Peso (Kg.)</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        containers && containers.map((con) => (
                          <tr key={con.CodEquPOD}>
                            <td className="txt-center">{con.CodEquPOD}</td>
                            <td>
                              <input
                                className="input-text-control txt-center"
                                type="text"
                                name={`dimen${con.CodEquPOD}`}
                                value={con.Dimension}
                                onChange={({ target }) => onUpdateDimisions(target.value, con.CodEquPOD)}
                                autoComplete="off" />
                            </td>
                            <td>
                              <input
                                className="input-text-control txt-center"
                                type="text"
                                name={`weigh${con.CodEquPOD}`}
                                value={con.Peso}
                                onChange={({ target }) => onUpdatePeso(target.value, con.CodEquPOD)}
                                autoComplete="off" />
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            }

            <div className="row-flex j-content-space-around m-y-small">
              <button type="submit" className="btn-primary">Guardar</button>
              <button type="button" className="btn-success" onClick={onHandlePrintPOD}>Imprimir</button>
              <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};