// componente para filtrar los anticipos

import { useEffect, useState } from "react";

// React translation
import { useTranslation } from "react-i18next";
import { StepTravelExpenses } from "../../global-functions/globalFunctions";
import { urls } from "../../api-urls/api-urls";


// react router dom import
import { useSearchParams } from "react-router-dom";






export const SearchCompAnticipo = ({ lang, onShowFilter, token, newState }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // search params
  const [params, setParams] = useSearchParams();

  // State definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null); // controlador para cancelar la peticion del servidor
  const [stateForm, setStateForm] = useState({ empresa: params.get("id_empr") ?? "", zona: params.get("id_area") ?? "", responsable: params.get("nom_respon") ?? "", etapa: params.get("id_eta") ?? "", division: params.get("id_divi") ?? "", gerencia: params.get("id_geren") ?? "", areaFun: params.get("id_unid") ?? "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [state, setState] = useState({ isLoading: false });


  // destructuring data server
  const { divisionEmpresa, gerencia, unidad, zona, empPerm } = dataServ && dataServ;



  // funcion para cambiar los valores del estado
  const onHandleChangeVal = (newVal = "", type = 0) => {
    params.set("filt", 1);

    switch (type) {
      case 1: // empresa
        params.set("id_empr", newVal);
        setStateForm((val) => ({ ...val, empresa: newVal }));
        setParams(params);
        break;
      case 2: // zona
        params.set("id_area", newVal);
        setStateForm((val) => ({ ...val, zona: newVal }));
        setParams(params);
        break;
      case 3: // responsable
        params.set("nom_respon", newVal);
        setStateForm((val) => ({ ...val, responsable: newVal }));
        setParams(params);
        break;
      case 4: // etapa
        params.set("id_eta", newVal);
        setStateForm((val) => ({ ...val, etapa: newVal }));
        setParams(params);
        break;
      case 5: // division empresa
        params.set("id_divi", newVal);
        setStateForm((val) => ({ ...val, division: newVal }));
        setParams(params);
        break;
      case 6: // gerencia
        params.set("id_geren", newVal);
        setStateForm((val) => ({ ...val, gerencia: newVal }));
        setParams(params);
        break;
      case 7: // area funcional
        params.set("id_unid", newVal);
        setStateForm((val) => ({ ...val, areaFun: newVal }));
        setParams(params);
        break;
    }
  }



  // Funcion para buscar por los demas campos que no sea el numero de control
  const onSearchGeneral = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.anticipo}?language=${lang}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&responsable=${stateForm.responsable}&etapa=${stateForm.etapa}&division=${stateForm.division}&gerencia=${stateForm.gerencia}&areaFun=${stateForm.areaFun}&page=1&pageSize=20&nroControl=&filt=1`, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();


      if (response.status === 200) {
        // setDataServer(data);
        newState(data?.solGastoList, data?.solGastoList.length < 20);
        onShowFilter();
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true, great: false }));
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };




  // funcion para traer la data del servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.anticipo}?language=${lang}&action=1`;

    setController(abortController);
    try {
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para actualizar el estado de los filtros
  useEffect(() => {
    if (empPerm && params.get("filt") === null) {
      setStateForm((val) => ({ ...val, empresa: empPerm[0]?.CodEmpresa, responsable: empPerm[0]?.NomEmpleado }));
    }
  }, [empPerm]);





  return (
    <div className="background-modal">
      <form onSubmit={onSearchGeneral} className="container-select-customer container-filter-viat-h">
        {/* FILA */}
        <div className="modal-header">

        </div>
        <div className="modal-body">
          <div className="row-flex">
            {/* Empresa */}
            <div className="col-5">
              <strong>{t("Viaticos.empresa")}</strong>
            </div>
            <div className="col-30">
              <select
                name="company"
                value={stateForm.empresa}
                onChange={({ target }) => onHandleChangeVal(target.value, 1)}>
                <option value=""></option>
                <option value="1">Global Medica</option>
                <option value="2">GMD Healthcare Ltd</option>
              </select>
            </div>
            {/* Zona */}
            <div className="col-5">
              <strong>{t("Viaticos.zona")}</strong>
            </div>
            <div className="col-30">
              <select
                name="zona"
                disabled={stateForm.empresa === ""}
                className={stateForm.empresa === "" ? "wrapper" : ""}
                value={stateForm.zona}
                onChange={({ target }) => onHandleChangeVal(target.value, 2)}>
                <option value=""></option>
                {
                  zona && zona.filter((zo) => zo.CodEmpresa.toString() === stateForm.empresa).map((zon) => (
                    <option key={zon.CodZona} value={zon.CodZona}>{zon.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>

          {/* FILA */}
          <div className="row-flex">
            {/* Responsable */}
            <div className="col-5">
              <strong>{t("Viaticos.responsable")}</strong>
            </div>
            <div className="col-30">
              <input
                className="input-text-control"
                type="text"
                name="responsable"
                value={stateForm.responsable}
                onChange={({ target }) => onHandleChangeVal(target.value, 3)} />
            </div>
            {/* Etapa */}
            <div className="col-5">
              <strong>{t("Viaticos.etapa")}</strong>
            </div>
            <div className="col-30">
              <select
                name="step"
                value={stateForm.etapa}
                onChange={({ target }) => onHandleChangeVal(target.value, 4)}>
                <option value="">--Pendientes--</option>
                {
                  StepTravelExpenses && StepTravelExpenses.map((step) => (
                    <option key={step.id_etapa} value={step.id_etapa}>
                      {lang === "en" ? step.nombreEn : step.nombre}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>

          {/* FILA */}
          <div className="row-flex">
            {/* Division empresa */}
            <div className="col-5">
              <strong>{t("Viaticos.divi_empr")}</strong>
            </div>
            <div className="col-30">
              <select
                name="diviCompan"
                value={stateForm.division}
                onChange={({ target }) => onHandleChangeVal(target.value, 5)}>
                <option value=""></option>
                {
                  divisionEmpresa && divisionEmpresa.map((divi) => (
                    <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Nombre}</option>
                  ))
                }
              </select>
            </div>
            {/* Gerencia */}
            <div className="col-5">
              <strong>{t("Viaticos.gerencia")}</strong>
            </div>
            <div className="col-30">
              <select
                name="geren"
                className={stateForm.division === "" ? "wrapper" : ""}
                disabled={stateForm.division === ""}
                value={stateForm.gerencia}
                onChange={({ target }) => onHandleChangeVal(target.value, 6)}>
                <option value=""></option>
                {
                  gerencia && gerencia.filter((ger) => ger.CodGrupo.toString() === stateForm.division).map((geren) => (
                    <option key={geren.CodGerencia} value={geren.CodGerencia}>{geren.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>

          {/* FILA */}
          <div className="row-flex">
            {/* Area funcional */}
            <div className="col-5">
              <strong>{t("Viaticos.area_fun")}</strong>
            </div>
            <div className="col-30">
              <select
                name="areaFun"
                className={stateForm.gerencia === "" ? "wrapper" : ""}
                disabled={stateForm.gerencia === ""}
                value={stateForm.areaFun}
                onChange={({ target }) => onHandleChangeVal(target.value, 7)}>
                <option value=""></option>
                {
                  unidad && unidad.filter((un) => un.CodGerencia.toString() === stateForm.gerencia).map((uni) => (
                    <option key={uni.CodUnidad} value={uni.CodUnidad}>{uni.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>

        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success">{t("Viaticos.buscar")}</button>
          <button type="reset" className="btn-secondary" onClick={onShowFilter}>{t("Viaticos.cerrar")}</button>
        </div>

      </form>
    </div>
  );
};