// componente para la busqueda de cotizaciones

import { t } from "i18next";
import { useEffect, useState } from "react";

// react router dom importation
import { useNavigate, useSearchParams } from "react-router-dom";




export const SearchQuotationComp = ({ onClose, urlsObj, lang, token, updateState }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  const [dataServ, setDataServ] = useState([]); // estado para cargar la data inicial
  const [params, setParams] = useSearchParams(); // para los params de la url
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [searching, setSearching] = useState(false);
  const [stateForm, setStateForm] = useState({ cliente: params.get("cus") ?? "", cotizacion: params.get("quot") ?? "", empresa: params.get("comp") ?? "", zona: params.get("area") ?? "", etapa: params.get("ste") ?? "14", division: params.get("div") ?? "", linea: params.get("lin") ?? "", idServ: params.get("idSe") ?? "", numPart: "", tipo: "" });


  // destructuring data server
  const { division, linea, empresa, zona, etapas, empPer } = dataServ && dataServ;

  const BTN_CLASS = `btn-success ${searching ? "disabled" : ""}`;


  // funcion para buscar
  const onHandleSearch = async (event) => {
    event.preventDefault();

    if (!params.get("filt")) {

      if (!params.get("comp") && empPer) {
        params.set("comp", empPer[0]?.CodEmpresa);
      }

      if (!params.get("area") && empPer) {
        params.set("area", empPer[0]?.CodZona);
      }

      params.set("filt", 1);
    }

    setParams(params);

    // setear el filtro en el localStorage
    try {
      setSearching(true);
      const response = await fetch(`${urlsObj.getListQuotations}?language=${lang}&action=1&filtro=1&cliente=${stateForm.cliente}&cotizacion=${stateForm.cotizacion}&empresa=${stateForm.empresa}&zona=${stateForm.zona}&etapa=${stateForm.etapa}&division=${stateForm.division}&linea=${stateForm.linea}&idServ=${stateForm.idServ}&numPart=${stateForm.numPart}&tipo=${stateForm.tipo}&page=1`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        console.log(data);
        updateState(data?.listQuotation, data?.nextPage, data?.total, data?.MoreData);
        onClose(); // cerrar el modal
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }
      setSearching(false);

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para realizar la peticion al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      const response = await fetch(`${urlsObj.getListQuotations}?language=${lang}&action=2&filtro=0`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para cambiar los valores del formulario
  const onHandleChangeForm = (newVal = "", formN = "", paramsN = "") => {

    if (["cotizacion", "idServ"].includes(formN)) {
      if (isNaN(newVal)) {
        return;
      }
    }

    setStateForm((val) => ({ ...val, [formN]: newVal }));
    params.set(paramsN, newVal);

  };


  // funcion inicial para establecer los params


  // useEffect para solicitar la data al serv
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, []);


  useEffect(() => {

    if (empPer && !params.get("filt")) {
      // setear los valores en el estado de los filtros
      if ([3, 6].includes(empPer[0]?.CodPerfil)) {
        setStateForm((val) => ({ ...val, zona: empPer[0]?.CodZona, empresa: empPer[0]?.CodEmpresa }));
      }

    }

  }, [empPer]);




  return (
    <div className="background-modal">
      <form onSubmit={onHandleSearch} className="container-select-customer">
        <div className="modal-header">
          <h3>Búsqueda</h3>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="row-flex">
            <div className="col-7">
              <strong>Cliente</strong>
            </div>
            <div className="col-80-94">
              <input
                className="input-text-control"
                type="text"
                name="custom"
                value={stateForm.cliente}
                onChange={({ target }) => onHandleChangeForm(target.value, "cliente", "cus")} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Cotización</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="cotNum"
                value={stateForm.cotizacion}
                onChange={({ target }) => onHandleChangeForm(target.value, "cotizacion", "quot")} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Empresa</strong>
            </div>
            <div className="col-3">
              <select
                name="company"
                value={stateForm.empresa}
                onChange={({ target }) => onHandleChangeForm(target.value, "empresa", "comp")}>
                <option value=""></option>
                {
                  empresa && empresa.map((emp) => (
                    <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-7">
              <strong>Zona</strong>
            </div>
            <div className="col-3">
              <select
                name="area"
                value={stateForm.zona}
                onChange={({ target }) => onHandleChangeForm(target.value, "zona", "area")}>
                <option value=""></option>
                {
                  zona && zona.filter((zo) => zo.CodEmpresa.toString() === stateForm.empresa).map((zonF) => (
                    <option key={zonF.CodZona} value={zonF.CodZona}>{zonF.NomZona}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Etapa</strong>
            </div>
            <div className="col-3">
              <select
                name="step"
                value={stateForm.etapa}
                onChange={({ target }) => onHandleChangeForm(target.value, "etapa", "ste")}>
                <option value=""></option>
                <option value="0">--Pendientes--</option>
                <option value="-1">Todo</option>
                {
                  etapas && etapas.map((eta) => (
                    <option key={eta.IdEtapa} value={eta.IdEtapa}>{eta.NomEtapa}</option>
                  ))
                }
                <option value="1">Oportunidad de venta</option>
              </select>
            </div>
            <div className="col-7">
              <strong>División</strong>
            </div>
            <div className="col-3">
              <select
                name="division"
                value={stateForm.division}
                onChange={({ target }) => onHandleChangeForm(target.value, "division", "div")}>
                <option value=""></option>
                {
                  division && division.map((div) => (
                    <option key={div.CodDivision} value={div.CodDivision}>{div.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Línea</strong>
            </div>
            <div className="col-3">
              <select
                name="line"
                value={stateForm.linea}
                onChange={({ target }) => onHandleChangeForm(target.value, "linea", "lin")}>
                <option value=""></option>
                {
                  linea && linea.filter((lin) => lin.CodDivision === stateForm.division).map((linF) => (
                    <option key={linF.CodLinea} value={linF.CodLinea}>{linF.NomLinea}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-7">
              <strong>Id Servicio</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="idServ"
                value={stateForm.idServ}
                onChange={({ target }) => onHandleChangeForm(target.value, "idServ", "idSe")} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Nro. parte</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="numpart"
                value={stateForm.numPart}
                onChange={({ target }) => onHandleChangeForm(target.value, "numPart", "numPar")} />
            </div>
            <div className="col-7">
              <strong>Tipo</strong>
            </div>
            <div className="col-3">
              <select
                name="type"
                value={stateForm.tipo}
                onChange={({ target }) => onHandleChangeForm(target.value, "tipo", "tip")}>
                <option value="">--Todo--</option>
                <option value="1">Mano de obra</option>
                <option value="2">Repuestos</option>
                <option value="3">Local / Inventario</option>
                <option value="4">CIF</option>
                <option value="5">FOB</option>
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer j-content-space-around">
          <button type={searching ? "button" : "submit"} className={BTN_CLASS}>Buscar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};