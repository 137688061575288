import React, { useEffect, useState } from "react";


// ----> Importation of useTranslation() Hook
import { useTranslation } from "react-i18next";

// ----> Import of useNavigate() Hook
import { useLocation, useNavigate } from "react-router-dom";

// COMPONENTS IMPORTATIONS
import { ToastNotification } from "../toast-notification/ToastNotification";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions";

// ----> urls importations
import { urls } from "../../api-urls/api-urls";


export const CreateCustomerComp = () => {

  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // ----> Destructuring useTranslation() Hook
  const [t] = useTranslation("global");

  // ----> useNavigate()
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // STATE DEFINITION

  const [phoneNumCustomer, setPhoneNumCustomer] = useState("");
  const [dataServer, setDataServer] = useState([]);
  const [filterStates, setFilterStates] = useState([]); // para filtrar los estados cuando cambie el stateForm.pais
  const [filterCities, setFilterCities] = useState([]); // para filtrar las ciudades cuando cambie el estado stateForm.estado
  const [stateForm, setStateForm] = useState({
    nombreCliente: "", ciudad: "", rncCliente: "", vendedor: "", pais: "", edificio: "", estado: "", codigoPostal: "",
    tecnicoResponsable: "", fax: "", aveCalle: "", paginaWeb: "", urbSector: "", codTelefono: "", telefono: "", correo: "",
    tipoPersona: "", institucion: "", cargo: "", condicionesCredito: "", observaciones: "", activo: true, sector: ""
  });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, customerCreated: false });
  const [state, setState] = useState({ isLoading: false, showModal: false, questionAction: "" });

  // DESTRUCTURING DATA SERVER
  const { states, employees, cities } = dataServer && dataServer;


  // filtrar los empleados que son de servicio
  const serviceEmployees = employees && employees.filter((emp) => emp.CodGrupo === 1);

  // filtrar los empleados que son de ventas
  const salesEmployees = employees && employees.filter((emp) => emp.CodGrupo === 3);


  // MSG LABELS
  const FIELDS_REQUIRED = language === "es" ? "Debe completar los campos que son obligatorios (*)." : "You must complete the fields that are required (*).";
  const EMAIL_MUST_BE_VALID = language === "es" ? "Debe proporcionar un correo válido." : "You must provide a valid email.";
  const ERROR_SEND_SERVER = language === "es" ? "Ocurrió un error al enviar los datos al servidor, trate de nuevo más tarde. Si el error persiste contacte al administrador." : "An error occurred when sending the data to the server, try again later. If the error persists contact the admin.";


  // ----> handleChange for phone number
  const handleChangePhoneCustomer = (event) => {
    const phone = event.target.value.replace(/\D/g, ''); // -- eliminar caracteres no numericos
    let formattedInputPhone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;

    setPhoneNumCustomer(formattedInputPhone);
  };

  // PATRON PARA VALIDAR EL CORREO
  const patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  // funcion para abrir el modal para crear el cliente
  const onOpenSaveModal = () => {
    // VALIDACIONES ANTES DE ENVIAR AL SERVIDOR
    if (stateForm.nombreCliente.trim() === "" || stateForm.rncCliente.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: FIELDS_REQUIRED, error: true }));
    } else if (!patron.test(stateForm.correo)) {
      return setNotification((val) => ({ ...val, show: true, msg: EMAIL_MUST_BE_VALID, error: true }));
    } else if (stateForm.tipoPersona.trim() === "") {
      return setNotification((val) => ({ ...val, show: true, msg: FIELDS_REQUIRED, error: true }));
    }

    setState((val) => ({ ...val, showModal: true, questionAction: "Está seguro que desea crear este nuevo cliente?" }));

  };


  // ----> HANDLE SUBMIT (ENVIAR DATOS AL SERVIDOR PARA CREAR EL CLIENTE)
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.getCustomer}/search?language=${language}&create=yes`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": getTheToken().token
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {
        // se creó algo
        setState((val) => ({ ...val, showModal: false, questionAction: "" }));
        return setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, customerCreated: true }));
      } else if (response.status === 400) { // ya existe un cliente con este codigo
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      setNotification((val) => ({ ...val, show: true, msg: ERROR_SEND_SERVER, error: true }));
    }

  };


  // BUSCAR LOS RECURSOS NECESARIOS PARA CREAR LOS EQUIPOS
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        const response = await fetch(`${urls.getCustomer}/search?language=${language}&create=no`, {
          method: "POST",
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 404) {
          // el recurso que se estaba buscando no se encontro
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

      };

      fetchDataServer();

    } catch (error) {
      console.log(error);
    }
  }, [navigate, location.pathname]);

  // useEffect para filtrar los estados dependiendo del pais
  useEffect(() => {
    if (states && states) {
      const stateFound = states && states.filter((item) => item.CodPais.toString() === stateForm.pais);
      setFilterStates(stateFound);
    }
  }, [stateForm.pais]);

  // useEffect para filtrar las ciudades dependiendo del estado
  useEffect(() => {
    if (cities && cities) {
      const cityFound = cities.filter((item) => item.CodEstado.toString() === stateForm.estado);
      setFilterCities(cityFound);
    }
  }, [stateForm.estado]);

  // useEffect para limpiar los timeout
  useEffect(() => {
    if (notification.show && !notification.customerCreated) {
      const timer = setTimeout(() => {
        setNotification({ show: false, msg: "", error: false, great: false, customerCreated: false });
      }, 5500);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.show]);


  // useEffect para redirigir al cliente
  useEffect(() => {
    if (notification.customerCreated) {
      const timer = setTimeout(() => {
        navigate(`/customers/customer-detail/${stateForm.rncCliente.trim()}`);
      }, 5000);

      return () => {
        clearTimeout(timer);
        console.log("Se desmontó el componente")
      }
    }
  }, [notification.customerCreated]);


  return (
    <>
      {
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <div className="display-data">

        <div className="container">
          <div className="card-new-customer row-flex"
            style={{
              flexDirection: 'column',
              gap: '0.7rem',
              margin: '0 0.3rem 0.8rem 0'
            }}>
            <h1 style={{
              textAlign: 'center',
              color: 'var(--first-color)'
            }}>
              {t("createCustomerComp.insertNewCustomer")}
            </h1>
            <hr />
            <form style={{ flexDirection: 'column', gap: '0.7rem', padding: '0 0.5rem 0 0.5rem' }} onSubmit={handleSubmit}>
              {/* FILA 1*/}
              <div className="row-flex" >
                {/* NOMBRE DEL CLIENTE */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.name")}
                  </strong>
                  <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="customerName"
                    value={stateForm.nombreCliente}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, nombreCliente: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerNamePlaceHold")} />
                </div>
                {/* CIUDAD DEL CLIENTE */}
                <div className="col-7">
                  <strong>
                    {t("createCustomerComp.city")}
                  </strong>
                </div>
                <div className="col-4">
                  <select
                    name="ciudad"
                    className={`${stateForm.estado === "" ? "wrapper" : ""}`}
                    disabled={stateForm.estado === ""}
                    value={stateForm.ciudad}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, ciudad: target.value }))}>
                    <option value=""></option>
                    {
                      filterCities && filterCities.map((city) => (
                        <option key={city.CodCiudad} value={city.CodCiudad}>{city.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* FILA 2 */}
              <div className="row-flex">
                {/* RNC O TAX ID */}
                <div className="col-5">
                  <strong>
                    RNC / Tax ID
                  </strong>
                  <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="codeCustomer"
                    value={stateForm.rncCliente}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, rncCliente: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerTaxPlaceHold")} />
                </div>
                {/* VENDEDOR */}
                <div className="col-7">
                  <strong>
                    {t("createCustomerComp.salesPerson")}
                  </strong>
                </div>
                <div className="col-4">
                  <select
                    name="vendedor"
                    value={stateForm.vendedor}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, vendedor: target.value }))}>
                    <option value=""></option>
                    {
                      salesEmployees && salesEmployees.map((emplo) => (
                        <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {/* FILA 3 */}
              <div className="row-flex">
                <div className="col-5">
                  {/* PAIS */}
                  <strong>
                    {t("createCustomerComp.country")}
                  </strong>
                </div>
                <div className="col-4">
                  <select
                    name="pais"
                    value={stateForm.pais}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, pais: target.value }))}>
                    <option value=""></option>
                    <option value="1">República Dominicana</option>
                    <option value="3">Islas del caribe</option>
                  </select>
                </div>
                {/* EDIFICIO */}
                <div className="col-7">
                  <strong>
                    {t("createCustomerComp.building")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="customerBuilding"
                    value={stateForm.edificio}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, edificio: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerBuildingPlaceHold")} />
                </div>
              </div>
              {/* FILA 4 */}
              <div className="row-flex">
                {/* PROVINCIA */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.state")}
                  </strong>
                </div>
                <div className="col-4">
                  <select
                    name="estado"
                    className={`${stateForm.pais === "" ? "wrapper" : ""}`}
                    disabled={stateForm.pais === ""}
                    value={stateForm.estado}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, estado: target.value }))}>
                    <option value=""></option>
                    {
                      filterStates && filterStates.map((state) => (
                        <option key={state.CodEstado} value={state.CodEstado}>{state.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* CODIGO POSTAL */}
                <div className="col-7">
                  <strong>
                    {t("createCustomerComp.postalCode")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="postalCode"
                    value={stateForm.codigoPostal}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, codigoPostal: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerPostalCodePlaceHold")} />
                </div>
              </div>
              {/* FFILA 5 */}
              <div className="row-flex">
                {/* TECNICO RESPONSABLE */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.responsibleTechnician")}
                  </strong>
                </div>
                <div className="col-4">
                  <select
                    name="tecnicoReponsable"
                    value={stateForm.tecnicoResponsable}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tecnicoResponsable: target.value }))}>
                    <option value=""></option>
                    {
                      serviceEmployees && serviceEmployees.map((emplo) => (
                        <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
                {/* FAX DEL CLIENTE */}
                <div className="col-7">
                  <strong>
                    Fax
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="faxCustomer"
                    value={stateForm.fax}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, fax: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerFaxPlaceHold")} />
                </div>
              </div>
              {/* FILA 6 */}
              <div className="row-flex">
                <div className="col-5">
                  {/* AVENIDA CALLE */}
                  <strong>
                    {t("createCustomerComp.avenue")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="streetCustomer"
                    value={stateForm.aveCalle}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, aveCalle: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerAvenuePlaceHold")} />
                </div>
                {/* PAGINA WEB */}
                <div className="col-7">
                  <strong>
                    {t("createCustomerComp.webPage")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="customerWebPage"
                    value={stateForm.paginaWeb}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, paginaWeb: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerWEBPagePlaceHold")} />
                </div>
              </div>
              {/* FILA 7 */}
              <div className="row-flex">
                {/* URBANIZACION SECTOR */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.urbSector")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="urbSectorCustomer"
                    value={stateForm.urbSector}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, urbSector: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerUrbPlaceHold")} />
                </div>
              </div>
              {/* FILA 8 */}
              <div className="row-flex">
                {/* TELEFONO */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.telephone")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    value={stateForm.telefono}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, telefono: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerTelephonePlaceHold")} />
                </div>
              </div>
              {/* FILA 9 */}
              <div className="row-flex">
                {/* CORREO */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.email")}
                  </strong>
                  <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="customerEmail"
                    value={stateForm.correo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, correo: target.value }))}
                    autoComplete="off"
                    placeholder={t("createCustomerComp.customerEmailPlaceHold")} />
                </div>
              </div>
              {/* FILA 10 */}
              <div className="row-flex">
                {/* seleccionar el tipo de persona (natural - juridica) */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.personType")}
                  </strong>
                  <span style={{ color: 'var(--danger-color)', padding: '0.2rem' }}>(*)</span>
                </div>
                <div className="row-flex gap-small align-c col-4">
                  {/* Colocar etiqueta para cada input radio */}
                  <span>Natural</span>
                  <input
                    style={{ width: '0.8rem', height: '0.8rem' }}
                    type="radio"
                    name="tipoPersona"
                    value="0"
                    checked={stateForm.tipoPersona === '0'}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipoPersona: target.value }))}
                  />
                  <span>Juridica</span>
                  <input
                    style={{ width: '0.8rem', height: '0.8rem' }}
                    type="radio"
                    name="tipoPersona"
                    value="1"
                    checked={stateForm.tipoPersona === '1'}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, tipoPersona: target.value }))}
                  />
                </div>
                {/* SECTOR */}
                <div className="col-7"><strong>{t("createCustomerComp.sector")}</strong></div>
                <div className="row-flex gap-small align-c col-4">
                  {/* Colocar etiqueta para cada input radio */}
                  <span>Publico</span>
                  <input
                    style={{ width: '0.8rem', height: '0.8rem' }}
                    type="radio"
                    name="sector"
                    value="0"
                    checked={stateForm.sector === '0'}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, sector: target.value }))}
                  />
                  <span>Privado</span>
                  <input
                    style={{ width: '0.8rem', height: '0.8rem' }}
                    type="radio"
                    name="sector"
                    value="1"
                    checked={stateForm.sector === '1'}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, sector: target.value }))}
                  />
                </div>
              </div>
              {/* FILA 11 */}
              <div className="row-flex">
                {/* INSTITUCION */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.institution")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="instituCustomer"
                    value={stateForm.institucion}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, institucion: target.value }))}
                    autoComplete="off" />
                </div>
                {/* INGRESAR EL CARGO */}
                <div className="col-7">
                  <strong>
                    {t("createCustomerComp.position")}
                  </strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="cargoCustomer"
                    value={stateForm.cargo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, cargo: target.value }))}
                    autoComplete="off" />
                </div>
              </div>
              {/* FILA 12 */}
              <div className="row-flex">
                {/* CONDICIONES DE CREDITO */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.creditTerms")}
                  </strong>
                </div>
                <div className="col-100">
                  <textarea
                    className="textarea-description"
                    value={stateForm.condicionesCredito}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, condicionesCredito: target.value }))}
                    placeholder={t("createCustomerComp.customerCreditTermsPlaceHold")} />
                </div>
              </div>
              {/* FILA 13 */}
              <div className="row-flex">
                {/* OBSERVACIONES */}
                <div className="col-5">
                  <strong>
                    {t("createCustomerComp.observations")}
                  </strong>
                </div>
                <div className="col-100">
                  <textarea
                    className="textarea-description"
                    value={stateForm.observaciones}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, observaciones: target.value }))}
                    placeholder={t("createCustomerComp.customerObserPlaceHold")} />
                </div>
              </div>

              {/* FILA 14 */}
              <div className="row-flex">
                {/* ACTIVO */}
                <div className="col-5"><strong>Activo</strong></div>
                <div className="col-4">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="activo"
                    checked={stateForm.activo}
                    onChange={({ target }) => setStateForm((val) => ({ ...val, activo: target.checked }))} />
                </div>
              </div>
              <hr />
              {/* Botones para guardar y cancelar */}
              <div className="row-flex m-y-small j-content-space-around">
                <button type="button" className="btn-primary" onClick={onOpenSaveModal}>{t("createCustomerComp.btnSave")}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {
                state.showModal && (
                  <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModal: false }))}>
                    <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                      {/* Question mark */}
                      <div className="row-flex m-y-small align-c justify-center">
                        <div className="question-mark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                          </svg>
                        </div>
                      </div>
                      <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                        {state.questionAction}
                      </h5>
                      {/* Botones de accion */}
                      <div className="row-flex m-y-medium j-content-space-around">
                        <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                        <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </form>
          </div>
        </div>

      </div>
    </>
  )
};