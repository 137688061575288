import React, { useEffect, useState } from "react";

// ----> Import for translation
import { useTranslation } from "react-i18next";

// ----> Imports from react-router-dom
import { useLocation, useNavigate, useParams } from "react-router-dom";


// ----> COMPONENTS IMPORTATIONS
import { LoadingComp } from "../Loading-comp/LoadingComp";
import { ContactsComp } from "./ContactsComp";
import { EquipmentsList } from "./EquipmentsList";

// ----> URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";


// ----> GLOBAL FUNCTION
import { getTheToken } from "../../global-functions/globalFunctions";


// ----> CUSTOM STYLES
import "../../components/customer-components/CustomerDetailsComp.css";




export const CustomerDetailsComp = () => {

  const { id } = useParams();

  // ----> fetching data
  // const { customerData, isLoading, errorMsg } = useFetchCustomerDetail(`${urls.getCustomer}/${id}`);

  // ----> Destructuring of react-i18next
  const navigate = useNavigate();

  // location from react router dom
  const location = useLocation();


  // ----> Destructuring useTranslation() Hook
  const [t] = useTranslation("global");


  // ----> language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [isLoading, setIsLoading] = useState(false);
  const [dataVisible, setDataVisible] = useState(false);


  // DESTRUCTURING DATA SERVER
  const { customers } = dataServer && dataServer;




  // console.log(customerData[2])

  const fetchDataCustomerById = async () => {
    const END_POINT = `${urls.getCustomer}/${id}/search?language=${language}&action=1`;

    const abortController = new AbortController();

    setController(abortController);

    try {
      setIsLoading(true);
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setDataVisible(true);
      } else if (response.status === 400) {
        // setErrorMsg(labelBadRequest);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        // setErrorMsg(labelNotFound);
      } else if (response.status === 500) {
        // ocurrio un error en el navegador
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // ----> fetching data
  useEffect(() => {

    fetchDataCustomerById();

    document.title = language === "es" ? "Detalle Clientes - GIS APP" : "Customer Detail - GIS APP"

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, id]);


  return (
    <>

      {isLoading && (<LoadingComp />)}


      <div className="display-data">
        <>
          {
            dataVisible && (
              <>
                <div className="container"> {/* ----> Container <---- */}
                  <div className="customer-container-detail"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                    }}
                  >

                    {/* Nombre del cliente */}
                    <div>
                      <h1 style={{ textAlign: 'center', color: 'var(--first-color)' }}>{customers && customers[0]?.Nombre}</h1>
                    </div>
                    <hr />

                    {/* Fila para el RNC del cliente */}
                    <div className="row-flex"
                      style={{ gap: 'var(--gap-row-flex)' }}
                    >
                      <div style={{ width: 'var(--width-column-customers-view)' }}>
                        <strong>
                          {t("customerView.taxId")}
                        </strong>
                      </div>
                      <div>
                        <span>
                          {customers && customers[0]?.CodCliente}
                        </span>
                      </div>
                    </div>
                    {/* Fila para la dirección del cliente */}
                    <div className="row-flex"
                      style={{ gap: 'var(--gap-row-flex)' }}
                    >
                      <div style={{ width: 'var(--width-column-customers-view)' }}>
                        <strong>
                          {t("customerView.address")}
                        </strong>
                      </div>
                      <div>
                        <span>
                          {customers && customers[0]?.AveCalle} {customers && customers[0]?.UrbSector} {customers && customers[0]?.NomCiudad}
                        </span>
                      </div>
                    </div>
                    {/* Fila para el telefono del cliente */}
                    <div className="row-flex"
                      style={{ gap: 'var(--gap-row-flex)' }}
                    >
                      <div style={{ width: 'var(--width-column-customers-view)' }}>
                        <strong>
                          {t("customerView.telephone")}
                        </strong>
                      </div>
                      <div>
                        <span>
                          {customers && customers[0]?.CodTelefono} {customers && customers[0]?.Telefono}
                        </span>
                      </div>
                    </div>
                    {/* Fila para el correo del cliente */}
                    <div className="row-flex" style={{ gap: 'var(--gap-row-flex)' }}>
                      <div style={{ width: 'var(--width-column-customers-view)' }}>
                        <strong>
                          {t("customerView.email")}
                        </strong>
                      </div>
                      <div>
                        <span style={{ color: 'blue', textDecoration: 'underline' }}>
                          {customers && customers[0]?.Correo}
                        </span>
                      </div>
                    </div>
                    {/* Fila para la temperatura (consultar si esto es necesario) */}

                    {/* Fila para la página web */}
                    <div className="row-flex" style={{ gap: 'var(--gap-row-flex)' }}
                    >
                      <div style={{ width: 'var(--width-column-customers-view)' }}>
                        <strong>
                          {t("customerView.webPage")}
                        </strong>
                      </div>
                      <div>
                        <span>
                          <a href={customers[0]?.PagWeb} target="_blank" rel="noopener noreferrer">{customers && customers[0]?.PagWeb}</a>
                        </span>
                      </div>
                    </div>

                  </div>
                  {/* Fila para BTN para modificar los datos del cliente */}
                  <div className="row-flex m-y-small j-content-space-around">
                    <button className="btn-primary" onClick={() => navigate(`/customers/customer-modify/${id}`)}>
                      {t("customerView.btnModify")}
                    </button>
                    <button className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>
                      {t("navigationMenu.backBtn")}
                    </button>
                  </div>
                  <hr />

                  <ContactsComp idCus={id} lang={language} />

                  {/* ----> EQUIPMENTS <---- */}
                  <EquipmentsList idCus={id} lang={language} token={getTheToken().token} action={"1"} />
                </div>
              </>
            )
          }
        </>
      </div>
    </>
  )
};