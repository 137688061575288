import React, { useEffect, useState } from "react";

// COMPONENTS
import { SolicitudesPendientesDePrecio } from "../../components/logistic-components/SolicitudesPendientesDePrecio";
import { OrdePendPorColocar } from "../../components/logistic-components/OrdePendPorColocar";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { SegOrdComp } from "../../components/logistic-components/SegOrdComp";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// STYLES IMPORTATION
import "../../views/logistic/LogisticView.css";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken } from "../../global-functions/globalFunctions";

// IMPORT TRANSLATATION FROM REACT
import { useTranslation } from "react-i18next";



export const LogisticView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  const token = getTheToken().token;

  // REACT TRANSLATION
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // para cuando este cargando la data
  const [controller, setController] = useState(null); // estado para cargar el controlador para abortar la peticion al servidor
  const [params, setParams] = useSearchParams(); // params de la url


  const { empresa, zona, empPermm } = dataServer;



  // const codEmpresa = empresaPertenece && empresaPertenece.length > 0 ? empresaPertenece[0].CodDYN : undefined;

  // ONCHANGE PARA CAMBIAR LA COMPANIA
  const onChangeSelectCompany = ({ target }) => {
    params.set("filt", 1);
    params.set("emp", target.value);
    params.set("area", "");
    setParams(params);
    setSelectCompany(target.value);
    setSelectZona("");
    // fetchDataLogistic();
  };

  // ONCHANGE PARA CAMBIAR LA ZONA
  const onChangeSelectZona = ({ target }) => {
    params.set("area", target.value);
    params.set("filt", 1);
    params.set("emp", selectCompany);
    setParams(params);
    setSelectZona(target.value);
    // localStorage.setItem("codZona", target.value);
    // fetchDataLogistic();
  };



  const [selectCompany, setSelectCompany] = useState(params.get("emp") ?? ""); // estado para la empresa
  const [selectZona, setSelectZona] = useState(params.get("area") ?? ""); // estado para la zona


  // funcion para realizar la peticion al servidor
  const fetchDataLogistic = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setIsLoading(true);
      const response = await fetch(`${urls.logisticPrincipal}?empresa=${selectCompany}&zona=${selectZona}&language=${language}&filt=${params.get("filt") ?? "0"}`, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();
      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }
      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect PARA TRAER LA DATA
  useEffect(() => {

    // PETICION AL BACKEND
    fetchDataLogistic();

    // CAMBIAR EL TITUTLO DEL DOCUMENTO
    document.title = language === "es" ? "Logística - Principal" : "Logistic - Principal";

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);



  useEffect(() => {
    if (empPermm && params.get("filt") === null) {
      setSelectCompany(empPermm[0]?.CodDYN);
      setSelectZona(empPermm[0]?.CodZona);
    }
  }, [empPermm]);






  return (
    <div className="container display-data">
      {isLoading && (<LoadingComp />)}
      {/* CAMBIO DE LAS ZONAS */}
      <div className="row-flex j-content-space-b">
        {/* CAMBIAR EMPRESA */}
        <div className="col-sm-35">
          <strong style={{ color: 'var(--first-color)' }}>{t("LogisticView.changeCompany")}</strong>
        </div>
        <div className="col-3 col-sm-50">
          <select
            name="company"
            value={selectCompany}
            onChange={onChangeSelectCompany}>
            <option value=""></option>
            {
              empresa && empresa.map((empr) => (
                <option key={empr.CodEmpresa} value={empr.CodDYN}>{empr.Nombre}</option>
              ))
            }
          </select>
        </div>
        {/* CAMBIAR LA ZONA */}
        <div className="col-sm-35">
          <strong style={{ color: 'var(--first-color)' }}>{t("LogisticView.changeArea")}</strong>
        </div>
        <div className="col-3 col-sm-50">
          <select
            name="codZon"
            value={selectZona}
            onChange={onChangeSelectZona}>
            <option></option>
            {
              zona && zona.filter((zon) => zon.CodDYN === selectCompany).map((element) => (
                <option key={element.CodZona} value={element.CodZona}>{element.NomZona}</option>
              ))
            }
          </select>
        </div>
      </div>
      {/* SOLICITUDES POR PRECIO */}
      <div className="m-y-small">
        <h4 style={{ color: 'var(--first-color)' }}>{t("LogisticView.requestPrice")}</h4>
      </div>
      {/* TABLA DE LAS SOLICITUDES DE PRECIO */}
      <SolicitudesPendientesDePrecio
        lang={language}
        area={selectZona}
        company={selectCompany}
        token={getTheToken().token} />

      {/* ORDENES PENDIENTES POR COLOCAR */}
      <div className="m-y-medium">
        <h4 style={{ color: 'var(--first-color)' }}>{t("LogisticView.orderPending")}</h4>
      </div>
      <OrdePendPorColocar
        lang={language}
        area={selectZona}
        company={selectCompany}
        token={getTheToken().token} />

      {/* SEGUIMIENTO DE ORDENES */}
      <div className="m-y-medium">
        <h4 style={{ color: 'var(--first-color)' }}>{t("LogisticView.followOrder")}</h4>
      </div>

      <SegOrdComp
        lang={language}
        area={selectZona}
        company={selectCompany}
        token={getTheToken().token} />

      {/* FINAL DEL CONTENEDOR */}
    </div>
  );
};