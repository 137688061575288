import React, { useEffect, useState } from "react";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error403View } from "../error-403-view/Error403View";
import { Error500Comp } from "../../components/errors/Error500Comp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { SearchEmplDyn } from "../../components/human-resources/SearchEmplDyn";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// ESTA FOTO ES PARA CUANDO HAYA UN EMPLEADO QUE NO TENGA FOTOS, SE LE ASIGNA ESTE
import FotoUser from "../../assets/perfil.JPG";

// USE TRANSLATION
import { useTranslation } from "react-i18next";
import { UploadFotoUser } from "../../components/human-resources/UploadFotoUser";



export const ModifyEmployee = () => {

  // LANGUAGE
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // translation
  const [t] = useTranslation("global");

  // use params
  const { id } = useParams();

  // navigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // STATE DECLARATION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la data del servidor
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, errorServer: false, modalDyn: false, searchEmpl: "", modalUpload: false });


  // DESTRUCTURING DATA SERVER 
  const { employee, RestInfo } = dataServer && dataServer;

  // STATE DEFINITIONS FOR THE INPUTS INFO
  const [stateInputs, setStateInputs] = useState({
    nombre: "", apellido: "", codLider: "", codDivision: "", codLinea: "", codZona: "", codPerfil: "", codNivCargo: "", coordinador: false, codEstado: "", codCiudad: "",
    codGerencia: "", codUnidad: "", unidad: "", cargo: "", inicial: "", cedula: "", aveCalle: "", ediQta: "", urbSector: "", codPostal: "", codTelefono: "", telefono: "",
    codCelular: "", celular: "", fecNacimiento: "", lugNacimiento: "", profesion: "", estCivil: "", correo: "", fecIngEmpresa: "", SSO: "", token: "", nomEmergencia: "", parentesco: "",
    codTelEmergencia: "", telEmergencia: "", nivViatico: "", activo: false, codEmpIngreso: "", codEmpModificacion: "", fecIngreso: "", fecModificacion: "", codEmpresa: "", extension: "",
    vacaciones: false, codPais: "", FESSO: "", numCuenta: "", empIng: "", empModf: "", diviEmpre: "", licenciaEnfer: "", dynamics: ""
  });



  // funcion para cerrar el modal de buscar en dyn
  const onCloseModalDyn = () => {
    setState((val) => ({ ...val, modalDyn: false }));
  };


  // funcion para cerrar el modal de cargar la foto
  const onCloseModalUpload = () => {
    setState((val) => ({ ...val, modalUpload: false }));
  };


  // funcion para actualizar el codigo del dyn desde el hijo
  const onUpdateDynCodeFromChild = (cod = "") => {
    setStateInputs((val) => ({ ...val, dynamics: cod }));
  };


  // funcion para recibir notificaciones desde el componente hijo
  const onSetNotificationFromChild = (msg = "", error = false) => {
    if (error) {
      setNotification((val) => ({ ...val, show: true, msg, error: true }));
    } else {
      setNotification((val) => ({ ...val, show: true, msg, great: true }));
    }
  };


  // FETCH DATA TO SERVER
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.employees}/${id}/search?language=${language}`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        // esto es porque el usuario no tiene el permiso para ver esta informacion
        setState((val) => ({ ...val, unauthorized: true }));
      } else if (response.status === 500) {
        // ocurrió un error en el servidor
        setState((val) => ({ ...val, errorServer: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };



  // useEffect declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };


  }, [id, language]);


  // useEffect para recargar la data del servidor
  useEffect(() => {

    if (notification.great) {

      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };

    }

  }, [notification.great]);




  // colocar la data del server en el state
  useEffect(() => {
    if (employee && employee.length > 0) {

      setStateInputs((val) => ({
        ...val, nombre: employee[0]?.Nombre, apellido: employee[0]?.Apellido, codLider: employee[0]?.CodLider, codDivision: employee[0]?.CodDivision, codLinea: employee[0]?.CodLinea, codZona: employee[0]?.CodZona, codPerfil: employee[0]?.CodPerfil, codNivCargo: employee[0]?.CodNivCargo, coordinador: employee[0]?.Coordinador, codEstado: employee[0]?.CodEstado, codCiudad: employee[0]?.CodCiudad,
        codGerencia: employee[0]?.CodGerencia, codUnidad: employee[0]?.CodUnidad, unidad: employee[0]?.Unidad, cargo: employee[0]?.Cargo, inicial: employee[0]?.Inicial, cedula: employee[0]?.Cedula, aveCalle: employee[0]?.AveCalle, ediQta: employee[0]?.EdiQta, urbSector: employee[0]?.UrbSector, codPostal: employee[0]?.CodPostal, codTelefono: employee[0]?.CodTelefono, telefono: employee[0]?.Telefono,
        codCelular: employee[0]?.CodCelular, celular: employee[0]?.Celular, fecNacimiento: employee[0]?.FecNacimiento.slice(0, 10), lugNacimiento: employee[0]?.LugNacimiento, profesion: employee[0]?.Profesion, estCivil: employee[0]?.EstCivil, correo: employee[0]?.Correo, fecIngEmpresa: employee[0]?.FecIngEmpresa.slice(0, 10), SSO: employee[0]?.SSO, token: employee[0]?.Token, nomEmergencia: employee[0]?.NomEmergencia, parentesco: employee[0]?.Parentesco,
        codTelEmergencia: employee[0]?.CodTelEmergencia, telEmergencia: employee[0]?.TelEmergencia, nivViatico: employee[0]?.NivViatico, activo: employee[0]?.Activo, codEmpIngreso: employee[0]?.CodEmpIngreso, codEmpModificacion: employee[0]?.CodEmpModificacion, fecIngreso: employee[0]?.FecIngreso.slice(0, 10), fecModificacion: employee[0]?.FecModificacion, codEmpresa: employee[0]?.CodEmpresa, extension: employee[0]?.Extension,
        vacaciones: employee[0]?.Vacaciones, codPais: employee[0]?.CodPais, FESSO: employee[0]?.FESSO, numCuenta: employee[0]?.NumCuenta, empIng: employee[0]?.EmpIngreso, empModf: employee[0]?.EmpModificacion, diviEmpre: employee[0]?.CodGrupo, licenciaEnfer: employee[0]?.Licencia, dynamics: employee[0]?.CodLiteSyt ?? ""
      }));
    }
  }, [employee]);

  // console.log(grupOcupacional)


  // useEffect para enviar las actualizaciones al servidor
  const onSubmitUpdateEmployee = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${urls.employees}/${id}/search?language=${language}&action=1`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": getTheToken().token
        },
        body: JSON.stringify(stateInputs)
      });

      const data = await response.json();


      if (response.status === 200) {
        // setErrorServer(false);
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 500) {
        // setErrorServer(true);
      }
    } catch (error) {
      console.log(error)
    }

  };

  // useEffect PARA LIMPIAR LAS NOTIFICACIONES
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000)

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);

  // console.log(RestInfo && RestInfo[0]?.allEmployees)



  return (
    <>
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }

      {
        state.errorServer && (<Error500Comp />)
      }

      {
        state.unauthorized && (<Error403View />)
      }
      {
        state.isLoading && (<LoadingComp />)
      }

      {
        // modal para buscar empleado en dyn
        state.modalDyn && (<SearchEmplDyn nomEmpl={stateInputs.nombre} token={getTheToken().token} onClose={onCloseModalDyn} lang={language} codEmpl={id} setNotification={onSetNotificationFromChild} setCodeDyn={onUpdateDynCodeFromChild} />)
      }

      {
        // modal para cargar las fotos
        state.modalUpload && (<UploadFotoUser onClose={onCloseModalUpload} token={getTheToken().token} codEmpl={id} lang={language} setNotify={onSetNotificationFromChild} />)
      }

      {
        state.dataVisible && (
          <>
            <div className="container">
              <form onSubmit={onSubmitUpdateEmployee}>
                {/* FILA 1 FOTO DEL USUARIO*/}
                <div className="row-flex j-content-space-b">
                  <div className="user-name">
                    <h3 style={{ color: 'var(--first-color)' }}>{stateInputs.nombre} {stateInputs.apellido}</h3>
                  </div>
                  <div className="row-flex align-c gap-small">
                    {
                      employee && employee[0]?.Foto ? <img className="im-employees-view" src={`data:image/jpeg;base64,${employee[0]?.Foto}`} alt={employee && employee[0]?.Nombre} /> : <img alt="Avatar" src={FotoUser} />
                    }
                    <button type="button" style={{ cursor: "pointer" }} onClick={() => setState((val) => ({ ...val, modalUpload: true }))}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--first-color)" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z" />
                        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466" />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Dynamics</strong>
                  </div>
                  <div className="col-7">
                    <input
                      className="input-text-control"
                      type="text"
                      name="dynamics"
                      value={stateInputs.dynamics}
                      readOnly
                      onFocus={() => setState((val) => ({ ...val, modalDyn: true }))} />
                  </div>
                  <div className="col-10">
                    <button type="button" className="btn-delete" onClick={() => setStateInputs((val) => ({ ...val, dynamics: "" }))}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="row-flex">
                  {/* NOMBRE */}
                  <div className="col-7">
                    <strong>{t("humanResources.name")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      type="text"
                      name="Nombre"
                      value={stateInputs.nombre}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, nombre: target.value }))}
                      autoComplete="off" />
                  </div>
                  {/* INICIALES */}
                  <div className="col-7">
                    <strong>{t("humanResources.lastName")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="Apellido"
                      type="text"
                      value={stateInputs.apellido}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, apellido: target.value }))} />
                  </div>
                </div>

                {/* FILA 3 */}
                <div className="row-flex">
                  {/* CEDULA */}
                  <div className="col-7">
                    <strong>{t("humanResources.idCardLabel")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="Cedula"
                      type="text"
                      value={stateInputs.cedula}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, cedula: target.value }))} />
                  </div>
                  {/* FECHA NACIMIENTO */}
                  <div className="col-7">
                    <strong>{t("humanResources.dateOfBirth")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecNacimiento"
                      value={stateInputs.fecNacimiento}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, fecNacimiento: target.value }))} /></div>
                </div>

                {/* FILA 4 */}
                <div className="row-flex">
                  {/* ESTADO CIVIL */}
                  <div className="col-7">
                    <strong>{t("humanResources.maritalStatus")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="EstCivil"
                      type="text"
                      value={stateInputs.estCivil}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, estCivil: target.value }))} />
                  </div>
                  {/* PROFESION */}
                  <div className="col-7">
                    <strong>{t("humanResources.profession")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="Profesion"
                      type="text"
                      value={stateInputs.profesion}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, profesion: target.value }))} />
                  </div>
                </div>

                {/* FILA 5 */}
                <div className="row-flex">
                  {/* TELEFONO */}
                  <div className="col-7">
                    <strong>{t("humanResources.telephoneLabel")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      style={{ width: '20%' }}
                      className="input-text-control"
                      name="CodTelefono"
                      type="text"
                      value={stateInputs.codTelefono}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codTelefono: target.value }))} />
                    <input
                      style={{ width: '75%', marginLeft: '5%' }}
                      className="input-text-control"
                      name="Telefono"
                      type="text"
                      value={stateInputs.telefono}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, telefono: target.value }))}
                      autoComplete="off" />
                  </div>
                  {/* TELEFONO FLOTA */}
                  <div className="col-7">
                    <strong>{t("humanResources.phoneCompany")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      style={{ width: '20%' }}
                      className="input-text-control"
                      name="CodCelular"
                      type="text"
                      value={stateInputs.codCelular}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codCelular: target.value }))} />
                    <input
                      style={{ width: '75%', marginLeft: '5%' }}
                      className="input-text-control"
                      name="Celular"
                      type="text"
                      value={stateInputs.celular}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, celular: target.value }))}
                      autoComplete="off" />
                  </div>
                  {/* EXT */}
                  <div className="col-7">
                    <strong>Ext.</strong>
                    <input
                      style={{ width: '50%', marginLeft: '5%' }}
                      className="input-text-control"
                      name="Extension"
                      type="text"
                      value={stateInputs.extension}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, extension: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                {/* FILA 6 */}
                <div className="row-flex">
                  {/* SSO GE */}
                  <div className="col-7">
                    <strong>SSO GE</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="SSO"
                      type="text"
                      value={stateInputs.SSO}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, SSO: target.value }))}
                      autoComplete="off" />
                  </div>
                </div>

                {/* DIRECCION DE RESIDENCIA */}
                <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">{t("humanResources.addressResidence")}</h4>
                <hr />

                {/* FILA 7 */}
                <div className="row-flex">
                  {/* AVENIDA / CALLE */}
                  <div className="col-7">
                    <strong>{t("humanResources.avenueStreet")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="AveCalle"
                      type="text"
                      value={stateInputs.aveCalle}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, aveCalle: target.value }))} />
                  </div>
                  {/* EDIF/QUINTA */}
                  <div className="col-7">
                    <strong>{t("humanResources.building")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="EdiQta"
                      type="text"
                      value={stateInputs.ediQta}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, ediQta: target.value }))} />
                  </div>
                </div>

                {/* FILA 8 */}
                <div className="row-flex">
                  {/* URBANIZACION/SECTOR */}
                  <div className="col-7">
                    <strong>{t("humanResources.urbaSector")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="UrbSector"
                      type="text"
                      value={stateInputs.urbSector}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, urbSector: target.value }))} />
                  </div>
                  {/* CODIGO POSTAL */}
                  <div className="col-7">
                    <strong>{t("humanResources.postalCode")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="CodPostal"
                      type="text"
                      value={stateInputs.codPostal}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codPostal: target.value }))} />
                  </div>
                </div>

                {/* FILA 9 */}
                <div className="row-flex">
                  {/* PROVINCIA */}
                  <div className="col-7">
                    <strong>{t("humanResources.regionLabel")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="CodEstado"
                      value={stateInputs.codEstado}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codEstado: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.estado.map((est) => (
                          <option key={est.CodEstado} value={est.CodEstado}>{est.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* CIUDAD */}
                  <div className="col-7">
                    <strong>{t("humanResources.cityLabel")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="CodCiudad"
                      value={stateInputs.codCiudad}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codCiudad: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.allCities.map((city) => (
                          <option key={city.CodCiudad} value={city.CodCiudad}>{city.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* EN CASO DE EMERGENCIA */}
                <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">{t("humanResources.emergencyLabel")}</h4>
                <hr />

                {/* FILA 10 */}
                <div className="row-flex">
                  {/* NOMBRE DEL CONTACTO EMERGENCIA */}
                  <div className="col-7">
                    <strong>{t("humanResources.name")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="NomEmergencia"
                      type="text"
                      value={stateInputs.nomEmergencia}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, nomEmergencia: target.value }))}
                      autoComplete="off" />
                  </div>
                  {/* PARENTESCO */}
                  <div className="col-7">
                    <strong>{t("humanResources.relationShip")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="Parentesco"
                      type="text"
                      value={stateInputs.parentesco}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, parentesco: target.value }))} />
                  </div>
                </div>

                {/* FILA 11 */}
                <div className="row-flex">
                  {/* TELEFONO */}
                  <div className="col-7">
                    <strong>{t("humanResources.telephoneLabel")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      style={{ width: '20%' }}
                      className="input-text-control"
                      name="CodTelEmergencia"
                      type="text"
                      value={stateInputs.codTelEmergencia}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codTelEmergencia: target.value }))} />
                    <input
                      style={{ width: '75%', marginLeft: '5%' }}
                      className="input-text-control"
                      name="TelEmergencia"
                      type="text"
                      value={stateInputs.telEmergencia}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, telEmergencia: target.value }))} />
                  </div>
                </div>

                {/* PERFIL */}
                <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">{t("humanResources.profile")}</h4>
                <hr />

                {/* FILA 12 */}
                <div className="row-flex">
                  {/* DIVISION PRODUCTOR */}
                  <div className="col-7">
                    <strong>{t("humanResources.productDivision")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="division"
                      value={stateInputs.codDivision}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codDivision: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.division.map((divi) => (
                          <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* LINEA PRODUCTO */}
                  <div className="col-7">
                    <strong>{t("humanResources.productLine")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="linPro"
                      value={stateInputs.codLinea}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codLinea: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.linea.filter((filtLin) => filtLin.CodDivision === stateInputs.codDivision).map((lin) => (
                          <option key={lin.CodLinea} value={lin.CodLinea}>{lin.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA 13 */}
                <div className="row-flex">
                  {/* ZONA */}
                  <div className="col-7">
                    <strong>{t("humanResources.zone")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="zona"
                      value={stateInputs.codZona}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codZona: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.areas.map((zon) => (
                          <option key={zon.CodZona} value={zon.CodZona}>{zon.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA 14 */}
                <div className="row-flex">
                  {/* PERFIL */}
                  <div className="col-7">
                    <strong>{t("humanResources.profile")}</strong>
                  </div>
                  {
                    RestInfo && RestInfo[0]?.empPerm[0]?.CodPerfil === 6 ? (
                      <div className="col-4">
                        <select
                          name="CodPerfil"
                          value={stateInputs.codPerfil}
                          onChange={({ target }) => setStateInputs((val) => ({ ...val, codPerfil: target.value }))}>
                          <option value=""></option>
                          {
                            RestInfo && RestInfo[0]?.perfiles.map((per) => (
                              <option key={per.CodPerfil} value={per.CodPerfil}>{per.Descripcion}</option>
                            ))
                          }
                        </select>
                      </div>
                    ) : (<div className="col-4"><strong>{language === "en" ? "Contact to the admin to modify the profile to the employee" : "Contacte al administrador para modificar el perfil al empleado"}</strong></div>)
                  }
                  {/* GERENTE / SUPERVISOR */}
                  <div className="col-7">
                    <strong>{t("humanResources.supervisor")}</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="coordinador"
                      checked={stateInputs.coordinador}
                      onChange={({ target }) => setStateInputs({ ...stateInputs, coordinador: target.checked })} />
                    <input
                      style={{ marginLeft: '5%', width: '90%' }}
                      className="input-text-control"
                      type="text" />
                  </div>
                </div>

                {/* FILA 15 */}
                <div className="row-flex">
                  <div className="col-7">
                    <strong>{t("humanResources.spending")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="viatico"
                      value={stateInputs.nivViatico}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, nivViatico: target.value }))}>
                      <option value=""></option>
                      <option value="0">{t("humanResources.employee")}</option>
                      <option value="1">Supervisor</option>
                      <option value="2">{t("humanResources.manager")}</option>
                      <option value="3">Director</option>
                      <option value="4">{t("humanResources.adminAssis")}</option>
                      <option value="5">{t("humanResources.payouts")}</option>
                    </select>
                  </div>
                </div>

                {/* DATOS DE LA EMPRESA */}
                <h4 style={{ color: 'var(--first-color)' }} className="m-y-small">{t("humanResources.companyData")}</h4>
                <hr />
                {/* FILA 16 */}
                <div className="row-flex">
                  {/* EMPRESA */}
                  <div className="col-7">
                    <strong>{t("humanResources.company")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="CodEmpresa"
                      value={stateInputs.codEmpresa}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codEmpresa: target.value }))}>
                      <option value=""></option>
                      {RestInfo && RestInfo[0]?.company.map((comp) => (
                        <option key={comp.CodEmpresa} value={comp.CodEmpresa}>{comp.Nombre}</option>
                      ))
                      }
                    </select>
                  </div>
                  {/* DIVISION */}
                  <div className="col-7">
                    <strong>{t("humanResources.division")}</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="divisionEmp"
                      value={stateInputs.diviEmpre}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, diviEmpre: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.divisionEmpre.map((divi) => (
                          <option key={divi.CodGrupo} value={divi.CodGrupo}>{divi.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA 17 */}
                <div className="row-flex">
                  {/* GERENCIA */}
                  <div className="col-7">
                    <strong>Gerencia</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="CodGerencia"
                      value={stateInputs.codGerencia}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codGerencia: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.gerencia.map((gere) => (
                          <option key={gere.CodGerencia} value={gere.CodGerencia}>{gere.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* AREA FUNCIONAL */}
                  <div className="col-7">
                    <strong>Area Funcional</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="areaFunc"
                      value={stateInputs.codUnidad}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codUnidad: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.areaFunc.filter((filtUni) => filtUni.CodGerencia.toString() === stateInputs.codGerencia.toString()).map((unid) => (
                          <option key={unid.CodUnidad} value={unid.CodUnidad}>{unid.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA 18 */}
                <div className="row-flex">
                  {/* GRUPO OCUPACIONAL */}
                  <div className="col-7">
                    <strong>Grupo Ocupacional</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="codNivCargo"
                      value={stateInputs.codNivCargo}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codNivCargo: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.grupOcupacional.map((grupOcup) => (
                          <option key={grupOcup.CodNivCargo} value={grupOcup.CodNivCargo}>{grupOcup.Descripcion}</option>
                        ))
                      }
                    </select>
                  </div>
                  {/* UNIDAD */}
                  <div className="col-7">
                    <strong>Unidad</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      type="text"
                      name="unidad"
                      value={stateInputs.unidad}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, unidad: target.value }))} />
                  </div>
                </div>

                {/* FILA 19 */}
                <div className="row-flex">
                  {/* CARGO */}
                  <div className="col-7">
                    <strong>Cargo</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="Cargo"
                      type="text"
                      value={stateInputs.cargo}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, cargo: target.value }))} />
                  </div>
                  {/* SUPERVISOR */}
                  <div className="col-7">
                    <strong>Supervisor</strong>
                  </div>
                  <div className="col-4">
                    <select
                      name="CodLider"
                      value={stateInputs.codLider}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, codLider: target.value }))}>
                      <option value=""></option>
                      {
                        RestInfo && RestInfo[0]?.allEmployees.map((emplo) => (
                          <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                {/* FILA 20 */}
                <div className="row-flex">
                  {/* CORREO */}
                  <div className="col-7">
                    <strong>Correo</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      name="Correo"
                      type="text"
                      value={stateInputs.correo}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, correo: target.value }))}
                      autoComplete="off" />
                  </div>
                  {/* FECHA INGRESO EMPRESA */}
                  <div className="col-7">
                    <strong>Fecha Ingreso</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecIngEmpresa"
                      value={stateInputs.fecIngEmpresa}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, fecIngEmpresa: target.value }))} />
                  </div>
                </div>

                {/* FILA 21 */}
                <div className="row-flex">
                  {/* CUENTA DE BANCO */}
                  <div className="col-7">
                    <strong>Cuenta de Banco</strong>
                  </div>
                  <div className="col-4">
                    <input
                      className="input-text-control"
                      type="text"
                      name="numCuenta"
                      value={stateInputs.numCuenta}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, numCuenta: target.value }))} />
                  </div>
                  {/* GENERAR EVALUACION */}
                  <div className="col-7">
                    <strong>General Evaluación Inicial</strong>
                  </div>
                </div>

                {/* FILA 22 */}
                <div className="row-flex m-y-small">
                  {/* ACTIVO */}
                  <div className="col-7">
                    <strong>Activo</strong>
                  </div>
                  <div className="col-10">
                    <input
                      className="input-control-checkbox"
                      name="Activo"
                      type="checkbox"
                      checked={stateInputs.activo}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, activo: target.checked }))} />
                  </div>
                  {/* LLEVAR VACACIONES */}
                  <div className="col-7">
                    <strong>Llevar Vacaciones</strong>
                  </div>
                  <div className="col-10">
                    <input
                      className="input-control-checkbox"
                      name="Vacaciones"
                      type="checkbox"
                      checked={stateInputs.vacaciones}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, vacaciones: target.checked }))} />
                  </div>
                  <div className="col-7">
                    <strong>Licencia enfermedad</strong>
                  </div>
                  <div className="col-10">
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="licenciaEnfer"
                      checked={stateInputs.licenciaEnfer}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, licenciaEnfer: target.checked }))} />
                  </div>
                </div>

                {/* FILA 23 */}
                <div className="row-flex">
                  {/* INGRESA POR */}
                  <div className="col-7">
                    <strong>Ingresa Por</strong>
                  </div>
                  <div className="col-4">
                    <span>{stateInputs.empIng}</span>
                  </div>
                  {/* MODIFICADO POR */}
                  <div className="col-7">
                    <strong>Modificado Por</strong>
                  </div>
                  <div className="col-4">
                    <span>{stateInputs.empModf}</span>
                  </div>
                </div>

                {/* FILA 24 */}
                <div className="row-flex">
                  {/* FECHA INGRESO */}
                  <div className="col-7">
                    <strong>Fecha Ingreso</strong>
                  </div>
                  <div className="col-4">
                    <span>{employee && renderDate(employee[0]?.FecIngreso, language)}</span>
                  </div>
                  {/* FECHA MODIFICACION */}
                  <div className="col-7">
                    <strong>Fecha Modificación</strong>
                  </div>
                  <div className="col-4">
                    <span>{employee && renderDate(employee[0]?.FecModificacion, language)}</span>
                  </div>
                </div>

                {/* FILA 25 BOTONES */}
                <div className="row-flex j-content-space-around m-y-small">
                  <button type="submit" className="btn-primary">Guardar</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Cancelar</button>
                  {RestInfo && RestInfo[0]?.empPerm[0]?.CodPerfil === 6 ? <button className="btn-warning">Reiniciar Clave</button> : null}
                </div>
              </form>
            </div>
          </>
        )
      }

    </>
  );
};