
import React, { useEffect, useState } from "react";


// components importation
import { Error403View } from "../error-403-view/Error403View";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";




// REACT ROUTER DOM IMPORTATION
import { useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { useTranslation } from "react-i18next";


export const AssetRequestConsumablesView = () => {

  // GET the language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);


  // GET the react translation
  const [t] = useTranslation("global");


  // useNavigate
  const navigate = useNavigate();


  // useParams
  const { idCall } = useParams();


  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, msg: "", modalB: false, loading: false, questionAction: "" });
  const [stateForm, setStateForm] = useState({ fecReque: "", hora: "", devolucion: "", envio: "", direccion: "", ciudad: 0, estado: 0, cliente: "", codServ: 0 });
  const [errors, setErrors] = useState({ fecRequerida: "", envio: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false, codSol: 0 });

  // destructuring data server
  const { initData } = dataServ && dataServ;



  // funcion para abrir el modal de guardar
  const onHandleSaveModal = () => {
    for (const clave in stateForm) {
      if (["fecReque", "envio"].includes(clave)) {
        if (stateForm[clave] === "") {
          return setNotification((val) => ({ ...val, show: true, msg: t("Viaticos.campos_reque"), error: true }));
        }
      }
    }

    setState((val) => ({ ...val, modalB: true, questionAction: t("serviceCard.seguro_crear_sol") }));
  };



  // funcion para mostrar mensajes de error
  const onHandleBlur = (event) => {
    const { name, value } = event.target;

    if (value.trim() === "") {
      setErrors((val) => ({ ...val, [name]: t("LogisticViewComp.campo_vacio") }));
    } else {
      setErrors((val) => ({ ...val, [name]: "" }));
    }
  };


  // funcion para enviar los datos al servidor
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, loading: true }));
      const response = await fetch(`${urls.requestTools}/${idCall}/search?language=${language}&action=1`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateForm)
      });

      const data = await response.json();

      if (response.status === 201) {
        setState((val) => ({ ...val, modalB: false }));
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true, codSol: data?.codSol }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

      setState((val) => ({ ...val, loading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para traer la data del servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.requestTools}/${idCall}/search?language=${language}&action=1`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, msg: data?.msg }));
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idCall]);



  // useEffect para cambiar el valor de la dirección
  useEffect(() => {
    if (initData && initData.length > 0) {
      const DirEnvio = {
        0: t("serviceCard.enviar_dhl"),
        1: t("serviceCard.enviar_mrw"),
        2: t("serviceCard.enviar_fedex"),
        3: t("serviceCard.person"),
        4: `${t("serviceCard.enviar_cliente")} ${initData[0]?.DirCliente}`
      };

      setStateForm((val) => ({ ...val, direccion: DirEnvio[stateForm.envio] ?? "", ciudad: initData[0]?.CodCiudad, estado: initData[0]?.CodEstado, cliente: initData[0]?.CodCliente, codServ: initData[0]?.CodServicio }));
    }
  }, [stateForm.envio, initData]);



  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para redirigir al detalle de la herramienta
  useEffect(() => {
    if (notification.success) {
      const timer = setTimeout(() => {
        navigate(`/service/asset-request-detail/${notification.codSol}`);
      }, 2500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notification.success]);






  return (
    <div className="container display-data">
      {
        // toast notification
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      {
        // Error403 component
        state.unauthorized && (<Error403View />)
      }
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        state.msg && (<h3 style={{ color: "var(--danger-color)" }}>{state.msg}</h3>)
      }

      {
        state.dataVisible && (
          <form onSubmit={onHandleSubmit}>
            {/* FILA 1 */}
            <div className="row-flex">
              {/* SOLICITUD */}
              <div className="col-8">
                <strong>{t("LogisticViewComp.request")}</strong>
              </div>
              <div className="col-4">

              </div>
              {/* ID SERVICIO */}
              <div className="col-9">
                <strong>{t("LogisticViewComp.serviceID")}</strong>
              </div>
              <div className="col-5">
                <span>{initData && initData[0]?.CodServicio}</span>
              </div>
              {/* FECHA SOLICITUD */}
              <div className="col-9">
                <strong>{t("WareHouseView.requestDate")}</strong>
              </div>
              <div className="col-7">
                <span>{initData && renderDate(initData[0]?.FecSolicitud, language)}</span>
              </div>
            </div>

            {/* FILA 2 */}
            <div className="row-flex">
              {/* NOTA SALIDA */}

              {/* FECHA REQUERIDA */}
              <div className="col-8">
                <strong>{t("WareHouseView.requiredDate")}</strong>
                <strong className="required">(*)</strong>
              </div>
              <div className="col-4">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecRequerida"
                  onBlur={onHandleBlur}
                  value={stateForm.fecReque}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, fecReque: target.value }))} />
                {errors.fecRequerida && (<p className="fade-animation" style={{ margin: 0, color: "var(--danger-color)" }}><span>{errors.fecRequerida}</span></p>)}
              </div>
              {/* HORA REQUERIDA */}
              <div className="col-9">
                <strong>{t("serviceCard.hora_reque")}</strong>
              </div>
              <div className="col-7">
                <select
                  name="horaRequerida"
                  value={stateForm.hora}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, hora: target.value }))}>
                  <option value=""></option>
                  <option value="0">{t("RequestPartsView.en_la_ma")}</option>
                  <option value="1">{t("RequestPartsView.en_la_tar")}</option>
                </select>
              </div>
            </div>

            {/* FILA 3 */}
            <div className="row-flex">
              {/* FECHA DEVOLUCION */}
              <div className="col-8">
                <strong>{t("RequestPartsView.fec_devol")}</strong>
              </div>
              <div className="col-4">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecDevolucion"
                  value={stateForm.devolucion}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, devolucion: target.value }))} />
              </div>
            </div>

            {/* FILA 4 */}
            <div className="row-flex">
              {/* CLIENTE */}
              <div className="col-8">
                <strong>{t("RequestPartsView.cliente")}</strong>
              </div>
              <div className="col-49-17">
                <span>{initData && initData[0]?.NomCliente}</span>
              </div>
            </div>

            {/* FILA 5 */}
            <div className="row-flex">
              {/* DIRECCION */}
              <div className="col-8">
                <strong>{t("customerView.address")}</strong>
              </div>
              <div className="col-49-17">
                <span>{initData && initData[0]?.DirCliente}</span>
              </div>
            </div>

            {/* FILA 6 */}
            <div className="row-flex">
              {/* ENVIO */}
              <div className="col-8">
                <strong>{t("RequestPartsView.envio")}</strong>
                <strong className="required">(*)</strong>
              </div>
              <div className="col-4">
                <select
                  name="envio"
                  value={stateForm.envio}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, envio: target.value }))}
                  onBlur={onHandleBlur}>
                  <option value=""></option>
                  <option value="0">DHL</option>
                  <option value="1">MRW</option>
                  <option value="2">Fedex</option>
                  <option value="3">Personal</option>
                  <option value="4">{t("RequestPartsView.cliente")}</option>
                </select>
                {errors.envio && (<p className="fade-animation" style={{ margin: 0, color: "var(--danger-color)" }}><span>{errors.envio}</span></p>)}
              </div>
            </div>

            {/* FILA 7 */}
            <div className="row-flex">
              {/* DIRECCION ENVIO */}
              <div className="col-8">
                <strong>{t("RequestPartsView.direc_envio")}</strong>
              </div>
              <div className="col-85-71">
                <textarea
                  className="textarea-description"
                  name="dirEnvio"
                  placeholder={language === "en" ? "Place the shipping address here..." : "Coloque la dirección de envío aquí..."}
                  value={stateForm.direccion}
                  onChange={({ target }) => setStateForm((val) => ({ ...val, direccion: target.value }))}
                  autoComplete="off" />
              </div>
            </div>


            {/* BOTONES DE ACCION */}
            <div className="row-flex j-content-space-around m-y-small">
              <button type="button" className="btn-primary" onClick={onHandleSaveModal}>{t("PartModify.guardar")}</button>
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
            </div>

            {
              state.modalB && (
                <div className="background-modal">
                  <div className="container-modal-confirm">
                    {/* Question mark */}
                    <div className="row-flex m-y-small align-c justify-center">
                      <div className="question-mark-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                        </svg>
                      </div>
                    </div>
                    <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                      {state.questionAction}
                    </h5>
                    {/* Botones de accion */}
                    <div className="row-flex m-y-medium j-content-space-around">
                      <button type="submit" className="btn-success" disabled={state.loading}>
                        {state.loading ? t("serviceCard.guardando") : t("RequestPartsView.si")}
                      </button>
                      <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, modalB: false }))}>No</button>
                    </div>
                  </div>
                </div>
              )
            }
          </form>
        )
      }

    </div>
  );
};