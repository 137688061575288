

import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { TopNavbarMobile } from "../../components/top-searchbar-mobile/TopNavbarMobile";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";


export const FMIView = () => {

  // language
  const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // navigate
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // state para cargar la data del servidor
  const [isLoading, setIsLoading] = useState(false);

  // DESTRUCTURING DATA SERVER
  const { FMI, employeePermission } = dataServer && dataServer;

  // useEffect
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setIsLoading(true);
        const response = await fetch(urls.fmi, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }

        setIsLoading(false);
      };

      fetchDataServer();

    } catch (error) {
      console.log(error)
    }
  }, []);

  return (
    <>
      {
        isLoading && (<LoadingComp />)
      }
      <TopNavbarMobile />
      <div className="container display-data">
        <div className="table-container-mobile">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th style={{ borderRadius: '5px 0 0 0' }}>Nombre</th>
                <th>Descripcion</th>
                <th># Equipos</th>
                <th># ID</th>
                <th style={{ borderRadius: '0 5px 0 0' }}>Fecha Tope</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                FMI && FMI.map((items) => (
                  <tr key={items.CodFMI}>
                    <td style={{ textAlign: 'center' }}><Link className="link-tag" to={`/service/fmi/fmi-detail/${items.CodFMI}`}>{items.NumFMI}</Link></td>
                    <td style={{ textTransform: 'capitalize' }}>{items.Descripcion}</td>
                    <td style={{ textAlign: 'center' }}>{items.Total}</td>
                    <td style={{ textAlign: 'center' }}>{items.Llamada}</td>
                    <td style={{ textAlign: 'center' }}>{renderDate(items.FecTope, language)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};