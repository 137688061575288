
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// REACT ROUTER IMPORTATION
import { Link, useLocation, useNavigate } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions";
import { ProcesarRequestOrd } from "../../components/commercial-orders-comp/ProcesarRequestOrd";


export const CommercialRequestOrdersView = () => {

  // GET the language
  const lang = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // useNavigate
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [requestOrd, setRequestOrd] = useState([]);
  const [controller, setController] = useState(null); // estado para cancerlar las peticiones al servidor
  const [state, setState] = useState({ isLoading: false, showBtnAdd: false, showModalUpdate: false, msg: "", RequestOrder: [] });

  // DESTRUCTURING DATA SERVER
  const { commercialRequest } = dataServer && dataServer;



  // funcion para abrir el modal para agregar la parte
  const onHandleOpenModal = () => {
    const FilRequest = requestOrd.filter((sol) => sol.Mover);
    setState((val) => ({ ...val, showModalUpdate: true, RequestOrder: FilRequest }));
  };



  // funcion para cerrar el modal
  const onCloseModal = () => {
    setState((val) => ({ ...val, showModalUpdate: false }));
  };


  // funcion para cambiar el valor del mover
  const onHandleChangeMover = (newVal = false, cod = 0) => {
    setRequestOrd((val) => {
      return val.map((ord) => {
        if (ord.CodDetOrden === cod) {
          return { ...ord, Mover: newVal };
        }
        return ord;
      });
    });
  };


  // funcion para realizar la peticion al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(urls.commercialRequest, {
        headers: {
          "Authorization": getTheToken().token,
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);


  // useEffect para cuando cambie el estado de las ordenes
  useEffect(() => {
    const filt = requestOrd.filter((sol) => sol.Mover);

    if (filt.length > 0) {
      setState((val) => ({ ...val, showBtnAdd: true }));
    } else {
      setState((val) => ({ ...val, showBtnAdd: false, msg: "" }));
    }

    if (filt.length > 1) {
      for (let i = 0; i < filt.length; i++) {
        if (filt[i]?.CodCliente !== filt[0]?.CodCliente) {
          setState((val) => ({ ...val, showBtnAdd: false, msg: "Las ordenes que seleccione deben ser del mismo cliente." }));
          return
        } else {
          setState((val) => ({ ...val, msg: "" }));
        }
      }
    }
  }, [requestOrd]);


  // useEffect para setear las solicitudes en el estado
  useEffect(() => {
    if (commercialRequest && commercialRequest.length > 0) {
      setRequestOrd(commercialRequest);
    }
  }, [commercialRequest]);



  return (
    <>
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        <div className="row-flex j-content-space-b">
          <div className="col-6">
            {
              state.msg && (
                <h5 className="required">{state.msg}</h5>
              )
            }
          </div>
          <div className="col-10 row-flex flex-end">
            <button type="button" className="btn-primary" onClick={() => navigate("/commercial/request-new-order")}>Nuevo</button>
          </div>
        </div>
        <div className="table-container-mobile m-y-small">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>#</th>
                <th>Solicitud</th>
                <th>Cliente</th>
                <th>Equipo</th>
                <th>Vend.</th>
                <th>Cant.</th>
                <th>Precio $</th>
                <th>Total $</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                requestOrd && requestOrd.map((list) => (
                  <tr key={list.CodeIndex}>
                    <td className="txt-center">
                      <Link className="link-tag" to={`/commercial/request-order-detail/${list.CodDetOrden}`}>{list.CodeIndex}</Link>
                    </td>
                    <td>{list.CodOrden}</td>
                    <td><Link className="link-tag" to={`/customers/customer-detail/${list.CodCliente}`}>{list.NomCliente}</Link></td>
                    <td>{list.NomModelo}</td>
                    <td style={{ textAlign: 'center' }}>{list.Inicial}</td>
                    <td style={{ textAlign: 'center' }}>{list.Cantidad}</td>
                    <td style={{ textAlign: 'right' }}><span className={list.NTD > 0 ? "completed" : ""}>{list.formattedNTD}</span></td>
                    <td style={{ textAlign: 'right' }}><span className={list.NTD > 0 ? "completed" : ""}>{list.formattedNTD}</span></td>
                    <td style={{ textAlign: 'center' }}>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name={`move-${list.CodDetOrden}`}
                        checked={list.Mover}
                        onChange={({ target }) => onHandleChangeMover(target.checked, list.CodDetOrden)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        {
          // boton para abrir el modal para agregar
          state.showBtnAdd && (
            <div className="btn-add-part-container">
              <button className="btn-add-part" onClick={onHandleOpenModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                </svg>
              </button>
            </div>
          )
        }

        {
          // modal para actualizar la orden
          state.showModalUpdate && (
            <ProcesarRequestOrd onClose={onCloseModal} Orders={state.RequestOrder} lang={lang} token={getTheToken().token} />
          )
        }
      </div>
    </>
  );
};