// componente para recargar la app cuando exista una nueva version

import { useTranslation } from "react-i18next";




export const ReloadComp = () => {

  // GET the react translation
  const [t] = useTranslation("global");

  // funcion para recargar el navegador
  const onReloadWind = () => {
    window.location.reload();
  };

  return (
    <div className="background-modal">
      <div className="container-modal modal-width-reload row-flex align-c justify-center">
        <h5 style={{ textAlign: "center" }}>{t("LoginView.n_version")}</h5>
        <button type="button" className="btn-success" onClick={onReloadWind}>Ok!</button>
      </div>
    </div>
  );
};