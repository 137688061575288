
import React from "react";

// react router dom
import { Link } from "react-router-dom";


// creo que este componente no sera necesario


// en este modulo debe encontrarse: solicitud de vacaciones, aprobacion, planificacion y logistica, y reportes
export const VacacionesPermisosDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  

  // state definition
  // const 



  return (
    <div className="container display-data">
      <form >

        <div className="row-flex">
          <div className="col-7 border">
            <strong>Nro. Control</strong>
          </div>
          <div className="col-3 border">
            <h5>5588</h5>
          </div>
          <div className="col-7 border">
            <strong>Etapa</strong>
          </div>
          <div className="col-3 flex-grow border">
            <h5>Elaboracion de vacaciones</h5>
          </div>
        </div>

        <div className="row-flex">
          <div className="col-7">
            <strong>Empresa</strong>
          </div>
          <div className="col-3">
            <select name="" id=""></select>
          </div>
        </div>

      </form>
    </div>
  );
};