import React from "react";

//----> Styles importation
import "../human-resources/HumanReView.css";

// ---> Employees component importation
import { EmployeesView } from "../../views/employees/EmployeesView";


export const HumanReView = () => {

  return (
    <>
      <div className="display-data">
        <div style={{
          margin: 'var(--margin-container)',
          padding: '0.3rem',
        }}>
          <EmployeesView />
        </div>
      </div>
    </>
  );
};